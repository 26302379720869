import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ProductService } from '../product.service';
import { Router } from '@angular/router';
import { ListService } from '../list.service';
import { Observable, Subject } from 'rxjs';
import { AutoComplete } from "primeng/autocomplete";
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { QuotationService } from "../quotation.service";
import { StoreService } from '../store.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { CatalogService } from '../catalog.service';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit, OnDestroy {
    
  @Input()
  viewer: boolean = false;

  @Input()
  shopMode: number;

  text: any;
  results: any[];
  //searchCatFilter: any = null;

  @ViewChild('autocompleteSearch', { static: true })
  formAutoComplete: AutoComplete;

  destroy$: Subject<boolean> = new Subject<boolean>();
  translations: any = {};

  private searchTerms$ = new Subject<string>();

  currentBrandId: string;
  brands$: Observable<any[]>;

  constructor(private productService: ProductService, private router: Router, private messageService: MessageService,
    private storeService: StoreService, private confirmationService: ConfirmationService, private catalogService : CatalogService,
    private listService: ListService, private quotationService: QuotationService) { }

  ngOnInit() {
    let translationRequired = marker(['warningAllUpper', 'confirmWithoutMandatoryComponents', 'yes', 'no',
      'notPossibleToAddToQuotation', 'articleAddToQuotation', 'quotationNotValid', 'articleAdded', 'articleAddToList']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.searchTerms$.pipe(debounceTime(250), switchMap((term: string) => {
      return this.productService.search(term, undefined);
    })).subscribe(data => {
      this.results = data;
    });

    this.catalogService.getCurrentBrandId().pipe(takeUntil(this.destroy$)).subscribe((idBrand) => {
      this.currentBrandId = idBrand;
    });

    this.brands$ = this.catalogService.getBrands();
  }

  ngOnDestroy(): void {

    this.searchTerms$.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

  }

  search(event) {
    this.searchTerms$.next(event.query);
  }

  onSelect(event: any) {
    let value = event.value;
    debugger;
    this.catalogService.populateAllCache(value.idBrand).subscribe(() => {
      if (value.type == 2) {
        this.formAutoComplete.writeValue(undefined);
        this.results = undefined;
        this.router.navigate(this.storeService.createUrl(['/product', value.id]) as any[]);
      } else if (value.type == 0) {
  
        this.router.navigate(this.storeService.createUrl(['/product', value.productModel]) as any[], { queryParams: { variant: value.id } });
      } else if (value.type == 1) {
        this.router.navigate(this.storeService.createUrl(['/accessories', value.category]) as any[], { queryParams: { name: value.name } });
      }
    });
  }

  onFocus(_event) {
    this.formAutoComplete.writeValue(undefined);
  }

  addToCart(item, ignoreObblig: boolean = false) {
    if (!ignoreObblig && item.hasComponentiObbligatori) {
      this.confirmationService.confirm({
        key: 'confirmAddWith',
        header: this.translations["warningAllUpper"],
        message: this.translations["confirmWithoutMandatoryComponents"],
        accept: () => {
          this.addToCart(item, true);
        },
        reject: () => {
          return;
        },
        acceptLabel: this.translations["yes"],
        rejectLabel: this.translations["no"]
      });
      return;
    }

    this.listService.addSearchItemToList(item).subscribe(t => {
      const type = t.listType == 1 ? "quotations" : "lists";
      if (t.listType == 1) {
        if (t.listState != 0) {
          this.messageService.add({ severity: 'error', summary: this.translations['quotationNotValid'], detail: this.translations['notPossibleToAddToQuotation'], life: 5000 });
        } else {
          this.quotationService.reloadQuotationDetail(t.id);
          this.messageService.add({ severity: 'success', summary: this.translations['articleAdded'], detail: this.translations['articleAddToQuotation'], life: 5000 });
          this.results.forEach(t => t.quantity = null);
          this.router.navigate(this.storeService.createUrl([type, t.id]) as any[]);
        }
      } else {
        this.listService.reloadListDetail(t.id);
        this.messageService.add({ severity: 'success', summary: this.translations['articleAdded'], detail: this.translations['articleAddToList'], life: 5000 });
        this.results.forEach(t => t.quantity = null);
        this.router.navigate(this.storeService.createUrl([type, t.id]) as any[]);
      }
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  clear() {
    this.text = undefined;
  }

  brandChanged(idBrand: string) {
    if(!idBrand) return;
    this.catalogService.populateAllCache(idBrand).subscribe(() => {
      this.router.navigate(this.storeService.createUrl(['/']) as any[]);
    });
  }
}
