import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ListService } from './list.service';
import { ActivatedRouteSnapshot } from "@angular/router";
import { SearchQuotationModel } from './shared/search-quotation.model';
import { filter as _filter, uniq as _uniq } from 'lodash';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuotationService  {

  private httpHeadersDefault: HttpHeaders;
  private identityUrl: string = environment.identityUrl;

  private _quotationSource: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly baseQuotation: Observable<any> = this._quotationSource.asObservable().pipe(filter(t => t != null));

  //N.B. Impostare _sempre_ prima i dettagli della lista. Se si imposta prima la lista cerca dettagli non allineati!
  private _quotationDetailSource: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly quotationDetail: Observable<any> = this._quotationDetailSource.asObservable().pipe(filter(t => t != null));

  private _opportunitySource: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public readonly quotationOpportunity: Observable<any> = this._opportunitySource.asObservable();

  private differenceInQuotation: boolean = false;

  //semplice subject per centralizzare l'azione di checkout
  private _checkoutAction: Subject<boolean> = new Subject<boolean>();
  public checkoutActionObs: Observable<boolean> = this._checkoutAction.asObservable();

  constructor(private httpClient: HttpClient, private listService: ListService) {
    this.httpHeadersDefault = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.httpClient.get<any>(`/api/list/${route.paramMap.get('id')}/get-quotation-details/`).pipe(tap(t => {
      this._quotationDetailSource.next(t.detail);
      this._quotationSource.next(t.list);
      this._opportunitySource.next(t.opportunity);
    }));
  }

  updateQuotationOpportunity(opportunity: any): void {
    this._opportunitySource.next(opportunity);
  }

  updateQuotation(deleteList: boolean = false): Observable<any> {
    if (deleteList) {
      return this.listService.updateQuotation(this._quotationSource.getValue(), deleteList);
    } else {

      return this.listService.updateQuotation(this._quotationSource.getValue())
        .pipe(tap(t => this._quotationSource.next(t)));
    }
  }

  //totale acquisto, totale vendita, margine
  calculateTotals(): [number, number, number] {
    let listTotalReserved = 0;
    let listTotalPublic = 0;
    let fullPrice = 0;
    let currentQuotation = this._quotationSource.value;
    let invalidList = this._quotationSource.value.invalid;

    for (let item of currentQuotation.items) {
      this.fillItemDetail(item);
      if (item.type == 3) {
        listTotalReserved += item.prezzoRiservato * item.quantity;
        listTotalPublic += item.prezzoVendita * item.quantity;
        fullPrice += item.prezzoRiservato * item.quantity;
      } else if (item.prezzo) {
        let inversoDiscount = (1 - (item.discount || 0) / 100) * (1 - (item.discount2 || 0) / 100) * (1 - (item.discount3 || 0) / 100);
        if (item.type != 2) {
          listTotalReserved += (item.prezzoRiservato * item.quantity);
          listTotalPublic += inversoDiscount * item.prezzo * item.quantity;
          fullPrice += item.prezzoRiservato * item.quantity;
        } else {
          //se e' configurazione la quantita' non deve influenzare
          listTotalReserved += item.prezzoRiservato;
          listTotalPublic += inversoDiscount * item.prezzo;
          fullPrice += item.prezzoRiservato;
        }

      }
      if (item.type != 2 && item.childItems) {
        for (let cItem of item.childItems) {
          if (cItem.prezzo) {
            let inversoDiscountChild = (1 - (cItem.discount || 0) / 100) * (1 - (cItem.discount2 || 0) / 100) * (1 - (cItem.discount3 || 0) / 100);

            listTotalReserved += (cItem.prezzoRiservato * cItem.quantity);
            listTotalPublic += inversoDiscountChild * cItem.prezzo * cItem.quantity;
            fullPrice += cItem.prezzoRiservato * cItem.quantity;
          }
        }
      }
      invalidList = (invalidList || item.esWarning);
    }

    this._quotationSource.value.invalid = invalidList;
    return [listTotalReserved, listTotalPublic, (listTotalPublic - fullPrice) / listTotalPublic];
  }

  fillItemDetail(item: any) {
    const details = this._quotationDetailSource.value;
    switch (item.type) {
      case 0: {

        const detail = details.variants.find(t => t.id == item.referenceId);
        if (!detail) {
          item.esWarning = true;
          break;
        }
        
        item.idBrand = detail.idBrand;
        item.productModel = detail.productModel;
        item.image = detail.images ? detail.images[0] : null;
        item.name = detail.esolverName ? detail.esolverName : "";
        item.prezzo = detail.prezzo;
        item.description = detail.esolverDescription;
        item.esWarning = item.esWarning || detail.esWarning;
        item.prezzoRiservato = detail.prezzoRiservato;
        item.availability = detail.qtaDisponibile;
        item.anthology = detail.anthology;
        item.anthologyStar = detail.anthologyStar;
        item.mxStar = detail.mxStar;
        item.um = detail.um;
        item.sku = detail.sku;
        item.categoryName = detail.categoryName;
        item.colors = [];
        let color = this.findColor(detail);
        if (color)
          item.colors.push(color);

        if (item.childItems) {
          for (let cItem of item.childItems) {
            let cdetail = details.accessories.find(t => t.id == cItem.referenceId && t.sku == cItem.sku);

            if (!cdetail) { //a causa della presenza di accessory anthology  con pack non anthology lato server puo' essere generato un modello con i soli pack non anthology
              cdetail = details.accessories.filter(a => a.packs).find(a => a.packs.some(p => p.sku == cItem.sku))
            }

            if (!cdetail) {
              item.esWarning = cItem.esWarning = true;
              continue;
            }

            let color = this.findColor(cdetail);
            cItem.idBrand = cdetail.idBrand;
            if (color)
              item.colors.push(color);
            if (cItem.sku == cdetail.sku) {
              cItem.image = cdetail.image;
              cItem.name = cdetail.esolverName ? cdetail.esolverName : "";
              cItem.prezzo = cdetail.prezzo;
              cItem.prezzoRiservato = cdetail.prezzoRiservato;
              cItem.availability = cdetail.qtaDisponibile;
              cItem.anthology = cdetail.anthology;
              cItem.anthologyStar = cdetail.anthologyStar;
              cItem.mxStar = cdetail.mxStar;
              cItem.esWarning = cItem.esWarning || cdetail.esWarning;
              item.esWarning = item.esWarning || cItem.esWarning;
              cItem.um = cdetail.um;
            } else {
              if (!cdetail.packs || cdetail.packs.length == 0) {
                item.esWarning = cItem.esWarning = true;
                continue;
              }

              //si tratta di un pack
              const pack = cdetail.packs.find(t => t.sku == cItem.sku);
              if (!pack) {
                item.esWarning = cItem.esWarning = true;
                continue;
              }
              
              cItem.image = cdetail.image;
              cItem.name = pack.esolverName ? pack.esolverName : "";
              cItem.prezzo = pack.prezzo;
              cItem.prezzoRiservato = pack.prezzoRiservato;
              cItem.availability = pack.qtaDisponibile;
              cItem.anthology = pack.anthology;
              cItem.anthologyStar = pack.anthologyStar;
              cItem.mxStar = pack.mxStar;
              cItem.esWarning = cItem.esWarning || pack.esWarning;
              item.esWarning = item.esWarning || cItem.esWarning;
              cItem.um = pack.um;
            }
          }
        }
        if (item.colors.length === 0)
          delete item.colors;
        else
          item.colors = _uniq(item.colors).map(t => t.toString());
        break;
      }
      case 1: {

        let detail = details.accessories.find(t => t.id == item.referenceId && t.sku == item.sku);

        if (!detail) { //a causa della presenza di accessory anthology  con pack non anthology lato server puo' essere generato un modello con i soli pack non anthology
          detail = details.accessories.filter(a => a.packs).find(a => a.packs.some(p => p.sku == item.sku))
        }

        if (!detail) {
          item.esWarning = true;
          break;
        }
        item.colors = [];
        let color = this.findColor(detail);

        item.idBrand = detail.idBrand;
        if (color)
          item.colors.push(color);
        if (item.sku == detail.sku) {
          item.image = detail.image;
          item.name = detail.esolverName ? detail.esolverName : "";
          item.prezzo = detail.prezzo;
          item.description = detail.esolverDescription;
          item.prezzoRiservato = detail.prezzoRiservato;
          item.availability = detail.qtaDisponibile;
          item.esWarning = item.esWarning || detail.esWarning;
          item.anthology = detail.anthology;
          item.anthologyStar = detail.anthologyStar;
          item.mxStar = detail.mxStar;
          item.um = detail.um;
        } else {
          if (!detail.packs || detail.packs.length == 0) {
            item.esWarning = true;
            break;
          }

          //si tratta di un pack
          const pack = detail.packs.find(t => t.sku == item.sku);
          if (!pack) {
            item.esWarning = true;
            break;
          }

          item.image = detail.image;
          item.name = pack.esolverName ? pack.esolverName : "";
          item.prezzo = pack.prezzo;
          item.prezzoRiservato = pack.prezzoRiservato;
          item.availability = pack.qtaDisponibile;
          item.anthologyStar = pack.anthologyStar;
          item.mxStar = pack.mxStar;
          item.anthology = pack.anthology;
          item.esWarning = item.esWarning || pack.esWarning;
          item.um = pack.um;
        }


        if (item.colors.length === 0)
          delete item.colors;
        else
          item.colors = _uniq(item.colors).map(t => t.toString());
        break;
      }
      case 2: {
        // wizard product

        item.colors = [];

        let product = undefined;
        if(details.products) {
          product = details.products.find(t => t.id == item.referenceId);
        }        
        if (product) {
          item.productModel = product.id;
          item.image = product.images ? product.images[0] : null;
          item.name = product.name;
          item.description = product.description;
          item.idBrand = product.idBrand;

          let color = this.findColor(product);
          if (color)
            item.colors.push(color);
        } else {
          item.esWarning = true;
        }


        if (item.childItems) {
          for (let cItem of item.childItems) {
            if (cItem.type == 1) { //accessorio

              let cdetail = details.accessories.find(t => t.id == cItem.referenceId && t.sku == cItem.sku);

              if (!cdetail) { //a causa della presenza di accessory anthology  con pack non anthology lato server puo' essere generato un modello con i soli pack non anthology
                cdetail = details.accessories.filter(a => a.packs).find(a => a.packs.some(p => p.sku == cItem.sku))
              }

              
              if (!cdetail) {
                item.esWarning = cItem.esWarning = true;
                continue;
              }
              cItem.idBrand = cdetail.idBrand;
              let color = this.findColor(cdetail);
              if (color)
                item.colors.push(color);

              if (cItem.sku == cdetail.sku) {
                cItem.image = cdetail.image;
                cItem.name = cdetail.esolverName ? cdetail.esolverName : "";
                cItem.prezzo = cdetail.prezzo;
                cItem.prezzoRiservato = cdetail.prezzoRiservato;
                cItem.availability = cdetail.qtaDisponibile;
                cItem.anthology = cdetail.anthology;
                cItem.anthologyStar = cdetail.anthologyStar;
                cItem.mxStar = cdetail.mxStar;
                cItem.esWarning = cItem.esWarning || cdetail.esWarning;
                item.esWarning = item.esWarning || cItem.esWarning;
                cItem.um = cdetail.um;
              } else {

                if (!cdetail.packs || cdetail.packs.length == 0) {
                  item.esWarning = cItem.esWarning = true;
                  continue;
                }

                const pack = cdetail.packs.find(t => t.sku == cItem.sku);
                if (!pack) {
                  item.esWarning = cItem.esWarning = true;
                  continue;
                }
                cItem.image = cdetail.image;
                cItem.name = pack.esolverName ? pack.esolverName : "";
                cItem.prezzo = pack.prezzo;
                cItem.prezzoRiservato = pack.prezzoRiservato;
                cItem.availability = pack.qtaDisponibile;
                cItem.anthology = pack.anthology;
                cItem.anthologyStar = pack.anthologyStar;
                cItem.mxStar = pack.mxStar;
                cItem.esWarning = cItem.esWarning || pack.esWarning;
                item.esWarning = item.esWarning || cItem.esWarning;
                cItem.um = pack.um;
              }
            } else { //variante
              const cdetail = details.variants.find(t => t.id == cItem.referenceId);

              if (!cdetail) {
                cItem.esWarning = true;
                continue;
              }

              cItem.idBrand = cdetail.idBrand;
              let color = this.findColor(cdetail);
              if (color)
                item.colors.push(color);

              cItem.productModel = cdetail.productModel;
              cItem.image = cdetail.images ? cdetail.images[0] : null;
              cItem.name = cdetail.esolverName ? cdetail.esolverName : "";
              cItem.prezzo = cdetail.prezzo;
              cItem.description = cdetail.esolverDescription;
              cItem.prezzoRiservato = cdetail.prezzoRiservato;
              cItem.availability = cdetail.qtaDisponibile;
              cItem.anthology = cdetail.anthology;
              cItem.anthologyStar = cdetail.anthologyStar;
              cItem.mxStar = cdetail.mxStar;
              cItem.esWarning = cItem.esWarning || cdetail.esWarning;
              cItem.sku = cdetail.sku;
              cItem.categoryName = cdetail.categoryName;

              if(!item.categoryName) {
                item.categoryName = cdetail.categoryName;
              }

              item.esWarning = item.esWarning || cItem.esWarning;
              cItem.um = cdetail.um;
            }
          }
          let counterPrezzo = 0;
          let counterPrezzoRiservato = 0;
          let allOk = true;
          for (let cItem of item.childItems) {
            counterPrezzo += cItem.prezzo * cItem.quantity;
            counterPrezzoRiservato += cItem.prezzoRiservato * cItem.quantity;
            allOk = allOk && !cItem.esWarning;
          }
          item.prezzo = allOk ? counterPrezzo : 0;
          item.prezzoRiservato = allOk ? counterPrezzoRiservato : 0;
          item.esWarning = item.esWarning || !allOk;
        } else {
          item.esWarning = true;
        }
        if (item.colors.length === 0)
          delete item.colors;
        else
          item.colors = _uniq(item.colors).map(t => t.toString());
        break;
      }
      case 3: { //custom
        item.prezzoRiservato = item.custom.prezzoAcquisto;
        item.prezzoVendita = item.custom.prezzoRiservato;
        break;
      }
      default: {
        //nothing here
        break;
      }
    }
  }

  getAttachments(list): Observable<any[]> {
    let url: string = `/api/list/${list}/get-quotation-attachments`;
    return this.httpClient.get<any[]>(url);
  }

  downloadAttachment(idQuotation: string, idAttachment: string): Observable<any> {
    let params = new HttpParams().set('id', idQuotation).set('idAttachment', idAttachment);
    const options = { params: params, headers: this.httpHeadersDefault, responseType: 'blob' as 'json' };
    return this.httpClient.get<any>('/api/list/getCustomAttachment', options);
  }

  requestProject(list, date: Date) {
    let url: string = `/api/list/${list.id}/request-project`;
    return this.httpClient.post<any>(url, date).pipe(tap((t: any) => {

      this._quotationSource.next(t);
    }));
  }

  unRegisterProject(list: any): Observable<any> {

    return this.listService.unRegisterProject(list, true).pipe(tap(t => {
      this._quotationSource.next(t);
    }));

  }

  unRegisterProjectNoSideEffect(list: any): Observable<any> {
    return this.listService.unRegisterProject(list, true);
  }

  searchImpersonation(value: string): Observable<any[]> {
    let params = new HttpParams().set('value', value);
    return this.httpClient.get<any[]>(this.identityUrl + '/service/service/searchCompany', { params: params });
  }

  getCompany(codCli: string) : Observable<any> {
    let params = new HttpParams().set('codCli', codCli);
    return this.httpClient.get<any>(this.identityUrl + '/service/service/getCompany', { params: params });
  }

  retrieveUserOfQuotation(quotationId : string) : Observable<any>{
    return this.httpClient.get<any>(`/api/list/${quotationId}/retrieve-user-of-quotation`); 
  }

  reloadQuotationDetail(checkQuotationId?: string) {
    if (!this._quotationSource.value)
      return;

    if (checkQuotationId && checkQuotationId != this._quotationSource.value.id)
      return;

    this.httpClient.get<any>(`/api/list/${this._quotationSource.value.id}/get-quotation-details/`).subscribe(detail => {
      this._quotationDetailSource.next(detail.detail);
      this._quotationSource.next(detail.list);
      this.calculateTotals();
    });
  }

  calculateProjectDiscount(): number {
    let localBaseQuotation = this._quotationSource.value;
    if (!localBaseQuotation.projectDiscount) return 0;

    let priceForDiscount: number = 0;
    for (let item of localBaseQuotation.items) {
      this.fillItemDetail(item);
      if (item.type != 3 && item.prezzo && !item.anthologyStar && !item.mxStar) {
        if (item.type == 2) {
          priceForDiscount += item.prezzoRiservato;
        } else {
          priceForDiscount += (item.prezzoRiservato * item.quantity);
        }
        
      }
      if (item.type != 2 && item.type != 3 &&  item.childItems) {
        for (let cItem of item.childItems) {
          if (cItem.prezzo && !item.anthologyStar && !item.mxStar) {
            priceForDiscount += (cItem.prezzoRiservato * cItem.quantity);
          }
        }
      }
    }

    return priceForDiscount * localBaseQuotation.projectDiscount / 100;
  }

  getTableNextQuotation(searchModel: SearchQuotationModel): Observable<any> {
    return this.httpClient.get<any>('/api/List/getQuotationsPaged', { params: this.createParamForPagedQuotation(searchModel) });
  }

  getTablePrevQuotation(searchModel: SearchQuotationModel): Observable<any> {
    return this.httpClient.get<any>('/api/List/getPrevQuotationsPaged', { params: this.createParamForPagedQuotation(searchModel) });
  }

  addRevision(listId: string) {
    let url: string = `/api/list/${listId}/add-revision`;
    return this.httpClient.post<any>(url, {}).pipe(tap((t: any) => {

      this._quotationDetailSource.next(t.detail);
      this._quotationSource.next(t.list);
    }));
  }

  deleteRevision(listId: string, rev: number) {
    let url: string = `/api/list/${listId}/delete-revision/${rev}`;

    return this.httpClient.delete<any>(url).pipe(tap((t: any) => {
      this._quotationDetailSource.next(t.detail);
      this._quotationSource.next(t.list);
    }));
  }

  setRevision(listId: string, rev: number) {
    let url: string = `/api/list/${listId}/set-revision`;
    var formData = new FormData();
    formData.append('rev', rev.toString());
    return this.httpClient.post<any>(url, formData).pipe(tap((t: any) => {

      this._quotationDetailSource.next(t.detail);
      this._quotationSource.next(t.list);
    }));
  }

  private findColor(item): any {
    let color = _filter(item.attributes, { 'sysName': 'color-temperature' }) as any;
    if (color.length > 0)
      return color[0].value;
    else
      return null;
  }

  private createParamForPagedQuotation(searchModel: SearchQuotationModel): HttpParams {
    let params = new HttpParams().set("pageSize", searchModel.pageSize.toString());
    if (searchModel.lastNameSearch) {
      params = params.set("lastName", searchModel.lastNameSearch);
    }
    if (searchModel.lastUpdateOnSearch) {
      params = params.set("lastUpdateOn", searchModel.lastUpdateOnSearch);
    }

    if (searchModel.lastIds && searchModel.lastIds.length > 0) {
      params = params.set("lastIds", JSON.stringify(searchModel.lastIds));
    }

    if (searchModel.status) {
      params = params.set("status", searchModel.status.toString());
    }
    if (searchModel.deleteId) {
      params = params.set("deleteId", searchModel.deleteId);
    }
    if (searchModel.deleteReason) {
      params = params.set("deleteReason", searchModel.deleteReason);
    }
    if (searchModel.orderModeDate) {
      params = params.set("orderModeDate", searchModel.orderModeDate.toString());
    }

    if (searchModel.mustContain) {
      params = params.set("matchName", searchModel.mustContain);
    }

    if (searchModel.publicCode) {
      params = params.set("publicCode", searchModel.publicCode);
    }

    if (searchModel.codCliFor) {
      params = params.set("codCli", searchModel.codCliFor);
    }

    return params;
  }

  public setDifferenceStatus(value: boolean) {
    this.differenceInQuotation = value;
  }

  public getDifferenceStatus(): boolean {
    return this.differenceInQuotation;
  }

  public deleteAttachment(idQuotation: string, idAttachment: string) {
    let params = new HttpParams().set('id', idQuotation).set('idAttachment', idAttachment);
    let url: string = `/api/list/deleteCustomAttachment`;
    return this.httpClient.get<any>(url, { params: params })
  }

  getInformationCustomer(listId: string, type: string) {
    let params = new HttpParams().set('listId', listId).set('type', type);
    return this.httpClient.get<any>("/api/list/getInformationCustomer", { params: params });
  }

  performCheckout() {
    this._checkoutAction.next(true);
  }

  forceQuotationToUseOldListino(idQuotation: string) : Observable<any> {
    let url: string = `/api/list/forceQuotationToUseOldListino/${idQuotation}`;
    return this.httpClient.put<any>(url, {});
  }
}
