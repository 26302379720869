import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'project-rejected',
	templateUrl: 'project-rejected.component.html',
	styleUrls: ['project-rejected.component.scss']
})

export class ProjectRejectedComponent implements OnInit {

	nameProject: string;

	constructor(private activatedRoute: ActivatedRoute) {

	}

	ngOnInit(): void {
		this.nameProject = this.activatedRoute.snapshot.params['nameproject'];
	}
}