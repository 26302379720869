<div class="application-menu" [@toggleAnimation]="status">
  <div class="outer-container">
    <div class="container">
      <div class="columns is-gapless">
        <div class="column is-4 column-category">
          <ng-scrollbar square-size *ngIf="currentApplication && currentApplication.categories">
            <div class="list-item" *ngFor="let category of currentApplication.categories" [class.active]="currentCategory?.id == category.id" hoverIntent (onHoverIntent)="selectCategory(category)">
              <div class="level is-mobile">
                <div class="level-left">
                  <div class="level-item category-name">
                    {{category.name}}
                  </div>
                </div>
              </div>
            </div>
          </ng-scrollbar>
        </div>
        <div class="column is-4 column-products">
          <ng-scrollbar *ngIf="currentCategory && currentCategory.families" square-size>
            <ng-container *ngFor="let family of currentCategory.families | orderBy:'name'">
              <div class="list-item prod" *ngFor="let prod of family.productsModel | orderBy:'name'" [class.active]="currentProduct?.id == prod.id" hoverIntent (onHoverIntent)="selectProduct(prod)">
                <a (click)="gotoProduct(prod)" class="level is-mobile">
                  <div class="level-left">
                    <div class="level-item">
                      {{prod.name}}
                    </div>
                  </div>
                  <div class="level-right">
                    <div class="level-item">
                      <figure class="image is-32x32">
                        <img fallback-src="https://via.placeholder.com/32x32" query="width=64&height=64&mode=crop" [cdnImg]="prod.images[0]" [productId]="prod.id">
                      </figure>
                    </div>
                  </div>
                </a>
              </div>
            </ng-container>
          </ng-scrollbar>
        </div>
        <div class="column is-4 column-detail" *ngIf="currentProduct">
          <figure class="image is-square">
            <img fallback-src="https://via.placeholder.com/1200x1200" query="width=800&height=800&mode=crop" [cdnImg]="currentProduct?.images[0]" [productId]="currentProduct?.id">
          </figure>
          <h1 class="title">
            <a (click)="gotoProduct(currentProduct)" class="has-text-dark">
              {{currentProduct?.name}}&nbsp;<i class="fas fa-angle-right"></i>
            </a>
          </h1>
          <h2 class="subtitle is-size-6">{{currentCategory?.name}}</h2>
          <div class="has-text-right close">
            <a class="has-text-black" (click)="close()">
              <i class="fas fa-2x fa-times"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
