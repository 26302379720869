import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private httpHeadersDefault: HttpHeaders;

  constructor(private httpClient: HttpClient) {
    this.httpHeadersDefault = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  getNotifications(notificationIds: string[]): Observable<any[]> {
    let url: string = '/api/firebaseNotification/getNotificationsForUser';
    return this.httpClient.post<any[]>(url, notificationIds, { headers: this.httpHeadersDefault });
  }

  getNotificationsForUser(page: number, pageSize: number): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('pageSize', pageSize.toString());
    let url: string = '/api/firebaseNotification/getAllNotificationsForUser';
    return this.httpClient.get<any[]>(url, { params: params });
  }

  getListino(idBrand: string): Observable<any> {
    let params = new HttpParams().set('idBrand', idBrand)
    return this.httpClient.get<any>('/api/product/getListinoPrezzi', { 
      headers: this.httpHeadersDefault,
      params: params,
      responseType: 'blob' as 'json' });
  }

  getSubstitute(configId: string, referenceId: string, quotationId: string, quantity: number, tipoLista: number) {
    let params = new HttpParams();
    if (configId) {
      params = params.set("configId", configId);
    } else {
      params = params.set("referenceId", referenceId);
    }

    params = params.set("quantity", quantity.toString())
      .set("listId", quotationId)
      .set("tipoLista", tipoLista.toString());



    return this.httpClient.get<any[]>("/api/product/get-substitutes", { params: params });
  }

  getInfoCatalogsFull(idBrand: string) : Observable<any[]> {
    let params = new HttpParams()
    .set('idBrand', idBrand);
    return this.httpClient.get<any[]>('/api/pdf/getBrochureFileStatus', {params: params});
  }

  downloadCatalogFull(parentFolder: string, idBrand: string) : Observable<any> {
    let params = new HttpParams()
    .set('idBrand', idBrand)
    .set('parentFolder', parentFolder);

    return this.httpClient.get<any>('/api/pdf/getBrochureFileFull', { params: params,
      headers: this.httpHeadersDefault,
      reportProgress: true,
      observe: 'events',
      responseType: 'blob' as 'json' });
  }

  downloadCatalogPdfUser(idBrand: string) : Observable<any> {
    let params = new HttpParams().set('idBrand', idBrand);

    return this.httpClient.get<any>('/api/pdf/getBrochureFileUser', {
      headers: this.httpHeadersDefault,
      reportProgress: true,
      params: params,
      observe: 'events',
      responseType: 'blob' as 'json' });
  }

  enqueueFileBrochureFullAll() : Observable<any> {
    return this.httpClient.get<any>('/api/pdf/getBrochureFull');
  }

  getHolyDays() : Observable<any[]> {
    return this.httpClient.get<any>('/api/list/getHolydays');
  }

  callSyncArticoli() : Observable<any> {
    return this.httpClient.get<any>('/api/EsolverApiWebHook/launchTriggerArticoli');
  }

  callSyncListini() : Observable<any> {
    return this.httpClient.get<any>('/api/EsolverApiWebHook/launchTriggerListini');
  }

  getPicturesToShow() : Observable<any[]> {
    return this.httpClient.get<any[]>('https://macrolux.eu/b2bapi/banner');
  }

}
