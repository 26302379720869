<h2 class="title-hr is-size-3 is-uppercase font-condensed has-text-weight-bold">
    <span [translate]="'performance'"></span>&nbsp;<span *ngIf="level=='agent'">{{agente.name}}</span><span *ngIf="level=='admin'" [translate]="'agent'"></span>

</h2>
<!-- Admin sceglie gli agenti con una select -->
<div class="field" *ngIf="level=='admin'">
    <div class="control ui-fluid">
        <div class="select">
            <select [(ngModel)]="agente" (ngModelChange)="onSelectedAgente($event)">
                <option [ngValue]="undefined" [translate]="'selectAgent'">/option>
                <option *ngFor="let option of agenti" [ngValue]="option">{{option.name | titlecase }}</option>
            </select>
        </div>
    </div>
</div>
<div class="columns">
  <div class="column" *ngIf="statsBudget && statsOrdinato">
    <p class="has-text-centered title is-5">Budget {{today | date:'yyyy'}}</p>
    <p class="has-text-centered subtitle is-6">
      {{statsBudget.budgetIntra | currency:'EUR':'symbol'}}
      <span [ngClass]="{'has-text-danger' : deltaPercent < 0, 'has-text-success' : deltaPercent >= 0 }">
        <i class="fas fa-fw" [ngClass]="{'fa-caret-down' : deltaPercent < 0, 'fa-caret-up' : deltaPercent >= 0 }"></i>{{deltaPercent | absolute | percent}}
      </span>
      
    </p>
  </div>
  <div class="column is-4">
    <div *ngIf="!loadingPreventivi; else loading">
      <div *ngIf="statsPreventivi">
        <p class="has-text-centered title is-5" [translate]="'orderConversion'">
          Conversione in ordine
        </p>
        <p class="has-text-centered subtitle is-6" [translate]="'last12Months'">ultimi 12 mesi</p>
      </div>
    </div>
  </div>
  <div class="column" *ngIf="statsUsers">
    <p class="has-text-centered title is-5" [translate]="'newUserB2b'">Nuovi utenti B2B</p>
    <p class="has-text-centered subtitle is-6">
      <span [translate]="'last12Months'">ultimi 12 mesi</span>
    </p>
  </div>
</div>
<div class="columns is-vcentered">
    <!-- Budget-->
    <div class="column">
        <div *ngIf="!loadingBudget && !loadingOrdinato; else loading">
          <p-chart type="bar" [data]="budgetChartData" [options]="budgetChartOptions" *ngIf="statsBudget && statsOrdinato"></p-chart>
        </div>
    </div>
    <!-- Preventivi-->
    <div class="column">
      <div *ngIf="statsPreventivi">
        <div class="columns is-vcentered">
          <div class="column has-text-centered">
            <ngx-gauge type="arch" [size]="175" [thresholds]="thresholdConfigQ"
                       [value]="statsPreventivi.numeroPreventivi*100/12" [thick]="10">
              <ngx-gauge-value>
                <span *ngIf="statsPreventivi.numeroPreventivi > 0">{{ statsPreventivi.numeroPreventivi | number }}</span>
                <span *ngIf="statsPreventivi.numeroPreventivi === 0">n/a</span>
              </ngx-gauge-value>
              <ngx-gauge-label [translate]="'quotations'">
                preventivi
              </ngx-gauge-label>
            </ngx-gauge>
            <p class="is-size-5 has-text-weight-semibold">{{statsPreventivi.totalePreventivi | currency:'EUR':'symbol'}}</p>
          </div>
          <div class="column is-narrow">
            <p class="has-text-centered title is-5">
              <span class="icon"><i class="fas fa-2x fa-long-arrow-alt-right"></i></span>
            </p>
            <p class="has-text-centered subtitle is-6">{{(statsPreventivi.numeroOrdini / statsPreventivi.numeroPreventivi) | percent }}</p>
          </div>
          <div class="column has-text-centered">
            <ngx-gauge type="arch" [size]="175" [thresholds]="thresholdConfigO"
                       [value]="statsPreventivi.numeroOrdini*100/12" [thick]="10">
              <ngx-gauge-value>
                <span *ngIf="statsPreventivi.numeroOrdini > 0">{{ statsPreventivi.numeroOrdini | number }}</span>
                <span *ngIf="statsPreventivi.numeroOrdini === 0">n/a</span>
              </ngx-gauge-value>
              <ngx-gauge-label [translate]="'orders'">
                ordini
              </ngx-gauge-label>
            </ngx-gauge>
            <p class="is-size-5 has-text-weight-semibold">{{statsPreventivi.totaleOrdini | currency:'EUR':'symbol'}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Utenti-->
    <div class="column">
        <div *ngIf="!loadingUsers; else loading">
            <div *ngIf="statsUsers">
                <p class="has-text-centered title is-1">{{statsUsers.total }}</p>
            </div>
        </div>
    </div>
</div>
<ng-template #loading>
    <span [translate]="'loading'">loading...</span>
</ng-template>
