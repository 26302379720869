import { ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class OpportunityResolver  {
  constructor(private httpClient: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('id');
    return this.httpClient.get<any>("/api/opportunity/find-by-quote/" + id);
  }
}
