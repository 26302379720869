import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { clone, differenceWith, isEqual } from "lodash";
import { tap, map, take, filter } from "rxjs/operators";
import { flatMap, maxBy, minBy, sortBy } from "lodash";
import { CatalogService } from './catalog.service';
import { StoreService } from './store.service';

@Injectable({
    providedIn: 'root'
})
export class ProductService  {

    headers: HttpHeaders;

    private _currentVariantSubject: BehaviorSubject<any> = new BehaviorSubject({});
    private _currentProduct: any;

    private _allAttributesSubject: BehaviorSubject<any> = new BehaviorSubject(null);

    translations: any = {};

    constructor(private httpClient: HttpClient, private catalogService: CatalogService, private storeService: StoreService) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.headers = this.headers.set('Accept', 'application/json');

        this.storeService.translateService.stream(['without', 'withoutDriver']).subscribe((data) => {
            this.translations["without"] = data.without;
            this.translations["withoutDriver"] = data.withoutDriver;

        });

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        let id = route.paramMap.get('id');
        let variant = route.queryParams['variant'];
        let productObservable = this.httpClient.get<any>("/api/product/get-product?id=" + id);
        let attributeObservable = this.getAttributes();
        let obs = [productObservable, attributeObservable];
        return combineLatest(obs).pipe(
            tap((data) => {
                this._currentProduct = data[0];
                this.catalogService.setCurrentCategory(this._currentProduct.category);
                this.catalogService.setCurrentFamily(this._currentProduct.productFamily);
                if (variant) {
                    this.setCurrentVariant(variant);
                } else {
                    let attributes = data[1].filter(t => t.selectable && this._currentProduct.attributesSysName.indexOf(t.sysName) > -1);
                    if (this._currentProduct.ignoredAttributes)
                        attributes = attributes.filter(t => this._currentProduct.ignoredAttributes.indexOf(t.sysName) === -1);
                    let stdVariants = this._currentProduct.productsSelectableVariant.filter(t => t.attributes.findIndex(x => x.sysName === "standard" && x.value == true) > -1);
                    let standard = this.combineVariantAttributes(attributes, stdVariants);
                    this.setDefaultVariant(standard.length > 0 ? "standard" : "normal");
                }
            }),
            map(([x, y]) => x),
            take(1)
        );
    }

    getCurrentVariant(): Observable<any> {
        return this._currentVariantSubject.asObservable();
    }

    setCurrentVariant(variantId) {
        let variant = this._currentProduct.productsSelectableVariant.find(t => t.id === variantId);
        variant = this.tryFindVmsVariant(variant);
        this.setAutomaticDescription(variant);
        this._currentVariantSubject.next(variant);
    }

    setDefaultVariant(mode: string) {
        let variants = mode == "normal" ? this._currentProduct.productsSelectableVariant.filter(t => t.attributes.findIndex(x => x.sysName === "standard" && x.value == true) === -1) :
            this._currentProduct.productsSelectableVariant.filter(t => t.attributes.findIndex(x => x.sysName === "standard" && x.value == true) > -1);
        let preselectedVariant : any = undefined;

        if (variants && variants.length) {
            variants = sortBy(variants, ['qtaDisponibile'], ['desc']);
            preselectedVariant = variants.find(t => t.favourite === true);
            //non c'e' la preferita, cerco una anthology
            if (!preselectedVariant) {
                let validVariant = variants.filter(v => v.esWarning === false && v.anthology && v.qtaDisponibile > 0);
                for (let i = 0; i < validVariant.length && !preselectedVariant; ++i) {
                    var attr = validVariant[i].attributes.find(x => x.sysName === "pieces");

                    if (!attr || attr.value == 1) {
                        preselectedVariant = validVariant[i];
                    }
                }
            }
            //non c'e' nemmeno anthology, cerco una con quantita' disponibile
            if (!preselectedVariant) {
                let validVariant = variants.filter(v => v.esWarning === false && v.qtaDisponibile > 0);
                for (let i = 0; i < validVariant.length && !preselectedVariant; ++i) {
                    var attr = validVariant[i].attributes.find(x => x.sysName === "pieces");

                    if (!attr || attr.value == 1) {
                        preselectedVariant = validVariant[i];
                    }
                }
            }
            //neanche con quantita' disponibile, prendo la prima di quelle disponibili 
            if (!preselectedVariant) {
                preselectedVariant = variants.filter(v => v.esWarning === false)[0];
            }
        }


        if (!preselectedVariant) //should never happen
            preselectedVariant = this._currentProduct.productsSelectableVariant[0];

        preselectedVariant = this.tryFindVmsVariant(preselectedVariant);
        this.setAutomaticDescription(preselectedVariant);
        this._currentVariantSubject.next(preselectedVariant);
    }

    search(query: string, idBrand: string): Observable<any[]> {
        let params = new HttpParams().set('query', query);
        if(idBrand) {
            params = params.set("idBrand", idBrand)
        }
        return this.httpClient.get<any[]>('/api/product/search', { params: params });
    }

    getAccessories(): Observable<any[]> {
        let ids = this._currentVariantSubject.value.accessories;

        if (!ids || !ids.length) {
            return of([]);
        }
        return this.httpClient.post<any[]>('/api/product/get-accessories', JSON.stringify(ids), {
            headers: this.headers
        });
    }

    setVariantAttribute(sysName: string, value: any): [boolean, any[]] {
        let allpossibleVariants, possibleVariants = [];
        if (value === -1) {
            allpossibleVariants = this._currentProduct.productsSelectableVariant.filter(t => t.attributes.findIndex(x => x.sysName === sysName) === -1);

        }
        else {
            allpossibleVariants = this._currentProduct.productsSelectableVariant.filter(t => t.attributes.findIndex(x => x.sysName === sysName && x.value == value) > -1);

        }

        possibleVariants = clone(allpossibleVariants);
        let filtrabeAttributes = this._allAttributesSubject.value.filter(t => t.selectable && this._currentProduct.attributesSysName.indexOf(t.sysName) > -1 && t.sysName !== sysName);
        if (this._currentProduct.ignoredAttributes)
            filtrabeAttributes = filtrabeAttributes.filter(t => this._currentProduct.ignoredAttributes.indexOf(t.sysName) === -1);

        for (let attribute of filtrabeAttributes) {
            let currentVariantAttribute = this._currentVariantSubject.value.attributes.find(t => t.sysName === attribute.sysName);
            let currentVariantAttributeValue = currentVariantAttribute ? currentVariantAttribute.value : -1;
            if (currentVariantAttributeValue === -1) {
                possibleVariants = possibleVariants.filter(t => t.attributes.findIndex(x => x.sysName === attribute.sysName) === -1);
            } else {
                possibleVariants = possibleVariants.filter(t => t.attributes.findIndex(x => x.sysName === attribute.sysName && x.value == currentVariantAttributeValue) > -1);
            }
        }
        if (possibleVariants.length > 0) {
            let variant = possibleVariants[0];
            variant = this.tryFindVmsVariant(variant);
            this.setAutomaticDescription(variant);
            this._currentVariantSubject.next(variant);
            return [true, []];
        }

        return [false, this.getVariantDistance(allpossibleVariants)];
    }

    private setAutomaticDescription(variant: any) {
        //&& !t.selectable 
        let descriptableAttributes = this._allAttributesSubject.value.filter(t => t.generateDescription && variant.attributes.some(a => a.sysName == t.sysName));
        let automaticDescription = "";

        for (let attributeFor of descriptableAttributes) {
            let currentVariantAttribute = variant.attributes.find(t => t.sysName == attributeFor.sysName);
            if (attributeFor.preValues) {
                let prevalue = attributeFor.preValues.find(p => p.label && currentVariantAttribute.value.toString() == p.value.toString());
                if (prevalue) {
                    automaticDescription = automaticDescription.concat(attributeFor.name + ": " + prevalue.label + ", ");
                }
            } else {
                automaticDescription = automaticDescription.concat(attributeFor.name + ": " + currentVariantAttribute.value + ", ");
            }
        }
        if (automaticDescription.length > 2) {
            automaticDescription = automaticDescription.slice(0, -2);
            variant.automaticDescription = automaticDescription;
        }
    }

    getFiltrabeAttribute(): Observable<{ normal: any[], standard: any[] }> {
        return this.getAttributes().pipe(map(attrs => {
            let attributes = attrs.filter(t => t.selectable && this._currentProduct.attributesSysName.indexOf(t.sysName) > -1);
            if (this._currentProduct.ignoredAttributes) {
                attributes = attributes.filter(t => this._currentProduct.ignoredAttributes.indexOf(t.sysName) === -1);
            }

            let variants = this._currentProduct.productsSelectableVariant.filter(t => t.attributes.findIndex(x => x.sysName === "standard") === -1 || t.attributes.findIndex(x => x.sysName === "standard" && x.value == false) > -1);
            let stdVariants = this._currentProduct.productsSelectableVariant.filter(t => t.attributes.findIndex(x => x.sysName === "standard" && x.value == true) > -1);

            return {
                normal: this.combineVariantAttributes(attributes, variants),
                standard: this.combineVariantAttributes(attributes, stdVariants)
            };
        }));
    }

    private tryFindVmsVariant(variant) {
        if (!variant) return variant;
        const vms = this._currentProduct.productsSelectableVariant.filter(t => t.vms == true);
        if (variant.vms == false && vms && vms.length > 0) {
            let vmsArticle = vms.find(t => differenceWith(t.attributes, variant.attributes, isEqual).length == 0);
            if (vmsArticle)
                variant = vmsArticle;
        }
        return variant;
    }

    private combineVariantAttributes(attributes, variants): any[] {
        let result = [];
        if (variants.length == 0) {
            return result;
        }

        for (let attribute of attributes) {
            let attrModel = { name: attribute.name, sysName: attribute.sysName, values: [], order: attribute.order };
            for (let variant of variants) {
                let attr = variant.attributes.find(t => t.sysName === attribute.sysName);
                if (attr) {
                    let value = this.getAttributeValue(attribute, attr);
                    attrModel.values.push(value);
                }
            }
            attrModel.values = attrModel.values.filter((v, i, a) => a.indexOf(v) === i); // === distinct
            if (attrModel.values.filter(t => t !== 0).length > 0) {
                result.push(attrModel);
            }
        }
        return result;
    }

    private getAttributeValue(attribute, attrValue): any {
        if (attrValue)
            return attrValue.value;
        //else if (attribute.sysName == "pieces")
        //  return 1;
        else if (attribute.controlType == 2)
            return false;
        else if (attribute.controlType == 1)
            return 0;
        else
            return -1;
    }

    getRestoration(sku): Observable<any> {
        return this.httpClient.get<any>("/api/product/get-restoration/" + sku);
    }

    isAnthology(article = null): boolean {
        if (article == null) {
            if (!this._currentVariantSubject.value)
                return false;
            article = this._currentVariantSubject.value;
        }

        let attribute = article.attributes.find(t => t.sysName === "anthology");
        return attribute && attribute.value == true;
    }

    isSelectedAttributeValue(attribute: any, value: any): boolean {

        if (!this._currentVariantSubject.value)
            return false;

        let variantAttribute = this._currentVariantSubject.value.attributes.find(x => x.sysName == attribute.sysName);
        let variantAttributeValue = this.getAttributeValue(attribute, variantAttribute);

        return variantAttributeValue == value;
    }

    private getVariantDistance(possibleVariants: any[]): any[] {
        let aiAttributes = this._allAttributesSubject.value.filter(t => t.aiEnabled === true && this._currentProduct.attributesSysName.indexOf(t.sysName) > -1);
        for (let attribute of aiAttributes) {
            this.getAttributeDistance(attribute, possibleVariants);
        }
        return sortBy(possibleVariants, ['distance']);
    }

    private getAttributeDistance(attribute: any, possibleVariants: any[]): void {
        let currentVariantAttribute = this._currentVariantSubject.value.attributes.find(x => x.sysName === attribute.sysName);
        if (!currentVariantAttribute)
            return;
        switch (attribute.controlType) {
            case 1: {
                let allAttributesFlatten = flatMap(possibleVariants, t => t.attributes.filter(x => x.sysName === attribute.sysName));
                if (allAttributesFlatten.length === 0)
                    return;
                let maxValue = maxBy(allAttributesFlatten, 'value');
                let minValue = minBy(allAttributesFlatten, 'value');
                let attributeRange = maxValue.value - minValue.value;
                if (attributeRange === 0)
                    return;
                for (let variant of possibleVariants) {
                    if (!variant.distance)
                        variant.distance = 0;

                    let variantAttrValue = variant.attributes.find(t => t.sysName === attribute.sysName);
                    if (variantAttrValue) {
                        variant.distance = variant.distance + (Math.abs(variantAttrValue.value - currentVariantAttribute.value) / attributeRange);
                    } else {
                        //not found --> max distance
                        variant.distance = variant.distance + 1;
                    }
                }
                break;
            }
            case 2: {
                //boolean;
                for (let variant of possibleVariants) {
                    if (!variant.distance)
                        variant.distance = 0;

                    let variantAttrValue = variant.attributes.find(t => t.sysName === attribute.sysName);
                    if (variantAttrValue) {
                        let distance = currentVariantAttribute.value === variantAttrValue.value ? 0 : 1;
                        variant.distance = variant.distance + distance;
                    } else {
                        //not found --> max distance
                        variant.distance = variant.distance + 1;
                    }
                }
                break;
            }
            case 3: {
                //preValues;
                let allAttributesFlatten = flatMap(possibleVariants, t => t.attributes.filter(x => x.sysName === attribute.sysName));
                let maxValue = maxBy(allAttributesFlatten, x => this.getAiValue(attribute, x.value));
                if (!maxValue) {
                    maxValue = { value: 100 };
                }
                let minValue = minBy(allAttributesFlatten, x => this.getAiValue(attribute, x.value));
                if (!minValue) {
                    minValue = { value: 0 };
                }

                let attributeRange = maxValue.value - minValue.value;
                if (attributeRange === 0)
                    return;
                for (let variant of possibleVariants) {
                    if (!variant.distance)
                        variant.distance = 0;

                    let variantAttrValue = variant.attributes.find(t => t.sysName === attribute.sysName);
                    if (variantAttrValue) {
                        variant.distance = variant.distance + (Math.abs(this.getAiValue(attribute, variantAttrValue.value) - this.getAiValue(attribute, currentVariantAttribute.value)) / attributeRange);
                    } else {
                        //not found --> max distance
                        variant.distance = variant.distance + 1;
                    }
                }
                break;
            }
            default: {
                //free text;
                //we cannot compare
                break;
            }
        }
    }

    private getAiValue(attribute: any, value: any): number {

        let attributeValue = attribute.preValues.find(t => t.value == value);
        return attributeValue.aiValue;
    }

    getAttributes() {
        return this._allAttributesSubject.asObservable().pipe(filter(a => a && a != null && a.length > 0));
    }

    populateAttributes() {
        this.httpClient.get<any[]>("/api/product/get-attributes")
            .subscribe((t) => {
                this._allAttributesSubject.next(t);
            });
    }

    getAttributeName(sysName: string): Observable<string> {
        return this.getAttributes().pipe(
            map(t => t.find(x => x.sysName === sysName)),
            map(t => t.name)
        );
    }

    getAttributeValueLabel(sysName: string, value: any) {

        return this.getAttributes().pipe(map(x => {
            let fullAttribute = x.find(t => t.sysName === sysName);
            if (fullAttribute && fullAttribute.preValues) {
                let preValue = fullAttribute.preValues.find(t => t.value == value);
                if (preValue)
                    return preValue.label;
                else if (fullAttribute.sysName == "driver") {
                    return this.translations["withoutDriver"];
                }
                else
                    return this.translations["without"] + " " + fullAttribute.name;
            } else if (fullAttribute.controlType == 1 && value == 0) {
                return "N/A";
            }

            return value;
        }));
    }

    getSelectableAttributes(attributes: any[]) {
        return this.getAttributes().pipe(map(x => {
            const validSysNames = x.filter(t => t.selectable == true).map(t => t.sysName);
            return attributes.filter(t => validSysNames.indexOf(t.sysName) > -1);
        }));
    }

}
