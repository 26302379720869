import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, inject } from '@angular/core';
import { StoreService } from '../../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CheckoutService } from '../../checkout.service';
import { doc } from 'firebase/firestore';
import { docData, Firestore } from '@angular/fire/firestore';

@Component({
  selector: 'checkout-item-plain',
  templateUrl: './checkout-item-plain.component.html',
  styleUrls: ['./checkout-item-plain.component.css']
})
/** checkout-item-plain component*/
export class CheckoutItemPlainComponent implements OnInit, OnDestroy, OnChanges {

  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() item: any;
  @Input() quotationId: any;
  @Output() requestSuggest = new EventEmitter<any>();
  @Output() qtaDisponibileChange = new EventEmitter<any>();

  translations: any = {};

  showAccessories: boolean = false;

  prezzoFinale: number = 0;
  accessoryTotal: number = 0;
  firestore: Firestore = inject(Firestore);
  /** checkout-item-plain ctor */
  constructor(private checkoutService: CheckoutService, private storeService: StoreService) {

  }
  ngOnChanges(_changes: SimpleChanges): void {
    if (this.item) {
      this.calculatePrezzoFinale();
    }
  }

  ngOnInit(): void {

    let translationKeys = marker(['askArticleReplacement']);
    this.storeService.translateService.stream(translationKeys).pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.translations = data;
      });

    let docRef = doc(this.firestore, `articoli/${this.item.sku}`);
    docData(docRef).pipe(takeUntil(this.destroy$)).subscribe(doc => {
      if (!doc) return;
      let newQuantity = doc.quantity;
      if (newQuantity != this.item.entity.qtaDisponibile) {
        this.item.entity.qtaDisponibile = newQuantity;

        if (this.item.entity.qtaDisponibile && this.item.entity.qtaDisponibile > 0) {
          this.item.entity.qtaDisponibile = Math.floor(this.item.entity.qtaDisponibile);
        } else {
          this.item.entity.qtaDisponibile = 0;
        }
        this.qtaDisponibileChange.emit({ qtaAvailable: this.item.entity.qtaDisponibile, sku: this.item.sku, type: 'qtyChange' });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  calculatePrezzoFinale() {
    this.prezzoFinale = 0;
    if (this.item.type != 2) {
      if (this.checkoutService.isModalitaListino2()) {
        let inversoDiscount = (1 - (this.item.discount || 0) / 100) * (1 - (this.item.discount2 || 0) / 100) * (1 - (this.item.discount3 || 0) / 100);
        if (this.item.type == 3) {
          this.item.priceToEvaluate = this.item.custom.prezzoVendita * inversoDiscount
        } else {
          this.item.priceToEvaluate = this.item.prezzo * inversoDiscount;
        }
        this.prezzoFinale = (this.item.priceToEvaluate * this.item.quantity);
      } else {
        if (this.item.type == 3) {
          this.item.priceToEvaluate = this.item.custom.prezzoAcquisto;
        } else {
          this.item.priceToEvaluate = this.item.prezzoRiservato;
        }
        
        this.prezzoFinale = (this.item.priceToEvaluate * this.item.quantity);
      }
    }
    let counter = 0;

    if (this.item.childItems) {
      for (let cItem of this.item.childItems) {
        if (cItem.prezzo) {
          if (this.checkoutService.isModalitaListino2()) {
            let inversoDiscountChild = (1 - (cItem.discount || 0) / 100) * (1 - (cItem.discount2 || 0) / 100) * (1 - (cItem.discount3 || 0) / 100);
            cItem.priceToEvaluate = cItem.prezzo * inversoDiscountChild;
            counter +=  (cItem.prezzo * cItem.quantity * inversoDiscountChild);
          } else {
            cItem.priceToEvaluate = cItem.prezzoRiservato;
            counter += (cItem.prezzoRiservato * cItem.quantity);
          }
        }
      }
    }
    this.accessoryTotal = counter;
    this.prezzoFinale = counter + this.prezzoFinale;

  }

  toggleAccessories() {
    this.showAccessories = !this.showAccessories;
  }

  askSubstitute() {
    this.requestSuggest.emit({
      configId: this.item.configurationId,
      sku: this.item.sku
    });
  }
}
