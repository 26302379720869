import {
  Component,
  OnInit,
  Output,
  Input,
  OnDestroy,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { StatsService } from '../stats.service';
import { of, combineLatest, Subject, ReplaySubject } from 'rxjs';
import { switchMap, tap, takeUntil } from 'rxjs/operators';
import { formatCurrency } from '@angular/common';
import * as moment from 'moment';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { StoreService } from '../store.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-performance-cliente',
  templateUrl: './performance-cliente.component.html',
  styleUrls: ['./performance-cliente.component.scss']
})
export class PerformanceClienteComponent implements OnInit, OnDestroy, OnChanges {

  @Input() level: string;
  @Input() clientiAgente: any[]
  @Input() cliente: any
  @Output() selectedCliente = new EventEmitter<any>();

  private companySubject$: ReplaySubject<any> = new ReplaySubject<any>();

  today = new Date();
  statsEquilibrio: any
  loadingEquilibrio: boolean = false
  equilibrioChartData: any
  equilibrioChartOptions: any = {
    plugins: {
      
      legend: {
        position: 'bottom'
      },
      tooltip: { enabled: false },
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold'
        },
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
          });
          return (value * 100 / sum).toFixed(1) + "%";
        }
      }
    }
   
  }

  thresholdConfigQ = {
    '33': { color: 'red' },
    '66': { color: '#c6912f' },
    '100': { color: '#173554' }
  }
  thresholdConfigO = {
    '33': { color: 'red' },
    '66': { color: '#c6912f' },
    '100': { color: '#173554' }
  }
  statsPreventivi: any
  loadingPreventivi: boolean = false

  statsBudget: any;
  loadingBudget: boolean = false;

  statsOrdinato: any
  loadingOrdinato: boolean = false

  budgetChartData: any
  budgetChartOptions: any = {
    scales: {
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true
        }
      },
      x: {
        stacked: true,
      },
    },
    
    plugins: {
      legend: {
        position: 'bottom',
        display: false
      },
      tooltips: { enabled: false },
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold'
        },
        display: (ctx) => {
          if (ctx.dataIndex === 1)
            return false;
          let value = ctx.dataset.data[ctx.dataIndex];
          return value > 0;
        },
        formatter: (value) => {
          return formatCurrency(value, 'it-IT', '€', 'EUR');
        }
      }
    },
  }

  pluginsChart: any;

  deltaPercent : number = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private statsService: StatsService, private storeService: StoreService) {
  }

  ngOnInit() {
    this.pluginsChart = [ChartDataLabels];
    this.companySubject$.asObservable().pipe(
      tap(() => this.loadingEquilibrio = true),
      switchMap(company => {
        if (company && company.codCliFor) {
          return this.statsService.getPerformanceEquilibrioForCompany(company.codCliFor)
        } else {
          return of(null)
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.loadingEquilibrio = false;
      this.statsEquilibrio = data;

      this.equilibrioChartData = {
        labels: ['Alluminio', 'Prodotti'],
        datasets: [
          {
            label: 'Tipologia',
            data: [data ? data.totaleAlluminio : 0, data ? data.totaleProdotti : 0],
            backgroundColor: [
              "#173554",
              "#c6912f",
              "#dbdbdb"
            ],
            hoverBackgroundColor: [
              "#173554",
              "#bc892d",
              "#dbdbdb"
            ]
          }
        ]
      };
    })

    this.companySubject$.asObservable().pipe(
      tap(() => this.loadingPreventivi = true),
      switchMap(company => {
        if (company && company.codCliFor) {
          return this.statsService.getPerformancePreventiviForCompany(company.codCliFor)
        } else {
          return of(null)
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.loadingPreventivi = false
      this.statsPreventivi = data;
    });

    let budget = this.companySubject$.asObservable().pipe(
      tap(() => this.loadingBudget = true),
      switchMap(company => {
        if (company && company.codCliFor) {
          return this.statsService.getPerformanceBudgetForCompany(company.codCliFor)
        } else {
          return of(null)
        }
      }),
      tap(() => this.loadingBudget = false),
    );

    let ordinato = this.companySubject$.asObservable().pipe(
      tap(() => this.loadingOrdinato = true),
      switchMap(company => {
        if (company && company.codCliFor) {
          return this.statsService.getPerformanceOrdinatoForCompany(company.codCliFor)
        } else {
          return of(null)
        }
      }),
      tap(() => this.loadingOrdinato = false),
    );

    let translationRequired = marker(['current', 'target']);

    combineLatest([budget, ordinato, this.storeService.translateService.stream(translationRequired)]).pipe(
      takeUntil(this.destroy$))
      .subscribe(([budget, ordinato, trad]) => {

        this.statsBudget = budget;
        this.statsOrdinato = ordinato;

        let budgetIntraPresent = budget ? budget.budgetIntra * moment().dayOfYear() / 365 : null;  
        this.deltaPercent = 0;
        if(budgetIntraPresent && ordinato > 0) {
          let deltaFixed = ordinato - budgetIntraPresent;
          this.deltaPercent = deltaFixed/budgetIntraPresent;
        }

        this.budgetChartData = {
          labels: [trad['current'], trad['target']],
          datasets: [
            {
              data: [ordinato ? ordinato.importo : 0, budget ? budget.budgetIntra * moment().dayOfYear() / 365 : 0],
              backgroundColor: [
                "#c6912f",
                "#173554",
              ],
              hoverBackgroundColor: [
                "#bc892d",
                "#173554",
              ]
            }, {
              data: [null, budget ? (budget.budgetIntra - (budget.budgetIntra * moment().dayOfYear() / 365)) : 0],
              backgroundColor: [
                'rgba(23,53,84, 0.35)',
                'rgba(23,53,84, 0.35)'
              ],
              datalabels: {
                display: false
              }
            }
          ]
        }
      });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cliente) {
      this.companySubject$.next(changes.cliente.currentValue);
    }

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onSelectedCompany(company: any) {
    this.companySubject$.next(company);
    this.selectedCliente.emit(company);
  }
}
