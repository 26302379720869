import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  open = false;

  @Input()
  url: string;

  @Output()
  updateClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  openUploadDialog() {
    this.open = true;
  }

  manageOpening(event: boolean) {
    if (event) {
      this.updateClosed.emit(true);
    }
  }

}
