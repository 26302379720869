<div class="columns">
  <div class="column">
    <figure class="image is-square is-clickable">
      <img fallback-src="https://via.placeholder.com/500x500" id="image" [cdnImg]="currentImage"
        (click)="openGallery(0)" [productId]="currentVariant.productModel" query="width=600&height=600&mode=crop">
    </figure>
    <figure class="image is-16by9 tech-drawing is-clickable">
      <img fallback-src="https://via.placeholder.com/640x360" id="techdrawing" [cdnImg]="currentDrawing"
        [productId]="currentVariant.productModel" (click)="openFullScreen()" type="techdrawings"
        query="width=640&height=360&mode=crop">
    </figure>
  </div>
  <div class="column is-narrow">
    <div class="image-mini current" *ngFor="let image of currentVariant.images; let index = index;">
      <figure class="image is-square is-48x48 is-clickable">
        <img (click)="openGallery(index)" [cdnImg]="image" query="width=84&height=84&mode=crop"
          [productId]="currentVariant.productModel">
      </figure>
    </div>
  </div>
</div>
<p-dialog header="Immagini modello" [(visible)]="visibleImagesModello" [modal]="true" [style]="{ width: '90vw' }"
  [draggable]="false" [resizable]="false">
  <p-galleria [(value)]="currentVariant.images" [responsiveOptions]="responsiveOptions"
    [containerStyle]="{ 'max-width': '640px' }" [numVisible]="5" [activeIndex]="activeIndexGallery">
    <ng-template pTemplate="item" let-item>
      <img fallback-src="https://via.placeholder.com/500x500" id="image" [cdnImg]="item"
        [productId]="currentVariant.productModel" query="width=600&height=600&mode=crop" />
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
      <div>
        <img [cdnImg]="item" query="width=84&height=84&mode=crop" [productId]="currentVariant.productModel" />
      </div>
    </ng-template>
  </p-galleria>
</p-dialog>
<div
  class="p-element p-image-mask p-component-overlay p-component-overlay-enter is-flex is-justify-content-center is-align-items-center"
  style="z-index: 10000;" *ngIf="showFullScreenTech">
  <div style="position: absolute; top:1em; right:1em; z-index: 10001;">
    <button class="button is-large" style="border-radius: 100%!important;" (click)="closeFullScreen()">
      <span class="icon is-medium">
        <i class="fas fa-times fa-2x"></i>
      </span>
    </button>
  </div>
  <img class="p-image-preview" style="background-color: white; transform: rotate(0deg) scale(1);"
    fallback-src="https://via.placeholder.com/640x360" id="techdrawing_full" [cdnImg]="currentDrawing"
    [productId]="currentVariant.productModel" type="techdrawings" query="width=640&height=360&mode=crop">
</div>