<p-dialog [header]="translations.sendFiles" [(visible)]="open" [closable]="canBeClosed" [style]="{width: '500px'}" (onHide)="onDialogHide($event)">
  <input type="file" #file style="display: none"
         (change)="onFilesAdded()" multiple />
  <ng-container *ngIf="files.size > 0; else noFiles">
    <div *ngFor="let file of files">
      
      <div class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <h4>{{file.name}}</h4>
          </div>
        </div>

        <!-- Right side -->
        <div class="level-right">
          <div class="level-item">
            <span *ngIf="file.error" class="has-text-warning">{{file.error}}</span>
          </div>
          <div class="level-item">
            <a (click)="removeFile(file)"><i class="fas fa-trash"></i></a>
          </div>
        </div>
      </div>


      <p-progressBar  *ngIf="progresses" [value]="progresses[file.id].progress | async"></p-progressBar>
      <p-progressBar value="0" *ngIf="!progresses"></p-progressBar>
    </div>
  </ng-container>
  <ng-template #noFiles>
    <div [translate]="'noFileSelected'">
      Nessun file selezionato. Aggiungi file ora..
    </div>
  </ng-template>
  <p-footer>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <button [disabled]="uploading" class="button is-primary" (click)="addFiles()" [translate]="'addFiles'">
          </button>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button" *ngIf="showCancelButton" (click)="close()" [translate]="'cancel'">Cancel</button>
        </div>
        <div class="level-item">
          <button class="button is-link" [disabled]="filesHasError || !canBeClosed || files.size === 0" (click)="closeDialog()">{{primaryButtonText}}</button>
        </div>
      </div>
    </div>
  </p-footer>
</p-dialog>
