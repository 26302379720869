import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ListService } from "../list.service";
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { StoreService } from '../store.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.css']
})
export class ListsComponent implements OnInit {

  newList: string;
  addListMode: boolean = false;

  newQuotation: string;
  addQuotationMode: boolean = false;

  claims: any;
  viewer: boolean = false;

  currentList: Observable<any>;


  constructor(private route: ActivatedRoute, private router: Router, private storeService: StoreService,
    private authService: AuthenticationService, public listService: ListService) {

  }

  ngOnInit() {
    this.claims = this.authService.getUserProfile();
    this.viewer = this.claims.view_only && this.claims.view_only == "true";

    this.currentList = this.listService.getCurrentList();

    if (!this.route.firstChild) {
      this.currentList.pipe(take(1)).subscribe(t => {
        if (t.type == 0)
          this.router.navigate(this.storeService.createUrl(['/lists', t.id]) as any[]);
        else
          this.router.navigate(this.storeService.createUrl(['/quotations', t.id]) as any[]);
      });
    }
  }



  goToList(list: any) {
    this.listService.setCurrentList(list);
  }

  goToQuotation(quotation: any) {
    this.listService.setCurrentList(quotation);
  }

  createList() {
    this.listService.createUserList(this.newList).subscribe(x => {
      this.newList = null;
      this.addListMode = false;
      this.router.navigate(this.storeService.createUrl(["/lists", x[x.length - 1].id]) as any[])
    });
  }

  addList(start) {
    this.addListMode = !!start;
  }

  createQuotation() {
    this.listService.createQuotationsList(this.newQuotation).subscribe(x => {
      this.newQuotation = null;
      this.addQuotationMode = false;
      this.router.navigate(this.storeService.createUrl(["/quotations", x[0].id]) as any[])
    });
  }

  addQuotation(start) {
    this.addQuotationMode = !!start;
  }
}
