<div class="sticky-top" *ngIf="baseQuotation">
  <div class="columns">
    <div class="column">
      <h1 class="title is-4" *ngIf="!editTitleMode">
        {{baseQuotation.name}}
        <a (click)="popperQuote.toggle($event)" 
          *ngIf="ownQuotation && baseQuotation.listState == 0" class="has-text-weight-semibold is-size-6">
          <i class="fas fa-fw fa-lg fa-angle-down"></i>
        </a>
      </h1>
      <form *ngIf="editTitleMode">
        <div class="field has-addons">
          <div class="control">
            <input class="input is-medium" type="text" [(ngModel)]="newTitle" name="newTitle">
          </div>
          <div class="control">
            <button type="submit" class="button is-info is-medium" [disabled]="!newTitle" (click)="updateTitle()">
              <i class="fas fa-check"></i>
            </button>
          </div>
          <div class="control">
            <button type="submit" class="button is-medium" (click)="editTitle(false)">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </form>
      <div class="subtitle is-4" style="margin-bottom: 0">
        <span *ngIf="shopMode == 0">
          <span [translate]="'total'">Totale</span>&nbsp;{{listTotalReserved | currency:'EUR'}}
          <span *ngIf="listMargin">({{listMargin | percent:'1.0-1'}})</span>
        </span>
        <span *ngIf="shopMode == 1">
          <span [translate]="'total'">Totale</span>&nbsp;{{listTotalPublic | currency:'EUR'}}
        </span>
      </div>
      <div class="is-size-6" *ngIf="userOwnerOfQuotation">
        <div>
          <span
            [translate]="'createdBy'"></span>&nbsp;{{userOwnerOfQuotation.name}}&nbsp;{{userOwnerOfQuotation.familyName}}
        </div>
        <div>
          <span>Email</span>&nbsp;{{userOwnerOfQuotation.email}}
        </div>
      </div>
      <div class="is-size-6">
        <span [translate]="'rev'"></span>&nbsp;<span>
          {{baseQuotation.currentRevision | number:'2.0-0'}} / {{baseQuotation.updateOn | date:'dd-MM-yy'}}
        </span>
        <ng-container *ngIf="canModify">
          <a (click)="popperRevision.toggle($event)" *ngIf="baseQuotation.revisions?.length"
            class="has-text-weight-semibold">
            <i class="fas fa-fw fa-lg fa-angle-down"></i>
          </a>
          <p-overlayPanel #popperRevision>
            <h2 class="title is-5"><span [translate]="'revisions'"></span>&nbsp;<i
                class="fas fa-circle-notch fa-spin fa-fw" *ngIf="settingRevision"></i></h2>
            <a (click)="popperRevision.hide(); setRevision(revision.rev);" class="has-text-dark"
              *ngFor="let revision of baseQuotation.revisions">
              <span [translate]="'rev'"></span>&nbsp;{{revision.rev | number:'2.0-0'}} / {{revision.updateOn |
              date:'dd-MM-yy HH:mm'}}<br />
            </a>
          </p-overlayPanel>
          <br>
          <a (click)="addRevision()" class="is-size-7">
            <i class="fas fa-plus fa-fw fa-sm"></i>
            <span [translate]="'addRevision'">Aggiungi revisione</span>
          </a>
          <i class="fas fa-circle-notch fa-spin fa-fw" *ngIf="addingRevision"></i>
          <ng-container *ngIf="baseQuotation.revisions?.length > 0 && (baseQuotation.revisions | sizeRevisionsArray:baseQuotation.currentRevision) > 0">
            &nbsp;
            <a (click)="deleteRevision(baseQuotation.currentRevision)" class="is-size-7">
              <i class="fas fa-trash fa-fw fa-sm"></i>
              <span [translate]="'deleteRevision'">Elimina revisione</span>
            </a>
          </ng-container>
          <i class="fas fa-circle-notch fa-spin fa-fw" *ngIf="deletingRevision"></i>

        </ng-container>
      </div>
    </div>
    <div class="column" *ngIf="opportunityAllowed">
      <div class="field has-addons" *ngIf="isAdmin">
        <div class="control is-expanded ui-fluid company">
          <p-autoComplete [suggestions]="searchResults" (completeMethod)="searchCompanies($event)" [(ngModel)]="company"
            (onSelect)="onSelect($event)" [field]="MySelectCompanyConvertion" [inputStyleClass]="'input is-medium'"
            appendTo="body" [minLength]="2" [disabled]="baseQuotation.listState != 0" [forceSelection]="true"
            [emptyMessage]="translations.noCompanyFound" [placeholder]="translations.backofficeFor">
            <ng-template let-comp pTemplate="item">
              <div>
                <div class="title is-6">{{comp.ragSoc}}</div>
                <div class="subtitle is-7"><span [translate]="'codCli'"></span> {{comp.codCliFor}}</div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
        <div class="control">
          <button type="submit" class="button is-medium is-info" [ngClass]="{'is-loading' : companyLoading }"
            (click)="setQuotationCompany()" [disabled]="baseQuotation.listState != 0 || !company" [translate]="'set'">
          </button>
        </div>
      </div>
      <div class="field has-addons" *ngIf="isAgent">
        <div class="control is-expanded ">
          <div class="select is-medium is-fullwidth">
            <select [(ngModel)]="codCliFor" [disabled]="baseQuotation.listState != 0">
              <option [ngValue]="null" disabled selected>
                {{translations.backofficeFor}} {{company?.ragSoc}}
              </option>
              <option *ngFor="let dealer of searchResults" [value]="dealer.codCliFor">{{dealer.ragSoc}}</option>
            </select>
          </div>
        </div>
        <div class="control">
          <button type="submit" class="button is-medium is-info" (click)="setQuotationCompany()"
            [disabled]="baseQuotation.listState != 0 || !codCliFor" [translate]="'set'">
            Imposta
          </button>
        </div>
      </div>
      <span class="has-text-info has-text-weight-semibold has-text-centered">
        <input class="is-checkradio is-info is-small" id="shareWithCompany" type="checkbox" name="shareWithCompany"
          [(ngModel)]="baseQuotation.shareWithCompany"
          [disabled]="!baseQuotation.assignedCodCliFor || baseQuotation.listState != 0">
        <label for="shareWithCompany" [translate]="'shareWithCompany'">Condividi con l'azienda</label>
      </span>
    </div>
    <div class="column is-narrow">
      <span class="button is-medium is-static" style="width: 100%;"
        *ngIf="baseQuotation.listState == 1; else buttonProceed">
        <span [translate]="'inProgress'">In lavorazione</span>&nbsp;&nbsp;<i class="fas fa-hourglass-start"></i>
      </span>
      <a class="button  is-small is-warning button-pdf" [routerLink]="['/quotations', baseQuotation?.id, 'pdf'] | localize"
        [translate]="'pdfQuotation'">
        Pdf Offerta
      </a>
      <a class="button is-small is-warning button-pdf" [routerLink]="['/quotations', baseQuotation?.id, 'brochure'] | localize"
        [translate]="'printBrochure'">Stampa Brochure</a>
      <a class="button  is-small is-warning button-pdf" *ngIf="isAdmin"
        [routerLink]="['/quotations', baseQuotation?.id, 'retail'] | localize" [translate]="'printPdfRetail'">Pdf
        Retail</a>
    </div>
  </div>
  <div class="tabs is-boxed" style="margin-bottom: 0px !important;">
    <ul>
      <li routerLinkActive="is-active" [routerLinkActiveOptions]="{exact:true}">
        <a [routerLink]="['/quotations', baseQuotation?.id] | localize" [translate]="'quotation'">Preventivo</a>
      </li>
      <li routerLinkActive="is-active">
        <a [routerLink]="['/quotations', baseQuotation?.id, 'customer'] | localize"
          [translate]="'header'">Intestazione</a>
      </li>
      <li routerLinkActive="is-active">
        <a [routerLink]="['/quotations', baseQuotation?.id, 'conditions'] | localize" [translate]="'condition'"></a>
      </li>
      <li routerLinkActive="is-active" *ngIf="opportunityAllowed">
        <a [routerLink]="['/quotations', baseQuotation?.id, 'opportunity'] | localize">
          <span [translate]="'opportunity'">Opportunità</span><span *ngIf="opportunity$ | async">
            &nbsp;<i class="fas fa-fw fa-link"></i>
          </span>
        </a>
      </li>
      <li routerLinkActive="is-active" *ngIf="canModify">
        <a [routerLink]="['/quotations', baseQuotation?.id, 'project'] | localize">
          <span [translate]="'registerProject'">Registra progetto</span><span *ngIf="baseQuotation.statusProject == 2">
            &nbsp;<i class="far fa-fw fa-registered"></i>
          </span>
        </a>
      </li>
      <li routerLinkActive="is-active">
        <a [routerLink]="['/quotations', baseQuotation?.id, 'attachments'] | localize"
          [translate]="'attachments'">Allegati</a>
      </li>
      <li routerLinkActive="is-active">
        <a [routerLink]="['/quotations', baseQuotation?.id, 'share'] | localize" [translate]="'share'">Condividi</a>
      </li>
    </ul>
  </div>
  <p-overlayPanel #popperQuote>
    <a (click)="editTitle(true)" class="has-text-dark is-block" [translate]="'rename'">Rinomina</a>
    <a (click)="duplicateQuotation()" class="has-text-dark is-block" [translate]="'duplicate'">Duplica</a>
    <a *ngIf="isAdmin && !baseQuotation.useOldListino" (click)="forceQuotationToUseOldListino()" 
    class="has-text-danger is-block" [translate]="'useOldListinoInThisQuotation'"></a>
    <a *ngIf="canModify" (click)="archive(baseQuotation?.id)" class="has-text-danger is-block" [translate]="'archive'">Archivia</a>
  </p-overlayPanel>
</div>
<div class="sticky-header" *ngIf="differenceTest">
  <div class="container">
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item" [translate]="'warningUnsavedChanges'">
          Attenzione: ci sono modifiche non salvate.
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button" [translate]="'cancel'" (click)="cancel()" [ngClass]="{'is-loading': isSending2}">
            Annulla
          </button>
        </div>
        <div class="level-item is-marginless">
          <div class="field is-grouped">
            <p class="control">
              <button class="button is-link" [ngClass]="{'is-loading': isSending}" (click)="saveQuotation()"
                [translate]="'save'">
                Salva
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
<div class="columns is-vcentered" style="margin-top:1.5em" *ngIf="baseQuotation && showSecProceedWithOrder">
  <div class="column">
  </div>
  <div class="column">
    <span class="has-text-danger is-italic" *ngIf="baseQuotation?.invalid" [translate]="'totalMayBeDifferent'">I totali
      potrebbero differire poichè una o più articoli non sono validi<br></span>
    <span class="is-italic" [translate]="'noTaxInclude'">Gli importi si intendono tutti IVA esclusa</span>
  </div>
  <div class="column has-text-right">
    <span class="button is-medium is-static" *ngIf="baseQuotation.listState == 1; else buttonProceed">
      <span [translate]="'inProgress'">In lavorazione</span>&nbsp;&nbsp;<i class="fas fa-hourglass-start"></i>
    </span>
  </div>
</div>

<ng-template #buttonProceed>
  <div [pTooltip]="tooltipQuote" tooltipPosition="left" [tooltipDisabled]="tooltipDisabled"
    tooltipStyleClass="m-tooltip" *ngIf="canModify">
    <button class="button is-medium is-link" [disabled]="buttonOrderDisabled" (click)="checkout(baseQuotation)">
      <span [translate]="'proceedWithOrder'">Procedi con l'ordine</span>&nbsp;&nbsp;<i class="fas fa-arrow-right"></i>
    </button>
  </div>
  <span class="button is-medium is-static" *ngIf="baseQuotation.listState == 1">
    <span [translate]="'inProgress'">In lavorazione</span>&nbsp;&nbsp;<i class="fas fa-hourglass-start"></i>
  </span>

</ng-template>

<p-confirmDialog key="confirmProjectAutoCancel" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmDialog key="confirmModifyQuotationShared" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-confirmDialog key="confirmNotLastRevision" icon="pi pi-exclamation-triangle"></p-confirmDialog>