import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { BehaviorSubject, of } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators'
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { AuthenticationService } from './services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private _hubConnection: signalR.HubConnection;
  private _userId: string;
  private _currentProjectId: string;

  private _onlineUsers: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  onlineUsers$ = this._onlineUsers.asObservable();

  constructor(private authenticationService: AuthenticationService, private eventService: PublicEventsService, 
    private router: Router, private traslateService: TranslateService, private storeService: StoreService) {
    
  }

  public startConnection = (userId: string, accessToken: string) => {
    this._userId = userId;

    this.eventService.registerForEvents().pipe(filter((ev) => ev.type == EventTypes.NewAuthenticationResult))
    .pipe(mergeMap(() => {
      if(this._hubConnection && this._hubConnection.state == signalR.HubConnectionState.Connected) {
        this._hubConnection.stop().then(() => console.log("SignalR connection stopped but will riconnect"));
      }
      if(this.authenticationService.isAuthenticated()) {
        return this.authenticationService.getAccessToken().pipe(take(1))
      } else {
        return of("")
      }
      }))
      .subscribe((accessToken) => {
        if(accessToken) {
          this.startConnectionPrivate(accessToken);
        }
    });

    this.startConnectionPrivate(accessToken);
    this.authenticationService.isAuthenticated$.pipe(filter((state) => !state)).subscribe(() => {
      if(!this._hubConnection || this._hubConnection.state != signalR.HubConnectionState.Connected) return;
      this._hubConnection.stop().then(() => console.log("SignalR connection stopped"));;
    });

    this._hubConnection.on("navigateByUrl", url => {
      this.router.navigateByUrl(this.storeService.createUrl(url) as string);
    });

    this._hubConnection.on("showError", error => {
      this.traslateService.get(error).subscribe(message => alert(message));
    });

    this._hubConnection.on("reloadProfile", () => {
      this.authenticationService.forceReload();
    });
  }

  public getConnectionId(): string {
    return this._hubConnection?.connectionId
  }

  private startConnectionPrivate (accessToken: string) {
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('/hub', { accessTokenFactory: () => { 
        let token = accessToken;
        return token;
      }})
      .build();

    this._hubConnection
      .start()
      .then(() => { 
        console.log('Signalr Connection started') 
      })
      .catch(err => { 
        console.log('Error while starting connection: ' + err) 
      });
  }
}