<div class="pt-3" style="height: 100vh;">
    <div class="container mb-3 is-flex is-justify-content-center is-align-items-center" style="height: calc(100% - 0.75rem); background-color: white;">
        <div class="block">
            <div class="has-text-centered title is-1">Progetto approvato</div>
            <div class="is-flex is-align-items-center mx-6">
                <div class="has-text-success">
                    <i class="fa-solid fa-thumbs-up fa-10x"></i>
                </div>
                <div class="ml-3 has-text-success is-size-3">
                    Hai approvato il progetto per il preventivo {{nameProject}} con lo sconto del {{discount}}%
                </div>
            </div>
        </div>
    </div>
</div>