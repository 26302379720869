import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { List } from 'immutable';
import { FileSaverService } from 'ngx-filesaver';
import { ConfirmationService, MessageService } from 'primeng/api';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil, tap, filter } from 'rxjs/operators';
import { CatalogService } from '../catalog.service';
import { ListService } from "../list.service";
import { PdfService } from '../pdf.service';
import { ProductService } from "../product.service";
import { StoreService } from '../store.service';
import { UserPreferenceService } from "../user-preference.service";
import { ReturnTo } from '../shared/costants';
import { SharedService } from '../shared.service';
import { concat } from 'lodash';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit, OnDestroy {

  @ViewChild('familyPopperContent', { static: true }) familyPopperContent: any;

  product: any;
  //category$: Observable<any>;
  family: any;
  currentVariant: any;
  categoryAndFamily$: Observable<any>;

  hasComponentsWarning: boolean = false;

  //quantity: number = 1;

  tmpItems: List<any>;
  selectedArticle: List<any>;
  selectedAccessories: List<any>;
  configurationEdit: boolean = false;

  familyPopperModel: any;

  favourites: any[];
  isInFavoutites: boolean = false;

  currentList: any;

  displayAlert: boolean = false;

  isDownloadingBrochure: boolean;

  isAdding: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  translations: any = {};

  displayAlert1: boolean = true;
  displayAlert2: boolean = true;
  displayAlert3: boolean = true;

  oldConfigIdToRemove: string;
  isSubstitutingArticle: boolean;

  navigation: Navigation;

  accSubstituteWarning: boolean;

  paramReturn = {
    mainRoute: "",
    typeCheckOut: "",
    idProduct: ""
  };
  returnTo: any[] = [];

  suggestArticle: any[] = [];
  suggestLoaded: boolean = false;

  currentBrandId: string;

  aiPopperSubstiteVisibile: boolean = false;

  constructor(private route: ActivatedRoute, private catalogService: CatalogService, private httpClient: HttpClient, private messageService: MessageService,
    private productService: ProductService, private listService: ListService, private router: Router, private confirmationService: ConfirmationService, private sharedService: SharedService,
    private fileSaverService: FileSaverService, private userService: UserPreferenceService, private pdfService: PdfService, private storeService: StoreService) {
    this.navigation = this.router.getCurrentNavigation();

  }

  ngOnInit() {
    let translationRequired = marker(['removeFromFavourite', 'AddToFavourite', 'warningAllUpper', 'errorCreatingPdf', 'confirmWithoutMandatoryComponents',
      'modifyQuotationProjectRegistered', 'proceed', 'cancel', 'confirmSharedQuotationChange', 'quantityError', 'error', 'yes', 'no']);

    this.catalogService.getCurrentBrandId().pipe(takeUntil(this.destroy$)).subscribe(brandId => {
      this.currentBrandId = brandId;
    });

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.product = this.route.snapshot.data.product;
    this.userService._userDetailObs.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data) {
        this.favourites = data.favorites || [];
        this.isInFavoutites = this.favourites.findIndex(t => t.link == "/product/" + this.product.id) > -1;
      }
    });

    this.oldConfigIdToRemove = this.route.snapshot.queryParams['oldConfigId'];
    this.isSubstitutingArticle = this.route.snapshot.queryParams['returnTo'] != undefined && !this.route.snapshot.params['configurationId'];
    let returnParam = this.route.snapshot.queryParams['returnTo'];

    this.listService.setCurrentConfiguration(this.route.snapshot.params['configurationId']);

    this.productService.getCurrentVariant().pipe(takeUntil(this.destroy$)).subscribe(variant => {
      this.currentVariant = variant;
      if (this.isSubstitutingArticle) { //se ho un punto di ritorno allora innesco tutti i comportamenti
        this.listService.removeConfigurationItem({ type: 0 });
        variant.selected = true;

        variant.quantity = this.route.snapshot.queryParams['q'] ? this.route.snapshot.queryParams['q'] : 1;
        let items = [];
        items.push({ referenceId: variant.id, sku: variant.sku, quantity: variant.quantity, type: 0 })
        let accessoriestoInject = window.history.state.accessories;
        this.accSubstituteWarning = false;
        if (accessoriestoInject) {
          for (let accessoryInject of accessoriestoInject) {

            let indexAcc = variant.accessories.findIndex(a => a == accessoryInject.referenceId);
            if (indexAcc != -1) {
              items.push({ referenceId: accessoryInject.referenceId, sku: accessoryInject.sku, quantity: accessoryInject.quantity, type: 1 })
            } else {
              this.accSubstituteWarning = true;
            }
          }
        }

        this.listService.addOrUpdateConfigurationItems(items);
      }
    });

    this.categoryAndFamily$ = combineLatest([this.catalogService.getCurrentCategory(),
    this.catalogService.getCurrentFamily(), this.catalogService.getApplications()])
      .pipe(tap(([_cat, t, applications]) => {
        let applicationIdToHide = applications.filter(a => a.hiddenCatalog).map(a => a.id);
        this.family = t;
        if (this.family && this.family.productsModel) {
          if (!applicationIdToHide.some(id => id == this.product.application)) {
            this.family.productsModel = this.family.productsModel.filter(x => !applicationIdToHide.some(id => id == x.application));
          }
        }
      }));

    this.paramReturn = {
      mainRoute: "",
      typeCheckOut: "",
      idProduct: ""
    };

    //this.category$ =  this.catalogService.getCurrentCategory().pipe(takeUntil(this.destroy$));
    //this.catalogService.getCurrentFamily().pipe(takeUntil(this.destroy$)).subscribe(x => this.family = x);
    this.listService.getCurrentList().pipe(takeUntil(this.destroy$), filter(l => l)).subscribe(t => {
      this.currentList = t;
      if (returnParam) {
        switch (returnParam) {
          case ReturnTo.quotation:
            this.paramReturn.mainRoute = "quotations";
            this.paramReturn.typeCheckOut = "";

            break;
          case ReturnTo.list:
            this.paramReturn.mainRoute = "lists";
            this.paramReturn.typeCheckOut = "";
            break;
          case ReturnTo.quotationCheckout3:
            this.paramReturn.mainRoute = "quotations";
            this.paramReturn.typeCheckOut = "checkout";

            break;
          case ReturnTo.quotationCheckoutPlain:
            this.paramReturn.mainRoute = "quotations";
            this.paramReturn.typeCheckOut = "checkout-plain";
            break;
          case ReturnTo.product:
            this.paramReturn.mainRoute = "product";
            let returnParamId = this.route.snapshot.queryParams['returnToId'];
            if (returnParamId) {
              this.paramReturn.idProduct = returnParamId;
            } else {
              this.paramReturn.idProduct = this.product.id;
            }
            break

          default:
            if (this.currentList.type == 0) {
              this.paramReturn.mainRoute = "lists";
            } else {
              this.paramReturn.mainRoute = "quotations";
            }
            break;
        }
      } else {
        this.paramReturn.mainRoute = "product";
        this.paramReturn.idProduct = this.product.id;
      }

      if (this.paramReturn.idProduct) { //in teoria solo per retrocompatibilita'
        this.returnTo = this.storeService.createUrl([this.paramReturn.mainRoute, this.paramReturn.idProduct]) as any[];
      } else {
        this.returnTo = this.storeService.createUrl([this.paramReturn.mainRoute, this.currentList.id, this.paramReturn.typeCheckOut]) as any[];
      }
    });
    this.listService.getCurrentConfiguration().pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.tmpItems = t;
      this.selectedArticle = t.filter(x => x.type == 0).toList();
      this.selectedAccessories = t.filter(x => x.type == 1).toList();
    });
    if (this.route.snapshot.params['configurationId'])
      this.configurationEdit = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  goto(model) {
    this.familyPopperContent.hide();
    this.router.navigate(this.storeService.createUrl(['/product', model.id]) as any[]);
  }

  cancelAdd() {
    this.listService.resetCurrentConfiguration();
    this.isAdding = false;
    if (this.isSubstitutingArticle || (this.currentList && this.configurationEdit)) {
      this.router.navigate(this.returnTo);
    }
  }

  addToList(reset: boolean) {
    if (this.currentList.listState != 0) {
      this.displayAlert = true;
      return;
    }
    this.showDialogAlert(reset);
  }

  selectFamilyPopper(model: any) {
    this.familyPopperModel = model;
  }

  onfamilyPopperShown(event) {
    this.familyPopperModel = this.product;
  }

  downloadBrochure() {
    this.isDownloadingBrochure = true;
    this.pdfService.printBrochureProduct(this.product.id).subscribe({
      next: (data) => {
        this.isDownloadingBrochure = false;
        if (data.size > 0) {
          this.fileSaverService.save(data, `Brochure ${this.product.name}.pdf`);
        } else {
          alert(this.translations["errorCreatingPdf"]);
        }
      },
      error: (_error: any) => {
        this.isDownloadingBrochure = false;
        alert(this.translations["errorCreatingPdf"]);
      }
    });
  }

  download(filetype) {
    const file = this.product.files.find(t => t.sysName == filetype);
    if (!file) return;
    const url = `/api/catalog/download/${this.product.id}/file/${file.value}`;
    this.httpClient.get(url, { responseType: 'blob' }).subscribe((blob) => {
      this.fileSaverService.save(blob, `${this.product.name}-${file.value}`);
    });
  }

  toggleFavourite() {
    if (!this.isInFavoutites) {
      let data = {
        name: this.product.name,
        link: "/product/" + this.product.id,
        brandId: this.currentBrandId
      };
      this.favourites = concat(this.favourites, data);
      this.isInFavoutites = true;
    } else {
      this.favourites = this.favourites.filter(t => t.link !== "/product/" + this.product.id);
      this.isInFavoutites = false;
    }
    this.userService.updateListFavorites(this.favourites);
  }

  setComponentsWarning(value: boolean) {
    this.hasComponentsWarning = value;
  }

  //il metodo si occupa di mostrare i vari dialog. Se una condizione si verifica lo mostra e blocca il proseguire del metodo ritornando.
  //Se l'alert riceve risposta positiva viene richiamato il metoodo stesso a true
  private showDialogAlert(reset: boolean,
    showUnregisterProjAlert: boolean = false, showOkCompanyAlert: boolean = false,
    showAlertMandatoryComponent: boolean = false) {
    if (!showUnregisterProjAlert && this.currentList.statusProject && (this.currentList.statusProject == 1 || this.currentList.statusProject == 2)) {
      this.confirmationService.confirm({
        key: 'confirmDialogProduct',
        header: this.translations["warningAllUpper"],
        message: this.translations["modifyQuotationProjectRegistered"],
        acceptLabel: this.translations["proceed"],
        rejectLabel: this.translations["cancel"],
        accept: () => {
          this.isAdding = true;
          this.listService.unRegisterProject(this.currentList).subscribe(() => {
            this.isAdding = false;
            this.showDialogAlert(reset, true, false, false);
          }, () => { this.isAdding = false });
        },
        reject: () => {
          return;
        }
      });
      return;
    }
    if (!showOkCompanyAlert && this.currentList.shareWithCompany) {
      this.confirmationService.confirm({
        key: 'confirmDialogProductProject',
        header: this.translations["warningAllUpper"],
        message: this.translations["confirmSharedQuotationChange"],
        acceptLabel: this.translations["yes"],
        rejectLabel: this.translations["no"],
        accept: () => {
          this.showDialogAlert(reset, true, true, false);
        },
        reject: () => {
          return;
        }
      });
      return;
    }

    if (!showAlertMandatoryComponent && this.hasComponentsWarning) {
      this.confirmationService.confirm({
        key: 'confirmDialogProductNoComponent',
        header: this.translations["warningAllUpper"],
        message: this.translations["confirmWithoutMandatoryComponents"],
        acceptLabel: this.translations["yes"],
        rejectLabel: this.translations["no"],
        accept: () => {
          this.showDialogAlert(reset, true, true, true);
        },
        reject: () => {
          return;
        }
      });
      return;
    }

    let obs = this.listService.addToList(reset, this.oldConfigIdToRemove);
    if (!obs) {
      this.messageService.add({ key: 'toastErrorProduct', severity: 'error', summary: this.translations["error"], detail: this.translations["quantityError"] });
      return;
    }
    this.isAdding = true;
    obs.subscribe({
      next: (t) => {
        this.isAdding = false;
        if (reset && this.currentList) {
          this.router.navigate(this.returnTo);
        }
      }, error: () => { this.isAdding = false }
    });
  }

  obtainReplacement(event: number) {
    this.suggestLoaded = false;
    this.aiPopperSubstiteVisibile = true;
    this.sharedService.getSubstitute(this.route.snapshot.params['configurationId'],
      this.currentVariant.id, this.currentList.id, event, this.currentList.type).subscribe((data) => {
        this.suggestArticle = data;
        this.suggestLoaded = true;
      });

  }

  itemSubstituteSelect(event: any) {
    if (!event.suggestedItem) {
      this.suggestLoaded = false;
      this.suggestArticle = [];
      this.aiPopperSubstiteVisibile = false;
      return;
    }

    let suggestedItem = event.suggestedItem;
    let item = this.currentVariant;

    let quantityToSubmit = item.quantity;

    let indexPiecesItem = item.attributes.findIndex(at => at.sysName == 'pieces');
    if (indexPiecesItem != -1 && item.attributes[indexPiecesItem].value) {
      quantityToSubmit = quantityToSubmit * item.attributes[indexPiecesItem].value;
    }

    let indexPiecesSubstituteItem = suggestedItem.attributes.findIndex(at => at.sysName == 'pieces');
    if (indexPiecesSubstituteItem != -1 && suggestedItem.attributes[indexPiecesSubstituteItem].value) {
      quantityToSubmit = Math.ceil(quantityToSubmit / suggestedItem.attributes[indexPiecesSubstituteItem].value);
    }
    let acc = this.tmpItems.toArray().filter(t => t.type == 1);

    let returnToParam: string;
    if (this.paramReturn.idProduct) {
      returnToParam = ReturnTo.product;
    } else if (this.paramReturn.typeCheckOut) {
      returnToParam = this.paramReturn.typeCheckOut == 'checkout' ? ReturnTo.quotationCheckout3 : ReturnTo.quotationCheckoutPlain;
    } else {
      returnToParam = this.currentList.type == 0 ? ReturnTo.list : ReturnTo.quotation;
    }


    this.router.navigate(this.storeService.createUrl(['/product', suggestedItem.productModel]) as any[], {
      queryParams: {
        variant: suggestedItem.id,
        oldConfigId: this.route.snapshot.params['configurationId'],
        q: quantityToSubmit,
        returnTo: returnToParam,
        returnToId: this.paramReturn.idProduct ? this.paramReturn.idProduct : undefined
      },
      state: {
        accessories: acc
      }
    });

  }
}
