<h2 class="title-hr is-size-3 is-uppercase font-condensed has-text-weight-bold">
  <span><span [translate]="'performance'">Performance</span>&nbsp;Macrolux</span>
</h2>

<!-- Admin sceglie i clienti con un autocomplete -->
<div class="field" *ngIf="level=='admin'">
  <div class="control ui-fluid">
    <div class="control ui-fluid">
      <app-search-companies [company]="cliente" (selectedCompany)="onSelectedCompany($event)"></app-search-companies>
    </div>
  </div>
</div>
<!-- L'agente sceglie i clienti con una select -->
<div class="field" *ngIf="level=='agent'">
  <div class="control ui-fluid">
    <div class="control ui-fluid">
      <div class="select">
        <select [(ngModel)]="cliente" (ngModelChange)="onSelectedCompany($event)">
          <option [ngValue]="null" [translate]="'selectCompany'">--seleziona azienda--</option>
          <option *ngFor="let option of clientiAgente" [ngValue]="option">{{option.ragSoc}}</option>
        </select>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="company">
  <div class="columns">
    <div class="column has-text-centered">
      <div class="block has-text-weight-bold">
        {{company.ragSoc}}
      </div>
      <div>
        {{company.streetAddress}}
      </div>
      <div>
        {{company.localityInfo}}
      </div>
      <div *ngIf="company.email">
        {{company.email}}
      </div>
    </div>
    <div class="column has-text-centered">
      <div class="block has-text-weight-bold">
        {{'conditionApplied' | translate}}
      </div>
      <ng-container *ngIf="company.desScontoListino">
        <div>
          {{'condition' | translate}}
        </div>
        <div>
          {{company.desScontoListino}}
        </div>
      </ng-container>
      <div>
        {{'payment' | translate}}
      </div>
      <div>
        {{company.desCondizPagamento}}
      </div>
    </div>
    <div class="column has-text-centered">
      <div class="block has-text-weight-bold">
        {{'informationUsersB2b' | translate}}
      </div>
      <div>
        {{'eSolverCode' | translate }}
      </div>
      <div>
        {{company.codCliFor}}
      </div>
      <div>
        {{'totalEntrusting' | translate}}
      </div>
      <div>
        {{company.impAffidamento > 0 ? (company.impAffidamento | currency:'EUR') : 'N/A' }}
      </div>
    </div>
  </div>
  <h2 class="title-hr is-size-3" style="margin-top: 2rem; margin-bottom: 2rem;">
  </h2>
</ng-container>
<div class="columns" *ngIf="statsPuntualita && statsQtaAnthology && statsSpedizioni">
  <div class="column">
    <ng-container *ngIf="statsPuntualita.totalOrders > 0; else no_orders">
      <p class="has-text-centered title is-5" [translate]="'punctuality'">Puntualità</p>
      <p class="has-text-centered subtitle is-6" [translate]="'gaugeIntro'"
        [translateParams]="{total: statsPuntualita.totalOrders}"></p>
    </ng-container>
    <ng-template #no_orders>
      <p class="has-text-centered title is-5" [translate]="'punctuality'">Puntualità</p>
      <p class="has-text-centered subtitle is-6"><span [translate]="'gaugeIntroNoOrders'">no ordini</span></p>
    </ng-template>
  </div>
  <div class="column">
    <p class="has-text-centered title is-5" [translate]="'sentIn48hLast6Month'">Spedito entro 48 ore</p>
    <p class="has-text-centered subtitle is-6"><span [translate]="'last12Months'">ultimi 12 mesi</span></p>
  </div>
  <div class="column">
    <p class="has-text-centered title is-5" [translate]="'savingShipCost'">Risparmio Costi di Trasporto</p>
    <p class="has-text-centered subtitle is-6"><span [translate]="'last12Months'">ultimi 12 mesi</span></p>
  </div>
</div>
<div class="columns is-vcentered">
  <!-- Report Puntualità-->
  <div class="column">
    <div *ngIf="!loadingPuntualita; else loading">
      <div *ngIf="statsPuntualita">
        <div class="has-text-centered">
          <ngx-gauge type="arch" [size]="175" [thresholds]="thresholdConfig" [value]="gaugeValue" [thick]="10">
            <ngx-gauge-append>
              <span *ngIf="gaugeValue > 0">%</span>
            </ngx-gauge-append>
            <ngx-gauge-value>
              <span *ngIf="gaugeValue > 0">{{ gaugeValue | number }}</span>
              <span *ngIf="gaugeValue === 0">n/a</span>
            </ngx-gauge-value>
            <ngx-gauge-label [translate]="'gaugeOnTime'">
              puntuali
            </ngx-gauge-label>
          </ngx-gauge>
        </div>
      </div>
    </div>
  </div>

  <!-- Composizione Anthology-->
  <div class="column">
    <div *ngIf="!loadingQtaAnthology; else loading">
      <div *ngIf="statsQtaAnthology">
        <div class="columns">
          <div class="column">
            <p class="has-text-centered title is-1">{{(statsQtaAnthology.totalePezzi)}}</p>
            <p class="has-text-centered subtitle is-6"><span [translate]="'articlePieces'">Articoli (PZ)</span></p>
          </div>
          <div class="column">
            <p class="has-text-centered title is-1">{{(statsQtaAnthology.totaleMetri | number:'1.2-2')}}</p>
            <p class="has-text-centered subtitle is-6"><span [translate]="'profileMisureMeter'">Profili a misura
                (MT)</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Risparmio Spedizioni con MyAnthology-->
  <div class="column">
    <div *ngIf="!loadingSpedizioni; else loading">
      <div *ngIf="statsSpedizioni">
        <p class="has-text-centered title is-1">{{(statsSpedizioni.spedizioniMyAnthology * costoSpedizione) |
          currency:'EUR':'symbol'}}</p>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <span [translate]="'loading'"></span>
</ng-template>