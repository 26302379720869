<section class="hero is-medium">
  <div class="hero-body">
    <div class="container">
      <div class="columns is-vcentered">
        <div class="column">
          <h1 class="title" [translate]="'orderSuccess'">
            Ordine correttamente inserito
          </h1>
          <h2 class="subtitle" [translate]="'orderMailWillSend'">
            Riceverà a breve una mail riepilogativa contenente tutti i dettagli del suo ordine.
          </h2>
        </div>
        <div class="column is-narrow">
          <h1 class="title">
            <i class="far fa-thumbs-up fa-10x"></i>
          </h1>
        </div>
      </div>
    </div>
  </div>
</section>
