import { Component, OnChanges, Input, Output, SimpleChanges, EventEmitter, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router} from '@angular/router';
import { trigger, animate, state, transition, style } from '@angular/animations';
import { StoreService } from '../store.service';
import { sortBy} from 'lodash'

@Component({
  selector: 'application-menu',
  templateUrl: './application-menu.component.html',
  styleUrls: ['./application-menu.component.css'],
  animations:
  [
    trigger('toggleAnimation', [
      state('active', style({
        height: '*',
      })),
      state('inactive', style({
        height: '0px',
      })),
      transition('active <=> inactive', animate('250ms ease-in-out'))
    ])
  ]
})
export class ApplicationMenuComponent implements OnChanges {

  @Input()
  status : string = "inactive";
  @Output()
  statusChange = new EventEmitter();

  @Input()
  currentApplication: any;
  @Output()
  currentApplicationChange = new EventEmitter();

  @Input()
  categories: any[];

  currentCategory: any;
  currentProduct: any;

  filteredCategories: any[];

  //@ViewChild("scrollProd") scrollProd: any;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    let target = event.target || event.srcElement;
    if (target && target.className && typeof target.className.indexOf === 'function' &&  target.className.indexOf("app-menu-link") > -1)
      return;
    if(!this.eRef.nativeElement.contains(event.target) && this.status === "active") {
      this.close();
    }
  }

  constructor(private router: Router, private eRef: ElementRef, private storeService: StoreService) { }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.currentApplication && changes.currentApplication.currentValue) {
      this.selectCategory(changes.currentApplication.currentValue.categories[0]);
    }
  }

  selectCategory(cat) {
    this.currentCategory = cat;
    const firstFamily = sortBy(this.currentCategory.families, ['name'])[0];
    const product = sortBy(firstFamily.productsModel, ['name'])[0];
    this.currentProduct = product;
    //this.scrollProd.scrollToTop();
  }

  selectProduct(product) {
    this.currentProduct = product;
  }

  close() {
    this.status = "inactive";
    this.statusChange.emit(this.status);
    this.currentApplicationChange.emit(null);
  }

  gotoProduct(prod) {
    this.close();
    this.router.navigate(this.storeService.createUrl(['/product', prod.id]) as any[]);
  }

}
