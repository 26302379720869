import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StoreService } from '../store.service';

@Component({
  selector: 'shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.css']
})
export class ShippingAddressComponent implements OnInit {

  @Input()
  shipAddress: any;

  @Input()
  customer: any;

  @Input()
  multicolumn?: boolean = false;

  @Output()
  modified = new EventEmitter<void>();

  allAddress: any[];
  address: any[];

  country: any;
  countries: any[];
  private searchTerms$ = new Subject<string>();

  constructor(private storeService: StoreService) {
  }

  ngOnInit() {
    this.searchTerms$.pipe(switchMap((term: string) => {
      return this.storeService.searchCountries(term);
    }))
      .subscribe(data => {
        this.countries = data;
      });
  }

  search(event: any) {

    this.searchTerms$.next(event.query);
  }

  onCountrySelect(country: any) {
    this.shipAddress.countryCode = country.value.alpha2;
    this.raiseEvent();
  }

  onClear() {
    this.shipAddress = { countryCode: undefined };
    this.raiseEvent();
  }

  onClearCountry() {
    this.shipAddress.countryCode = undefined;
    this.raiseEvent();
  }

  filter(event: any) {
    if (event.query) {
      let queryLowerCase = event.query;
      this.address = this.customer.address.filter(a => a.indirizzo.toLowerCase().indexOf(queryLowerCase) != -1);
    } else {
      this.address = this.customer.address.slice();
    }
  }

  onSelect(event: any) {
    let value = event.value;
    this.shipAddress.address1 = value.indirizzo;
    this.shipAddress.address2 = value.indirizzo2;
    this.shipAddress.city1 = value.localita;
    this.shipAddress.city2 = value.localita2;
    this.shipAddress.zipCode = value.cap;
    this.shipAddress.emailAddress = value.email;
    this.shipAddress.telephoneNumber1 = value.numTel;
    this.shipAddress.countryCode = value.codStato;

    if (value.codStato) {
      this.storeService.getCountryByCode(value.codStato).subscribe((country) => {
        this.country = country
      });
    }

    this.raiseEvent();
  }

  raiseEvent() {
    this.modified.emit();
  }

}
