import { Injectable, Component } from "@angular/core";

@Injectable()
export class CanDeactivateQuotationGuard  {
  canDeactivate(component: GetQuotationDifference): boolean {
    if (component && component.differenceQuotation()) {
      if (confirm(component.alertMessage())) {
        component.reloadDetail();
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}

export interface GetQuotationDifference {
  differenceQuotation(): boolean;
  alertMessage(): string;
  reloadDetail(): void;
}
//['proceedWillDestroyModifyQuotation']
