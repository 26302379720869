<div class="is-divider is-medium" [attr.data-content]="'profileRole' | translate"></div>
@if (claims) {
<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">&nbsp;</label>
  </div>
  <div class="field-body">
    <div class="field">
      <div class="control">
        <span *ngIf="!isViewer">{{roles}}</span>
        <span *ngIf="isViewer" class="button is-static is-uppercase">Viewer</span>
      </div>
    </div>
  </div>
</div>
<div class="is-divider is-medium" [attr.data-content]="'profilePersonalData' | translate"></div>
<form>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label" [translate]="'name'">Nome</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <input class="input" type="text" [(ngModel)]="name" name="name">
        </div>
      </div>
    </div>
  </div>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label" [translate]="'familyName'">Cognome</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <input class="input" type="text" [(ngModel)]="surname" name="surname">
        </div>
      </div>
    </div>
  </div>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Email</label>
    </div>
    <div class="field-body">
      <div class="field has-addons">
        <div class="control is-expanded">
          <input class="input" type="text" readonly [value]="claims.email">
        </div>
        <div class="control">
          <a class="button is-info" [translate]="'sendChangeRequest'">
            Richiedi cambio
          </a>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="is-divider is-medium" [attr.data-content]="'profileChangePassword' | translate"></div>
<form>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label" [translate]="'newPassword'">Nuova Password</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <input class="input" type="password" [(ngModel)]="password" name="password">
        </div>
      </div>
    </div>
  </div>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label" [translate]="'confirmPassword'">Conferma Password</label>
    </div>
    <div class="field-body">
      <div class="control" style="width: 100%">
        <div class="field has-addons">
          <div class="control is-expanded">
            <input class="input" type="password" [(ngModel)]="confirmPassword" name="confirmPassword">
          </div>
          <div class="control">
            <button class="button is-info" [translate]="'change'" (click)="changePassword()"
              [disabled]="!password || confirmPassword != password">
              Cambia
            </button>
          </div>
        </div>
        <p class="help is-danger" *ngFor="let error of passwordChangeErrors">{{error.description}}</p>
      </div>
    </div>
  </div>
</form>
<div class="is-divider is-medium" [attr.data-content]="'profileCompany' | translate"></div>
<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label" [translate]="'refCustomer'">Rif. Cliente</label>
  </div>
  <div class="field-body">
    <div class="field">
      <div class="control">
        <input class="input is-static" type="text" readonly value="{{claims.dbGruppo}} {{claims.codCli}}">
      </div>
    </div>
  </div>
</div>
<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label" [translate]="'company'">Azienda</label>
  </div>
  <div class="field-body">
    <div class="field">
      <div class="control">
        <input class="input is-static" type="text" readonly [value]="claims.ragSoc">
      </div>
    </div>
  </div>
</div>
<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label" [translate]="'vatRegNumber'">Partita IVA</label>
  </div>
  <div class="field-body">
    <div class="field">
      <div class="control">
        <input class="input is-static" type="text" readonly [value]="claims.piva ? claims.piva : 'N/A'">
      </div>
    </div>
  </div>
</div>
<div class="field is-horizontal" *ngIf="!isViewer">
  <div class="field-label is-normal">
    <label class="label" [translate]="'plan'">Piano</label>
  </div>
  <div class="field-body">
    <div class="field">
      <div class="control">
        <input class="input is-static" type="text" readonly [value]="shippingPlans[claims.shipPlan]">
      </div>
    </div>
  </div>
</div>
<div class="field is-horizontal" *ngIf="!isViewer">
  <div class="field-label is-normal">
    <label class="label" [translate]="'paymentCond'">Cond. Pagamento</label>
  </div>
  <div class="field-body">
    <div class="field">
      <div class="control">
        <input class="input is-static" type="text" readonly [value]="claims.condPag">
      </div>
    </div>
  </div>
</div>
<div class="field is-horizontal" *ngIf="!isViewer">
  <div class="field-label is-normal">
    <label class="label" [translate]="'entrust'">Affidato</label>
  </div>
  <div class="field-body">
    <div class="field is-grouped">
      <div class="control">
        <span class="button is-static is-success is-uppercase" *ngIf="claims.clienteAffidato == 'True'"
          [translate]="'yes'">Si</span>
        <span class="button is-static is-danger is-uppercase" *ngIf="claims.clienteAffidato == 'False'"
          [translate]="'no'">No</span>
      </div>
    </div>
  </div>
</div>
}