<form *ngIf="baseQuotation">
  <div class="columns">
    <div class="column">
      <h2 class="title is-4" [translate]="'billingDetail'">Dati di fatturazione</h2>
      <a (click)="addAddress()">
        <i class="fas fa-plus fa-fw"></i>&nbsp;<span [translate]="'addHeader'">Aggiungi intestazione</span>
      </a>
    </div>
    <div class="column has-text-right">
      <div class="select is-medium"  >
        <select name="address" [(ngModel)]="dropdownAddress" (ngModelChange)="changeAddress()" [disabled]="baseQuotation?.customer.otherBillingAddress.length == 0">
          <option [ngValue]="undefined" selected [translate]="'changeHolder'">
          </option>
          <option *ngFor="let address of baseQuotation?.customer.otherBillingAddress"
                  [ngValue]="address">{{address.company}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column">
      <label class="label" [translate]="'companyName'">Ragione Sociale</label>
      <div class="control">
        <input name="companyCliente" [(ngModel)]="baseQuotation?.customer.billingAddress.company" class="input "
               type="text"
               [placeholder]="'companyName' | translate" [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
    <div class="column">
      <label class="label" [translate]="'vatRegNumber'">Partita IVA</label>
      <div class="control">
        <input name="vat" [(ngModel)]="baseQuotation?.customer.billingAddress.vat" class="input " type="text"
               [placeholder]="'vatRegNumber' | translate" [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column">
      <label class="label">PEC</label>
      <div class="control">
        <input name="pec" [(ngModel)]="baseQuotation?.customer.billingAddress.pec" class="input" email type="email"
               [placeholder]="'amministrativeCommunication' | translate" [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
    <div class="column">
      <label class="label" [translate]="'destinationNumber'">Codice destinatario</label>
      <div class="control">
        <input name="codiceDestinatario" [(ngModel)]="baseQuotation?.customer.billingAddress.codiceDestinatario"
               class="input " type="text" placeholder="per fatturazione elettronica"
               [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column">
      <label class="label" [translate]="'name'">Nome</label>
      <div class="control">
        <input name="nomeCliente" [(ngModel)]="baseQuotation?.customer.billingAddress.firstName" class="input "
               type="text" [placeholder]="'name' | translate" [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
    <div class="column">
      <label class="label" [translate]="'familyName'">Cognome</label>
      <div class="control">
        <input name="cognomeCliente" [(ngModel)]="baseQuotation?.customer.billingAddress.lastName" class="input "
               type="text" [placeholder]="'familyName' | translate" [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column">
      <label class="label">Email</label>
      <div class="control">
        <input name="emailCliente" [(ngModel)]="baseQuotation?.customer.billingAddress.emailAddress" class="input "
               type="text" placeholder="Email" [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
    <div class="column">
      <label class="label" [translate]="'telephone'">Telefono</label>
      <div class="control">
        <input name="telefonoCliente" [(ngModel)]="baseQuotation?.customer.billingAddress.telephoneNumber1"
               class="input " type="text" [placeholder]="'telephone' | translate"
               [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column">
      <label class="label" [translate]="'address'">Indirizzo</label>
      <div class="control">
        <input name="indirizzoCliente" [(ngModel)]="baseQuotation?.customer.billingAddress.address1" class="input "
               type="text" [placeholder]="'address' | translate"
               [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
    <div class="column">
      <label class="label">&nbsp;</label>
      <div class="control">
        <input name="indirizzoCliente2" [(ngModel)]="baseQuotation?.customer.billingAddress.address2" class="input "
               type="text" [placeholder]="'additionalAddress' | translate" [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column">
      <label class="label" [translate]="'city'">Città</label>
      <div class="control">
        <input name="cittaCliente" [(ngModel)]="baseQuotation?.customer.billingAddress.city1" class="input " type="text"
               [placeholder]="'city' | translate" [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
    <div class="column">
      <label class="label" [translate]="'provincia'"></label>
      <div class="control">
        <input name="localitaCliente" [(ngModel)]="baseQuotation?.customer.billingAddress.city2" class="input "
               type="text" maxlength="2"
               [placeholder]="'provincia' | translate" [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
    <div class="column">
      <label class="label" [translate]="'zipCode'">CAP</label>
      <div class="control">
        <input name="capCliente" [(ngModel)]="baseQuotation?.customer.billingAddress.zipCode" class="input " type="text"
               [placeholder]="'zipCode' | translate" [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
    <div class="column">
      <label class="label" [translate]="'countryCode'">Codice Nazione</label>
      <div class="control">
        <input name="statoCliente" maxlength="2" [(ngModel)]="baseQuotation?.customer.billingAddress.countryCode"
               class="input " type="text"
               [placeholder]="'country' | translate" [disabled]="baseQuotation?.listState != 0">
      </div>
    </div>
  </div>
</form>

