import {Component, OnInit, OnDestroy} from '@angular/core';
import {QuotationService} from "../quotation.service";
import {Observable, Subject} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {PdfService} from "../pdf.service";
import { ConfirmationService } from 'primeng/api';
import { StoreService } from '../store.service';
import { takeUntil } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-quotation-attachments',
  templateUrl: './quotation-attachments.component.html',
  styleUrls: ['./quotation-attachments.component.css']
})
export class QuotationAttachmentsComponent implements OnInit, OnDestroy {

  type: number;
  order: string = 'date';
  attachments$: Observable<any[]>;

  idQuotation: string;

  baseQuotation: any;
  uploadUrl: string;

  destroy$: Subject<boolean> = new Subject<boolean>();

  translations: any = {};

  constructor(private quotationService: QuotationService, private route: ActivatedRoute, private fileSaverService: FileSaverService,
    private pdfService: PdfService, private confirmationService: ConfirmationService, private storeService: StoreService) {
  }

  ngOnInit() {
    this.idQuotation = this.route.snapshot.params.id;
    this.quotationService.baseQuotation.subscribe(t => this.baseQuotation = t);
    this.attachments$ = this.quotationService.getAttachments(this.idQuotation);
    this.uploadUrl = `/api/list/${this.baseQuotation.id}/upload-attachments`;

    let translationRequired = marker(['warningAllUpper','areAyouSureDeleteAttachment', 'yes', 'no']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  updateAttachment() {
    this.attachments$ = this.quotationService.getAttachments(this.idQuotation);
  }

  download(attachment) {

    attachment.downloading = true;
    if (attachment.name == "pdfQuotation") {
      this.pdfService.printQuotationPdf(this.idQuotation, true, 1, true, true).subscribe((data: any) => {
          this.fileSaverService.save(data, `${this.baseQuotation.name}-quotation.pdf`);
          attachment.downloading = false;
        }
      );
    } else if (attachment.name == "printBrochure") {
      this.pdfService.printBrochureQuotation(this.idQuotation).subscribe((data: any) => {
        this.fileSaverService.save(data, `${this.baseQuotation.name}-brochure.pdf`);
        attachment.downloading = false;
      });
    } else if (attachment.type == 3) {
      this.quotationService.downloadAttachment(this.idQuotation, attachment.id).subscribe((data: any) => {
        this.fileSaverService.save(data, `${attachment.name}`);
        attachment.downloading = false;
      });
    } else {
      window.open(attachment.url, "_blank");
      attachment.downloading = false;
    }
  }

  sortTable(field:string) {
    if (field === this.order) {
      this.order = "-" + field;
    } else {
      this.order = field;
    }
  }

  delete(attachment) {
    this.confirmationService.confirm({
      key: 'confirmDeleteAttach',
      header: this.translations["warningAllUpper"],
      message: this.translations["areAyouSureDeleteAttachment"],
      rejectLabel: this.translations["no"],
      acceptLabel: this.translations["yes"],
      accept: () => {
        this.quotationService.deleteAttachment(this.idQuotation, attachment.id).subscribe(() => {
          this.attachments$ = this.quotationService.getAttachments(this.idQuotation);
        });
      },
      reject: () => {
        return;
      }
    });
  }
}
