import { HostListener, Directive, ElementRef, Input, AfterViewInit, OnInit } from '@angular/core';
import { ResizeService } from './resize.service';

@Directive({ selector: '[fill-height]' })
export class FillHeightDirective implements AfterViewInit, OnInit {

  selfSet: boolean = false;

  @Input()
  footer: any = null;

  constructor(private el: ElementRef, private resizeService: ResizeService) {
  }

  ngAfterViewInit(): void {
    this.calculateAndSetElementHeight();
  }

  ngOnInit() {
    this.resizeService.addResizeEventListener(this.el.nativeElement, (elem) => {
      if (!this.selfSet)
        this.calculateAndSetElementHeight();
      else this.selfSet = false;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (!this.selfSet)
      this.calculateAndSetElementHeight();
    else this.selfSet = false;
  }

  private calculateAndSetElementHeight() {
    this.el.nativeElement.style.overflow = 'auto';
    const windowHeight = window.innerHeight;
    const elementOffsetTop = this.getElementOffsetTop();
    const elementMarginBottom = this.el.nativeElement.style.marginBottom;
    const footerElementMargin = this.getfooterElementMargin();

    this.el.nativeElement.style.height = windowHeight - footerElementMargin - elementOffsetTop + 'px';
    console.debug([windowHeight, elementOffsetTop, elementMarginBottom, footerElementMargin, this.el.nativeElement.style.height]);
    this.selfSet = true;
  }

  private getElementOffsetTop() {
    this.el.nativeElement.style.setProperty('display', '');
    return this.el.nativeElement.getBoundingClientRect().top;
  }

  private getfooterElementMargin() {
    if (!this.footer) { return 0; }
    const footerStyle = window.getComputedStyle(this.footer);
    return parseInt(footerStyle.height, 10);
  }
}
