import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { QuotationService } from '../quotation.service';
import { combineLatest, Observable, Subject } from "rxjs";
import { StoreService } from '../store.service';
import { mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GetQuotationDifference } from './quotation.guard';
import { SalesForceService } from '../sales-force.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { IsSuperAdmin } from '../shared/functions';
import { error } from 'console';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-quotation-opportunity',
  templateUrl: './quotation-opportunity.component.html',
  styleUrls: ['./quotation-opportunity.component.css']
})
export class QuotationOpportunityComponent implements OnInit, OnDestroy, GetQuotationDifference {

  @ViewChild(NgForm, { static: false }) f: NgForm;

  createMode: number = 0; //0 crea nuova, 1 collega esistente, 2 visualizzazione opportunita' collegata 

  baseQuotation: any;
  opportunity: any;

  agentOpportunities: any[];
  existingOpportunity: any;
  quotationTotal: string;

  quotationTotalAcquisto: string;
  quotationTotalVendita: string;

  contacts: any[];
  resultsClients: any[];
  editModel: any = { stageName: 'Preventivo' };
  modelOpportunity: any;
  userClaims: any;
  allowedDealers: any[];
  isAdmin: boolean = false;
  mode: number = 1;

  destroy$: Subject<boolean> = new Subject<boolean>();
  locale: string;

  translations: any = {};

  private _opportunitiesDealer$: Subject<any[]> = new Subject<any[]>();
  opportunitiesDealer$: Observable<any[]> = this._opportunitiesDealer$.asObservable();

  showNoopportunitesForThisDealer: boolean;

  _searchTermNameOpportunites$: Subject<string> = new Subject<string>();
  _searchTermNameClient$: Subject<string> = new Subject<string>();

  creatingOpportunity: boolean;

  constructor(private authService: AuthenticationService, private quotationService: QuotationService, private messageService: MessageService,
    private salesForceService: SalesForceService, private storeService: StoreService) {

  }

  ngOnInit() {
    this.locale = this.storeService.localeDefault;

    this.storeService.getLocaleObservable().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.locale = data;
    });

    let translationRequired = marker(['success', 'error', 'proceedWillDestroyModifyQuotation',
      'opportunityCreated', 'opportunityCannotBeCreated', 'opportunityAmountMustBeMoreThanZero']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.userClaims = this.authService.getUserProfile();
    this.isAdmin = IsSuperAdmin(this.userClaims);
    combineLatest([this.quotationService.baseQuotation, this.quotationService.quotationOpportunity]).pipe(takeUntil(this.destroy$))
      .subscribe(t => {
        this.opportunity = t[1];
        this.baseQuotation = t[0];
        if (this.opportunity) {
          this.createMode = 2;
          this.opportunity.dateParsed = moment(this.opportunity.closeDate, 'YYYY-MM-DD').toDate();
        } else {
          this.editModel.name = this.baseQuotation.name;
        }
      });

    combineLatest([this.quotationService.baseQuotation, this.quotationService.quotationDetail])
      .pipe(takeUntil(this.destroy$)).subscribe(([bq, _]) => {
        let total = this.quotationService.calculateTotals();
        this.quotationTotal = total[0].toFixed(2);

        this.quotationTotalAcquisto = this.quotationTotal;
        this.quotationTotalVendita = total[1].toFixed(2);

        if (bq.extraDiscount) {
          this.quotationTotalVendita = (total[1] - (total[1] * bq.extraDiscount / 100)).toFixed(2);
        } else {
          this.quotationTotalVendita = total[1].toFixed(2);
        }

        if (!this.opportunity) {
          this.editModel.budget = this.quotationTotal;
        }
      });

    this._searchTermNameOpportunites$.asObservable().pipe(
      switchMap((query) => {
        return this.salesForceService.searchOpportunities(query)
      }),
      takeUntil(this.destroy$)).subscribe((data: any) => {
        this.agentOpportunities = data;
      });

    this._searchTermNameClient$.asObservable().pipe(
      switchMap((query) => {
        return this.salesForceService.searchCustomer(query)
      }), takeUntil(this.destroy$)).subscribe((data: any) => {
        this.resultsClients = data;
      });
  }

  ngOnDestroy(): void {
    this.quotationService.reloadQuotationDetail(); //TODO refactoring
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  searchClient(event: any) {
    this._searchTermNameClient$.next(event.query);

  }

  selectClient(event) {
    this.editModel.account = {};
    this.editModel.account.codice_cliente__c = event.value.codice_cliente__c;
  }

  searchOpportunity(event) {
    this._searchTermNameOpportunites$.next(event.query);

  }

  differenceQuotation(): boolean {
    return this.quotationService.getDifferenceStatus();
  }

  alertMessage(): string {
    return this.translations.proceedWillDestroyModifyQuotation;
  }

  reloadDetail(): void {
    this.quotationService.reloadQuotationDetail();
  }

  assignOpportunity() {
    this.salesForceService.assignOpportunity(this.baseQuotation.id, this.existingOpportunity.id, this.quotationTotal).pipe(mergeMap(() => {
      this.opportunity = this.existingOpportunity;
      this.opportunity.amount = this.quotationTotal;
      return this.quotationService.updateQuotation()
    })).subscribe({
      next: () => {
        this.creatingOpportunity = false;
        this.quotationService.updateQuotationOpportunity(this.existingOpportunity);
      },
      error: (_err) => {
        this.creatingOpportunity = false;
        this.messageService.add({
          key: 'opportunityResult',
          severity: 'error',
          summary: this.translations.error,
          detail: this.translations.opportunityCannotBeCreated
        });
      }

    });
  }

  assignOpportunityFromDealer(opportunity) {
    this.existingOpportunity = opportunity;
    this.assignOpportunity();
  }


  unlink() {
    this.salesForceService.deassignOpportunity(this.baseQuotation.id, this.opportunity.id).subscribe(_ => {
      this.createMode = 0;
      this.opportunity = undefined;
      this.existingOpportunity = undefined;
      this.quotationService.updateQuotationOpportunity(this.opportunity);
      this.editModel = { stageName: 'Preventivo' };
      this.editModel.name = this.baseQuotation.name;
      this.editModel.budget = this.quotationTotal;
    });
  }


  save() {
    if (this.f.invalid) {
      Object.keys(this.f.controls).forEach(key => {
        this.f.controls[key].markAsTouched();
      });
      return;
    }

    if (!this.editModel.budget || this.editModel.budget == "0.00") {
      alert(this.translations['opportunityAmountMustBeMoreThanZero']);
      return;
    }

    let modelOpportunity = {
      Name: this.editModel.name,
      Description: this.editModel.description,
      CloseDate: moment(this.editModel.closeDate).format('YYYY-MM-DD'),
      Amount: this.editModel.budget,
      ID_Preventivo__c: this.baseQuotation.id,
      Account: {
        codice_cliente__c: this.editModel.client.codice_cliente__c,
        Name: this.editModel.client.name
      }
    };
    this.creatingOpportunity = true;
    this.salesForceService.createOpportunity(modelOpportunity).subscribe({
      next: (data: any) => {
        this.existingOpportunity = data;
        this.assignOpportunity();
        this.messageService.add({
          key: 'opportunityResult',
          severity: 'success',
          summary: this.translations.success,
          detail: this.translations.opportunityCreated
        });
      },
      error: (_err) => {
        this.creatingOpportunity = false;
        this.messageService.add({
          key: 'opportunityResult',
          severity: 'error',
          summary: this.translations.error,
          detail: this.translations.opportunityCannotBeCreated
        });
      }
    });
  }

  selectDealer(event: any) {
    this.salesForceService.getOpportunities(event.value.codice_cliente__c).subscribe((results) => {
      if (results && results.length) {
        this.showNoopportunitesForThisDealer = false;
      } else {
        this.showNoopportunitesForThisDealer = true;
      }
      this._opportunitiesDealer$.next(results);

    })
  }

  setBudgetValue(mode: number) {
    if (!this.isAdmin) return;
    if (mode == 1) {
      this.editModel.budget = this.quotationTotalAcquisto;
      this.quotationTotal = this.quotationTotalAcquisto;
      this.mode = 1;
    } else {
      this.editModel.budget = this.quotationTotalVendita;
      this.quotationTotal = this.quotationTotalVendita;
      this.mode = 2;
    }
  }

}
