import {Component, OnDestroy, OnInit} from '@angular/core';
import {QuotationService} from '../quotation.service';
import { Guid } from "guid-typescript";
import { filter } from "lodash";
import { GetQuotationDifference } from './quotation.guard';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StoreService } from '../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  templateUrl: './quotation-customer.component.html',
  styleUrls: ['./quotation-customer.component.css']
})
export class QuotationCustomerComponent implements OnInit, OnDestroy, GetQuotationDifference {
    
  destroy$: Subject<boolean> = new Subject<boolean>();
  baseQuotation: any;
  dropdownAddress: any;

  translations: any = {};

  constructor(private quotationService: QuotationService, private storeService: StoreService) {
  }

  ngOnInit() {
    let translationRequired = marker(['proceedWillDestroyModifyQuotation']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.quotationService.baseQuotation.pipe(takeUntil(this.destroy$)).subscribe(t => this.baseQuotation = t);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  differenceQuotation(): boolean {
    return this.quotationService.getDifferenceStatus();
  }

  alertMessage(): string {
    return this.translations.proceedWillDestroyModifyQuotation;
  }

  reloadDetail(): void {
    this.quotationService.reloadQuotationDetail();
  }

  addAddress() {
    this.baseQuotation.customer.otherBillingAddress = this.baseQuotation.customer.otherBillingAddress.concat(this.baseQuotation.customer.billingAddress);
    this.baseQuotation.customer.billingAddress = {id: Guid.create().toString()};
  }

  changeAddress() {
    this.baseQuotation.customer.otherBillingAddress = filter(this.baseQuotation.customer.otherBillingAddress, o => { return o.id != this.dropdownAddress.id; });
    this.baseQuotation.customer.otherBillingAddress = this.baseQuotation.customer.otherBillingAddress.concat(this.baseQuotation.customer.billingAddress);
    this.baseQuotation.customer.billingAddress = this.dropdownAddress;
  }
}
