import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ProductService } from '../../product.service';
import { ListService } from '../../list.service';
import { CatalogService } from '../../catalog.service';
import { Observable, Subject } from 'rxjs';
import { List } from 'immutable';
import { takeUntil } from 'rxjs/operators';
import { StoreService } from '../../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.css']
})
export class AddToCartComponent implements OnInit, OnDestroy {

  @Input()
  product: any;

  @Input()
  notReset: boolean;

  @Output()
  askReplacement = new EventEmitter<number>();

  skipReset: boolean;

  restoration$: Observable<any>;

  tmpItems: List<any>;

  currentVariant: any;

  iconAttributes: string[] = ["driver", "ip", "lighting-fixture", "voltage"];
  attributesForIcon: any[];
  leadTime: number = 3;
  showSymbolTutti : boolean = true;

  shopMode: number = 1;
  discountStar: number = 0;
  accManagedStock: string;

  destroy$: Subject<boolean> = new Subject<boolean>();

  locale: string;
  constructor(private productService: ProductService,
    private listService: ListService, private storeService: StoreService, private catalogService: CatalogService) {


  }

  ngOnInit() {
    marker('accManagedStock');

    this.storeService.translateService.stream('accManagedStock').pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.accManagedStock = data;
    });

    this.locale = this.storeService.localeDefault;

    this.storeService.getLocaleObservable().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.locale = data;
    });

    this.skipReset = this.notReset;

    this.productService.getCurrentVariant().pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.currentVariant = x;
      if (this.currentVariant.anthologyStar) {
        this.discountStar = 100 - Math.round(this.currentVariant.prezzoRiservato * 100 / this.currentVariant.prezzo);
      } else {
        this.discountStar = 0;
      }

      if (this.currentVariant.anthology) {
        this.restoration$ = this.productService.getRestoration(x.sku);
      }
      else {
        this.restoration$ = undefined;
      }        
      this.attributesForIcon = this.currentVariant.attributes.filter(t => this.iconAttributes.indexOf(t.sysName) > -1);

      let indexNoSymbol = this.currentVariant.attributes.findIndex(a => a.sysName == 'no-symbol');
      if (indexNoSymbol != -1) {
        this.showSymbolTutti = this.currentVariant.attributes[indexNoSymbol].value == 'true';
      }

      const leadTimeAttribute = this.currentVariant.attributes.find(t => t.sysName == "lead-time");
      if (leadTimeAttribute) {
        this.leadTime = Number(leadTimeAttribute.value);
      }
      if (!this.notReset) {
        this.listService.removeConfigurationItem({ type: 0 });
        this.listService.getCurrentConfiguration().subscribe(t => {
          if (!t)
            return;

          this.tmpItems = t;
          let index = this.tmpItems.findIndex(t => t.referenceId == this.currentVariant.id && t.type == 0);
          this.currentVariant.selected = index != -1;
          if (this.currentVariant.selected) {
            this.currentVariant.quantity = this.tmpItems.get(index).quantity;
          }
          else {
            this.currentVariant.quantity = undefined;
          }
        });

      }

    });

    this.catalogService.getShopMode().pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.shopMode = t;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addToCart(variant) {
    variant.selected = !variant.selected;
    if (variant.selected) {
      variant.quantity = 1;
      this.listService.addOrUpdateConfigurationItem({ referenceId: variant.id, sku: variant.sku, quantity: variant.quantity, type: 0 });
    } else {

      variant.quantity = null;
      this.listService.removeConfigurationItem({ referenceId: variant.id, type: 0 });
    }
  }

  onQtyChange() {
    this.listService.addOrUpdateConfigurationItem({ referenceId: this.currentVariant.id, sku: this.currentVariant.sku, quantity: this.currentVariant.quantity, type: 0 });
  }

  askSubstitute() {
    this.askReplacement.emit(this.currentVariant.quantity ? this.currentVariant.quantity : 1);
  }
}
