import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PdfService } from "../pdf.service";
import { QuotationService } from '../quotation.service';
import { StoreService } from '../store.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { IsSuperAdmin } from '../shared/functions'
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';
import { AuthenticationService } from '../services/authentication.service';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-quotation-pdf',
  templateUrl: './quotation-pdf.component.html',
  providers: [ DecimalPipe ],
  styleUrls: ['./quotation-pdf.component.scss']
})
export class QuotationPdfComponent implements OnInit, OnDestroy {

  baseQuotation: any;
  data: any;
  source: any;
  ready: boolean = false;

  translations: any = {};

  showComponentiConfigInPdf: boolean = true;
  showProductCodeInPdf: boolean = true;
  showProductImageInPdf: boolean = true;

  destroy$: Subject<boolean> = new Subject<boolean>();

  dropDownPdfModeIsActive: boolean = false;

  admin: boolean;

  @ViewChild('pdfViewerAutoLoad', { static: false} )
  pdfViewerAutoLoad;

  constructor(private pdfService: PdfService, private quotationService: QuotationService, private fileSaverService: FileSaverService,
    private authService: AuthenticationService, private storeService: StoreService, private decimalPipe: DecimalPipe) {

    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    
  }

  ngOnInit() {
    let userdata = this.authService.getUserProfile();
    this.admin = IsSuperAdmin(userdata);

    let translationRequired = marker(['errorCreatingPdf', 'proforma', 'rev']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.quotationService.baseQuotation.pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.baseQuotation = t;
      this.reloadPdf();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  reloadPdf() {
    this.ready = false;
    this.pdfService.printQuotationPdf(this.baseQuotation.id, this.showComponentiConfigInPdf, 1, this.showProductCodeInPdf, this.showProductImageInPdf).subscribe((data: any) => {
      if (data.size > 0) {
        this.data = data;

        this.pdfViewerAutoLoad.pdfSrc = data; // pdfSrc can be Blob or Uint8Array
        this.pdfViewerAutoLoad.refresh(); // Ask pdf viewer to load/refresh pdf

      } else {
        alert(this.translations['errorCreatingPdf']);
      }
    }, (_) => {
      alert(this.translations['errorCreatingPdf']);
    });
  }

  printPdfQuotation() {
    let nameFile = this.baseQuotation.name;
    if (this.baseQuotation.currentRevision > 0) {
      nameFile += ` ${this.translations.rev}${this.decimalPipe.transform(this.baseQuotation.currentRevision, "2.0-0")}`
    }
    this.fileSaverService.save(this.data, `${nameFile}${moment().format('DD-MM-YYYY')}.pdf`);
    setTimeout(() => {
      this.dropDownPdfModeIsActive = false;
    }, 10);
  }

  printPdfProformaQuotation() {
    this.pdfService.printQuotationPdf(this.baseQuotation.id, this.showComponentiConfigInPdf, 2, this.showProductCodeInPdf, this.showProductImageInPdf).subscribe((data: any) => {
      if (data.size > 0) {
        let nameFile = this.baseQuotation.name;
        if (this.baseQuotation.currentRevision > 0) {
          nameFile += ` ${this.translations.rev}${this.decimalPipe.transform(this.baseQuotation.currentRevision, "2.0-0")}`
        }
        this.fileSaverService.save(data, `${nameFile} ${this.translations.proforma} ${moment().format('DD-MM-YYYY')}.pdf`);
        this.ready = true;
      } else {
        alert(this.translations['errorCreatingPdf']);
      }
      this.dropDownPdfModeIsActive = false;
    }, (_error: any) => {
      alert(this.translations['errorCreatingPdf']);
      this.dropDownPdfModeIsActive = false;
    });
  }

  closeDropdown() {
    this.dropDownPdfModeIsActive = false;
  }
}
