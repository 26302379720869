<section class="section" style="padding-top: 1.5em">
  <div class="container">
    <div class="tabs is-boxed is-relative">
      <ul>
        <li class="is-active"><a [translate]="'checkOutSingle'"></a></li>
        <li><a (click)="goTo3Car()" [translate]="'checkOutTriple'"></a></li>
      </ul>
      <a routerLink="../" style="position: absolute; right:0;"><i class="fas fa-times fa-lg"></i></a>
    </div>
    <div class="columns is-gapless" *ngIf="dataShown">
      <div class="column is-8">
        <div class="spacer"></div>
        <div *ngFor="let sectionArticle of dataShown|getValues">
          <ng-container *ngIf="sectionArticle.value.items && sectionArticle.value.items.length > 0">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span class="is-size-5"
                    *ngIf="sectionArticle.value.name; else noNameSection">{{sectionArticle.value.name}}</span>
                  <ng-template #noNameSection>
                    <i class="is-size-5" [translate]="'sectionNoName'"></i>
                  </ng-template>
                </div>
              </div>
            </div>
            <div *ngFor="let item of sectionArticle.value.items">
              <checkout-item-plain [item]="item" (requestSuggest)="openSuggest($event, item)"
                (qtaDisponibileChange)="recalcAvailability($event)" [quotationId]="cartDetail.id">
              </checkout-item-plain>
              <p-dialog [(visible)]="aiPopperSubstiteVisibile" [closable]="true" [modal]="true"
                [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
                <ng-template pTemplate="header">
                  <div>
                    <p class="title is-4" [translate]="'replaceArticles'"></p>
                  </div>
                </ng-template>
                <ng-template pTemplate="closeicon">
                  <i class="has-text-white fas fa-times"></i>
                </ng-template>
                <item-substitute [ready]="ready" [item]="item" [itemSuggest]="item.itemSuggest"
                  (close)="substituteArticle($event, item)"></item-substitute>
              </p-dialog>
            </div>
            <hr *ngIf="sectionArticle.value.name" />
          </ng-container>
        </div>
      </div>
      <div class="column is-4">
        <div class="box" style="margin-left: 3em; position: sticky; top: 16px;" *ngIf="atleastOneavailable && !allAvaliable">
          <div class="has-text-centered has-text-weight-bold" [translate]="'availableObjectGoToPartialShip'" > </div>
          <button class="button is-warning is-fullwidth has-text-centered has-text-weight-semibold is-size-5" style="margin: 1em 0px 1em 0px;" type="button" 
            (click)="goTo3Car()" [translate]="'goToPartialShipping'"></button>
          <div class="has-text-centered"><span [translate]="'warning'"></span>:</div>
          <div class="has-text-centered" [translate]="'possibleIncreaseShipCost'"></div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="columns" style="padding-right:1em">
          <div class="column is-narrow">
            <div class="title is-size-4" [translate]="'total'">Totale</div>
            <div class="subtitle is-size-6" [translate]="'shippingCost'">
              Spese di spedizione
            </div>
          </div>
          <div class="column has-text-right">
            <div class="title is-size-4" *ngIf="listTotalReserved > 0">{{listTotalReserved | currency:'EUR'}}</div>
            <div class="subtitle is-size-6" *ngIf="shippingTotal > 0; else no_shipCost">{{shippingTotal |
              currency:'EUR'}}</div>
          </div>
          <ng-template #no_shipCost>
            <div class="subtitle is-size-6" [translate]="'freeOfCharge'">gratuite</div>
          </ng-template>
        </div>
      </div>
      <div class="column is-4 has-text-right">
        <h4 class="title is-size-4">
          <ng-container *ngIf="shipDateRequired; else noShipDate">
            <span [translate]="'shippingDate'">Data di spedizione</span>
          </ng-container>
          <br />
          <span class="has-text-info has-text-weight-semibold has-text-centered">
            <input [pTooltip]="translations['clickDataToModify']" tooltipEvent="focus" tooltipPosition="bottom"
              [tooltipDisabled]="shipDateRequired" tooltipStyleClass="m-tooltip" class="is-checkradio is-info is-small"
              type="checkbox" id="shipDateRequired" name="shipDateRequired" 
              [(ngModel)]="shipDateRequired" (ngModelChange)="updateShipDateRequired($event)" >
            <label for="shipDateRequired" [translate]="'shipInDate'">Spedisci in data specifica</label>
          </span>
        </h4>
        <h5 class="subtitle is-size-5" *ngIf="shipDateRequired">
          <p-calendar [minDate]="minShipDate" dateFormat="dd/mm/yy" [firstDayOfWeek]="1"
            [maxDate]="maxShipDate" [disabledDates]="holydays"
            [(ngModel)]="shipDate" [inputStyleClass]="'input is-static'"
            [inputStyle]="{'text-align':'right', 'margin':'0 !important', 'padding':'0!important', 'height':'1.2em'}"></p-calendar>
        </h5>
      </div>
    </div>
    <hr class="marginHr" />
    <div>
      <div class="columns">
        <div class="column">
          <h2 class="title is-size-4" [translate]="'shippingMethod'">
            Metodo di spedizione
          </h2>
        </div>
        <div class="column is-narrow has-text-right">
          <h2 class="title is-size-4" *ngIf="shippingTotal > 0">
            <span [translate]="'charge'">Spese</span> {{shippingTotal | currency:'EUR'}}
          </h2>
          <h2 class="title is-size-4" *ngIf="shippingTotal == 0" [translate]="'shippingFree'">
            Spedizione gratuita
          </h2>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="columns">
        <div class="column">
          <div class="shipping-modes">
            <div class="control">
              <label class="radio" *ngIf="!onlyExWork">
                <input type="radio" name="shippingMode" [(ngModel)]="shippingMode" [value]="1"
                  (change)="singleShippingModeChange()">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'courier'">CON CORRIERE MACROLUX</span>
              </label>
              <div class="is-size-7" [translate]="'shippingOrganizedByMacrolux'">
                Trasporto organizzato da Macrolux srl - Condizioni economiche del trasporto in
                base al Piano prescelto.
              </div>
            </div>
            <div class="control" *ngIf="!onlyExWork && !hideCourierChoiceByCustomer">
              <label class="radio">
                <input type="radio" name="shippingMode" [(ngModel)]="shippingMode" [value]="2"
                  (change)="singleShippingModeChange()">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'courierCustomer'">CON CORRIERE DEL CLIENTE</span>
              </label>
              <div class="is-size-7" [translate]="'shippingOrganizedByCustomer'">
                Trasporto organizzato da Macrolux srl, con Corriere Convenzionato del Cliente
                (costo a carico del Cliente).
              </div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="shippingMode" [(ngModel)]="shippingMode" [value]="3"
                  (change)="singleShippingModeChange()">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'ourMagazine'">NS RITIRO (EX WORKS)</span>
              </label>
              <div class="is-size-7" [translate]="'pickUpMagazine'">
                Desidero ritirare la merce
                presso il magazzino Macrolux
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="marginHr" *ngIf="!disableShippingAddress" />
    <div *ngIf="!disableShippingAddress">
      <div class="columns is-vcentered">
        <div class="column">
          <h2 class="title is-size-4" [translate]="'shipAddresses'">
            Indirizzi di spedizione
          </h2>
        </div>
        <div class="column is-narrow">
        </div>
      </div>
      <div class="spacer"></div>
      <div class="columns" *ngIf="customer">
        <div class="column">
          <shipping-address [(shipAddress)]="shipAddress" [customer]="customer"
            (modified)="updateEnableButtonOrder()"></shipping-address>
        </div>
      </div>
    </div>
    <hr class="marginHr" />

    <div>
      <div class="columns is-vcentered">
        <div class="column">
          <h2 class="title is-size-4" [translate]="'payment'">
            Pagamento
          </h2>
        </div>
        <div class="column is-narrow">
        </div>
      </div>
      <div class="spacer"></div>
      <div class="columns">
        <div class="column">
          <div class="payment-modes">
            <div class="control" *ngIf="b2bPaymentAllowed">
              <label class="radio" [attr.disabled]="b2bEnabled ? null : true"
                [pTooltip]="'insufficientFund' | translate : {amountAvailable: (companyInfo.fido  | currency:'EUR') }"
                tooltipStyleClass="m-tooltip" [tooltipDisabled]="b2bEnabled">
                <input type="radio" name="paymentMethod" [(ngModel)]="paymentMethod" [value]="1"
                  [attr.disabled]="b2bEnabled ? null : true" (ngModelChange)="changeMethodPayment($event)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'b2bCustomerEntrusted'">CLIENTE B2B AFFIDATO</span>
              </label>
              <div class="is-size-7" [translate]="'paymentAsAgreed'">Come da condizioni di pagamento in uso</div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="paymentMethod" [(ngModel)]="paymentMethod" [value]="3"
                  (ngModelChange)="changeMethodPayment($event)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'bankTransfer'">BONIFICO BANCARIO</span>
              </label>
              <div class="is-size-7">{{bank}} - {{iban}} BIC/SWIFT : {{swift}}</div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="paymentMethod" [(ngModel)]="paymentMethod" [value]="2"
                  (ngModelChange)="changeMethodPayment($event)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'creditCardUpper'"></span>
              </label>
              <div class="is-size-7" [translate]="'subTitleCreditCardChoice'"></div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="paymentMethod" [(ngModel)]="paymentMethod" [value]="4"
                  (ngModelChange)="changeMethodPayment($event)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'paypalUpper'"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div #containerCreditCard class="is-hidden">
        <hr class="marginHr" />
        <h2 class="title is-size-4" [translate]="'creditCardPlural'"></h2>
        <div class="subtitle is-size-5 has-text-info" [translate]="'choiceOrCreateCard'"></div>
        <div class="spacer"></div>
        <div class="columns is-vcentered">

          <div class="column" [ngClass]="{'is-hidden':insertNewCard}">

            <ul>
              <li class="field has-addons" *ngFor="let card of creditCards">
                <a class="radio-link is-flex">
                  <label class="radio is-flex" style="align-items: center;">
                    <input type="radio" name="creditCardSelect" [(ngModel)]="crediCardSelectId" [value]="card.id">&nbsp;
                    <span>
                      <span class="has-text-weight-semibold">{{card.brand | titlecase}}</span>&nbsp;<span
                        [translate]="'endWith'"></span>&nbsp;{{card.lastFourNumber}}&nbsp;<br />
                      <span class="is-size-7">
                        <span [translate]="'expireAt'"></span>&nbsp;{{card.expireMonth |
                        number:'2.0-0'}}/{{card.expireYear}}
                      </span>&nbsp;<span>{{card.holderCard}}</span>
                    </span>
                  </label>
                </a>
              </li>
            </ul>
            <div class="spacer"></div>
            <div class="field">
              <p class="control">
                <button (click)="insertNewCard = true; crediCardSelectId = undefined;" class="button is-info"
                  [translate]="'newCard'">
                  Nuova Carta
                </button>
              </p>
            </div>
          </div>
          <div class="column" [ngClass]="{'is-hidden':insertNewCard}">
            <div class="notification is-clearfix">
              <div class="level">
                <div class="level-right">
                  <div class="level-item">
                    <div>
                      <div class="title is-5" [translate]="'totalCard'"></div>
                      <small [translate]="'includeIva'" *ngIf="countryCart.codStato == 'IT'"></small>
                    </div>
                  </div>
                </div>
                <div class="level-left">
                  <div class="level-item">
                    <div class="title is-5">{{totalCard | currency:'EUR'}}</div>
                  </div>
                </div>
              </div>
              <div class="spacer"></div>
              <div class="is-size-6" [translate]="'checkOutStripeText'">
              </div>
              <div class="spacer"></div>
              <div>
                <img class="is-pulled-right" style="height:2em" src="/images/powered_by_stripe.png" />
              </div>
            </div>
          </div>
          <div class="card" [ngClass]="{'is-hidden':!insertNewCard}">
            <header class="card-header" style="background-color: #173554;">
              <p class="card-header-title is-size-5 has-text-weight-normal has-text-white" [translate]="'newCard'">
                Nuova carta
              </p>
              <a href="#" *ngIf="creditCards" (click)="insertNewCard = false" class="card-header-icon has-text-white"
                aria-label="more options">
                <span class="icon">
                  <i class="fas fa-times" aria-hidden="true"></i>
                </span>
              </a>
            </header>
            <div class="card-content">
              <div class="columns is-vcentered">
                <div class="column">
                  <label class="label" [translate]="'creditCardHolder'"></label>
                  <div class="control is-expanded">
                    <input class="input" type="text" placeholder="Name" [(ngModel)]="intestatarioCarta">
                  </div>
                  <label class="label" style="margin-top: 1.5rem;" [translate]="'creditCardData'">
                    Dati della carta
                  </label>
                  <div class="control is-expanded">
                    <div id="formCreditCard"></div>
                  </div>
                </div>
                <div class="column">
                  <div class="notification is-clearfix">
                    <div class="level">
                      <div class="level-right">
                        <div class="level-item">
                          <div>
                            <div class="title is-5" [translate]="'totalCard'"></div>
                            <small [translate]="'includeIva'" *ngIf="countryCart.codStato == 'IT'"></small>
                          </div>
                        </div>
                      </div>
                      <div class="level-left">
                        <div class="level-item">
                          <div class="title is-5">{{totalCard | currency:'EUR'}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="spacer"></div>
                    <div class="is-size-6" [translate]="'checkOutStripeText'">
                    </div>
                    <div class="spacer"></div>
                    <div>
                      <img class="is-pulled-right" style="height:2em" src="/images/powered_by_stripe.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="marginHr" />
    <div class="columns">
      <div class="column">
        <div class="column is-4">
        </div>
      </div>
      <div class="column is-4 has-text-right">
        <h4 class="title is-size-4">
          <ng-container *ngIf="shipDateRequired; else noShipDate">
            <span [translate]="'shippingDate'">Data di spedizione</span>
          </ng-container>
          <br />
          <span class="has-text-info has-text-weight-semibold has-text-centered">
            <input [pTooltip]="translations['clickDataToModify']" tooltipEvent="focus" tooltipPosition="bottom"
              [tooltipDisabled]="shipDateRequired" tooltipStyleClass="m-tooltip" class="is-checkradio is-info is-small"
              type="checkbox" id="shipDateRequired2" name="shipDateRequired" [(ngModel)]="shipDateRequired" 
              (ngModelChange)="updateShipDateRequired($event)">
            <label for="shipDateRequired2" [translate]="'shipInDate'">Spedisci in data specifica</label>
          </span>
        </h4>
        <h5 class="subtitle is-size-5" *ngIf="shipDateRequired">
          <p-calendar [minDate]="minShipDate" dateFormat="dd/mm/yy" [firstDayOfWeek]="1"
           [maxDate]="maxShipDate" [disabledDates]="holydays" [(ngModel)]="shipDate" [inputStyleClass]="'input is-static'"
            [inputStyle]="{'text-align':'right', 'margin':'0 !important', 'padding':'0!important', 'height':'1.2em'}"></p-calendar>
        </h5>
      </div>
    </div>
    <hr class="marginHr" />
    <div class="columns">
      <div class="column"></div>
      <div class="column is-narrow has-text-right">
        <total-checkout [projectDiscountValue]="projectDiscountValue" [listTotalReserved]="listTotalReserved"
          [extraSconto]="extraSconto" [checkPrizeApplied]="checkPrizeApplied" [customerDiscountApplied]="customerDiscountApplied"
          [shippingTotal]="shippingTotal" ></total-checkout>
      </div>
    </div>
    <div class="spacer"></div>
    <div class="columns" style="align-items: flex-end">
      <div class="column" style="align-self: flex-start">
        <h2 class="title is-size-4" [translate]="'accept'">
          Conferma
        </h2>
        <div class="subtitle is-size-5" [translate]="'willConfirmSingleOrder'">
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label" [translate]="'orderReference'">Riferimento ordine</label>
          <div class="control">
            <input class="input" type="text" maxlength="25" [(ngModel)]="rifOrdine" [placeholder]="'ref' | translate">
          </div>
        </div>
        <div>&nbsp;</div>
        <div class="field">
          <label class="label" [translate]="'checkPriceUsableForMax'">
            Check prize
            utilizzabile (max. 10% val. acquisto)
          </label>
          <div class="field has-addons">
            <div class="control is-expanded">
              <input class="input" type="text" disabled readonly [value]="checkPrize  | currency:'EUR'" />
            </div>
            <div class="control" [pTooltip]="checkPrizeDisableText" tooltipStyleClass="m-tooltip"
              [tooltipDisabled]="!checkPrizeDisableText">
              <button class="button is-warning" (click)="applyCheckPrize()" [translate]="'apply'"
                [disabled]="cartDetail.projectDiscount > 0 || checkPrizeThreshold[userdata.shipPlan] > listTotalReserved || (!(checkPrize > 0) || checkPrizeApplied > 0)">
                Applica
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <button class="button is-large is-fullwidth" routerLink="../">
          <span [translate]="'backTo'">Torna</span>&nbsp;{{translations["toQuotation"]}}
        </button>
        <div class="spacer"></div>
        <button class="button is-large is-link is-fullwidth"
          [class.is-hidden]="paypalSelect && !shippingInvalid && !orderSending"
          [disabled]="orderSending || shippingInvalid" [class.is-loading]="orderSending" (click)="sendOrder()"
          [translate]="'order'">
          Ordina
        </button>
        <div [class.is-hidden]="!paypalSelect || shippingInvalid || orderSending">
          <div class="has-text-weight-bold" [translate]="'payThisAmountWithPaypalAndProceed'"
            [translateParams]="{amountToPay: (totalCard | currency:'EUR')}"></div>
          <div id="paypal-button"></div>
        </div>
      </div>
    </div>
  </div>
</section>
<p-confirmDialog key="confirmOrder"></p-confirmDialog>
<p-toast key="toastErrorCreditCard"></p-toast>
<ng-template #noShipDate>
  <span *ngIf="allAvaliable" [translate]="'ordSpedH48Checkout'">Spedizione in 48h</span>
  <span *ngIf="!allAvaliable" [translate]="'shipInWeeks'" [translateParams]="{num: minSettimaneShip}"></span>
</ng-template>
<p-confirmDialog key="confirmChangeModePay" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-dialog [modal]="true" [style]="{width: '350px'}" [header]="'warningAllUpper' | translate"
  [(visible)]="displayInsufficientFund">
  <span [translate]="'insufficientFund'"
    [translateParams]="{amountAvailable: (companyInfo.fido  | currency:'EUR')}"></span>
</p-dialog>
<p-confirmDialog key="sendMailToCustomer"></p-confirmDialog>