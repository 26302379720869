import { Routes } from "@angular/router";
import { CatalogComponent } from "./catalog/catalog.component";
import { ProductComponent } from "./catalog/product.component";
import { ListDetailComponent } from "./lists/list-detail.component";
import { ListsComponent } from "./lists/lists.component";
import { ProductService } from "./product.service";
import { QuotationDetailComponent } from "./quotations/quotation-detail.component";
import { CanDeactivateQuotationGuard } from "./quotations/quotation.guard";
import { CanDeactivatListGuard } from "./lists/list-detail.guard";
import { CheckoutService } from "./checkout.service";
//import { PageComponent } from "./page/page.component";
import { HomeComponent } from "./home/home.component";
import { CategoryComponent } from "./catalog/category.component";
import { FamilyComponent } from "./catalog/family.component";
import { ProfileComponent } from './profile/profile.component';
import { ProfileDetailComponent } from './profile/profile-detail.component';
import { ProfileSettingsComponent } from './profile/profile-settings.component';
import { AccessoryCatalogComponent } from "./accessory-catalog/accessory-catalog.component";
import { AccessoryCategoryComponent } from "./accessory-catalog/accessory-category.component";
import { AccessoriesResolver, AccessoryCategoriesResolver } from "./resolvers/accessories.resolver";
import { QuotationCustomerComponent } from "./quotations/quotation-customer.component";
import { QuotationDiscountComponent } from "./quotations/quotation-discount.component";
import { QuotationComponent } from './quotations/quotation.component';
import { ProductWizardComponent } from "./catalog/product-wizard.component";
import { QuotationProjectComponent } from "./quotations/quotation-project.component";
import { QuotationPdfComponent } from './quotations/quotation-pdf.component';
import { QuotationService } from "./quotation.service";
import { ProfileAddressComponent } from './profile/address.component';
import { OrderCompletedComponent } from './checkout/order-completed.component';
import { ListQuotationComponent } from './quotations/listquotations/list-quotation.component';
import { QuotationBrochureComponent } from './quotations/quotation-brochure.component';
import { ListService } from './list.service';
import { ProfileContractsComponent } from "./profile/profile-contracts.component";
import { StoreComponent } from './store/store.component';
import { StoreResolver } from './store/store.resolver';
import { ProfileCheckprizeComponent } from "./profile/checkprize.component";
import { AuthGuardBuyMode } from './auth/auth-guard-buy-mode.guard';
import { PaymentMethodsComponent } from './profile/payment-methods.component';
import { CheckOutResolver } from './checkout/checkout.resolver';
import { CheckoutPlainComponent } from './checkout/checkout-plain.component';
import { CheckOutPlainResolver } from './checkout/checkout-plain.resolver';
import { Checkout3CarrelliComponent } from './checkout/checkout-3-carrelli.component';
import { NotificationsComponent } from "./profile/notifications.component";
import { QuotationAttachmentsComponent } from "./quotations/quotation-attachments.component";
import { CompanyInfoResolver } from './resolvers/companyInfo.resolver';
import { QuotationsShareComponent } from './quotations/quotations-share.component';
import { QuotationOpportunityComponent } from "./quotations/quotation-opportunity.component";
import { OpportunityResolver } from "./resolvers/opportunity.resolver";
import { OpportunityComponent } from "./opportunity/opportunity.component";
import { ProjectApprovedComponent } from "./project-procedure-output/project-approved/project-approved.component";
import { ProjectRejectedComponent } from "./project-procedure-output/project-rejected/project-rejected.component";
import { AutoLoginPartialRoutesGuard } from "angular-auth-oidc-client";
import { AuthCallbackComponent } from "./auth/auth-callback/auth-callback.component";
import { QuotationRetailComponent } from "./quotations/quotation-retail.component";

export const Route: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: StoreComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    resolve: { pageFound: StoreResolver }
  },
  { path: 'callback', component: AuthCallbackComponent },
  {
    path: 'project-approved/:discount/:nameproject',
    component: ProjectApprovedComponent
  },
  {
    path: 'project-rejected/:nameproject',
    component: ProjectRejectedComponent
  },
  {
    path: ':store',
    component: StoreComponent,
    resolve: { pageFound: StoreResolver },
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'profile',
        component: ProfileComponent,
        children: [
          {
            path: '',
            redirectTo: 'detail',
            pathMatch: 'full'
          },
          {
            path: 'detail',
            component: ProfileDetailComponent
          },
          {
            path: 'settings',
            component: ProfileSettingsComponent
          },
          {
            path: 'notifications',
            component: NotificationsComponent
          },
          {
            path: 'contracts',
            canActivate: [AuthGuardBuyMode],
            component: ProfileContractsComponent
          },
          {
            path: 'address',
            canActivate: [AuthGuardBuyMode],
            component: ProfileAddressComponent
          },
          {
            path: 'paymentMethods',
            canActivate: [AuthGuardBuyMode],
            component: PaymentMethodsComponent
          },
          {
            path: 'checkprize',
            canActivate: [AuthGuardBuyMode],
            component: ProfileCheckprizeComponent
          }
        ]
      },
      {
        path: 'catalog',
        component: CatalogComponent,
        children: [
          {
            path: '',
            component: CategoryComponent
          },
          {
            path: ':id',
            component: CategoryComponent
          },
          {
            path: 'families/:id',
            component: FamilyComponent
          }
        ]
      },
      {
        path: 'accessories',
        component: AccessoryCatalogComponent,
        resolve: { categories: AccessoryCategoriesResolver },
        children: [
          {
            path: '',
            component: AccessoryCategoryComponent
          },
          {
            path: ':id',
            component: AccessoryCategoryComponent,
            resolve: {
              accessories: AccessoriesResolver,
            }
          }
        ]
      },
      {
        path: 'lists',
        component: ListsComponent,
        children: [
          {
            path: ':id',
            component: ListDetailComponent,
            canDeactivate: [CanDeactivatListGuard],
            resolve: {
              listDetail: ListService
            },
          }
        ]
      },
      {
        path: 'lists/:id/checkout',
        component: Checkout3CarrelliComponent,
        canActivate: [AuthGuardBuyMode],
          resolve: {
              cartDetail: CheckoutService,
              infoCheckout: CheckOutResolver,
              company: CompanyInfoResolver
          },
        data: { type: 'l' }
      },
      {
        path: 'quotations',
        component: ListsComponent,
        children: [
          {
            path: 'listQuotations',
            component: ListQuotationComponent,
          },
          {
            path: ':id',
            component: QuotationComponent,
            resolve: {
              detail: QuotationService
            },
            children: [
              {
                path: '',
                canDeactivate: [CanDeactivateQuotationGuard],
                component: QuotationDetailComponent,
              },
              {
                path: 'customer',
                canDeactivate: [CanDeactivateQuotationGuard],
                component: QuotationCustomerComponent
              },
              {
                path: 'opportunity',
                canDeactivate: [CanDeactivateQuotationGuard],
                component: QuotationOpportunityComponent,
              },
              {
                path: 'conditions',
                canDeactivate: [CanDeactivateQuotationGuard],
                component: QuotationDiscountComponent,
              },
              {
                path: 'pdf',
                component: QuotationPdfComponent,
              },
              {
                path: 'brochure',
                component: QuotationBrochureComponent,
              },
              {
                path: 'retail',
                component: QuotationRetailComponent,
              },
              {
                path: 'project',
                component: QuotationProjectComponent,
              },
              {
                path: 'attachments',
                component: QuotationAttachmentsComponent,
              },
              {
                path: 'share',
                component: QuotationsShareComponent,
              }
            ]
          }
        ]
      },
      {
        path: 'quotations/:id/checkout',
        component: Checkout3CarrelliComponent,
        canActivate: [AuthGuardBuyMode],
          resolve: {
            cartDetail: CheckoutService,
            infoCheckout: CheckOutResolver,
            company: CompanyInfoResolver,
          },
        data: { type: 'q' }
      },
      {
        path: 'quotations/:id/checkout-plain',
        component: CheckoutPlainComponent,
        canActivate: [AuthGuardBuyMode],
        resolve: {
          cartDetail: CheckOutPlainResolver,
          infoCheckout: CheckOutResolver,
          company: CompanyInfoResolver,
        },
        data: { type: 'q' }
      },
      {
        path: 'opportunities',
        component: ListsComponent,
        canActivate: [AuthGuardBuyMode],
        children: [
          {
            path: ':id',
            component: OpportunityComponent,
            resolve: {
              opportunity: OpportunityResolver,
              quotationDetail: CheckoutService
            },
            data: { type: 'q' }
          }]
      },
      {
        path: 'product/:id',
        component: ProductComponent,
        resolve: {
          product: ProductService,
        },
        data: {
          reuseComponent: false
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'product/:id/wizard',
        component: ProductWizardComponent,
        resolve: {
          product: ProductService
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'product/:id/wizard/:configurationId',
        component: ProductWizardComponent,
        resolve: {
          product: ProductService
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'product/:id/:configurationId',
        component: ProductComponent,
        resolve: {
          product: ProductService,
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'order-complete',
        canActivate: [AuthGuardBuyMode],
        component: OrderCompletedComponent,
      },
      {
        path: 'orders',
        loadChildren: () => import('../orders/orders.module').then(m => m.OrdersModule)
      }
    ]
  }
  // { path: '**', component: PageComponent }
];
