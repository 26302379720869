import { LogLevel } from "angular-auth-oidc-client";

export const environment = {
  production: false,
  staging: true,
  baseUrl: "https://macroluxstore.scpweb.it",
  urlBlob: "https://macroluxdevelopment.blob.core.windows.net/",
  stripePublicKey: "pk_test_7TyNbZ4uaaqcfzAvkGKpEn8X005J9BB2Hz",
  firebase: {
    apiKey: "AIzaSyAKpiWhRzZ6zVgew9zvvwk4dgnaExGBmDo",
    authDomain: "macroluxb2b.firebaseapp.com",
    databaseURL: "https://macroluxb2b.firebaseio.com",
    projectId: "macroluxb2b",
    storageBucket: "macroluxb2b.appspot.com",
    messagingSenderId: "148421399599",
    appId: "1:148421399599:web:f542c5d350f7eae6c662da"
  },
  identityUrl: 'https://devmacroluxidentity.scpweb.it',
  logLevel: LogLevel.Warn,
  clientIdPaypal: "AeZHoXpYU84guyd0cNwLIip-Ds_TdZ23tyJj4co1tKUNPZeMKVv6pTWU8TUJ2GUKJJhfmwmua0tQNFJV",
  enableRouteTracing: false
};
