import { PipeTransform, Pipe, ChangeDetectorRef, OnDestroy, Injectable } from '@angular/core';
import { StoreService } from '../store.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable()
@Pipe({
  name: 'localize',
  pure: false // required to update the value when the promise is resolved
})
export class LocalizeRouterPipe implements PipeTransform, OnDestroy {
  value: string | any[];
  lastKey: string | any[];
  onLangChange: Subscription;

  constructor(private storeService: StoreService, private _ref: ChangeDetectorRef) {

  }

  updateValue(query: string | any[], store?: any): void {
    this.value = this.storeService.createUrl(query, store);
    this.lastKey = query;
    this._ref.markForCheck();
  }

  transform(query: string | any[]): string | any[] {
    if (!query || query.length === 0) {
      return query;
    }

    if (equals(query, this.lastKey)) {
      return this.value;
    }

    this.updateValue(query);

    // subscribe to onLangChange event, in case the language changes
    if (!this.onLangChange) {
      this.onLangChange = this.storeService.translateService.onLangChange.subscribe((_event: LangChangeEvent) => {
        if (this.lastKey) {
          const store = this.storeService.getCurrentStore();
          this.lastKey = null; // we want to make sure it doesn't return the same value until it's been updated
          this.updateValue(store);
        }
      });
    }

    return this.value;
  }

  ngOnDestroy(): void {
    if (this.onLangChange) {
      this.onLangChange.unsubscribe();
    }
  }

}


/* tslint:disable */
/**
 * Determines if two objects or two values are equivalent.
 *
 * Two objects or values are considered equivalent if at least one of the following is true:
 *
 * * Both objects or values pass `===` comparison.
 * * Both objects or values are of the same type and all of their properties are equal by
 *   comparing them with `equals`.
 *
 * @param o1 Object or value to compare.
 * @param o2 Object or value to compare.
 * @returns true if arguments are equal.
 */
export function equals(o1: any, o2: any): boolean {
  if (o1 === o2) return true;
  if (o1 === null || o2 === null) return false;
  if (o1 !== o1 && o2 !== o2) return true; // NaN === NaN
  let t1 = typeof o1, t2 = typeof o2, length: number, key: any, keySet: any;
  if (t1 == t2 && t1 == 'object') {
    if (Array.isArray(o1)) {
      if (!Array.isArray(o2)) return false;
      if ((length = o1.length) == o2.length) {
        for (key = 0; key < length; key++) {
          if (!equals(o1[key], o2[key])) return false;
        }
        return true;
      }
    } else {
      if (Array.isArray(o2)) {
        return false;
      }
      keySet = Object.create(null);
      for (key in o1) {
        if (!equals(o1[key], o2[key])) {
          return false;
        }
        keySet[key] = true;
      }
      for (key in o2) {
        if (!(key in keySet) && typeof o2[key] !== 'undefined') {
          return false;
        }
      }
      return true;
    }
  }
  return false;
}
