import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { CatalogService } from '../../catalog.service';
import { StoreService } from '../../store.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ListService } from '../../list.service';

@Component({
  selector: 'quotation-custom-item',
  templateUrl: './custom-item.component.html',
  styleUrls: ['./custom-item.component.css']
})
export class CustomItemComponent implements OnInit, OnDestroy {

  @Input() item: any;
  @Input() list: any;
  @Input() section: any;
  @Input() sections: any[];

  @Output() onTotalChange = new EventEmitter<number>();
  @Output() onClone = new EventEmitter<any>();

  prezzoFinale: number = 0;
  inplaceSection: boolean = false;
  inplaceCurrentSection: string;
  inplaceAllowedSections: any[];

  shopMode: number = 1;

  translations: any = {};

  editNameMode: boolean = false;
  newName: string;

  srcNotUrl: SafeUrl = "/images/custom_item.jpg";

  private destroy$: Subject<boolean> = new Subject<boolean>();
  popperItem: any;
  @ViewChild('popperListContent', { static: true }) popperListContent: any;

  constructor(private sanitizer: DomSanitizer, private catalogService: CatalogService, 
    private storeService: StoreService, private listService: ListService) {
  }

  ngOnInit() {
    let transReq = marker(['articleProductCustomNotValid', 'articleProductInsertName', 'confirmDeleteItemFromQuotation', 'logoImageTooBig']);

    this.storeService.translateService.stream(transReq).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });
    this.editNameMode = !this.item.custom.name;

    this.catalogService.getShopMode().pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.shopMode = t;
      this.updatePrezzoFinale();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  updateDiscount() {
    let discountInverso = (1 - (this.item.discount || 0) / 100) * (1 - (this.item.discount2 || 0) / 100)
    * (1 - (this.item.discount3 || 0) / 100)
    this.item.custom.prezzoRiservato = discountInverso * this.item.custom.prezzoVendita;
    this.updatePrezzoFinale();
  }

  updatePriceListino() {
    this.updateDiscount();
  }

  updatePriceRiservato() {
    if (this.item.custom.prezzoRiservato > this.item.custom.prezzoVendita) {
      this.item.custom.prezzoRiservato = this.item.custom.prezzoVendita;
    }
    this.item.discount = 100 - this.item.custom.prezzoRiservato * 100 / this.item.custom.prezzoVendita;
    this.item.discount2 = 0;
    this.item.discount3 = 0;
    this.updatePrezzoFinale();
  }

  updatePrezzoFinale() {
    if (!this.item.quantity) {
      this.item.quantity = 1;
    }

    let allOk: boolean = this.item.custom.prezzoAcquisto && this.item.custom.prezzoVendita && this.item.custom.prezzoRiservato && this.item.quantity;
    if (allOk) {
      if (this.shopMode == 0) {
        this.prezzoFinale = (this.item.custom.prezzoAcquisto * this.item.quantity);
      }
      else {
        this.prezzoFinale = (this.item.custom.prezzoRiservato * this.item.quantity);
      }
    } else {
      this.prezzoFinale = 0;
      this.item.esWarning = true;
    }
    if (allOk) {
      this.item.esWarning = !(allOk && (this.item.custom.name != undefined && this.item.custom.name != "") &&
        (this.item.custom.unitaMisura != undefined && this.item.custom.unitaMisura != ""));
    } else {
      this.prezzoFinale = 0;
      this.item.esWarning = true;
    }
    this.onTotalChange.emit(this.shopMode);
  }

  setPopperItem(item) {
    this.inplaceCurrentSection = this.section.key;
    this.inplaceAllowedSections = this.sections.filter(t => t.key != "").map(t => {
      return {
        value: t.key,
        label: t.value.name
      }
    });
    this.inplaceSection = false;
    this.popperItem = item;
  }

  changeSection() {
    if (this.inplaceCurrentSection === this.section.key) {
      //no changes
      this.inplaceSection = false;
      return;
    }
    this.section.value.items = this.section.value.items.filter(t => t.configurationId != this.item.configurationId);
    if (this.section.key != "") {
      this.list.section.find(t => t.sectionId == this.section.key).item = this.list.section.find(t => t.sectionId == this.section.key).item.filter(t => t != this.item.configurationId);
    }
    
    this.sections.find(t => t.key == this.inplaceCurrentSection).value.items.push(this.item);
    if (this.inplaceCurrentSection != "") {
      this.list.section.find(t => t.sectionId == this.inplaceCurrentSection).item.push(this.item.configurationId);
    }
      
    this.list.items = this.sections.map(d => d.value.items);
  }

  removeItem(configurationId) {
    if (confirm(this.translations["confirmDeleteItemFromQuotation"])) {
      this.list.items = this.list.items.filter(i => i.configurationId != configurationId);
      this.section.value.items = this.section.value.items.filter(i => i.configurationId != configurationId);
      this.popperListContent.hide();
      this.onTotalChange.emit(this.shopMode);
    }
  }

  editName(onOff: boolean) {
    this.newName = this.item.custom.name;
    this.editNameMode = onOff;
    this.popperListContent.hide();
  }

  updateName() {
    this.item.custom.name = this.newName;
    this.editNameMode = false;
  }

  updateFile(files: FileList) {
    let file = files[0];
    if (file.size > 614400) {
      alert(this.translations.fileTooBig);
      return;
    }
    let name = this.item.configurationId + "." + file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name;

    const newFile: File = new File([file], name, { type: file.type });

    this.item.custom.urlImg = undefined;
    this.item.custom.file = newFile;
    this.item.custom.fileName = name;
    this.srcNotUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
  }

  deleteFile() {
    this.item.custom.file = undefined;
    this.item.custom.urlImg = undefined;
    this.item.custom.fileName = ""; //non funziona il check su proprieta' non definite.
    this.srcNotUrl = "/images/custom_item.jpg";
  }

  clone(item) {
    this.listService.cloneQuotationItem(this.list.id, item).subscribe(t => {

      this.popperListContent.hide();
      this.onClone.emit(t);
    });
  }
}
