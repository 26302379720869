<div class="pt-3" style="height: 100vh;">
    <div class="container mb-3 is-flex is-justify-content-center is-align-items-center" style="height: calc(100% - 0.75rem); background-color: white;">
        <div class="block">
            <div class="has-text-centered title is-1">Progetto rifiutato</div>
            <div class="is-flex is-align-items-center mx-6">
                <div class="has-text-danger">
                    <i class="fa-regular fa-circle-xmark fa-10x"></i>
                </div>
                <div class="ml-3 has-text-danger is-size-3">
                    Hai rifiutato il progetto per il preventivo {{nameProject}}
                </div>
            </div>
        </div>
    </div>
</div>