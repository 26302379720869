import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ProductService } from '../../product.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Subject } from 'rxjs';
import { StoreService } from '../../store.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'item-substitute',
    templateUrl: './item-substitute.component.html',
    styleUrls: ['./item-substitute.component.css']
})
export class ItemSubstituteComponent implements OnInit, OnChanges, OnDestroy {


    @Input()
    itemSuggest: any[] = [];

    @Input()
    item: any;

    @Input()
    ready: boolean;

    @Output()
    close = new EventEmitter<any>();

    attributesWeight: any[];
    destroy$: Subject<boolean> = new Subject<boolean>();
    translations: any = {};

    constructor(private productService: ProductService, private storeService: StoreService) { }

    ngOnInit(): void {
        let translationRequired = marker(['differenceFor', 'differenceOnlyFor', 'and', 'noAttributeDifference', 'model']);
        this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
            this.translations = data;
        });

        this.productService.getAttributes().subscribe((att) => {
            this.attributesWeight = att.filter(a => a.weight).sort((att1, att2) => {

                if (att1.weight > att2.weight) {
                        return -1;
                    }
                    return 1;
            });

        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    closeSuggest() {

        this.close.emit({
            item: this.item
        });
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!this.item || !this.attributesWeight || !this.itemSuggest || this.itemSuggest.length == 0) return;

        for (let itemSugg of this.itemSuggest) {
            itemSugg.textDifference = undefined;
            let nameDifference = [];
            if (itemSugg.productModel != this.item.productModel) {
                nameDifference.push(this.translations.model);
            }
            for (let attToCheck of this.attributesWeight) {
                let indexAttToCheckItem = this.item.attributes.findIndex(att => att.sysName == attToCheck.sysName);
                let indexAttToSubstituteItem = itemSugg.attributes.findIndex(att => att.sysName == attToCheck.sysName);
                if (indexAttToCheckItem == -1 && indexAttToSubstituteItem == -1) {
                    continue; // entrambi non hanno l'attributo
                }
                //uno dei due non ha l'attributo!
                if (indexAttToCheckItem == -1 || indexAttToSubstituteItem == -1) {
                    nameDifference.push(attToCheck.name);
                    continue;
                }
                if (this.item.attributes[indexAttToCheckItem].value != itemSugg.attributes[indexAttToSubstituteItem].value) {
                    
                    nameDifference.push(attToCheck.name);
                }
            }
            if (nameDifference.length == 0) {
                itemSugg.textDifference = this.translations.noAttributeDifference;
                continue;
            }

            if (nameDifference.length == 1) {
                itemSugg.textDifference = this.translations.differenceOnlyFor + " " + nameDifference[0];
                continue;
            }
            if (nameDifference.length > 1) {
                itemSugg.textDifference = this.translations.differenceFor + " " + nameDifference.join(", ");
                let pos = itemSugg.textDifference.lastIndexOf(',');
                itemSugg.textDifference = itemSugg.textDifference.substring(0, pos)
                     + " " + this.translations.and + " " +
                    itemSugg.textDifference.substring(pos + 1)
            }
        }
        //for (let article of this.itemSuggest) {
        //    let indexAttribute = 

        //    let difference = article.attributes
        //        .filter(attr => !this.item.attributes.some(attr2 => attr2.sysName == attr.sysName))
        //        .concat(this.item.attributes.filter(x => !article.attributes.includes(x)));
        //}

    }

    selectSuggest(suggestedItem) {

        let a = this.item;
        this.close.emit({
            suggestedItem: suggestedItem,
            item: this.item
        });
    }
}
