import { Component, OnInit, inject } from '@angular/core';
import { SharedService } from '../shared.service';
import { StoreService } from '../store.service';
import { Subject, combineLatest } from 'rxjs';
import { environment } from '../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { FormatString } from '../shared/functions';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { arrayRemove, doc, updateDoc } from 'firebase/firestore';
import { docData, Firestore } from '@angular/fire/firestore';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  private notificationsSubject = new Subject<any>();

  first: number = 0;
  rows: number = 5;

  urlBlob: string;
  userdata: any = {};
  translations: any = {};
  notifiche: any[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();

  currentPage: number = 0;
  totalItems: number;
  totalPage: number;

  urlImageToShowFullScreen: string;
  showFullImage: boolean;

  firestore: Firestore = inject(Firestore);

  constructor(private sharedService: SharedService, private storeService: StoreService, private router: Router,
    private authService: AuthenticationService) {


  }

  ngOnInit(): void {

    this.urlBlob = environment.urlBlob;

    let translationRequired = marker(['notificationOrderRegisterBody', 'notificationOrderRegisterInEsolverTitle', 'notificationProjectBody', 'notificationRejectProjectBody',
      'notificationQuotationSharedBody', 'notificationQuotationSharedTitle', 'notificationProjApprovedTitle', 'notificationProjRejectTitle', 'notificationUseCheckPrizeTitle',
      'notificationUseCheckPrizeBody', 'notificationUseCheckPrizeBodyMultipleOrder', 'notificiationNewArticleAnthologyBody', 'notificiationNewArticleAnthologyStarBody',
      'notificiationNewArticleAnthologyStarTitle', 'notificiationNewArticleAnthologyTitle', 'notificiationNewArticleBody', 'notificiationNewArticleTitle']);

    this.userdata = this.authService.getUserProfile();

    let docInfo = doc(this.firestore, `user/${this.userdata.sub}`);

    combineLatest([this.storeService.translateService.stream(translationRequired),
      docData<any>(docInfo), this.notificationsSubject.asObservable()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {

        this.translations = data[0];
        let rawNotification = [];

        if (data[1] && data[1].length > 0) {
          rawNotification = (data[1][0] as any).notifications as any[];
        }

        let infoAllNotification = data[2];

        let pagedNotification = infoAllNotification.items;
        this.totalItems = infoAllNotification.totalItems;
        this.totalPage = Math.ceil(this.totalItems / this.rows);


        for (let not of pagedNotification) {
          if (not.type == 0) {
            not.titleNotification = this.translations[not.idTitle];
            not.abstractNotification = FormatString(this.translations[not.idText], not.parameterText);

          } else { //not.type == 1
            not.titleNotification = not.title.find(t => t.language == this.storeService.translateService.currentLang).label;
            not.abstractNotification = not.abstract.find(t => t.language == this.storeService.translateService.currentLang).label;
            if (not.url && not.url.length > 0) {
              not.urlNotification = not.url.find(t => t.language == this.storeService.translateService.currentLang).label;
            }
          }

          let notificationFireBase = rawNotification.find(n => n.id == not.id);
          if (notificationFireBase) {
            not.notRead = true;
            not.date = notificationFireBase.date;
          } else {
            not.notRead = false;
            not.date = undefined;
          }
        }

        this.notifiche = pagedNotification;
      });
    this.onPageChange({
      page : 0,
      first : 0,
      rows : 5,
      pageCount : 1
    });
  }

  deleteNotification(notifica: any) {
    let notification = doc(this.firestore, `user/${this.userdata.sub}`);
    
    debugger;
    updateDoc(notification, { notifications : arrayRemove({ "id": notifica.id, "date": notifica.date })}).then((res) => {
      debugger;
    }).catch((err) => {
      debugger;
     });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onPageChange(event: any) {
    this.currentPage = event.page;
    this.sharedService.getNotificationsForUser(this.currentPage, event.rows).subscribe(data => {
      this.notificationsSubject.next(data);
    });
  }

  openNotificationUrl(notifica: any) {
    if (notifica.urlRouting || notifica.urlNotification) {
      if (notifica.type == 0) {
        this.router.navigateByUrl(this.storeService.createUrl(notifica.urlRouting) as string);

      } else {
        window.open(notifica.urlNotification, notifica.openUrlBlank ? "_blank" : "_parent");
      }
    }
  }

  openFullImage(url: string) {
    this.urlImageToShowFullScreen = url;
    this.showFullImage = true;
  }
}
