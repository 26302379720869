import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { ListService } from "../list.service";
import { CatalogService } from "../catalog.service";
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { StoreService } from '../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'list-wizard-item',
  templateUrl: './list-wizarditem.component.html',
  styleUrls: ['./list-wizarditem.component.css'],
})
export class ListWizarditemComponent implements OnInit, OnDestroy {

  @Input() item: any;
  @Input() list: any;

  @Output() onTotalChange = new EventEmitter<number>();

  @Output() onInvalidItem = new EventEmitter();

  prezzoFinale: number = 0;
  accessoryTotal: number = 0;
  showAccessories: boolean = false;

  shopMode: number = 1;
  translations: any = {};

  popperItem: any;
  @ViewChild('popperListContent', { static: true }) popperListContent: any;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router, private listService: ListService, private catalogService: CatalogService,
    private storeService: StoreService) {
  }

  ngOnInit() {
    let translationRequired = marker(['articleProductNotValid', 'confirmDeleteItemFromList']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.catalogService.getShopMode().pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.shopMode = t;
      this.getPrezzoFinale();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges() {
    this.accessoryTotal = 0;
    this.listService.fillItemDetail(this.item);
    this.getPrezzoFinale();
  }

  toggleAccessories() {
    this.showAccessories = !this.showAccessories;
  }

  setPopperItem(item) {
    this.popperItem = item;
  }

  gotoConfiguration(item) {
    this.listService.setCurrentList(this.list);
    this.catalogService.populateAllCache(this.item.idBrand).pipe(take(1)).subscribe(() => {
      this.router.navigate(this.storeService.createUrl(["/product", item.productModel, "wizard", item.configurationId.substring(1)]) as any[]);
    });
  }

  removeItem(configurationId) {
    if (confirm(this.translations["confirmDeleteItemFromList"])) {
      this.list.items = this.list.items.filter(i => i.configurationId != configurationId);
      this.popperListContent.hide();
      this.onTotalChange.emit(this.shopMode);
    }
  }

  getPrezzoFinale() {
    let counter = 0;
    let counterPrezzo = 0;
    let counterPrezzoRiservato = 0;
    let allOk = true;
    if (this.item.childItems) {
      for (let cItem of this.item.childItems) {
        counterPrezzo += cItem.prezzo * cItem.quantity;
        counterPrezzoRiservato += cItem.prezzoRiservato * cItem.quantity;
        allOk = allOk && cItem.prezzoRiservato && cItem.prezzo && cItem.quantity;
        if (cItem.prezzo) {
          if (this.shopMode == 0)
            counter += (cItem.prezzoRiservato * cItem.quantity);
          else
            counter += (cItem.prezzo * cItem.quantity);
        }
      }
    } else {
      this.prezzoFinale = 0;
      this.item.prezzo = 0;
      this.item.prezzoRiservato = 0;
      this.item.esWarning = true;
      this.onInvalidItem.emit();
      return;
    }

    if (allOk) {
      this.prezzoFinale = counter;
      this.item.prezzo = counterPrezzo;
      this.item.prezzoRiservato = counterPrezzoRiservato;
      this.item.esWarning = false;
      this.onTotalChange.emit(this.shopMode);
    } else {
      this.prezzoFinale = 0;
      this.item.prezzo = 0;
      this.item.prezzoRiservato = 0;
      this.item.esWarning = true;
      this.onInvalidItem.emit();
    }
  }

  updateComponentsTotal() {

  }

  goToProduct() {
    this.catalogService.populateAllCache(this.item.idBrand).subscribe(() => {
      this.router.navigate(this.storeService.createUrl(['/product', this.item.productModel]) as any[]);
    });
  }

}
