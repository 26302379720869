import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { CatalogService } from "../catalog.service";
import { QuotationService } from '../quotation.service';
import { StoreService } from '../store.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-quotation-project',
  templateUrl: './quotation-project.component.html',
  styleUrls: ['./quotation-project.component.css']
})
export class QuotationProjectComponent implements OnInit, OnDestroy {

  shopMode: number = 1;
  userClaims: any;
  baseQuotation: any;

  totaleListino: number;
  totaleRiservato: number;
  margine: number;
  customer: any;
  dateModel: any;
  minDate: Date;

  isSending: boolean;

  //anthologyWarn: string;
  //requireTotalBiggerThan: string

  projectDiscountValue: number;

  destroy$: Subject<boolean> = new Subject<boolean>();

  translations: any = {};

  constructor(private authService: AuthenticationService, private quotationService: QuotationService,
    private route: ActivatedRoute, private catalogService: CatalogService, private storeService: StoreService) {

  }

  ngOnInit() {
    let translationReq = marker(['anthologyWarn', 'requireTotalBiggerThan']);

    this.storeService.translateService.stream(translationReq).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.minDate = new Date();

    this.quotationService.baseQuotation.pipe(takeUntil(this.destroy$)).subscribe(baseQuotation => {
      this.catalogService.getShopMode().pipe(takeUntil(this.destroy$)).subscribe(t => this.shopMode = t);
      this.userClaims = this.authService.getUserProfile();
      this.customer = baseQuotation.customer;
      this.baseQuotation = baseQuotation;
      if (this.baseQuotation.projectDate) {
        this.dateModel = new Date(this.baseQuotation.projectDate);
      }
      this.projectDiscountValue = this.quotationService.calculateProjectDiscount();
    });
    this.quotationService.quotationDetail.pipe(takeUntil(this.destroy$)).subscribe(() => {
      [this.totaleRiservato, this.totaleListino, this.margine] = this.quotationService.calculateTotals();
    });
  }

  ngOnDestroy(): void {
    this.quotationService.reloadQuotationDetail(); //TODO refactoring
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  register() {
    this.isSending = true;
    this.quotationService.requestProject(this.baseQuotation, this.dateModel).subscribe(t => {
      this.isSending = false;
    });
  }

  onProjectDateChange() {
    //TODO check datetime zone handling between client and server
    if (this.baseQuotation.projectDiscount > 0) {
      this.baseQuotation.projectDate = this.dateModel;
    }
  }

  unRegister() {
    this.isSending = true;
    this.quotationService.unRegisterProject(this.baseQuotation).subscribe(() => {
      this.isSending = false;
    });
  }
}
