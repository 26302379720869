import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { DndDropEvent, DropEffect } from "ngx-drag-drop";
import { MessageService } from 'primeng/api';
import { GuidGenerator } from '../shared/functions';
import { clone, uniq, without } from 'lodash';
import { switchMap, delay, takeUntil } from 'rxjs/operators';
import { CatalogService } from "../catalog.service";
import { QuotationService } from '../quotation.service';
import { ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { StoreService } from '../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GetQuotationDifference } from './quotation.guard';
import ObjectID from 'bson-objectid';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'quotation-detail',
  templateUrl: './quotation-detail.component.html',
  styleUrls: ['./quotation-detail.component.css'],
})
/** QuotationDetail component*/
export class QuotationDetailComponent implements OnInit, OnDestroy, GetQuotationDifference {

  //lists: any[];
  baseQuotation: any;

  translations: any = {};

  colorChecker: any = { counter: 0 };

  shopMode: number;
  listTotalReserved: number = 0;
  listTotalPublic: number = 0;
  listMargin: number = 0;

  newSection: string;
  addSectionMode1: boolean;

  editTitleSectionMode = false;
  newTitleSection: string;

  dataShown: Map<string, any>;

  cuSectionPopper: any;

  cuItemId: any;
  cuIndex: any;
  cuSection: any;
  dragStart: boolean = false;

  cuSectionId: any;
  cuSectionIndex: any;
  dragSectionStart: boolean = false;

  globalDiscount: any;
  discountProj: number;
  calcTotalsSubject$: Subject<number> = new Subject<number>();

  @ViewChild('popperSection', { static: true }) popperSection: any;

  requireModeBuy: string;
  articleNotValidInCart: string;

  isViewer: boolean = false;
  isAdmin: boolean = false;
  isAgent: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  takeUserData$: Subject<boolean> = new Subject<boolean>();

  descriptionDiscountApplied: string;

  private translationsReady$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private messageDifferentKelvinToShow$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  collassato: boolean = false;


  constructor(
    private authService: AuthenticationService, private catalogService: CatalogService,
    private messageService: MessageService, private renderer: Renderer2,
    private quotationService: QuotationService, private storeService: StoreService) {

  }

  ngOnInit() {
    let translationRequired = marker(['requireModeBuy', 'articleNotValidInCart', 'kelvinCheckToastTitle', 'agentCanNotCheckOut',
      'kelvinCheckToastMessage', 'confirmDeleteSection', 'proceedWillDestroyModifyQuotation']);

    combineLatest([this.messageDifferentKelvinToShow$.asObservable(), this.translationsReady$.asObservable()])
      .pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.messageService.clear('kelvin');
        this.messageService.add({
          key: 'kelvin',
          severity: 'warn',
          summary: this.translations.kelvinCheckToastTitle,
          detail: this.translations.kelvinCheckToastMessage + ': ' + this.colorChecker.allColors.join(", ") + "°K",
          sticky: true
        });

      });

    this.catalogService.getShopMode().pipe(takeUntil(this.destroy$)).subscribe(t => this.shopMode = t);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
      this.translationsReady$.next(true);
    });

    const claims = this.authService.getUserProfile();
    this.isViewer = claims.view_only && claims.view_only == "true";
    this.isAdmin = !this.isViewer && claims.role && (claims.role.indexOf("Super Admin") > -1);
    this.isAgent = !this.isAdmin && claims.role && (claims.role.indexOf("Agent") > -1);

    this.quotationService.baseQuotation.pipe(switchMap((t) => {
      this.baseQuotation = t;
      this.modifyDataShow();
      return this.quotationService.getCompany(this.baseQuotation.assignedCodCliFor);
    }),
      takeUntil(this.destroy$)).subscribe(customer => {
        this.descriptionDiscountApplied = customer.desScontoListino;
      });

    this.calcTotalsSubject$.pipe(switchMap((useless: number) => {
      return of(useless).pipe(delay(100));
    }), takeUntil(this.destroy$)).subscribe(_data => {
      this.calculateFromObservable();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    this.takeUserData$.unsubscribe();
  }

  differenceQuotation(): boolean {
    return this.quotationService.getDifferenceStatus();
  }

  alertMessage(): string {
    return this.translations.proceedWillDestroyModifyQuotation;
  }

  reloadDetail(): void {
    this.quotationService.reloadQuotationDetail();
  }

  onDragStartSection(_event, index, section) {
    this.renderer.addClass(document.body, 'dragging');
    this.cuSectionId = section.key;
    this.cuSectionIndex = index;
    this.dragSectionStart = true;
  }

  onDragStart(_event, index, section, itemId) {
    this.renderer.addClass(document.body, 'dragging');
    this.cuItemId = itemId;
    this.cuIndex = index;
    this.cuSection = section;
    this.dragStart = true;
  }


  onDragEndSection(_event) {
    this.renderer.removeClass(document.body, 'dragging');
    this.cuSectionId = undefined;
    this.cuSectionIndex = undefined;
    this.dragSectionStart = false;
  }

  onDragEnd(_event) {
    this.renderer.removeClass(document.body, 'dragging');

    this.cuItemId = undefined;
    this.cuIndex = undefined;
    this.cuSection = undefined;
    this.dragStart = false;
  }

  onDraggedSection(effect: DropEffect) {
    if (effect === "move") {
      //nota, questo evento viene eseguito DOPO onDrop
      this.modifyDataShow();
    }

  }

  //la lista sono gli elementi nella sezione
  onDragged(index: number, list: any[], effect: DropEffect) {

    if (effect === "move") {
      //nota, questo evento viene eseguito DOPO onDrop, quindi list contiene un elemento doppio, che vado a rimuovere
      list.splice(index, 1);
      //essendo eseguito -dopo- ondrop e' il posto giusto per update
      for (let key of Array.from(this.dataShown.keys())) {

        let indexSec = this.baseQuotation.section.findIndex(s => s.sectionId == key);
        if (indexSec > -1) {
          this.baseQuotation.section[indexSec].item = this.dataShown.get(key).items.map(i => i.configurationId);
        }
      }
      this.baseQuotation.items = [].concat.apply([], Array.from(this.dataShown.values()).map(d => d.items));
    }
  }

  onDropSection(event: DndDropEvent, index: number) {
    if (event.dropEffect === "copy" || event.dropEffect === "move") {

      //l'oggetto di event non e' lo stesso di quello di baseQuotation, quindi devo cercarlo
      let itemIndex = this.baseQuotation.section.findIndex(s => s.sectionId == this.cuSectionId);
      if(itemIndex == -1) {
        debugger;
        return; //capire perche' pare verificarsi
      }
      this.baseQuotation.section.splice(index, 0, this.baseQuotation.section[itemIndex]);
      if (index > itemIndex) {
        this.baseQuotation.section.splice(itemIndex, 1);//rimuovo la copia
      } else {
        this.baseQuotation.section.splice(itemIndex + 1, 1);//rimuovo la copia
      }
    }
  }

  //qua intera sezione
  onDrop(event: DndDropEvent, section: any, index: number) {

    if (section && (event.dropEffect === "copy"
      || event.dropEffect === "move")) {

      let item = event.data;
      //sono in coda
      if (index == -1) {
        section.value.items.push(item);
      } else {
        section.value.items.splice(index, 0, item);
      }
    }
  }

  editTitleSection(start) {
    if (start == true) {
      this.newTitleSection = clone(this.cuSectionPopper.value.name);
      this.editTitleSectionMode = true;
      this.popperSection.hide();
    } else {
      this.newTitleSection = undefined;
      this.editTitleSectionMode = false;
    }
  }

  updateTitleSection() {
    this.cuSectionPopper.value.name = this.newTitleSection;

    this.baseQuotation.section.find(s => s.sectionId == this.cuSectionPopper.key).name = this.newTitleSection;

    this.cuSectionPopper = undefined;
    this.newTitleSection = undefined;
    this.editTitleSectionMode = false;
  }

  addSection1(show: boolean): void {
    this.newSection = "";
    this.addSectionMode1 = show;
  }

  createSection(): void {
    if (!this.newSection || this.newSection.length == 0) return;

    this.addSectionMode1 = false;
    let section = {
      sectionId: GuidGenerator.first8CharGuid(),
      name: this.newSection,
      description: "",
      item: []
    };
    if (!this.baseQuotation.section) {
      this.baseQuotation.section = [];
    }

    this.baseQuotation.section.push(section);

    this.addSectionMode1 = false;
    this.newSection = undefined;

    this.modifyDataShow();

  }

  openPopperSection(_event: any, section: any) {
    this.cuSectionPopper = section;
    this.cuSection = section;
    this.editTitleSectionMode = false;
    section.popperOpen = true;
  }

  closePopperSection() {
    this.cuSection.popperOpen = false;
    this.cuSection = undefined;
  }

  removeSection(): void {
    if (confirm(this.translations["confirmDeleteSection"])) {
      if (this.baseQuotation.section) {
        let index = this.baseQuotation.section.findIndex(s => s.sectionId == this.cuSection.key);

        if (index != -1) {

          let items = this.baseQuotation.section[index].item;
          if (items) {
            this.baseQuotation.items = this.baseQuotation.items.filter(item => !items.includes(item.configurationId))
          }
          this.baseQuotation.section.splice(index, 1);
        }
      }
      this.modifyDataShow();
    }
    this.popperSection.hide();
  }

  private modifyDataShow() {
    let oldDataShown = this.dataShown;
    this.dataShown = new Map<string, any>();
    this.colorChecker = { counter: 0 };

    if (this.baseQuotation.section) {
      for (let sect of this.baseQuotation.section) {
        let oldCollapsed = false;
        if (oldDataShown) {
          let oldSection = oldDataShown.get(sect.sectionId);
          if (oldSection) {
            oldCollapsed = oldSection.collapsed;
          }
        }
        this.dataShown.set(sect.sectionId, { name: sect.name, collapsed: oldCollapsed, items: new Array<any>() });
      }
    }

    this.dataShown.set("", { name: "", collapsed: false, items: new Array<any>() });

    for (let item of this.baseQuotation.items) {
      let sectionItemId: string = "";
      let posInSection: number = -1;
      if (this.baseQuotation.section) {
        for (let sect of this.baseQuotation.section) {
          if (sect.item) {
            posInSection = sect.item.findIndex(i => i == item.configurationId);
            if (posInSection != -1) {
              sectionItemId = sect.sectionId;
              break;
            }
          }
        }
      }
      let sectionToAddItem = this.dataShown.get(sectionItemId);
      if (posInSection == -1) {
        sectionToAddItem.items.push(item);
      } else {
        sectionToAddItem.items.splice(posInSection, 0, item);
      }
      this.dataShown[sectionItemId] = sectionToAddItem;

    }
  }

  checkout(_) {
    this.quotationService.performCheckout();
  }

  onItemChange() {
    this.calcTotalsSubject$.next(0);
  }

  calculateFromObservable() {
    [this.listTotalReserved, this.listTotalPublic, this.listMargin] = this.quotationService.calculateTotals();

    this.discountProj = this.quotationService.calculateProjectDiscount();
  }

  addCustomItem() {
    let item = {
      type: 3, quantity: 1, discount: this.baseQuotation.globalDiscount, configurationId: GuidGenerator.first8CharGuid(),
      custom: { prezzoRiservato: 0, prezzoVendita: 0, prezzoAcquisto: 0, unitaMisura: 'pz', fileName: '' }
    };
    this.baseQuotation.items.push(item);
    this.modifyDataShow();
  }

  checkKelvin(event) {
    this.colorChecker.counter++;
    if (!event.colors) {
      this.toastKelvin();
      return;
    }

    let sectionFound = false;

    if (this.baseQuotation.section) {
      for (let sect of this.baseQuotation.section) {
        if (sect.item) {
          let sectionColors = sect.item.find(t => t == event.configurationId);
          if (sectionColors) {
            this.colorChecker[sect.sectionId] = uniq((this.colorChecker[sect.sectionId] ? this.colorChecker[sect.sectionId] : []).concat(event.colors));
            sectionFound = true;
            break;
          }
        }
      }
    }

    if (!sectionFound)
      this.colorChecker["default"] = uniq((this.colorChecker["default"] ? this.colorChecker["default"] : []).concat(event.colors));

    var keys = without(Object.keys(this.colorChecker), "allColors", "error", "counter");
    for (let sectionKey of keys) {
      let values = this.colorChecker[sectionKey];
      this.colorChecker.allColors = uniq((this.colorChecker.allColors ? this.colorChecker.allColors : []).concat(values));
      this.colorChecker.error = this.colorChecker.allColors && this.colorChecker.allColors.length > 1;
    }
    this.toastKelvin();
  }

  onCloneItem(event) {
    this.baseQuotation.items.push(event);
    this.modifyDataShow();
  }

  addNote() {
    if (!this.baseQuotation.noteDaInviare) {
      this.baseQuotation.noteDaInviare = [];
    }
    this.baseQuotation.noteDaInviare.push({
      id: ObjectID.generate(),
      text: "",
      tipoStampaCommento: 0
    })
  }

  deleteNote(id: string) {
    this.baseQuotation.noteDaInviare = this.baseQuotation.noteDaInviare.filter(n => n.id != id);
  }

  toggleCollapsed(section: any) {

    section.value.collapsed = !section.value.collapsed;
    this.dataShown.get(section.key).collapsed = section.value.collapsed;
  }

  private toastKelvin() {
    if (this.colorChecker.counter === this.baseQuotation.items.length) {
      if (this.colorChecker.error) {
        this.messageDifferentKelvinToShow$.next(true);
      } else {
        this.messageService.clear('kelvin');
      }
    }
  }

  collapseAllSection() {

    this.collassato = true;
    this.dataShown.forEach((value, key) => {
      if (key != "") {
        value.collapsed = true;
      }
    });
  }

  expandAllSection() {
    this.collassato = false;
    this.dataShown.forEach((value, key) => {
      if (key != "") {
        value.collapsed = false;
      }
    });
  }
}
