<div style="padding-left:1em; padding-right:1em;">
  <div class="item" *ngIf="item.type == 3; else stdItem">
    <div class="columns">
      <div class="column is-narrow">
        <figure class="image is-96x96">
          <img *ngIf="item.custom.urlImg" fallback-src="https://via.placeholder.com/96x96" query="width=96&height=96"
            [cdnImg]="item.custom.urlImg" [productId]="quotationId" type="custom">
          <img *ngIf="!item.custom.urlImg" style="max-height: 100%; max-width: 100%; width:auto; margin:auto;"
            src="/images/custom_item.jpg">
        </figure>
      </div>
      <div class="column has-content-vertically-aligned">
        <div class="subtitle is-size-6">
          <div class="columns">
            <div class="column">
              <div class="title is-size-5">{{item.custom.name}}</div>
            </div>
            <div class="column is-narrow has-text-right">
              <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzoRiservato">{{prezzoFinale |
                currency:'EUR'}}</span>
            </div>
          </div>
        </div>
        <div class="columns info">
          <div class="column is-narrow">
            <span class="is-size-7" [translate]="'quantity'">Quantità</span><br />
            <span class="is-size-5 has-text-weight-semibold">{{item.quantity | number : '0.0-2' }}</span>
          </div>
          <div class="column">
            <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span><br />
            <span class="is-size-5 has-text-weight-semibold">{{item.priceToEvaluate | currency:'EUR'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #stdItem>
    <div class="item">
      <div class="columns">
        <div class="column is-narrow">
          <figure class="image is-96x96" [class.accessory]="item.type == 1">
            <img fallback-src="https://via.placeholder.com/96x96" query="width=96&height=96&mode=crop"
              *ngIf="item.type == 0" [cdnImg]="item.image" [productId]="item.productModel">
            <img fallback-src="https://via.placeholder.com/96x96" query="width=96&height=96&mode=crop"
              *ngIf="item.type == 1" [cdnImg]="item.image" type="accessory">
            <img fallback-src="https://via.placeholder.com/96x96" query="width=96&height=96&mode=crop"
              *ngIf="item.type == 2" [cdnImg]="item.childItems[0].image" [productId]="item.childItems[0].productModel">
          </figure>
        </div>
        <div class="column has-content-vertically-aligned">
          <div class="is-size-6">
            <div class="columns">
              <div class="column">
                <div class="is-size-5 has-text-weight-semibold">
                  <span>{{item.idBrand | brandName | async }}&nbsp;</span>{{item.name}}
                  <span *ngIf="item.type == 2">&nbsp;-&nbsp;
                    <span [translate]="'configuration'">configurazione</span>
                  </span>
                  <span class="is-size-6" *ngIf="item.categoryName">&nbsp;{{item.categoryName}}</span>
                </div>
                <span *ngIf="item.type == 2">{{item.configurationId}}</span>
                <span *ngIf="item.type != 2">{{item.sku}}</span>
                <span *ngIf="item.anthology || item.anthologyStar || item.vms"
                  class=" is-size-7 has-text-uppercase  has-text-right has-text-info has-text-weight-semibold">
                  &nbsp;<span>Anthology</span>
                  <span *ngIf="item.anthologyStar" class="has-text-warning has-text-weight-bold">Star</span>
                  <span *ngIf="item.vms" class="has-text-warning has-text-weight-bold">VMS</span>
                </span>
                &nbsp;<i class="fas fa-info-circle fa-sm" *ngIf="item.description" [pTooltip]="item.description"
                  tooltipStyleClass="m-tooltip"></i>
              </div>
              <div class="column is-narrow has-text-right">
                <div class="has-text-right">
                  <span class="is-size-5 has-text-weight-semibold">{{prezzoFinale | currency:'EUR'}}</span>

                  <ng-container *ngIf="item.type != 2 && item.childItems?.length > 0">
                    <br />
                    <span class="subtitle is-size-7" [translate]="'accessoriesTotal'">di cui accessori:</span><br />
                    <span class="title is-size-6 has-text-weight-semibold">{{accessoryTotal | currency:'EUR'}}</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="columns info">
            <div class="column is-narrow">
              <span class="is-size-7" [translate]="'quantity'">Quantità</span><br />
              <span class="is-size-5 has-text-weight-semibold">{{item.quantity | number : '0.0-2' }}{{item.um |
                lowercase}}</span>
            </div>
            <div class="column is-narrow" *ngIf="item.type != 2">
              <span class="is-size-7" [translate]="'available'"></span><br />
              <span class="is-size-5 has-text-weight-semibold"
                [class.has-text-info]="item.availability >= item.quantity"
                [class.has-text-warning]="item.availability < item.quantity">{{item.availability}}</span>
            </div>
            <div class="column" *ngIf="item.type != 2">
              <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span><br />
              <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzoRiservato">
                {{item.priceToEvaluate | currency:'EUR'}}</span>
              <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzoRiservato">N/A</span>
            </div>
            <div class="column is-narrow" *ngIf="item.suggestSubstitute">
              <span class="is-size-7 has-text-warning has-text-weight-bold"
                [translate]="'insufficientArticles'"></span><br />
              <a class="button is-warning is-small" (click)="askSubstitute()">
                <span [translate]="'askArticleReplacement'"></span>&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
          <div *ngIf="item.childItems?.length > 0">
            <a (click)="toggleAccessories()" class="has-text-weight-semibold">
              <i class="fas fa-angle-down"></i>&nbsp;&nbsp;{{item.childItems.length}} <span
                [translate]="'accessoryAdded'">accessori aggiuntivi</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-template>


  <div class="column is-12 child-item" *ngIf="item.childItems?.length > 0 && showAccessories">
    <div class="columns" *ngFor="let acc of item.childItems">
      <div class="column is-narrow">
        <div style="width:96px"></div>
      </div>
      <div class="column is-narrow">
        <figure class="image is-64x64" [class.accessory]="item.type == 1">
          <img fallback-src="https://via.placeholder.com/64" query="width=64&height=64&mode=crop" *ngIf="acc.type == 0"
            [cdnImg]="acc.image" [productId]="acc.productModel">
          <img fallback-src="https://via.placeholder.com/64" query="width=64&height=64&mode=crop" *ngIf="acc.type == 1"
            [cdnImg]="acc.image" type="accessory">
        </figure>
      </div>
      <div class="column noRightpadding">
        <div class="columns">
          <div class="column">
            <span class="has-text-weight-semibold is-size-6">
              {{acc.name}}&nbsp;
              <span class="is-size-7" *ngIf="acc.categoryName">{{acc.categoryName}}&nbsp;</span>
              <span class="is-size-7">{{acc.sku}}</span>
            </span>
            <span *ngIf="acc.anthology || acc.anthologyStar || acc.vms"
              class="has-text-uppercase is-size-7 has-text-right has-text-info has-text-weight-semibold">
              &nbsp;<span>Anthology</span>
              <span *ngIf="acc.anthologyStar" class="has-text-warning has-text-weight-bold">Star</span>
              <span *ngIf="acc.vms" class="has-text-warning has-text-weight-bold">VMS</span>
            </span>
            &nbsp;<i class="fas fa-info-circle fa-sm" *ngIf="acc.esolverDescription" [pTooltip]="acc.esolverDescription"
              tooltipStyleClass="m-tooltip"></i>
          </div>
          <div class="column is-narrow has-text-right">
            <span class="is-size-6 has-text-weight-semibold" *ngIf="acc.prezzoRiservato">{{acc.priceToEvaluate *
              acc.quantity | currency:'EUR'}}</span>
          </div>
        </div>
        <div class="columns">
          <div class="column is-narrow">
            <span class="is-size-7" [translate]="'quantity'">Quantità</span><br />
            <span class="is-size-6 has-text-weight-semibold">{{acc.quantity | number : '0.0-2' }}{{acc.um |
              lowercase}}</span>
          </div>
          <div class="column is-narrow" *ngIf="acc.um != 'M'">
            <span class="is-size-7" [translate]="'available'"></span><br />
            <span class="is-size-6 has-text-weight-semibold" [class.has-text-info]="acc.availability >= acc.quantity"
              [class.has-text-warning]="acc.availability < acc.quantity">{{acc.availability}}</span>
          </div>
          <div class="column">
            <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span><br />
            <span class="is-size-6 has-text-weight-semibold" *ngIf="acc.prezzoRiservato">{{acc.priceToEvaluate |
              currency:'EUR'}}</span>
            <span class="is-size-6 has-text-weight-semibold" *ngIf="!acc.prezzoRiservato">N/A</span>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>