<div class="inner-discount-container" *ngIf="baseQuotation">
  <div class="is-divider is-medium" [attr.data-content]="'saleDiscount' | translate"></div>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label"><span [translate]="'rowDiscount'">Sconto riga</span>&nbsp;<span class="icon is-small">
          <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
        </span></label>
    </div>
    <div class="field-body">
      <div class="field has-addons is-narrow">
        <div class="control">
          <p-inputNumber [(ngModel)]="globalDiscount" placeholder="0%" [disabled]="baseQuotation.listState != 0"
            [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
        </div>
        <div class="control">
          <button type="submit" class="button is-info" (click)="setDiscount()"
            [disabled]="baseQuotation?.listState != 0" [translate]="'set'">
            Imposta
          </button>
        </div>
      </div>
      <div class="field">
        <div class="control discount-switch">
          <input id="shopModeSwitch" [(ngModel)]="baseQuotation.hideDiscount" type="checkbox" name="shopModeSwitch"
            class="switch">
          <label for="shopModeSwitch">
            <span *ngIf="baseQuotation?.hideDiscount" [translate]="'hideDiscount'">Sconto nascosto (prezzi netti)</span>
            <span *ngIf="!baseQuotation?.hideDiscount" [translate]="'showDiscount'">Sconto visibile</span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">&nbsp;</label>
    </div>
    <div class="field-body">
      <div class="field">
        <p style="margin-bottom: 1.5em;">
          <i class="fas fa-lg fa-fw fa-exclamation-triangle"></i>&nbsp;<span [translate]="'discountWillBeOverWritten'">
            Lo sconto verrà impostato su tutti gli articoli.
            Eventuali sconti presenti saranno sovrascritti.
          </span>
        </p>
        <a class="has-text-danger" (click)="removeDiscount()" *ngIf="baseQuotation?.listState == 0"
          [translate]="'deleteDiscountEveryRow'">Elimina lo sconto per tutte le righe</a>
      </div>
    </div>
  </div>
  <div class="field is-horizontal" style="margin-top: 3em;">
    <div class="field-label is-normal">
      <label class="label"><span [translate]="'extraDiscount'">Extra Sconto</span>&nbsp;<span class="icon is-small">
          <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
        </span>
      </label>
    </div>
    <div class="field-body">
      <div class="field is-expanded">
        <div class="field has-addons">
          <div class="control">
            <p-inputNumber [(ngModel)]="extraDiscount" (ngModelChange)="updateDiscountVariant(item)" 
              placeholder="0%" [disabled]="baseQuotation.listState != 0"
              [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
          </div>
          <div class="control">
            <button type="submit" class="button is-info" (click)="setExtraDiscount()"
              [disabled]="baseQuotation?.listState != 0" [translate]="'set'"></button>
          </div>
        </div>
        <p class="help" [translate]="'unconditionalDiscountEndDocument'">
          sconto incondizionato a fine documento
        </p>
        <br />
        <a class="has-text-danger" (click)="removeExtraDiscount()" *ngIf="baseQuotation?.listState == 0"
          [translate]="'removeExtraDiscount'">Elimina lo sconto per tutte le righe</a>
      </div>
    </div>
  </div>
  <div class="is-divider is-medium" [attr.data-content]="'condTrasporto' | translate"></div>
  <input class="input" [ngModel]="baseQuotation.condTrasporto" (ngModelChange)="baseQuotation.condTrasporto=$event">
  <div class="is-divider is-medium" [attr.data-content]="'condPagamento' | translate"></div>
  <input class="input" [(ngModel)]="baseQuotation.condVendita">
  <div class="is-divider is-medium" [attr.data-content]="'noteUpper' | translate"></div>
  <textarea class="textarea" [(ngModel)]="baseQuotation.note">
  </textarea>
</div>