import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatsService {


  constructor(private httpClient: HttpClient) { }


   //#region Performance Macrolux
   getPerformancePuntualitaForCompany(codCliFor: number): Observable<any> {
    let params = new HttpParams();

    params = params.set('nomeFiltro', 'Cliente');
    params = params.set('valueFiltro', `${codCliFor}`);

    return this.httpClient.get(`/api/platformOrder/ordini-stats`, { params: params });
  }

  getPerformanceQtaAnthologyForCompany(codCliFor: number): Observable<any> {
    return this.httpClient.get<any>(`/api/stats/performance-qta-anthology/${codCliFor}`);
  }

  getPerformanceSpedizioniForCompany(codCliFor: number): Observable<any> {
    return this.httpClient.get<any>(`/api/stats/performance-spedizioni/${codCliFor}`);
  }

  //#endregion

  //#region Performance Cliente
  getPerformanceEquilibrioForCompany(codCliFor: number): Observable<any> {
    return this.httpClient.get<any>(`/api/stats/performance-equilibrio/${codCliFor}`);
  }

  getPerformancePreventiviForCompany(codCliFor: number): Observable<any> {
    return this.httpClient.get<any>(`/api/stats/performance-preventivi-cliente/${codCliFor}`);
  }

  getPerformanceOrdinatoForCompany(codCliFor: number): Observable<any> {
    return this.httpClient.get<any>(`/api/stats/performance-ordinato/${codCliFor}`);
  }

  getPerformanceBudgetForCompany(codCliFor: number): Observable<any> {
    return this.httpClient.get<any>(`/api/stats/performance-budget/${codCliFor}`);
  }

  //#endregion


  //#region Performance Agente
  getPerformanceBudgetForAgente(userId:string): Observable<any> {
    return this.httpClient.get<any>(`/api/stats/performance-budget-agente/${userId}`);
  }

  getPerformanceOrdinatoForAgente(userId:string): Observable<any> {
    return this.httpClient.get<any>(`/api/stats/performance-agente-ordinato/${userId}`);
  }

  getPerformancePreventiviForAgente(userId:string): Observable<any> {
    return this.httpClient.get<any>(`/api/stats/performance-preventivi-agente/${userId}`);
  }

  getPerformanceUsersForAgente(userId:string): Observable<any> {
    return this.httpClient.get<any>(`/api/stats/performance-users-agente/${userId}`);
  }

  //#endregion


  //#region Admin
  getPerformanceUtenti(): Observable<any> {
    return this.httpClient.get<any>(`/api/stats/users`);
  }

  getExcelUser (): Observable<any> {
    const options = { headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }), responseType: 'blob' as 'json' };
    return this.httpClient.get<any>('/api/user/getUserActiveInactive', options);
  }

  //#endregion

}
