import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-contracts',
  templateUrl: './profile-contracts.component.html',
  styleUrls: ['./profile-contracts.component.css']
})
export class ProfileContractsComponent implements OnInit {

  today: Date;

  constructor(public translateService: TranslateService) {

  }

  ngOnInit() {
    this.today = new Date();
  }

  getPdfContract() {
    let lang = this.translateService.currentLang;
    window.open(environment.urlBlob + `cms/Contract_${lang}.pdf`);
  }

}
