<div class="is-divider is-medium" [attr.data-content]="'stdB2b' | translate" [attr.id]="'defaultPayMode'"></div>
  <div id="spinner-wrapper" *ngIf="loadingInfoPagamento">
    <div class="spinner"></div>
  </div>
  <ng-container *ngIf="!loadingInfoPagamento">
    <div class="is-size-6" [translate]="'noDefaultPaymentMethod'" *ngIf="!infoCustomerPagamentoStdB2b.affidato">
      Nessun metodo di pagamento di default
    </div>
    <div class="is-size-6">
      {{infoCustomerPagamentoStdB2b.codiceCondizPagamentoDescr}}<span *ngIf="infoCustomerPagamentoStdB2b.affidato">
        &nbsp;-&nbsp;<span [translate]="'fidoResiduo'"></span>:&nbsp;{{infoCustomerPagamentoStdB2b.fido | currency:'EUR' }}
      </span>
    </div>
  </ng-container>

  <div class="is-divider is-medium" [attr.data-content]="'bankTransferLowerCase' | translate" [attr.id]="'bankTransfer'"></div>
<div class="is-size-6">{{bank}} - {{iban}} BIC/SWIFT : {{swift}}</div>
<div class="is-divider is-medium" [attr.data-content]="'creditCardPlural' | translate" [attr.id]="'creditCards'"></div>
<table class="table full-width is-hoverable">
  <thead *ngIf="creditCards">
    <tr>
      <th [translate]="'cardBrand'">
      </th>
      <th [translate]="'numberCard'">
      </th>
      <th [translate]="'creditCardHolder'">
      </th>
      <th [translate]="'zipCode'">
      </th>
      <th [translate]="'expireAt'">
      </th>
      <th style="width:4.5em; text-align:center">
        &nbsp;
      </th>
    </tr>
  </thead>
  <tbody *ngIf="creditCards">
    <tr *ngFor="let card of creditCards">
      <td>
        {{card.brand | titlecase}}
      </td>
      <td>
        XXXX-XXXX-XXXX-{{card.lastFourNumber}}
      </td>
      <td>
        <ng-container *ngIf="creditCardTemp?.id != card.id">
          {{card.holderCard}}
        </ng-container>
        <ng-container *ngIf="creditCardTemp?.id == card.id">
          <input maxlength="100" class="input" [(ngModel)]="creditCardTemp.holderCard" />
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="creditCardTemp?.id != card.id">
          {{card.zipCode}}
        </ng-container>
        <ng-container *ngIf="creditCardTemp?.id == card.id">
          <p-inputMask [styleClass]="'input'" mask="99999" [(ngModel)]="creditCardTemp.zipCode"></p-inputMask>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="creditCardTemp?.id != card.id">
          {{card.expireMonth  | number:'2.0-0'}}/{{card.expireYear}}
        </ng-container>
        <div *ngIf="creditCardTemp?.id == card.id">
          <p-inputNumber [showButtons]="true" [min]="1" [max]="12" [size]="2" [inputStyleClass]="'input'" [(ngModel)]="creditCardTemp.expireMonth"></p-inputNumber>/
          <p-inputNumber [showButtons]="true" [min]="2000" [max]="2099" [size]="4" [inputStyleClass]="'input'" [(ngModel)]="creditCardTemp.expireYear"></p-inputNumber>
        </div>
      </td>
      <td>
        <ng-container *ngIf="creditCardTemp?.id != card.id; else editingButton">
          <a (click)="editing(card)">
            <i class="fas fa-pen"></i>
          </a>
          &nbsp;
          <a (click)="deleteCard(card.id)">
            <i class="fas fa-trash-alt"></i>
          </a>
        </ng-container>
        <ng-template #editingButton>
          <a (click)="saveMethod(creditCardTemp)">
            <i class="fas fa-check"></i>
          </a>
          &nbsp;
          <a (click)="creditCardTemp = undefined">
            <i class="fas fa-times"></i>
          </a>
        </ng-template>
      </td>
    </tr>
  </tbody>
  <tbody *ngIf="loading">
    <tr>
      <td>
        <div id="spinner-wrapper">
          <div class="spinner"></div>
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td colspan="6">
        <button class="button is-info" style="margin-top:1rem;" (click)="insertNewCard = true" [translate]="'add'" [ngClass]="{'is-hidden':insertNewCard}"></button>
        <div [ngClass]="{'is-hidden':!insertNewCard}">
          <div class="is-divider is-medium " style="margin:2rem 0 1rem;margin-right:.75em;" [attr.data-content]="'NUOVA CARTA'"></div>
          <div class="columns" style="margin:0;">
            <div class="column">
              <label class="label" [translate]="'creditCardHolder'"></label>
              <div class="control is-expanded">
                <input class="input" [placeholder]=" 'name' | translate " type="text" [(ngModel)]="intestatarioCarta">
              </div>
              <label class="label" style="margin-top: 1.5rem;" [translate]="'creditCardData'">Dati della carta</label>
              <div class="control is-expanded">
                <div id="formNewCreditCard"></div>
              </div>
            </div>
            <div class="column">
              <div class="notification is-clearfix" style="display: flex;padding-right: 1.25rem;margin-top: 2rem;">
                <div class="is-size-6" style="margin-right: 1.5rem;" [translate]="'creditCardStripeText'">
                </div>
                <div class="spacer"></div>
                <div>
                  <img class="is-pulled-right" src="/images/powered_by_stripe.png" />
                </div>
              </div>
              <div class="field" style="margin-top: 1.5rem;">
                <div class="control is-pulled-right">
                  <div class="field has-addons">
                    <a class="button" (click)="insertNewCard = false" *ngIf="creditCards && creditCards.length > 0">Chiudi</a>
                    &nbsp;
                    <a class="button is-info" (click)="addCard()" [translate]="'saveNewCard'" [ngClass]="{'is-loading':sendNewCard}"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tfoot>
</table>
<p-toast key="toastCardErrorModify"></p-toast>
<p-confirmDialog key="confirmDeleteCardFromProfile" icon="pi pi-exclamation-triangle"></p-confirmDialog>
