import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KeyCurrentStore } from './shared/costants';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { escapeRegExp, filter as filterLodash, find, method } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private storeMap: any = {
    "italy": "it",
    "europe": "en",
    "france": "fr",
    "austria": "de",
    "germany": "de",
    "swiss": "de",
    "spain": "es",
    "australia": "en",
    "asia": "en",
    "mideast": "en"
  }

  private storeMapLocale: any = {
    "italy": "it",
    "europe": "en",
    "france": "fr",
    "austria": "de-AT",
    "germany": "de",
    "swiss": "de-CH",
    "spain": "es",
    "australia": "en-AU",
    "asia": "en",
    "mideast": "en"
  }

  storeDefault: string = "europe";
  langDefault: string = "en";
  localeDefault: string = "en";

  currentStore: string;

  private _localeSubject: BehaviorSubject<string> = new BehaviorSubject("en");

  cacheCountry : {
    lang: string,
    countries: any[]
  } = undefined;


  constructor(public translateService: TranslateService, private httpClient: HttpClient) {
  }

  loadCache() : Observable<any> {
    let lang = this.translateService.currentLang;
    if(lang) {
      lang = lang.toLowerCase();
    } else {
      lang = 'en';
    }

    return this.httpClient.get<any[]>(`assets/countries/${lang}/world.json`).pipe(map((data) => {
      this.cacheCountry = {
        lang : lang,
        countries : data
      };
    }));
  }


  getCurrentStore() {
    return this.currentStore;
  }

  public setStoreIfExist(storeTocheck: string): boolean {
    if (this.storeMap[storeTocheck]) {
      if (this.currentStore == storeTocheck) return true;

      this.currentStore = storeTocheck;
      this.translateService.use(this.storeMap[storeTocheck]);

      sessionStorage.setItem(KeyCurrentStore, this.currentStore);

      this._localeSubject.next(this.storeMapLocale[storeTocheck]);

      return true;
    } else {
      return false;
    }
  }

  public getLocaleObservable() {
    return this._localeSubject.asObservable();
  }

  public createUrl(path: string | any[], store?: string) {
    if (!store)
      store = this.currentStore;

    if (!path || path.length === 0) {
      return path;
    }

    if (typeof path === 'string') {

      let pathSplitted = path.split("/");
      pathSplitted = pathSplitted.filter(p => p);

      if (pathSplitted[0] == store) {
        return "/" + pathSplitted.join("/");
      }
      
      if (this.storeMap[pathSplitted[0]]) {
        pathSplitted.shift();
      }
      
      pathSplitted.unshift(store);
      return "/" + pathSplitted.join("/");
    } else {
      let pathStore = `/${store}`;
      if (path[0] == pathStore) return path;
      let pathArrayTrust: string[] = []
      pathArrayTrust.push(pathStore);
      for (let pathString of path) {
        if (!pathString) {
          continue;
        }
        if (typeof pathString != 'string') {
          pathArrayTrust.push(pathString.toString());
          continue;
        }

        let pathSplitted = pathString.split("/");
        pathSplitted = pathSplitted.filter(p => p);
        for (let pathStringTrust of pathSplitted) {
          pathArrayTrust.push(pathStringTrust);
        }
      }
      return pathArrayTrust;
    }
  }

  
  searchCountries(query: string) : Observable<any[]> {

    let lang = this.translateService.currentLang;
    if(lang) {
      lang = lang.toLowerCase();
    } else {
      lang = 'en';
    }
    let matchRegXp = new RegExp(`(^|\\s)${escapeRegExp(query)}`, 'i');

    if(this.cacheCountry && this.cacheCountry.lang == lang) {
      let values = filterLodash(this.cacheCountry.countries, method('name.match', matchRegXp));
      return of(values);
    }
    return this.httpClient.get<any[]>(`assets/countries/${lang}/world.json`).pipe(map((data) => {
      this.cacheCountry = {
        lang : lang,
        countries : data
      };

      let values = filterLodash(this.cacheCountry.countries, method('name.match', matchRegXp));
      return values;
    }));
  }

  getCountryByCode(countryCode: string) : Observable<any> {

    let lang = this.translateService.currentLang;
    if(lang) {
      lang = lang.toLowerCase();
    } else {
      lang = 'en';
    }
    if(!countryCode || countryCode.length != 2) {
      countryCode = 'it'
    } else {
      countryCode = countryCode.toLowerCase();
    }

    return this.httpClient.get<any>(`assets/countries/${lang}/world.json`).pipe(map((data) => {
      let value = find(data, { 'alpha2': countryCode });
      return value;
    }));
  }
}
