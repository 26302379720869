import { NgModule } from '@angular/core';
import { FillHeightDirective } from './fill-height.directive';
import { ResizeService } from './resize.service';

@NgModule({
  imports: [],
  declarations: [
    FillHeightDirective
  ],
  providers: [
    ResizeService
  ],
  exports: [
    FillHeightDirective
  ]
})
export class FillHeightModule {
}

