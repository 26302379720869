<ng-container *ngIf="filteredFamilies">
  <h2 class="title is-4">{{category?.name}}</h2>
  <h3 class="subtitle" (click)="modePopper.toggle($event)">
    <span class="pointer" *ngIf="mode == 0"><span [translate]="'listFamilies'">Elenco famiglie</span>&nbsp;&nbsp;<i
        class="fas fa-angle-down has-text-info"></i></span>
    <span class="pointer" *ngIf="mode == 1"><span [translate]="'listModels'">Elenco modelli</span>&nbsp;&nbsp;<i
        class="fas fa-angle-down has-text-info"></i></span>
  </h3>
  <ng-container *ngFor="let sub of category.childrens">
    <div class="section-margined" *ngIf="filteredFamilies[sub.id].length > 0">
      <h2 class="title-hr is-size-4 is-uppercase font-condensed has-text-weight-bold"><span>{{sub.name}}</span></h2>
      <div class="columns is-multiline" *ngIf="mode === 0; else prod_template">
        <div class="column is-3" *ngFor="let fam of filteredFamilies[sub.id] | orderBy:'name'">
          <div class="card pointer card-equal-height" [routerLink]="['/catalog', 'families', fam.id]  | localize"
            [queryParams]="{sub: sub.id}">
            <div class="card-image">
              <figure class="image is-square">
                <img fallback-src="https://via.placeholder.com/600x600" query="width=600&height=600&mode=crop"
                  [cdnImg]="fam.image2" type="family">
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-5">{{fam.name}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #prod_template>
        <div class="columns is-multiline equalheight">
          <ng-container *ngFor="let fam of filteredFamilies[sub.id] | orderBy:'name'">
            <div class="column is-3" *ngFor="let prod of fam.productsModel | filterBy: ['category']: sub.id">
              <div class="card pointer card-equal-height" [routerLink]="['/product', prod.id] | localize">
                <div class="card-image">
                  <figure class="image is-square">
                    <img fallback-src="https://via.placeholder.com/600x600" query="width=600&height=600&mode=crop"
                      [cdnImg]="prod.images[0]" [productId]="prod.id">
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-5">{{prod.name}}</p>
                      <p class="subtitle is-6">{{fam.name}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </ng-container>
  <p-overlayPanel #modePopper>
    <div>
      <a class="has-text-dark" (click)="setMode(0)" [translate]="'listFamilies'">Elenco famiglie</a>
    </div>
    <div>
      <a class="has-text-dark" (click)="setMode(1)" [translate]="'listModels'">Elenco modelli</a>
    </div>
  </p-overlayPanel>
</ng-container>