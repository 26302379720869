import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { StoreService } from '../store.service';
import { takeWhile, switchMap, map } from 'rxjs/operators';
import { QuotationService } from '../quotation.service';

@Component({
  selector: 'app-search-companies',
  templateUrl: './search-companies.component.html',
  styleUrls: ['./search-companies.component.css']
})
export class SearchCompaniesComponent implements OnInit, OnDestroy {

  private componentActive = true
  searchResultsCompanies: any[];
  private searchTermsCompany$ = new Subject<string>();
  
  @Input() company: any;
  @Output() selectedCompany = new EventEmitter<any>();

  translations: any = {};

  constructor(private quotationService: QuotationService, private storeService: StoreService) { }

  ngOnInit() {
    let translationRequired = marker(['projectRegistered', 'noCompanyFound', 'companyClientName',
      'quotationShared', 'quotationSharedFrom', 'requireModeBuy', 'statusInProgress']);

    this.storeService.translateService.stream(translationRequired).pipe(takeWhile(() => this.componentActive)).subscribe((data) => {
      this.translations = data;
    });

    this.searchTermsCompany$.pipe(
      takeWhile(() => this.componentActive),
      switchMap((term: string) => {
        return this.quotationService.searchImpersonation(term);
      })
    ).subscribe(data => {
      this.searchResultsCompanies = data;
    });
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  searchCompanies(event) {
    this.searchTermsCompany$.next(event.query);
  }

  onChange($event) {
    this.selectedCompany.emit($event)
  }


}
