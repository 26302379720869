import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CatalogService } from "../catalog.service";
import { CheckoutService } from "../checkout.service";
import { StoreService } from '../store.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.css'],
})
export class OpportunityComponent implements OnInit {

  tab: number = 1;
  userClaims: any;
  baseQuotation: any;
  opportunity: any;
  totaleListino: number;
  totaleRiservato: number;
  projDiscountValue: number;

  margine: number;
  shopMode: number;

  itemsList: any[];

  constructor(private authService: AuthenticationService, private router: Router, private checkoutService: CheckoutService,
    private route: ActivatedRoute, private catalogService: CatalogService, private storeService: StoreService) {
  }

  ngOnInit() {
    this.catalogService.getShopMode().subscribe(t => this.shopMode = t);
    this.userClaims = this.authService.getUserProfile();
    this.opportunity = this.route.snapshot.data.opportunity;
    this.baseQuotation = this.route.snapshot.data.quotationDetail;
    this.totaleListino = this.checkoutService.getTotal(this.baseQuotation, 1);
    this.totaleRiservato = this.checkoutService.getTotal(this.baseQuotation, 0);

    this.projDiscountValue = this.checkoutService.getProjectDiscountValue(this.baseQuotation);

    this.margine = (this.totaleListino - this.totaleRiservato) / this.totaleListino;
    this.itemsList = this.createItemsList();
  }

  checkout(quotation) {
    this.router.navigate(this.storeService.createUrl(["quotations", quotation.id, "checkout"]) as any[]);
  }

  private createItemsList() {
    let onDemand = this.baseQuotation.onDemand;

    for (let item of this.baseQuotation.anthology) {
      let existingItemIndex = onDemand.findIndex(t => t.sku == item.sku);
      if (existingItemIndex > -1)
        onDemand[existingItemIndex].quantity = onDemand[existingItemIndex].quantity + item.quantity;
      else
        onDemand.push(item);
    }

    for (let item of this.baseQuotation.reserve) {
      let existingItemIndex = onDemand.findIndex(t => t.sku == item.sku);
      if (existingItemIndex > -1)
        onDemand[existingItemIndex].quantity = onDemand[existingItemIndex].quantity + item.quantity;
      else
        onDemand.push(item);
    }

    return onDemand;
  }

}
