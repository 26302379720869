import { ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QuotationService } from '../quotation.service';

@Injectable()
export class CompanyInfoResolver  {
  constructor(private quotationService: QuotationService) { }

  resolve(route: ActivatedRouteSnapshot) {

    let id = route.paramMap.get('id');
    let typeCheckOut = route.data['type'];
    return this.quotationService.getInformationCustomer(id, typeCheckOut);
  }

}
