<div *ngIf="baseQuotation">
  <div *ngIf="baseQuotation.items.length > 0;else no_items">
    <div *ngIf="baseQuotation.listState == 0" class="is-size-6 top-bar-sticky" [style.top]="isAdmin ? '222px' : '205px'">
      <a (click)="addSection1(true)" *ngIf="!addSectionMode1" [translate]="'addSection'">Aggiungi sezione</a>
      <a [translate]="'collapseAll'" *ngIf="!collassato" (click)="collapseAllSection()"
        style="margin-left: 2.7em">Collassa sezioni</a>
      <a [translate]="'expandAll'" *ngIf="collassato" (click)="expandAllSection()" style="margin-left: 2.7em">Espandi
        sezioni</a>
      <a (click)="addCustomItem()" *ngIf="(isAdmin) && !addSectionMode1" style="margin-left: 2.7em"
        [translate]="'addCustomItem'">Aggiungi articolo speciale</a>
      <form *ngIf="addSectionMode1">
        <div class="field has-addons is-fullwidth">
          <div class="control">
            <input class="input" type="text" [(ngModel)]="newSection" name="newSection"
              [placeholder]="'exampleSection' | translate">
          </div>
          <div class="control">
            <button type="submit" class="button is-info" (click)="createSection()">
              <i class="fas fa-check"></i>
            </button>
          </div>
          <div class="control">
            <button type="submit" class="button" (click)="addSection1(false)">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div>
      <div *ngFor="let sectionArticle of dataShown|getValues; let firstSection = first; let indexSection = index"
        [dndDraggable]="sectionArticle.value" dndEffectAllowed="move" (dndEnd)="onDragEndSection($event)"
        (dndStart)="onDragStartSection($event, indexSection, sectionArticle)"
        [dndDisableIf]="baseQuotation.listState != 0" (dndMoved)="onDraggedSection('move')">
        <div class="drop-zone" [class.no-border]="firstSection" dndDropzone
          [dndDisableIf]="dragStart || (dragSectionStart && (cuSectionId == sectionArticle.key || cuSectionIndex+1 == indexSection ))"
          (dndDrop)="onDropSection($event, indexSection)">
          <div dndPlaceholderRef class="dndplaceHolder"></div>
        </div>
        <div class="level" hoverIntent (onHoverIntent)="sectionArticle.hovering = true"
          (mouseleave)="sectionArticle.hovering = false" *ngIf="sectionArticle.value.name">
          <div class="level-left">
            <div class="level-item">
              <div [class.draggable]="baseQuotation.listState == 0"
                *ngIf="!editTitleSectionMode || !cuSectionPopper || cuSectionPopper.key != sectionArticle.key">
                <a (click)="toggleCollapsed(sectionArticle)" class="fas"
                  [class.fa-minus]="!sectionArticle.value.collapsed"
                  [class.fa-plus]="sectionArticle.value.collapsed"></a>
                &nbsp;
                <span class="is-size-5">{{sectionArticle.value.name}}</span>&nbsp;
                <a [popper]="popperSection" [popperPlacement]="'bottom'" [popperApplyArrowClass]="'arrow'"
                  [popperTrigger]="'click'" [popperHideOnClickOutside]="true"
                  (popperOnShown)="openPopperSection($event, sectionArticle)"
                  (popperOnHidden)="closePopperSection($event, sectionArticle)"
                  class="has-text-weight-semibold is-size-6"
                  *ngIf="baseQuotation.listState == 0 && (sectionArticle.hovering || sectionArticle.popperOpen)">
                  Modifica&nbsp;<i class="fas fa-angle-down"></i>
                </a>
                &nbsp;

              </div>
              <form
                *ngIf="editTitleSectionMode && cuSectionPopper && cuSectionPopper.key == sectionArticle.key && !cuSection">
                <div class="field has-addons">
                  <div class="control">
                    <input class="input" type="text" [(ngModel)]="newTitleSection"
                      name="{{sectionArticle.key}}+'newTitleSection'">
                  </div>
                  <div class="control">
                    <button type="submit" class="button is-info" [disabled]="!newTitleSection"
                      (click)="updateTitleSection()">
                      <i class="fas fa-check"></i>
                    </button>
                  </div>
                  <div class="control">
                    <button type="submit" class="button" (click)="editTitleSection(false)">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div [class.is-hidden]="sectionArticle.value.collapsed">
          <ng-container *ngIf="sectionArticle.value.items.length > 0; else section_empty">
            <div [dndDraggable]="item" dndEffectAllowed="move"
              *ngFor="let item of sectionArticle.value.items; index as i; first as f" (dndEnd)="onDragEnd($event)"
              (dndStart)="onDragStart($event, i, sectionArticle, item.configurationId)"
              [dndDisableIf]="dragSectionStart || !item.hoverImage || baseQuotation.listState != 0"
              (dndMoved)="onDragged(i, sectionArticle.value.items, 'move')">
              <div class="drop-zone" [class.no-border]="f" dndDropzone
                [dndDisableIf]="dragSectionStart || (dragStart && (cuSection.key == sectionArticle.key && (cuItemId == item.configurationId || i == cuIndex+1 )))"
                (dndDrop)="onDrop($event, sectionArticle, i)">
                <div dndPlaceholderRef class="dndplaceHolder"></div>
              </div>
              <quotation-item *ngIf="item.type == 0 || item.type == 1" [item]="item" class="columns"
                (onTotalChange)="onItemChange($event)" [sections]="dataShown|getValues" [section]="sectionArticle"
                (onKelvinCheck)="checkKelvin($event)" (onClone)="onCloneItem($event)" [list]="baseQuotation">
              </quotation-item>
              <quotation-wizard-item *ngIf="item.type == 2" [item]="item" class="columns"
                (onTotalChange)="onItemChange($event)" [sections]="dataShown|getValues" [section]="sectionArticle"
                (onKelvinCheck)="checkKelvin($event)" [list]="baseQuotation"></quotation-wizard-item>
              <quotation-custom-item *ngIf="item.type == 3" [item]="item" class="columns"
                (onTotalChange)="onItemChange($event)" [sections]="dataShown|getValues" [section]="sectionArticle"
                [list]="baseQuotation" (onClone)="onCloneItem($event)"></quotation-custom-item>
            </div>
          </ng-container>
          <div class="drop-zone no-border" dndDropzone
            [dndDisableIf]="dragSectionStart || (dragStart && (cuSection.key == sectionArticle.key && cuIndex+1 == sectionArticle.value.items.length))"
            (dndDrop)="onDrop($event, sectionArticle, -1)">
            <div dndPlaceholderRef class="dndplaceHolder"></div>
          </div>
        </div>
        <ng-template #section_empty>
          <p class="is-size-6" *ngIf="sectionArticle.value.name" [translate]="'emptySection'">Sezione vuota</p>
        </ng-template>
        <hr class="margin-smaller" *ngIf="sectionArticle.value.name" />
      </div>
      <hr />
      <ng-container *ngIf="shopMode == 0 && baseQuotation?.items.length > 0">
        <div class="columns">
          <div class="column is-3">
            <ng-container *ngTemplateOutlet="note"></ng-container>
          </div>
          <div class="column">
            <div class="border-column is-info">
              <table class="table is-fullwidth">
                <tr>
                  <td colspan="2">
                    <div style="margin-bottom: 1.125rem;">
                      <span class="title is-3 has-text-weight-bold" [translate]="'sellMode'">Modalità Vendi</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="title is-5" [translate]="'total'">Totale</div>
                  </td>
                  <td>
                    <div class="title is-5" style="white-space: nowrap;">{{listTotalPublic | currency:'EUR'}}</div>
                  </td>
                </tr>
                <tr *ngIf="baseQuotation.extraDiscount > 0">
                  <td>
                    <div class="title is-5" [translate]="'unconditionalDiscount'">Sconto incondizionato</div>
                  </td>
                  <td>
                    <div class="title is-5" style="white-space: nowrap;">{{(listTotalPublic *
                      baseQuotation.extraDiscount / 100) | currency:'EUR'}}</div>
                  </td>
                </tr>
                <tr *ngIf=" baseQuotation.extraDiscount > 0">
                  <td>
                    <div class="title is-4" [translate]="'total'">Totale</div>
                  </td>
                  <td>
                    <div class="title is-4" style="white-space: nowrap;">{{(listTotalPublic - (listTotalPublic *
                      baseQuotation.extraDiscount / 100)) | currency:'EUR'}}</div>
                  </td>
                </tr>
              </table>
            </div>

          </div>
          <div class="column">
            <div class="border-column is-warning">
              <table class="table  is-fullwidth">
                <tr>
                  <td colspan="2">
                    <div style="margin-bottom: 1.125rem">
                      <span class="title is-3 has-text-warning has-text-weight-bold" [translate]="'buyMode'">Modalità
                        Acquista</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="title is-5" [translate]="'subtotal'">Totale parziale</div>
                  </td>
                  <td>
                    <div class="title is-5" style="white-space: nowrap;">{{listTotalReserved | currency:'EUR'}}</div>
                  </td>
                </tr>
                <tr *ngIf="baseQuotation.projectDiscount > 0">
                  <td>
                    <div class="title is-5" [translate]="'projectDiscount'">Sconto progetto</div>
                  </td>
                  <td>
                    <div class="title is-5" style="white-space: nowrap;">{{discountProj | currency:'EUR'}}</div>
                  </td>
                </tr>
                <tr *ngIf="descriptionDiscountApplied">
                  <td>
                    <span class="title is-5" [translate]="'condition'"></span>
                  </td>
                  <td>
                    <div class="title is-5">{{descriptionDiscountApplied}}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="title is-5" [translate]="'averageProfitMargin'">Margine medio</div>
                  </td>
                  <td>
                    <div class="title is-5" style="white-space: nowrap;">{{((listTotalPublic * (1 -
                      baseQuotation.extraDiscount/100))-(listTotalReserved
                      * (1 - baseQuotation.projectDiscount/100)))/(listTotalPublic * (1 -
                      baseQuotation.extraDiscount/100))
                      | percent:'1.1-1'}}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="title is-4" [translate]="'total'">Totale</div>
                  </td>
                  <td>
                    <div class="title is-4">{{(listTotalReserved - discountProj) | currency:'EUR'}}</div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="shopMode == 1 && baseQuotation?.items.length > 0">
        <div class="columns">
          <div class="column is-3">
            <ng-container *ngTemplateOutlet="note"></ng-container>
          </div>
          <div class="column">
          </div>
          <div class="column is-5">
            <div class="border-column is-info">
              <table class="table is-fullwidth">
                <tr>
                  <td>
                    <div class="title"
                      [ngClass]="{'is-4' : baseQuotation.extraDiscount == 0, 'is-5' : baseQuotation.extraDiscount != 0}"
                      [translate]="'total'">Totale</div>
                  </td>
                  <td>
                    <div class="title has-text-right"
                      [ngClass]="{'is-4' : baseQuotation.extraDiscount == 0, 'is-5' : baseQuotation.extraDiscount != 0}">
                      {{listTotalPublic | currency:'EUR'}}</div>
                    <div class="subtitle is-6 has-text-right" *ngIf="baseQuotation.extraDiscount == 0">
                      <span [translate]="'reservedPrice'">prezzo riservato</span>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="baseQuotation.extraDiscount > 0">
                  <td>
                    <div class="title is-5" [translate]="'unconditionalDiscount'">Sconto incondizionato</div>
                  </td>
                  <td>
                    <div class="title is-5 has-text-right">{{ (listTotalPublic *
                      baseQuotation.extraDiscount / 100) | currency:'EUR'}}</div>
                    <div class="subtitle is-size-6 has-text-right">
                      <span [translate]="'equalTo'">pari al</span>&nbsp;<span>{{baseQuotation.extraDiscount}}%</span>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="baseQuotation.extraDiscount > 0">
                  <td>
                    <div class="title is-4" [translate]="'totalDocument'">Totale documento</div>
                  </td>
                  <td>
                    <div class="title is-4 has-text-right">{{(listTotalPublic -
                      (listTotalPublic * baseQuotation.extraDiscount / 100)) | currency:'EUR'}}
                    </div>
                    <div class="subtitle is-6 has-text-right">
                      <span [translate]="'reservedPrice'">prezzo riservato</span>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>


      </ng-container>
    </div>
  </div>
  <ng-template #no_items>
    <div *ngIf="baseQuotation.listState == 0" class="is-size-6">
      <a (click)="addCustomItem()" *ngIf="(isAdmin) && !addSectionMode1" [translate]="'addCustomItem'">Aggiungi articolo
        speciale</a>
      <br><br>
    </div>
    <span [translate]="'quotationIsEmpty'">Il preventivo è vuoto</span>
  </ng-template>

  <ng-container *ngFor="let nota of baseQuotation.noteDaInviare">
    <div class="columns is-vcentered">
      <div class="column">
        <div class="field">
          <div class="control">
            <div class="select">
              <select [(ngModel)]="nota.tipoStampaCommento" [disabled]="baseQuotation.listState != 0">
                <option [ngValue]="0" [translate]="'allDocuments'">
                </option>
                <option [ngValue]="1" [translate]="'ordiniPreventivi'">
                </option>
                <option [ngValue]="2" [translate]="'ddtMandati'">
                </option>
                <option [ngValue]="3" [translate]="'fatture'">
                </option>
                <option [ngValue]="4" [translate]="'ordiniFatture'">
                </option>
                <option [ngValue]="5" [translate]="'ordiniDdt'">
                </option>
                <option [ngValue]="6" [translate]="'ddtFatture'">
                </option>
                <option [ngValue]="9" [translate]="'nonStampare'">
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-narrow" [translate]="'max4000Char'">

      </div>
    </div>

    <div class="field">
      <div class="control">
        <textarea class="textarea" maxlength="4000" [(ngModel)]="nota.text"
          [disabled]="baseQuotation.listState != 0"></textarea>
      </div>
    </div>
    <div class="buttons is-right">
      <button class="button" type="button" [disabled]="baseQuotation.listState != 0" [translate]="'delete'"
        (click)="deleteNote(nota.id)"></button>
    </div>
  </ng-container>
</div>
<p-overlayPanel #popperSection (onHide)="closePopperSection()">
  <a (click)="editTitleSection(true)" [translate]="'rename'">Rinomina</a><br />
  <a (click)="removeSection()" class="has-text-danger" [translate]="'delete'">Elimina</a>
</p-overlayPanel>
<p-toast position="bottom-right" key="kelvin" id="toastKelvin"></p-toast>
<ng-template #note>
  <div class="border-column is-info">
    <div class="title is-3 is-spaced has-text-weight-bold" style="margin-top: 0.5rem;">{{'note' | translate}}</div>
    <div class="subtitle is-5">
      {{'describeNoteFunction' | translate}}
    </div>
    <div class="columns is-vcentered">
      <div class="column is-narrow">
        <div class="title is-5" [translate]="'internalNote'">
        </div>
      </div>
      <div class="column is-narrow">
        <button type="button" class="button" (click)="addNote()" [disabled]="baseQuotation.listState != 0">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div class="column"></div>
    </div>
  </div>

</ng-template>