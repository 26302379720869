import { ProductService } from "./product.service";
import { PipeTransform, Pipe } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { CatalogService } from "./catalog.service";
import { Observable, of } from "rxjs";
import { ImageService } from "./image.service";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { GenerateUrlDownloadAcc } from "./shared/functions";
import { environment } from "../environments/environment";

@Pipe({ name: 'isAnthology' })
export class AnthologyPipe implements PipeTransform {

  constructor(private productService: ProductService) {
  }

  transform(accessory: any): boolean {
    if (accessory && accessory.anthology === false)
      return false;
    if (accessory && accessory.anthology === true)
      return true;
    return this.productService.isAnthology(accessory);
  }
}

@Pipe({ name: 'absolute' })
export class AbsolutePipe implements PipeTransform {

  constructor() {
  }

  transform(num: number): number {
    if (num == undefined) return 0;
    return Math.abs(num);
  }
}


@Pipe({ name: 'ceiling' })
export class CeilingPipe implements PipeTransform {

  constructor() {
  }

  transform(num: number): number {
    if (num == undefined) return 0;
    return Math.ceil(num);
  }
}

@Pipe({ name: 'categoryName' })
export class CategoryNamePipe implements PipeTransform {

  constructor(private catalogService: CatalogService) {
  }

  transform(category: any, includeSubCategory: boolean = false): Observable<string> {
    return this.catalogService.getCategoryName(category, includeSubCategory);
  }
}

@Pipe({ name: 'applicationName' })
export class ApplicationNamePipe implements PipeTransform {

  constructor(private catalogService: CatalogService) {
  }

  transform(category: any): Observable<string> {
    return this.catalogService.getApplicationName(category);
  }
}

@Pipe({ name: 'attributeName' })
export class AttributeNamePipe implements PipeTransform {

  constructor(private productService: ProductService) {
  }

  transform(attribute: any): Observable<string> {
    return this.productService.getAttributeName(attribute.sysName);
  }
}

@Pipe({ name: 'isSelectedAttributeValue', pure: false })
export class IsSelectedAttributeValuePipe implements PipeTransform {

  constructor(private productService: ProductService) {
  }

  transform(attribute: any, value: string): any {
    //TODO now is inpure! try make it pure and depends on currentVariant
    return this.productService.isSelectedAttributeValue(attribute, value);
  }
}

@Pipe({ name: 'isSelectableAttribute' })
export class IsSelectedAttributePipe implements PipeTransform {

  constructor(private productService: ProductService) {
  }

  transform(attributes: any[]): Observable<any[]> {
    return this.productService.getSelectableAttributes(attributes);
  }
}

//@Pipe({name: 'variantAttributeValue'})
//export class VariantAttributeValuePipe implements PipeTransform {

//  constructor(private productService: ProductService) {
//  }

//  transform(variant: any, attribute: any): Observable<any> {
//    const variantAttribute = variant.attributes.find(t => t.sysName === attribute.sysName);
//    if (variantAttribute)
//      return this.productService.getAttributeValueLabel(attribute.sysName, variantAttribute.value);
//    return this.productService.getAttributeValueLabel(attribute.sysName, null);
//  }
//}

@Pipe({ name: 'attributeValue' })
export class AttributeValuePipe implements PipeTransform {

  constructor(private productService: ProductService) {
  }

  transform(attribute: any, value: any = undefined): Observable<any> {
    if (value !== undefined)
      return this.productService.getAttributeValueLabel(attribute.sysName, value);

    return this.productService.getAttributeValueLabel(attribute.sysName, attribute.value);
  }
}

@Pipe({ name: 'hasFile' })
export class HasFilePipe implements PipeTransform {

  constructor(private productService: ProductService) {
  }

  transform(files: any[], sysName: string): boolean {
    if (!sysName)
      return false;

    return files.findIndex(t => t.sysName == sysName) > -1;
  }
}

@Pipe({ name: 'getValues' })
export class GetValuesPipe implements PipeTransform {
  transform(map: Map<any, any>): any[] {
    let ret = [];

    map.forEach((val, key) => {
      ret.push({
        key: key,
        value: val
      });
    });
    return ret;
  }
}

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Pipe({
  name: 'cdnImage'
})
export class CdnImagePipe implements PipeTransform {
  constructor(private imageService: ImageService) {
  }

  transform(value: string, type: string, product: string, fallback: string, query: string): string {
    if (type === "") type = "images";
    if (product === "") product = undefined;
    if (fallback === "") fallback = undefined;
    if (query === "") query = undefined;
    let result = this.imageService.getImageUrl(value, product, fallback, type, query);
    if (result)
      result = result.replace(" ", "%20");
    return result;
  }
}

@Pipe({
  name: 'accountName'
})
export class AccountNamePipe implements PipeTransform {
  constructor(private httpClient: HttpClient) {
  }

  transform(value: string): Observable<string> {
    return this.httpClient.post<any[]>(`${environment.identityUrl}/service/service/getAgents`, [value])
      .pipe(map(account => AccountNamePipe.getAccountFullName(account[0])));
  }

  private static getAccountFullName(account) {
    let name = account.claims.find(t => t.claimType === "given_name");
    let surname = account.claims.find(t => t.claimType === "family_name");
    return `${surname.claimValue} ${name.claimValue}`;
  }
}

@Pipe({ name: 'accessoryFile' })
export class AccessoryFilePipe implements PipeTransform {

  transform(accessory: any): string {
    if (!accessory.files || accessory.files.length === 0)
      return '';

    return GenerateUrlDownloadAcc(accessory);
  }
}


@Pipe({ name: 'langName' })
export class LangNamePipe implements PipeTransform {

  transform(value: any): string {

    switch (value) {
      case 'it':
        return "Italiano";
      case 'fr':
        return "Français";
      case 'es':
        return "Español"
      case 'en':
        return "English"
      case 'de':
        return "Deutsch"
      default:
        return 'N/A'
    }
  }
}

@Pipe({ name: 'brandName' })
export class BrandNamePipe implements PipeTransform {

  constructor(private catalogService: CatalogService) {

  }

  transform(idBrand: string): Observable<string> {
    if (!idBrand) return of("");
    return this.catalogService.getBrands().pipe(map((brands) => {
      let index = brands.findIndex(b => b.id == idBrand);
      if (index != -1) {
        return brands[index].name;
      } else {
        return "";
      }
    }))
  }
}

@Pipe({ 
  name: 'calculateMargine', 
  pure: false //purtroppo...
})
export class CalculateMarginePipe implements PipeTransform {

  transform(item: any, itemSconto?: any): number {
    if (!item) return 0;

    if (item.prezzo == 0) return 0;

    let itemWithSconto = itemSconto || item;

    let inversoDiscount = (1 - (itemWithSconto.discount || 0) / 100) * (1 - (itemWithSconto.discount2 || 0) / 100) * (1 - (itemWithSconto.discount3 || 0) / 100);
    return (inversoDiscount * item.prezzo - item.prezzoRiservato) / (inversoDiscount * item.prezzo)
  }
}

@Pipe({ 
  name: 'sizeRevisionsArray', 
  pure: true
})
export class SizeRevisionsArrayPipe implements PipeTransform {

  transform(revisions: any[], idCurrentRev: string): number {
    if(!revisions) return 0;
    if(!revisions.length) return 0;

    return revisions.filter(r => r.rev != idCurrentRev).length
  }
}

@Pipe({ 
  name: 'calculatePrezzoVendita',
   pure: false //purtroppo... 
})
export class CalculatePrezzoVenditaPipe implements PipeTransform {

  transform(item: any, itemSconto?: any): number {
    if (!item) return 0;

    if (item.prezzo == 0) return 0;

    let itemWithSconto = itemSconto || item;

    let inversoDiscount = (1 - (itemWithSconto.discount || 0) / 100) * (1 - (itemWithSconto.discount2 || 0) / 100) * (1 - (itemWithSconto.discount3 || 0) / 100);
    return inversoDiscount * item.prezzo;
  }
}
