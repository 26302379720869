import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { tap, takeUntil, filter, take, mergeMap } from 'rxjs/operators';
import { SignalrService } from "./signalr.service";
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from "@sentry/browser";
import { CatalogService } from './catalog.service';
import { environment } from '../environments/environment';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { StoreService } from './store.service';
import { ProductService } from './product.service';
import { Router, RoutesRecognized } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { UserPreferenceService } from './user-preference.service';
import { KeyCurrentStore, LocalizeMap } from './shared/costants';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

    canActivateProtectedRoutes: Observable<boolean>;
    env: string = environment.production ? "production/staging" : "development";

    destroy$: Subject<boolean> = new Subject<boolean>();

    loadAPIPaypal: Promise<any>;
    localeStoreMap = LocalizeMap;

    authorized: boolean = false;

    constructor(private authService: AuthenticationService, private signalService: SignalrService,
        private userService: UserPreferenceService, private storeService: StoreService,
        private productService: ProductService, private router: Router, private config: PrimeNGConfig,
        private translate: TranslateService, private catalogService: CatalogService) {
    }

    ngOnInit(): void {

        //stringhe presenti nei pdf o nella mail. Se tolte da qua assicurarsi non siano piu' usate nei pdf e nelle mail!!!!
        let translationReq = marker(['brochureTitle', 'quotationOf', 'projectDiscount', 'indirizzoFatturazione', 'codCli', "del", 'insertBy',
            'paymentMethod', 'shippingDate', 'shippingMethod', 'receiptAddress', 'abbrUnitPrice', "rev", 'backofficeFor',
            'abbrQty', 'yourReference', 'macroluxYourNewOrder', 'checkPrizeDiscount', 'summaryOrder', 'clickHereOpenUrl',
            'totalNoTax', 'without', 'withoutDriver', 'emailSubjectRetrieveCode', 'bodyEmailRetrieveCode', 'isPack', "cod",
            'priceWithoutIva', 'infoBrochure', 'catalogName', 'price', 'vat22', 'totalWithVat', 
            'primeng.dayNamesMin0', 'primeng.dayNamesMin1', 'primeng.dayNamesMin2', 'primeng.dayNamesMin3', 'primeng.dayNamesMin4',
            'primeng.dayNamesMin5', 'primeng.dayNamesMin6', 'primeng.monthNames0', 'primeng.monthNames1', 'primeng.monthNames2',
            'primeng.monthNames3', 'primeng.monthNames4', 'primeng.monthNames5', 'primeng.monthNames6', 'primeng.monthNames7',
            'primeng.monthNames8', 'primeng.monthNames9', 'primeng.monthNames10', 'primeng.monthNames11',
            'thisVariantIsInSection', 'condVendita', 'noteUpper', 'pdfConfiguration', 'dxfConfiguration', 'modelName', 'proforma']);

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('it');

        this.storeService.translateService.stream(translationReq).pipe(takeUntil(this.destroy$)).subscribe((trad) => {

            this.config.setTranslation({
                "dayNamesMin": [
                  trad['primeng.dayNamesMin0'],
                  trad['primeng.dayNamesMin1'],
                  trad['primeng.dayNamesMin2'],
                  trad['primeng.dayNamesMin3'],
                  trad['primeng.dayNamesMin4'],
                  trad['primeng.dayNamesMin5'],
                  trad['primeng.dayNamesMin6']
                ],
                "monthNames": [
                  trad['primeng.monthNames0'],
                  trad['primeng.monthNames1'],
                  trad['primeng.monthNames2'],
                  trad['primeng.monthNames3'],
                  trad['primeng.monthNames4'],
                  trad['primeng.monthNames5'],
                  trad['primeng.monthNames6'],
                  trad['primeng.monthNames7'],
                  trad['primeng.monthNames8'],
                  trad['primeng.monthNames9'],
                  trad['primeng.monthNames10'],
                  trad['primeng.monthNames11']
                ],
              });
         });


        this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(_t => {
            this.catalogService.populateAllCache().subscribe(() => { });
            this.storeService.loadCache();
            this.productService.populateAttributes();
        });

        this.router.events.pipe(filter((e) => { return e instanceof RoutesRecognized }), take(1))
        .subscribe((event : RoutesRecognized) => {
            if (!event.url.includes('project-approved') && !event.url.includes('project-rejected')) {
                this.initializeApp();
            }
        });

        this.loadAPIPaypal = new Promise((resolve) => {
            this.loadScript();
            resolve(true);
        });

    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    loadScript() {
        let isFound = false;
        let scripts = document.getElementsByTagName("script")
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("paypal.com")) {
                isFound = true;
            }
        }

        if (!isFound) {
            let dynamicScripts = [`https://www.paypal.com/sdk/js?client-id=${environment.clientIdPaypal}&currency=EUR&disable-funding=credit,card,mybank,sofort`];

            for (let i = 0; i < dynamicScripts.length; i++) {
                let node = document.createElement('script');
                node.src = dynamicScripts[i];
                node.type = 'text/javascript';
                node.async = false;
                document.getElementsByTagName('head')[0].appendChild(node);
            }
        }
    }

    initializeApp() {
        this.authService.checkAuth()
            .pipe(mergeMap((isAuthenticated) => {

                if (isAuthenticated) {

                    this.userService.loadUserData();

                    let store = this.storeService.getCurrentStore();
                    if (!store) { //puo' essere un refresh o un login
                        store = sessionStorage.getItem(KeyCurrentStore);
                        if (store) {

                            if (!this.storeService.setStoreIfExist(store)) {
                                debugger;
                                //teoricamente non dovrebbe mai succedere...
                                store = this.storeService.storeDefault;
                                this.storeService.setStoreIfExist(store);
                                this.router.navigate(['/' + store]);
                            }
                        } else {
                            //login
                            let claim = this.authService.getUserProfile();
                            store = this.storeService.storeDefault;

                            //se non c'e' nel claim o nella mappa sopra uso il default
                            if (claim.locale && this.localeStoreMap[claim.locale.toString()]) {
                                store = this.localeStoreMap[claim.locale.toString()];
                            }

                            this.storeService.setStoreIfExist(store);
                        }
                    }
                    return this.authService.getAccessToken().pipe(take(1));
                } else {
                    return of("");
                }
            }))
            .subscribe({
                next: (token) => {
                    if (token) {
                        const claims = this.authService.getUserProfile();
                        this.signalService.startConnection(claims.sub, token);

                        Sentry.setUser({
                            id: claims.sub,
                            email: claims.email,
                            idListino: claims.idListino
                        });
                        this.authorized = true;
                    }
                },
                error: (_err) => {
                    debugger;
                    alert("Auth problem. Try again later");
                }
            });
    }
}
