<div class="buttons is-right">
  <button class="button is-info" (click)="printBrochureQuotation()" [disabled]="!ready" [translate]="'downloadPdfBrochure'">
    Scarica PDF brochure
  </button>
</div>
<div class="is-relative">
  <!-- non usare ngIf o hidden, leggere README-->
  <div [ngClass]="{'is-invisible': !ready }" style="height: 780px">
    <ng2-pdfjs-viewer #pdfViewerAutoLoadBrochure viewerId="brochureId" (onDocumentLoad)="ready = true"
                      [download]="false" [print]="false" [openFile]="false" [viewBookmark]="false">
    </ng2-pdfjs-viewer>
  </div>

  <div id="spinner-wrapper" *ngIf="!ready" style="position: absolute; top: 0; left:0; right: 0;">
    <div class="spinner"></div>
  </div>
</div>
