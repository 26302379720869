import { ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CatalogService } from "../catalog.service";
import { mergeMap, take } from 'rxjs/operators';

@Injectable()
export class AccessoriesResolver  {
  constructor(private httpClient: HttpClient, private catalogService : CatalogService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.catalogService.getCurrentBrandId().pipe(take(1), mergeMap((idBrand) => {
      let httpParams = new HttpParams().set("idBrand", idBrand); 
      return this.httpClient.get<any>("/api/catalog/accessories/" + route.paramMap.get('id'), { params: httpParams });
    }));
    
  }
}

@Injectable()
export class AccessoryCategoriesResolver  {
  constructor(private catalogService: CatalogService) { }

  resolve(_route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.catalogService.getAccessoryCategories().pipe(take(1));
  }
}
