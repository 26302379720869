export const KeyCurrentStore: string = "chiave_store";


export interface SimpleMap {
  [key: string]: string;
}


export const LocalizeMap: SimpleMap = {
  "it-IT": "italy",
  "de-DE": "germany",
  "fr-FR": "france",
  "de-CH": "swiss",
  "de-AT": "austria",
  "es-ES": "spain",
  "en-EU": "europe",
  "en-AB": "asia",
  "en-MB":"mideast",
  "en-AU":"australia"
};


export enum ReturnTo {
  quotation = "quotation",
  list = "list",
  quotationCheckout3 = "quotationCheckout3",
  quotationCheckoutPlain = "quotationCheckoutPlain",
  listCheckout3 = "listCheckout3", //not used
  listCheckoutPlain = "listCheckoutPlain", //not used
  product = "product",
}

export const Bank: string = "Intesa Sanpaolo S.p.A.";
export const Iban: string = "IT 32 R 03069 61626 100000001141";
export const BicSwift: string = "BCITITMM";

export const SessionKeyBrand : string = "macrolux_b2b_key_session_brand";
export const IdAggStucchi : string = "64c217dbaa4ee222c8fc8c55";