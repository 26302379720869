import { Injectable } from '@angular/core';
import * as moment from 'moment';
//internamente moment-business usa utc!
var momentBd = require('moment-business-days');

@Injectable({
  providedIn: 'root'
})
export class ServiceMoment {

  //internamente moment-business usa utc!
  constructor() {

  }
  init(holydays: Date[]) {
    let holydaysString : string[] = [];
    
    for(let holyday of holydays) {
      holydaysString.push(moment(holyday).format('YYYY-MM-DD'));
    }
     momentBd.updateLocale('it', {
      
      holidays: holydaysString,
      holidayFormat: 'YYYY-MM-DD',

    });
  }

  //internamente moment-business usa utc!
  addDayBusiness(amount: number): any {
    let today = momentBd.utc();
    return today.businessAdd(amount);
  }

  subtractDayBusiness(amount: number, date?: Date): any {
    let dateToConsider : any;
    if(date) {
      dateToConsider = moment.utc(date);
    } else {
      dateToConsider = momentBd.utc();
    }
    return dateToConsider.businessSubtract(amount);
  }

}
