import { Guid } from 'guid-typescript';
import { environment } from '../../environments/environment';
import { SessionKeyBrand } from './costants';

export class GuidGenerator {
  static first8CharGuid() : string {
    return Guid.create().toString().substr(0, 8);
  }
}

export function FormatString(str: string, val: string[]): string {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

export function GenerateUrlDownloadAcc(accessory: any): string {
    return environment.urlBlob + `catalog/accessories/${accessory.id}/files/${accessory.files[0].value}`;
}

export function IsSuperAdmin(claims: any): boolean  {
  return claims.role && (claims.role.indexOf("Super Admin") > -1);
}

export function IsAgent(claims: any): boolean {
  return claims.role && (claims.role.indexOf("Agent") > -1);
}

export function getCurrentBrandId() : string {
  return localStorage.getItem(SessionKeyBrand);
}

export function clearCurrentBrandId() : void {
  localStorage.removeItem(SessionKeyBrand);
}

export function setCurrentBrandId(idBrand : string) {
  localStorage.setItem(SessionKeyBrand, idBrand);
}
