import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { tap, switchMap, takeUntil } from 'rxjs/operators';
import { StatsService } from '../stats.service';

@Component({
  selector: 'app-performance-macrolux',
  templateUrl: './performance-macrolux.component.html',
  styleUrls: ['./performance-macrolux.component.scss'],
})
export class PerformanceMacroluxComponent implements OnInit, OnDestroy, OnChanges {

  @Input() level: string;
  @Input() clientiAgente: any[]
  @Input() cliente: any
  @Output() selectedCliente = new EventEmitter<any>();
  private companySubject$: BehaviorSubject<any>
  company: any;

  destroy$: Subject<boolean> = new Subject<boolean>();
  
  loadingPuntualita: boolean = false;
  statsPuntualita: any

  loadingQtaAnthology: boolean = false;
  statsQtaAnthology: any

  loadingSpedizioni: boolean = false;
  statsSpedizioni: any
  costoSpedizione = 30

  thresholdConfig = {
    '0': { color: 'black' },
    '1': { color: 'red' },
    '75': { color: '#c6912f' },
    '90': { color: '#173554' }
  }

  get gaugeValue(): number {
    if (!this.statsPuntualita) return 0;

    if (this.statsPuntualita.totalOrders > 0) {
      return Math.round((this.statsPuntualita.totalOrders - this.statsPuntualita.ordersInLate) / this.statsPuntualita.totalOrders * 100 * 10) / 10;
    }
    return 0;
  }

  constructor(private statsService: StatsService) { }

  ngOnInit() {
    this.companySubject$ = new BehaviorSubject(this.cliente)
    const currentCompany$ = this.companySubject$.asObservable();

    currentCompany$.pipe(takeUntil(this.destroy$)).subscribe((company) => {
      this.company = company;
    });

    currentCompany$.pipe(
      tap(() => this.loadingPuntualita = true),
      switchMap(company => {
        if (company && company.codCliFor) {
          return this.statsService.getPerformancePuntualitaForCompany(company.codCliFor)
        } else {
          return of(null)
        }
      }),
      tap(() => this.loadingPuntualita = false),
      takeUntil(this.destroy$)
    ).subscribe(data => this.statsPuntualita = data);

    currentCompany$.pipe(
      tap(() => this.loadingQtaAnthology = true),
      switchMap(company => {
        if (company && company.codCliFor) {
          return this.statsService.getPerformanceQtaAnthologyForCompany(company.codCliFor)
        } else {
          return of(null)
        }
      }),
      tap(() => this.loadingQtaAnthology = false),
      takeUntil(this.destroy$)
    ).subscribe(data => this.statsQtaAnthology = data);

    currentCompany$.pipe(
      tap(() => this.loadingSpedizioni = true),
      switchMap(company => {
        if (company && company.codCliFor) {
          return this.statsService.getPerformanceSpedizioniForCompany(company.codCliFor)
        } else {
          return of(null)
        }
      }),
      tap(() => this.loadingSpedizioni = false),
      takeUntil(this.destroy$)
    ).subscribe(data => this.statsSpedizioni = data);

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.companySubject$ && changes.cliente) {
      this.companySubject$.next(changes.cliente.currentValue);
    }

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onSelectedCompany(company: any) {
    this.companySubject$.next(company);
    this.selectedCliente.emit(company);
  }


}
