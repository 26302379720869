import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserPreferenceService } from "../user-preference.service";
import { MessageService } from 'primeng/api';
import { IsSuperAdmin } from '../shared/functions';
import { AuthenticationService } from '../services/authentication.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.css']
})
export class ProfileDetailComponent implements OnInit, OnDestroy {

  claims: any;
  name: string;
  surname: string;
  password: string;
  confirmPassword: string;
  isViewer: boolean = false;
  roles: string;
  shippingPlans: { [id: string]: string; } = {
    "2": "Business Italia",
    "3": "Executive Italia",
    "4": "Executive Europe",
    "5": "Business Europe",
    "9": "Ex Works"
  };
  passwordChangeErrors: any[];

  isAdmin: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private authService: AuthenticationService, private userService: UserPreferenceService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.authService.userProfile$.pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.claims = t;
      this.isViewer = this.claims.view_only && this.claims.view_only == "true";

      if (this.claims.role instanceof Array) {
        this.roles = this.claims.role.join(", ");
      } else {
        this.roles = this.claims.role;
      }
      this.isAdmin = IsSuperAdmin(this.claims);

      this.name = this.claims.given_name;
      this.surname = this.claims.family_name;
    });
  }


  changePassword() {
    this.userService.changePassword(this.password, this.confirmPassword).subscribe(t => {
      if (t.succeeded) {
        this.messageService.add({ severity: 'success', summary: 'Cambio password', detail: 'Operazione completata.' });
      } else {
        this.passwordChangeErrors = t.errors;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
}

}
