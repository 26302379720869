import {
  Directive, ElementRef, AfterViewChecked,
  HostListener
} from '@angular/core';

@Directive({
  selector: '[square-size]'
})
export class SquareSizeDirective implements AfterViewChecked {

  constructor(private el: ElementRef) {
  }

  ngAfterViewChecked() {
    // call our matchHeight function here later
    this.matchHeight(this.el.nativeElement);
  }

  @HostListener('window:resize')
  onResize() {
    // call our matchHeight function here later
    this.matchHeight(this.el.nativeElement);
  }

  matchHeight(element: HTMLElement) {
    if (element && element.clientWidth) {
      element.style.height = `${element.clientWidth}px`;
    }

  }
}
