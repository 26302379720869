import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { ListService } from "../../list.service";
import { CatalogService } from "../../catalog.service";
import { QuotationService } from '../../quotation.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StoreService } from '../../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'quotation-wizard-item',
  templateUrl: './quotation-wizarditem.component.html',
  styleUrls: ['./quotation-wizarditem.component.css'],
})
export class QuotationWizarditemComponent implements OnInit, OnChanges, OnDestroy {

  @Input() item: any;
  @Input() list: any;
  @Input() section: any;
  @Input() sections: any[];

  @Output() onTotalChange = new EventEmitter<number>();
  @Output() onKelvinCheck = new EventEmitter<any>();

  prezzoFinale: number = 0;
  accessoryTotal: number = 0;
  showAccessories: boolean = false;

  inplaceSection: boolean = false;
  inplaceCurrentSection: string;
  inplaceAllowedSections: any[];

  shopMode: number = 1;
  translations: any = {};

  popperItem: any;
  @ViewChild('popperListContent', { static: true }) popperListContent: any;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router, private listService: ListService,
    private quotationService: QuotationService, private catalogService: CatalogService, private storeService: StoreService) {

  }

  ngOnInit() {
    let translationRequired = marker(['articleProductNotValid', 'confirmDeleteItemFromQuotation']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.catalogService.getShopMode().pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.shopMode = t;
      if (!this.item.childItems) return;
      this.getPrezzoFinale();
    });
  }

  ngOnChanges() {
    this.accessoryTotal = 0;
    this.quotationService.fillItemDetail(this.item);
    this.getPrezzoFinale();
    this.checkKelvin();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  toggleAccessories() {
    this.showAccessories = !this.showAccessories;
  }

  setPopperItem(item) {
    this.inplaceCurrentSection = this.section.key;
    this.inplaceAllowedSections = this.sections.filter(t => t.key != "").map(t => {
      return {
        value: t.key,
        label: t.value.name
      }
    });
    this.inplaceSection = false;
    this.popperItem = item;
  }

  changeSection() {
    if (this.inplaceCurrentSection === this.section.key) {
      //no changes
      this.inplaceSection = false;
      return;
    }
    this.section.value.items = this.section.value.items.filter(t => t.configurationId != this.item.configurationId);
    if (this.section.key != "")
      this.list.section.find(t => t.sectionId == this.section.key).item = this.list.section.find(t => t.sectionId == this.section.key).item.filter(t => t != this.item.configurationId);
    this.sections.find(t => t.key == this.inplaceCurrentSection).value.items.push(this.item);
    if (this.inplaceCurrentSection != "")
      this.list.section.find(t => t.sectionId == this.inplaceCurrentSection).item.push(this.item.configurationId);
    this.list.items = [].concat.apply([], this.sections.map(d => d.value.items));
  }

  gotoConfiguration(item) {
    this.listService.setCurrentList(this.list);
    this.catalogService.populateAllCache(this.item.idBrand).subscribe(() => {
      this.router.navigate(this.storeService.createUrl(["/product", item.productModel, "wizard", item.configurationId.substring(1)]) as any[]);
    });

  }

  removeItem(configurationId) {
    if (confirm(this.translations["confirmDeleteItemFromQuotation"])) {
      this.list.items = this.list.items.filter(i => i.configurationId != configurationId);
      this.section.value.items = this.section.value.items.filter(i => i.configurationId != configurationId);
      this.popperListContent.hide();
      this.onTotalChange.emit(this.shopMode);
    }
  }

  getPrezzoFinale() {
    let counter = 0;
    let counterPrezzo = 0;
    let counterPrezzoRiservato = 0;
    let allOk = true;
    if (this.item.childItems) {
      for (let cItem of this.item.childItems) {
        counterPrezzo += cItem.prezzo * cItem.quantity;
        counterPrezzoRiservato += cItem.prezzoRiservato * cItem.quantity;
        allOk = allOk && cItem.prezzoRiservato && cItem.prezzo && cItem.quantity;
        if (cItem.prezzo) {
          if (this.shopMode == 0) {
            counter += (cItem.prezzoRiservato * cItem.quantity);
          } else {
            var inversoDiscount = (1 - (this.item.discount || 0) / 100) * (1 - (this.item.discount2 || 0) / 100) * (1 - (this.item.discount3 || 0) / 100);
            counter += (inversoDiscount * cItem.prezzo * cItem.quantity);
          }
        }
      }
    } else {
      this.prezzoFinale = 0;
      this.item.prezzo = 0;
      this.item.prezzoRiservato = 0;
      this.item.esWarning = true;
      return;
    }
    if (allOk) {
      this.prezzoFinale = counter;
      this.item.prezzo = counterPrezzo;
      this.item.prezzoRiservato = counterPrezzoRiservato;
      this.item.esWarning = false;
      this.onTotalChange.emit(this.shopMode);
    } else {
      this.prezzoFinale = 0;
      this.item.prezzo = 0;
      this.item.prezzoRiservato = 0;
      this.item.esWarning = true;
    }
  }

  updateItemDiscount() {
    for (let cItem of this.item.childItems) {
      cItem.discount = this.item.discount;
      cItem.discount2 = this.item.discount2;
      cItem.discount3 = this.item.discount3;
    }
    this.getPrezzoFinale();
  }

  checkKelvin() {
    this.onKelvinCheck.emit({ configurationId: this.item.configurationId, colors: this.item.colors });
  }

  goToProduct() {
    this.catalogService.populateAllCache(this.item.idBrand).subscribe(() => {
      this.router.navigate(this.storeService.createUrl(['/product', this.item.productModel]) as any[]);
    });
  }
}
