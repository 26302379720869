<div class="rect-box selector has-text-centered" [class.selected]="!currentVariant.selected"
  [class.danger]="currentVariant.selected">
  <div *ngIf="!currentVariant.selected" class="is-uppercase has-text-weight-bold pointer has-text-white"
    [translate]="'add'" (click)="addToCart(currentVariant)">
    Aggiungi
  </div>
  <div *ngIf="currentVariant.selected" class="is-uppercase has-text-weight-bold has-text-white"
    [translate]="'enterQuantity'">
    Inserisci la quantità
  </div>
</div>
<div class="rect-box">
  <div class="content">
    <div class="columns is-vcentered">
      <div class="column is-narrow" style="line-height: 0.8em;">
        <div>
          <span class="is-size-6" *ngIf="currentVariant.sku.length <= 20">{{currentVariant.sku}}</span>
          <span [pTooltip]="currentVariant.sku" tooltipPosition="left" tooltipStyleClass="m-tooltip" class="is-size-6"
            *ngIf="currentVariant.sku.length > 20">{{(currentVariant.sku | slice:0:20)+'..'}}</span>
        </div>
        <div *ngIf="(currentVariant | isAnthology) || currentVariant.vms == true"
          class="has-text-uppercase is-size-7 has-text-right has-text-info has-text-weight-semibold">
          <span>Anthology</span>
          <span *ngIf="currentVariant.anthologyStar" class="has-text-warning has-text-weight-bold">Star</span>
          <span *ngIf="currentVariant.vms" class="has-text-warning has-text-weight-bold">VMS</span>
        </div>
      </div>
      <div class="column has-text-right">
        <p-inputNumber [showButtons]="true" size="2" [(ngModel)]="currentVariant.quantity" [min]="1"
          [disabled]="!currentVariant.selected" (ngModelChange)="onQtyChange()"></p-inputNumber>
      </div>
    </div>
    <div class="title is-5 has-text-weight-semibold variant-name">
      <ng-container *ngIf="!currentVariant.esWarning">
        <span class="has-text-weight-semibold">{{currentVariant.esolverName}}</span><br />
      </ng-container>
      <ng-container *ngIf="currentVariant.esWarning">
        <span class="has-text-danger has-text-weight-semibold" [translate]="'productNotFound'">-- Prodotto non trovato
          --</span><br />
      </ng-container>
    </div>
    <div class="symbols">
      <figure class="image" *ngFor="let attr of attributesForIcon">
        <img src="/images/symbols/{{attr.sysName}}_{{attr.value}}.png" height="24" />
      </figure>
      <figure class="image" *ngIf="showSymbolTutti">
        <img src="/images/symbols/tutti.png" height="24" />
      </figure>
    </div>
    @if (currentVariant.quantity && currentVariant.quantity > currentVariant.qtaDisponibile) {
      <div class="columns">
        <div class="column is-narrow">
          <span class="is-size-7 has-text-warning has-text-weight-bold" *ngIf="currentVariant | isAnthology"
            style="margin-bottom: 2em;display: inline-block;" [translate]="'insufficientArticles'"></span><br />
          <div class="cb-button">
            <div class="cbb-rectangle"></div>
            <div class="cbb-rectangle-fill"></div>
            <a class="button is-warning is-small" (click)="askSubstitute()">
              <i class="fas fa-chevron-left"></i>&nbsp;&nbsp;<span [translate]="'askArticleReplacement'"></span>
            </a>
          </div>
        </div>
        <div class="column"></div>
      </div>
    }
    <ng-container *ngIf="!(currentVariant | isAnthology)">
      <div *ngIf="currentVariant.qtaDisponibile > 0;else no_anthology_stock">
        <b *ngIf="currentVariant.um == 'PZ'; else meterAvailable" [translate]="'piecesInStock'"
          [translateParams]="{qta: currentVariant.qtaDisponibile}"></b>
      </div>
      <ng-template #no_anthology_stock>
        <div>
          <b [translate]="'beProduced'">Da produrre</b><br>
        </div>
      </ng-template>
      <p [translate]="'extimateTimeWeek'" [translateParams]="{time: leadTime}"> </p>
    </ng-container>
    <ng-container *ngIf="currentVariant | isAnthology">
      <div>
        <ng-container *ngIf="currentVariant.qtaDisponibile > 0;else no_qta">
          <b *ngIf="currentVariant.um == 'PZ'; else meterAvailable" [translate]="'piecesInStock'"
            [translateParams]="{qta: currentVariant.qtaDisponibile}">
          </b>
        </ng-container>
        <ng-template #no_qta>
          <b class="has-text-warning has-text-weight-bold" [translate]="'quantityOutOfStock'">&nbsp;Out of stock</b>
        </ng-template>
      </div>
      <div *ngIf="currentVariant.qtaDisponibile > 0">
        <span style="margin-right: 0;" [translate]="'shipment48h'">
          Spedizione in 48h
        </span>
        <span>
          <i class="fas fa-truck"></i>
        </span>
      </div>
      <div *ngIf="currentVariant.sottoScorta">
        <span [translate]="'fewArticle'">
          Sotto scorta
        </span>
      </div>
      <ng-container *ngIf="restoration$ | async as restoration">
        <div>
          <span [translate]="'inProductionPieces'" [translateParams]="{qta: restoration.quantita}">
          </span>
        </div>
        <div>
          <span [translate]="'restorationEstimatedFrom'">
            Ripristino stimato il
          </span>&nbsp;{{restoration.dataTermine | date : 'mediumDate' : undefined : locale }}
        </div>
      </ng-container>
    </ng-container>
    <div class="bottom">
      <div class="is-clearfix prices">
        <span class="is-pulled-right has-text-right" *ngIf="shopMode == 0">
          <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span><br />
          <span class="is-size-5 has-text-weight-semibold"
            *ngIf="currentVariant.prezzoRiservato">{{currentVariant.prezzoRiservato | currency:'EUR'}}</span>
          <span class="is-size-5 has-text-weight-semibold" *ngIf="!currentVariant.prezzoRiservato">N/A</span>
        </span>
        <span class="is-pulled-right has-text-right">
          <span class="is-size-7" [translate]="'listPrice'">Prezzo di listino</span><br />
          <span class="is-size-5 has-text-weight-semibold" *ngIf="currentVariant.prezzo"
            [class.is-line-through]="shopMode == 0">{{currentVariant.prezzo | currency:'EUR'}}</span>
          <span class="is-size-5 has-text-weight-semibold" *ngIf="!currentVariant.prezzo">N/A</span>
        </span>
        <div class="has-text-right" *ngIf="currentVariant.anthologyStar && discountStar > 0 && shopMode == 0">
          <span class="tag is-light is-size-7" [translate]="'discountAnthologyStar'"
            [translateParams]="{discount: discountStar}"></span>
        </div>
      </div>
    </div>
    <div *ngIf="currentVariant.mxStar; else anthology" class="anthology-holder">
      <div class="anthology-chevron" [pTooltip]="'MxStar'" tooltipPosition="right" tooltipStyleClass="m-tooltip">
        <span class="mxstar-icon">
          <i class="fas fa-star fa-fw"></i>
        </span>
      </div>
    </div>
    <ng-template #anthology>
      <div *ngIf="currentVariant | isAnthology" class="anthology-holder">
        <div class="anthology-chevron" [pTooltip]="accManagedStock" tooltipPosition="right"
          tooltipStyleClass="m-tooltip">
          <span class="anthology-icon">
            <img src="/images/anthology.png" height="10" />
          </span>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<ng-template #meterAvailable>
  <b [translate]="'quantityInStock'" [translateParams]="{qta: currentVariant.qtaDisponibile}"></b>
</ng-template>