<div class="column">
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="columns">
        <div class="column is-narrow">
          <figure class="image is-128x128" [class.draggable]="list.listState == 0" dndHandle hoverIntent
            (onHoverIntent)="item.hoverImage = true" (mouseleave)="item.hoverImage = false">
            <img fallback-src="https://via.placeholder.com/128x128" query="width=128&height=128&mode=crop"
              *ngIf="item.type == 0" [cdnImg]="item.image" [productId]="item.productModel">
            <img fallback-src="https://via.placeholder.com/128x128" query="width=128&height=128&mode=crop"
              *ngIf="item.type == 1" [cdnImg]="item.image" type="accessory">
          </figure>
        </div>
        <div class="column">
          <h2 class="title is-5">
            <span>{{item.idBrand | brandName | async }}&nbsp;</span>
            <span *ngIf="item.name" [ngClass]="{'has-background-success': item.searchedPublicCode}">{{item.name}}</span>
            <span *ngIf="item.esWarning">&nbsp;<i class="fas fa-fw fa-exclamation-triangle has-text-warning"
                [pTooltip]="translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i></span>
            <span class="is-size-6">&nbsp;{{item.categoryName}}&nbsp;</span>
          </h2>
          <h2 *ngIf="!item.name" class="title is-5">
            <span [translate]="'accessoryProductNoLongerAvailable'"></span>
            <i *ngIf="item.esWarning" class="fas fa-fw fa-exclamation-triangle has-text-warning"
              [pTooltip]="translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
          </h2>
          <h3 class="subtitle is-6 buttons">
            <span>
              <span>{{item.sku}}</span>
              <span *ngIf="item.anthology || item.vms == true"
                class="has-text-uppercase is-size-7 has-text-right has-text-info has-text-weight-semibold">
                <span style="margin-right: 0">Anthology</span>
                <span *ngIf="item.anthologyStar" class="has-text-warning has-text-weight-bold">Star</span>
                <span *ngIf="item.vms" class="has-text-warning has-text-weight-bold">VMS</span>
              </span>
              <i *ngIf="item.description" class="fas fa-info-circle fa-sm" [pTooltip]="item.description"
                tooltipStyleClass="m-tooltip"></i>
            </span>
            &nbsp;
            <a *ngIf="list.listState == 0" 
              (click)="setPopperItem(item); popperListContent.toggle($event)"
              class="has-text-weight-semibold">
              <span [translate]="'modify'" style="margin-right:0.5em">Modifica</span><i class="fas fa-angle-down"></i>
            </a>
            <a *ngIf="item.type == 0 && item.productModel" (click)="goToProduct()" class="has-text-weight-semibold">
              <span [translate]="'goToProduct'">Vai al prodotto</span>
            </a>
          </h3>
          <div class="columns info">
            <div class="column is-narrow">
              <span class="is-size-7"><span [translate]="'quantity'">Quantità</span>
                <ng-container *ngIf="item.um">({{item.um | lowercase }})</ng-container>
              </span><br />
              <p-inputNumber [showButtons]="true" [size]="2" [(ngModel)]="item.quantity" [min]="1" 
                (ngModelChange)="updatePrezzoFinale()"
                [disabled]="list.listState != 0"></p-inputNumber>
            </div>
            <div class="column is-narrow">
              <span class="is-size-7" [translate]="'available'">Disponibili</span><br />
              <span class="is-size-5 has-text-weight-semibold"
                [class.has-text-info]="item.availability >= item.quantity"
                [class.has-text-warning]="item.availability < item.quantity">{{item.availability}}</span>
            </div>
            <ng-container *ngIf="shopMode == 0">
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'priceList'">Prezzo di listino</span><br />
                <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzo"
                  [class.is-line-through]="item.discount > 0 || item.discount2 > 0 || item.discount3 > 0">{{item.prezzo | currency:'EUR'}}</span>
                <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzo">N/A</span>
              </div>
              <ng-container *ngIf="!list.hideDiscount">
                <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <span class="icon-text">
                    <span class="is-size-7" [translate]="'discount'">Sconto</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount" placeholder="0%" 
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountVariant(item)"
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <span class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>&nbsp;2</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber type="text" [(ngModel)]="item.discount2" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountVariant(item)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <span class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>&nbsp;3</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount3" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountVariant(item)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
              </ng-container>

              <div class="column is-narrow prezzo-vendita">
                <span class="is-size-7" [translate]="'salePrice'">Prezzo di vendita</span><br />
                <input style="width: 7.5em;" class="p-inputtext p-inputnumber-input"
                  [(ngModel)]="item.prezzoVendita" [disabled]="list.listState != 0"
                  (ngModelChange)="updatePriceVariant($event)" currencyMask
                  (focus)="selectOnfocus($event)" />
              </div>
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span><br />
                <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzoRiservato">{{item.prezzoRiservato |
                  currency:'EUR'}}</span>
                <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzoRiservato">N/A</span>
              </div>
              <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                <span class="is-size-7" [translate]="'profitMargin'">Margine</span><br />
                <span class="is-size-5 has-text-weight-semibold"
                  [class.has-text-danger]="(item | calculateMargine) < 0.05">
                  {{item | calculateMargine | percent}}
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="shopMode == 1">
              <ng-container *ngIf="!list.hideDiscount">
                <div class="column is-narrow">
                  <span class="is-size-7" [translate]="'priceList'">Prezzo di listino</span><br />
                  <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzo"
                    [class.is-line-through]="item.discount > 0 || item.discount2 > 0 || item.discount3 > 0">{{item.prezzo
                    | currency:'EUR'}}</span>
                  <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzo">N/A</span>
                </div>
                <div class="column is-narrow">
                  <span class="icon-text">
                    <span class="is-size-7" [translate]="'discount'">Sconto</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountVariant(item)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow">
                  <span class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>2</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount2" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountVariant(item)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow">
                  <span class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>3</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount3" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountVariant(item)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
              </ng-container>
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'reservedPrice'">Prezzo riservato</span><br />
                <input style="width: 7.5em" class="p-inputtext p-inputnumber-input"
                  [(ngModel)]="item.prezzoVendita" (ngModelChange)="updatePriceVariant($event)" currencyMask
                  (focus)="selectOnfocus($event)" />
              </div>
            </ng-container>
          </div>
          <div class="columns info is-hidden-fullhd" *ngIf="shopMode == 0">
            <ng-container *ngIf="!list.hideDiscount">
              <div class="column is-narrow">
                <span class="icon-text">
                  <span class="is-size-7" [translate]="'discount'">Sconto</span>
                  <span class="icon is-small">
                    <i class="is-size-7 fas fa-circle-info" [pTooltip]="'discountCanBeDecimal' | translate"></i>
                  </span>
                </span>
                <br />
                <p-inputNumber type="text" [(ngModel)]="item.discount" placeholder="0%" 
                  [disabled]="list.listState != 0" (ngModelChange)="updateDiscountVariant(item)" 
                  [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
              </div>
              <div class="column is-narrow">
                <span class="icon-text">
                  <span class="is-size-7"><span [translate]="'discount'">Sconto</span>&nbsp;2</span>
                  <span class="icon is-small">
                    <i class="is-size-7 fas fa-circle-info" [pTooltip]="'discountCanBeDecimal' | translate"></i>
                  </span>
                </span>
                <br />
                <p-inputNumber [(ngModel)]="item.discount2" placeholder="0%" 
                  [disabled]="list.listState != 0" (ngModelChange)="updateDiscountVariant(item)" 
                  [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
              </div>
              <div class="column is-narrow">
                <span class="icon-text">
                  <span class="is-size-7"><span [translate]="'discount'">Sconto</span>&nbsp;3</span>
                  <span class="icon is-small">
                    <i class="is-size-7 fas fa-circle-info" [pTooltip]="'discountCanBeDecimal' | translate"></i>
                  </span>
                </span>
                <br />
                <p-inputNumber [(ngModel)]="item.discount3" placeholder="0%" 
                  [disabled]="list.listState != 0" (ngModelChange)="updateDiscountVariant(item)" 
                  [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
              </div>
            </ng-container>
            <div class="column is-narrow">
              <span class="is-size-7" [translate]="'profitMargin'">Margine</span><br />
              <span class="is-size-5 has-text-weight-semibold"
                [class.has-text-danger]="(item | calculateMargine) < 0.05">
                {{item | calculateMargine | percent}}
              </span>
            </div>
          </div>
          <div *ngIf="item.childItems?.length > 0">
            <a (click)="toggleAccessories()" class="has-text-weight-semibold">
              <i class="fas fa-angle-down"></i>&nbsp;&nbsp;{{item.childItems.length}} <span
                [translate]="'accessoryAdded'">accessori aggiuntivi</span>
            </a>
          </div>
        </div>
       
        <div class="column is-2 price">
          <div class="has-text-right">
            <div class="title is-size-5 has-text-weight-semibold" *ngIf="prezzoFinale">{{prezzoFinale | currency:'EUR'}}
            </div>
            <div class="title is-size-5 has-text-weight-semibold" *ngIf="!prezzoFinale">N/A</div>
            <ng-container *ngIf="item.childItems?.length > 0">
              <div class="subtitle is-size-7" [translate]="'accessoriesTotal'">di cui accessori:</div>
              <div class="title is-size-6 has-text-weight-semibold" *ngIf="accessoryTotal">{{accessoryTotal |
                currency:'EUR'}}</div>
              <div class="title is-size-6 has-text-weight-semibold" *ngIf="!accessoryTotal">N/A</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-12 child-item" *ngIf="item.childItems?.length > 0 && showAccessories">
      <div class="columns" *ngFor="let acc of item.childItems">
        <div class="column is-narrow">
          <div style="width:128px"></div>
        </div>
        <div class="column is-narrow">
          <figure class="image is-64x64">
            <img fallback-src="https://via.placeholder.com/128x128" [cdnImg]="acc.image"
              query="width=128&height=128&mode=crop" type="accessory">
          </figure>
        </div>
        <div class="column">
          <h4 *ngIf="acc.name" class="subtitle has-text-weight-semibold is-size-6">
            <span [ngClass]="{'has-background-success': acc.searchedPublicCode}">{{acc.name}}</span>&nbsp;<span
              class="is-size-7">{{acc.sku}}</span>
          </h4>
          <h4 *ngIf="!acc.name" class="subtitle has-text-weight-semibold is-size-6">
            <span [translate]="'accessoryProductNoLongerAvailable'"></span>
            <i *ngIf="acc.esWarning" class="fas fa-fw fa-exclamation-triangle has-text-warning"
              [pTooltip]="translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
          </h4>
          <div class="columns info is-vcentered">
            <div class="column is-narrow">
              <div class="is-size-7"><span [translate]="'quantity'">Quantità</span>
                <ng-container *ngIf="acc.um">({{acc.um | lowercase }})</ng-container>
              </div>
              <p-inputNumber [showButtons]="true" [size]="2" [(ngModel)]="acc.quantity" [min]="1" (ngModelChange)="updatePrezzoFinale()"
                [disabled]="list.listState != 0"></p-inputNumber>
            </div>
            <div class="column is-narrow">
              <div>
                <span class="is-size-7" [translate]="'available'">Disponibili</span>&nbsp;&nbsp;
                <span class="is-size-6 has-text-weight-semibold"
                  [class.has-text-info]="acc.availability >= acc.quantity"
                  [class.has-text-warning]="acc.availability < acc.quantity">
                  {{acc.availability}}
                </span>
              </div>
              <div>
                <a *ngIf="list.listState == 0" class="is-size-7 has-text-danger has-text-weight-semibold"
                  (click)="deleteChildItem(acc)" [translate]="'delete'">Elimina</a>
              </div>
            </div>
            <ng-container *ngIf="shopMode == 0">
              <div class="column is-narrow">
                <div>
                  <span class="is-size-7" [translate]="'listPrice'">Prezzo listino</span>&nbsp;&nbsp;
                  <span class="is-size-6 has-text-weight-semibold" *ngIf="acc.prezzo"
                    [class.is-line-through]="acc.discount > 0 || acc.discount2 > 0 || acc.discount3">{{acc.prezzo |
                    currency:'EUR'}}</span>
                  <span class="is-size-6 has-text-weight-semibold" *ngIf="!acc.prezzo">N/A</span>
                </div>
                <div>
                  <span class="is-size-7" [translate]="'salePrice'">Prezzo di vendita</span>&nbsp;&nbsp;
                  <span class="is-size-6 has-text-weight-semibold">{{acc | calculatePrezzoVendita |
                    currency:'EUR'}}</span>
                </div>
              </div>
              <ng-container *ngIf="!list.hideDiscount">
                <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <div class="icon-text">
                    <span class="is-size-7" [translate]="'discount'">Sconto</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </div>
                  <p-inputNumber [(ngModel)]="acc.discount" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountAccessory(acc)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <div class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>&nbsp;2</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </div>
                  <p-inputNumber [(ngModel)]="acc.discount2" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountAccessory(acc)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <div class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>&nbsp;3</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </div>
                  <p-inputNumber [(ngModel)]="acc.discount3" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountAccessory(acc)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
              </ng-container>
              <div class="column is-narrow">
                <div>
                  <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span>&nbsp;&nbsp;
                  <span class="is-size-6 has-text-weight-semibold" *ngIf="acc.prezzoRiservato">{{acc.prezzoRiservato |
                    currency:'EUR'}}</span>
                  <span class="is-size-6 has-text-weight-semibold" *ngIf="!acc.prezzoRiservato">N/A</span>
                </div>
                <div class="is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <span class="is-size-7" [translate]="'profitMargin'">Margine</span>&nbsp;&nbsp;
                  <span class="is-size-6 has-text-weight-semibold"
                    [class.has-text-danger]="(acc | calculateMargine ) < 0.05">{{acc | calculateMargine |
                    percent}}</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="shopMode == 1">
              <ng-container *ngIf="!list.hideDiscount">
                <div class="column is-narrow">
                  <span class="is-size-7" [translate]="'listPrice'">Prezzo listino</span>&nbsp;&nbsp;
                  <span class="is-size-6 has-text-weight-semibold" *ngIf="acc.prezzo"
                    [class.is-line-through]="acc.discount > 0 || acc.discount2 > 0 || acc.discount3 > 0">{{acc.prezzo | currency:'EUR'}}</span>
                  <span class="is-size-6 has-text-weight-semibold" *ngIf="!acc.prezzo">N/A</span>
                </div>
                <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <div class="icon-text">
                    <span class="is-size-7" [translate]="'discount'">Sconto</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </div>
                  <p-inputNumber [(ngModel)]="acc.discount" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountAccessory(acc)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <div class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>&nbsp;2</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </div>
                  <p-inputNumber [(ngModel)]="acc.discount2" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountAccessory(acc)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <div class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>&nbsp;3</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </div>
                  <p-inputNumber [(ngModel)]="acc.discount3" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountAccessory(acc)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
              </ng-container>
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'reservedPrice'">Prezzo riservato</span>&nbsp;&nbsp;
                <span class="is-size-6 has-text-weight-semibold">{{acc | calculatePrezzoVendita |
                  currency:'EUR'}}</span>
              </div>
            </ng-container>
          </div>
          <div class="columns info is-vcentered is-hidden-fullhd">
            <ng-container *ngIf="!list.hideDiscount" >
              <div class="column is-narrow">
                <div class="icon-text">
                  <span class="is-size-7" [translate]="'discount'">Sconto</span>
                  <span class="icon is-small">
                    <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                  </span>
                </div>
                <p-inputNumber [(ngModel)]="acc.discount" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountAccessory(acc)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
              </div>
              <div class="column is-narrow">
                <div class="icon-text">
                  <span class="is-size-7"><span [translate]="'discount'">Sconto</span>&nbsp;2</span>
                  <span class="icon is-small">
                    <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                  </span>
                </div>
                <p-inputNumber [(ngModel)]="acc.discount2" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountAccessory(acc)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
              </div>
              <div class="column is-narrow">
                <div class="icon-text">
                  <span class="is-size-7"><span [translate]="'discount'">Sconto</span>&nbsp;3</span>
                  <span class="icon is-small">
                    <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                  </span>
                </div>
                <p-inputNumber [(ngModel)]="acc.discount3" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscountAccessory(acc)" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
              </div>
            </ng-container>
            <div class="column is-narrow"  *ngIf="shopMode == 0">
              <span class="is-size-7" [translate]="'profitMargin'">Margine</span><br />
              <span class="is-size-5 has-text-weight-semibold"
                [class.has-text-danger]="(acc | calculateMargine) < 0.05">{{acc | calculateMargine | percent}}</span>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-overlayPanel #popperListContent>
  <ng-container *ngIf="popperItem?.type==0 && popperItem?.name && popperItem?.tmp !== true">
    <a (click)="gotoConfiguration(popperItem)" class="has-text-dark"
      [translate]="'configuration'">Configurazione</a><br />
  </ng-container>
  <ng-container *ngIf="popperItem?.tmp === true">
    <span [translate]="'duplicateArticle'">Articolo duplicato</span><br />
  </ng-container>
  <ng-container *ngIf="sections.length > 1">
    <a (click)="inplaceSection = true" class="has-text-dark" [translate]="'changeSection'">Sposta di sezione <br /></a>
  </ng-container>
  <ng-container *ngIf="sections.length > 1 && inplaceSection">
    <div class="field has-addons">
      <div class="control is-expanded">
        <div class="select is-small is-fullwidth">
          <select [(ngModel)]="inplaceCurrentSection">
            <option value="" [translate]="'noSection'">Nessuna sezione</option>
            <option *ngFor="let sec of inplaceAllowedSections" [value]="sec.value">{{sec.label}}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-info is-small" (click)="changeSection()">
          <i class="fas fa-check"></i>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="popperItem?.name">
    <a class="has-text-dark" (click)="clone(popperItem)" [translate]="'duplicateArticle'">Duplica a fine
      documento</a><br />
  </ng-container>
  <a (click)="removeItem(popperItem?.configurationId)" class="has-text-danger" [translate]="'delete'">Elimina</a>
</p-overlayPanel>