<section class="section">
  <div class="container is-widescreen">
    <div class="columns">
      <div class="column is-one-fifth list-enum">
        <h3 class="title is-4" [translate]="'profile'">Profilo</h3>
        <ul>
          <li>
            <a class="has-text-dark" [routerLink]="['/profile', 'detail'] | localize" routerLinkActive="has-text-weight-bold" [translate]="'customerDetail'">Dettagli cliente</a>
          </li>
          <li>
            <a class="has-text-dark" [routerLink]="['/profile', 'settings'] | localize" routerLinkActive="has-text-weight-bold" [translate]="'settings'">Impostazioni</a>
          </li>
          <li *ngIf="!isViewer">
            <a class="has-text-dark" [routerLink]="['/profile', 'paymentMethods'] | localize" routerLinkActive="has-text-weight-bold" [translate]="'paymentMethods'">paymentMethods</a>
          </li>
          <li *ngIf="!isViewer">
            <a class="has-text-dark" [routerLink]="['/profile', 'contracts'] | localize" routerLinkActive="has-text-weight-bold" [translate]="'myAgreements'">I miei contratti</a>
          </li>
          <li *ngIf="!isViewer">
            <a class="has-text-dark" [routerLink]="['/profile', 'address'] | localize" routerLinkActive="has-text-weight-bold" [translate]="'myAddresses'">I miei indirizzi</a>
          </li>
          <li *ngIf="!isViewer">
            <a class="has-text-dark" [routerLink]="['/profile', 'checkprize'] | localize" routerLinkActive="has-text-weight-bold">Check Prize</a>
          </li>
        </ul>
        <hr />
        <ul>
          <li *ngIf="!isViewer">
            <a class="has-text-dark" [routerLink]="['/orders'] | localize" [class.has-text-weight-bold]="false" [translate]="'myOrders'">I miei ordini</a>
          </li>
          <li>
            <a class="has-text-dark" [routerLink]="['/profile', 'notifications'] | localize" [class.has-text-weight-bold]="false" routerLinkActive="has-text-weight-bold" [translate]="'myNotifications'"></a>
          </li>
          <li>
            <a class="has-text-dark" [routerLink]="['/lists'] | localize" [class.has-text-weight-bold]="false">
              <span [translate]="'listsAndQuotations'" *ngIf="!isViewer">Liste e preventivi</span>
              <span [translate]="'yoursQuotations'" *ngIf="isViewer">I miei preventivi</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="column is-four-fifths detail" *ngIf="claims">
        <h2 class="title is-4">{{claims.ragSoc}}</h2>
        <h3 class="subtitle">{{claims.given_name}} {{claims.family_name}}</h3>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>

