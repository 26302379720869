<div class="section-no-ori-pad footer-1 scroll-top bg-gray-3 has-text-centered" [translate]="'backTop'" >Torna su</div>
<footer class="footer-2 bg-gray-2" *ngIf="!isViewer">
  <div class="container is-widescreen">
    <div class="section-no-ori-pad" style="padding-bottom: 0">
      <div class="columns">
        <div class="column">
          <h4 class="title is-5 has-text-weight-bold" [translate]="'myAccount'">Il mio account</h4>
          <ul>
            <li><a [routerLink]="['/profile', 'detail'] | localize" *ngIf="(shopMode$ | async) == 0" [translate]="'customerDetail'" >Dettagli cliente</a></li>
            <li><a [routerLink]="['/profile', 'address'] | localize" *ngIf="(shopMode$ | async) == 0" [translate]="'myAddresses'">I miei indirizzi</a></li>
            <li><a [routerLink]="['/orders'] | localize" *ngIf="(shopMode$ | async) == 0" [translate]="'myOrders'">I miei ordini</a></li>
            <li><a [routerLink]="['/profile', 'settings'] | localize" *ngIf="(shopMode$ | async) == 0" [translate]="'settings'">Impostazioni</a></li>
            <li><a [routerLink]="['/lists'] | localize" class="has-text-dark" [translate]="'listsAndQuotations'">Liste e preventivi</a></li>
          </ul>
        </div>
        <div class="column">
          <h4 class="title is-5 has-text-weight-bold" [translate]="'categories'">Categorie</h4>
          <ul>
            <li *ngFor="let cat of categories$ | async">
              <a [routerLink]="['/catalog', cat.id] | localize">{{cat.name}}</a>
            </li>
            <li>
              <a [routerLink]="['/accessories'] | localize" [translate]="'accessories'">Accessori</a>
            </li>
          </ul>
        </div>
        <div class="column">
          <h4 class="title is-5 has-text-weight-bold" [translate]="'paymentMethods'">Metodi di pagamento</h4>
          <ul>
            <li><a [translate]="'stdB2b'" [routerLink]="['/profile', 'paymentMethods'] | localize" fragment="defaultPayMode" >Standard B2B</a></li>
            <li><a [translate]="'bankTransferLowerCase'" [routerLink]="['/profile', 'paymentMethods'] | localize" fragment="bankTransfer" >Bonifico bancario</a></li>
            <li><a [translate]="'creditCard'" [routerLink]="['/profile', 'paymentMethods'] | localize" fragment="creditCards" >Carta di credito</a></li>
            <li><a>PayPal</a></li>
          </ul>
        </div>
        <div class="column">
          <h4 class="title is-5 has-text-weight-bold">MyAnthology</h4>
          <ul>
            <li><a [routerLink]="['/my-anthology'] | localize" fragment="spedizione-veloce" [translate]="'fastShipping'">Spedizione veloce</a></li>
            <li><a [routerLink]="['/my-anthology'] | localize" fragment="costi-trasporto" [translate]="'lowShopping'">Costi di trasporto bassi</a></li>
            <li><a [routerLink]="['/my-anthology'] | localize" fragment="prezzi-netti" [translate]="'netPrices'">Prezzi netti</a></li>
            <li><a [routerLink]="['/my-anthology'] | localize" fragment="outlet">Outlet</a></li>
            <li><a [routerLink]="['/my-anthology'] | localize" fragment="logistica" [translate]="'innovativeSolutionLogistic'">Logistica innovativa</a></li>
            <li><a [routerLink]="['/my-anthology'] | localize" fragment="vms">Management stock</a></li>
          </ul>
        </div>
        
      </div>
    </div>
  </div>
</footer>
<!--<hr class="bg-gray-3 is-marginless" />-->
<footer class="footer-3 bg-gray-1">
  <div class="container is-widescreen">
    <div class="section-no-ori-pad has-text-centered">
      <img src="/images/macrolux-logo.png" width="140" />
      <!--<h2 class="is-size-3 is-uppercase font-condensed has-text-weight-bold">Macrolux Anthology</h2>-->
      <ul class="ul-ori">
        <li><a (click)="setLangAndRefresh('italy')"><span>Italia</span></a></li>
        <li><a (click)="setLangAndRefresh('austria')"><span>Österreich</span></a></li>
        <li><a (click)="setLangAndRefresh('germany')"><span>Deutschland</span></a></li>
        <li><a (click)="setLangAndRefresh('france')">France</a></li>
        <li><a (click)="setLangAndRefresh('swiss')"><span>Schweiz</span></a></li>
        <li><a (click)="setLangAndRefresh('spain')"><span>España</span></a></li>
        <li><a (click)="setLangAndRefresh('europe')"><span>Europe</span></a></li>
        <li><a (click)="setLangAndRefresh('asia')"><span>Asia</span></a></li>
        <li><a (click)="setLangAndRefresh('australia')"><span>Australia</span></a></li>
        <li><a (click)="setLangAndRefresh('mideast')"><span>Middle East</span></a></li>
      </ul>
    </div>
  </div>
</footer>
<!-- <footer class="footer-4 bg-gray-1 is-size-7" style="font-size: 0.85rem !important;"> 
  <div class="container is-widescreen" *ngIf="!isViewer">
    <div class="section-no-ori-pad">
      <div class="columns">
        <div class="column has-text-weight-bold has-text-white">
          <a href="https://intra.macrolux.it/" target="_blank">Intra<br> <span class="has-text-weight-normal has-text-grey-light" [innerHtml]="'manageRelationship' | translate"></span></a>
        </div>
        <div class="column has-text-weight-bold has-text-white">
          <a href="https://cfg.macrolux.it/" target="_blank">CFG<br> <span class="has-text-weight-normal has-text-grey-light" [innerHtml]="'configurationForIllumination' | translate" ></span></a>
        </div>
        <div class="column has-text-weight-bold has-text-white">
          <a href="https://ayuppie.com">Ayuppie<br> <span class="has-text-weight-normal has-text-grey-light" [translate]="'manageProjects'">Gestione dei progetti</span></a>
        </div>
      </div>
    </div>
  </div>
</footer> -->
<footer class="footer-5 bg-gray-1" style="font-size: 0.85rem !important;">
  <div class="container is-widescreen">
    <div class="section-no-ori-pad last" [class.viewer]="isViewer">
      <ul class="ul-ori has-text-centered">
        <li><a href="" [translate]="'conditionsofUseSale'"></a></li>
         <li><a  [translate]="'privacyNotice'"></a></li>
         <!-- (click)="openIframe('https://www.iubenda.com/privacy-policy/23791216')" -->
        <li><a href="" [translate]="'legalArea'"></a></li>
        <li><a href="" [translate]="'cookieNotice'"></a></li>        
        <li><span class="has-text-grey-light">© 2015-2019, Macrolux.it, srl - P.IVA 03547130264 Web Agency SCP srl.</span></li>
      </ul>
    </div>
  </div>
</footer>

<!--<div class="modal is-active" *ngIf="showModal" >
  <div class="modal-background" (click)="closeIFrame()"></div>
  <div class="modal-content">
    <iframe-component [url]="urlToOpen"></iframe-component>
  </div>
  <button class="modal-close is-large" (click)="closeIFrame()"></button>
</div>-->
