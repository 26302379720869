import { Component, OnInit, ViewChild, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ListService } from '../list.service';
import { CatalogService } from '../catalog.service';
import { StoreService } from '../store.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReturnTo } from '../shared/costants';

@Component({
  selector: 'list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css'],
})
export class ListItemComponent implements OnInit, OnChanges, OnDestroy {

  @Input() item: any;
  @Input() list: any;

  @Output() onTotalChange = new EventEmitter<number>();

  hasDiscount: boolean = false;
  prezzoFinale: number = 0
  accessoryTotal: number = 0;
  showAccessories: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();
  popperItem: any;
  @ViewChild('popperListContent', { static: true }) popperListContent: any;

  shopMode: number = 1;
  notValidItem: string;

  translations: any = {};

  constructor(private router: Router, private listService: ListService,
    private catalogService: CatalogService, private storeService: StoreService) {


  }

  ngOnInit(): void {
    let translationReq = marker(['articleProductNotValid', 'confirmDeleteItemFromList', 'confirmDeleteAccessoryFromVariant']);

    this.storeService.translateService.stream(translationReq).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.catalogService.getShopMode().pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.shopMode = t;
      this.updatePrezzoFinale();
    });
  }

  ngOnChanges() {
    this.accessoryTotal = 0;
    this.listService.fillItemDetail(this.item);
    this.updatePrezzoFinale();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setPopperItem(item) {
    this.popperItem = item;
  }

  gotoConfiguration(item) {
    this.listService.setCurrentList(this.list);
    this.catalogService.populateAllCache(this.item.idBrand).subscribe(() => {
      this.router.navigate(this.storeService.createUrl(["/product", item.productModel, item.configurationId]) as any[], {
        queryParams: {
          returnTo: ReturnTo.list,
        }
      });
    });
  }

  removeItem(configurationId) {
    if (confirm(this.translations["confirmDeleteItemFromList"])) {
      this.list.items = this.list.items.filter(i => i.configurationId != configurationId);
      this.onTotalChange.emit(this.shopMode);
    }
  }

  deleteChildItem(acc) {
    if (confirm(this.translations["confirmDeleteAccessoryFromVariant"])) {
      this.item.childItems = this.item.childItems.filter(c => c.referenceId != acc.referenceId);
      this.updatePrezzoFinale();
    }
  }

  toggleAccessories() {
    this.showAccessories = !this.showAccessories;
  }

  clone(item) {
    this.listService.cloneListItem(this.list.id, item).subscribe(t => {
      this.list.items.push(t);
      this.popperListContent.hide();
    });
  }

  updatePrezzoFinale() {
    if (!this.item.quantity) {
      this.item.quantity = 1;
    }
    let allOk = this.item.prezzo && this.item.prezzoRiservato && this.item.quantity;
    if (allOk) {
      if (this.shopMode == 0)
        this.prezzoFinale = (this.item.prezzoRiservato * this.item.quantity);
      else
        this.prezzoFinale = (this.item.prezzo * this.item.quantity);
    } else {
      this.prezzoFinale = 0;
      this.accessoryTotal = 0;
      this.item.esWarning = true;
      return;
    }
    let counter = 0;

    if (this.item.childItems) {
      for (let cItem of this.item.childItems) {
        if (!cItem.quantity) {
          cItem.quantity = 1;
        }

        allOk = allOk && cItem.prezzoRiservato && cItem.prezzo && cItem.quantity;
        if (!allOk) {
          break;
        }

        if (cItem.prezzo) {
          if (this.shopMode == 0)
            counter += (cItem.prezzoRiservato * cItem.quantity);
          else
            counter += (cItem.prezzo * cItem.quantity);
        }
      }
    }
    if (allOk) {
      this.accessoryTotal = counter;
      this.prezzoFinale = counter + this.prezzoFinale;
      this.item.esWarning = false;
      this.onTotalChange.emit(this.shopMode);
    } else {
      this.accessoryTotal = 0;
      this.prezzoFinale = 0;
      this.item.esWarning = true;
    }
  }

  goToProduct() {
    this.catalogService.populateAllCache(this.item.idBrand).subscribe(() => {
      this.router.navigate(this.storeService.createUrl(['/product', this.item.productModel]) as any[]);
    });
  }
}
