<!-- Per l'utente normale il cliente è selezionato automaticamente -->
<h2 class="title-hr is-size-3 is-uppercase font-condensed has-text-weight-bold">
  <span>
    <span [translate]="'performance'">Performance</span>&nbsp;
    <ng-container *ngIf="(level==='company')">
      <span *ngIf="cliente">
        {{cliente.ragSoc}}
      </span>
      <span>cliente</span>
    </ng-container>
    
  </span>
</h2>
<!-- Admin sceglie i clienti con un autocomplete -->
<div class="field" *ngIf="level=='admin'">
  <div class="control ui-fluid">
    <app-search-companies [company]="cliente" (selectedCompany)="onSelectedCompany($event)"></app-search-companies>
  </div>
</div>
<!-- L'agente sceglie i clienti con una select -->
<div class="field" *ngIf="level=='agent'">
  <div class="control ui-fluid">
    <div class="select">
      <select [(ngModel)]="cliente" (ngModelChange)="onSelectedCompany($event)">
        <option [ngValue]="null" [translate]="'selectCompany'">--seleziona azienda--</option>
        <option *ngFor="let option of clientiAgente" [ngValue]="option">{{option.ragSoc}}</option>
      </select>
    </div>
  </div>
</div>
<div class="columns">
  <!-- Equilibrio Prodotti/Profili-->
  <div class="column is-4">
    <div *ngIf="!loadingEquilibrio; else loading">
      <div *ngIf="statsEquilibrio">
        <p class="has-text-centered title is-5" [translate]="'balanceProductsProfiles'">
          Equilibrio
          prodotti/profili
        </p>
        <p class="has-text-centered subtitle is-6" [translate]="'last12Months'">ultimi 12 mesi</p>
      </div>
    </div>
  </div>
  <!-- Preventivi-->
  <div class="column is-4">
    <div *ngIf="!loadingPreventivi; else loading">
      <div *ngIf="statsPreventivi">
        <p class="has-text-centered title is-5" [translate]="'orderConversion'">
          Conversione in ordine
        </p>
        <p class="has-text-centered subtitle is-6" [translate]="'last12Months'">ultimi 12 mesi</p>
      </div>
    </div>
  </div>
  <!-- Fidelizzazione-->
  <div class="column is-4">
    <div *ngIf="!loadingOrdinato || !loadingBudget; else loading">
      <div *ngIf="statsBudget && statsOrdinato">
        <p class="has-text-centered title is-5">Budget {{today | date:'yyyy'}}</p>
        <p class="has-text-centered subtitle is-6">
          {{statsBudget.budgetIntra | currency:'EUR':'symbol'}}
          <span [ngClass]="{'has-text-danger' : deltaPercent < 0, 'has-text-success' : deltaPercent >= 0 }">
            <i class="fas fa-fw" [ngClass]="{'fa-caret-down' : deltaPercent < 0, 'fa-caret-up' : deltaPercent >= 0 }"></i>{{deltaPercent | absolute | percent}}
          </span>
        </p>
      </div>
    </div>
  </div>
</div>
<div class="columns is-vcentered">
  <div class="column is-4">
    <ng-container *ngIf="statsEquilibrio">
      <p-chart type="pie" [data]="equilibrioChartData" [options]="equilibrioChartOptions" [plugins]="pluginsChart"
               *ngIf="statsEquilibrio.totaleAlluminio > 0 || statsEquilibrio.totaleProdotti > 0; else no_total"></p-chart>
    </ng-container>
  </div>
  <div class="column is-4">
    <div *ngIf="statsPreventivi">
      <div class="columns is-vcentered">
        <div class="column has-text-centered">
          <ngx-gauge type="arch" [size]="160" [thresholds]="thresholdConfigQ"
                     [value]="statsPreventivi.numeroPreventivi*100/12" [thick]="10">
            <ngx-gauge-value>
              <span *ngIf="statsPreventivi.numeroPreventivi > 0">{{ statsPreventivi.numeroPreventivi | number }}</span>
              <span *ngIf="statsPreventivi.numeroPreventivi === 0">n/a</span>
            </ngx-gauge-value>
            <ngx-gauge-label [translate]="'quotations'">
              preventivi
            </ngx-gauge-label>
          </ngx-gauge>
          <p class="is-size-5 has-text-weight-semibold">{{statsPreventivi.totalePreventivi | currency:'EUR':'symbol'}}</p>
        </div>
        <div class="column is-narrow">
          <p class="has-text-centered title is-5">
            <span class="icon"><i class="fas fa-2x fa-long-arrow-alt-right"></i></span>
          </p>
          <p class="has-text-centered subtitle is-6">{{(statsPreventivi.numeroOrdini / statsPreventivi.numeroPreventivi) | percent }}</p>
        </div>
        <div class="column has-text-centered">
          <ngx-gauge type="arch" [size]="160" [thresholds]="thresholdConfigO"
                     [value]="statsPreventivi.numeroOrdini*100/12" [thick]="10">
            <ngx-gauge-value>
              <span *ngIf="statsPreventivi.numeroOrdini > 0">{{ statsPreventivi.numeroOrdini | number }}</span>
              <span *ngIf="statsPreventivi.numeroOrdini === 0">n/a</span>
            </ngx-gauge-value>
            <ngx-gauge-label [translate]="'orders'">
              ordini
            </ngx-gauge-label>
          </ngx-gauge>
          <p class="is-size-5 has-text-weight-semibold">{{statsPreventivi.totaleOrdini | currency:'EUR':'symbol'}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="column is-4">
    <ng-container *ngIf="statsBudget && statsOrdinato">
      <p-chart type="bar" [data]="budgetChartData" [options]="budgetChartOptions" [plugins]="pluginsChart"></p-chart>
    </ng-container>
  </div>
</div>
<ng-template #loading>
  <span [translate]="'loading'">loading...</span>
</ng-template>
<ng-template #no_total>
  <p class="has-text-centered title is-1" style="font-weight: normal">
    n/a
  </p>
</ng-template>
