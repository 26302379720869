<ng-container *ngIf="customer">
  <ng-container *ngIf="!multicolumn">
    <div class="columns">
      <div class="column is-6">
        <label class="label" [translate]="'companyName'">Ragione Sociale</label>
        <div class="control is-expanded ui-fluid">
          <p-autoComplete [(ngModel)]="shipAddress.company" (ngModelChange)="raiseEvent()"
                          [suggestions]="address" optionLabel="ragSoc1" (completeMethod)="filter($event)"
                          [placeholder]="'companyName' | translate" (onSelect)="onSelect($event)" (onClear)="onClear()"
                          [emptyMessage]="'noResultFound' | translate"
                          [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                          [dropdown]="true" [inputStyleClass]="!shipAddress.company ? 'input is-danger' : 'input'" name="addressCompany" >
            <ng-template let-addr pTemplate="item">
              <div class="ui-helper-clearfix">
                <div class="title is-6">{{addr.ragSoc1}}</div>
                <div class="subtitle is-7">{{addr.indirizzo}}&nbsp;-&nbsp;{{addr.localita}}&nbsp;({{addr.provincia}})&nbsp;-&nbsp;{{addr.codStato}}</div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label" [translate]="'name'">Nome</label>
        <div class="control">
          <input name="nomeCliente" [(ngModel)]="shipAddress.firstName" class="input " type="text" [placeholder]="'name' | translate">
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'familyName'">Cognome</label>
        <div class="control">
          <input name="cognomeCliente" [(ngModel)]="shipAddress.lastName" class="input " type="text" [placeholder]="'familyName' | translate">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Email</label>
        <div class="control">
          <input name="emailCliente" [(ngModel)]="shipAddress.emailAddress" class="input " type="text" placeholder="Email">
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'telephone'">Telefono</label>
        <div class="control">
          <input name="telefonoCliente" [(ngModel)]="shipAddress.telephoneNumber1" class="input " type="text" [placeholder]="'telephone' | translate">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label" [translate]="'addressWithNumber'">Indirizzo (completo di numero civico)</label>
        <div class="control">
          <input name="indirizzoCliente" [class.is-danger]="!shipAddress.address1" class="input " type="text" [placeholder]="'address' | translate"
                 [(ngModel)]="shipAddress.address1" (ngModelChange)="raiseEvent()" >
        </div>
      </div>
      <div class="column">
        <label class="label">&nbsp;</label>
        <div class="control">
          <input name="indirizzoCliente2" [(ngModel)]="shipAddress.address2" class="input " type="text" [placeholder]="'additionalAddress' | translate">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label" [translate]="'city'">Città</label>
        <div class="control">
          <input name="cittaCliente" [class.is-danger]="!shipAddress.city1" class="input " type="text" [placeholder]="'city' | translate"
                 [(ngModel)]="shipAddress.city1" (ngModelChange)="raiseEvent()" >
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'locality'">Località</label>
        <div class="control">
          <input name="localitaCliente" [(ngModel)]="shipAddress.city2" class="input " type="text" [placeholder]="'locality' | translate">
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'zipCode'">CAP</label>
        <div class="control">
          <input name="capCliente" [class.is-danger]="!shipAddress.zipCode" class="input " type="text" [placeholder]="'zipCode' | translate"
                 [(ngModel)]="shipAddress.zipCode"  (ngModelChange)="raiseEvent()">
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'country'">Nazione</label>
        <div class="control ui-fluid">
          <p-autoComplete [(ngModel)]="country" forceSelection="true"
                          [suggestions]="countries" optionLabel="name" (completeMethod)="search($event)"
                          [placeholder]="'country' | translate" (onSelect)="onCountrySelect($event)" (onClear)="onClearCountry()"
                          [inputStyleClass]="!shipAddress.countryCode ? 'input is-danger' : 'input'"
                          [emptyMessage]="'noCountryAvailable' | translate"
                          [minLength]="2" name="statoCliente" ></p-autoComplete>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="multicolumn">
    <div class="columns">
      <div class="column">
        <label class="label" [translate]="'companyName'">Ragione Sociale</label>
        <div class="control is-expanded ui-fluid">
          <p-autoComplete [(ngModel)]="shipAddress.company" (onClear)="onClear()" (ngModelChange)="raiseEvent()" [immutable]="false"
                          [suggestions]="address" optionLabel="ragSoc1" (completeMethod)="filter($event)" 
                          [inputStyleClass]="!shipAddress.company ? 'input is-danger' : 'input'"
                          [emptyMessage]="'noResultFound' | translate"
                          [placeholder]="'companyName' | translate" (onSelect)="onSelect($event)" [dropdown]="true">
            <ng-template let-addr pTemplate="item">
              <div>
                <div class="title is-6">{{addr.ragSoc1}}</div>
                <div class="subtitle is-7">{{addr.indirizzo}} - {{addr.localita}} ({{addr.provincia}}) - {{addr.codStato}}</div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label" [translate]="'name'">Nome</label>
        <div class="control">
          <input name="nomeCliente" [(ngModel)]="shipAddress.firstName" class="input " type="text" [placeholder]="'name' | translate" >
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'familyName'">Cognome</label>
        <div class="control">
          <input name="cognomeCliente" [(ngModel)]="shipAddress.lastName" class="input " type="text" [placeholder]="'familyName' | translate" >
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Email</label>
        <div class="control">
          <input name="emailCliente" [(ngModel)]="shipAddress.emailAddress" class="input " type="text" placeholder="Email">
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'telephone'">Telefono</label>
        <div class="control">
          <input name="telefonoCliente" [(ngModel)]="shipAddress.telephoneNumber1" class="input " type="text" [placeholder]="'telephone' | translate" >
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label" [translate]="'addressWithNumber'">Indirizzo (completo di numero civico)</label>
        <div class="control">
          <input name="indirizzoCliente" [class.is-danger]="!shipAddress.address1" class="input " type="text"[placeholder]="'address' | translate"
                 [(ngModel)]="shipAddress.address1" (ngModelChange)="raiseEvent()"  >
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="control">
          <input name="indirizzoCliente2" [(ngModel)]="shipAddress.address2" class="input " type="text" [placeholder]="'additionalAddress' | translate">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label" [translate]="'city'">Città</label>
        <div class="control">
          <input name="cittaCliente" [class.is-danger]="!shipAddress.city1" class="input " type="text" [placeholder]="'city' | translate"
                  [(ngModel)]="shipAddress.city1"  (ngModelChange)="raiseEvent()" >
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'locality'">Località</label>
        <div class="control">
          <input name="localitaCliente" [(ngModel)]="shipAddress.city2" class="input " type="text" [placeholder]="'locality' | translate">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label" [translate]="'zipCode'">CAP</label>
        <div class="control">
          <input name="capCliente" [class.is-danger]="!shipAddress.zipCode" class="input " type="text" [translate]="'zipCode'"
                 [(ngModel)]="shipAddress.zipCode" (ngModelChange)="raiseEvent()" >
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'countryCode'">Codice Nazione</label>
        <div class="control">
          <p-autoComplete [(ngModel)]="country" forceSelection="true"
                          [suggestions]="countries" optionLabel="name" (completeMethod)="search($event)"
                          [placeholder]="'country' | translate" (onSelect)="onCountrySelect($event)" (onClear)="onClearCountry()"
                          [inputStyleClass]="!shipAddress.countryCode ? 'input is-danger' : 'input'"
                          [emptyMessage]="'noCountryAvailable' | translate"
                          [minLength]="2" name="statoCliente"></p-autoComplete>
        </div>
      </div>
    </div>
  </ng-container>
  
</ng-container>
