<h2 class="title-hr is-size-3 is-uppercase font-condensed has-text-weight-bold">
    <span><span [translate]="'performance'">Performance</span>&nbsp;Admin</span>
</h2>
<div class="columns" *ngIf="stats$ | async as stats; else loading">
    <div class="column">
      <p class="has-text-centered title is-5">
        <span [translate]="'totalUser'">Totale Utenti</span>
      </p>
      <p class="has-text-centered title is-1">
        {{stats.totalCount | number}}
      </p>
      <p class="has-text-centered subtitle is-6"><a (click)="getExcelUsers()">Esporta <i class="fas fa-spinner fa-pulse" *ngIf="downloadingInformationUser"></i></a></p>
    </div>
    <div class="column">
      <p class="has-text-centered title is-5">
        <span [translate]="'userActiveLast12Month'">Utenti Attivi</span>
      </p>
      <p class="has-text-centered title is-1">
        {{stats.activeCount}}
      </p>
      <p class="has-text-centered subtitle is-6">
        <span [translate]="'lastMonth'">ultimo mese</span>
      </p>
    </div>
    <div class="column">
      <p class="has-text-centered title is-5">
        <span [translate]="'userInactiveLast12Month'">Utenti Dormienti</span>
      </p>
      <p class="has-text-centered title is-1">
        {{stats.sleepersCount}}
      </p>
      <p class="has-text-centered subtitle is-6">
        <span [translate]="'last6Months'">ultimi 6 mesi</span>
      </p>
    </div>
  <div class="column">
    <p class="has-text-centered title is-5">
      <span [translate]="'userInactive'">Utenti Inattivi</span>
    </p>
    <p class="has-text-centered title is-1">
      {{stats.totalCount-stats.sleepersCount-stats.activeCount}}
    </p>
    <p class="has-text-centered subtitle is-6">
      &nbsp;
    </p>
  </div>
</div>
<ng-template #loading>
    <span [translate]="'loading'"></span>
</ng-template>
