<ng-container *ngIf="ready; else searching">
  <div class="container is-fluid" style="overflow-y: auto; height:340px; margin-right: -1rem; margin-top: -1rem; margin-bottom: -1rem; padding-top: 1rem; padding-bottom: 1rem;">
    <div *ngIf="!itemSuggest || itemSuggest.length == 0">
      <span class="has-text-weight-semibold" [translate]="'sorryNoArticleReplace'"></span>
    </div>
    <ng-container *ngIf="itemSuggest && itemSuggest.length > 0">
      <ng-container *ngFor="let suggestedItem of itemSuggest; let last = last">
        <div class="columns">
          <div class="column is-narrow is-flex is-align-content-center is-flex-wrap-wrap">
            <figure class="image is-96x96">
              <img fallback-src="https://via.placeholder.com/96x96" [pTooltip]="suggestedItem.textDifference"
                tooltipPosition="left" tooltipStyleClass="m-tooltip" [tooltipDisabled]="!suggestedItem.textDifference"
                query="width=96&height=96&mode=crop" [cdnImg]="suggestedItem.images[0]"
                [productId]="suggestedItem.productModel">
            </figure>
          </div>
          <div class="column has-content-vertically-aligned">
            <div class="is-size-6">
              <div>
                <div class="is-size-5 has-text-weight-semibold">{{suggestedItem.esolverName}}</div>
                <span>{{suggestedItem.sku}}</span>
                <span *ngIf="suggestedItem.anthology || suggestedItem.anthologyStar || suggestedItem.vms"
                  class="is-size-7 has-text-uppercase has-text-right has-text-info has-text-weight-semibold">
                  &nbsp;<span>Anthology</span>
                  <span *ngIf="suggestedItem.anthologyStar" class="has-text-warning has-text-weight-bold">Star</span>
                  <span *ngIf="suggestedItem.vms" class="has-text-warning has-text-weight-bold">VMS</span>
                </span>
                &nbsp;<i class="fas fa-info-circle fa-sm" *ngIf="suggestedItem.esolverDescription"
                  [pTooltip]="suggestedItem.esolverDescription" tooltipStyleClass="m-tooltip"></i>
              </div>
              <div class="columns info">
                <div class="column is-narrow">
                  <span class="is-size-7" [translate]="'purchasePrice'"></span><br />
                  <div class="is-size-5 has-text-weight-semibold"
                    [class.has-text-danger]="suggestedItem.prezzoRiservato > item.prezzoRiservato"
                    [class.has-text-success]="suggestedItem.prezzoRiservato < item.prezzoRiservato"
                    *ngIf="suggestedItem.prezzoRiservato">
                    {{suggestedItem.prezzoRiservato | currency:'EUR'}}
                  </div>
                  <span class="is-size-5 has-text-weight-semibold" *ngIf="!suggestedItem.prezzoRiservato">N/A</span>
                </div>
                <div class="column is-narrow">
                  <span class="is-size-7" [translate]="'available'"></span><br />
                  <span class="is-size-5 has-text-weight-semibold has-text-info">{{suggestedItem.qtaDisponibile}}</span>
                </div>
                <div class="column has-text-right" style="margin-top: auto;padding-right: 25px">
                  <a [pTooltip]="'substituteArticle' | translate " tooltipStyleClass="m-tooltip" class="button is-link"
                    (click)="selectSuggest(suggestedItem)">
                    <i class="fas fa-exchange-alt"></i>
                  </a>
                </div>
              </div>
              <div style="line-height: 0.75" *ngIf="suggestedItem.prezzoRiservato !== item.prezzoRiservato">
                <span class="is-size-7" *ngIf="suggestedItem.prezzoRiservato > item.prezzoRiservato">
                  <span [translate]="'moreExpensiveThan'">più alto di</span>
                  +{{(suggestedItem.prezzoRiservato - item.prezzoRiservato) | currency:'EUR'}}
                  (+{{(suggestedItem.prezzoRiservato - item.prezzoRiservato) / item.prezzoRiservato | percent}}
                  )
                </span>
                <span class="is-size-7" *ngIf="suggestedItem.prezzoRiservato < item.prezzoRiservato">
                  <span [translate]="'lessExpensiveThan'">più basso di</span>
                  {{(suggestedItem.prezzoRiservato - item.prezzoRiservato) | currency:'EUR'}}
                  ({{(suggestedItem.prezzoRiservato - item.prezzoRiservato) / item.prezzoRiservato | percent}}
                  )
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr *ngIf="!last" />
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-template #searching>
  <div style="padding:1em">
    <p [translate]="'checkMagazine'"></p>
  </div>
</ng-template>