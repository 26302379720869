<div class="field is-grouped">
  <div class="control is-expanded">
    <input type="checkbox" name="hideShowComponent" id="hideShowComponent" class="switch"
      [(ngModel)]="showComponentiConfigInPdf" (ngModelChange)="reloadPdf()" [disabled]="!ready">
    <label for="hideShowComponent">
      <span [translate]="'showComponentConfiguration'"></span>
    </label>
  </div>
  <div *ngIf="admin" class="control is-expanded">
    <input type="checkbox" name="hideProductCode" id="hideProductCode" class="switch" [(ngModel)]="showProductCodeInPdf"
      (ngModelChange)="reloadPdf()" [disabled]="!ready">
    <label for="hideProductCode">
      <span [translate]="'showProductCode'"></span>
    </label>
  </div>
  <div *ngIf="admin" class="control is-expanded">
    <input type="checkbox" name="hideProductImage" id="hideProductImage" class="switch"
      [(ngModel)]="showProductImageInPdf" (ngModelChange)="reloadPdf()" [disabled]="!ready || showProductCodeInPdf">
    <label for="hideProductImage">
      <span [translate]="'showProductImage'"></span>
    </label>
  </div>
  <div class="control">
    <div *ngIf="!ready; else buttonDownload">
      <span [translate]="'loadingNoTrail'">Loading</span>&nbsp;<i class="fas fa-spinner fa-pulse"></i>
    </div>
    <ng-template #buttonDownload>

      <div class="dropdown" [class.is-active]="dropDownPdfModeIsActive" (clickOutside)="closeDropdown()" (click)="dropDownPdfModeIsActive = true" *ngIf="admin" >
        <div class="dropdown-trigger">
          <button class="button">
            <span [translate]="'downloadModePdf'" ></span>
            <span class="icon is-small">
              <i class="fas fa-angle-down"></i>
            </span>
          </button>
        </div>
        <div class="dropdown-menu">
          <div class="dropdown-content">
            <a (click)="printPdfQuotation()" class="dropdown-item" [translate]="'downloadPdfQuotation'"></a>
            <a (click)="printPdfProformaQuotation()" class="dropdown-item" [translate]="'downloadPdfProformaQuotation'"></a>
          </div>
        </div>
      </div>
      <button *ngIf="!admin" class="button is-info" (click)="printPdfQuotation()"
        [translate]="'downloadPdfQuotation'">
        Scarica PDF preventivo
      </button>
    </ng-template>
    
  </div>
</div>
<div class="is-relative">
  <!-- non usare ngIf o hidden, leggere README-->
  <div [ngClass]="{'is-invisible': !ready }" style="height: 780px">
    <ng2-pdfjs-viewer #pdfViewerAutoLoad viewerId="quotationId" (onDocumentLoad)="ready = true"
    [download]="false" [print]="false" [openFile]="false" [viewBookmark]="false">

    </ng2-pdfjs-viewer>
  </div>

  <div id="spinner-wrapper" *ngIf="!ready" style="position: absolute; top: 0; left:50%;">
    <div class="spinner"></div>
  </div>
</div>
