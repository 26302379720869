import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SalesForceService {

    private httpHeadersDefault: HttpHeaders;

    constructor(private httpClient: HttpClient) {
        this.httpHeadersDefault = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    getOpportunities(codCliFor : string) : Observable<any[]> {
        let url: string = '/api/salesForce/find-by-dealer/' + codCliFor;
        return this.httpClient.get<any[]>(url);
    }

    searchOpportunities(query: string) {
        let url: string = '/api/salesForce/find';
        let params = new HttpParams().set("query", query)
        return this.httpClient.get<any[]>(url, { params: params });
    }

    assignOpportunity(quotationId: string, opportunityId: string, total: string) : Observable<any> {
        const paramObj = {
            IdQuotation: quotationId,
            IdOpportunity: opportunityId,
            total: total
        };
        return this.httpClient.post<any>("/api/salesForce/associate_quotation_opportunity", paramObj)
    }

    deassignOpportunity(quotationId: string, opportunityId: string) : Observable<any> {
        const paramObj = {
            IdQuotation: quotationId,
            IdOpportunity: opportunityId,
        };
        return this.httpClient.post<any>("/api/salesForce/deassociate_quotation_opportunity", paramObj)
    }

    createOpportunity(model: any) : Observable<any> {
        return this.httpClient.post<any>("/api/salesForce/create_opportunity", model);
    }


    searchCustomer(query: string) : Observable<any[]> {
        let url: string = '/api/salesForce/find-cliente';
        let params = new HttpParams().set("query", query)
        return this.httpClient.get<any[]>(url, { params: params });
    }

    // retrieveOpportunity(IdQuotation: string) {
    //     let url: string = '/api/salesForce/get-opportunity-of-quotation';
    //     let params = new HttpParams().set("idQuotation", IdQuotation)
    //     return this.httpClient.get<any[]>(url, { params: params });
    // }

}