import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, skipWhile } from 'rxjs/operators';

import { CatalogService } from '../catalog.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardBuyMode  {
  constructor(private catalogService: CatalogService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.catalogService.getShopMode().pipe(
      skipWhile(t => {

        return t == -1;
      }),
      map(t => { return t == 0; }),
      tap(accessGranted => {
        if (!accessGranted)
          console.warn('You tried to go to ' + state.url + ' but you this section is rescticted to \'buy mode\' only');
      }));
  }
}
