<div class="column">
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="columns">
        <div class="column is-narrow">
          <div class="image-upload" style="position:relative">
            <figure class="image is-128x128" [class.draggable]="list.listState == 0" dndHandle hoverIntent
              (onHoverIntent)="item.hoverImage = true" (mouseleave)="item.hoverImage = false">
              <img *ngIf="item.custom.urlImg" fallback-src="https://via.placeholder.com/128x128"
                query="width=128&height=128" [cdnImg]="item.custom.urlImg" [productId]="list.id" [quotationId]="list.id" type="custom">
              <img *ngIf="!item.custom.urlImg" style="max-height: 100%; max-width: 100%; width:auto; margin:auto;"
                [src]="srcNotUrl">
            </figure>
            <a class="delete buttonDelete" *ngIf="(item.custom.fileName || item.custom.urlImg) && list.listState == 0"
              (click)="deleteFile()"></a>
            <ng-container *ngIf="list.listState == 0">
              <label [for]="'file-input' + item.configurationId" class="labelUpload">
                <span class="button buttonUpload"><i class="fas fa-upload"></i></span>
              </label>
              <input [id]="'file-input' + item.configurationId" type="file" accept=".jpg,.jpeg,.png"
                (change)="updateFile($event.target.files)" />
            </ng-container>
          </div>
        </div>
        <div class="column">
          <div class="columns">
            <div class="column">
              <h2 class="title is-size-5">
                <span *ngIf="!editNameMode">{{item.custom.name}}</span>
                <form *ngIf="editNameMode">
                  <div class="field has-addons">
                    <div class="control">
                      <input class="input" style="width:22em" autofocus [maxLength]="50" type="text"
                        [(ngModel)]="newName" [placeholder]="translations['articleProductInsertName']" name="newName">
                    </div>
                    <div class="control">
                      <button type="submit" class="button is-info" (click)="updateName()">
                        <i class="fas fa-check"></i>
                      </button>
                    </div>
                    <div class="control">
                      <button type="submit" class="button" (click)="editName(false)">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </form>
                &nbsp;<i *ngIf="item.esWarning && !editNameMode"
                  class="fas fa-fw fa-exclamation-triangle has-text-warning"
                  [pTooltip]="translations['articleProductCustomNotValid']" tooltipStyleClass="m-tooltip"></i>
              </h2>
              <h3 class="subtitle is-size-6 buttons">
                <a *ngIf="list.listState == 0" (click)="setPopperItem(item);popperListContent.toggle($event)"
                  class="has-text-weight-semibold">
                  <span [translate]="'modify'">Modifica</span>&nbsp;<i class="fas fa-angle-down"></i>
                </a>
              </h3>
            </div>
            <div class="column is-2 price">
              <div class="has-text-right">
                <div class="title is-size-5 has-text-weight-semibold" *ngIf="prezzoFinale">{{(prezzoFinale) |
                  currency:'EUR'}}</div>
                <div class="title is-size-5 has-text-weight-semibold" *ngIf="!prezzoFinale">N/A</div>
              </div>
            </div>
          </div>

          <div class="columns info">
            <div class="column is-narrow">
              <span class="is-size-7" [translate]="'quantity'">Quantità</span><br />
              <p-inputNumber [showButtons]="true" [size]="2" [(ngModel)]="item.quantity" [min]="1" (ngModelChange)="updatePrezzoFinale()"
                [disabled]="list.listState != 0"></p-inputNumber>
            </div>
            <ng-container *ngIf="shopMode == 0">
              <div class="column is-narrow prezzo-vendita">
                <span class="is-size-7" [translate]="'priceList'"></span><br />
                <input style="width: 7.5em" [(ngModel)]="item.custom.prezzoVendita"
                  class="p-inputtext p-inputnumber-input" currencyMask
                  (ngModelChange)="updatePriceListino()" [disabled]="list.listState != 0"
                  (focus)="$event.target.select()" />
              </div>
              <ng-container *ngIf="!list.hideDiscount">
                <div class="column is-narrow">
                  <span class="icon-text">
                    <span class="is-size-7" [translate]="'discount'">Sconto</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow">
                  <span class="icon-text">
                    <span class="is-size-7" ><span [translate]="'discount'">Sconto</span>&nbsp;2</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount2" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow">
                  <span class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>&nbsp;3</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount3" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
              </ng-container>
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'reservedPrice'">Prezzo riservato</span><br />
                <input style="width: 7.5em" [(ngModel)]="item.custom.prezzoRiservato" currencyMask
                  (ngModelChange)="updatePriceRiservato()" class="p-inputtext p-inputnumber-input" 
                  class="p-inputtext p-inputnumber-input"
                  [disabled]="list.listState != 0" (focus)="$event.target.select()" />
              </div>
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span><br />
                <input style="width: 7.5em" [(ngModel)]="item.custom.prezzoAcquisto" currencyMask
                  (ngModelChange)="updatePrezzoFinale()" class="p-inputtext p-inputnumber-input" 
                  class="p-inputtext p-inputnumber-input"
                  [disabled]="list.listState != 0" (focus)="$event.target.select()" />
              </div>
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'measureUnit'"></span><br />
                <div class="control">
                  <span class="select">
                    <select [(ngModel)]="item.custom.unitaMisura" [disabled]="list.listState != 0" class="selectInItem">
                      <option [ngValue]="'pz'" [translate]="'pieces'"></option>
                      <option [ngValue]="'m'" [translate]="'meters'"></option>
                    </select>
                  </span>
                </div>
              </div>
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'profitMargin'">Margine</span><br />
                <span class="is-size-5 has-text-weight-semibold"
                  [class.has-text-danger]="(item.custom.prezzoRiservato - item.custom.prezzoAcquisto)/item.custom.prezzoRiservato < 0.05">{{(item.custom.prezzoRiservato
                  - item.custom.prezzoAcquisto)/item.custom.prezzoRiservato | percent}}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="shopMode == 1">

              <ng-container *ngIf="!list.hideDiscount">
                <div class="column is-narrow">
                  <span class="is-size-7" [translate]="'priceList'"></span><br />
                  <input style="width: 7.5em" [(ngModel)]="item.custom.prezzoVendita" currencyMask
                    (ngModelChange)="updatePriceListino()" class="p-inputtext p-inputnumber-input" 
                    class="p-inputtext p-inputnumber-input"
                    [disabled]="list.listState != 0" (focus)="$event.target.select()" />
                </div>
                <div class="column is-narrow">
                  <span class="icon-text">
                    <span class="is-size-7" [translate]="'discount'">Sconto</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow">
                  <span class="icon-text">
                    <span class="is-size-7" ><span [translate]="'discount'">Sconto</span>2</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount2" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow">
                  <span class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>3</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount3" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
              </ng-container>
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'reservedPrice'"></span><br />
                <input style="width: 7.5em" [(ngModel)]="item.custom.prezzoRiservato" currencyMask
                  (ngModelChange)="updatePriceRiservato()" class="p-inputtext p-inputnumber-input" [disabled]="list.listState != 0"
                  class="p-inputtext p-inputnumber-input"
                  (focus)="$event.target.select()" />
              </div>
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'measureUnit'"></span><br />
                <div class="control">
                  <span class="select">
                    <select [(ngModel)]="item.custom.unitaMisura" [disabled]="list.listState != 0" class="selectInItem">
                      <option [ngValue]="'pz'" [translate]="'pieces'"></option>
                      <option [ngValue]="'m'" [translate]="'meters'"></option>
                    </select>
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-overlayPanel #popperListContent>
  <a (click)="editName(true)" class="has-text-dark" [translate]="'rename'">Rinomina<br /></a>
  <ng-container *ngIf="popperItem?.tmp === true">
    <span [translate]="'duplicateArticle'">Articolo duplicato</span><br />
  </ng-container>
  <ng-container *ngIf="sections.length > 1">
    <a (click)="inplaceSection = true" class="has-text-dark" [translate]="'changeSection'">Sposta di sezione <br /></a>
  </ng-container>
  <ng-container *ngIf="sections.length > 1 && inplaceSection">
    <div class="field has-addons">
      <div class="control is-expanded">
        <div class="select is-small is-fullwidth">
          <select [(ngModel)]="inplaceCurrentSection">
            <option value="" [translate]="'noSection'">Nessuna sezione</option>
            <option *ngFor="let sec of inplaceAllowedSections" [value]="sec.value">{{sec.label}}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-info is-small" (click)="changeSection()">
          <i class="fas fa-check"></i>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="popperItem?.custom?.name">
    <a class="has-text-dark" (click)="clone(popperItem)" [translate]="'duplicateArticle'">Duplica a fine
      documento</a><br />
  </ng-container>
  <a (click)="removeItem(popperItem?.configurationId)" class="has-text-danger" [translate]="'delete'">Elimina</a>
</p-overlayPanel>