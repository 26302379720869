import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogService } from '../catalog.service';
import { cloneDeep, intersection, isNil, orderBy } from 'lodash';
import { Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {

  private allProductFamilies: any[];
  category: any;
  filteredFamilies: any;
  mode: number = -1;

  firstSet = true;

  @ViewChild('modePopper', { static: false }) modePopper: any;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private route: ActivatedRoute, private router: Router, private catalogService: CatalogService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if (this.route.snapshot.params.id)
      this.catalogService.setCurrentCategory(this.route.snapshot.params.id);
    else
      this.catalogService.setCurrentCategory(null);
  }

  ngOnInit() {
    //modifica apposita per mostrare all'ingresso sempre la visualizzazione modelli per arc decor
    combineLatest([this.catalogService.getCurrentBrandId().pipe(filter(id => !isNil(id) && id != '')), this.catalogService.getCatalogMode()])
      .subscribe(([currentBrandId, t]) => {
        if (this.mode == t) return; //non posso sostituirlo con il distinctuntilchange 

        if (this.firstSet && t == 0 && currentBrandId == '64c217bbaa4ee222c8fc8c54') {

          this.firstSet = false;
          this.mode = 1;
        } else {
          this.mode = t;
        }


      });

    combineLatest([this.catalogService.getProductFamilies(),
    this.catalogService.getCurrentCategory(),
    this.catalogService.getApplications()])
      .pipe(takeUntil(this.destroy$)).subscribe(([x, cat, applications]) => {
        this.allProductFamilies = x;
        if (!cat) {
          return;
        }
        let applicationIdToHide = applications.filter(a => a.hiddenCatalog).map(a => a.id);
        let manager = cloneDeep(this.allProductFamilies);
        this.category = cat;
        let childrenCategoriesId = cat.childrens.map(t => t.id);
        let filteredFamilies = manager.filter(f => intersection(f.categories, childrenCategoriesId).length > 0).map(t => {
          t.productsModel = orderBy(t.productsModel.filter(x => childrenCategoriesId.indexOf(x.category) > -1
            && !applicationIdToHide.some(id => id == x.application)),
            ['order', p => p.name.toLowerCase()], ["asc", "asc"]);
          return t;
        });
        this.filteredFamilies = {};
        for (let subCategory of cat.childrens) {
          this.filteredFamilies[subCategory.id] = filteredFamilies.filter(t => t.productsModel.filter(x => x.category === subCategory.id).length > 0);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setMode(mode) {
    this.catalogService.setCatalogMode(mode);
    this.modePopper.hide();
  }

}
