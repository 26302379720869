import { Injectable } from "@angular/core";

import { ListDetailComponent } from "./list-detail.component";

@Injectable()
export class CanDeactivatListGuard  {
  canDeactivate(component: ListDetailComponent): boolean {
    if (component && component.thereAreDifference()) {
      if (confirm(component.translations['proceedWillDestroyModifyList'])) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
