<div class="is-flex" style="gap: 2em;">
  <div class="field">
    <!-- <div class="control" *ngIf="brands$ | async">
      <span [translate]="'youVisualizing'">
      </span>
      &nbsp;
      <span>
        {{currentBrandId | brandName | async}}
      </span>
    </div> -->
    <div class="control">
      <span class="select" [pTooltip]="'currentBrand' | translate" tooltipPosition="bottom">
        <select [ngModel]="currentBrandId" (ngModelChange)="brandChanged($event)">
          <ng-container *ngFor="let brand of brands$ | async">
            <option [ngValue]="brand.id">{{brand.name}}</option>
          </ng-container>
        </select>
      </span>
    </div>
  </div>
  <div class="field has-addons is-flex-grow-1">
    <div class="control has-icons-left is-expanded ui-fluid">
      <p-autoComplete #autocompleteSearch [suggestions]="results" (completeMethod)="search($event)"
        (onSelect)="onSelect($event)" inputStyleClass="input" appendTo="body" [minLength]="2" scrollHeight="654px"
        optionLabel="name" [(ngModel)]="text" (onFocus)="onFocus($event)" [emptyMessage]="'noProductFound' | translate"
        [placeholder]="'searchAllBrand' | translate">
        <ng-template let-item pTemplate="item">
          <div [ngSwitch]="item.type">
            <div class="item" *ngSwitchCase="2">
              <div class="columns">
                <div class="column is-narrow">
                  <figure class="image is-96x96">
                    <img fallback-src="https://via.placeholder.com/96x96" query="width=96&height=96&mode=crop"
                      [cdnImg]="item.image" [productId]="item.id">
                  </figure>
                </div>
                <div class="column has-content-vertically-aligned">
                  <div class="title is-size-5">{{item.name}}&nbsp;-&nbsp;{{item.idBrand | brandName | async}}</div>
                  <div *ngIf="item.category" class="subtitle is-size-6">{{item.category | categoryName:true | async}}
                  </div>
                  <div *ngIf="item.application" class="is-size-6">{{item.application | applicationName | async}}</div>
                </div>
              </div>
            </div>
            <div class="item item-cursor" *ngSwitchDefault>
              <div class="columns is-vcentered">
                <div class="column is-narrow">
                  <figure class="image is-96x96" [class.accessory]="item.type == 1">
                    <img fallback-src="https://via.placeholder.com/96x96" query="width=96&height=96&mode=crop"
                      *ngIf="item.type == 0" [cdnImg]="item.image" [productId]="item.productModel">
                    <img fallback-src="https://via.placeholder.com/96x96" query="width=96&height=96&mode=crop"
                      *ngIf="item.type == 1" [cdnImg]="item.image" type="accessory">
                  </figure>
                </div>
                <div class="column">
                  <div class="title is-size-5" *ngIf="item.name;else no_name">{{item.name}}&nbsp;-&nbsp;{{item.idBrand |
                    brandName | async}}</div>
                  <div class="subtitle is-size-6">{{item.sku}}</div>
                  <div class="columns info">
                    <div class="column is-narrow">
                      <span class="is-size-7" [translate]="'availability'">Disponibilità</span><br />
                      <span class="is-size-5 has-text-weight-semibold">{{item.qtaDisponibile}}</span>
                    </div>
                    <div class="column is-narrow">
                      <span class="is-size-7" [translate]="'listPrice'">Prezzo di listino</span><br />
                      <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzo"
                        [class.is-line-through]="shopMode == 0">{{item.prezzo | currency:'EUR'}}</span>
                      <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzo">N/A</span>
                    </div>
                    <div *ngIf="shopMode == 0" class="column is-narrow">
                      <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span><br />
                      <span class="is-size-5 has-text-weight-semibold"
                        *ngIf="item.prezzoRiservato">{{item.prezzoRiservato
                        |
                        currency:'EUR'}}</span>
                      <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzoRiservato">N/A</span>
                    </div>
                  </div>
                </div>
                <div class="column is-narrow">
                  <div (click)="stopPropagation($event)">
                    <span [translate]="'quantity'">Quantità</span>&nbsp;
                    <p-inputNumber [showButtons]="true" [size]="2" [(ngModel)]="item.quantity"
                      [min]="1"></p-inputNumber>
                  </div>
                  <div style="margin-top: .5em" (click)="stopPropagation($event)">
                    <button class="button is-small is-fullwidth add-button" (click)="addToCart(item)"
                      [disabled]="!item.quantity || item.quantity <= 0" [translate]="'addUpperCase'"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-autoComplete>
      <span class="icon is-small is-left">
        <i class="fas fa-search fa-fw"></i>
      </span>
    </div>
    <div class="control">
      <button class="button is-info" (click)="clear()">
        <i class="fas fa-eraser fa-fw"></i>
      </button>
    </div>
    <ng-template #no_name>
      <div class="title is-size-5" [translate]="'esolverArticleNotFound'">Esolver - Art. non trovato</div>
    </ng-template>
  </div>

</div>