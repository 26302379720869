import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ProductService } from '../../product.service';
import { Subject, filter, fromEvent, skipWhile, takeUntil } from 'rxjs';


@Component({
  selector: 'product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.css']
})
export class ProductImagesComponent implements OnInit, OnDestroy {

  currentVariant: any;
  currentImage: any;
  currentDrawing: any;

  responsiveOptions: any[] | undefined;
  visibleImagesModello: boolean = false;

  activeIndexGallery : number = 0;
  showFullScreenTech: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private productService: ProductService) { }

  ngOnInit() {

    fromEvent(document, 'keydown').pipe(
      skipWhile(() => !this.showFullScreenTech),
      filter((e: KeyboardEvent) => e.key === 'Escape'),
      takeUntil(this.destroy$)
    ).subscribe({
      next: () => { 
        this.closeFullScreen(); 
      }
    });

    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 5
      },
      {
          breakpoint: '768px',
          numVisible: 3
      },
      {
          breakpoint: '560px',
          numVisible: 1
      }
  ];

    this.productService.getCurrentVariant().subscribe(x => {
      this.currentVariant = x;

      if (this.currentVariant.images && this.currentVariant.images.length > 0)
        this.currentImage = this.currentVariant.images[0];
      else
        this.currentImage = null;

      if (this.currentVariant.techDrawings && this.currentVariant.techDrawings.length > 0)
        this.currentDrawing = this.currentVariant.techDrawings[0];
      else
        this.currentDrawing = null;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  openGallery(index: number) {
    this.activeIndexGallery = index;
    this.visibleImagesModello = true;
  }

  openFullScreen() {
    this.showFullScreenTech = true;
  }

  closeFullScreen() {
    this.showFullScreenTech = false;
  }
}
