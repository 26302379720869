import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, forkJoin, of } from 'rxjs';
import { map, take, filter, mergeMap, tap } from 'rxjs/operators';
import { UserPreferenceService } from './user-preference.service';
import * as Sentry from "@sentry/browser";
import { getCurrentBrandId, setCurrentBrandId } from './shared/functions';
import { isNil } from 'lodash';
import { AuthenticationService } from './services/authentication.service';


@Injectable({
    providedIn: 'root'
})
export class CatalogService implements OnDestroy {

    private _orderSidebarOpenSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _categorySource: BehaviorSubject<any> = new BehaviorSubject(null);
    private _familySource: BehaviorSubject<any> = new BehaviorSubject(null);

    private _shopModeSource: BehaviorSubject<number> = new BehaviorSubject(-1);


    private _catalogModeSource: BehaviorSubject<number> = new BehaviorSubject(0);

    private _currentBrand: BehaviorSubject<string> = new BehaviorSubject("");
    private _brands: BehaviorSubject<any[]> = new BehaviorSubject([]);
    private _allCategories: BehaviorSubject<any[]> = new BehaviorSubject([]);
    private _allFamilies: BehaviorSubject<any[]> = new BehaviorSubject([]);
    private _allApplications: BehaviorSubject<any[]> = new BehaviorSubject([]);
    private _allAccessoryCategories: BehaviorSubject<any[]> = new BehaviorSubject([]);

    //private _allCategories$: Observable<Array<any>>;
    //private _allFamilies$: Observable<Array<any>>;
    //private _allApplications$: Observable<Array<any>>;
    //private _accessoryCategories$: Observable<Array<any>>;
    //  private reset$: Subject<boolean> = new BehaviorSubject<boolean>(true);
    private destroy$: Subject<boolean> = new Subject<boolean>();

    private claims: any;

    constructor(private httpClient: HttpClient, private userService: UserPreferenceService, private authService: AuthenticationService) {
        
        
        this.authService.userProfile$.pipe(mergeMap(profile => {
            this.claims = profile;
            return this.userService._userDetailObs;
        })).subscribe((data) => {
            if (data) {
                if (this.claims.view_only && this.claims.view_only == "true") {
                    this.setShopMode(1);
                }
                else {
                    this.setShopMode(data.b2bShopMode);
                }
                this.setCatalogMode(data.b2bCatalogMode);
                this.destroy$.next(true);
            }
        });
    }

    populateAllCache(idBrand? : string) : Observable<any> {

        if(idBrand) { //vuol dire che i brand sono gia' in memoria

            if(idBrand == this._currentBrand.value) {
                return of([
                    this._allCategories.value,
                    this._allApplications.value,
                    this._allAccessoryCategories.value,
                    this._allFamilies.value
                ]);
            }
            setCurrentBrandId(idBrand);
            return this.populateData(idBrand)
            .pipe(tap(([categories, applications, categoriesAccessories, families ]) => {
                this._allCategories.next(categories);
                this._allApplications.next(applications);
                this._allAccessoryCategories.next(categoriesAccessories);
                this._allFamilies.next(families);
            }));
        } else {
            return this.httpClient.get<any[]>("/api/catalog/brands").pipe((mergeMap((brands)=> {
                this._brands.next(brands);
                let currentBrandId = getCurrentBrandId();
                //non c'e' o il brand non esiste piu'
                if(!currentBrandId || brands.findIndex(b => b.id == currentBrandId) == -1) {
                    currentBrandId = brands[0].id;
                    setCurrentBrandId(currentBrandId);
                }
                return this.populateData(currentBrandId);
            })), tap(([categories, applications, categoriesAccessories, families ]) => {
                this._allCategories.next(categories);
                this._allApplications.next(applications);
                this._allAccessoryCategories.next(categoriesAccessories);
                this._allFamilies.next(families);
            }));
        }
    }

    private populateData(idBrand : string) : Observable<any> {

        var params = new HttpParams().set("idBrand", idBrand);
        this._currentBrand.next(idBrand);

        return forkJoin([
            this.httpClient.get<any[]>("/api/catalog/navigation", {params: params}),
            this.httpClient.get<any[]>("/api/catalog/applications", {params: params}),
            this.httpClient.get<any[]>("/api/catalog/accessoriesNavigation", {params: params}),
            this.httpClient.get<any[]>("/api/catalog/families", {params: params})
        ]);
    }

    setShopMode(mode: number) {

        if (this.claims.view_only && this.claims.view_only == "true")
            mode = 1;
        this._shopModeSource.next(mode);
    }

    getShopMode(): Observable<number> {
        if (!this.claims) {
            this.authService.userProfile$.pipe(take(1)).subscribe((profile) => {
                let claimTemp = profile;
                if (claimTemp.view_only && claimTemp.view_only == "true") {
                    this._shopModeSource.next(1);
                } else {
                    this._shopModeSource.next(this._shopModeSource.value);
                }
            });
            this._shopModeSource.next(1);

        } else if (this.claims.view_only && this.claims.view_only == "true") {
            this._shopModeSource.next(1);
        }

        return this._shopModeSource.asObservable();
    }

    setCatalogMode(mode: number) {
        this._catalogModeSource.next(mode);
    }

    getCatalogMode(): Observable<number> {
        return this._catalogModeSource.asObservable();
    }

    toggleOrderSidebar() {
        let status = this._orderSidebarOpenSource.value;
        this._orderSidebarOpenSource.next(!status);
    }

    getOrderSidebarStatus() : Observable<boolean> {
        return this._orderSidebarOpenSource.asObservable();
    }

    getCurrentBrandId() : Observable<string> {
        return this._currentBrand.asObservable();
    }

    getBrands() : Observable<any[]> {
        return this._brands.asObservable().pipe(filter(b => b && b.length > 0));
    }
    getBrandsSync() : any[] {
        return this._brands.value;
    }

    getApplications(): Observable<any[]> {
        return this._allApplications.asObservable().pipe(filter(a => !isNil(a)));
    }

    getCategories(): Observable<any[]> {
        return this._allCategories.asObservable().pipe(filter(a => a && a.length > 0));
    }

    getAccessoryCategories(): Observable<any[]> {
        return this._allAccessoryCategories.asObservable().pipe(filter(a => a && a.length > 0));
    }

    getProductFamilies(): Observable<any[]> {
        return this._allFamilies.asObservable().pipe(filter(a => a && a.length > 0));
    }

    getCurrentCategory(): Observable<any> {
        return this._categorySource.asObservable().pipe(filter(c => c));
    }

    getCurrentFamily(): Observable<any> {
        return this._familySource.asObservable().pipe(filter(f => f));
    }

    setCurrentFamily(familyId): void {
        this.getProductFamilies().subscribe(t => {
            let family = t.find(z => z.id == familyId);
            if (family) {
                this._familySource.next(family);
            }
        })
    }

    setCurrentCategory(categoryId): void {
        this.getCategories().subscribe(allCategories => {
            let category = categoryId ? allCategories.find(t => t.id === categoryId) : allCategories[0];
            if (category) {
                delete category.subcategory;
                this._categorySource.next(category);
                return;
            }

            //we are in a sub category -> search in childrens
            let index = -1;
            category = allCategories.find(t => {
                index = t.childrens.findIndex(x => x.id === categoryId);
                return index > -1;
            });

            if (category) {
                category.subcategory = category.childrens[index];
                this._categorySource.next(category);
                return;
            }
            this._categorySource.next(null);
        });
    }

    getCategoryName(categoryId: string, includeSubCategory: boolean): Observable<string> {
        return this.getCategories().pipe(map(allCategories => {
            let index = -1;
            let category = allCategories.find(t => {
                index = t.childrens.findIndex(x => x.id === categoryId);
                return index > -1;
            });
            if (!category) {
                return "";
            }

            return includeSubCategory ? category.name + " - " + category.childrens[index].name : category.name;
        }));
    }

    getApplicationName(categoryId: string): Observable<string> {
        return this.getApplications().pipe(map(allCategories => {

            if (!allCategories || allCategories.length == 0) {
                Sentry.captureException(new Error("allCategories is null or empty! search " + categoryId));
                return "";
            }

            let category = allCategories.find(t => t.id == categoryId);

            if (!category) {
                return "";
            }

            return category.name;
        }));
    }

    ngOnDestroy(): void {
        //this._allCategories$ = null;
        //this._allFamilies$ = null;
        //this._allApplications$ = null;
        //this._allFamilies$ = null;
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        //this.reset$.unsubscribe();
    }

    getPaymentConditions() : Observable<any[]> {
        return this.httpClient.get<any[]>('/api/catalog/getPaymentDescription');
    }

    updateListino() : Observable<any> {
        return this.httpClient.post<any>('/api/catalog/updateArticlesOld', {});
    }
}
