<div>Check Prize:&nbsp;<span [innerHtml]="'creditValue' | translate"></span></div>
<div class="level" style="margin-top:1.5rem">
  <div class="level-left">
    <div class="level-item">
      <strong [translate]="'date'"></strong>:&nbsp;<strong>{{today | date : 'shortDate' }}</strong>
    </div>
  </div>

  <div class="level-right">
    <div class="level-item ">
      <button class="button is-info" (click)="exportCheckPrize()"><span [translate]="'exportCreationExcel'"></span>&nbsp;<i class="fas fa-file-excel fa-fw"></i></button>
    </div>
  </div>
</div>
<p>
  <strong><span [translate]="'balanceToday'"></span>&nbsp;{{currentCheckPrize | currency:'EUR'}}</strong><br>
  <strong *ngIf="sumCheckPrizes && sumCheckPrizes > 0"><span [translate]="'valueRefundToday'"></span>&nbsp;{{sumCheckPrizes | currency:'EUR'}}</strong>
</p>
<table class="table is-fullwidth">
  <thead>
    <tr>
      <th style="width: 10em" [translate]="'date'">Data</th>
      <th style="width: 8em" [translate]="'operation'">Operazione</th>
      <th [translate]="'reason'">Causale</th>
      <th [translate]="'amount'" style="width: 10em">Importo</th>
    </tr>
  </thead>
  <tr *ngFor="let prize of checkPrizes | orderBy: '-date'">
    <td>
      {{prize.date | date:'short'}}
    </td>
    <td>
      <span class="tag is-warning" style="width:7em;" *ngIf="prize.delta < 0" [translate]="'debit'">Debito</span>
      <span class="tag is-info" style="width:7em;" *ngIf="prize.delta > 0" [translate]="'credit'">Credito</span>
    </td>
    <td>
      {{prize.reason}}
    </td>
    <td>
      {{prize.delta | currency:'EUR'}}
    </td>
  </tr>
</table>
<div class="is-invisible">
  <a class="has-text-dark" [translate]="'generalConditionCheckPrize'">

  </a>
</div>
