<section class="section" style="padding-top: 1.5em">
  <div class="container">
    <div class="tabs is-boxed is-relative" *ngIf="cartType == 'q'">
      <ul>
        <li><a (click)="goToSingleCar()" [translate]="'checkOutSingle'"></a></li>
        <li class="is-active"><a [translate]="'checkOutTriple'"></a></li>
      </ul>
      <a routerLink="../" style="position: absolute; right:0;"><i class="fas fa-times fa-lg"></i></a>
    </div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-size-4" [translate]="'anthologyOrder'">Ordine Anthology</h2>
        <h4 class="subtitle is-size-5">
          <span [translate]="'ordSpedH48Checkout'">Spedizione in 48h</span><br />
          <span class="has-text-info has-text-weight-semibold has-text-centered"
            [hidden]="hideCheckboxCart1 || ((!cartDetail.anthology || cartDetail.anthology.length < 1) && !disableChart1)">
            <input class="is-checkradio is-info is-small" id="disableChart1" type="checkbox" name="disableChart1"
              [(ngModel)]="disableChart1" (ngModelChange)="disableCart()">
            <label for="disableChart1" [translate]="'moveInProduction'">Sposta in produzione</label>
          </span>
        </h4>
        <div class="spacer"></div>
        <checkout-item *ngFor="let item of cartDetail.anthology | orderBy: 'type'" [item]="item"
          (toggleForceAnthologyChange)="updateCheckOut($event)" [hideDiscount]="cartDetail.hideDiscount" [chartType]="0"
          [quotationId]="cartDetail.id" (qtaDisponibileChange)="updateCheckOut($event)"></checkout-item>
      </div>
      <div class="column border">
        <h2 class="title is-size-4" [translate]="'orderReserved'">
          Ordine con
          prenotatazione
        </h2>
        <h4 class="subtitle is-size-5">
          <span [translate]="'shipRestoration'">Spedizione a ripristino scorta </span>
          <!--max date-->
          <br />
          <span class="has-text-info has-text-weight-semibold has-text-centered"
            [hidden]="hideCheckboxCart2 || ((!cartDetail.reserve || cartDetail.reserve.length < 1) && !disableChart2)">
            <input class="is-checkradio is-info is-small" id="disableChart2" type="checkbox" name="disableChart2"
              [(ngModel)]="disableChart2" (ngModelChange)="disableCart()">
            <label for="disableChart2" [translate]="'moveInProduction'">Sposta in produzione</label>
          </span>
        </h4>
        <div class="spacer"></div>
        <checkout-item *ngFor="let item of cartDetail.reserve | orderBy: 'type'" [item]="item" [checkRestoration]="true"
          [chartType]="1" [quotationId]="cartDetail.id" [hideDiscount]="cartDetail.hideDiscount"
          (qtaDisponibileChange)="updateCheckOut($event)"
          (toggleForceAnthologyChange)="updateCheckOut($event)"></checkout-item>
      </div>
      <div class="column">
        <h2 class="title is-size-4">
          <span [translate]="'productionOrder'">Ordine di produzione</span>
          <a routerLink="../" *ngIf="cartType != 'q'"><i class="fas fa-times is-pulled-right"></i></a>
        </h2>
        <h4 class="subtitle is-size-5">
          <span [translate]="'shipInWeeks'" [translateParams]="{num: minNumeroSettimaneForProduction}"></span><br />
          <span class="level is-mobile">
            <span class="level-left">
              <span class="level-item">
                <span class="has-text-info has-text-weight-semibold has-text-centered ">
                  <input [pTooltip]="translations['clickDataToModify']" tooltipEvent="focus" tooltipPosition="left"
                    [tooltipDisabled]="shipDateRequired" tooltipStyleClass="m-tooltip"
                    class="is-checkradio is-info is-small" id="shipDateRequired" type="checkbox" name="shipDateRequired"
                    [(ngModel)]="shipDateRequired" (change)="shipDateToggle()">
                  <label for="shipDateRequired" [translate]="'shipInDate'"></label>
                </span>
                <p-calendar *ngIf="shipDateRequired" [minDate]="minShipDate" dateFormat="dd/mm/yy" [firstDayOfWeek]="1"
                  [maxDate]="maxShipDate" [disabledDates]="holydays" [disabledDays]="[0,6]" [(ngModel)]="shipDate"
                  [inputStyleClass]="'input is-static is-small'"
                  [inputStyle]="{'font-weight':'bold','color':'#173554'}"></p-calendar>
              </span>
            </span>
            <span class="level-right" *ngIf="forcedItems.length > 0">
              <span class="level-item">
                <button class="button is-small" (click)="reset()">Reset</button>
              </span>
            </span>
          </span>
        </h4>
        <div class="spacer"></div>
        <checkout-item *ngFor="let item of cartDetail.onDemand | orderBy: 'type'" [item]="item"
          (toggleForceAnthologyChange)="updateCheckOut($event)" [chartType]="2" [hideDiscount]="cartDetail.hideDiscount"
          [quotationId]="cartDetail.id" (qtaDisponibileChange)="updateCheckOut($event)"></checkout-item>
      </div>
    </div>
    <div class="columns totals">
      <div class="column">
        <div class="columns" *ngIf="cartDetail.anthology.length > 0">
          <div class="column is-narrow">
            <div class="title is-size-4" [translate]="'total'">Totale</div>
            <div class="subtitle is-size-6" [translate]="'shippingCost'">
              Spese di spedizione
            </div>
          </div>
          <div class="column has-text-right">
            <div class="title is-size-4" *ngIf="anthologyTotal > 0;else no_value">{{anthologyTotal | currency:'EUR'}}
            </div>
            <div class="subtitle is-size-6" *ngIf="shippingCost1 > 0; else no_shipCost">{{shippingCostCad |
              currency:'EUR'}}</div>
          </div>
        </div>
      </div>
      <div class="column border">
        <div class="columns" *ngIf="cartDetail.reserve.length > 0">
          <div class="column is-narrow">
            <div class="title is-size-4" [translate]="'total'">Totale</div>
            <div class="subtitle is-size-6" [translate]="'shippingCost'">
              Spese di spedizione
            </div>
          </div>
          <div class="column has-text-right">
            <div class="title is-size-4" *ngIf="reserveTotal > 0;else no_value">{{reserveTotal | currency:'EUR'}}</div>
            <div class="subtitle is-size-6" *ngIf="shippingCost2 > 0; else no_shipCost">{{shippingCostCad |
              currency:'EUR'}}</div>

          </div>
        </div>
      </div>
      <div class="column">
        <div class="columns" *ngIf="cartDetail.onDemand.length > 0">
          <div class="column is-narrow">
            <div class="title is-size-4" [translate]="'total'">Totale</div>
            <div class="subtitle is-size-6" [translate]="'shippingCost'">
              Spese di spedizione
            </div>
          </div>
          <div class="column has-text-right">
            <div class="title is-size-4" *ngIf="onDemandTotal > 0;else no_value">{{onDemandTotal | currency:'EUR'}}
            </div>
            <div class="subtitle is-size-6" *ngIf="shippingCost3 > 0; else no_shipCost">{{shippingCostCad |
              currency:'EUR'}}</div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #no_shipCost>
      <div class="subtitle is-size-6" [translate]="'freeOfCharge'">gratuite</div>
    </ng-template>
    <hr class="marginHr" />
    <div>
      <div class="columns">
        <div class="column">
          <h2 class="title is-size-4" [translate]="'shippingMethod'">
            Metodo di spedizione
          </h2>
          <div class="subtitle is-size-5 has-text-info" *ngIf="cartCount > 1">
            <input class="is-checkradio is-info" id="shipModeMultiple" type="checkbox" name="shipModeMultiple"
              [(ngModel)]="shipModeMultiple" (change)="singleShippingModeChange();" [disabled]="onlyExWork">
            <label for="shipModeMultiple" [translate]="'enableShippingMethod'">
              Abilita
              metodi spedizione differenti per singolo ordine
            </label>
          </div>
        </div>
        <div class="column is-narrow has-text-right">
          <div class="card">
            <div class="card-content has-text-centered has-text-weight-bold">
              <span [translate]="'thereAre'">Ci sono</span>&nbsp;<span *ngIf="shipCount" [translate]="'shipments'" [translateParams]="{qta: shipCount}"></span>
              <span *ngIf="pickupCount > 0 && shipCount > 0">
                &nbsp;<span [translate]="'and'"></span>&nbsp;
              </span>
              <span *ngIf="pickupCount > 0" [translate]="'pickUpCount'" [translateParams]="{qta: pickupCount}"></span>
              <ng-container>
                <br />
                <span [translate]="'withACostOf'" [translateParams]="{amount: shippingTotal}"></span>
              </ng-container>
              <br />
              <button class="button is-warning is-fullwidth has-text-centered has-text-weight-semibold is-size-5"
                style="margin: 1em 0px 1em 0px;" type="button" (click)="goToSingleCar()"
                [translate]="'goToSingleShipping'"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="columns"
        *ngIf="shipModeMultiple && (cartDetail.anthology.length > 0 || cartDetail.reserve.length > 0);else single_shipping">
        <div class="column">
          <div class="shipping-modes" *ngIf="cartDetail.anthology.length > 0">
            <h5 class="title is-size-4" [translate]="'anthologyOrder'">Ordine Anthology</h5>
            <div class="control" *ngIf="!onlyExWork">
              <label class="radio">
                <input type="radio" name="shippingMode1" [(ngModel)]="shippingMode1" [value]="1"
                  (change)="singleShippingModeChange()">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'courier'">CON CORRIERE MACROLUX</span>
              </label>
              <div class="is-size-7" [translate]="'shippingOrganizedByMacrolux'">
                Trasporto organizzato da Macrolux srl - Condizioni economiche del trasporto in base
                al Piano prescelto.
              </div>
            </div>
            <ng-container>
              <div class="control" *ngIf="!onlyExWork && !hideCourierChoiceByCustomer">
                <label class="radio">
                  <input type="radio" name="shippingMode1" [(ngModel)]="shippingMode1" [value]="2"
                    (change)="singleShippingModeChange()">&nbsp;
                  <span class="has-text-weight-semibold" [translate]="'courierCustomer'">CON CORRIERE DEL CLIENTE</span>
                </label>
                <div class="is-size-7" [translate]="'shippingOrganizedByCustomer'">
                  Trasporto organizzato da Macrolux srl, con Corriere Convenzionato del Cliente
                  (costo a carico del Cliente).
                </div>
              </div>
              <div class="control">
                <label class="radio">
                  <input type="radio" name="shippingMode1" [(ngModel)]="shippingMode1" [value]="3"
                    (change)="singleShippingModeChange()">&nbsp;
                  <span class="has-text-weight-semibold" [translate]="'ourMagazine'">NS RITIRO (EX WORKS)</span>
                </label>
                <div class="is-size-7" [translate]="'pickUpMagazine'">
                  Desidero ritirare la merce presso il magazzino Macrolux
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="column border">
          <div class="shipping-modes" *ngIf="cartDetail.reserve.length > 0">
            <h5 class="title is-size-4" [translate]="'orderReserved'">
              Ordine con
              prenotazione
            </h5>
            <div class="control" *ngIf="!onlyExWork">
              <label class="radio">
                <input type="radio" name="shippingMode2" [(ngModel)]="shippingMode2" [value]="1"
                  (change)="singleShippingModeChange()">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'courier'">CON CORRIERE MACROLUX</span>
              </label>
              <div class="is-size-7" [translate]="'shippingOrganizedByMacrolux'">
                Trasporto organizzato da Macrolux srl - Condizioni economiche del trasporto in base
                al Piano prescelto.
              </div>
            </div>
            <div class="control" *ngIf="!onlyExWork && !hideCourierChoiceByCustomer">
              <label class="radio">
                <input type="radio" name="shippingMode2" [(ngModel)]="shippingMode2" [value]="2"
                  (change)="singleShippingModeChange()">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'courierCustomer'">CON CORRIERE DEL CLIENTE</span>
              </label>
              <div class="is-size-7" [translate]="'shippingOrganizedByCustomer'">
                Trasporto organizzato da Macrolux srl, con Corriere Convenzionato del Cliente
                (costo a carico del Cliente).
              </div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="shippingMode2" [(ngModel)]="shippingMode2" [value]="3"
                  (change)="singleShippingModeChange()">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'ourMagazine'">NS RITIRO (EX WORKS)</span>
              </label>
              <div class="is-size-7" [translate]="'pickUpMagazine'">
                Desidero ritirare la merce
                presso il magazzino Macrolux
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="shipping-modes" *ngIf="cartDetail.onDemand.length > 0">
            <h5 class="title is-size-4" [translate]="'productionOrder'">
              Ordine di
              produzione
            </h5>
            <div class="control" *ngIf="!onlyExWork">
              <label class="radio">
                <input type="radio" name="shippingMode3" [(ngModel)]="shippingMode3" [value]="1"
                  (change)="singleShippingModeChange()">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'courier'">CON CORRIERE MACROLUX</span>
              </label>
              <div class="is-size-7" [translate]="'shippingOrganizedByMacrolux'">
                Trasporto organizzato da Macrolux srl - Condizioni economiche del trasporto in base
                al Piano prescelto.
              </div>
            </div>
            <div class="control" *ngIf="!onlyExWork && !hideCourierChoiceByCustomer">
              <label class="radio">
                <input type="radio" name="shippingMode3" [(ngModel)]="shippingMode3" [value]="2"
                  (change)="singleShippingModeChange()">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'courierCustomer'">CON CORRIERE DEL CLIENTE</span>
              </label>
              <div class="is-size-7" [translate]="'shippingOrganizedByCustomer'">
                Trasporto organizzato da Macrolux srl, con Corriere Convenzionato del Cliente
                (costo a carico del Cliente).
              </div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="shippingMode3" [(ngModel)]="shippingMode3" [value]="3"
                  (change)="singleShippingModeChange()">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'ourMagazine'">NS RITIRO (EX WORKS)</span>
              </label>
              <div class="is-size-7" [translate]="'pickUpMagazine'">
                Desidero ritirare la merce
                presso il magazzino Macrolux
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #single_shipping>
        <div class="columns">
          <div class="column">
            <div class="shipping-modes">
              <div class="control">
                <label class="radio" *ngIf="!onlyExWork">
                  <input type="radio" name="shippingMode3" [(ngModel)]="shippingMode3" [value]="1"
                    (change)="singleShippingModeChange()">&nbsp;
                  <span class="has-text-weight-semibold" [translate]="'courier'">CON CORRIERE MACROLUX</span>
                </label>
                <div class="is-size-7" [translate]="'shippingOrganizedByMacrolux'">
                  Trasporto organizzato da Macrolux srl - Condizioni economiche del trasporto in
                  base al Piano prescelto.
                </div>
              </div>
              <ng-container>
                <div class="control" *ngIf="!onlyExWork && !hideCourierChoiceByCustomer">
                  <label class="radio">
                    <input type="radio" name="shippingMode3" [(ngModel)]="shippingMode3" [value]="2"
                      (change)="singleShippingModeChange()">&nbsp;
                    <span class="has-text-weight-semibold" [translate]="'courierCustomer'">CON CORRIERE DEL CLIENTE</span>
                  </label>
                  <div class="is-size-7" [translate]="'shippingOrganizedByCustomer'">
                    Trasporto organizzato da Macrolux srl, con Corriere Convenzionato del Cliente
                    (costo a carico del Cliente).
                  </div>
                </div>
                <div class="control">
                  <label class="radio">
                    <input type="radio" name="shippingMode3" [(ngModel)]="shippingMode3" [value]="3"
                      (change)="singleShippingModeChange()">&nbsp;
                    <span class="has-text-weight-semibold" [translate]="'ourMagazine'">NS RITIRO (EX WORKS)</span>
                  </label>
                  <div class="is-size-7" [translate]="'pickUpMagazine'">
                    Desidero ritirare la merce
                    presso il magazzino Macrolux
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <hr class="marginHr" *ngIf="disableShippingAddress === false" />
    <div *ngIf="disableShippingAddress === false">
      <div class="columns is-vcentered">
        <div class="column">
          <h2 class="title is-size-4" [translate]="'shipAddresses'">
            Indirizzi di spedizione
          </h2>
          <div class="subtitle is-size-5 has-text-info" *ngIf="cartCount > 1">
            <input class="is-checkradio is-info" id="shipAddressMultiple" type="checkbox" name="shipAddressMultiple"
              [(ngModel)]="shipAddressMultiple" (ngModelChange)="updateEnableButtonOrder()">
            <label for="shipAddressMultiple" [translate]="'enableShipAddress'">
              Abilita
              indirizzi di spedizione differenti per singolo ordine
            </label>
          </div>
        </div>
        <div class="column is-narrow">
        </div>
      </div>
      <div class="spacer"></div>
      <div class="columns" *ngIf="customer && shipAddressMultiple && cartCount > 1;else single_shipping_address">
        <div class="column">
          <div *ngIf="cartDetail.anthology.length > 0 && shippingMode1 != 3">
            <h5 class="title is-size-4" [translate]="'anthologyOrder'">Ordine Anthology</h5>
            <shipping-address [(shipAddress)]="shipAddress1" [multicolumn]="true" [customer]="customer"
              (modified)="updateEnableButtonOrder()"></shipping-address>
          </div>
        </div>
        <div class="column border">
          <div *ngIf="cartDetail.reserve.length > 0 && shippingMode2 != 3">
            <h5 class="title is-size-4" [translate]="'orderReserved'">
              Ordine con prenotazione
            </h5>
            <shipping-address [(shipAddress)]="shipAddress2" [multicolumn]="true" [customer]="customer"
              (modified)="updateEnableButtonOrder()"></shipping-address>
          </div>
        </div>
        <div class="column">
          <div *ngIf="cartDetail.onDemand.length > 0 && shippingMode3 != 3">
            <h5 class="title is-size-4" [translate]="'productionOrder'">
              Ordine di produzione
            </h5>
            <shipping-address [(shipAddress)]="shipAddress3" [multicolumn]="true" [customer]="customer"
              (modified)="updateEnableButtonOrder()"></shipping-address>
          </div>
        </div>
      </div>
      <ng-template #single_shipping_address>
        <div class="columns" *ngIf="customer">
          <div class="column">
            <shipping-address [(shipAddress)]="shipAddress3" [customer]="customer"
              (modified)="updateEnableButtonOrder()"></shipping-address>
          </div>
        </div>
      </ng-template>
    </div>
    <hr class="marginHr" />
    <div>
      <div class="columns is-vcentered">
        <div class="column">
          <h2 class="title is-size-4" [translate]="'payment'">
            Pagamento
          </h2>
          <div class="subtitle is-size-5 has-text-info" *ngIf="cartCount > 1">
            <input class="is-checkradio is-info" id="paymentModeMultiple" type="checkbox" name="paymentModeMultiple"
              [(ngModel)]="paymentModeMultiple" (ngModelChange)="changeMethodPayment($event, -1)">
            <label for="paymentModeMultiple" [translate]="'paymentModeSingleOrder'">
              Abilita
              pagamenti differenti per singolo ordine
            </label>
          </div>
        </div>
        <div class="column is-narrow">
        </div>
      </div>
      <div class="spacer"></div>
      <div class="columns"
        *ngIf="paymentModeMultiple && (cartDetail.anthology.length > 0 || cartDetail.reserve.length > 0);else single_payment">
        <div class="column">
          <div class="payment-modes" *ngIf="cartDetail.anthology.length > 0">
            <h5 class="title is-size-4" [translate]="'anthologyOrder'">Ordine Anthology</h5>
            <div class="control" *ngIf="b2bPaymentAllowed">
              <label class="radio" [attr.disabled]="b2bEnables[0] ? null : true"
                [pTooltip]="'insufficientFund' | translate : {amountAvailable: (companyInfo.fido | currency:'EUR') }"
                tooltipStyleClass="m-tooltip" [tooltipDisabled]="b2bEnables[0]">
                <input type="radio" name="paymentMethod1" [(ngModel)]="paymentMethods[0]"
                  [attr.disabled]="b2bEnables[0] ? null : true" [value]="1"
                  (ngModelChange)="changeMethodPayment($event, 0)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'b2bCustomerEntrusted'">CLIENTE B2B AFFIDATO</span>
              </label>
              <div class="is-size-7" [translate]="'paymentAsAgreed'">Come da condizioni di pagamento in uso</div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="paymentMethod1" [(ngModel)]="paymentMethods[0]" [value]="3"
                  (ngModelChange)="changeMethodPayment($event, 0)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'bankTransfer'">BONIFICO BANCARIO</span>
              </label>
              <div class="is-size-7">{{bank}} - {{iban}} BIC/SWIFT : {{swift}}</div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="paymentMethod1" [(ngModel)]="paymentMethods[0]" [value]="2"
                  (ngModelChange)="changeMethodPayment($event, 0)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'creditCardUpper'"></span>
              </label>
              <div class="is-size-7" [translate]="'subTitleCreditCardChoice'"></div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="paymentMethod1" [(ngModel)]="paymentMethods[0]" [value]="4"
                  (ngModelChange)="changeMethodPayment($event, 0)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'paypalUpper'"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="column border">
          <div class="payment-modes" *ngIf="cartDetail.reserve.length > 0">
            <h5 class="title is-size-4" [translate]="'orderReserved'">
              Ordine con
              prenotazione
            </h5>
            <div class="control" *ngIf="b2bPaymentAllowed">
              <label class="radio" [attr.disabled]="b2bEnables[1] ? null : true"
                [pTooltip]="'insufficientFund' | translate : {amountAvailable: (companyInfo.fido  | currency:'EUR') }"
                tooltipStyleClass="m-tooltip" [tooltipDisabled]="b2bEnables[1]">
                <input type="radio" name="paymentMethod2" [(ngModel)]="paymentMethods[1]"
                  [attr.disabled]="b2bEnables[1] ? null : true" [value]="1"
                  (ngModelChange)="changeMethodPayment($event, 1)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'b2bCustomerEntrusted'">CLIENTE B2B AFFIDATO</span>
              </label>
              <div class="is-size-7" [translate]="'paymentAsAgreed'">Come da condizioni di pagamento in uso</div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="paymentMethod2" [(ngModel)]="paymentMethods[1]" [value]="3"
                  (ngModelChange)="changeMethodPayment($event, 1)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'bankTransfer'">BONIFICO BANCARIO</span>
              </label>
              <div class="is-size-7">{{bank}} - {{iban}} BIC/SWIFT : {{swift}}</div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="paymentMethod2" [(ngModel)]="paymentMethods[1]" [value]="2"
                  (ngModelChange)="changeMethodPayment($event, 1)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'creditCardUpper'"></span>
              </label>
              <div class="is-size-7" [translate]="'subTitleCreditCardChoice'"></div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="paymentMethod2" [(ngModel)]="paymentMethods[1]" [value]="4"
                  (ngModelChange)="changeMethodPayment($event, 1)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'paypalUpper'"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="payment-modes" *ngIf="cartDetail.onDemand.length > 0">
            <h5 class="title is-size-4" [translate]="'productionOrder'">
              Ordine di produzione
            </h5>
            <div class="control" *ngIf="b2bPaymentAllowed">
              <label class="radio" [attr.disabled]="b2bEnables[2] ? null : true"
                [pTooltip]="'insufficientFund' | translate : {amountAvailable: (companyInfo.fido  | currency:'EUR') }"
                tooltipStyleClass="m-tooltip" [tooltipDisabled]="b2bEnables[2]">
                <input type="radio" name="paymentMethod3" [(ngModel)]="paymentMethods[2]"
                  [attr.disabled]="b2bEnables[2] ? null : true" [value]="1"
                  (ngModelChange)="changeMethodPayment($event, 2)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'b2bCustomerEntrusted'">CLIENTE B2B AFFIDATO</span>
              </label>
              <div class="is-size-7" [translate]="'paymentAsAgreed'">Come da condizioni di pagamento in uso</div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="paymentMethod3" [(ngModel)]="paymentMethods[2]" [value]="3"
                  (ngModelChange)="changeMethodPayment($event, 2)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'bankTransfer'">BONIFICO BANCARIO</span>
              </label>
              <div class="is-size-7">{{bank}} - {{iban}} BIC/SWIFT : {{swift}}</div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="paymentMethod3" [(ngModel)]="paymentMethods[2]" [value]="2"
                  (ngModelChange)="changeMethodPayment($event, 2)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'creditCardUpper'"></span>
              </label>
              <div class="is-size-7" [translate]="'subTitleCreditCardChoice'"></div>
            </div>
            <div class="control">
              <label class="radio">
                <input type="radio" name="paymentMethod3" [(ngModel)]="paymentMethods[2]" [value]="4"
                  (ngModelChange)="changeMethodPayment($event, 2)">&nbsp;
                <span class="has-text-weight-semibold" [translate]="'paypalUpper'"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <ng-template #single_payment>
        <div class="columns">
          <div class="column">
            <div class="payment-modes">
              <div class="control" *ngIf="b2bPaymentAllowed">
                <label class="radio" [attr.disabled]="b2bEnables[2] ? null : true"
                  [pTooltip]="'insufficientFund' | translate : {amountAvailable: (companyInfo.fido  | currency:'EUR') }"
                  tooltipStyleClass="m-tooltip" [tooltipDisabled]="b2bEnables[2]">
                  <input type="radio" name="paymentMethod3" [(ngModel)]="paymentMethods[2]" [value]="1"
                    [attr.disabled]="b2bEnables[2] ? null : true"
                    (ngModelChange)="changeMethodPayment($event, 2)">&nbsp;
                  <span class="has-text-weight-semibold" [translate]="'b2bCustomerEntrusted'">CLIENTE B2B
                    AFFIDATO</span>
                </label>
                <div class="is-size-7" [translate]="'paymentAsAgreed'">Come da condizioni di pagamento in uso</div>
              </div>
              <div class="control">
                <label class="radio">
                  <input type="radio" name="paymentMethod3" [(ngModel)]="paymentMethods[2]" [value]="3"
                    (ngModelChange)="changeMethodPayment($event, 2)">&nbsp;
                  <span class="has-text-weight-semibold" [translate]="'bankTransfer'">BONIFICO BANCARIO</span>
                </label>
                <div class="is-size-7">{{bank}} - {{iban}} BIC/SWIFT : {{swift}}</div>
              </div>
              <div class="control">
                <label class="radio">
                  <input type="radio" name="paymentMethod3" [(ngModel)]="paymentMethods[2]" [value]="2"
                    (ngModelChange)="changeMethodPayment($event, 2)">&nbsp;
                  <span class="has-text-weight-semibold" [translate]="'creditCardUpper'"></span>
                </label>
                <div class="is-size-7" [translate]="'subTitleCreditCardChoice'"></div>
              </div>
              <div class="control">
                <label class="radio">
                  <input type="radio" name="paymentMethod3" [(ngModel)]="paymentMethods[2]" [value]="4"
                    (ngModelChange)="changeMethodPayment($event, 2)">&nbsp;
                  <span class="has-text-weight-semibold" [translate]="'paypalUpper'"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <div #containerCreditCard class="is-hidden">
        <hr class="marginHr" />
        <h2 class="title is-size-4" [translate]="'creditCardPlural'"></h2>
        <div class="subtitle is-size-5 has-text-info" [translate]="'choiceOrCreateCard'"></div>
        <div class="spacer"></div>
        <div class="columns is-vcentered">

          <div class="column" [ngClass]="{'is-hidden':insertNewCard}">
            <ul>
              <li class="field has-addons" *ngFor="let card of creditCards">
                <a class="radio-link is-flex">
                  <!-- sarebbe da settare la classe 'is-active' quando il radio button è attivo... -->
                  <label class="radio is-flex" style="align-items: center;">
                    <input type="radio" name="creditCardSelect" [(ngModel)]="crediCardSelectId" [value]="card.id">&nbsp;
                    <span>
                      <span class="has-text-weight-semibold">{{card.brand | titlecase}}</span>&nbsp;<span
                        [translate]="'endWith'"></span>&nbsp;{{card.lastFourNumber}}&nbsp;<br />
                      <span class="is-size-7"><span [translate]="'expireAt'"></span>&nbsp;{{card.expireMonth |
                        number:'2.0-0'}}/{{card.expireYear}}</span>&nbsp;<span>{{card.holderCard}}</span>
                    </span>
                  </label>
                </a>
              </li>
            </ul>
            <div class="spacer"></div>
            <div class="field">
              <p class="control">
                <button (click)="insertNewCard = true; crediCardSelectId = undefined;" class="button is-info"
                  [translate]="'newCard'">
                  Nuova Carta
                </button>
              </p>
            </div>
          </div>
          <div class="column" [ngClass]="{'is-hidden':insertNewCard}">
            <div class="notification is-clearfix">
              <div class="level">
                <div class="level-right">
                  <div class="level-item">
                    <div>
                      <div class="title is-5" [translate]="'totalCard'"></div>
                      <small [translate]="'includeIva'" *ngIf="countryCart.codStato == 'IT'"></small>
                    </div>
                  </div>
                </div>
                <div class="level-left">
                  <div class="level-item">
                    <div class="title is-5">{{totalCard | currency:'EUR'}}</div>
                  </div>
                </div>
              </div>
              <div class="spacer"></div>
              <div class="is-size-6" [translate]="'checkOutStripeText'">
              </div>
              <div class="spacer"></div>
              <div>
                <img class="is-pulled-right" style="height:2em" src="/images/powered_by_stripe.png" />
              </div>
            </div>
          </div>
          <div class="card" [ngClass]="{'is-hidden':!insertNewCard}">
            <header class="card-header" style="background-color: #173554;">
              <p class="card-header-title is-size-5 has-text-weight-normal has-text-white" [translate]="'newCard'">
                Nuova carta
              </p>
              <a href="#" *ngIf="creditCards" (click)="insertNewCard = false" class="card-header-icon has-text-white"
                aria-label="more options">
                <span class="icon">
                  <i class="fas fa-times" aria-hidden="true"></i>
                </span>
              </a>
            </header>
            <div class="card-content">
              <div class="columns is-vcentered">
                <div class="column">
                  <label class="label" [translate]="'creditCardHolder'"></label>
                  <div class="control is-expanded">
                    <input class="input" type="text" placeholder="Name" [(ngModel)]="intestatarioCarta">
                  </div>
                  <label class="label" style="margin-top: 1.5rem;" [translate]="'creditCardData'">Dati della
                    carta</label>
                  <div class="control is-expanded">
                    <div id="formCreditCard3Cart"></div>
                  </div>
                </div>
                <div class="column">
                  <div class="notification is-clearfix">
                    <div class="level">
                      <div class="level-right">
                        <div class="level-item">
                          <div>
                            <div class="title is-5" [translate]="'totalCard'"></div>
                            <small [translate]="'includeIva'" *ngIf="countryCart.codStato == 'IT'"></small>
                          </div>
                        </div>
                      </div>
                      <div class="level-left">
                        <div class="level-item">
                          <div class="title is-5">{{totalCard | currency:'EUR'}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="spacer"></div>
                    <div class="is-size-6" [translate]="'checkOutStripeText'">
                    </div>
                    <div class="spacer"></div>
                    <div>
                      <img class="is-pulled-right" style="height:2em" src="/images/powered_by_stripe.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <hr class="marginHr" />
    <div class="columns">
      <div class="column"></div>
      <div class="column is-narrow has-text-right">
        <total-checkout [projectDiscountValue]="projectDiscountValue" [listTotalReserved]="listTotalReserved"
          [extraSconto]="extraSconto" [checkPrizeApplied]="checkPrizeApplied"
          [customerDiscountApplied]="customerDiscountApplied" [shippingTotal]="shippingTotal"></total-checkout>
      </div>
    </div>
    <div class="spacer"></div>
    <div class="columns" style="align-items: flex-end">
      <div class="column" style="align-self: flex-start;">
        <h2 class="title is-size-4" [translate]="'accept'">
          Conferma
        </h2>
        <div class="subtitle is-size-5" *ngIf="pickupCount + shipCount > 1" [translate]="'willConfirmMultiOrders'"
          [translateParams]="{count: (pickupCount + shipCount).toString()}">
        </div>
        <div class="subtitle is-size-5" *ngIf="pickupCount + shipCount == 1" [translate]="'willConfirmSingleOrder'">
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label" [translate]="'orderReference'">Riferimento ordine</label>
          <div class="control">
            <input class="input" type="text" maxlength="25" [(ngModel)]="rifOrdine" [placeholder]="'ref' | translate">
          </div>
        </div>
        <div>&nbsp;</div>
        <div class="field">
          <label class="label" [translate]="'checkPriceUsableForMax'">
            Check prize
            utilizzabile (max. 10% val. acquisto)
          </label>
          <div class="field has-addons">
            <div class="control is-expanded">
              <input class="input" type="text" disabled readonly [value]="checkPrize | currency:'EUR'" />
            </div>
            <div class="control" [pTooltip]="checkPrizeDisableText" tooltipStyleClass="m-tooltip"
              [tooltipDisabled]="!checkPrizeDisableText">
              <button *ngIf="userdata" class="button is-warning" (click)="applyCheckPrize()" [translate]="'apply'"
                [disabled]="cartDetail.projectDiscount > 0 || checkPrizeThreshold[userdata.shipPlan] > listTotalReserved || (!(checkPrize > 0) || checkPrizeApplied > 0)">
                Applica
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <button class="button is-large is-fullwidth" routerLink="../">
          <span [translate]="'backTo'">Torna</span>&nbsp;{{cartType === 'q' ? translations["toQuotation"] :
          translations["toList"]}}
        </button>
        <div class="spacer"></div>
        <button class="button is-large is-link is-fullwidth"
          [class.is-hidden]="paypalSelect && !shippingInvalid && !orderSending"
          [disabled]="orderSending || shippingInvalid" [class.is-loading]="orderSending" (click)="sendOrder()"
          [translate]="'order'">
          Ordina
        </button>
        <div [class.is-hidden]="!paypalSelect || shippingInvalid || orderSending">
          <div class="has-text-weight-bold" [translate]="'payThisAmountWithPaypalAndProceed'"
            [translateParams]="{amountToPay: (totalCard | currency:'EUR')}"></div>
          <div id="paypal-button"></div>
        </div>
      </div>
    </div>
  </div>
</section>
<p-confirmDialog key="confirmOrder"></p-confirmDialog>
<p-toast key="toastErrorCreditCard"></p-toast>

<p-confirmDialog key="confirmChangeModePay" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-dialog [modal]="true" [style]="{width: '350px'}" [header]="'warningAllUpper' | translate"
  [(visible)]="displayInsufficientFund">
  <span [translate]="'insufficientFund'"
    [translateParams]="{amountAvailable: (companyInfo.fido  | currency:'EUR')}"></span>
</p-dialog>
<p-confirmDialog key="sendMailToCustomer"></p-confirmDialog>
<p-dialog [modal]="true" [style]="{width: '350px'}" [header]="'warningAllUpper' | translate"
  [(visible)]="displayNotBothPayPalCreditCard">
  <span [translate]="'cannotBothPayPalCreditCard'"></span>
</p-dialog>