<ng-container *ngIf="!isViewer">

  <ng-container *ngIf="isAdmin">
    <div class="is-divider is-medium" [attr.data-content]="'adminFunction' | translate"></div>
    <!-- <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Force sync </label>
      </div>
      <div class="field-body">
        <div class="field is-grouped">
          <div class="control">
            <button type="button" class="button is-link" (click)="forsesyncArticoli()" >
              Articoli
            </button>
          </div>
          <div class="control">
            <button type="button" class="button is-link" (click)="forsesyncListini()" >
              Listini
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" [translate]="'queueBrochurePdf'">Crea pdf catalogo-listino</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <button type="button" class="button is-link" (click)="enQueuePdfBrochure()"
              [ngClass]="{'is-loading' : enqueuePdf}">
              {{'enqueueBrochurePdf' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="datiBrochureFiles$ | async as datiBrochureFiles">
      <div class="field is-horizontal" *ngIf="datiBrochureFiles.length">
        <div class="field-label is-normal">
          <label class="label" [translate]="'files'">Files</label>
        </div>
        <div class="field-body is-block" *ngIf="!downloadingFile">
          <div class="block " *ngFor="let fileInfo of datiBrochureFiles">
            <button type="button" class="button"
              (click)="downloadFileBrochure(fileInfo.parentDirectory, fileInfo.name)">
              {{fileInfo.parentDirectory | langName}}&nbsp;-&nbsp;{{ fileInfo.lastModify | date : 'medium'}}
            </button>
          </div>
        </div>
        <div class="field-body">
          <div class="field" *ngIf="downloadingFile">
            <progress class="progress" [value]="percentDownload" max="100"></progress>{{percentDownload}}%
          </div>
        </div>
      </div>
    </ng-container>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Gestione listino</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <button type="button" class="button is-link" (click)="updateCurrentListino()">
              CREA LISTINO BACKUP PER PREVENTIVI ESISTENTI
            </button>
          </div>
          <div class="helper">
            Cliccando questo pulsante viene creato un listino di backup identico al listino corrente, i preventivi
            esistenti verranno collegati a questo listino, mentre i preventivi creati successivamente verranno collegati
            al listino corrente. Tale funzione serve per fare degli aggiornamenti al listino corrente senza modificare i
            prezzi dei preventivi esistenti in un determinato momento
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="is-divider is-medium" [attr.data-content]="'profileShopMode' | translate"></div>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label" [translate]="'askUnclock'">Richiedi sblocco</label>
    </div>
    <div class="field-body">
      <div class="field">
        <input id="shopModeSwitch" [(ngModel)]="userData.b2bShopLock" (ngModelChange)="shopLockChange()" type="checkbox"
          name="shopModeSwitch" class="switch" checked="checked">
        <label for="shopModeSwitch">
          <span *ngIf="userData.b2bShopLock" [translate]="'enable'">Attivo</span>
          <span *ngIf="!userData.b2bShopLock" [translate]="'disable'">Disattivo</span>
        </label>
        <p class="help" [translate]="'toGotobuyMode'">per passare in modalità acquista</p>
      </div>
    </div>
  </div>
  <form>
    <div class="field is-horizontal" *ngIf="userData.b2bShopLock">
      <div class="field-label is-normal">
        <label class="label" [translate]="'unlockCode'">Codice sblocco</label>
      </div>
      <div class="field-body">
        <div class="field is-expanded">
          <div class="field">
            <div class="control">
              <input class="input" type="password" [(ngModel)]="userData.shopLockCode" name="shopLockCode">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" [translate]="'defaultMode'">Modalità predefinita</label>
      </div>
      <div class="field-body">
        <div class="field has-addons">
          <p class="control">
            <button class="button" (click)="setDefaultShopMode(0)" [disabled]="userData.b2bShopLock"
              [class.is-primary]="!userData.b2bShopMode">
              <span [translate]="'buy'">Acquista</span>
            </button>
          </p>
          <p class="control">
            <button class="button" (click)="setDefaultShopMode(1)" [class.is-primary]="userData.b2bShopMode">
              <span [translate]="'sell'">Vendi</span>
            </button>
          </p>
        </div>
      </div>
    </div>
  </form>
</ng-container>
<div class="is-divider is-medium" [attr.data-content]="'profileCatalogMode' | translate"></div>
<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label" [translate]="'defaultMode'">Modalità predefinita</label>
  </div>
  <div class="field-body">
    <div class="field has-addons">
      <p class="control">
        <a class="button" (click)="setDefaultCheckOutMode(0)" [class.is-primary]="!userData.b2bCatalogMode">
          <span [translate]="'familyList'">Elenco famiglie</span>
        </a>
      </p>
      <p class="control">
        <a class="button" (click)="setDefaultCheckOutMode(1)" [class.is-primary]="userData.b2bCatalogMode">
          <span [translate]="'modelsList'">Elenco modelli</span>
        </a>
      </p>
    </div>
  </div>
</div>
<div class="is-divider is-medium" [attr.data-content]="'checkModeProfile' | translate"></div>
<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label" [translate]="'defaultMode'">Modalità predefinita</label>
  </div>
  <div class="field-body">
    <div class="field has-addons">
      <p class="control">
        <a class="button" (click)="setDefaultCheckOutMode(1)"
          [class.is-primary]="userData.checkOutMode && userData.checkOutMode == 1">
          <span [translate]="'checkOutSingle'"></span>
        </a>
      </p>
      <p class="control">
        <a class="button" (click)="setDefaultCheckOutMode(2)"
          [class.is-primary]="userData.checkOutMode && userData.checkOutMode == 2">
          <span [translate]="'checkOutTriple'"></span>
        </a>
      </p>
    </div>
  </div>
</div>

<div class="is-divider is-medium" [attr.data-content]="'profileCustomizeQuotation' | translate"></div>
<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">Logo</label>
  </div>
  <div class="field-body">
    <div id="containerLogo" style="position:relative">
      <figure *ngIf="userData?.nomeLogo" class="image is-128x128">
        <img style="max-height: 100%; max-width: 100%; width:auto;" src="{{linkImage}}">
      </figure>
      <a id="buttonDelete" class="delete" style="position:absolute;  top: 0px; right: 0px;"
        (click)="deleteFileLogo()"></a>
    </div>

    <div *ngIf="!userData?.nomeLogo" class="field">
      <div class="file" name="fileLogo">
        <label class="file-label">
          <input class="file-input" type="file" accept=".png" name="resume"
            (change)="uploadFileLogo($event.target.files)">
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label" [translate]="'chooseFile'">
              Seleziona file...
            </span>
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label" [translate]="'header'">Intestazione</label>
  </div>
  <div class="field-body">
    <div class="field">
      <textarea class="textarea" [(ngModel)]="userData.intestazione" placeholder="Acme Inc."></textarea>
    </div>
  </div>
</div>
<ng-container *ngIf="isAdmin">
  <div class="is-divider is-medium" [attr.data-content]="'profileRetailQuotation' | translate"></div>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Logo Retail</label>
    </div>
    <div class="field-body">
      <div id="containerLogo" style="position:relative">
        <figure *ngIf="userData?.nomeLogoRetail" class="image is-128x128">
          <img style="max-height: 100%; max-width: 100%; width:auto;" [src]="linkImageRetail">
        </figure>
        <a id="buttonDelete" class="delete" style="position:absolute;  top: 0px; right: 0px;"
          (click)="deleteFileLogoRetail()"></a>
      </div>

      <div *ngIf="!userData?.nomeLogoRetail" class="field">
        <div class="file" name="fileLogo">
          <label class="file-label">
            <input class="file-input" type="file" accept=".png" name="resume"
              (change)="uploadFileLogoRetail($event.target.files)">
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label" [translate]="'chooseFile'">
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label" [translate]="'headerRetail'"></label>
    </div>
    <div class="field-body">
      <div class="field">
        <textarea class="textarea" [(ngModel)]="userData.intestazioneRetail" placeholder="Acme Inc."></textarea>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="userData?.favorites">
  <div class="is-divider is-medium" [attr.data-content]="'profileFavourites' | translate"></div>
  <div class="columns">
    <div class="column">
      <div *ngFor="let fav of userData.favorites; index as i; first as f" [dndDraggable]="fav" dndEffectAllowed="move"
        (dndEnd)="onDragEnd($event)" (dndStart)="onDragStart($event)" (dndMoved)="onDragged(i, 'move')">
        <div class="drop-zone" [class.no-border]="f" dndDropzone (dndDrop)="onDrop($event, i)">
          <div dndPlaceholderRef class="dndplaceHolder"></div>
        </div>
        <div class="columns is-vcentered" style="cursor:move">
          <div class="column is-narrow">
            <a class="button is-text has-text-link" (click)="deleteFavorite(fav.link)"><i
                class="fas fa-trash-alt"></i></a>
          </div>
          <div class="column" style="width: 20em">
            <a [routerLink]="['fav.link'] | localize" class="has-text-link">{{fav.name}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div class="sticky-footer" *ngIf="this.differenceTest">
  <div class="container">
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item" [translate]="'warningUnsavedChanges'">
          Attenzione: ci sono modifiche non salvate.
        </div>
      </div>
      <div class="level-right">
        <div class="level-item is-marginless">
          <div class="field is-grouped">
            <p class="control">
              <button class="button is-link" [ngClass]="{'is-loading': isSending}" (click)="updateProfile()"
                [translate]="'save'">Salva</button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog key="confirmUpdateListino" icon="pi pi-exclamation-triangle"></p-confirmDialog>