import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from '../../environments/environment';
import { AuthenticationService } from "../../app/services/authentication.service";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private baseUrl: string = environment.identityUrl;

    static readonly RoleCompany: string = "Company";
    static readonly RoleAgent: string = "Agent";
    static readonly RoleAdmin: string = "Super Admin";

    constructor(private http: HttpClient, private oauthService: AuthenticationService) {

    }

    getUserProfile(): Observable<any> {
        return this.oauthService.userProfile$;
    }

    getAddresses(): Observable<any[]> {
        return this.getUserProfile().pipe(map(profile => profile.address));
    }

    isInRole(roleName: string): boolean {
        const identityClaims = this.oauthService.getUserProfile();
        if (!identityClaims) return false;

        if(!identityClaims.role) return false;

        if(identityClaims.role instanceof Array) {
            return identityClaims.role.includes(roleName);
        }
        return identityClaims.role == roleName;
    }

    showAdminControl(): boolean {
        var roleIsAdmin = this.isInRole(UserService.RoleAdmin);
        return roleIsAdmin;
    }

    showAgentControl(): boolean {
        return this.isInRole(UserService.RoleAgent) && !this.isInRole(UserService.RoleAdmin);
    }

    //restituisce le aziende collegate ad un utente
    getCompaniesForAgent(userId: string): Observable<any[]> {
        return this.http.get<any[]>(`${this.baseUrl}/service/service/GetCompaniesForAgent?userId=${userId}`);
    }

    //restituisce l'elenco degli agenti
    getAgents(): Observable<any[]> {
        return this.http.post<any[]>(`${this.baseUrl}/service/service/GetAgents`, []).pipe(
            map(users => {
                if (!users) return [];
                return users.map(u => {
                    const c1 = u.claims.find(x => x.claimType === 'family_name')
                    const c2 = u.claims.find(x => x.claimType === 'given_name')
                    return {
                        uid: u.id,
                        name: c1 ? `${c1.claimValue} ${c2.claimValue}` : u.email
                    }
                });
            }),
            map(users => users.sort((a, b) => a.name <= b.name ? -1 : 1))
        );
    }

    searchCliente(value: string): Observable<any[]> {

        let params = new HttpParams().set('value', value);
        return this.http.get<any[]>(this.baseUrl + '/service/service/searchCompany', { params: params });
    }

    getAgenti(): Observable<any[]> {
        if (this.isInRole(UserService.RoleAdmin)) {
            return this.http.get<any[]>("/api/platformOrder/allAgent");
        } else {
            return of([]);
        }
    }
}
