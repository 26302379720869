import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ListService } from "../list.service";
import { NavigationEnd, Router } from "@angular/router";
import { cloneDeep, clone } from 'lodash';
import { CatalogService } from "../catalog.service";
import { watch, unwatch } from 'melanke-watchjs';
import { diff } from "deep-diff";
import { QuotationService } from '../quotation.service';
import { ConfirmationService } from 'primeng/api';
import { Observable, Subject, combineLatest, forkJoin, of } from "rxjs";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";
import { PdfService } from '../pdf.service';
import { StoreService } from '../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { UserPreferenceService } from '../user-preference.service';
import { UserService } from '../../orders/services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.css'],
})
export class QuotationComponent implements OnInit, OnDestroy {

  originalQuotation: any;
  baseQuotation: any;

  tooltipQuote: string;
  tooltipDisabled: boolean;
  buttonOrderDisabled: boolean;

  isViewer: boolean = false;
  canModify: boolean = false;
  shopMode: number;

  editTitleMode = false;
  newTitle: string;

  titleHovering: boolean = false;

  isAdmin: boolean = false;
  isAgent: boolean = false;
  ownQuotation: boolean = false;

  addingRevision: boolean = false;
  settingRevision: boolean = false;

  listTotalReserved: number;
  listTotalPublic: number;
  listMargin: number;

  changes: any;
  public differenceTest: boolean = false;
  isSending: boolean = false;
  isSending2: boolean = false;
  opportunityAllowed: boolean = false;
  opportunity$: Observable<any>;

  private searchTerms$ = new Subject<string>();
  searchResults: any[];
  codCliFor: string = null;
  company: any;
  allowedDealers: any[];

  translations: any = {};

  destroy$: Subject<boolean> = new Subject<boolean>();
  takeUserData$: Subject<boolean> = new Subject<boolean>();

  companyLoading: boolean = false;

  codCliUser: string;
  showSecProceedWithOrder: boolean;
  userOwnerOfQuotation: any = undefined;

  deletingRevision: boolean = false;


  constructor(private listService: ListService, private catalogService: CatalogService, private fileSaverService: FileSaverService,
    private quotationService: QuotationService, private router: Router, private authService: AuthenticationService,
    private userService: UserService, private userPreferenceService: UserPreferenceService,
    private confirmationService: ConfirmationService, private pdfService: PdfService, private storeService: StoreService) {

  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.thereAreDifference()) {
      $event.returnValue = true;
    }
  }

  ngOnInit() {

    let translationKeys = marker(['requireModeBuy', 'errorCreatingPdf', 'proceed', 'cancel',
      'projectRegistrationAutoCancel', 'proceed', 'cancel', 'articleNotValidInCart', 'backofficeFor',
      'warningAllUpper', 'agentCanNotCheckOut', 'error', 'archiveQuotation', 'confirmSharedQuotationChange',
      'shareWithCompany', 'onlyCustomerB2bCanPerformTheOrder', 'pricesAppliedWillBeSelling',
      'proceedWillDestroyModifyQuotation', 'noCompanyFound', 'notLastRevision', 'areYouSureQuotationUseOldListino']);

    this.showSecProceedWithOrder = this.router.url.split("/").length == 4;
    this.router.events.pipe(filter((e) => { return e instanceof NavigationEnd; }),
      takeUntil(this.destroy$)).subscribe((e: NavigationEnd) => {
        this.showSecProceedWithOrder = e.url.split("/").length == 4;
      });

    const claims = this.authService.getUserProfile();

    this.opportunity$ = this.quotationService.quotationOpportunity;
    this.codCliUser = claims.codCli;
    this.isViewer = claims.view_only && claims.view_only == "true";
    this.isAdmin = !this.isViewer && claims.role && (claims.role.indexOf("Super Admin") > -1);
    this.isAgent = !this.isViewer && !this.isAdmin && claims.role && (claims.role.indexOf("Agent") > -1);

    if (this.isAgent) {
      this.userService.getCompaniesForAgent(claims.sub).subscribe((data: any[]) => {
        this.searchResults = data;
      });
    }

    let obsQuotation = this.quotationService.baseQuotation.pipe(switchMap((t) => {
      let quotation = t;
      if (!this.company || this.company.codCliFor != quotation.assignedCodCliFor) {

        let obs: Observable<any>[] = []
        this.codCliFor = quotation.assignedCodCliFor;

        obs.push(this.quotationService.getCompany(quotation.assignedCodCliFor));
        if (this.isAdmin) {
          obs.push(this.quotationService.retrieveUserOfQuotation(quotation.id));
        }

        return forkJoin(obs).pipe(map((values) => {
          this.company = values[0];
          if (this.isAdmin) {
            this.userOwnerOfQuotation = values[1];
          }
          return quotation;
        }));
      } else {
        return of(quotation);
      }
    }), map(t => {

      this.differenceTest = false;
      this.quotationService.setDifferenceStatus(false);
      unwatch(this.baseQuotation, _ => this.thereAreDifference());
      this.originalQuotation = cloneDeep(t);
      this.baseQuotation = t;

      this.canModify = this.baseQuotation.listState == 0;
      this.ownQuotation = this.isAdmin || this.baseQuotation.userId == claims.sub;
      this.isSending2 = false;
      [this.listTotalReserved, this.listTotalPublic, this.listMargin] = this.quotationService.calculateTotals();

      this.opportunityAllowed = this.isAdmin || (this.isAgent && this.baseQuotation.userId == claims.sub);
      let projectDiscount = this.quotationService.calculateProjectDiscount();
      if (this.baseQuotation.extraDiscount > 0 || projectDiscount > 0) {
        this.listTotalReserved = this.listTotalReserved - projectDiscount;
        this.listTotalPublic = this.listTotalPublic - ((this.listTotalPublic * this.baseQuotation.extraDiscount / 100));
        this.listMargin = (this.listTotalPublic - this.listTotalReserved) / this.listTotalPublic;
      }
      watch(this.baseQuotation, _ => this.thereAreDifference());
      return t;
    }));


    combineLatest([obsQuotation,
      this.catalogService.getShopMode(),
      this.storeService.translateService.stream(translationKeys)])
      .pipe(takeUntil(this.destroy$)).subscribe(([quotation, shopMode, translation]) => {
        this.translations = translation;
        this.shopMode = shopMode;
        this.tooltipQuote = "";
        this.tooltipDisabled = false;
        this.buttonOrderDisabled = true;
        if (quotation) {
          if (shopMode === 1) {
            this.tooltipQuote = this.translations['requireModeBuy'];
          } else if (quotation.agentQuotationNotValid) {
            this.tooltipQuote = this.translations['agentCanNotCheckOut'];
          } else if (quotation.invalid) {
            this.tooltipQuote = this.translations['articleNotValidInCart'];
          } else if (quotation.idListino != 2 && quotation.assignedCodCliFor != this.codCliUser) {
            this.tooltipQuote = this.translations['onlyCustomerB2bCanPerformTheOrder'];
            if (!quotation.shareWithCompany) {
              this.tooltipQuote = this.translations['shareWithCompany'] + " " + this.tooltipQuote;
            }
          } else {

            if (quotation.idListino == 2 && quotation.assignedCodCliFor != this.codCliUser) {
              this.tooltipQuote = this.translations['pricesAppliedWillBeSelling'];
            } else {
              this.tooltipDisabled = true;
            }
            this.buttonOrderDisabled = !(quotation.items.length > 0);
          }
        }

      });

    this.searchTerms$.pipe(switchMap((term: string) => {
      return this.quotationService.searchImpersonation(term);
    }), takeUntil(this.destroy$)).subscribe(data => {
      this.searchResults = data;
    });

    this.quotationService.checkoutActionObs.pipe(takeUntil(this.destroy$)).subscribe(() => {

      var isLastRevision = true;

      if (this.baseQuotation.revisions && this.baseQuotation.revisions.length > 0) {
        const maxRev = this.baseQuotation.revisions.reduce(
          (max, revision) => (revision.rev > max ? revision.rev : max),
          this.baseQuotation.revisions[0].rev
        );
        isLastRevision = maxRev == this.baseQuotation.currentRevision;
      }
      if (isLastRevision) {
        this.performCheckOut();
      } else {
        this.confirmationService.confirm({
          key: 'confirmNotLastRevision',
          header: this.translations.warningAllUpper,
          message: this.translations.notLastRevision,
          acceptLabel: this.translations.proceed,
          rejectLabel: this.translations.cancel,
          accept: () => {
            this.performCheckOut();
          }
        });
      }

    });
  }

  ngOnDestroy(): void {
    unwatch(this.baseQuotation, _ => this.thereAreDifference());
    this.quotationService.setDifferenceStatus(false);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    this.takeUserData$.unsubscribe();
  }

  checkout(_) {

    this.quotationService.performCheckout();

  }

  archive(listId: string) {
    if (confirm(this.translations.archiveQuotation))
      this.listService.archiveUserQuotation(listId).subscribe(x => this.router.navigateByUrl(this.storeService.createUrl("/quotations/listQuotations") as string));
  }

  duplicateQuotation() {
    this.listService.duplicateQuotation(this.baseQuotation.id).subscribe(q => {
      this.listService.setCurrentList(q);
      this.router.navigate(this.storeService.createUrl(["quotations", q.id]) as any[]);
    });
  }

  editTitle(start) {
    if (start == true) {
      this.newTitle = clone(this.baseQuotation.name);
      this.editTitleMode = true;
    } else {
      this.newTitle = null;
      this.editTitleMode = false;
    }
  }

  updateTitle() {
    this.baseQuotation.name = this.newTitle;
    this.newTitle = null;
    this.editTitleMode = false;
  }

  //se ritorna true _IGNORA_
  private differenceTester(path, key): boolean {
    if (key === "name") {
      return path.includes("items") && !path.includes("custom");
    }

    if (["categoryName", "prezzoVendita", "prezzoRiservato", "unitaMisura", "file", "imgUrl", "fileName"].includes(key)) {

      return !path.includes("custom");
    }
    return ['invalid', 'image', 'prezzo', 'availability', 'sku', 'colors', 'prezzoVendita', 'prezzoRiservato', 'idBrand',
      'assignedCodCliFor', 'productModel', 'anthology', 'anthologyStar', 'esWarning', 'mxStar', 'searchedPublicCode',
      'um', 'projectDiscount', 'description', 'hoverImage'].includes(key);
  }

  thereAreDifference(): boolean {
    this.changes = diff(this.originalQuotation, this.baseQuotation, this.differenceTester);
    this.differenceTest = this.changes !== undefined;

    this.quotationService.setDifferenceStatus(this.differenceTest);

    return this.differenceTest;
  }

  cancel() {
    this.isSending2 = true;
    this.quotationService.reloadQuotationDetail();
  }

  saveQuotation() {
    if (this.baseQuotation.shareWithCompany === true) {
      this.confirmationService.confirm({
        key: 'confirmModifyQuotationShared',
        header: this.translations.warningAllUpper,
        message: this.translations.confirmSharedQuotationChange,
        acceptLabel: this.translations.proceed,
        rejectLabel: this.translations.cancel,
        accept: () => {
          this.doSave()
        }
      });
    } else {
      this.doSave()
    }
  }

  private doSave() {
    this.isSending = true;
    if (this.baseQuotation.statusProject && (this.baseQuotation.statusProject == 1 || this.baseQuotation.statusProject == 2)) {
      let totaleRiservato = this.quotationService.calculateTotals()[0];
      if (totaleRiservato < 2500) {
        this.confirmationService.confirm({
          key: 'confirmProjectAutoCancel',
          header: this.translations.warningAllUpper,
          message: this.translations.projectRegistrationAutoCancel,
          acceptLabel: this.translations.proceed,
          rejectLabel: this.translations.cancel,
          accept: () => {

            this.baseQuotation.projectDiscount = undefined;
            this.baseQuotation.projectDate = undefined;

            combineLatest([this.quotationService.unRegisterProjectNoSideEffect(this.baseQuotation), this.quotationService.updateQuotation()])
              .subscribe({
                next: () => {
                  this.isSending = false;
                },
                error: _err => {

                  this.isSending = false;
                }
              });
          },
          reject: () => {
            this.isSending = false;
            return;
          }
        });
      } else {
        this.quotationService.updateQuotation().subscribe(() => {
          this.isSending = false;
        }, _err => {

          this.isSending = false;
        });
      }
    } else {
      this.quotationService.updateQuotation().subscribe(() => {

        this.isSending = false;
      }, _err => {

        this.isSending = false;
      });
    }
  }

  searchCompanies(event) {
    this.searchTerms$.next(event.query);
  }

  onSelect(event) {
    this.codCliFor = event.value.codCliFor;
  }

  setQuotationCompany() {
    if (!this.codCliFor)
      return;
    if (this.baseQuotation.assignedCodCliFor == this.codCliFor) {
      return;
    }
    this.baseQuotation.assignedCodCliFor = this.codCliFor;

    this.companyLoading = true;

    this.quotationService.updateQuotation().subscribe(() => {
      this.companyLoading = false;
      this.quotationService.reloadQuotationDetail();
    });
  }

  addRevision() {
    this.addingRevision = true;
    this.quotationService.addRevision(this.baseQuotation.id).subscribe(t => {
      this.addingRevision = false;
    }, err => {
      alert(this.translations['data']);
      this.addingRevision = false;
      alert

    });
  }

  deleteRevision(rev: number) {
    this.deletingRevision = true;
    this.quotationService.deleteRevision(this.baseQuotation.id, rev).subscribe(t => {
      this.deletingRevision = false;
    });
  }

  setRevision(rev: number) {
    this.settingRevision = true;
    this.quotationService.setRevision(this.baseQuotation.id, rev).subscribe(t => {
      this.settingRevision = false;
    });
  }

  printBrochureQuotation() {
    this.pdfService.printBrochureQuotation(this.baseQuotation.id).subscribe((data) => {

      if (data.size > 0) {
        this.fileSaverService.save(data, `BrochurePreventivo.pdf`);
      } else {
        alert(this.translations['errorCreatingPdf']);
      }
    }, () => {
      alert(this.translations['errorCreatingPdf']);
    });
  }

  forceQuotationToUseOldListino() {
    this.confirmationService.confirm({
      key: 'confirmModifyQuotationShared',
      header: this.translations.warningAllUpper,
      message: this.translations.areYouSureQuotationUseOldListino,
      acceptLabel: this.translations.proceed,
      rejectLabel: this.translations.cancel,
      accept: () => {
        this.quotationService.forceQuotationToUseOldListino(this.baseQuotation.id).subscribe(() => {
          this.quotationService.reloadQuotationDetail();
        });
      }
    });

  }

  MySelectCompanyConvertion(company: any): string {
    if (!company) return "";
    return company.ragSoc + ' (' + company.codCliFor + ')';
  }

  private performCheckOut() {
    this.userPreferenceService._userDetailObs.pipe(takeUntil(this.takeUserData$)).subscribe(userData => {
      if (!userData) {
        return;
      }
      this.takeUserData$.next(true);

      if (userData.checkOutMode == 1) {
        this.router.navigate(this.storeService.createUrl(["quotations", this.baseQuotation.id, "checkout-plain"]) as any[]);
        return;
      }
      this.router.navigate(this.storeService.createUrl(["quotations", this.baseQuotation.id, "checkout"]) as any[]);
    });
  }

}
