import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserService } from '../../orders/services/user.service';
import { mergeMap, shareReplay, take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StoreService } from '../store.service';
import { environment } from '../../environments/environment';
import { SharedService } from '../shared.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { HttpEventType } from '@angular/common/http';
import { CatalogService } from '../catalog.service';
import { AuthenticationService } from '../services/authentication.service';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  slideShowHeight: string;
  companies$: Observable<any[]>
  agents$: Observable<any[]>
  user: any;
  currentCliente: any
  currentMacrolux: any
  level: 'company' | 'agent' | 'admin' = 'company'
  currentAgente: any;

  translations: any = {};

  downloadingListino: boolean;
  percentDownloadListino: number;

  downloadingListinoPdf: boolean;
  percentDownload: number;

  catalogoUrl = environment.urlBlob + "catalog/Catalogo.zip"
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private userService: UserService, private catalogService: CatalogService,
    private storeService: StoreService, private sharedService: SharedService, private fileSaverService: FileSaverService,
    private authenticationService: AuthenticationService, private router: Router) {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event?) {
    this.slideShowHeight = (window.innerWidth / 3.85) + "px";
  }

  imageData: any[];

  ngOnInit(): void {

    this.sharedService.getPicturesToShow().subscribe((imagesData) => {
      this.imageData = [];
      for (let image of imagesData) {
        let imageUrl = {
          url: image.image,
          openUrl: image.clickUrl
        }
        this.imageData.push(imageUrl);
      }
    });

    let translationRequired = marker(['catalogName', 'errorCreatingListino', 'priceListDocument', 'priceListLink']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.user = this.authenticationService.getUserProfile();
    if (this.user && this.user.role) {
      if (this.user.role.includes('Super Admin')) {
        this.level = 'admin'
      } else if (this.user.role.includes('Agent')) {
        this.level = 'agent'
      } else if (this.user.role.includes('Company')) {
        this.level = 'company'
      }
    }

    this.currentCliente = this.level == 'company' ? { codCli: this.user.codCli, ragSoc: this.user.ragSoc } : null
    this.currentMacrolux = this.currentCliente;

    if (this.level == 'agent') {
      const c1 = this.user.family_name;
      const c2 = this.user.given_name;
      this.currentAgente = {
        uid: this.user.sub,
        name: c1 ? `${c1} ${c2}` : this.user.email
      };
    }

    this.companies$ = this.userService.getCompaniesForAgent(this.user.sub).pipe(
      shareReplay(1)
    );
    if (this.level == 'admin') {
      this.agents$ = this.userService.getAgents();
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  openUrlBlank(url: string) {
    window.open(url, "_blank");
  }

  onSelectedCliente(cliente: any) {
    this.currentMacrolux = cliente
  }

  onSelectedMacrolux(cliente: any) {
    this.currentCliente = cliente
  }

  downloadListinoExcel() {
    if (this.downloadingListino) return;

    this.downloadingListino = true;
    this.percentDownloadListino = 0;

    this.catalogService.getCurrentBrandId().pipe(take(1), mergeMap((idBrand) => {
      return this.sharedService.getListino(idBrand);
    })).subscribe({
      next: (result) => {
        this.downloadingListino = false;
        if (result.size) {
          this.fileSaverService.save(result, `${this.translations['priceListDocument']}.xlsx`);
        } else {
          alert(this.translations["errorCreatingListino"]);
        }
      },
      error: (_error: any) => {
        this.downloadingListinoPdf = false;
        alert(this.translations["errorCreatingListino"]);
      }
    });
  }


  downloadListinoPdf() {
    if (this.downloadingListinoPdf) return;
    this.downloadingListinoPdf = true;
    this.percentDownload = 0;

    this.catalogService.getCurrentBrandId().pipe(take(1), mergeMap((idBrand) => {
      return this.sharedService.downloadCatalogPdfUser(idBrand)
    })).subscribe({
      next: (result) => {
        if (result.type === HttpEventType.DownloadProgress) {
          this.percentDownload = Math.round(100 * result.loaded / result.total);
        }
        if (result.type === HttpEventType.Response) {
          this.downloadingListinoPdf = false;
          if (result.body && result.body.size) {
            this.fileSaverService.save(result, `${this.translations['catalogName']}.xlsx`);
          } else {
            alert('unable to download');
          }
        }
      }
      , error: (_error: any) => {
        this.downloadingListinoPdf = false;
        alert('unable to download');
      }
    });
  }

  goToBrand(idBrand: string) {
    this.catalogService.populateAllCache(idBrand).subscribe(() => {
      this.router.navigate(this.storeService.createUrl(['/catalog']) as any[]);
    });
  }

  openImage(urlImage: string) {
    window.open(urlImage, "_self");
  }
}
