<div class="column" >
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="columns">
        <div class="column is-narrow">
          <figure class="image is-128x128 draggable" dndHandle hoverIntent (onHoverIntent)="item.hoverImage = true" (mouseleave)="item.hoverImage = false">
            <img fallback-src="https://via.placeholder.com/128x128" query="width=128&height=128&mode=crop" *ngIf="item.type == 0" [cdnImg]="item.image"
                 [productId]="item.productModel">
            <img fallback-src="https://via.placeholder.com/128x128" query="width=128&height=128&mode=crop" *ngIf="item.type == 1" [cdnImg]="item.image" type="accessory">
          </figure>
        </div>
        <div class="column">
          <h2 class="title is-5">
            <span>{{item.idBrand | brandName | async }}&nbsp;</span>
            <span *ngIf="item.name" [ngClass]="{'has-background-success': item.searchedPublicCode}">{{item.name}}</span>
            <span *ngIf="item.esWarning">&nbsp;
              <i *ngIf="item.esWarning" class="fas fa-fw fa-exclamation-triangle has-text-warning" 
              [pTooltip]="translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
            </span>
            <span class="is-size-6">&nbsp;{{item.categoryName}}&nbsp;</span>
          </h2>
          <h2 *ngIf="!item.name" class="title is-5">
            <span [translate]="'accessoryProductNoLongerAvailable'"></span>
            <i *ngIf="item.esWarning" class="fas fa-fw fa-exclamation-triangle has-text-warning" [pTooltip]="translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
          </h2>
          <h3 class="subtitle is-6 buttons">
            <span>
              {{item.sku}}
              <span *ngIf="item.anthology || item.vms == true" class="has-text-uppercase is-size-7 has-text-right has-text-info has-text-weight-semibold">
                <span style="margin-right: 0">Anthology</span>
                <span *ngIf="item.anthologyStar" class="has-text-warning has-text-weight-bold">Star</span>
                <span *ngIf="item.vms" class="has-text-warning has-text-weight-bold">VMS</span>
              </span>
              <i class="fas fa-info-circle fa-sm" [pTooltip]="item.description"></i>
            </span>
            <a (click)="setPopperItem(item);popperListContent.toggle($event)" class="has-text-weight-semibold">
              <span [translate]="'modify'">Modifica</span>&nbsp;<i class="fas fa-angle-down"></i>
            </a>
            <a *ngIf="item.type == 0 && item.productModel" (click)="goToProduct()" class="has-text-weight-semibold">
              <span [translate]="'goToProduct'">Vai al prodotto</span>
            </a>
          </h3>
          <div class="columns info">
            <div class="column is-narrow">
              <span class="is-size-7"><span [translate]="'quantity'">Quantità</span> ({{item.um | lowercase }})</span><br />
              <p-inputNumber [showButtons]="true" [size]="2" [(ngModel)]="item.quantity" [min]="1" (ngModelChange)="updatePrezzoFinale()"></p-inputNumber>
            </div>
            <div class="column is-narrow">
              <span class="is-size-7" [translate]="'available'">Disponibili</span><br />
              <span class="is-size-5 has-text-weight-semibold" [class.has-text-info]="item.availability >= item.quantity" [class.has-text-warning]="item.availability < item.quantity">{{item.availability}}</span>
            </div>
            <div class="column is-narrow">
              <span class="is-size-7" [translate]="'listPrice'">Prezzo di listino</span><br />
              <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzo" [class.is-line-through]="shopMode == 0">{{item.prezzo | currency:'EUR'}}</span>
              <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzo">N/A</span>
            </div>
            <div *ngIf="shopMode == 0" class="column is-narrow">
              <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span><br />
              <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzoRiservato">{{item.prezzoRiservato | currency:'EUR'}}</span>
              <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzoRiservato">N/A</span>
            </div>
          </div>
          <div *ngIf="item.childItems?.length > 0">
            <a (click)="toggleAccessories()" class="has-text-weight-semibold">
              <i class="fas fa-angle-down"></i>&nbsp;&nbsp;{{item.childItems.length}} <span [translate]="'accessoryAdded'">accessori aggiuntivi</span>
            </a>
          </div>
        </div>
        <div class="column is-2 price">
          <div class="has-text-right">
            <div class="title is-size-5 has-text-weight-semibold" *ngIf="prezzoFinale">{{prezzoFinale | currency:'EUR'}}</div>
            <div class="title is-size-5 has-text-weight-semibold" *ngIf="!prezzoFinale">N/A</div>
            <ng-container *ngIf="item.childItems?.length > 0">
              <div class="subtitle is-size-7" [translate]="'accessoriesTotal'">di cui accessori:</div>
              <div class="title is-size-6 has-text-weight-semibold" *ngIf="accessoryTotal">{{accessoryTotal | currency:'EUR'}}</div>
              <div class="title is-size-6 has-text-weight-semibold" *ngIf="!accessoryTotal">N/A</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-12 child-item" *ngIf="item.childItems?.length > 0 && showAccessories">
      <div class="columns" *ngFor="let acc of item.childItems">
        <div class="column is-narrow">
          <div style="width:128px"></div>
        </div>
        <div class="column is-narrow">
          <figure class="image is-64x64">
            <img fallback-src="https://via.placeholder.com/128x128" [cdnImg]="acc.image" query="width=128&height=128&mode=crop" type="accessory">
          </figure>
        </div>
        <div class="column">
          <h4 *ngIf="acc.name" class="subtitle has-text-weight-semibold is-size-6">
            {{acc.name}} <span class="is-size-7">{{acc.sku}}</span>
          </h4>
          <h4 *ngIf="!acc.name" class="subtitle has-text-weight-semibold is-size-6">
            <span [translate]="'accessoryProductNoLongerAvailable'"></span>
            <i *ngIf="acc.esWarning" class="fas fa-fw fa-exclamation-triangle has-text-warning" [pTooltip]="translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
          </h4>
          <div class="columns info is-vcentered">
            <div class="column is-narrow">
              <span class="is-size-7"><span [translate]="'quantity'">Quantità</span> ({{acc.um | lowercase }})</span>&nbsp;&nbsp;
              <p-inputNumber [showButtons]="true" [size]="2" [(ngModel)]="acc.quantity" [min]="1" (ngModelChange)="updatePrezzoFinale()"></p-inputNumber>
            </div>
            <div class="column is-narrow">
              <span class="is-size-7" [translate]="'available'">Disponibili</span>&nbsp;&nbsp;
              <span class="is-size-6 has-text-weight-semibold" [class.has-text-info]="acc.availability >= acc.quantity" [class.has-text-warning]="acc.availability < acc.quantity">{{acc.availability}}</span>
            </div>
            <div class="column is-narrow">
              <span class="is-size-7" [translate]="'listPrice'">Prezzo di listino</span>&nbsp;&nbsp;
              <span class="is-size-6 has-text-weight-semibold" *ngIf="acc.prezzo" [class.is-line-through]="shopMode == 0">{{acc.prezzo | currency:'EUR'}}</span>
              <span class="is-size-6 has-text-weight-semibold" *ngIf="!acc.prezzo">N/A</span>
            </div>
            <div *ngIf="shopMode == 0" class="column is-narrow">
              <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span>&nbsp;&nbsp;
              <span class="is-size-6 has-text-weight-semibold" *ngIf="acc.prezzoRiservato">{{acc.prezzoRiservato | currency:'EUR'}}</span>
              <span class="is-size-6 has-text-weight-semibold" *ngIf="!acc.prezzoRiservato">N/A</span>
            </div>
            <div class="column is-narrow">
              <a class="is-size-7 has-text-danger has-text-weight-semibold" (click)="deleteChildItem(acc)" [translate]="'delete'">
                Elimina
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-overlayPanel #popperListContent>
  <ng-container *ngIf="popperItem?.type==0 && popperItem?.name && popperItem?.tmp !== true">
    <a (click)="gotoConfiguration(popperItem)" class="has-text-dark" [translate]="'configuration'" >Configurazione</a><br />
  </ng-container>
  <ng-container *ngIf="popperItem?.tmp === true">
    <span [translate]="'articleDuplicate'">Articolo duplicato</span><br />
  </ng-container>
  <a *ngIf="popperItem?.name" class="has-text-dark" (click)="clone(popperItem)" [translate]="'duplicateArticle'">Duplica a fine documento</a><br />
  <a (click)="removeItem(popperItem?.configurationId)" class="has-text-danger" [translate]="'delete'">Elimina</a>
</p-overlayPanel>
