<h3
  *ngIf="attributes?.length > 0"
  style="margin-top:1.5em" class="title is-5" [translate]="'configureTheProduct'">
</h3>
<div class="tabs is-boxed"
  *ngIf="product.configurable || stdFilterableAttributes?.length > 0 || filterableAttributes?.length > 0">
  <ul>
    <li [class.is-active]="stdView"><a (click)="setStdView(true)" *ngIf="stdFilterableAttributes?.length > 0"
        [translate]="'bareProfileL3000'"></a></li>
    <li [class.is-active]="!stdView" *ngIf="filterableAttributes?.length > 0">
      <a (click)="setStdView(false)">
        <span [translate]="'finishedDevice'">Apparecchio Finito</span>
      </a>
    </li>
    <li *ngIf="product.configurable && !editMode"><a [routerLink]="['product', product.id, 'wizard']  | localize"
        [translate]="'customConfig'"></a></li>
  </ul>
</div>
<div class="columns is-multiline">
  <div *ngFor="let attribute of attributes" class="column is-6 attributes"
    [hidden]="attribute.sysName == 'pieces' && attribute.values.length < 2">
    <div class="columns">
      <div class="column">
        <h5 class="is-uppercase" style="margin-bottom: 0.75em">{{attribute.name}}</h5>
        <div class="field" *ngFor="let value of attribute.values | orderBy; let i = index">
          <input class="is-checkradio is-info" id="{{attribute.sysName}}_{{i}}" [name]="attribute.sysName"
            (click)="selectAttributeValue($event, attribute, value)" type="radio"
            [checked]="attribute | isSelectedAttributeValue:value">
          <label for="{{attribute.sysName}}_{{i}}">{{attribute | attributeValue:value | async}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog [appendTo]="'body'" header="Attenzione" *ngIf="currentChoice" [(visible)]="needSelectionVisible"
  (onHide)="onNeedSelectionHide()" [draggable]="false" [resizable]="false" [modal]="true" [style]="{width: '800px'}">
  <p [translate]="'attributeNotCompatible'">L'attributo che hai selezionato non è
    compatibile con le scelte precedenti.</p>
  <p [translate]="'attributeNotCompatibleChoose'">Scegli qui sotto tra i modelli compatibili:</p>
  <hr>
  <div *ngFor="let variant of additionalChoices">
    <div>
      <a (click)="selectVariant(variant)">
        {{variant.sku}}
      </a>
    </div>
    <span *ngFor="let attr of variant.attributes | isSelectableAttribute | async; let last = last" class="is-size-7">
      <span>{{attr | attributeName | async}}: {{attr | attributeValue | async}}</span>
      <span *ngIf="!last">,&nbsp;</span>
    </span>
  </div>
</p-dialog>