<div class="item" *ngIf="item.type == 3; else stdItem">
  <div class="columns">
    <div class="column is-narrow">
      <figure class="image is-96x96" [class.overlay]="chartType != 2" (click)="toggleMoveToProd()">
        <img *ngIf="item.entity.image" fallback-src="https://via.placeholder.com/96x96"
             query="width=96&height=96" [cdnImg]="item.entity.image"
             [productId]="quotationId" type="custom">
        <img *ngIf="!item.entity.image" style="max-height: 100%; max-width: 100%; width:auto; margin:auto;"
             src="/images/custom_item.jpg">
      </figure>
     
    </div>
    <div class="column has-content-vertically-aligned">
      <div class="title is-size-5" *ngIf="item.entity.name;else no_name">{{item.entity.name}}</div>
      <div class="columns info">
        <div class="column is-narrow">
          <span class="is-size-7" [translate]="'quantity'">Quantità</span><br/>
          <span
            class="is-size-5 has-text-weight-semibold">{{item.quantity | number : '0.0-2'  }}</span>
        </div>
        <div class="column">
          <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span><br/>
          <span class="is-size-5 has-text-weight-semibold">{{prezzo | currency:'EUR'}}</span>
          <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.entity.prezzoRiservato">N/A</span>
        </div>
        <div class="column is-narrow has-text-right">
          <span class="is-size-7" [translate]="'subtotal'">Tot. parziale</span><br/>
          <span class="is-size-5 has-text-weight-semibold">{{prezzo * item.quantity | currency:'EUR'}}</span>
          <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.entity.prezzoRiservato">N/A</span>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #stdItem>
  <div class="item">
    <div class="columns">
      <div class="column is-narrow">
        <figure class="image is-96x96" [class.overlay]="chartType != 2" [class.accessory]="item.type == 1" (click)="toggleMoveToProd()">
          <img fallback-src="https://via.placeholder.com/96x96" query="width=96&height=96&mode=crop"
               *ngIf="item.type == 0" [cdnImg]="item.entity.images[0]"
               [productId]="item.entity.productModel">
          <img fallback-src="https://via.placeholder.com/96x96" query="width=96&height=96&mode=crop"
               *ngIf="item.type == 1" [cdnImg]="item.entity.image"
               type="accessory">
        </figure>
      </div>
      <div class="column has-content-vertically-aligned">
        <div class="is-size-5 has-text-weight-semibold">
          <span>{{item.entity.idBrand | brandName | async}}&nbsp;</span>
        </div>
        <div class="title is-5" >
          <span *ngIf="item.entity.esolverName;else no_name">{{item.entity.esolverName}}</span>
        </div>
        <div class="subtitle is-6">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                {{item.sku}}
                <span *ngIf="(item.entity | isAnthology) || item.entity.vms== true"
                      class="has-text-uppercase is-size-7 has-text-right has-text-info has-text-weight-semibold">
                  &nbsp;<span>Anthology</span>
                  <span *ngIf="item.entity.anthologyStar" class="has-text-warning has-text-weight-bold">Star</span>
                  <span *ngIf="item.entity.vms" class="has-text-warning has-text-weight-bold">VMS</span>
                 </span>
                &nbsp;<i class="fas fa-info-circle fa-sm" *ngIf="item.entity.esolverDescription"
                         [pTooltip]="item.entity.esolverDescription" tooltipStyleClass="m-tooltip"></i>
              </div>
            </div>
            <div class="right" *ngIf="restoration$ | async as restoration" pTooltip="{{'restorationEstimatedFrom' | translate}}" tooltipPosition="top" tooltipStyleClass="m-tooltip">
              <i class="fas fa-history fa-lg fa-fw"></i>
              <span style="margin-left: 0.2em;" class="is-size-6">{{restoration.dataTermine | date : undefined : undefined : locale }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="item.configForceToReserve" class="is-size-7 has-text-white has-background-info" style="border-radius: 3px; padding: 3px 5px"
             [translate]="'configurableAnthologyNotAvailable'" [translateParams]="{previonalDate: (dateProvisional | date : 'EEEE, d MMMM' : undefined : locale)}"></div>
        <div class="columns info">
          <div class="column is-narrow">
            <span class="is-size-7" [translate]="'quantity'">Quantità</span><br/>
            <span
              class="is-size-5 has-text-weight-semibold">{{item.quantity | number : '0.0-2'  }} {{item.entity.um | lowercase}}</span>
          </div>
          <div class="column">
            <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span><br/>
            <span class="is-size-5 has-text-weight-semibold"
                  *ngIf="item.entity.prezzoRiservato">{{prezzo | currency:'EUR'}}</span>
            <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.entity.prezzoRiservato">N/A</span>
          </div>
          <div class="column is-narrow has-text-right">
            <span class="is-size-7" [translate]="'subtotal'">Tot. parziale</span><br/>
            <span class="is-size-5 has-text-weight-semibold"
                  *ngIf="item.entity.prezzoRiservato">{{prezzo * item.quantity | currency:'EUR'}}</span>
            <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.entity.prezzo">N/A</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #no_value>
  <div class="title is-size-4">N/A</div>
</ng-template>
<ng-template #no_name>
  <span [translate]="'esolverArticleNotFound'">Esolver - Art. non
    trovato
  </span>
</ng-template>
