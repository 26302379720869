import { Component, OnInit } from '@angular/core';
import { CatalogService } from "../catalog.service";
import { Observable } from "rxjs";
import { StoreService } from '../store.service';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    
  // urlToOpen: string;
  // showModal: boolean;

  isViewer: boolean = false;
  categories$: Observable<any[]>;
  shopMode$: Observable<number>;

  constructor(private catalogService: CatalogService, private authService: AuthenticationService,
    private storeService: StoreService, private router: Router) { }

  ngOnInit() {
    this.shopMode$ = this.catalogService.getShopMode();
    this.categories$ = this.catalogService.getCategories();
    const claims = this.authService.getUserProfile();
    this.isViewer = claims.view_only && claims.view_only == "true"
  }

  setLangAndRefresh(store: string): void {
    if (this.storeService.getCurrentStore() == store) return;

    this.storeService.setStoreIfExist(store);

   
    let newUrl = this.storeService.createUrl(this.router.url) as string;
    this.router.navigateByUrl(newUrl);
  }

  // openIframe(url: string) {
  //   this.urlToOpen = url;
  //   this.showModal = true;
  // }

  // closeIFrame() {
  //   this.showModal = false;
  //   this.urlToOpen = undefined;
  // }
}
