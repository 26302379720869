<div class="columns">
  <div class="column">
    <ng-container *ngIf="!opportunity">
      <div class="tabs is-right is-toggle">
        <ul>
          <li [class.is-active]="createMode === 0">
            <a (click)="createMode = 0">
              <span [translate]="'createNew'">Crea nuova</span>
            </a>
          </li>
          <li [class.is-active]="createMode === 1">
            <a (click)="createMode = 1">
              <span [translate]="'connectExisting'">Collega esistente</span>
            </a>
          </li>
        </ul>
      </div>

    </ng-container>
    <form (ngSubmit)="save()" #f="ngForm" *ngIf="createMode === 0">
      <h2 class="title is-4" *ngIf="!opportunity" [translate]="'createOpportunity'">Crea opportunità</h2>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label"
            [class.has-text-danger]="f.form.controls.clientId?.invalid && f.form.controls.clientId?.touched"
            [translate]="'nominatoName'">Account</label>
        </div>
        <div class="field-body">
          <div class="field is-expanded">
            <div class="field">
              <div class="control is-expanded"
                [class.has-icons-right]="f.form.controls.clientId?.invalid && f.form.controls.clientId?.touched">
                <p-autoComplete [(ngModel)]="editModel.client" [suggestions]="resultsClients"
                  [placeholder]="'searchByName' | translate" minLength="2" forceSelection="true"
                  (onSelect)="selectClient($event)" required [disabled]="baseQuotation?.listState != 0" optionLabel="name"
                  (completeMethod)="searchClient($event)" name="clientId" emptyMessage="{{'noMatch' | translate}}"
                  [inputStyleClass]="f.form.controls.clientId?.invalid && f.form.controls.clientId?.touched ? 'input is-danger' : 'input'"
                  class="control ui-fluid is-expanded">
                  <ng-template let-item pTemplate="item">
                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item is-size-7">
                          <span class="tag is-uppercase" [class.is-warning]="!item.prescrittore__c">
                            {{item.prescrittore__c ? 'P' : 'C'}}
                          </span>
                        </div>
                        <div class="level-item">
                          {{item.name}}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <span class="icon is-small is-right"
                  *ngIf="!(editModel.id > 0) && f.form.controls.clientId?.invalid && f.form.controls.clientId?.touched">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label" [class.has-text-danger]="name.invalid && name.touched"
            [translate]="'title'">Titolo</label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <div class="control is-expanded" [class.has-icons-right]="name.invalid && name.touched">
              <input class="input" [class.is-danger]="name.invalid && name.touched" type="text"
                [(ngModel)]="editModel.name" name="name" #name="ngModel" required
                [disabled]="baseQuotation?.listState != 0">
              <span class="icon is-small is-right" *ngIf="name.invalid && name.touched">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label" [translate]="'step'">Fase</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control is-expanded">
              <span class="select is-fullwidth" [class.is-warning]="editModel.stageName == 'Preventivo'"
                [class.is-success]="editModel.stageName == 'Chiusa Vinta'"
                [class.is-danger]="editModel.stageName == 'Chiusa Persa'">
                <select name="status" [(ngModel)]="editModel.stageName" [disabled]="baseQuotation?.listState != 0"
                  (change)="onStatusChange($event)">
                  <option [ngValue]="'Preventivo'" [translate]="'propose'">Preventivo</option>
                  <option [ngValue]="'Chiusa Vinta'" [translate]="'winner'">Vinta</option>
                  <option [ngValue]="'Chiusa Persa'" [translate]="'lost'">Persa</option>
                </select>
              </span>
            </div>
          </div>
          <div class="field">
            <div class="control is-expanded">
              <span class="select is-fullwidth">
                <select name="reason" [(ngModel)]="editModel.descrizione_motivo_chiusa_persa__c"
                  [disabled]="baseQuotation?.listState != 0 || editModel.stageName != 'Chiusa Persa'">
                  <option [ngValue]="null" [translate]="'reasonLoss'">Motivo della perdita</option>
                  <option [ngValue]="'Vinta da un competitor'" [translate]="'winnerByCompetitor'">Vinta da un competitor
                  </option>
                  <option [ngValue]="'Budget insufficiente'" [translate]="'notEnoughBudget'">Budget insufficiente
                  </option>
                  <option [ngValue]="'Nessuna decisione'" [translate]="'noDecision'">Nessuna decisione</option>
                  <option [ngValue]="'Prezzo'" [translate]="'price'">Prezzo</option>
                  <option [ngValue]="'Altro'" [translate]="'other'">Altro</option>
                </select>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" [translate]="'purchaseValue'">Valore d'acquisto</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="field has-addons is-expanded">
              <div class="control is-expanded">
                <input class="input" type="text" [(ngModel)]="editModel.budget" readonly disabled name="budget"
                  #budget="ngModel" [required]="true" pKeyFilter="money">
              </div>
              <div class="control">
                <a class="button is-static">
                  EUR
                </a>
              </div>
              <div class="control" *ngIf="isAdmin">
                <div class="sell-buy-switch is-inline-flex">
                  <button class="button" type="button" [class.is-warning]="mode == 1" (click)="setBudgetValue(1)" [translate]="'buy'">
                    Acquista
                  </button>
                  <button class="button" type="button" [class.is-info]="mode != 1" (click)="setBudgetValue(2)" [translate]="'sell'">
                    Vendi
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" [class.has-text-danger]="f.form.controls.date?.invalid && f.form.controls.date?.touched"
            [translate]="'dueDate'">
            Data prevista
          </label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control ui-fluid"
              [class.has-icons-right]="f.form.controls.date?.invalid && f.form.controls.date?.touched">
              <p-calendar [(ngModel)]="editModel.closeDate" [required]="true" name="date" dateFormat="dd/mm/yy" [firstDayOfWeek]="1"
                appendTo="body" #date="ngModel" [disabled]="baseQuotation?.listState != 0"
                [inputStyleClass]="f.form.controls.date?.invalid && f.form.controls.date?.touched ? 'input is-danger' : 'input'">
              </p-calendar>
              <span class="icon is-small is-right"
                *ngIf="f.form.controls.date?.invalid && f.form.controls.date?.touched">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" [translate]="'description'">Descrizione</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control">
              <textarea class="textarea" [(ngModel)]="editModel.description" name="description"
                [disabled]="baseQuotation?.listState != 0"></textarea>
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div class="content has-text-right is-clearfix" #footerRef>
        <!-- field is-grouped per poter usare anche il tooltip con button disabled-->
        <div class="field is-grouped is-grouped-right">
          <div class="control" *ngIf="baseQuotation?.listState == 0"
            pTooltip="{{'cannotDisconnectAConfirmedOpportunity' | translate}}" tooltipPosition="top"
            tooltipStyleClass="m-tooltip">
            <button type="button" class="button is-danger" (click)="unlink()" *ngIf="editModel.id"
              [disabled]="editModel.stageName != 'Preventivo'" [translate]="'disconnect'">Scollega</button>
          </div>
          <div class="control">
            <button type="submit" class="button is-primary" [class.is-loading]="creatingOpportunity" [disabled]="baseQuotation?.listState != 0">
              <span *ngIf="!editModel.id" [translate]="'createAndConnect'">Crea e collega</span>
            </button>
          </div>
        </div>
      </div>
    </form>
    <ng-container *ngIf="createMode === 1">
      <h2 class="title is-4" [translate]="'connectQuotation'">Collega preventivo</h2>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" [translate]="'opportunity'">Opportunità</label>
        </div>
        <div class="field-body">
          <div class="field is-expanded">
            <div class="field has-addons">
              <div class="control is-expanded">
                <p-autoComplete [(ngModel)]="existingOpportunity" [suggestions]="agentOpportunities"
                  [placeholder]="'searchByName' | translate" minLength="2" forceSelection="true"
                  emptyMessage="{{'noMatch' | translate}}" [disabled]="baseQuotation?.listState != 0" optionLabel="name"
                  (completeMethod)="searchOpportunity($event)" [inputStyleClass]="'input'"
                  class="control ui-fluid is-expanded">
                  <ng-template let-item pTemplate="item">
                    <div class="columns">
                      <div class="column">
                        <p>{{item.name}}</p>
                      </div>
                      <div class="column">
                        <p>{{item.amount | currency:'EUR'}}</p>
                      </div>
                    </div>
                  </ng-template>
                </p-autoComplete>
              </div>
              <div class="control">
                <button class="button is-info" [disabled]="!existingOpportunity" (click)="assignOpportunity()"
                  [translate]="'connect'">
                  Collega
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="is-divider is-medium" [attr.data-content]="'or' | translate" [attr.id]="'or'"></div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" [translate]="'nominatoName'">Account</label>
        </div>
        <div class="field-body">
          <div class="field is-expanded">
            <div class="field">
              <div class="control is-expanded">
                <p-autoComplete [(ngModel)]="editModel.client" [suggestions]="resultsClients"
                  [placeholder]="'searchByName' | translate" minLength="2" forceSelection="true"
                  (onSelect)="selectDealer($event)" required [disabled]="baseQuotation?.listState != 0" optionLabel="name"
                  (completeMethod)="searchClient($event)" name="clientId" emptyMessage="{{'noMatch' | translate}}"
                  [inputStyleClass]="'input'" class="control ui-fluid is-expanded">
                  <ng-template let-item pTemplate="item">
                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item is-size-7">
                          <span class="tag is-uppercase" [class.is-warning]="!item.prescrittore__c">
                            {{item.prescrittore__c ? 'P' : 'C'}}
                          </span>
                        </div>
                        <div class="level-item">
                          {{item.name}}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-autoComplete>
              </div>
            </div>
          </div>
        </div>
      </div>
      <table class="table is-fullwidth is-striped">
        <tr *ngFor="let item of opportunitiesDealer$ | async">
          <td>
            <p>{{item.name}}</p>
          </td>
          <td>
            <p>{{item.amount | currency:'EUR'}}</p>
          </td>
          <td style="width: 7.5em">
            <button class="button is-info" (click)="assignOpportunityFromDealer(item)" [translate]="'connect'">
              Collega
            </button>
          </td>
        </tr>
      </table>
      <div class="has-text-centered" *ngIf="showNoopportunitesForThisDealer"
        [translate]="'dealerHasNoAvailableOpportunities'">

      </div>
    </ng-container>
    <div *ngIf="createMode === 2">
      <h2 class="title is-4" [translate]="'viewExisting'">Vedi esistente</h2>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" [translate]="'nominatoName'">Account</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control fix-align">
              {{opportunity.account?.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" [translate]="'title'">Titolo</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control fix-align">
              {{opportunity.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" [translate]="'purchaseValue'">Valore d'acquisto</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="field">
              <div class="control fix-align">
                {{opportunity.amount | currency:'EUR' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" [translate]="'dueDate'">Data prevista</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="field">
              <div class="control fix-align">
                {{opportunity.dateParsed | date:'mediumDate' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label" [translate]="'step'">Fase</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control is-expanded">
              <span class="select is-fullwidth" [class.is-warning]="opportunity.stageName == 'Preventivo'"
                [class.is-success]="opportunity.stageName == 'Chiusa Vinta'"
                [class.is-danger]="opportunity.stageName == 'Chiusa Persa'">
                <select name="status" [(ngModel)]="opportunity.stageName" disabled>
                  <option [ngValue]="'Preventivo'" [translate]="'propose'">Preventivo</option>
                  <option [ngValue]="'Chiusa Vinta'" [translate]="'winner'">Vinta</option>
                  <option [ngValue]="'Chiusa Persa'" [translate]="'lost'">Persa</option>
                </select>
              </span>
            </div>
          </div>
          <div class="field" *ngIf="opportunity.stageName == 'Chiusa Persa'">
            <div class="control is-expanded">
              <span class="select is-fullwidth">
                <select name="reason" [ngModel]="opportunity.descrizione_motivo_chiusa_persa__c" disabled>
                  <option [ngValue]="null" [translate]="'reasonLoss'">Motivo della perdita</option>
                  <option [ngValue]="'Vinta da un competitor'" [translate]="'winnerByCompetitor'">Vinta da un competitor
                  </option>
                  <option [ngValue]="'Budget insufficiente'" [translate]="'notEnoughBudget'">Budget insufficiente
                  </option>
                  <option [ngValue]="'Nessuna decisione'" [translate]="'noDecision'">Nessuna decisione</option>
                  <option [ngValue]="'Prezzo'" [translate]="'price'">Prezzo</option>
                  <option [ngValue]="'Altro'" [translate]="'other'">Altro</option>
                </select>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" [translate]="'opportunityUrl'">Opportunità Url</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control fix-align">
              <a href="{{opportunity.attributes.url}}" target="_blank" [translate]="'goToOpportunity'"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" [translate]="'description'">Descrizione</label>
        </div>
        <div class="field-body">
          <div class="field">
            {{opportunity.description}}
          </div>
        </div>
      </div>
      <div class="level" *ngIf="baseQuotation.listState == 0">
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button is-info" (click)="unlink()" [translate]="'disconnect'">Scollega</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast position="top-right" key="opportunityResult" id="toastOpportunityResult"></p-toast>