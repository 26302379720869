<div class="level is-mobile">
  <div class="level-left">
    <div class="level-item">
      <div class="select is-medium">
        <select name="address" [(ngModel)]="type">
          <option [ngValue]="undefined" [translate]="'allAttachments'"></option>
          <option [ngValue]="1" [translate]="'attachmentOfProject'"></option>
          <option [ngValue]="2" [translate]="'attachmentOfConfiguration'"></option>
          <option [ngValue]="3" [translate]="'attachmentCustom'"></option>
        </select>
      </div>
    </div>
  </div>
  <div class="level-right">
    <div class="level-item">
      <app-upload [url]="uploadUrl" (updateClosed)="updateAttachment()"></app-upload>
    </div>
  </div>
</div>
<table cdk-table [dataSource]="attachments$ | async | filterBy:['type']: type | orderBy: order" class="table is-fullwidth">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container cdkColumnDef="date">
    <th cdk-header-cell *cdkHeaderCellDef>
      <a (click)="sortTable('date')">
        <span [translate]="'date'"></span>
        <ng-container *ngIf="order.endsWith('date')">
          &nbsp;<i class="fas fa-sort-down" *ngIf="order.charAt(0) !== '-'"></i>
          <i class="fas fa-sort-up" *ngIf="order.charAt(0) === '-'"></i>
        </ng-container>
      </a></th>
    <td cdk-cell *cdkCellDef="let element"> {{element.date | date}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container cdkColumnDef="fileName">
    <th cdk-header-cell *cdkHeaderCellDef><span [translate]="'fileName'"></span></th>
    <td cdk-cell *cdkCellDef="let element">
      <ng-container *ngIf="element.label">
        <span [translate]="element.label"></span>&nbsp;
      </ng-container>
      {{element.name}}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container cdkColumnDef="fileType">
    <th cdk-header-cell *cdkHeaderCellDef>
      <a (click)="sortTable('extension')">
        <span [translate]="'fileType'"></span>
        <ng-container *ngIf="order.endsWith('extension')">
          &nbsp;<i class="fas fa-sort-down" *ngIf="order.charAt(0) !== '-'"></i>
          <i class="fas fa-sort-up" *ngIf="order.charAt(0) === '-'"></i>
        </ng-container>
      </a>
    </th>
    <td cdk-cell *cdkCellDef="let element"> {{element.extension}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container cdkColumnDef="author">
    <th cdk-header-cell *cdkHeaderCellDef>
      <a (click)="sortTable('author')">
        <span [translate]="'author'"></span>
        <ng-container *ngIf="order.endsWith('author')">
          &nbsp;<i class="fas fa-sort-down" *ngIf="order.charAt(0) !== '-'"></i>
          <i class="fas fa-sort-up" *ngIf="order.charAt(0) === '-'"></i>
        </ng-container>
      </a>
    <td cdk-cell *cdkCellDef="let element"> {{element.author}} </td>
  </ng-container>

  <ng-container cdkColumnDef="buttons">
    <th cdk-header-cell *cdkHeaderCellDef><span>&nbsp;</span></th>
    <td cdk-cell *cdkCellDef="let at">
      <a (click)="download(at)" *ngIf="!at.downloading"><i class="fas fa-download"></i></a>
      <i class="fas fa-cog fa-spin" *ngIf="at.downloading"></i>
      <span *ngIf="at.author != 'system' && at.author != 'configurator' ">
              &nbsp;
              <a (click)="delete(at)"><i class="fas fa-trash"></i></a>
            </span>
    </td>
  </ng-container>

  <tr cdk-header-row *cdkHeaderRowDef="['date', 'fileName', 'fileType', 'author', 'buttons']"></tr>
  <tr cdk-row *cdkRowDef="let row; columns: ['date', 'fileName', 'fileType', 'author','buttons'];"></tr>
</table>
<p-confirmDialog key="confirmDeleteAttach" icon="pi pi-exclamation-triangle"></p-confirmDialog>