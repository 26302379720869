import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StoreService } from '../store.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(private storeService: StoreService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let lang = this.storeService.translateService.currentLang;
    if (!lang) {
      return next.handle(req);
    }
    const headers = req.headers.set("Accept-Language", lang);
    req = req.clone({ headers });
    return next
      .handle(req);
  }

}
