import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-nav-menu',
  template: `
    <div [ngSwitch]="viewer">
      <nav-menu-viewer *ngSwitchCase="true"></nav-menu-viewer>
      <nav-menu-std *ngSwitchDefault></nav-menu-std>
    </div>
  `
})
export class NavMenuComponent implements OnInit {
  viewer: boolean = false;

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit() {
    const claim = this.authService.getUserProfile();
    this.viewer = claim.view_only && claim.view_only == "true";
  }

}
