import { Component, OnInit } from '@angular/core';
import { StatsService } from '../stats.service';
import { Observable } from 'rxjs';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-performance-admin',
  templateUrl: './performance-admin.component.html',
  styleUrls: ['./performance-admin.component.scss']
})
export class PerformanceAdminComponent implements OnInit {

  stats$:Observable<any>;
  downloadingInformationUser: boolean;

  constructor(private statsService:StatsService, private fileSaverService: FileSaverService) { }

  ngOnInit() {

    this.stats$=this.statsService.getPerformanceUtenti()
  }

  getExcelUsers() {
    if(this.downloadingInformationUser) return;
    
    this.downloadingInformationUser = true;
    this.statsService.getExcelUser().subscribe({
      next: (data) => {
      this.downloadingInformationUser = false;
      if (data.size > 0) {
        this.fileSaverService.save(data, `UtentiAttivi.xlsx`);
      } else {
        alert("Errore nella creazione del file excel");
      }
    }, 
    error: (error: any) => {
        this.downloadingInformationUser = false;
        alert("Errore nella creazione del file excel");
    }});
  }

}
