import { Component, OnInit, OnDestroy, ViewChild, inject } from '@angular/core';
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatString } from "../shared/functions";
import * as moment from 'moment';
import { SharedService } from "../shared.service";
import { StoreService } from "../store.service";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { concat } from 'lodash';
import { arrayRemove, doc, updateDoc } from 'firebase/firestore';
import { AuthenticationService } from '../services/authentication.service';
import { docData, Firestore } from '@angular/fire/firestore';

@Component({
  selector: 'new-notifications',
  templateUrl: './new-notifications.component.html',
  styleUrls: ['./new-notifications.component.css']
})
export class NewNotificationsComponent implements OnInit, OnDestroy {

  userdata: any = {};
  translations: any = {};
  notifiche: any[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  urlBlob: string;

  @ViewChild('panelNotification', { static: true }) 
  panelNotification: any;

  urlImageToShowFullScreen: string;
  showFullImage: boolean;

  firestore: Firestore = inject(Firestore);

  constructor(private router: Router, private authService: AuthenticationService,
    private sharedService: SharedService, private storeService: StoreService) { }

  ngOnInit() {
    this.urlBlob = environment.urlBlob;

    let translationRequired = marker(['notificationOrderRegisterBody', 'notificationOrderRegisterInEsolverTitle', 'notificationProjectBody', 'notificationRejectProjectBody',
      'notificationQuotationSharedBody', 'notificationQuotationSharedTitle', 'notificationProjApprovedTitle', 'notificationProjRejectTitle', 'notificationUseCheckPrizeTitle',
      'notificationUseCheckPrizeBody', 'notificationUseCheckPrizeBodyMultipleOrder', 'notificiationNewArticleAnthologyBody', 'notificiationNewArticleAnthologyStarBody',
      'notificiationNewArticleAnthologyStarTitle', 'notificiationNewArticleAnthologyTitle', 'notificiationNewArticleBody', 'notificiationNewArticleTitle']);

    this.userdata = this.authService.getUserProfile();


    let docRef = doc(this.firestore, `user/${this.userdata.sub}`);
    combineLatest([this.storeService.translateService.stream(translationRequired),
      docData(docRef)])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([translation, doc]) => {
        this.translations = translation;
        let rawNotification = []; 
        rawNotification = doc.notifications as any[];

        let notificationsToAsk: string[] = [];

        if (rawNotification.length > 0) {
          if (this.notifiche.length > 0) {
            notificationsToAsk = rawNotification.filter(r => !this.notifiche.includes(n => n.id == r.id)).map(r => r.id);
            var i = this.notifiche.length;

            while (i--) { //postfisso! Deve fare check sul valore _precedente_ 0 e' valido

              if (!rawNotification.some(r => r.id == this.notifiche[i].id)) {
                this.notifiche.splice(i, 1);
              }
            }
          } else {
            notificationsToAsk = rawNotification.map(r => r.id);
            this.notifiche = [];
          }
        } else {
          //non ho notifiche da mostrare. azzero
          this.notifiche = [];
        }

        if (notificationsToAsk.length > 0) {
          this.sharedService.getNotifications(notificationsToAsk).subscribe(nS => {

            let notificheTemp = concat(this.notifiche, nS);
            notificheTemp = notificheTemp.filter((el, i, selfArray) => i == selfArray.findIndex(n => n.id == el.id)); //elimino doppioni

            let index = 0;
            while (index < notificheTemp.length) {
              let rowTempNotification = rawNotification.find(r => r.id == notificheTemp[index].id);
              if (!rowTempNotification) {
                //non c'e' piu' la notifica. Eliminata in altra tab o cache vecchia
                notificheTemp = notificheTemp.splice(index, 1);
                continue;
              }
              notificheTemp[index].date = rawNotification.find(r => r.id == notificheTemp[index].id).date;
              notificheTemp[index].dateFormatted = moment(notificheTemp[index].date, 'DD-MM-YYYY HH:mm:ss').toDate();
              if (notificheTemp[index].type == 0) {
                notificheTemp[index].titleNotification = this.translations[notificheTemp[index].idTitle];
                notificheTemp[index].abstractNotification = FormatString(this.translations[notificheTemp[index].idText], notificheTemp[index].parameterText);
              } else { //not.type == 1
                notificheTemp[index].titleNotification = notificheTemp[index].title.find(t => t.language == this.storeService.translateService.currentLang).label;
                notificheTemp[index].abstractNotification = notificheTemp[index].abstract.find(t => t.language == this.storeService.translateService.currentLang).label;
                if (notificheTemp[index].url && notificheTemp[index].url.length > 0) {
                  notificheTemp[index].urlNotification = notificheTemp[index].url.find(t => t.language == this.storeService.translateService.currentLang).label;
                }
              }
              ++index;
            }
            this.notifiche = notificheTemp;

          });
        } else {
          for (let not of this.notifiche) {
            if (not.type == 0) {
              not.titleNotification = this.translations[not.idTitle];
              not.abstractNotification = FormatString(this.translations[not.idText], not.parameterText);
            } else { //not.type == 1
              not.titleNotification = not.title.find(t => t.language == this.storeService.translateService.currentLang).label;
              not.abstractNotification = not.abstract.find(t => t.language == this.storeService.translateService.currentLang).label;
              if (not.url && not.url.length > 0) {
                not.urlNotification = not.url.find(t => t.language == this.storeService.translateService.currentLang).label;
              }
            }
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  deleteNotification(notifica: any) {
    let notification = doc(this.firestore, `user/${this.userdata.sub}`);
    
    debugger;
    updateDoc(notification, { notifications : arrayRemove({ "id": notifica.id, "date": notifica.date })}).then((res) => {
      debugger;
    }).catch((err) => {
      debugger;
     });
  }

  deleteAllNotifications() {
    let notification = doc(this.firestore, `user/${this.userdata.sub}`);
    
    debugger;
    updateDoc(notification, { notifications : []}).then((res) => {
      debugger;
    }).catch((err) => {
      debugger;
     });
  }

  goToArchive() {
    const url = this.storeService.createUrl(['/profile', 'notifications']) as string[];
    this.router.navigate(url);
    this.panelNotification.hide();
  }

  openNotificationUrl(notifica: any) {
    if (notifica.urlRouting || notifica.urlNotification) {
      if (notifica.type == 0) {
        this.router.navigateByUrl(this.storeService.createUrl(notifica.urlRouting) as string);
      } else {
        window.open(notifica.urlNotification, notifica.openUrlBlank ? "_blank" : "_parent");
      }
      this.panelNotification.hide();
    }
  }

  openFullImage(url: string) {
    this.urlImageToShowFullScreen = url;
    this.showFullImage = true;
  }

}
