import { Directive, EventEmitter, HostListener, OnInit, Output, ElementRef } from '@angular/core';
import { Observable, Subscription, fromEvent, interval } from 'rxjs';

@Directive({
  selector: '[hoverIntent]'
})
export class HoverIntentDirective implements OnInit {
  @Output() onHoverIntent = new EventEmitter();
  @Output() onLeave = new EventEmitter();
  private mouseMove = new Observable();
  private interval = new Observable();
  private mouseMoveSubscription: Subscription;
  private timerSubscription: Subscription;
  private state: any = { isActive: false };
  private cX: number;
  private cY: number;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.mouseMove = fromEvent(this.el.nativeElement, 'mousemove');
    this.interval = interval(100);
  }

  ngOnDestroy() {
    if (this.mouseMoveSubscription)
      this.mouseMoveSubscription.unsubscribe();
    if (this.timerSubscription)
      this.timerSubscription.unsubscribe();
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(ev: MouseEvent) {
    // clear any existing timeout
    if (this.timerSubscription)
      this.timerSubscription.unsubscribe();
    // do nothing if already active
    if (this.state.isActive) { return; }
    // set "previous" X and Y position based on initial entry point
    this.state.pX = ev.pageX; this.state.pY = ev.pageY;
    this.mouseMoveSubscription = this.mouseMove.subscribe((ev: MouseEvent) => {
      this.cX = ev.pageX;
      this.cY = ev.pageY;
    });
    this.timerSubscription = this.interval.subscribe(_ => {
      // compare mouse positions to see if pointer has slowed enough to trigger `over` function
      const compare = Math.sqrt((this.state.pX - this.cX) * (this.state.pX - this.cX) + (this.state.pY - this.cY) * (this.state.pY - this.cY));
      if (compare < 6) {
        this.mouseMoveSubscription.unsubscribe();
        this.timerSubscription.unsubscribe();
        this.state.isActive = true;
        // clear coordinate data from state object
        delete this.state.pX; delete this.state.pY;
        // emit event
        this.onHoverIntent.emit();
      }
      else {
        // set previous coordinates for next comparison
        this.state.pX = this.cX; this.state.pY = this.cY;
      }
    });
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(_event: MouseEvent) {
    // clear any existing timeout
    if (this.timerSubscription)
      this.timerSubscription.unsubscribe();
    // do nothing if not already active
    if (!this.state.isActive) { return; }
    this.state.isActive = false;
    // unbind expensive subscriptions
    if (this.mouseMoveSubscription)
      this.mouseMoveSubscription.unsubscribe();
    if (this.timerSubscription)
      this.timerSubscription.unsubscribe();
    // emit event
    this.onLeave.emit();
  }

}
