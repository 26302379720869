<table class="table">
  <tr>
    <td>
      <div class="title is-4" [translate]="'subtotal'">Totale parziale</div>
    </td>
    <td>
      <div class="title is-4 has-text-right">{{listTotalReserved | currency:'EUR'}}</div>
    </td>
  </tr>
  <tr *ngIf="projectDiscountValue > 0">
    <td>
      <div class="title is-4" [translate]="'projectDiscountValue'"></div>
    </td>
    <td>
      <div class="title is-4 has-text-right">{{projectDiscountValue | currency:'EUR'}}</div>
    </td>
  </tr>
  <tr *ngIf="extraSconto > 0">
    <td>
      <div class="title is-4" [translate]="'extraDiscount'"></div>
    </td>
    <td>
      <div class="title is-4 has-text-right">{{extraSconto | currency:'EUR'}}</div>
    </td>
  </tr>
  <tr *ngIf="checkPrizeApplied > 0">
    <td>
      <div class="title is-4 has-text-warning">Check Prize</div>
    </td>
    <td>
      <div class="title is-4 has-text-right has-text-warning">{{checkPrizeApplied | currency:'EUR'}}</div>
    </td>
  </tr>
  <tr>
    <td>
      <div class="title is-4" [translate]="'shippingCost'">Spese di spedizione</div>
    </td>
    <td>
      <div class="title is-4 has-text-right"
           *ngIf="shippingTotal > 0;else no_shipCost_total">{{shippingTotal | currency:'EUR'}}</div>
      <ng-template #no_shipCost_total>
        <div class="title is-4 has-text-right" [translate]="'freeCost'">Gratuite</div>
      </ng-template>
    </td>
  </tr>
  <tr *ngIf="customerDiscountApplied">
    <td>
      <div class="title is-4" [translate]="'condition'"></div>
    </td>
    <td>
      <div class="title is-4 has-text-right">
        {{customerDiscountApplied}}
      </div>
    </td>
  </tr>
  <tr>
    <td>
      <div class="title is-3" [translate]="'total'">Totale</div>
    </td>
    <td>
      <div class="title is-3 has-text-right">
        {{(shippingTotal + listTotalReserved - extraSconto - checkPrizeApplied - projectDiscountValue) | currency:'EUR'}}
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" class="has-text-right">
      <span class="is-italic is-size-6" [translate]="'noTaxInclude'">Importi IVA esclusa</span>
    </td>
  </tr>
</table>
