import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private cdnHost: string = "https://macroluxcdn.azureedge.net/media"

  constructor() {

  }

  getImageUrl(image: string, product: string, fallbackSrc: string, type: string, query: string, quotationId: string = ""): string {
    switch (type) {
      case 'custom': {
        if (image) {
          let url : string;
          if(environment.production) {
            url = `${this.cdnHost}/quotation/${quotationId}/${image}`;
            if (query)
              url = `${this.cdnHost}/quotation/${quotationId}/${image}?${query}`;
          } else {
            url = `${environment.urlBlob}/catalog/quotation/${quotationId}/${image}`;
          }
          return url;
        } else if (fallbackSrc) {
          return fallbackSrc;
        }
        return null;
      }

      case 'accessory': {
        if (image) {
          let url = `${this.cdnHost}/accessories/${image}`;
          if (query)
            url = `${this.cdnHost}/accessories/${image}?${query}`;
          return url;
        } else if (fallbackSrc) {
          return fallbackSrc;
        }
        return null;
      }
      case 'family': {
        if (image) {
          let url = `${this.cdnHost}/productfamilies/${image}`;
          if (query)
            url = `${this.cdnHost}/productfamilies/${image}?${query}`;
          return url;

        } else if (fallbackSrc) {
          return fallbackSrc;
        }
        return null;
      }
      default: {
        if (image && product) {
          let url = `${this.cdnHost}/${product}/${type}/${image}`;
          if (query)
            url = `${this.cdnHost}/${product}/${type}/${image}?${query}`;
          return url;
        } else if (fallbackSrc) {
          return fallbackSrc;
        }
        return null;
      }
    }
  }
}
