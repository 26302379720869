import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeDeAt from '@angular/common/locales/de-AT';
import localeDeCh from '@angular/common/locales/de-CH';
import localeEnAu from '@angular/common/locales/en-AU';

import { NgPipesModule } from 'ngx-pipes';
import { FileSaverModule } from 'ngx-filesaver';
import { CdkTableModule } from '@angular/cdk/table';

import { AppComponent } from './app.component';
// Import the service
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CatalogComponent } from './catalog/catalog.component';
import { CategoryComponent } from './catalog/category.component';
import { MenuComponent } from './catalog/menu.component';
import { ProductComponent } from './catalog/product.component';

import { AttributeControlComponent } from './catalog/attribute-control.component';
import { AccessoriesComponent } from './catalog/accessories/accessories.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';

import {
  AnthologyPipe,
  AttributeValuePipe,
  IsSelectedAttributePipe,
  AttributeNamePipe,
  IsSelectedAttributeValuePipe,
  CategoryNamePipe,
  CeilingPipe,
  AbsolutePipe,
  HasFilePipe,
  GetValuesPipe,
  ApplicationNamePipe,
  SanitizeHtmlPipe,
  CdnImagePipe, AccountNamePipe, AccessoryFilePipe, LangNamePipe, BrandNamePipe, CalculateMarginePipe, CalculatePrezzoVenditaPipe,
  SizeRevisionsArrayPipe
} from './app.pipes';
import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { SidebarModule as PrimeSidebarModule } from 'primeng/sidebar';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { InplaceModule } from 'primeng/inplace';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ApplicationMenuComponent } from './nav-menu/application-menu.component';
import { SquareSizeDirective } from './directives/square-size.directive';
import { HoverIntentDirective } from './directives/hover-intent.directive';
import { AddToCartComponent } from './catalog/add-to-cart/add-to-cart.component';
import { ProductImagesComponent } from './catalog/product-images/product-images.component';
import { CdnImageBackgroundDirective, CdnImageDirective, ShowTooltipIfTruncatedDirective } from './app.directives';
import { ListsComponent } from './lists/lists.component';
import { ListDetailComponent } from './lists/list-detail.component';
import { ListItemComponent } from './lists/list-item.component';
import { Route } from './app.routes';
import { QuotationDetailComponent } from './quotations/quotation-detail.component';
import { QuotationItemComponent } from './quotations/items/quotation-item.component';
import { QuotationWizarditemComponent } from './quotations/items/quotation-wizarditem.component';
import { DndModule } from "ngx-drag-drop";
import { CanDeactivateQuotationGuard } from './quotations/quotation.guard';
import { CanDeactivatListGuard } from './lists/list-detail.guard';
import { QuotationCustomerComponent } from './quotations/quotation-customer.component';
import { CheckoutItemComponent } from './checkout/item-checkout/checkout-item.component';
import { ShippingAddressComponent } from './checkout/shipping-address.component';
import { SearchComponent } from './nav-menu/search.component';
import { FooterComponent } from './footer/footer.component';
import { PageComponent } from './page/page.component';
import { FamilyComponent } from './catalog/family.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileDetailComponent } from './profile/profile-detail.component';
import { ProfileSettingsComponent } from './profile/profile-settings.component';
import { AccessoryCatalogComponent } from "./accessory-catalog/accessory-catalog.component";
import { QuotationOpportunityComponent } from './quotations/quotation-opportunity.component';
import { OpportunityResolver } from "./resolvers/opportunity.resolver";
import { OpportunityComponent } from './opportunity/opportunity.component';
import { AccessoryCategoryComponent } from './accessory-catalog/accessory-category.component';
import { AccessoriesResolver, AccessoryCategoriesResolver } from "./resolvers/accessories.resolver";
import { QuotationDiscountComponent } from './quotations/quotation-discount.component';
import { QuotationComponent } from './quotations/quotation.component';
import { ProductWizardComponent } from './catalog/product-wizard.component';
import { QuotationProjectComponent } from './quotations/quotation-project.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { QuotationPdfComponent } from './quotations/quotation-pdf.component';
import { FillHeightModule } from './modules/fill-height/fill-height.module';
import { ListWizarditemComponent } from './lists/list-wizarditem.component';
import { LogLevel } from '@microsoft/signalr';
import { ProfileAddressComponent } from './profile/address.component';
import { OrderCompletedComponent } from './checkout/order-completed.component';
import { environment } from '../environments/environment';
import { VERSION } from '../environments/version';
import { ListQuotationComponent } from './quotations/listquotations/list-quotation.component';
import { ItemQuotationComponent } from './quotations/listquotations/item-quotation.component';
import { QuotationBrochureComponent } from './quotations/quotation-brochure.component';

import * as Sentry from "@sentry/angular";
import { ProfileContractsComponent } from './profile/profile-contracts.component';
import { StoreComponent } from './store/store.component';
import { StoreResolver } from './store/store.resolver';
import { LocalizeRouterPipe } from './store/localize.pipe';
import { LanguageInterceptor } from './interceptor/LanguageInterceptor';
import { CustomItemComponent } from './quotations/items/custom-item.component';
import { ProfileCheckprizeComponent } from './profile/checkprize.component';
import { NavMenuViewerComponent } from './nav-menu/nav-menu-viewer.component';
import { NavMenuStandardComponent } from './nav-menu/nav-menu-std.component';
import { PaymentMethodsComponent } from './profile/payment-methods.component';
import { CheckOutResolver } from './checkout/checkout.resolver';
import { CheckoutPlainComponent } from './checkout/checkout-plain.component';
import { CheckOutPlainResolver } from './checkout/checkout-plain.resolver';
import { CheckoutItemPlainComponent } from './checkout/item-checkout/checkout-item-plain.component';
import { TotalCheckoutComponent } from './checkout/item-checkout/total-checkout.component';
import { Checkout3CarrelliComponent } from './checkout/checkout-3-carrelli.component';
import { NewNotificationsComponent } from './nav-menu/new-notifications.component';
import { NotificationsComponent } from './profile/notifications.component';
import { QuotationAttachmentsComponent } from './quotations/quotation-attachments.component';
import { UploadModule } from './modules/upload/upload.module';
import { ItemSubstituteComponent } from './catalog/item-substitute/item-substitute.component';
import { CompanyInfoResolver } from './resolvers/companyInfo.resolver';
import { HubConnectionFactory } from '@ssv/signalr-client';
import { ChartModule } from 'primeng/chart';
import { PerformanceClienteComponent } from './home/performance-cliente.component';
import { SearchCompaniesComponent } from './home/search-companies.component';
import { PerformanceMacroluxComponent } from './home/performance-macrolux.component';
import { PerformanceAgenteComponent } from './home/performance-agente.component';
import { PerformanceAdminComponent } from './home/performance-admin.component';
import { QuotationsShareComponent } from './quotations/quotations-share.component';
import { IframeComponent } from './footer/IframeComponent/iframe.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProjectApprovedComponent } from './project-procedure-output/project-approved/project-approved.component';
import { ProjectRejectedComponent } from './project-procedure-output/project-rejected/project-rejected.component';

import { NgxCurrencyDirective, NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { AbstractSecurityStorage, AuthInterceptor, AuthModule } from 'angular-auth-oidc-client';
import { configAuth } from './auth/auth.config';
import { CarouselModule } from 'primeng/carousel';
import { AppRouteReuseStrategy } from './shared/approutereusestrategy';
import { DefaultLocalStorageService } from './auth/auth.local-storage';
import { AuthCallbackComponent } from './auth/auth-callback/auth-callback.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { PaginatorModule } from 'primeng/paginator';
import { GalleriaModule } from 'primeng/galleria';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { QuotationRetailComponent } from './quotations/quotation-retail.component';


if (environment.production) {
  Sentry.init({
    dsn: "https://a2d18b2f88d2438584e9e58dd55e3a7e@sentry.io/1408396",
    integrations: [],
    release: VERSION.version,
    environment: 'production',
    enabled: environment.production
  });
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/messages.', '.json');
}

registerLocaleData(localeIt, 'it');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDeAt, 'de-AT');
registerLocaleData(localeDeCh, 'de-CH');
registerLocaleData(localeEnAu, 'en-AU');


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CatalogComponent,
    MenuComponent,
    CategoryComponent,
    ProductComponent,
    AttributeControlComponent,
    AccessoriesComponent,
    AnthologyPipe,
    AttributeNamePipe,
    AttributeValuePipe,
    IsSelectedAttributeValuePipe,
    CategoryNamePipe,
    CeilingPipe,
    AbsolutePipe,
    ApplicationNamePipe,
    HasFilePipe,
    ApplicationMenuComponent,
    SquareSizeDirective,
    HoverIntentDirective,
    AddToCartComponent,
    ProductImagesComponent,
    CdnImageDirective,
    ListsComponent,
    ListDetailComponent,
    ListItemComponent,
    QuotationDetailComponent,
    QuotationItemComponent,
    GetValuesPipe,
    QuotationCustomerComponent,
    CheckoutItemComponent,
    CheckoutPlainComponent,
    Checkout3CarrelliComponent,
    TotalCheckoutComponent,
    CheckoutItemPlainComponent,
    ShippingAddressComponent,
    SearchComponent,
    FooterComponent,
    PageComponent,
    SanitizeHtmlPipe,
    CdnImagePipe,
    CdnImageBackgroundDirective,
    AccountNamePipe,
    LocalizeRouterPipe,
    IsSelectedAttributePipe,
    AccessoryFilePipe,
    FamilyComponent,
    ProfileComponent,
    ProfileDetailComponent,
    ProfileSettingsComponent,
    AccessoryCatalogComponent,
    AccessoryCategoryComponent,
    QuotationDiscountComponent,
    QuotationComponent,
    NavMenuComponent,
    NavMenuViewerComponent,
    NavMenuStandardComponent,
    ProductWizardComponent,
    QuotationProjectComponent,
    QuotationPdfComponent,
    QuotationBrochureComponent,
    QuotationRetailComponent,
    QuotationWizarditemComponent,
    ListWizarditemComponent,
    ProfileAddressComponent,
    OrderCompletedComponent,
    ListQuotationComponent,
    ItemQuotationComponent,
    ProfileContractsComponent,
    StoreComponent,
    CustomItemComponent,
    ProfileCheckprizeComponent,
    PaymentMethodsComponent,
    NewNotificationsComponent,
    NotificationsComponent,
    QuotationAttachmentsComponent,
    ItemSubstituteComponent,
    PerformanceClienteComponent,
    SearchCompaniesComponent,
    PerformanceMacroluxComponent,
    PerformanceAgenteComponent,
    PerformanceAdminComponent,
    QuotationsShareComponent,
    LangNamePipe,
    ShowTooltipIfTruncatedDirective,
    QuotationOpportunityComponent,
    OpportunityComponent,
    IframeComponent,
    BrandNamePipe,
    CalculateMarginePipe,
    CalculatePrezzoVenditaPipe,
    ProjectApprovedComponent,
    ProjectRejectedComponent,
    AuthCallbackComponent,
    SizeRevisionsArrayPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule.forRoot(
      configAuth
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxCurrencyDirective,
    FillHeightModule,
    NgPipesModule,
    AutoCompleteModule,
    DropdownModule,
    InplaceModule,
    ToastModule,
    DialogModule,
    FileSaverModule,
    PdfJsViewerModule,
    ConfirmDialogModule,
    TooltipModule,
    InputNumberModule,
    CalendarModule,
    CdkTableModule,
    InputMaskModule,
    PrimeSidebarModule,
    DndModule,
    UploadModule,
    ChartModule,
    RouterModule.forRoot(Route,
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 32],
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload',
        enableTracing: environment.enableRouteTracing
      }
    ),
    OverlayPanelModule,
    CarouselModule,
    NgxGaugeModule,
    NgxCurrencyDirective,
    NgClickOutsideDirective,
    PaginatorModule,
    GalleriaModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    { provide: AbstractSecurityStorage, useClass: DefaultLocalStorageService },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    HubConnectionFactory,
    ConfirmationService,
    MessageService,
    AccessoriesResolver,
    CheckOutResolver,
    CompanyInfoResolver,
    CheckOutPlainResolver,
    AccessoryCategoriesResolver,
    StoreResolver,
    CanDeactivatListGuard,
    CanDeactivateQuotationGuard,
    DatePipe,
    LangNamePipe,
    OpportunityResolver,
    provideEnvironmentNgxCurrency({
      prefix: 'EUR ',
      thousands: '.',
      decimal: ',',
      allowNegative: false,
      allowZero: false,
      nullable: false,
      align: 'right',
      precision: 2,
      suffix: '',
      inputMode: NgxCurrencyInputMode.Financial
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(hubFactory: HubConnectionFactory) {
    hubFactory.create(
      {
        key: "service",
        endpointUri: "/hub",
        options: {
          logger: LogLevel.Warning,
          retry: {
            maximumAttempts: 15,
            randomBackOffStrategy: {
              min: 1,
              max: 6,
              intervalMs: 10000
            }
          }
        }
      },
    );
  }
}
