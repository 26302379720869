import { Injectable } from '@angular/core'
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { FileIdWithError } from './interfaces';

@Injectable()
export class UploadService {
  constructor(private http: HttpClient) { }

  public upload(files: Set<FileIdWithError>, url: string): { [key: string]: { progress: Observable<number> } } {

    // this will be the our resulting map
    const status: { [key: string]: { progress: Observable<any> } } = {};

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', url, formData, {
        reportProgress: true
      });

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // send the http-request and subscribe for progress-updates
      this.http.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {

          // calculate the progress percentage
          const percentDone = Math.round(100 * event.loaded / event.total);

          // pass the percentage into the progress-stream
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {

          if (!event.ok) {
            progress.next(0);
            file.error = event.statusText;
          }

          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();
        }
      }, (err) => {
          progress.next(0);
          file.error = err.error;
          progress.complete();
      });

      // Save every progress-observable in a map of all observables
      let key = file.id ? file.id : file.name;

      status[key] = {
        progress: progress.asObservable()
      };
    });

    // return the map of progress.observables
    return status;
  }
}
