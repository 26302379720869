import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceMoment } from '../factory-moment.service';
import { SharedService } from '../shared.service';
import { UserPreferenceService } from '../user-preference.service';

@Injectable()
export class CheckOutResolver  {
  constructor(private userPreference: UserPreferenceService, private serviceMoment: ServiceMoment,
    private sharedService: SharedService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    let type = route.data['type'];
    let id = route.paramMap.get('id');
    return forkJoin([this.userPreference.getIvaCountry(id, type), this.sharedService.getHolyDays().pipe(map((periods) => {
      let days = [];
      for(let period of periods) {
        let start =  moment(period.inizio).toDate();
        let end =  moment(period.fine).toDate();
        days.push(start);
        let currentDate = new Date(start);
       
        while(currentDate < end) {
          currentDate = moment(currentDate).add(1, 'days').toDate();
          days.push(currentDate)
        }
      }
      this.serviceMoment.init(days);
      return days;
    }))]) ;
  }
}
