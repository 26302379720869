<ng-container *ngFor="let addr of address">
  <div class="is-divider is-medium" style="margin: 2rem 0 2rem" [attr.data-content]="addr.numProgr == 0 ? ('profileMainAddress' | translate) : (('profileAddress' | translate) + ' ' + addr.numProgr)"></div>
  <div class="title is-5">{{addr.ragSoc1}}</div>
  <div class="sub-title is-6" *ngIf="addr.ragSoc2">{{addr.ragSoc2}}</div>
  <div class="columns">
    <div class="column is-4">
      <div *ngIf="addr.indirizzo">{{addr.indirizzo}}</div>
      <div *ngIf="addr.indirizzo2">{{addr.indirizzo2}}</div>
      <div>
        {{addr.localita}}<span *ngIf="addr.localita2">&nbsp;-&nbsp;{{addr.localita2}}</span>
        <span *ngIf="addr.provincia">&nbsp;({{addr.provincia}})</span>
        <span *ngIf="addr.cap">&nbsp;{{addr.cap}}</span>
      </div>
      <div>{{addr.codStato}}</div>
    </div>
    <div class="column">
      <div *ngIf="addr.email"><i class="fas fa-at fa-fw"></i>&nbsp;{{addr.email}}</div>
      <div *ngIf="addr.numTel"><i class="fas fa-phone fa-fw"></i>&nbsp;{{addr.numTel}}</div>
      <div *ngIf="addr.numTel2"><i class="fas fa-phone fa-fw"></i>&nbsp;{{addr.numTel2}}</div>
    </div>
  </div>
</ng-container>
