<section class="section">
  <div class="container is-widescreen">
    <div class="columns">
      <div class="column is-one-fifth list-enum">
        <ng-container *ngIf="!viewer">
          <h3 class="title is-4" [translate]="'yourLists'">Le tue liste</h3>
          <div class="subtitle is-6">
            <a (click)="addList(true)" *ngIf="!addListMode">
              <i class="fas fa-plus fa-fw fa-sm"></i><span [translate]="'addList'">Aggiungi lista</span>
            </a>
            <form *ngIf="addListMode">
              <div class="field has-addons is-fullwidth">
                <div class="control">
                  <input class="input" type="text" [(ngModel)]="newList" name="newList"
                    [placeholder]="'listName' | translate">
                </div>
                <div class="control">
                  <button type="submit" class="button is-info" (click)="createList()" [disabled]="!newList">
                    <i class="fas fa-check"></i>
                  </button>
                </div>
                <div class="control">
                  <button type="submit" class="button" (click)="addList(false)">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <ul>
            <li *ngFor="let list of listService.lists | async">
              <a class="has-text-dark truncate list-name" [routerLink]="['/lists',list.id] | localize"
                (click)="goToList(list)" routerLinkActive="has-text-weight-bold">
                <span *ngIf="list.defaultShoppingCart" [translate]="'carrello'"></span>
                <span *ngIf="!list.defaultShoppingCart">{{list.name}}</span>
              </a>
            </li>
          </ul>
          <hr />
        </ng-container>
        <h3 class="title is-4">
          <ng-container *ngIf="(listService.quotations | async) as quotations; else noQuotation">
            <a [routerLink]="['/quotations', 'listQuotations'] | localize">
              <span [translate]="'yoursQuotations'">I tuoi preventivi</span>
            </a>
          </ng-container>
          <ng-template #noQuotation>
            <span class="quotations-title" [translate]="'yoursQuotations'">I tuoi preventivi</span>
          </ng-template>
        </h3>
        <div class="subtitle is-6">
          <a (click)="addQuotation(true)" *ngIf="!addQuotationMode">
            <i class="fas fa-plus fa-fw fa-sm"></i><span [translate]="'addQuotation'">Aggiungi preventivo</span>
          </a>
          <form *ngIf="addQuotationMode">
            <div class="field has-addons is-fullwidth">
              <div class="control">
                <input class="input" type="text" [(ngModel)]="newQuotation" name="newQuotation"
                  [placeholder]="'name' | translate">
              </div>
              <div class="control">
                <button type="submit" class="button is-info" (click)="createQuotation()" [disabled]="!newQuotation">
                  <i class="fas fa-check"></i>
                </button>
              </div>
              <div class="control">
                <button type="submit" class="button" (click)="addQuotation(false)">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
        <table class="table is-fullwidth" style="margin-left: -1.5em;">
          <tr *ngFor="let quotation of listService.quotations | async">
            <td>
              <i class="fas fa-fw" [class.has-text-white]="!quotation.shareWithCompany"
                [class.fa-file-export]="quotation.userId == claims.sub"
                [class.fa-file-import]="quotation.userId != claims.sub"></i>
            </td>
            <td>
              <div class="truncate quotation-name" style="margin-left: 0.3em;">
                <a class="has-text-dark" [routerLink]="['/quotations',quotation.id] | localize"
                (click)="goToQuotation(quotation)" routerLinkActive="has-text-weight-bold">
                {{quotation.name}}
              </a>
              </div>
              
            </td>
            <td>
              <i class="far fa-fw fa-registered" *ngIf="quotation.statusProject == 2"></i>
              <i class="far fa-fw fa-link" *ngIf="quotation.idOppportunitySalesForce"></i>
            </td>
          </tr>
        </table>
        <ng-container *ngIf="!viewer && listService.opportunities | async as opportunities">
          <div *ngIf="opportunities.length > 0">
            <hr />
            <h3 class="title is-4" [translate]="'opportunityIntra'">Opportunità</h3>
            <ul>
              <li *ngFor="let opportunity of opportunities">
                <a class="has-text-dark" [routerLink]="['/opportunities',opportunity.quotationId] | localize"
                  routerLinkActive="has-text-weight-bold">{{opportunity.name}}</a>
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
      <div class="column is-four-fifths detail">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>