import { Component, OnInit, ViewChild, Renderer2, HostListener, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { Router } from "@angular/router";
import { ListService } from "../list.service";
import { cloneDeep, clone } from "lodash";
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { diff } from "deep-diff";
import { watch, unwatch } from 'melanke-watchjs';
import { CatalogService } from '../catalog.service';
import { takeUntil, switchMap, delay, skip, take, mergeMap } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { StoreService } from '../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
//import { PopperContent } from 'ngx-popper';
//import { ReturnTo } from '../shared/costants';
//import { SharedService } from '../shared.service';

@Component({
  selector: 'app-list-detail',
  templateUrl: './list-detail.component.html',
  styleUrls: ['./list-detail.component.css'],
})
export class ListDetailComponent implements OnInit, OnDestroy {

  //@ViewChildren('aiPopper')
  //aiPopper: QueryList<PopperContent>;

  originalList: any;
  baseList: any;

  shopMode: number;
  listTotal: number;

  editTitleMode = false;
  newTitle: string;

  differenceTest: any;

  canDelete: boolean = false;

  isSending: boolean;
  titleHovering: boolean = false;

  cuItemId: any;
  cuIndex: any;
  dragStart: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  calcTotalsSubject$: Subject<number> = new Subject<number>();

  translations: any = {};

  @ViewChild('popperListQuote', { static: true }) popperListQuote: any;

  //ready: boolean;
  //itemSuggest: any[];

  //lastRequestedConfigId: string;
  //lastRequestedSku: string;
  //, private sharedService: SharedService
  constructor(private router: Router, private listService: ListService, private renderer: Renderer2,
    private catalogService: CatalogService, private storeService: StoreService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;


  }

  ngOnInit() {
    let translationRequired = marker(['requireModeBuy', 'saveModifyBeforeContinue', 'articleNotValidInCart',
      'confirmDeleteList', 'confirmTrasformationInQuotation', 'proceedWillDestroyModifyList']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.catalogService.getShopMode().pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.shopMode = t 
    });

    this.listService.getCurrentList().pipe(takeUntil(this.destroy$)).subscribe(t => {
      if (t.type != 0 || (this.originalList && t.id != this.originalList.id)) {
        return;
      }
      this.differenceTest = undefined;
      unwatch(this.baseList, _ => this.thereAreDifference());
      this.originalList = cloneDeep(t);
      this.baseList = t;
      watch(this.baseList, _ => this.thereAreDifference());
      this.canDelete = this.baseList.defaultShoppingCart != true
    });

    this.calcTotalsSubject$.pipe(switchMap((shopMode: number) => {
      return of(shopMode).pipe(delay(100));
    }), takeUntil(this.destroy$)).subscribe(data => {
      this.calculateFromObservable(data);
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    unwatch(this.baseList, _ => this.thereAreDifference());
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.thereAreDifference()) {
      $event.returnValue = true;
    }
  }

  editTitle(start) {
    if (start == true) {
      this.newTitle = clone(this.baseList.name);
      this.editTitleMode = true;
      this.popperListQuote.hide();
    } else {
      this.newTitle = null;
      this.editTitleMode = false;
    }
  }

  cancel() {
    this.differenceTest = undefined;
    this.baseList = cloneDeep(this.originalList);
  }

  updateTitle() {
    this.baseList.name = this.newTitle;
    this.newTitle = null;
    this.editTitleMode = false;
  }

  delete(listId) {
    if (confirm(this.translations["confirmDeleteList"]))
      this.listService.deleteUserList(listId).subscribe(x => this.router.navigateByUrl(this.storeService.createUrl("/lists") as string));
  }

  onDragStart(_event, index, itemId) {
    this.renderer.addClass(document.body, 'dragging');
    this.cuItemId = itemId;
    this.cuIndex = index;
    this.dragStart = true;
  }

  onDragEnd(_event) {
    this.renderer.removeClass(document.body, 'dragging');

    this.cuItemId = undefined;
    this.cuIndex = undefined;
    this.dragStart = false;
  }

  onDragged(index: number, list: any[], effect: DropEffect) {

    if (effect === "move") {
      //nota, questo evento viene eseguito DOPO onDrop, quindi list contiene un elemento doppio, che vado a rimuovere
      list.splice(index, 1);
    }
  }

  //qua intera sezione
  onDrop(event: DndDropEvent, list: any[], index: number) {

    if (list && (event.dropEffect === "copy"
      || event.dropEffect === "move")) {

      let item = event.data;
      //sono in coda
      if (index == -1) {
        list.push(item);
      } else {
        list.splice(index, 0, item);
      }
    }
  }

  saveList() {
    this.isSending = true;

    //se l'utente cambia in alto a dx la lista/quotazione devo prima resettare la currentList, aspettare che sia impostata e quindi chiamare l'updatelist
    this.listService.getCurrentList().pipe(skip(1), take(1), mergeMap(() => {
      return this.listService.updateList();
    })).subscribe(() => {
      this.isSending = false;
    });
    this.listService.setCurrentList(this.baseList);
  }

  //se ritorna true _IGNORA_
  private differenceTester(path, key): boolean {
    if (key === "name") {
      return path.includes("items");
    }

    return ['image', 'prezzo', 'categoryName', 'idBrand', 'hoverImage',
    'prezzoRiservato', 'availability', 'productModel', 'um', 
    'sku', 'esWarning', 'anthology', 'anthologyStar', 'mxStar', 'description'].includes(key);
  }

  thereAreDifference() {
    this.differenceTest = diff(this.originalList, this.baseList, this.differenceTester);
    return this.differenceTest !== undefined;
  }

  checkout(list) {
    this.router.navigate(this.storeService.createUrl(["lists", list.id, "checkout"]) as any[]);
  }

  calculateTotals(shopMode: number) {
    this.calcTotalsSubject$.next(shopMode);
  }

  private calculateFromObservable(shopMode: number) {
    this.shopMode = shopMode;
    let result = 0;
    let invalidList = false;
    for (let item of this.baseList.items) {
      let hasDiscount = item.discount > 0 || item.discount2 > 0 || item.discount3 > 0;
      let inversoDiscount = (1 - (item.discount || 0) / 100) * (1 - (item.discount2 || 0) / 100) * (1 - (item.discount3 || 0) / 100);
      if (item.type != 2) {
        if (item.prezzo) {
          if (shopMode == 0) {
            result += (item.prezzoRiservato * item.quantity);
          }
          else if (hasDiscount) {
            
            result += (inversoDiscount * item.prezzo * item.quantity);
          }
          else {
            result += (item.prezzo * item.quantity);
          }
        }
        for (let cItem of item.childItems) {
          if (!cItem.prezzo) continue;

          let hasDiscountChild = item.discount > 0 || item.discount2 > 0 || item.discount3 > 0;
          if (shopMode == 0) {
            result += (cItem.prezzoRiservato * cItem.quantity);
          }
          else if (hasDiscountChild) {
            let inversoDiscountChild = (1 - (cItem.discount || 0) / 100) * (1 - (cItem.discount2 || 0) / 100) * (1 - (cItem.discount3 || 0) / 100);
            result += (inversoDiscountChild * cItem.prezzo * cItem.quantity);
          }
          else {
            result += (cItem.prezzo * cItem.quantity);
          }
        }
      } else if (item.type == 2) { //se e' configurazione non considerare quantita'
        if (shopMode == 0) {
          result += (item.prezzoRiservato);
        }
        else if (hasDiscount) {
          result += (inversoDiscount * item.prezzo);
        }
        else {
          result += (item.prezzo);
        }
          
      }
      invalidList = (invalidList || item.esWarning);
    }
    this.baseList.invalid = invalidList;
    this.listTotal = result;
  }


  toQuote() {
    if (confirm(this.translations["confirmTrasformationInQuotation"]))
      this.listService.transformToQuote(this.baseList).subscribe(t => {
        this.router.navigate(this.storeService.createUrl(["quotations", t.item3]) as any[]);
      });
  }

  empty() {
    if (!this.baseList.defaultShoppingCart)
      return;

    this.baseList.items = [];
    this.saveList();
  }

  //openSuggest(event: any, popper: PopperContent) {
  //  if (this.lastRequestedConfigId == event.configId) {
  //    return;
  //  }
  //  this.lastRequestedConfigId = event.configId;
  //  //this.removeSelectionOnItems();
  //  let indexItem = this.baseList.items.findIndex(it => it.configurationId == this.lastRequestedConfigId);
  //  this.baseList.items[indexItem].highlight = true;
  //  this.lastRequestedSku = event.sku;
  //  this.ready = false;
  //  popper.show();
  //  this.sharedService.getSubstitute(this.lastRequestedConfigId, this.baseList.items[indexItem].referenceId, this.baseList.items[indexItem].quantity, this.baseList.id, 0).subscribe((data) => {
  //    this.itemSuggest = data;
  //    this.ready = true;
  //    popper.scheduleUpdate();
  //  });
  //}

  //removeSelectionOnItems() {
  //  for (let item of this.cartDetail.list.items) {
  //    item.highlight = false;
  //  }
  //  if (this.aiPopper)
  //    this.aiPopper.forEach(t => t.hide());
  //}

  //substituteArticle(event) {
  //  if (!event || !event.suggestedItem) {
  //    this.ready = false;
  //    this.itemSuggest = [];
  //    this.lastRequestedConfigId = undefined;
  //    return;
  //  }

  //  let suggestedItem = event.suggestedItem;
  //  let item = event.item;

  //  let quantityToSubmit = item.quantity;

  //  let indexPiecesItem = item.attributes.findIndex(at => at.sysName == 'pieces');
  //  if (indexPiecesItem != -1 && item.attributes[indexPiecesItem].value) {
  //    quantityToSubmit = quantityToSubmit * item.attributes[indexPiecesItem].value;
  //  }

  //  let indexPiecesSubstituteItem = suggestedItem.attributes.findIndex(at => at.sysName == 'pieces');
  //  if (indexPiecesSubstituteItem != -1 && suggestedItem.attributes[indexPiecesSubstituteItem].value) {
  //    quantityToSubmit = Math.ceil(quantityToSubmit / suggestedItem.attributes[indexPiecesSubstituteItem].value);
  //  }

  //  this.router.navigate(this.storeService.createUrl(['/product', suggestedItem.productModel]) as any[], {
  //    queryParams: {
  //      variant: suggestedItem.id,
  //      oldConfigId: this.lastRequestedConfigId,
  //      q: quantityToSubmit,
  //      returnTo: ReturnTo.list
  //    },
  //    state: {
  //      accessories: item.childItems
  //    }
  //  });
  //}
}
