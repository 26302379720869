import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService {
  private _userDetailSource: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly _userDetailObs: Observable<any> = this._userDetailSource.asObservable();

  private httpHeadersDefault: HttpHeaders;

  private identityUrl: string = environment.identityUrl;

  constructor(private httpClient: HttpClient) {
    this.httpHeadersDefault = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  loadUserData() {
    if (this._userDetailSource.value)
      return;
    this.httpClient.get<any>('/api/user/getUserDetail').subscribe(detail => {
      this._userDetailSource.next(detail);
    });
  }

  updatePreferences(userPref: any): void {
    this.httpClient.post<any>('/api/user/setUserDetail', userPref, {
      headers: this.httpHeadersDefault
    }).subscribe(detail => {
      this._userDetailSource.next(detail);
    });
  }

  updateListFavorites(favorites: any[]): void {
    this.httpClient.post<any>('/api/user/setUserFavorites', favorites, {
      headers: this.httpHeadersDefault
    }).subscribe(detail => {
      this._userDetailSource.next(detail);
    });
  }

  updateLogoFile(fileToUpload: File): void {
    const formData: FormData = new FormData();
    formData.append('logoFile', fileToUpload, fileToUpload.name);
    this.httpClient.post<any>('/api/user/setLogoUtente', formData).subscribe(detail => {
        this._userDetailSource.next(detail);
      });
  }

  deleteLogoFile(): void {
    this.httpClient.get<any>('/api/user/deleteLogoUtente').subscribe(detail => {
      this._userDetailSource.next(detail);
    });
  }

  updateLogoRetailFile(fileToUpload: File): void {
    const formData: FormData = new FormData();
    formData.append('logoRetailFile', fileToUpload, fileToUpload.name);
    this.httpClient.post<any>('/api/user/setLogoRetail', formData).subscribe(detail => {
        this._userDetailSource.next(detail);
      });
  }

  deleteLogoRetailFile(): void {
    this.httpClient.get<any>('/api/user/deleteLogoRetail').subscribe(detail => {
      this._userDetailSource.next(detail);
    });
  }


  recoverShopLockCode(): Observable<any> {
    return this.httpClient.get<any>('/api/user/recover-lockcode');
  }

  subscribeContract(contractName: string): any {
    return this.httpClient.post('/api/user/subscribe-contract', JSON.stringify(contractName),{
      headers: this.httpHeadersDefault
    });
  }

  getUserCheckPrizes(year): Observable<any[]> {
    return this.httpClient.get<any[]>(this.identityUrl + "/service/service/getCheckPrice?year=" + year);
  }

  getCompany(codCli): Observable<any> {
    let params = new HttpParams().set('codCli', codCli);
    return this.httpClient.get<any>(this.identityUrl + '/service/service/getCompany', { params: params });
  }

  exportExcelCheckPrize(translations: string[], debitString: string, creditString: string, language: string): Observable<any> {
    let exportModel = {
      translations: translations,
      credito: creditString,
      debito: debitString,
      language: language
    };

    const options = { headers: this.httpHeadersDefault, responseType: 'blob' as 'json' };
    return this.httpClient.post<any>(this.identityUrl + '/service/service/exportCheckPrize', exportModel,  options);
  }

  getAllCard(): Observable<any[]> {
    return this.httpClient.get<any[]>("/api/order/getAllCard");
  }

  deleteCard(idCard: string) : Observable<any> {
    var payload = {
      id: idCard
    };

    return this.httpClient.post<any>("/api/order/deleteCard", JSON.stringify(payload), { headers: this.httpHeadersDefault });
  }

  updateCard(card: any): Observable<any> {
    var payload = {
      id: card.id,
      ExpireMonth: card.expireMonth,
      ExpireYear: card.expireYear,
      holderCard: card.holderCard,
      zipCode: card.zipCode
    };

    return this.httpClient.post<any>("/api/order/updateCard", JSON.stringify(payload), { headers: this.httpHeadersDefault });
  }

  addCard(idPayment: string) {
    var payload = {
      paymentIntentId: idPayment
    };
    return this.httpClient.post<any>("/api/order/addCard", JSON.stringify(payload), { headers: this.httpHeadersDefault });
  }

  changePassword(newPassword: string, confirmPassword: string) {
    const data = {newPassword: newPassword, confirmPassword: confirmPassword};
    return this.httpClient.post<any>(this.identityUrl + "/service/service/changePassword", data);
  }

  getIvaCountry(id: string, type: string) : Observable<any> {
    if (type == "q") {
      let params = new HttpParams().set('id', id);
      return this.httpClient.get<any>("/api/user/getCountryForQuotation", { params: params });
    } else {
      return this.httpClient.get<any>("/api/user/getCountryForList");
    }
  }

  getMetodoPagamentoDefault() {
    return this.httpClient.get<any>("/api/user/getPagamentoPreferitoAndFidoResiduo");
  }

}
