import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CatalogService } from '../catalog.service';
import { intersection, cloneDeep, orderBy } from 'lodash';
import { ListService } from '../list.service';
import { MessageService } from 'primeng/api';
import { UserPreferenceService } from '../user-preference.service';
import { StoreService } from '../store.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'nav-menu-std',
  templateUrl: './nav-menu-std.component.html',
  styleUrls: ['./nav-menu-std.component.css'],
})
export class NavMenuStandardComponent implements OnInit, OnDestroy {
  hide: boolean = false;
  userdata: any = {};
  userPreference: any;

  shopMode: number = 0;
  unlockCode: string;
  wrongUnlockCode: boolean = false;

  text: string;

  dragging: boolean = false;
  favourites: any[] = [];

  applicationMenuStatus: string = "inactive";
  applications: any[];
  applicationIdToHide: string[];

  currentApplication: any;

  currentList: any = "Loading..";
  newList: string;

  categories: any[];
  currentCategory: any;
  currentSubCategory: any;
  currentFamily: any;

  filteredFamilies: any = {};
  allProductFamilies: any[] = [];

  @ViewChild('popperSelectProduct', { static: false }) popperSelectProduct: any;
  @ViewChild('popperListContent', { static: false }) popperListContent: any;
  @ViewChild('accountPopper', { static: false }) accountPopper: any;
  @ViewChild('popperUnlock', { static: false }) popperUnlock: any;

  translations: any = {};
  destroy$: Subject<boolean> = new Subject<boolean>();

  currentBrandId: string;

  constructor(private router: Router, private authService: AuthenticationService, private messageService: MessageService,
    private catalogService: CatalogService, private listService: ListService, private userService: UserPreferenceService,
    private storeService: StoreService) {
  }

  ngOnInit() {

    let translationRequired = marker(['requireModeBuy', 'codeSend', 'codeSendByMail', 'noCode', 'noCodeSet']);

    this.catalogService.getCurrentBrandId().pipe(takeUntil(this.destroy$)).subscribe(brandId => {
      this.currentBrandId = brandId;
    });

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe(data => this.translations = data);
    this.userdata = this.authService.getUserProfile();

    this.authService.userProfile$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.userdata = data;
    });
    this.userService._userDetailObs.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.userPreference = data;
      if (this.userPreference) {
        this.favourites = this.userPreference.favorites || [];
      }
    });

    this.catalogService.getCategories().pipe(takeUntil(this.destroy$)).subscribe(t => this.categories = t);
    this.catalogService.getApplications().pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.applications = t;
      this.applicationIdToHide = t.filter(a => a.hiddenCatalog).map(a => a.id);
    });
    this.catalogService.getProductFamilies().pipe(takeUntil(this.destroy$)).subscribe(x => this.allProductFamilies = cloneDeep(x));

    this.listService.getCurrentList().pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.currentList = t;
    });
    this.catalogService.getShopMode().pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.shopMode = t;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  logOut() {
    this.authService.logout().subscribe();
  }

  setShopMode(mode: number) {
    if (!this.userPreference) return;

    if (mode === 0 && this.userPreference.b2bShopLock) {
      this.popperUnlock.show();      
      return;
    }

    this.catalogService.setShopMode(mode);
  }

  onUnlockPopperShown() {
    this.wrongUnlockCode = false;
    this.unlockCode = undefined;
  }

  unlockShopMode() {
    if (!this.userPreference) return;
    if (this.unlockCode === this.userPreference.shopLockCode) {
      this.popperUnlock.hide();
      this.catalogService.setShopMode(0);
    } else {
      this.wrongUnlockCode = true;
    }
  }

  recoverCode() {
    this.userService.recoverShopLockCode().subscribe(t => {
      this.messageService.add({ severity: 'success', summary: this.translations["codeSend"], detail: this.translations["codeSendByMail"], life: 7000 });
      this.popperUnlock.hide();
    }, () => {
      this.messageService.add({ severity: 'error', summary: this.translations["noCode"], detail: this.translations["noCodeSet"], life: 7000 });
      this.popperUnlock.hide();
    });
  }

  selectCategory(cat) {
    this.currentCategory = cat;
    let childrenCategoriesId = cat.childrens.map(t => t.id);
    let manager = cloneDeep(this.allProductFamilies);
    //intersezione tra tutte le categorie figlie della categoria corrente e le categorie di una famiglia
    let filteredFamiliesLocal = manager.filter(f => intersection(f.categories, childrenCategoriesId).length > 0).map(t => {
      t.productsModel = t.productsModel.filter(x => childrenCategoriesId.indexOf(x.category) > -1);
      return t;
    });
    let alreadySet = false;
    this.filteredFamilies = {};
    for (let subCategory of cat.childrens) {

      this.filteredFamilies[subCategory.id] =
        filteredFamiliesLocal.filter(t => t.productsModel
          .filter(x => x.category === subCategory.id && !this.applicationIdToHide.some(id => id == x.application)).length > 0);

      if (!alreadySet && this.filteredFamilies[subCategory.id].length > 0) {
        this.selectFamily(this.filteredFamilies[subCategory.id][0], subCategory);
        alreadySet = true;
      }
    }
  }

  selectFamily(fam, subCat) {
    let famClone = cloneDeep(fam);
    famClone.productsModel = orderBy(famClone.productsModel
      .filter(x => x.category === subCat.id && !this.applicationIdToHide.some(id => id == x.application)),
      ['order', p => p.name.toLowerCase()], ["asc", "asc"]);
    this.currentFamily = famClone;
    this.currentSubCategory = subCat;
  }

  onCategoryMenuShown() {
    this.selectCategory(this.categories[0]);
  }

  onCategoryMenuHide() {
    this.currentCategory = null;
    this.currentFamily = null;
    this.currentSubCategory = null;
    this.filteredFamilies = {};
  }

  goToProduct(productId) {
    this.popperSelectProduct.hide();
    this.router.navigate(this.storeService.createUrl(['/product', productId]) as any[]);
  }

  toggleOrders() {
    this.catalogService.toggleOrderSidebar();
  }

  openApplicationMenu(application) {
    this.currentApplication = application;
    this.applicationMenuStatus = "active";
  }

  onApplicationMenuHide() {
    this.currentApplication = null;
  }

  viewLists(type: number) {
    this.popperListContent.hide();
    let destinationPage: string;
    if (type == 0) {
      destinationPage = "/lists";
      if (type == this.currentList.type) {

        this.router.navigate(this.storeService.createUrl([destinationPage, this.currentList.id]) as any[]);
      } else {
        this.router.navigate(this.storeService.createUrl([destinationPage]) as any[]);
      }
    } else {
      if (type == this.currentList.type) {
        this.router.navigate(this.storeService.createUrl(["/quotations", this.currentList.id]) as any[]);
      } else {
        this.router.navigate(this.storeService.createUrl(["/quotations/listQuotations"]) as any[]);
      }
    }


  }

  viewSingleListQuotation(list: any) {
    this.popperListContent.hide();
    if (!list) return;

    if (list.type == 0) {
      this.router.navigate(this.storeService.createUrl(["/lists", list.id]) as any[]);
    } else {
      this.router.navigate(this.storeService.createUrl(["/quotations", list.id]) as any[]);
    }
  }

  setCurrentList(list: any) {
    this.listService.setCurrentList(list);
    this.popperListContent.hide();
  }

  createList(type: number) {
    if (type == 0) {
      this.listService.createUserList(this.newList).subscribe(x => {
        this.newList = null;
        //this.userLists = x;
        this.popperListContent.hide();
      });
    } else {
      this.listService.createQuotationsList(this.newList).subscribe(x => {
        this.newList = null;
        //this.userLists = x;
        this.popperListContent.hide();
      });
    }

  }

  gotoList(listType) {
    this.accountPopper.hide();
    if (listType == 0)
      this.router.navigate(this.storeService.createUrl(["/lists"]) as any[]);
    else
      this.router.navigate(this.storeService.createUrl(["/quotations"]) as any[]);
  }

  onFavouriteDrop(event) {
    this.dragging = false;
    let data = { name: undefined, link: '#', brandId: undefined };
    switch (event.type) {
      case "category": {
        data.name = event.data.name;
        data.link = "/catalog/" + event.data.id;
        break;
      }
      case "family": {
        data.name = event.data.name;
        data.link = "/catalog/families/" + event.data.id;
        break;
      }
      default: {
        data.name = event.data.name;
        data.link = "/product/" + event.data.id;
        break;
      }
    }

    data.brandId = this.currentBrandId;
    if (this.favourites.findIndex(f => f.link == data.link) != -1) {
      return;
    }

    this.favourites.push(data);

    this.userService.updateListFavorites(this.favourites);
  }

  onDragStart(_event) {
    this.dragging = true;
  }

  onDragEnd(_event) {
    this.dragging = false;
  }

  goToFavourite(favourite) {
    this.catalogService.populateAllCache(favourite.brandId).subscribe(() => {
      this.router.navigate(this.storeService.createUrl([favourite.link]) as any[]);
    });
  }
}
