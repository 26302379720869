import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CheckoutService } from '../checkout.service';

@Injectable()
export class CheckOutPlainResolver  {
  constructor(private checkoutService: CheckoutService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    let id = route.paramMap.get('id');
    return this.checkoutService.obtainDataForCheckoutPlain(id).pipe(map(data => {
      this.checkoutService.fillItemDetailForPlainCheckout(data.list, data.detail);
      this.checkoutService.evaluateItemToSubstitute(data.list);

      return data.list;
    }));
  }
}
