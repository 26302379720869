import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { map, take } from 'rxjs/operators';
import { IsSuperAdmin } from './shared/functions';
import { AuthenticationService } from './services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService  {

  constructor(private httpClient: HttpClient, private authService: AuthenticationService) {
  }

  private typeCheckOut: string;
  private idObjectCheckOut: string;

  private infoQuotation: any;
  private infoListDetail: any;
  private infoVariantQuantity: any[];
  private infoAccessoryQuantity: any[];
  private itemListFlatted: any[];
  private adminLimit: any;

  private offSetConfigurationMeterTotal: number = 0;

  private configurationIds: string[];

  //se a true, vanno considerati i prezzi di vendita e non i prezzi di acquisto. Deve essere anche un admin a fare l'operazione
  private modalitaListino2: boolean;

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): any {

    this.typeCheckOut = route.data['type'];
    this.idObjectCheckOut = route.paramMap.get('id');
    return this.getInfoForCheckOut();
  }

  public isModalitaListino2(): boolean {
    return this.modalitaListino2;
  }

  public getIdListino(): number {
    return this.infoQuotation.idListino;
  }

  public getInfoForCheckOut(): Observable<any> {

    let obsData: Observable<any>;
    if (this.typeCheckOut == 'q') {
      obsData = this.httpClient.get<any>(`/api/list/${this.idObjectCheckOut}/get-quotation-details/`);
    } else {
      obsData = this.httpClient.get<any>(`/api/list/${this.idObjectCheckOut}/get-details/`);
    }

    return combineLatest([this.authService.isDoneLoading$.pipe(take(1)), obsData]).pipe(
      map(([_, data]) => {

        let superAdmin = IsSuperAdmin(this.authService.getUserProfile());

        this.modalitaListino2 = superAdmin && data.list.idListino == 2;
        let infoList = data.list;

        this.infoQuotation = {
          customer: infoList.customer,
          id: infoList.id,
          name: infoList.name,
          userId: infoList.userId,
          hideDiscount: infoList.hideDiscount,
          extraDiscount: infoList.extraDiscount,
          projectDiscount: infoList.projectDiscount,
          assignedCodCliFor: infoList.assignedCodCliFor,
          shareWithCompany: infoList.shareWithCompany,
          idListino: infoList.idListino,
          noteDaInviare: infoList.noteDaInviare
        };
        this.adminLimit = data.adminLimit;
        this.infoListDetail = data.detail;
        this.infoVariantQuantity = undefined;
        if (this.infoListDetail.variants) {
          this.infoVariantQuantity = this.createMappingQtaDisp(this.infoListDetail.variants);
        }
        this.infoAccessoryQuantity = undefined;
        if (this.infoListDetail.accessories) {
          this.infoAccessoryQuantity = this.infoListDetail.accessories.map(a => ({
            sku: a.sku,
            qtaDisponibile: a.qtaDisponibile
          }));

          for (let accessory of this.infoListDetail.accessories) {
            if (accessory.packs) {
              let infoAccPacksQuantity = accessory.packs.map(p => ({
                sku: p.sku,
                qtaDisponibile: p.qtaDisponibile * p.size
              }));
              this.infoAccessoryQuantity = this.infoAccessoryQuantity.concat(infoAccPacksQuantity);
            }
          }
        }

        this.configurationIds = [];
        this.itemListFlatted = [];
        let itemPack = [];
        let configurationMeter = [];
        for (let item of infoList.items) {
          if (item.childItems) {
            for (let cItem of item.childItems) {
              if (cItem.type == 1) {
                this.pushItemInItemList(this.itemListFlatted, cItem);

              } else if (cItem.type == 0) {
                let detail = this.infoListDetail.variants.find(v => v.sku == cItem.sku);
                if (this.isVariantPack(detail)) { //se è pack va in cima alla lista
                  this.pushItemInItemList(itemPack, cItem);
                } else {
                  let detailInnerItem = this.infoListDetail.variants.find(v => v.sku == cItem.sku);
                  let indexConfigurable = detailInnerItem.attributes.findIndex(a => a.sysName == 'configurable');
                  if (indexConfigurable != -1 && detail.um.toUpperCase() == "M") {
                    cItem.configurationId = item.configurationId;
                    this.pushItemInItemList(configurationMeter, cItem);
                  } else {
                    this.pushItemInItemList(this.itemListFlatted, cItem);
                  }
                }
              }
            }
          }
          item.childItems = undefined;
          if (item.type == 1) {
            this.pushItemInItemList(this.itemListFlatted, item);
          } else if (item.type == 0) {
            let detail = this.infoListDetail.variants.find(v => v.sku == item.sku);
            if (this.isVariantPack(detail)) { //se è pack va in cima alla lista
              this.pushItemInItemList(itemPack, item);
            } else {
              this.pushItemInItemList(this.itemListFlatted, item);
            }

          } else if (item.type == 3) {
            let objInList = this.itemListFlatted.find(it => it.configurationId == item.configurationId && it.type == 3);
            if (objInList) {
              objInList.quantity += item.quantity;
            } else {
              this.itemListFlatted.push(item);
            }
          } else {
            this.configurationIds.push(item.configurationId.replace("#", ""));
          }
        }

        configurationMeter = configurationMeter.sort((art1, art2) => {
          if (art1.quantity < art2.quantity) { //in cima gli articoli piu' piccoli
            return -1;
          }
          return 1;
        });
        this.itemListFlatted = itemPack.concat(configurationMeter).concat(this.itemListFlatted);

        let disableAnthology = false;
        let disableReserve = false;

        //if ((IsAgent(userdata) || IsSuperAdmin(userdata)) && this.infoQuotation.assignedCodCliFor && this.infoQuotation.userId == userdata.sub) {
        //  disableAnthology = true;
        //  disableReserve = true;
        //}

        return this.createCheckOutModel(disableAnthology, disableReserve);

      }));
  }

  public createCheckOutModel(disableAnthology: boolean, disableReserve: boolean, forcedItems: string[] = []): any {
    this.offSetConfigurationMeterTotal = 0;
    let cloneInfoVariantQuantity = undefined;
    if (this.infoVariantQuantity) {
      cloneInfoVariantQuantity = cloneDeep(this.infoVariantQuantity);
    }

    let cloneInfoAccessoryQuantity = undefined;
    if (this.infoAccessoryQuantity) {
      cloneInfoAccessoryQuantity = cloneDeep(this.infoAccessoryQuantity);
    }
    let cloneAdminParam = cloneDeep(this.adminLimit);

    let itemAnthology = [];
    let itemReserve = [];
    let itemOnDemand = [];

    for (let item of this.itemListFlatted) {
      item.forcedToProduction = forcedItems.indexOf(item.sku) > -1;
      if (item.type == 0) { //variante
        this.manageVariant(cloneDeep(item), this.infoListDetail.variants, cloneInfoVariantQuantity,
          itemAnthology, itemReserve, itemOnDemand, disableAnthology, disableReserve, cloneAdminParam);
      } else if (item.type == 3) {
        let itemCloned = cloneDeep(item);
        let obj = {
          entity: {
            image: item.custom.urlImg,
            prezzo: item.custom.prezzoRiservato, //il prezzo riservato del custom è il prezzo a cui viene venduto al cliente finale
            prezzoRiservato: item.custom.prezzoAcquisto,
            name: item.custom.name,
            unitaMisura: item.custom.unitaMisura
          },
          id: item.configurationId,
          sku: null,
          quantity: itemCloned.quantity,
          discount: 0,
          type: itemCloned.type
        };
        itemOnDemand.push(obj);

      } else { //accessory

        this.manageAccessory(cloneDeep(item), this.infoListDetail.accessories, cloneInfoAccessoryQuantity, itemAnthology, itemReserve, itemOnDemand,
          disableAnthology, disableReserve);
      }
    }
    let billingAddress = undefined;

    if (this.infoQuotation.customer) {
      billingAddress = this.infoQuotation.customer.billingAddress ? this.infoQuotation.customer.billingAddress : undefined;
    }

    itemAnthology = itemAnthology.sort((art1, art2) => {
      if (art1.type == art2.type) { //stesso tipo, ordino per sku
        if (art1.sku > art2.sku) {
          return 1;
        }
        return -1;
      } else {
        if (art1.type > art2.type) { //art1 e'accessory, ma art2 e' variant
          return -1;
        }
        return 1;
      }
    });

    itemReserve = itemReserve.sort((art1, art2) => {
      if (art1.type == art2.type) { //stesso tipo, ordino per sku
        if (art1.sku > art2.sku) {
          return 1;
        }
        return -1;
      } else {
        if (art1.type > art2.type) { //art1 e'accessory, ma art2 e' variant
          return -1;
        }
        return 1;
      }
    });

    itemOnDemand = itemOnDemand.sort((art1, art2) => {
      if (art1.type == art2.type) { //stesso tipo, ordino per sku
        if (art1.sku > art2.sku) {
          return 1;
        }
        return -1;
      } else {
        if (art1.type > art2.type) { //art1 e'accessory, ma art2 e' variant
          return -1;
        }
        return 1;
      }
    });

    let checkOutModel = {
      id: this.infoQuotation.id,
      userId: this.infoQuotation.userId,
      hideDiscount: this.infoQuotation.hideDiscount != undefined ? this.infoQuotation.hideDiscount : undefined,
      customerAddress: billingAddress,
      extraDiscount: this.infoQuotation.extraDiscount,
      projectDiscount: this.infoQuotation.projectDiscount != undefined ? this.infoQuotation.projectDiscount : undefined,
      assignedCodCliFor: this.infoQuotation.assignedCodCliFor != undefined ? this.infoQuotation.assignedCodCliFor : undefined,
      anthology: itemAnthology,
      reserve: itemReserve,
      onDemand: itemOnDemand,
      configurationIds: this.configurationIds,
      noteDaInviare: this.infoQuotation.noteDaInviare
    };
    return checkOutModel;
  }

  public updateAvailability(sku: string, qtaAvailable: number) {
    if (this.infoVariantQuantity) {
      let infoQta = this.infoVariantQuantity.find(info => info.sku == sku);
      if (infoQta) {
        infoQta.qtaDisponibile = qtaAvailable;
      }
    }

    if (this.infoAccessoryQuantity) {
      let infoQta = this.infoAccessoryQuantity.find(info => info.sku == sku);
      if (infoQta) {
        infoQta.qtaDisponibile = qtaAvailable;
      }
    }
  }

  public updateAdminRemainMeter(newRemainMeter: number): boolean {
    if (this.adminLimit.remainingMeter == newRemainMeter) {
      return false;
    }
    this.adminLimit.remainingMeter = newRemainMeter;
    return true;
  }

  private manageVariant(item: any, infoListDetail: any[], infoVariantQuantity: any[],
    itemAnthology: any[], itemReserve: any[], itemOnDemand: any[],
    disableAnthology: boolean, disableReserve: boolean, limitAdmin: any) {

    let type = 0;

    let detail = infoListDetail.find(v => v.sku == item.sku);
    let isConfigurable: boolean = false;
    //anthologyBehavior e' un valore che arriva dal server, ma non e' nel db
    //identifica tutti gli elementi che possono finire nel carrello riserva, anziche' produzione se non hanno disponibilita'
    let isAnthologyBehavior: boolean = detail.attributes.findIndex(a => a.sysName == 'anthologyBehavior') != -1;

    let indexConfigurable = detail.attributes.findIndex(a => a.sysName == 'configurable');
    if (indexConfigurable != -1) {
      isConfigurable = detail.attributes[indexConfigurable].value == true;
    }

    if ((disableAnthology && disableReserve) || item.forcedToProduction) {

      if (item.forceToReserve) {
        item.forceToReserve = false;
      }

      this.pushItemInList(itemOnDemand, item, detail, type);
      return;
    }
    
    if (isConfigurable && detail.um == 'M') {
      if (disableAnthology) { //se e' disabilito anthology metto tutto in produzione
        item.forceToReserve = false;
        this.pushItemInList(itemOnDemand, item, detail, type);
      } else {
        if (limitAdmin.remainingMeter > 0) {

          if ((item.quantity - limitAdmin.tolerance) <= limitAdmin.remainingMeter) {
            limitAdmin.remainingMeter = Math.max(limitAdmin.remainingMeter - item.quantity, 0);
            item.forceToReserve = false;
            this.pushItemInList(itemAnthology, item, detail, type); //in questo caso item forzato in anthology
          } else {
            item.forceToReserve = true;
            this.offSetConfigurationMeterTotal += item.quantity;
            item.amountOffset = this.offSetConfigurationMeterTotal;
            item.meterPerDay = this.adminLimit.meterMaxWorkablePerDay;

            this.pushItemInList(disableReserve ? itemOnDemand : itemReserve,
              item, detail, type);//va in reserve se per oggi superato limite
          }

        } else {
          item.forceToReserve = true;
          this.offSetConfigurationMeterTotal += item.quantity;
          item.amountOffset = this.offSetConfigurationMeterTotal;
          item.meterPerDay = this.adminLimit.meterMaxWorkablePerDay;
          this.pushItemInList(disableReserve ? itemOnDemand : itemReserve,
            item, detail, type);//va in reserve se per oggi superato limite
        }
      }
      return;
    }

    //se sono qua almeno uno dei due carrelli e' disponibile e isConfigurable e' false
    if (disableAnthology) { //e' abilitato il reserve
      this.pushItemInList(isAnthologyBehavior ? itemReserve : itemOnDemand, item, detail, type);
      return;
    }
    let infoQta = infoVariantQuantity.find(info => info.sku == item.sku);

    if (infoQta && infoQta.qtaDisponibile >= item.quantity) {
      this.pushItemInList(itemAnthology, item, detail, type);
      infoQta.qtaDisponibile -= item.quantity;
    } else {
      if (infoQta && infoQta.qtaDisponibile > 0) {
        let itemClone = cloneDeep(item);
        itemClone.quantity = infoQta.qtaDisponibile;
        infoQta.qtaDisponibile -= itemClone.quantity; //azzero la quantita' disponibile
        this.pushItemInList(itemAnthology, itemClone, detail, type);
        item.quantity -= itemClone.quantity; //sottraggo la quantita' messa nel carrello
      }
      if (disableReserve || !isAnthologyBehavior) {
        this.pushItemInList(itemOnDemand, item, detail, type);
      } else {
        this.pushItemInList(itemReserve, item, detail, type);
      }
    }
  }

  private manageAccessory(item: any, infoListDetail: any[], infoAccessoriesQuantity: any[],
    itemAnthology: any[], itemReserve: any[], itemOnDemand: any[], disableAnthology: boolean, disableReserve: boolean) {

    let type = 1;

    let detail = infoListDetail.find(a => a.sku == item.sku);
    let isPack = false;

    if (!detail) { //potrebbe essere pack
      let detailBaseAcc = infoListDetail.filter(a => a.packs).find(a => a.packs.some(p => p.sku == item.sku));
      if (detailBaseAcc) {
        detail = detailBaseAcc.packs.find(p => p.sku == item.sku);
        detail.image = detailBaseAcc.image;
        detail.id = detailBaseAcc.id;
        detail.idBrand = detailBaseAcc.idBrand;
        detail.attributes = detailBaseAcc.attributes;
        isPack = true;
      }
    }
    if (detail) {

      let isConfigurable: boolean = false;
      //anthologyBehavior e' un valore che arriva dal server, ma non e' nel db
      //identifica tutti gli elementi che possono finire nel carrello riserva
      let isAnthologyBehavior: boolean = detail.attributes.findIndex(a => a.sysName == 'anthologyBehavior') != -1;

      let indexConfigurable = detail.attributes.findIndex(a => a.sysName == 'configurable');
      if (indexConfigurable != -1) {
        isConfigurable = detail.attributes[indexConfigurable].value == true;
      }
      if ((disableAnthology && disableReserve) || item.forcedToProduction) {
        this.pushItemInList(itemOnDemand, item, detail, type);
        return;
      }

      if (isConfigurable) {
        if (disableAnthology) { //se e' disabilito anthology metto tutto in produzione
          this.pushItemInList(itemOnDemand, item, detail, type);
        } else {
          this.pushItemInList(itemAnthology, item, detail, type); //in questo caso item forzato in anthology
        }
        return;
      }

      //se sono qua almeno uno dei due carrelli e' disponibile e isConfigurable e' false
      if(disableAnthology) {
        this.pushItemInList(isAnthologyBehavior ? itemReserve : itemOnDemand, item, detail, type);
        return;
      }

      let infoQta = infoAccessoriesQuantity.find(info => info.sku == detail.sku);

      if (isPack) { //e' un pack
        let numberPackAvailableInAnthology = infoQta.qtaDisponibile;
        if (numberPackAvailableInAnthology >= item.quantity) {
          this.pushItemInList(itemAnthology, item, detail, type);
          infoQta.qtaDisponibile -= item.quantity;
        } else {
          if (numberPackAvailableInAnthology > 0) {
            let itemClone = cloneDeep(item);
            itemClone.quantity = numberPackAvailableInAnthology;
            infoQta.qtaDisponibile -= itemClone.quantity;
            this.pushItemInList(itemAnthology, itemClone, detail, type);
            item.quantity -= itemClone.quantity;
          }
          if (disableReserve || !isAnthologyBehavior) {
            this.pushItemInList(itemOnDemand, item, detail, type);
            return;
          }
          this.pushItemInList(itemReserve, item, detail, type);
        }
      } else { //non e' un pack
        if (infoQta.qtaDisponibile >= item.quantity) {
          this.pushItemInList(itemAnthology, item, detail, type);
          infoQta.qtaDisponibile -= item.quantity;
        } else {
          if (infoQta.qtaDisponibile > 0) {
            let itemClone = cloneDeep(item);
            itemClone.quantity = infoQta.qtaDisponibile;
            infoQta.qtaDisponibile -= itemClone.quantity;

            this.pushItemInList(itemAnthology, itemClone, detail, type);
            item.quantity -= itemClone.quantity;
          }
          if (disableReserve || !isAnthologyBehavior) {
            this.pushItemInList(itemOnDemand, item, detail, type);
            return;
          }
          this.pushItemInList(itemReserve, item, detail, type);
        }
      }
    }
  }

  private pushItemInItemList(list: any[], item: any): void {
    if (item.configurationId) {
      list.push(item);
      return;
    }

    let objInList = list.find(it => it.sku == item.sku && it.discount == item.discount 
      && it.discount2 == item.discount2 && it.discount3 == item.discount3);
    if (objInList) {
      objInList.quantity += item.quantity;
    } else {
      list.push(item);
    }
  }

  private pushItemInList(list: any[], item: any, detail: any, type: number) {
    if (!detail.prezzoRiservato) {
      return;
    }
    let indexConfigurable = detail.attributes.findIndex(a => a.sysName == 'configurable');
    //se configurable non aggrego _mai_

    let indexLeadTime = detail.attributes.findIndex(a => a.sysName == 'lead-time');
    let settimaneConsegnaSeDemand = 3
    if(indexLeadTime != -1) {
      settimaneConsegnaSeDemand = detail.attributes[indexLeadTime].value > 3 ? detail.attributes[indexLeadTime].value : 3;
    }
    if (indexConfigurable != -1) {
      let obj = {
        entity: detail,
        id: detail.id,
        sku: detail.sku,
        quantity: item.quantity,
        discount: item.discount,
        discount2: item.discount2,
        discount3: item.discount3,
        configurationId: item.configurationId,
        configForceToReserve: item.forceToReserve,
        type: type,
        amountOffset: item.amountOffset,
        meterPerDay: item.meterPerDay,
        leadTime : settimaneConsegnaSeDemand
      };
      list.push(obj);
      return;
    }
    let indexObj = list.findIndex(a => a.sku == detail.sku
      && a.type == type
      && a.discount == item.discount && a.discount2 == item.discount2 && a.discount3 == item.discount3);

    if (indexObj == -1) {
      let obj = {
        entity: detail,
        id: detail.id,
        sku: detail.sku,
        quantity: item.quantity,
        discount: item.discount,
        discount2: item.discount2,
        discount3: item.discount3,
        configurationId: item.configurationId,
        configForceToReserve: item.forceToReserve,
        type: type,
        amountOffset: item.amountOffset,
        meterPerDay: item.meterPerDay,
        leadTime : settimaneConsegnaSeDemand
      };
      list.push(obj);
    } else {
      list[indexObj].quantity += item.quantity;
    }
  }

  private isVariantPack(info: any): number {

    if (info.attributes) {
      let attribute = info.attributes.find(a => a.sysName == "pieces");
      if (attribute) {
        let packUnit = attribute.value;
        if (packUnit > 1) return packUnit;
      }
    }
    return undefined;
  }

  private createMappingQtaDisp(infoDetailVariant: any[]): any[] {
    let skuQuantityDuplicate = infoDetailVariant.map(function (detail) {
      return {
        sku: detail.sku,
        productModel: detail.productModel,
        qtaDisponibile: detail.qtaDisponibile
      };
    });

    return skuQuantityDuplicate;
  }

  public getTotalItemList(items: any[]): number {
    let result: number;
    //gli item custom sono sempre venduti a prezzo riservato, ma qui e' irrilevante perche' il discount e' sempre a zero
    if (this.modalitaListino2) {
      result = items.map(c => 
        { 
          let inversoDiscount = (1 - (c.discount || 0) / 100) * (1 - (c.discount2 || 0) / 100) * (1 - (c.discount3 || 0) / 100);
          return inversoDiscount * c.entity.prezzo * c.quantity 
        }
      ).reduce((sum, current) => sum + current, 0);

    } else {
      result = items.map(c => c.entity.prezzoRiservato * c.quantity).reduce((sum, current) => sum + current, 0);
    }
    return result;
  }

  getPartialProjDiscount(items: any[], discount: number): number {
    if (!items || items.length == 0 || !discount || discount <= 0) return 0;

    let itemsValid = items.filter(t => t.entity.prezzo && t.type != 3 && !t.entity.anthologyStar && !t.entity.mxStar);
    let totalForProject: number;

    if (this.modalitaListino2) {
      totalForProject = itemsValid.map(c => { 

        let inversoDiscount = (1 - (c.discount || 0) / 100) * (1 - (c.discount2 || 0) / 100) * (1 - (c.discount3 || 0) / 100);
        return inversoDiscount * c.entity.prezzo * c.quantity
        
        
      }).reduce((sum, current) => sum + current, 0);
    } else {
      totalForProject = itemsValid.map(c => c.entity.prezzoRiservato ? c.entity.prezzoRiservato * c.quantity : c.entity.prezzo * c.quantity)
        .reduce((sum, current) => sum + current, 0);
    }

    return totalForProject * discount / 100;
  }

  fillItemDetailForPlainCheckout(list: any, details: any) {
    for (let item of list.items) {

      switch (item.type) {
        case 0: {
          const detail = details.variants.find(t => t.id == item.referenceId);

          if (!detail) {
            item.esWarning = true;
            break;
          }

          item.entity = detail;
          item.idBrand = detail.idBrand;
          item.productModel = detail.productModel;
          item.image = detail.images ? detail.images[0] : null;
          item.name = detail.esolverName ? detail.esolverName : "";
          item.prezzo = detail.prezzo;
          item.description = detail.esolverDescription;
          item.prezzoRiservato = detail.prezzoRiservato;
          item.availability = detail.qtaDisponibile;
          item.anthology = detail.anthology;
          item.anthologyStar = detail.anthologyStar;
          item.mxStar = detail.mxStar;
          item.categoryName = detail.categoryName;
          item.um = detail.um;
          item.sku = detail.sku;
          item.attributes = detail.attributes; //per gestire quantita' pack con gli item suggeriti
          if (item.childItems) {
            for (let cItem of item.childItems) {
              let cdetail = details.accessories.find(t => t.id == cItem.referenceId && t.sku == cItem.sku);

              if (!cdetail) { //a causa della presenza di accessory anthology  con pack non anthology lato server puo' essere generato un modello con i soli pack non anthology
                cdetail = details.accessories.filter(a => a.packs).find(a => a.packs.some(p => p.sku == cItem.sku))
              }

              if (!cdetail) {
                item.esWarning = cItem.esWarning = true;
                continue;
              }
              cItem.entity = cdetail;
              cItem.idBrand = cdetail.idBrand;
              cItem.attributes = cdetail.attributes;
              if (cItem.sku == cdetail.sku) {

                cItem.image = cdetail.image;
                cItem.name = cdetail.esolverName ? cdetail.esolverName : "";
                cItem.prezzo = cdetail.prezzo;
                cItem.prezzoRiservato = cdetail.prezzoRiservato;
                cItem.availability = cdetail.qtaDisponibile;
                cItem.anthology = cdetail.anthology;
                cItem.anthologyStar = cdetail.anthologyStar;
                cItem.mxStar = cdetail.mxStar;
                cItem.um = cdetail.um;
              } else {
                if (!cdetail.packs || cdetail.packs.length == 0) {
                  item.esWarning = cItem.esWarning = true;
                  continue;
                }

                //si tratta di un pack
                const pack = cdetail.packs.find(t => t.sku == cItem.sku);
                if (!pack) {
                  item.esWarning = cItem.esWarning = true;
                  continue;
                }

                cItem.image = cdetail.image;
                cItem.name = pack.esolverName ? pack.esolverName : "";
                cItem.prezzo = pack.prezzo;
                cItem.prezzoRiservato = pack.prezzoRiservato;
                cItem.availability = pack.qtaDisponibile;
                cItem.anthology = pack.anthology;
                cItem.anthologyStar = pack.anthologyStar;
                cItem.mxStar = pack.mxStar;
                cItem.um = pack.um;
              }
            }
          }
          break;
        }
        case 1: {

          let detail = details.accessories.find(t => t.id == item.referenceId && t.sku == item.sku);

          if (!detail) { //a causa della presenza di accessory anthology  con pack non anthology lato server puo' essere generato un modello con i soli pack non anthology
            detail = details.accessories.filter(a => a.packs).find(a => a.packs.some(p => p.sku == item.sku))
          }
          if (!detail) {
            item.esWarning = true;
            break;
          }
          item.entity = detail;
          item.idBrand = detail.idBrand;
          item.attributes = detail.attributes; 
          if (item.sku == detail.sku) {
            item.image = detail.image;
            item.name = detail.esolverName ? detail.esolverName : "";
            item.prezzo = detail.prezzo;
            item.description = detail.esolverDescription;
            item.prezzoRiservato = detail.prezzoRiservato;
            item.availability = detail.qtaDisponibile;
            item.anthology = detail.anthology;
            item.anthologyStar = detail.anthologyStar;
            item.mxStar = detail.mxStar;
            item.um = detail.um;
          } else {
            if (!detail.packs || detail.packs.length == 0) {
              item.esWarning = true;
              break;
            }

            //si tratta di un pack
            const pack = detail.packs.find(t => t.sku == item.sku);
            if (!pack) {
              item.esWarning = true;
              break;
            }

            item.image = detail.image;
            item.name = pack.esolverName ? pack.esolverName : "";
            item.prezzo = pack.prezzo;
            item.prezzoRiservato = pack.prezzoRiservato;
            item.availability = pack.qtaDisponibile;
            item.anthologyStar = pack.anthologyStar;
            item.mxStar = pack.mxStar;
            item.anthology = pack.anthology;
            item.um = pack.um;
            item.size = pack.size;
          }
          break;
        }
        case 2: {
          // wizard product
          const product = details.products.find(t => t.id == item.referenceId);
          item.productModel = product.id;
          item.image = product.images ? product.images[0] : null;
          item.name = product.name;
          item.description = product.description;
          item.idBrand = product.idBrand;
          if (item.childItems) {
            for (let cItem of item.childItems) {
              if (cItem.type == 1) { //accessorio
                let cdetail = details.accessories.find(t => t.id == cItem.referenceId && t.sku == cItem.sku);

                if (!cdetail) { //a causa della presenza di accessory anthology  con pack non anthology lato server puo' essere generato un modello con i soli pack non anthology
                  cdetail = details.accessories.filter(a => a.packs).find(a => a.packs.some(p => p.sku == cItem.sku))
                }

                if (!cdetail) {
                  item.esWarning = cItem.esWarning = true;
                  continue;
                }

                cItem.entity = cdetail;
                cItem.idBrand = cdetail.idBrand;
                cItem.attributes = cdetail.attributes;
                if (cItem.sku == cdetail.sku) {
                  cItem.image = cdetail.image;
                  cItem.name = cdetail.esolverName ? cdetail.esolverName : "";
                  cItem.prezzo = cdetail.prezzo;
                  cItem.prezzoRiservato = cdetail.prezzoRiservato;
                  cItem.availability = cdetail.qtaDisponibile;
                  cItem.anthology = cdetail.anthology;
                  cItem.anthologyStar = cdetail.anthologyStar;
                  cItem.mxStar = cdetail.mxStar;
                  cItem.um = cdetail.um;
                } else {

                  if (!cdetail.packs || cdetail.packs.length == 0) {
                    item.esWarning = cItem.esWarning = true;
                    continue;
                  }

                  const pack = cdetail.packs.find(t => t.sku == cItem.sku);
                  if (!pack) {
                    item.esWarning = cItem.esWarning = true;
                    continue;
                  }
                  cItem.image = cdetail.image;
                  cItem.name = pack.esolverName ? pack.esolverName : "";
                  cItem.prezzo = pack.prezzo;
                  cItem.prezzoRiservato = pack.prezzoRiservato;
                  cItem.availability = pack.qtaDisponibile;
                  cItem.anthology = pack.anthology;
                  cItem.anthologyStar = pack.anthologyStar;
                  cItem.mxStar = pack.mxStar;
                  cItem.um = pack.um;
                }
              } else {
                const cdetail = details.variants.find(t => t.id == cItem.referenceId);

                if (!cdetail) {
                  cItem.esWarning = true;
                  continue;
                }
                cItem.entity = cdetail;

                cItem.idBrand = cdetail.idBrand;
                cItem.productModel = cdetail.productModel;
                cItem.image = cdetail.images ? cdetail.images[0] : null;
                cItem.name = cdetail.esolverName ? cdetail.esolverName : "";
                cItem.prezzo = cdetail.prezzo;
                cItem.description = cdetail.esolverDescription;
                cItem.prezzoRiservato = cdetail.prezzoRiservato;
                cItem.availability = cdetail.qtaDisponibile;
                cItem.anthology = cdetail.anthology;
                cItem.anthologyStar = cdetail.anthologyStar;
                cItem.mxStar = cdetail.mxStar;
                cItem.sku = cdetail.sku;
                cItem.um = cdetail.um;

                cItem.categoryName = cdetail.categoryName;

                if (!item.categoryName) {
                  item.categoryName = cdetail.categoryName;
                }
              }
            }
            let counterPrezzo = 0;
            let counterPrezzoRiservato = 0;
            let allOk = true;
            for (let cItem of item.childItems) {
              counterPrezzo += cItem.prezzo * cItem.quantity;
              counterPrezzoRiservato += cItem.prezzoRiservato * cItem.quantity;
              allOk = allOk && !cItem.esWarning;
            }
            item.prezzo = allOk ? counterPrezzo : 0;
            item.prezzoRiservato = allOk ? counterPrezzoRiservato : 0;
          }
          break;
        }
        case 3: {
          item.prezzoRiservato = item.custom.prezzoAcquisto;
          //item.prezzoVendita = item.custom.prezzoVendita;
          break;
        }
        default: {
          //nothing here
          break;
        }
      }
    }
  }

  calculateProjectDiscountForPlainCheckout(list: any): number {
    if (!list.projectDiscount) return 0;

    let priceForDiscount: number = 0;
    for (let item of list.items.filter(t => t.type != 3 && !t.anthologyStar && !t.mxStar)) {
      if (item.prezzoRiservato) {
        if (this.modalitaListino2) {
          let inversoDiscount = (1 - (item.discount || 0) / 100) * (1 - (item.discount2 || 0) / 100) * (1 - (item.discount3 || 0) / 100);
          if (item.type == 2) {
            priceForDiscount += (item.prezzo * inversoDiscount);
          } else {
            priceForDiscount += (item.prezzo * inversoDiscount * item.quantity);
          }
        } else {
          if (item.type == 2) {
            priceForDiscount += item.prezzoRiservato;
          } else {
            priceForDiscount += (item.prezzoRiservato * item.quantity);
          }
        }
      }
      if (item.childItems && item.type != 2) {
        for (let cItem of item.childItems.filter(t => !t.anthologyStar && !t.mxStar)) {
          if (cItem.prezzo) {
            if (this.modalitaListino2) {
              let inversoDiscountChild = (1 - (cItem.discount || 0) / 100) * (1 - (cItem.discount2 || 0) / 100) * (1 - (cItem.discount3 || 0) / 100);
              priceForDiscount += (cItem.prezzo * inversoDiscountChild * cItem.quantity);
            } else {
              priceForDiscount += (cItem.prezzoRiservato * cItem.quantity);
            }
          }
        }
      }
    }
    
    return priceForDiscount * list.projectDiscount / 100;
  }

  calculateTotalListBeforeDiscountsForPlainCheckout(list: any): number {
    if (!list.items) return 0;

    let totalList: number = 0;
    for (let item of list.items) {
      if (this.modalitaListino2) {
        let inversoDiscount = (1 - (item.discount || 0) / 100) * (1 - (item.discount2 || 0) / 100) * (1 - (item.discount3 || 0) / 100);
        if (item.type == 3) {

          totalList += (item.custom.prezzoVendita * inversoDiscount * item.quantity);
        } else if (item.type != 2) {
          totalList += (item.prezzo * inversoDiscount * item.quantity);
        }
      } else {
        if (item.type == 3) { //gli item custom sono venduti a prezzo riservato
          totalList += (item.custom.prezzoAcquisto * item.quantity);
        } else if (item.type != 2) {
          totalList += (item.prezzoRiservato * item.quantity);
        }
      }

      if (item.childItems)
        for (let cItem of item.childItems) {
          if (cItem.prezzo) {
            if (this.modalitaListino2) {
              let inversoDiscountChild = (1 - (cItem.discount || 0) / 100) * (1 - (cItem.discount2 || 0) / 100) * (1 - (cItem.discount3 || 0) / 100);
              totalList += (cItem.prezzo * inversoDiscountChild * cItem.quantity);
            } else {
              totalList += (cItem.prezzoRiservato * cItem.quantity);
            }
          }
        }
    }
    return totalList;
  }

  evaluateItemToSubstitute(list: any) {
    //array di oggetti fatti cosi' key : {sku, availability, articles[]: { configurationId, quantity }
    //solo varianti
    let skuQuantita: any[] = [];

    //prima viene creata una mappa dove vengono raggruppate le varianti richieste nel preventivo per sku
    for (let item of list.items) {
      item.suggestSubstitute = undefined;
      if (item.type == 0) {
        let indexMain = skuQuantita.findIndex(s => s.sku == item.sku);
        if (indexMain != -1) {
          skuQuantita[indexMain].articles.push({
            configurationId: item.configurationId,
            quantity: item.quantity
          });
        } else {
          let objSkuQuantita = {
            sku: item.sku,
            availability: item.availability,
            articles: []
          }
          objSkuQuantita.articles.push({
            configurationId: item.configurationId,
            quantity: item.quantity
          });
          skuQuantita.push(objSkuQuantita);
        }
      }

      if (item.childItems) {
        for (let cItem of item.childItems) {
          if (cItem.type == 0) {
            let indexChild = skuQuantita.findIndex(s => s.sku == cItem.sku);
            if (indexChild != -1) {
              skuQuantita[indexChild].articles.push({
                configurationId: "", //qui e' configurazione. non puo' essere mai candidato a sostituzione
                quantity: cItem.quantity
              });
            } else {
              let objSkuQuantita = {
                sku: cItem.sku,
                availability: cItem.availability,
                articles: []
              }
              objSkuQuantita.articles.push({
                configurationId: "", //oggetti child non possono essere candidati a sostituzione
                quantity: cItem.quantity
              });
              skuQuantita.push(objSkuQuantita);
            }
          }
        }
      }
    }

    for (let skuQ of skuQuantita) {
      let totalRequest = skuQ.articles.map(a => a.quantity)
        .reduce((sum, current) => sum + current, 0);

      if (totalRequest <= skuQ.availability) continue; //quantita disponibile suff non faccio altro

      //ordinamento. Prima varianti richieste in configurazione, poi in base a quantita' richiesta.
      skuQ.articles.sort((art1, art2) => {
        if (!art1.configurationId) return -1; //fa parte di una configurazione
        if (art1.quantity >= art2.quantity) {
          return -1;
        }
        return 1;
      });

      for (let art of skuQ.articles) {
        if (skuQ.availability >= art.quantity) { //se quantita' richiesta da singolo articolo e' sufficiente, sottraggo da disponibilita'
          skuQ.availability -= art.quantity;
        } else if (art.configurationId) { //se non e' sufficiente lo segno come "sostituibile" se e solo se non e' variante in configurazione
          let indexItem = list.items.findIndex(it => it.configurationId == art.configurationId);
          if (indexItem != -1) {
            list.items[indexItem].suggestSubstitute = true;
          }
        }
      }
    }
  }

  getSubstitute(configId: string, quotationId: string, tipoLista: number) {
    let params = new HttpParams().set("configId", configId).set("listId", quotationId).set("tipoLista", tipoLista.toString());

    return this.httpClient.get<any[]>("/api/product/get-substitutes", { params: params });
  }

  obtainDataForCheckoutPlain(id: string): Observable<any> {
    return combineLatest([this.authService.isDoneLoading$.pipe(take(1)),
    this.httpClient.get<any>(`/api/list/${id}/get-quotation-details/`)]).pipe(
      map(([_, data]) => {

        let superAdmin = IsSuperAdmin(this.authService.getUserProfile());

        this.adminLimit = data.adminLimit;
        let infoList = data.list;
        this.infoQuotation = {
          customer: infoList.customer,
          id: infoList.id,
          name: infoList.name,
          userId: infoList.userId,
          hideDiscount: infoList.hideDiscount,
          extraDiscount: infoList.extraDiscount,
          projectDiscount: infoList.projectDiscount,
          assignedCodCliFor: infoList.assignedCodCliFor,
          shareWithCompany: infoList.shareWithCompany,
          idListino: infoList.idListino,
          noteDaInviare: infoList.noteDaInviare
        };
        this.modalitaListino2 = superAdmin && data.list.idListino == 2;
        return data
      }));
  }

  //metodi chiamati da opportunity
  getTotal(checkoutModel: any, shopMode: number = 0): number {
    let partialAmount = this.getPartialAmount(checkoutModel.anthology, shopMode) +
      this.getPartialAmount(checkoutModel.reserve, shopMode) +
      this.getPartialAmount(checkoutModel.onDemand, shopMode);

    if (shopMode === 1) {
      partialAmount = partialAmount - ((partialAmount * checkoutModel.extraDiscount / 100));
    }

    return partialAmount;
  }

  manageAvailabilityVariantForPlainCheckout(item: any) : boolean {
    if (this.adminLimit.remainingMeter > 0) {
      if ((item.quantity - this.adminLimit.tolerance) <= this.adminLimit.remainingMeter) {
        this.adminLimit.remainingMeter = Math.max(this.adminLimit.remainingMeter - item.quantity, 0);
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  //chiamata solo dal metodo getTotal
  private getPartialAmount(items, shopMode = 0): number {
    let result = 0;
    if (shopMode == 0) {
      result = items.filter(t => t.entity.prezzo).map(c => c.entity.prezzoRiservato ? c.entity.prezzoRiservato * c.quantity : c.entity.prezzo * c.quantity)
        .reduce((sum, current) => sum + current, 0);
    } else {
      result = items.filter(t => t.entity.prezzo).map(c => { 
        let inversoDiscount = (1 - c.discount / 100) * (1 - c.discount2 / 100) * (1 - c.discount3 / 100);
        return inversoDiscount * c.entity.prezzo * c.quantity; })
        .reduce((sum, current) => sum + current, 0);
    }
    return result;
  }

  public getProjectDiscountValue(checkoutModel: any): number {
    if (!checkoutModel.projectDiscount) return 0;

    let partialAmount = this.getPartialAmountForProjDiscount(checkoutModel.anthology) +
      this.getPartialAmountForProjDiscount(checkoutModel.reserve) +
      this.getPartialAmountForProjDiscount(checkoutModel.onDemand);


    return partialAmount * checkoutModel.projectDiscount / 100;
  }

  //chiamato solo da getProjectDiscountValue
  private getPartialAmountForProjDiscount(items): number {
    let result = items.filter(t => t.entity.prezzo && t.type != 3 && !t.entity.anthologyStar && !t.mxStar)
      .map(c => c.entity.prezzoRiservato ? c.entity.prezzoRiservato * c.quantity : c.entity.prezzo * c.quantity)
      .reduce((sum, current) => sum + current, 0);

    return result;
  }
  //fine regione chiamati da opportunity
}
