import {Component, OnInit} from '@angular/core';
import {CatalogService} from "../catalog.service";
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {

  claims: any;
  shopMode: number;
  isViewer: boolean = false;

  constructor(private authService: AuthenticationService, private catalogService: CatalogService) {
    
  }

  ngOnInit() {
    this.catalogService.getShopMode().subscribe(t => this.shopMode = t);
    this.claims = this.authService.getUserProfile();
    if(this.claims) {
      this.isViewer = this.claims.view_only && this.claims.view_only == "true";
    }
    this.authService.userProfile$.subscribe(t => {
      this.claims = t;
      this.isViewer = this.claims.view_only && this.claims.view_only == "true";
    });
  }

}
