import { Component, Input } from '@angular/core';

@Component({
  selector: 'total-checkout',
  templateUrl: './total-checkout.component.html',
  styleUrls: ['./total-checkout.component.css']
})
/** total-checkout component*/
export class TotalCheckoutComponent {

  @Input()
  listTotalReserved: number;
  @Input()
  projectDiscountValue: number = 0;
  @Input()
  extraSconto: number = 0;
  @Input()
  checkPrizeApplied: number = 0;
  @Input()
  shippingTotal: number = 0;
  @Input()
  customerDiscountApplied: string = "";

  /** total-checkout ctor */
  constructor() {

  }
}
