import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'project-approved',
	templateUrl: 'project-approved.component.html',
	styleUrls: ['project-approved.component.scss']
})

export class ProjectApprovedComponent implements OnInit {

	discount: number;
	nameProject: string;

	constructor(private activatedRoute: ActivatedRoute) {

	}

	ngOnInit(): void {
		this.discount = this.activatedRoute.snapshot.params['discount'];
		this.nameProject = this.activatedRoute.snapshot.params['nameproject'];
	}
}