import { Component, OnInit, OnDestroy, ViewChild, ElementRef, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { CheckoutService } from '../checkout.service';
import { cloneDeep, union, pull, round, maxBy } from 'lodash';
import { OrderService } from '../order.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { StoreService } from '../store.service';
import { debounceTime, distinctUntilChanged, filter, mergeMap, skip, take, takeUntil, tap } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from '../../environments/environment';
import { UserPreferenceService } from '../user-preference.service';
import { ServiceMoment } from '../factory-moment.service';
import { IsSuperAdmin } from '../shared/functions';
import { Iban, Bank, BicSwift } from '../shared/costants';
import { QuotationService } from '../quotation.service';
import { collection } from 'firebase/firestore';
import { AuthenticationService } from '../services/authentication.service';
import { collectionData, docData, Firestore } from '@angular/fire/firestore';

@Component({
  selector: 'checkout-3-carrelli',
  templateUrl: './checkout-3-carrelli.component.html',
  styleUrls: ['./checkout-3-carrelli.component.css'],
})
export class Checkout3CarrelliComponent implements OnInit, OnDestroy {

  b2bPaymentAllowed: boolean = false;
  b2bEnables: boolean[];
  companyInfo: any;

  //b2bEnable: boolean = true;

  //TODO parametrizzare su server
  shippingCostCad: number = 50;
  shippingCostThreshold: number = 500;
  shipDateRequired: boolean = false;
  shipDate: Date;
  maxShipDate: Date;
  minShipDate: Date;

  userdata: any;

  cartCount: number;

  cartDetail: any;
  countryCart: any;
  cartType: string;

  anthologyTotal: number;
  reserveTotal: number;
  onDemandTotal: number;
  listTotalReserved: number;

  anthologyProjDiscount: number;
  reserveProjDiscount: number;
  onDemandProjDiscount: number;

  projectDiscountValue: number;
  extraSconto: number = 0;

  hideCheckboxCart1: boolean = false;
  hideCheckboxCart2: boolean = false;

  disableChart1: boolean = false;
  disableChart2: boolean = false;

  shipModeMultiple: boolean = false;
  shippingMode1: number = 1;
  shippingMode2: number = 1;
  shippingMode3: number = 1;

  shippingCost1: number = 0;
  shippingCost2: number = 0;
  shippingCost3: number = 0;
  shippingTotal: number = 0;
  shipCount: number = 0;
  pickupCount: number = 0;

  shipAddressMultiple: boolean = false;
  shipAddress1: any = {};
  shipAddress2: any = {};
  shipAddress3: any = {};
  disableShippingAddress: boolean = false;
  shippingInvalid: boolean = true;


  paymentModeMultiple: boolean = false;
  paypalSelect: boolean = false;

  //4 e' payapal, 3 e' bonifico bancario, 2 e' carta di credito e 1 e' dilazione di pagamento
  paymentMethods: number[]; 

  checkPrize: number = 0;
  checkPrizeApplied: number = 0;
  checkPrizeThreshold: { [id: string]: number; } = {
    "2": 334,
    "3": 334,
    "4": 556,
    "5": 556,
    "9": 0
  };

  checkPrizeDisableText: string;
  rifOrdine: string;
  orderSending: boolean = false;
  onlyExWork: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  translations: any = {};
  forcedItems: any[] = [];

  @ViewChild('containerCreditCard', { static: true })
  containerCreditCard: ElementRef;  

  cardElement: any;
  stripe: any;
  elements: any;

  creditCards: any[];
  crediCardSelectId: string;

  intestatarioCarta: string;

  insertNewCard: boolean;

  totalCard: number;

  displayInsufficientFund: boolean;
  displayNotBothPayPalCreditCard: boolean;

  bank = Bank;
  iban = Iban;
  swift = BicSwift;

  holydays = [];

  customer: any;
  customerDiscountApplied: string;

  minNumeroSettimaneForProduction: number = 3;
  hideCourierChoiceByCustomer: boolean = false;

  private _performReCalculateInfoCheckOut$ : Subject<boolean> = new Subject<boolean>();
  private performReCalculateInfoCheckOut$ : Observable<boolean> = this._performReCalculateInfoCheckOut$.asObservable().pipe(
    debounceTime(500), tap(() => this.reCalculateInfoCheckOut()), takeUntil(this.destroy$));

    firestore: Firestore = inject(Firestore);

  constructor(private route: ActivatedRoute, private messageService: MessageService, private quotationService : QuotationService,
    private router: Router, private confirmationService: ConfirmationService, private userPreferenceService: UserPreferenceService,
    private orderService: OrderService, private checkOutService: CheckoutService, private serviceMoment: ServiceMoment,
    private authService: AuthenticationService, private storeService: StoreService) {

    this.paymentMethods = [];
    this.b2bEnables =  [];
    for (let i = 0; i < 3; ++i) {
      this.paymentMethods.push(3);
      this.b2bEnables.push(false);
    }
  }

  ngOnInit() {
    this.performReCalculateInfoCheckOut$.subscribe();

    paypal.Buttons({
      // Sets up the transaction when a payment button is clicked
      createOrder: (_data, actions) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: this.getPaypalAmount() // Can also reference a variable or function
            }
          }]
        });  
      },
      // Finalize the transaction after payer approval
      onApprove: (_data, actions) => {
        return actions.order.capture().then((orderData) => {
         
          const transaction = orderData.purchase_units[0].payments.captures[0];
          this.confirmSendMailifAdmin(transaction.id);
           //console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
          //alert(`Transaction ${transaction.status}: ${transaction.id}\n\nSee console for all available details`);
          
        });
      }
    }).render('#paypal-button');

    let translationRequired = marker(['toQuotation', 'toList', 'clickDataToModify', 'quotation', 'list', 'proceed', 'cancel', 'warningAllUpper', 'cardRefusedOrNotValid',
      'confirmOrder', 'errorSendOrderRetry', 'noCheckPrizeDiscountProject', 'nocheckPrizeAmountTooLow', 'courier', 'courierCustomer', 'verifyCreditCard',
      'ourMagazine', 'b2bCustomerEntrusted', 'creditCard', 'bankTransfer', 'mustProvideHeader', 'headerConfirmOrder', 'changePaymentModeFromB2b',
      'headerAskadminSendMail', 'askadminSendMail', 'yes', 'no', 'paypalPayment' ]);

    this.stripe = Stripe(environment.stripePublicKey);
    this.elements = this.stripe.elements({
      locale: this.storeService.translateService.currentLang
    });

    this.companyInfo = this.route.snapshot.data['company'];

    // if(!environment.production) {
    //   this.companyInfo.fido = 4000;
    //   this.companyInfo.clienteAffidato = true;
    // }
   
    this.cartType = this.route.snapshot.data['type'];
   
    this.countryCart = this.route.snapshot.data['infoCheckout'][0];
    if (this.countryCart.codStato.toLowerCase() == "it") {
      this.hideCourierChoiceByCustomer = true;
    } else {
      this.hideCourierChoiceByCustomer = false;
    }
    this.holydays = this.route.snapshot.data['infoCheckout'][1];
    this.cartDetail = this.route.snapshot.data['cartDetail'];

    let codCli : string;
    if(this.cartType == "l") {
      codCli = this.authService.getUserProfile().codCli
    } else {
      codCli = this.cartDetail.assignedCodCliFor;
    }
    this.quotationService.getCompany(codCli).subscribe((c) => {
      this.customer = c;
      if(this.checkOutService.isModalitaListino2()) {
        this.customerDiscountApplied = "";
      } else {
        this.customerDiscountApplied = this.customer.desScontoListino;
      }
    });
    

    this.userdata = this.authService.getUserProfile();

    this.b2bPaymentAllowed = this.companyInfo.clienteAffidato;

    this.shipDate = this.serviceMoment.addDayBusiness(12).toDate();
    this.minShipDate = this.serviceMoment.addDayBusiness(12).toDate();
    this.maxShipDate = this.serviceMoment.addDayBusiness(90).toDate();

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      //QUA e solo qua NON si puo' assegnare data a translations (chiavi non coincidono)
      this.translations["clickDataToModify"] = data.clickDataToModify;
      this.translations["toQuotation"] = data.toQuotation;
      this.translations["toList"] = data.toList;
      this.translations["quotation"] = data.quotation;
      this.translations["list"] = data.list;
      this.translations["proceed"] = data.proceed;
      this.translations["cancel"] = data.cancel;
      this.translations["confirmOrder"] = data.confirmOrder;
      this.translations["errorSendOrderRetry"] = data.errorSendOrderRetry;
      this.translations["noCheckPrizeDiscountProject"] = data.noCheckPrizeDiscountProject;
      this.translations["nocheckPrizeAmountTooLow"] = data.nocheckPrizeAmountTooLow;
      this.translations["ship1"] = data.courier;
      this.translations["ship2"] = data.courierCustomer;
      this.translations["ship3"] = data.ourMagazine;
      this.translations["pay1"] = data.b2bCustomerEntrusted;
      this.translations["pay2"] = data.creditCard;
      this.translations["pay3"] = data.bankTransfer;
      this.translations["pay4"] = data.paypalPayment;
      this.translations["mustProvideHeader"] = data.mustProvideHeader;
      this.translations["headerConfirmOrder"] = data.headerConfirmOrder;
      this.translations["areAyouSureDeleteCard"] = data.areAyouSureDeleteCard;
      this.translations["warningAllUpper"] = data.warningAllUpper;
      this.translations["areAyouSureDeleteCard"] = data.areAyouSureDeleteCard;
      this.translations["verifyCreditCard"] = data.verifyCreditCard;
      this.translations["cardRefusedOrNotValid"] = data.cardRefusedOrNotValid;
      this.translations["changePaymentModeFromB2b"] = data.changePaymentModeFromB2b;
      this.translations["headerAskadminSendMail"] = data.headerAskadminSendMail;
      this.translations["askadminSendMail"] = data.askadminSendMail;
      this.translations["yes"] = data.yes;
      this.translations["no"] = data.no;
    });

    this.cardElement = this.elements.create('card', {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: "#363636",
          color: "#363636",
          fontWeight: 400,
          fontFamily: "'Work Sans', sans-serif",
          fontSize: "14px",
          fontSmoothing: "antialiased",

          "::placeholder": {
            color: "#666"
          },
          ":-webkit-autofill": {
            color: "#666"
          }
        },
        invalid: {
          iconColor: "#ff3860",
          color: "#ff3860"
        }
      }
    });

    this.cardElement.mount('#formCreditCard3Cart');

    this.authService.userProfile$.pipe(takeUntil(this.destroy$)).subscribe((t) => {
      this.userdata = t;
      this.ManageChartsAndPayment();
    });

    const itemCollection = collection(this.firestore, 'adminParameter');

    collectionData(itemCollection).pipe(filter((doc) => doc?.length == 0), take(1), mergeMap((doc) => {
      let docInfo = doc[0] as any;
      return docData<any>(docInfo);
    }), distinctUntilChanged()).subscribe((value) => {
      this.updateMeterWorkable(value.remainingMeter);

    });
  }

  private ManageChartsAndPayment() {

    if (this.companyInfo.shipPlan) {
      if (this.companyInfo.shipPlan == 9) {
        this.onlyExWork = true;
        this.disableShippingAddress = true;
        this.shippingMode1 = this.shippingMode2 = this.shippingMode3 = 3;
        this.shippingInvalid = false;

      } else if (this.companyInfo.shipPlan == 4 || this.companyInfo.shipPlan == 5) {
        this.shippingCostThreshold = 800;
      }
    }

    this.performReCalculateInfoCheckOut$.pipe(take(1)).subscribe(() => {

      this.projectDiscountValue = this.anthologyProjDiscount + this.reserveProjDiscount + this.onDemandProjDiscount;
      if(this.checkOutService.isModalitaListino2()) {
        this.extraSconto = this.cartDetail.extraDiscount * this.listTotalReserved / 100;
      }

      if (this.cartDetail.projectDiscount && this.cartDetail.projectDiscount > 0) {
        this.checkPrizeDisableText = this.translations["noCheckPrizeDiscountProject"];
      } else if (this.checkPrizeThreshold[this.companyInfo.shipPlan] > this.listTotalReserved) {
        this.checkPrizeDisableText = this.translations["nocheckPrizeAmountTooLow"];
      } else {
        this.checkPrizeDisableText = "";
      }
  
      if (this.userdata.checkPrize > 0) {
        this.checkPrize = Math.min(this.listTotalReserved * 0.1, this.userdata.checkPrize);
      }
      //if ((IsAgent(this.userdata) || IsSuperAdmin(this.userdata)) && (this.cartDetail.assignedCodCliFor && this.cartDetail.userId == this.userdata.sub)) {
      //  this.hideCheckboxCart1 = this.hideCheckboxCart2 = true;
  
      //  this.disableChart1 = this.disableChart2 = true;
      //} else {
        if (!this.cartDetail.anthology || this.cartDetail.anthology.length == 0) {
          this.hideCheckboxCart1 = true;
          this.disableChart1 = false;
        }
        if (!this.cartDetail.reserve || this.cartDetail.reserve.length == 0) {
          this.hideCheckboxCart2 = true;
          this.disableChart2 = false;
        }
      //}
    });

    
    this._performReCalculateInfoCheckOut$.next(true);
    //this.reCalculateInfoCheckOut();
    
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private reCalculateInfoCheckOut() {

    this.hideCheckboxCart1 = !this.disableChart1 && (!this.cartDetail.anthology || this.cartDetail.anthology.length == 0);
    this.hideCheckboxCart2 = !this.disableChart2 && (!this.cartDetail.reserve || this.cartDetail.reserve.length == 0);
    
    this.anthologyTotal = this.checkOutService.getTotalItemList(this.cartDetail.anthology);
    this.reserveTotal = this.checkOutService.getTotalItemList(this.cartDetail.reserve);
    this.onDemandTotal = this.checkOutService.getTotalItemList(this.cartDetail.onDemand);
    this.listTotalReserved = this.anthologyTotal + this.reserveTotal + this.onDemandTotal;

    this.calculateShippingTotal();

    this.anthologyProjDiscount = this.checkOutService.getPartialProjDiscount(this.cartDetail.anthology, this.cartDetail.projectDiscount);
    this.reserveProjDiscount = this.checkOutService.getPartialProjDiscount(this.cartDetail.reserve, this.cartDetail.projectDiscount);
    this.onDemandProjDiscount = this.checkOutService.getPartialProjDiscount(this.cartDetail.onDemand, this.cartDetail.projectDiscount);

    this.countCarts();
    this.calculateTotalCreditCardOrPaypal();
    this.calculateTotalForAffidatario();

    if(this.cartDetail.onDemand && this.cartDetail.onDemand.length) {
      this.minNumeroSettimaneForProduction = maxBy(this.cartDetail.onDemand as any[], 'leadTime').leadTime;
    }
    if(this.minNumeroSettimaneForProduction < 3) { 
      this.minNumeroSettimaneForProduction = 3;
    }
    this.minShipDate = this.serviceMoment.addDayBusiness(this.minNumeroSettimaneForProduction * 5).toDate();
    this.shipDate = this.serviceMoment.addDayBusiness(this.minNumeroSettimaneForProduction * 5).toDate();
  }

  disableCart() {
    this.cartDetail = this.checkOutService.createCheckOutModel(this.disableChart1, this.disableChart2);
    this.reCalculateInfoCheckOut();
  }

  shipDateToggle() {

  }

  applyCheckPrize() {
    this.checkPrizeApplied = this.checkPrize;
    this.calculateTotalCreditCardOrPaypal();
    this.calculateTotalForAffidatario();
  }

  calculateShippingTotal() {
    this.shippingTotal = 0;
    this.shipCount = 0;
    this.pickupCount = 0;
    this.shippingCost1 = 0;
    this.shippingCost2 = 0;
    this.shippingCost3 = 0;

    if (this.cartDetail.anthology.length > 0) {
      if (this.shipModeMultiple) {
        if (this.shippingMode1 != 3) {
          this.shippingCost1 = this.shippingMode1 == 1 && this.anthologyTotal < this.shippingCostThreshold ? this.shippingCostCad : 0;
          this.shipCount++;
        } else {
          this.pickupCount++;
        }
      } else {
        if (this.shippingMode3 != 3) {
          this.shippingCost1 = this.shippingMode3 == 1 && this.anthologyTotal < this.shippingCostThreshold ? this.shippingCostCad : 0;
          this.shipCount++;
        } else {
          this.pickupCount++;
        }
      }
      this.shippingTotal = this.shippingTotal + this.shippingCost1;
    }

    if (this.cartDetail.reserve.length > 0) {
      if (this.shipModeMultiple) {
        if (this.shippingMode2 != 3) {
          this.shippingCost2 = this.shippingMode2 == 1 && this.reserveTotal < this.shippingCostThreshold ? this.shippingCostCad : 0;
          this.shipCount++;
        } else {
          this.pickupCount++;
        }
      } else {
        if (this.shippingMode3 != 3) {
          this.shippingCost2 = this.shippingMode3 == 1 && this.reserveTotal < this.shippingCostThreshold ? this.shippingCostCad : 0;
          this.shipCount++;
        } else {
          this.pickupCount++;
        }
      }
      this.shippingTotal = this.shippingTotal + this.shippingCost2;
    }

    if (this.cartDetail.onDemand.length > 0) {
      if (this.shippingMode3 != 3) {
        this.shippingCost3 = this.shippingMode3 == 1 && this.onDemandTotal < this.shippingCostThreshold ? this.shippingCostCad : 0;
        this.shipCount++;
      } else {
        this.pickupCount++;
      }
      this.shippingTotal = this.shippingTotal + this.shippingCost3;
    }
  }

  sendOrder() {

    this.confirmationService.confirm({
      key: 'confirmOrder',
      header: this.translations["headerConfirmOrder"],
      message: this.translations["confirmOrder"],
      acceptLabel: this.translations["proceed"],
      rejectLabel: this.translations["cancel"],
      accept: () => {

        if (this.paymentMethods.some(p => p == 2)) {

          this.orderSending = true;
          if (this.crediCardSelectId) {
            this.sendCreditCardPayment(this.crediCardSelectId);
          } else {
            if (!this.intestatarioCarta) {
              this.messageService.add({ key: 'toastErrorCreditCard', severity: 'error', summary: this.translations["error"], detail: this.translations.mustProvideHeader });
              return;
            }
            this.stripe.createPaymentMethod('card', this.cardElement, {
              billing_details: { name: this.intestatarioCarta },
            }).then((result) => {
              if (result.error) {
                this.messageService.add({ key: 'toastErrorCreditCard', severity: 'error', summary: this.translations["error"], detail: this.translations.verifyCreditCard });
                this.orderSending = false;
                return;
                // Show error in payment form
              } else {
                //calcolo totali per carta di credito
                this.sendCreditCardPayment(result.paymentMethod.id);
              }
            });
          }


        } else {
          this.confirmSendMailifAdmin();
        }
      }
    });
  }

  private calculateTotalCreditCardOrPaypal() {
    this.totalCard = 0;
    if (this.paymentModeMultiple) {
      if ((this.paymentMethods[0] == 2 || this.paymentMethods[0] == 4) && this.anthologyTotal) {
        if (this.checkPrizeApplied) {
          this.totalCard += (this.anthologyTotal - (this.anthologyTotal * this.checkPrizeApplied )/ this.listTotalReserved);
        } else if (this.anthologyProjDiscount) {
          this.totalCard += this.anthologyTotal - this.anthologyProjDiscount;
        } else {
          this.totalCard += this.anthologyTotal;
        }
        if (this.shippingCost1) {
          this.totalCard += this.shippingCost1;
        }
      }
      if ((this.paymentMethods[1] == 2 || this.paymentMethods[1] == 4) && this.reserveTotal) {
        if (this.checkPrizeApplied) {
          this.totalCard += (this.reserveTotal - (this.reserveTotal * this.checkPrizeApplied) / this.listTotalReserved);
        } else if (this.reserveProjDiscount) {
          this.totalCard += this.reserveTotal - this.reserveProjDiscount;
        } else {
          this.totalCard += this.reserveTotal;
        }
        if (this.shippingCost2) {
          this.totalCard += this.shippingCost2;
        }
      }
      if ((this.paymentMethods[2] == 2 || this.paymentMethods[2] == 4) && this.onDemandTotal) {
        if (this.checkPrizeApplied) {
          this.totalCard += (this.onDemandTotal - (this.onDemandTotal * this.checkPrizeApplied) / this.listTotalReserved);
        } else if (this.onDemandProjDiscount) {
          this.totalCard += this.onDemandTotal - this.onDemandProjDiscount;
        } else {
          this.totalCard += this.onDemandTotal;
        }
        if (this.shippingCost3) {
          this.totalCard += this.shippingCost3;
        }
      }
      

    } else {
      if ((this.paymentMethods[2] != 2 && this.paymentMethods[2] != 4)) return;
      if (this.anthologyTotal) {
        if (this.anthologyProjDiscount) {
          this.totalCard += this.anthologyTotal - this.anthologyProjDiscount;
        } else {
          this.totalCard += this.anthologyTotal;
        }
      }
      if (this.reserveTotal) {
        if (this.reserveProjDiscount) {
          this.totalCard += this.reserveTotal - this.reserveProjDiscount;
        } else {
          this.totalCard += this.reserveTotal;
        }
      }
      if (this.onDemandTotal) {
        if (this.onDemandProjDiscount) {
          this.totalCard += this.onDemandTotal - this.onDemandProjDiscount;
        } else {
          this.totalCard += this.onDemandTotal;
        }
      }
      if (this.checkPrizeApplied) {
        this.totalCard -= this.checkPrizeApplied;
      }
      if (this.shippingCost1) {
        this.totalCard += this.shippingCost1;
      }
      if (this.shippingCost2) {
        this.totalCard += this.shippingCost2;
      }
      if (this.shippingCost3) {
        this.totalCard += this.shippingCost3;
      }
    }
    //il totale della carta di credito/paypal e' con IVA
    if (this.countryCart.codStato.toLowerCase() == "it") {
      this.totalCard *= 1.22;
    }
  }

  //se isReset e' a true il metodo riorganizza per mettere piu' valori possibili in anthology
  private calculateTotalForAffidatario(isReset = true, showAlert: boolean = true) { 
    if (!this.b2bPaymentAllowed) return;
    let fidoAvailable = this.companyInfo.fido;
    let totalAnthology = 0;
    let totalReserve = 0;
    let totalProduction = 0;
    if (this.paymentModeMultiple) {

      if (this.anthologyTotal) {
        if (this.checkPrizeApplied) {
          //calcolo il check prize ripartendolo per il totale del carrello anthology
          totalAnthology += (this.anthologyTotal - (this.anthologyTotal * this.checkPrizeApplied) / this.listTotalReserved);
        } else if (this.anthologyProjDiscount) {
          totalAnthology += this.anthologyTotal - this.anthologyProjDiscount;
        } else {
          totalAnthology += this.anthologyTotal;
        }
      }
      if (this.reserveTotal) {
        if (this.checkPrizeApplied) {
          totalReserve += (this.reserveTotal - (this.reserveTotal * this.checkPrizeApplied) / this.listTotalReserved);
        } else if (this.reserveProjDiscount) {
          totalReserve += this.reserveTotal - this.reserveProjDiscount;
        } else {
          totalReserve += this.reserveTotal;
        }
      }
      if (this.onDemandTotal) {
        if (this.checkPrizeApplied) {
          totalProduction += (this.onDemandTotal - (this.onDemandTotal * this.checkPrizeApplied) / this.listTotalReserved);
        } else if (this.onDemandProjDiscount) {
          totalProduction += this.onDemandTotal - this.onDemandProjDiscount;
        } else {
          totalProduction+= this.onDemandTotal;
        }
      }

      if (isReset) { //sto riassegnando
        let mustShowAlert = false;
        if (totalAnthology <= fidoAvailable) {
          fidoAvailable -= totalAnthology;
          this.b2bEnables[0] = true;
          this.paymentMethods[0] = 1;
        } else {
          this.b2bEnables[0] = false;
          if(this.paymentMethods[0] != 3) {
            this.paymentMethods[0] = 3;
            mustShowAlert = true;
          }
          
        }

        if (totalReserve <= fidoAvailable) {
          fidoAvailable -= totalReserve;
          this.b2bEnables[1] = true;
          this.paymentMethods[1] = 1;
        } else {
          this.b2bEnables[1] = false;

          if(this.paymentMethods[1] != 3) {
            this.paymentMethods[1] = 3;
            mustShowAlert = true;
          }
        }

        if (totalProduction <= fidoAvailable) {
          fidoAvailable -= totalProduction;
          this.b2bEnables[2] = true;
          this.paymentMethods[2] = 1;
        } else {
          this.b2bEnables[2] = false;

          if(this.paymentMethods[2] != 3) {
            this.paymentMethods[2] = 3;
            mustShowAlert = true;
          }
        }
        this.displayInsufficientFund = mustShowAlert && showAlert;
      }
      else { //devo valutare solo abilitazione
        if (this.paymentMethods[0] == 1) {
          fidoAvailable -= totalAnthology;
        }
        if (this.paymentMethods[1] == 1) {
          fidoAvailable -= totalReserve;
        }
        if (this.paymentMethods[2] == 1) {
          fidoAvailable -= totalProduction;
        }
        if (this.paymentMethods[0] != 1) {
          this.b2bEnables[0] = fidoAvailable >= totalAnthology;
        }
        if (this.paymentMethods[1] != 1) {
          this.b2bEnables[1] = fidoAvailable >= totalReserve;
        }
        if (this.paymentMethods[2] != 1) {
          this.b2bEnables[2] = fidoAvailable >= totalProduction;
        }
      }

    } else {
      if (this.anthologyTotal) {
        if (this.anthologyProjDiscount) {
          totalAnthology += this.anthologyTotal - this.anthologyProjDiscount;
        } else {
          totalAnthology += this.anthologyTotal;
        }
      }
      if (this.reserveTotal) {
        if (this.reserveProjDiscount) {
          totalReserve += this.reserveTotal - this.reserveProjDiscount;
        } else {
          totalReserve += this.reserveTotal;
        }
      }
      if (this.onDemandTotal) {
        if (this.onDemandProjDiscount) {
          totalProduction += this.onDemandTotal - this.onDemandProjDiscount;
        } else {
          totalProduction += this.onDemandTotal;
        }
      }
      let totalSingle = totalAnthology + totalReserve + totalProduction;

      if (this.checkPrizeApplied) {
        totalSingle -= this.checkPrizeApplied;
      }
     
      if (isReset) { //sto riassegnando
        if (totalSingle <= fidoAvailable) {
          this.b2bEnables[2] = true;
          this.paymentMethods[2] = 1;
          this.displayInsufficientFund = false;
        } else {
          this.b2bEnables[2] = false;
          this.paymentMethods[2] = 3;
          this.displayInsufficientFund = true && showAlert;
        }
      } else {
        
        this.b2bEnables[2] = totalSingle <= fidoAvailable;
      }
    }
  }

  private sendCreditCardPayment(paymentId: string) {
    this.calculateTotalCreditCardOrPaypal();

    this.orderService.generatePayment(paymentId, this.totalCard).subscribe((result: any) => {
      this.manageResponseStripeFromServer(result);
      if (!this.userdata.customerIdStripe) {
        //qua fare reload profile
      }

    }, (error: HttpErrorResponse) => {
        this.messageService.add({ key: 'toastErrorCreditCard', severity: 'error', summary: this.translations["error"], detail: error.error });
      this.orderSending = false;
    });
  }

  private manageResponseStripeFromServer(result: any) {

    if (result.requiresAction) {
      this.stripe.handleCardAction(result.paymentIntentClientSecret,
        this.cardElement,
        {
          save_payment_method: true,
        }).then((result) => {
        if (result.error) {
          this.messageService.add({ key: 'toastErrorCreditCard', severity: 'error', summary: this.translations["error"], detail: result.error });
          this.orderSending = false;
        } else {
          this.orderService.confirmPayment(result.paymentIntent.id).subscribe((result: any) => {
            this.manageResponseStripeFromServer(result); //chiamata ricorsiva. possono essere richieste verifiche in successione o altro
          }, () => {
              this.messageService.add({ key: 'toastErrorCreditCard', severity: 'error', summary: this.translations["error"], detail: this.translations.cardRefusedOrNotValid  });
            this.orderSending = false;
          });
        }
      }).catch(() => {
        this.messageService.add({ key: 'toastErrorCreditCard', severity: 'error', summary: this.translations["error"], detail: this.translations.cardRefusedOrNotValid });
      });
    } else {
      this.confirmSendMailifAdmin();
    }
  }

  countCarts() {
    let result = 0;
    if (!this.disableChart1 && this.cartDetail.anthology.length > 0)
      result++;
    if (!this.disableChart2 && this.cartDetail.reserve.length > 0)
      result++;
    if (this.cartDetail.onDemand.length > 0)
      result++;

    this.cartCount = result;
  }

  singleShippingModeChange() {
    if (this.shipModeMultiple == false) {
      this.disableShippingAddress = this.shippingMode3 == 3;


    } else {
      this.disableShippingAddress = this.cartDetail.onDemand.length == 0 || this.shippingMode3 == 3;
      this.disableShippingAddress = this.disableShippingAddress && (this.cartDetail.reserve.length == 0 || this.shippingMode2 == 3);
      this.disableShippingAddress = this.disableShippingAddress && (this.cartDetail.anthology.length == 0 || this.shippingMode1 == 3);
    }
    this.calculateShippingTotal();
    this.calculateTotalCreditCardOrPaypal();
    this.updateEnableButtonOrder();
  }

  updateEnableButtonOrder() {
    if (this.disableShippingAddress) {
      this.shippingInvalid = false;
      return;
    }

    if ((this.shipAddressMultiple && (
      (this.cartDetail.onDemand.length > 0 && this.nonValidShipAddress(this.shipAddress3, this.shippingMode3)) ||
      (this.cartDetail.reserve.length > 0 && this.nonValidShipAddress(this.shipAddress2, this.shippingMode2)) ||
      (this.cartDetail.anthology.length > 0 && this.nonValidShipAddress(this.shipAddress1, this.shippingMode1)))) ||
      (!this.shipAddressMultiple && this.nonValidShipAddress(this.shipAddress3))) {

      this.shippingInvalid = true;

    } else {
      this.shippingInvalid = false;
    }
  }

  nonValidShipAddress(shipAddress: any, shipMode: any = null): boolean {
    if (shipMode && shipMode == 3)
      return false;

    return !shipAddress.company || !shipAddress.address1 || !shipAddress.countryCode || !shipAddress.zipCode || !shipAddress.city1;
  }

  updateCheckOut(event: any = undefined) {
    if (event.type == 'qtyChange')
      this.checkOutService.updateAvailability(event.sku, event.qtaAvailable);
    else {
      this.forcedItems = event.forcedToProduction ? union(this.forcedItems, [event.sku]) : pull(this.forcedItems, [event.sku]);
    }
    this.cartDetail = this.checkOutService.createCheckOutModel(this.disableChart1, this.disableChart2, this.forcedItems);
    this._performReCalculateInfoCheckOut$.next(true);
  }

  updateMeterWorkable(availability: number) {
    if(this.checkOutService.updateAdminRemainMeter(availability)) {
      this.cartDetail = this.checkOutService.createCheckOutModel(this.disableChart1, this.disableChart2, this.forcedItems);
      this._performReCalculateInfoCheckOut$.next(true);
    }
  }

  reset() {
    this.forcedItems = [];
    this.cartDetail = this.checkOutService.createCheckOutModel(this.disableChart1, this.disableChart2, this.forcedItems);
    this.reCalculateInfoCheckOut();
  }

  //viene chiamato ogni volta che si interagisce con i metodi di pagamento per ricalcolare i vari totali o altre opzioni
  changeMethodPayment(event, paymentMethodSelect: number, showAlertSelectB2b: boolean = true) {
    this.calculateTotalCreditCardOrPaypal();
    this.calculateTotalForAffidatario(paymentMethodSelect == -1, showAlertSelectB2b);

    //se il pagamento standard b2b e' attivo, non ha semplicemente attivato i pagamenti multipli e ha selezionato il bonifico bancario
    //allora chiedo conferma del cambio
    if (this.b2bPaymentAllowed && event == 3 && paymentMethodSelect != -1 && this.paymentMethods[paymentMethodSelect] != 1
      && this.b2bEnables[paymentMethodSelect]) {
      this.confirmationService.confirm({
        key: 'confirmChangeModePay',
        header: this.translations["warningAllUpper"],
        message: this.translations["changePaymentModeFromB2b"],
        acceptLabel: this.translations["proceed"],
        rejectLabel: this.translations["cancel"],
        accept: () => {

        },
        reject: () => {
          this.paymentMethods[paymentMethodSelect] = 1;
          this.changeMethodPayment(event, paymentMethodSelect, false);
        }
      });
    }

    let usedCreditCard = false;
    //mostra o meno il contenitore delle carte di credito se almeno un radio button e' selezionato
    if ((this.paymentModeMultiple && this.paymentMethods.some(p => p == 2)) || this.paymentMethods[2] == 2) {

      usedCreditCard = true;
      if (this.containerCreditCard.nativeElement.classList.contains("is-hidden")) {

        this.containerCreditCard.nativeElement.classList.remove("is-hidden");
        this.userPreferenceService.getAllCard().subscribe(data => {
          this.creditCards = data;
          if (this.creditCards && this.creditCards.length > 0) {
            this.crediCardSelectId = this.creditCards[0].id;
          } else {
            this.insertNewCard = true;
          }
        });
      }
    } else {
      this.containerCreditCard.nativeElement.classList.add("is-hidden");
    }

    
    //mostra o meno la sezione paypal
    if ((this.paymentModeMultiple && this.paymentMethods.some(p => p == 4)) || this.paymentMethods[2] == 4) {
      //puo' succedere con opzioni pagamento multiplo
      if(usedCreditCard) {
        let index = this.paymentMethods.findIndex(p => p == 4);
        setTimeout(() => {
          this.paymentMethods[index] = 2;
        }, 10);
       
        //check se c'e' un altro paypal
        index = this.paymentMethods.findIndex(p => p == 4);
        if(index != -1) {
          setTimeout(() => {
            this.paymentMethods[index] = 2;
          }, 10);
        }
        this.paypalSelect = false;
        this.displayNotBothPayPalCreditCard = true;
      } else if (!this.paypalSelect) {
        this.paypalSelect = true;
      }
    } else {
      this.paypalSelect = false;
    }
  }

  private sendOrderToServer(disableSendMailCustomer: boolean, idTrasactionPaypal: string = undefined) {
    this.orderSending = true;
    let cartDetail = cloneDeep(this.cartDetail);

    cartDetail.totals = [this.anthologyTotal, this.reserveTotal, this.onDemandTotal];
    cartDetail.discountsProj = [this.anthologyProjDiscount, this.reserveProjDiscount, this.onDemandProjDiscount];

    cartDetail.paymentModeMultiple = this.paymentModeMultiple;
    cartDetail.payments = [this.paymentMethods[0], this.paymentMethods[1], this.paymentMethods[2]];
    if (this.paymentModeMultiple) {
      cartDetail.paymentDescription = [this.translations["pay" + this.paymentMethods[0].toString()],
        this.translations["pay" + this.paymentMethods[1].toString()],
        this.translations["pay" + this.paymentMethods[2].toString()]];
    } else {
      let stringValue = "pay" + this.paymentMethods[2].toString();
      cartDetail.paymentDescription = [this.translations[stringValue], this.translations[stringValue], this.translations[stringValue]];
    }

    cartDetail.shipAddressMultiple = this.shipAddressMultiple;
    cartDetail.shipAddress = [this.shipAddress1, this.shipAddress2, this.shipAddress3];

    cartDetail.shipModeMultiple = this.shipModeMultiple;
    cartDetail.shipModes = [this.shippingMode1, this.shippingMode2, this.shippingMode3];
    cartDetail.shipCosts = [this.shippingCost1, this.shippingCost2, this.shippingCost3];
    if (this.shipModeMultiple) {
      cartDetail.shipDescription = [this.translations["ship" + this.shippingMode1.toString()],
      this.translations["ship" + this.shippingMode2.toString()],
      this.translations["ship" + this.shippingMode3.toString()]];
    } else {
      let stringValue = "ship" + this.shippingMode3.toString();
      cartDetail.shipDescription = [this.translations[stringValue], this.translations[stringValue], this.translations[stringValue]];
    }

    if (this.shipDateRequired) {
      cartDetail.shipDate3 = this.shipDate;
    }

    if (this.rifOrdine) {
      cartDetail.riferimento = this.rifOrdine.trim().replace(';', ' ').substring(0, 24);
    }
      
    cartDetail.projectDiscount = this.cartDetail.projectDiscount;
    cartDetail.checkPrizeApplied = this.checkPrizeApplied;
    if(idTrasactionPaypal) {
      cartDetail.idTrasactionPaypal = idTrasactionPaypal;
    }

    //potrebbe essere una lista, per questo il check
    if (this.cartDetail.assignedCodCliFor) {
      this.userPreferenceService.getCompany(this.cartDetail.assignedCodCliFor).subscribe(userProfile => {
        this.orderService.send(cartDetail, this.cartDetail.id, this.cartType, userProfile, this.forcedItems,
           this.disableChart1, this.disableChart2, this.checkOutService.getIdListino(), disableSendMailCustomer, this.checkOutService.isModalitaListino2()).subscribe(() => {
          this.authService.forceReload().then(() => {
            this.router.navigateByUrl(this.storeService.createUrl("/order-complete") as string);
          }).catch(() => {
            this.authService.checkAuth().pipe(take(1)).subscribe((isAuthenticated) => {
              if(isAuthenticated) {
                this.router.navigateByUrl(this.storeService.createUrl("/order-complete") as string);
              }
            });
          });
        }, () => {
          this.orderSending = false;
          alert(this.translations["errorSendOrderRetry"]);
        });
      });
    } else {
      this.orderService.send(cartDetail, this.cartDetail.id, this.cartType, null, this.forcedItems,
        this.disableChart1, this.disableChart2, this.checkOutService.getIdListino(), disableSendMailCustomer, this.checkOutService.isModalitaListino2()).subscribe(() => {
        this.authService.forceReload().then(() => {
          this.router.navigateByUrl(this.storeService.createUrl("/order-complete") as string);
        }).catch(() => {
          this.authService.checkAuth().pipe(take(1)).subscribe((isAuthenticated) => {
            if(isAuthenticated) {
              this.router.navigateByUrl(this.storeService.createUrl("/order-complete") as string);
            }
          });
        });
      }, () => {
        this.orderSending = false;
        alert(this.translations["errorSendOrderRetry"]);
      });
    }
  }

  goToSingleCar() {
    this.router.navigate(this.storeService.createUrl(["quotations", this.cartDetail.id, "checkout-plain"]) as any[]);
  }

  confirmSendMailifAdmin(transactionIdPaypal: string = undefined) {
    if (IsSuperAdmin(this.userdata)) {
      this.confirmationService.confirm({
        key: 'sendMailToCustomer',
        header: this.translations["headerAskadminSendMail"],
        message: this.translations["askadminSendMail"],
        acceptLabel: this.translations["yes"],
        rejectLabel: this.translations["no"],
        accept: () => {
          this.sendOrderToServer(false, transactionIdPaypal);
        },
        reject: () => {
          this.sendOrderToServer(true, transactionIdPaypal);
        }
      });
    } else {
      this.sendOrderToServer(false, transactionIdPaypal);
    }
  }
  //paypal vuole al massimo 2 cifre dopo la virgola
  getPaypalAmount() {
    return round(this.totalCard, 2).toString();
  }
}
