import { Component, OnInit, OnDestroy, HostListener, Renderer2 } from '@angular/core';
import { CatalogService } from "../catalog.service";
import { UserPreferenceService } from '../user-preference.service';
import { filter, mergeMap, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { cloneDeep, isNil } from 'lodash';
import { diff } from "deep-diff";
import { watch, unwatch } from 'melanke-watchjs';
import { DropEffect, DndDropEvent } from 'ngx-drag-drop';
import { StoreService } from '../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { IsSuperAdmin } from '../shared/functions';
import { SharedService } from '../shared.service';
import { HttpEventType } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { AuthenticationService } from '../services/authentication.service';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.css']
})
export class ProfileSettingsComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  imageData: any;

  originalUserData: any;

  claims: any;
  isViewer: boolean = false;
  isAdmin: boolean = false;
  userData: any = {};

  changes: any;
  differenceTest: boolean = false;
  isSending: boolean = false;

  linkImage: string;
  linkImageRetail: string;

  trad: any = {};
  //langPdfBrochure: string;
  enqueuePdf: boolean;
  datiBrochureFiles$: Observable<any[]>;
  downloadingFile: boolean;
  percentDownload: number;

  constructor(private userService: UserPreferenceService, private sharedService: SharedService, private fileSaverService: FileSaverService,
    private authService: AuthenticationService, private renderer: Renderer2, private confirmationService: ConfirmationService,
    private catalogService: CatalogService, private storeService: StoreService) {

  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.thereAreDifference()) {
      $event.returnValue = true;
    }
  }

  ngOnInit() {
    marker(['logoImageTooBig', 'fileQueue']);

    this.storeService.translateService.stream(['logoImageTooBig', 'fileQueue']).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.trad = data;
    });

    this.claims = this.authService.getUserProfile();

    this.isAdmin = IsSuperAdmin(this.claims);

    if (this.isAdmin) {
      this.datiBrochureFiles$ = this.catalogService.getCurrentBrandId()
      .pipe(filter((idBrand) => { return !isNil(idBrand) && idBrand != ""  }),take(1), mergeMap((idBrand) => {
          return this.sharedService.getInfoCatalogsFull(idBrand);
      }));
    }

    this.isViewer = this.claims.view_only && this.claims.view_only == "true";

    this.userService._userDetailObs.pipe(takeUntil(this.destroy$)).subscribe((userData) => {
      if (!userData) {
        userData = {
          b2bCatalogMode: 0,
          b2bShopMode: 0,
          b2bShopLock: 0,
          B2bCatalogMode: 2,
          shopLockCode: "",
          intestazione: "",
          nomeLogo: ""
        };
      }

      let oldIdLogo = "";
      let oldIdLogoRetail = "";
      if (this.originalUserData) {
        oldIdLogo = this.originalUserData.idLogo;
        oldIdLogoRetail = this.originalUserData.idLogoRetail;
      }
      this.isSending = false;

      unwatch(this.userData, _ => this.thereAreDifference());
      this.originalUserData = userData;
      this.userData = cloneDeep(userData);
      this.differenceTest = false;
      watch(this.userData, _ => this.thereAreDifference());

      if (this.userData.idLogo != oldIdLogo) {
        this.linkImage = undefined;
      }
      
      if (!this.linkImage && this.userData.idLogo) {
        this.linkImage = `/api/user/getLogoUtente/${this.claims.sub}?t=${new Date().getTime()}`;
      }

      if (this.userData.idLogoRetail != oldIdLogoRetail) {
        this.linkImageRetail = undefined;
      }

      if (!this.linkImageRetail && this.userData.idLogoRetail) {
        this.linkImageRetail = `/api/user/getLogoRetail/${this.claims.sub}?t=${new Date().getTime()}`;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    unwatch(this.userData, _ => this.thereAreDifference());
  }

  updateProfile() {
    this.isSending = true;

    this.userService.updatePreferences(this.userData);
  }

  shopLockChange() {
    if (this.userData.b2bShopLock) {
      if (this.userData.b2bShopMode != 0) return;
      this.userData.b2bShopMode = 1;
      this.catalogService.setShopMode(this.userData.b2bShopMode);
    }
  }

  setDefaultShopMode(mode) {
    if (this.userData.b2bShopMode == mode) return;

    this.userData.b2bShopMode = mode;
  }

  setDefaultCatalogMode(mode) {
    if (this.userData.b2bCatalogMode == mode) return;

    this.userData.b2bCatalogMode = mode;
  }

  setDefaultCheckOutMode(mode) {
    if (this.userData.checkOutMode == mode) return;

    this.userData.checkOutMode = mode;
  }

  thereAreDifference() {
    this.changes = diff(this.originalUserData, this.userData);
    this.differenceTest = this.changes !== undefined;
    return this.differenceTest;
  }

  uploadFileLogo(files: FileList) {
    let file = files[0];
    if (file.size > 614400) {
      alert(this.trad['fileTooBig']);
      return;
    }

    this.userService.updateLogoFile(files[0]);
  }

  deleteFileLogo() {
    this.userService.deleteLogoFile();
  }

  uploadFileLogoRetail(files: FileList) {
    let file = files[0];
    if (file.size > 614400) {
      alert(this.trad['fileTooBig']);
      return;
    }

    this.userService.updateLogoRetailFile(files[0]);
  }

  deleteFileLogoRetail() {
    this.userService.deleteLogoRetailFile();
  }

  deleteFavorite(link: string) {
    let index = this.userData.favorites.findIndex(f => f.link == link);

    if (index != -1) {
      this.userData.favorites.splice(index, 1);

      this.userService.updateListFavorites(this.userData.favorites);
    }
  }

  onDragStart(_event) {
    this.renderer.addClass(document.body, 'dragging');
  }

  onDragEnd(_event) {
    this.renderer.removeClass(document.body, 'dragging');

  }

  onDragged(index: number, effect: DropEffect) {

    if (effect === "move") {
      //nota, questo evento viene eseguito DOPO onDrop, quindi list contiene un elemento doppio, che vado a rimuovere
      this.userData.favorites.splice(index, 1);
      this.userService.updateListFavorites(this.userData.favorites);
    }
  }

  onDrop(event: DndDropEvent, index: number) {

    if (event.dropEffect === "copy"
      || event.dropEffect === "move") {
      let item = event.data;
      //sono in coda
      if (index == -1) {
        this.userData.favorites.push(item);
      } else {
        this.userData.favorites.splice(index, 0, item);
      }
    }
  }

  enQueuePdfBrochure() {
    this.enqueuePdf = true;
    this.sharedService.enqueueFileBrochureFullAll().subscribe(() => {
      this.enqueuePdf = false;
      alert(this.trad['fileQueue']);
    }, _err => {
      this.enqueuePdf = false;
      alert('Error request!')

    });
  }


  downloadFileBrochure(parentFolder: string, fileName: string) {
    if (this.downloadingFile) return;
    this.downloadingFile = true;
    this.percentDownload = 0;

    this.catalogService.getCurrentBrandId().pipe(take(1), mergeMap((idBrand) => {
      return this.sharedService.downloadCatalogFull(parentFolder, idBrand);
    })).subscribe({
      next: (result) => {
        if (result.type === HttpEventType.DownloadProgress) {
          this.percentDownload = Math.round(100 * result.loaded / result.total);
        }
        if (result.type === HttpEventType.Response) {
          this.downloadingFile = false;
          if (result.body && result.body.size) {
            this.fileSaverService.save(result.body, fileName);
          } else {
            alert('unable to download');
          }
        }

      }, error: (_error: any) => {
        this.downloadingFile = false;
        alert('unable to download');
      }
    });
  }

  updateCurrentListino() {

    this.confirmationService.confirm({
      key: 'confirmUpdateListino',
      header: "ATTENZIONE!",
      message: "Verrà creato un listino di backup identico al listino corrente, i preventivi esistenti verranno collegati a questo listino, mentre i preventivi creati successivamente verranno collegati al listino corrente. Tale funzione serve per fare degli aggiornamenti al listino corrente senza modificare i prezzi dei preventivi esistenti in un determinato momento. L'operazione è IRREVERSIBILE! Procedere?",
      acceptLabel: "Procedi",
      rejectLabel: "Annulla",
      accept: () => {
        this.catalogService.updateListino().subscribe(() => {
          alert('Listino di backup creato');
        });
      }
    });

  }

  forsesyncArticoli() {
    this.sharedService.callSyncArticoli().subscribe();
  }

  forsesyncListini() {
    this.sharedService.callSyncListini().subscribe();
  }
}

