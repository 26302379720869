import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PdfService } from '../pdf.service';
import { QuotationService } from '../quotation.service';
import { Subject } from 'rxjs';
import { StoreService } from '../store.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'quotation-brochure',
  templateUrl: './quotation-brochure.component.html',
  styleUrls: ['./quotation-brochure.component.scss']
})
/** quotation-brochure component*/
export class QuotationBrochureComponent implements OnInit, OnDestroy {

  baseQuotation: any;
  data: any;
  source: any;
  ready: boolean = false;

  errorCreatingPdf: string;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('pdfViewerAutoLoadBrochure', { static: false} )
  pdfViewerAutoLoadBrochure;

  constructor(private pdfService: PdfService, private quotationService: QuotationService,
     private storeService: StoreService, private fileSaverService: FileSaverService) {
  }

  ngOnInit() {
    marker('errorCreatingPdf');

    this.storeService.translateService.stream('errorCreatingPdf').pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.errorCreatingPdf = data;
    });

    this.quotationService.baseQuotation.pipe(switchMap(t => {
      this.baseQuotation = t;
      return this.pdfService.printBrochureQuotation(this.baseQuotation.id)
    })).subscribe((data: any) => {
      if (data.size > 0) {
        this.data = data;

        this.pdfViewerAutoLoadBrochure.pdfSrc = data; // pdfSrc can be Blob or Uint8Array
        this.pdfViewerAutoLoadBrochure.refresh(); // Ask pdf viewer to load/refresh pdf

      } else {
        alert(this.errorCreatingPdf)
      }
    }, () => {
      alert(this.errorCreatingPdf)
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  printBrochureQuotation() {
    this.fileSaverService.save(this.data, `${this.baseQuotation.name}-brochure.pdf`);
  }
}
