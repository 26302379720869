<div class="is-divider is-medium"></div>
<div *ngFor="let notifica of notifiche">
  <div class="media">
    <div class="media-left">
      <figure *ngIf="notifica.urlImg" class="image is-48x48 is-square">
        <img [src]="urlBlob + 'notificationimage/' + notifica.urlImg">
      </figure>
      <div class="icon is-large" *ngIf="!notifica.urlImg">
        <i class="far fa-message-smile fa-2x"></i>
      </div>
    </div>
    <div class="media-content" style="width: 100%;">
      <div class="content">
        <div style="margin-bottom: .5em;">
          <strong>{{notifica.titleNotification}}</strong>
        </div>
        <div><span style="white-space: pre-line">{{notifica.abstractNotification}}</span></div>
        <div style="margin-top: .5em;">
          <div class="is-relative image-to-open is-inline-block" style="height:256px;" *ngIf="notifica.urlContentImg">
            <img style="height:256px; width: auto;" [src]="urlBlob + 'notificationimage/' + notifica.urlContentImg">
            <div class="to-show-when-hover" (click)="openFullImage(notifica.urlContentImg)"><i
                class="fas fa-eye fa-2x"></i>
            </div>
          </div>
        </div>
        <div style="margin-top: .5em">
          <a class="has-text-weight-medium" style="text-decoration: underline;"
            *ngIf="notifica.urlRouting || notifica.urlNotification" (click)="openNotificationUrl(notifica)"
            [translate]="'showMore'">Scopri di più</a>
        </div>
      </div>
    </div>
    <div class="media-right has-text-right">
      <a *ngIf="notifica.notRead" (click)="deleteNotification(notifica)">
        <span class="has-text-primary">
          <span [translate]="'toRead'">Da leggere</span>&nbsp;<i class="fas fa-check"></i>
        </span>
      </a>
      <br />
      <span class="has-text-grey-light is-size-7"><span [translate]="'receivedOn'">Ricevuta
          il</span>&nbsp;{{notifica.sentToFirebase | date : 'mediumDate'}}</span>
    </div>
  </div>
  <hr style="margin: 1rem 0;" />
</div>
<div class="flex-auto">
  <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalItems"
    [rowsPerPageOptions]="[5, 10, 20]"></p-paginator>
</div>
<div class="modal is-active" *ngIf="showFullImage">
  <div class="modal-background" (click)="showFullImage = false"></div>
  <div class="modal-content">
    <p class="image is-square">
      <img [src]="urlBlob + 'notificationimage/' + urlImageToShowFullScreen">
    </p>
  </div>
  <button class="modal-close is-large" aria-label="close" (click)="showFullImage = false"></button>
</div>