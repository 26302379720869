import { Inject, Injectable, } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, filter, map, mergeMap, take } from 'rxjs/operators';
import { ProductService } from './product.service';
import { List } from 'immutable';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ListService  {

  private _listSources: BehaviorSubject<List<any>> = new BehaviorSubject(List([]));
  public readonly lists: Observable<List<any>> = this._listSources.asObservable();

  private _opportunitiesSource: BehaviorSubject<List<any>> = new BehaviorSubject(List([]));
  public readonly opportunities: Observable<List<any>> = this._opportunitiesSource.asObservable();

  private _quotationsSources: BehaviorSubject<List<any>> = new BehaviorSubject(List([]));
  //usato in lists component e nav-menu-std con sottoscrizione | async
  public readonly quotations: Observable<List<any>> = this._quotationsSources.asObservable();

  private _currentListSource: BehaviorSubject<any> = new BehaviorSubject(null);

  private detailList: any;

  private _tmpItemsSource: BehaviorSubject<List<any>> = new BehaviorSubject(List([]));
  private _currentConfigurationId: string;

  private httpHeadersDefault: HttpHeaders;

  constructor(private httpClient: HttpClient, private authService: AuthenticationService,
    private productService: ProductService, @Inject(SESSION_STORAGE) private storage: StorageService) {
    this.initUserList();
    this.httpHeadersDefault = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.httpClient.get<any>(`/api/list/${route.paramMap.get('id')}/get-details/`).pipe(tap(t => {
      this.detailList = t.detail;
      this._currentListSource.next(t.list);

    }));
  }

  private initUserList() {
    let sessionList = this.storage.get("currentList");
    const claims = this.authService.getUserProfile();
    // if (claims.codCli) {
    //   this.httpClient.get<any[]>(`/api/salesForce/find-by-dealer/${claims.codCli}`).subscribe((t: any[]) => {
    //     this._opportunitiesSource.next(List(t));
    //   });
    // }
    if (!(claims.view_only && claims.view_only == "true")) {
      // viewer has no lists
      this.httpClient.get<any[]>("/api/list/get-lists").subscribe((t: any[]) => {
        this._listSources.next(List(t));

        if (sessionList && sessionList.type == 0) {
          let list = t.find(x => x.id == sessionList.id);

          if (list) {
            this._currentListSource.next(list);
          } else {
            this._currentListSource.next(t.find(x => x.defaultShoppingCart));
          }

        } else if (!sessionList) {
          this._currentListSource.next(t.find(x => x.defaultShoppingCart));
        }
      });
    }
    this.refreshQuotationList();
  }

  public refreshQuotationList(): void {
    const claims = this.authService.getUserProfile();
    let sessionList = this.storage.get("currentList");
    this.httpClient.get<any[]>("/api/list/get-listquotations").subscribe((t: any[]) => {
      this._quotationsSources.next(List(t));
      if (sessionList && sessionList.type == 1) {
        this._currentListSource.next(t.find(x => x.id == sessionList.id));
      } else if (claims.view_only && claims.view_only == "true") {
        // viewer must access firt quotation available
        this._currentListSource.next(t[0]);
      }
    });
  }

  setCurrentList(list): void {
    this.storage.set("currentList", list);
    this._currentListSource.next(list);
  }

  getListDetail(listId): Observable<any> {
    return this.httpClient.get<any>(`/api/list/${listId}/get-details/`);
  }

  getCurrentList() {
    return this._currentListSource.asObservable().pipe(filter(l => l));
  }

  setCurrentConfiguration(configurationId) {
    if (configurationId) {

      //non e' detto che currentList sia istanziato quando entro nell'if
      this._currentListSource.asObservable().pipe(filter(c => c), take(1), mergeMap((c) => {
        let url: string;
        if (this._currentListSource.value.type == 0) { //non modifico lista
          url = `/api/list/${this._currentListSource.value.id}/get-configuration-item/${configurationId}`;
        } else {
          url = `/api/list/${this._currentListSource.value.id}/get-configuration-item-quotation/${configurationId}`;
        }
        return this.httpClient.get<any>(url);
      })).subscribe(t => {
          if (t) {
            this.productService.setCurrentVariant(t.items.find(x => x.type === 0).referenceId);
            this._currentConfigurationId = t.id;
            this._tmpItemsSource.next(List(t.items));
          }
        });
    } else {
      this.resetCurrentConfiguration();
    }
  }

  getCurrentConfiguration() {
    return this._tmpItemsSource.asObservable().pipe(filter(t => t !== null));
  }

  addOrUpdateConfigurationItem(item) {
    let tmpItems = this._tmpItemsSource.getValue();
    let index = tmpItems.findIndex(t => t.referenceId == item.referenceId);
    if (index == -1) {
      tmpItems = tmpItems.push(item);
    }
    else {
      tmpItems = tmpItems.set(index, item);
    }
    this._tmpItemsSource.next(tmpItems);
  }

  addOrUpdateConfigurationItems(items: any[]) {
    let tmpItems = this._tmpItemsSource.getValue();
    tmpItems = tmpItems.concat(items);
    this._tmpItemsSource.next(tmpItems);
  }

  removeConfigurationItem(item) {
    let items: List<any>;

    if (item.referenceId)
      items = this._tmpItemsSource.getValue().filter(t => t.referenceId != item.referenceId).toList();
    else
      items = this._tmpItemsSource.getValue().filter(t => t.type != item.type).toList();
    this._tmpItemsSource.next(items);
  }

  resetCurrentConfiguration() {
    this._currentConfigurationId = null;
    this._tmpItemsSource.next(List([]));
  }

  createUserList(name: string): Observable<any[]> {
    let url = `/api/list/create`;
    return this.httpClient.post<any[]>(url, JSON.stringify(name), {
      headers: this.httpHeadersDefault
    }).pipe(tap((t: any[]) => {
      const all = List(t);
      this._listSources.next(all);
      this._currentListSource.next(all.last());
    }));
  }

  createQuotationsList(name: string): Observable<any[]> {
    let url = `/api/list/create-quotation`;
    return this.httpClient.post<any[]>(url, JSON.stringify(name), {
      headers: this.httpHeadersDefault
    }).pipe(tap((t: any[]) => {
      const all = List(t);
      this._quotationsSources.next(all);
      this._currentListSource.next(all.first()); //quotations are ordered by updateOn
    }));
  }

  addSearchItemToList(item: any): any {
    let endPoint: string = "";
    let currentListType = this._currentListSource.value.type;
    if (currentListType == 1) { //prodotto, sono sempre sulla currentList
      endPoint = "-quotation";
    }

    let items = [];
    items.push({ referenceId: item.id, sku: item.sku, quantity: item.quantity, type: item.type });

    const formData: FormData = new FormData();
    formData.append("items", JSON.stringify(items));

    let url = `/api/list/${this._currentListSource.value.id}/add-items${endPoint}`;
    return this.httpClient.put<any[]>(url, formData).pipe(tap((t: any) => {

      if (currentListType == 0) {
        let lists = this._listSources.getValue();
        let index = lists.findIndex(x => x.id === t.id);
        this._listSources.next(lists.set(index, t));
      } else {
        let quotations = this._quotationsSources.getValue();
        let index = quotations.findIndex(x => x.id === t.id);
        this._quotationsSources.next(quotations.set(index, t));
      }

    }),
      map(() => {
        const list = this._currentListSource.value;

        return { listState: list.listState, listType: currentListType, id: list.id }
      }));
  }

  addToList(reset: boolean = true, configIdToRemove: string = undefined): Observable<any[]> {

    let valueToUpdate = this._tmpItemsSource.value;

    if (valueToUpdate.some(v => !v.quantity)) return null;

    const formData: FormData = new FormData();
    formData.append("items", JSON.stringify(valueToUpdate));

    let endPoint: string = "";
    let currentListType = this._currentListSource.value.type;
    if (currentListType == 1) { //prodotto, sono sempre sulla currentList
      endPoint = "-quotation";
    }
    let url = `/api/list/${this._currentListSource.value.id}/add-items${endPoint}`;
    if (configIdToRemove) {
      url += "/" + configIdToRemove;
    }

    if (this._currentConfigurationId) {
      url = `/api/list/${this._currentListSource.value.id}/update${endPoint}/${this._currentConfigurationId}`;
    }
    return this.httpClient.put<any[]>(url, formData).pipe(tap((t: any) => {

      if (!this._currentConfigurationId || reset) {
        this._currentConfigurationId = null;
        this._tmpItemsSource.next(List([]));
      }

      if (currentListType == 0) {
        let lists = this._listSources.getValue();
        let index = lists.findIndex(x => x.id === t.id);
        this._listSources.next(lists.set(index, t));
      } else {
        let quotations = this._quotationsSources.getValue();
        let index = quotations.findIndex(x => x.id === t.id);
        this._quotationsSources.next(quotations.set(index, t));
      }
      this._currentListSource.next(t);
    }));
  }

  cloneListItem(listId: string, item: any): Observable<any> {
    let url = `/api/list/${listId}/clone-item`;
    return this.httpClient.post<any>(url, item, {
      headers: this.httpHeadersDefault
    }).pipe(tap((t: any) => {
      t.tmp = true
    }));
  }

  cloneQuotationItem(listId: string, item: any): Observable<any> {
    let url = `/api/list/${listId}/clone-item-quotation`;
    return this.httpClient.post<any>(url, item, {
      headers: this.httpHeadersDefault
    }).pipe(tap((t: any) => {
      t.tmp = true
    }));
  }

  deleteUserList(listId) {
    let url = `/api/list/${listId}/delete`;
    let currentListType = this._currentListSource.value.type;
    return this.httpClient.post<any[]>(url, JSON.stringify(listId), {
      headers: this.httpHeadersDefault
    }).pipe(tap((t: any[]) => {
      const all = List(t);
      this._listSources.next(all);
      if (currentListType == 0) {
        this._currentListSource.next(all.first());
      }
    }));
  }

  archiveUserQuotation(listId) {
    let url = `/api/list/${listId}/archive-quotation`

    let currentListType = this._currentListSource.value.type;
    return this.httpClient.post<any[]>(url, JSON.stringify(listId), {
      headers: this.httpHeadersDefault
    }).pipe(tap((t: any[]) => {
      const all = List(t);
      this._quotationsSources.next(all);
      if (currentListType == 1) {
        this._currentListSource.next(all.first());
      }
    }));
  }

  duplicateQuotation(listId : string) : Observable<any> {
    let url = `/api/list/${listId}/duplicate-quotation`
    return this.httpClient.post<any>(url, null);
  }

  // deleteUserQuotation(listId) {
  //   let url = `/api/list/${listId}/delete-quotation`

  //   let currentListType = this._currentListSource.value.type;
  //   return this.httpClient.post<any[]>(url, JSON.stringify(listId), {
  //     headers: this.httpHeadersDefault
  //   }).pipe(tap((t: any[]) => {
  //     const all = List(t);
  //     this._quotationsSources.next(all);
  //     if (currentListType == 1) {
  //       this._currentListSource.next(all.first());
  //     }
  //   }));
  // }

  updateList() {
    let cList = this._currentListSource.value;
    if (!cList) return;

    const formData: FormData = new FormData();
    formData.append("list", JSON.stringify(cList));

    let url: string = `/api/list/${cList.id}/update-list`;
    return this.httpClient.put<any[]>(url, formData).pipe(tap((t: any) => {


      let lists = this._listSources.getValue();
      let index = lists.findIndex(x => x.id === t.id);
      this._listSources.next(lists.set(index, t));
      this._currentListSource.next(t);

    }));
  }

  updateListLocal(list) {
    let lists = this._listSources.getValue();
    let index = lists.findIndex(x => x.id === list.id);
    this._listSources.next(lists.set(index, list));
  }

  setGlobalDiscount(list, discount) {
    for (let item of list.items) {
      item.discount = discount;
      item.discount2 = 0;
      item.discount3 = 0;
      if (item.type == 3 && item.custom.prezzoVendita) {
        item.custom.prezzoRiservato = (1 - item.discount / 100) * item.custom.prezzoVendita;
      }

      if (item.childItems) {
        for (let cItem of item.childItems) {
          cItem.discount = discount;
          cItem.discount2 = 0;
          cItem.discount3 = 0;
        }
      }
    }
  }

  updateQuotation(quotation: any, deleteList: boolean = false): Observable<any> {

    let url: string = `/api/list/${quotation.id}/update-quotation`;
    const formData: FormData = new FormData();
    formData.append("quotation", JSON.stringify(quotation));

    if (quotation.items.some(it => it.type == 3 && it.custom && it.custom.file)) {
      for (let item of quotation.items.filter(it => it.type == 3 && it.custom && it.custom.file)) {
        formData.append(item.id, item.custom.file, item.custom.file.name);
        item.custom.file = undefined; //tolgo
      }
    }

    let obs = this.httpClient.put<any[]>(url, formData);

    if (deleteList) {
      return obs.pipe(tap((t: any) => {
        let quotations = this._quotationsSources.getValue();
        let index = quotations.findIndex(x => x.id === t.id);
        quotations = quotations.remove(index);
        this._quotationsSources.next(quotations);
        this._currentListSource.next(quotations.first());
      }));
    } else {
      return obs.pipe(tap((t: any) => {
        let quotations = this._quotationsSources.getValue();

        let index = quotations.findIndex(x => x.id === t.id);

        if (index != -1) {
          quotations = quotations.set(index, t);
          this._quotationsSources.next(quotations);
        }
      }));
    }

  }

  updateQuotationLocal(quotation) {
    let quotations = this._quotationsSources.getValue();
    let index = quotations.findIndex(x => x.id === quotation.id);
    this._quotationsSources.next(quotations.set(index, quotation));
  }

  unRegisterProject(list: any, updateSource: boolean = false): Observable<any> {
    let params = new HttpParams().set('listId', list.id);

    let url: string = `/api/list/unregister-project`;
    return this.httpClient.get<any>(url, { params: params }).pipe(tap((t: any) => {
      if (!updateSource) return;
      let quotations = this._quotationsSources.getValue();
      let index = quotations.findIndex(x => x.id === t.id);
      quotations = quotations.set(index, t);
      this._quotationsSources.next(quotations);
    }));
  }

  transformToQuote(list) {
    let url: string = `/api/list/${list.id}/transform-to-quotation`;
    return this.httpClient.post<any>(url, null).pipe(tap((t: any) => {
      this._quotationsSources.next(t.item2);

      this._listSources.next(t.item1);
      this._currentListSource.next(t.item2.find(x => x.id == t.item3));
    }));
  }

  fillItemDetail(item: any) {
    switch (item.type) {
      case 0: {

        const detail = this.detailList.variants.find(t => t.id == item.referenceId);
        if (!detail) {
          item.esWarning = true;
          break;
        }
        item.idBrand = detail.idBrand;
        item.productModel = detail.productModel;
        item.image = detail.images ? detail.images[0] : null;
        item.name = detail.esolverName ? detail.esolverName : "";
        item.prezzo = detail.prezzo;
        item.description = detail.esolverDescription;
        item.esWarning = detail.esWarning;
        item.prezzoRiservato = detail.prezzoRiservato;
        item.availability = detail.qtaDisponibile;
        item.anthology = detail.anthology;
        item.anthologyStar = detail.anthologyStar;
        item.mxStar = detail.mxStar;
        item.categoryName = detail.categoryName;
        item.sku = detail.sku;
        item.um = detail.um;

        if (item.childItems) {
          for (let cItem of item.childItems) {
            const cdetail = this.detailList.accessories.find(t => t.id == cItem.referenceId);
            if (!cdetail) {
              cItem.esWarning = true;
              continue;
            }
            cItem.idBrand = cdetail.idBrand;
            if (cItem.sku == cdetail.sku) {
              cItem.image = cdetail.image;
              cItem.name = cdetail.esolverName ? cdetail.esolverName : "";
              cItem.prezzo = cdetail.prezzo;
              cItem.prezzoRiservato = cdetail.prezzoRiservato;
              cItem.availability = cdetail.qtaDisponibile;
              cItem.anthology = cdetail.anthology;
              cItem.anthologyStar = cdetail.anthologyStar;
              cItem.mxStar = cdetail.mxStar;
              cItem.esWarning = cdetail.esWarning;
              cItem.um = cdetail.um;
            } else {
              if (!cdetail.packs || cdetail.packs.length == 0) {
                item.esWarning = cItem.esWarning = true;
                continue;
              }

              const pack = cdetail.packs.find(t => t.sku == cItem.sku);
              if (!pack) {
                item.esWarning = cItem.esWarning = true;
                continue;
              }

              cItem.image = cdetail.image;
              cItem.name = pack.esolverName ? pack.esolverName : "";
              cItem.prezzo = pack.prezzo;
              cItem.prezzoRiservato = pack.prezzoRiservato;
              cItem.availability = pack.qtaDisponibile;
              cItem.anthology = pack.anthology;
              cItem.anthologyStar = pack.anthologyStar;
              cItem.mxStar = pack.mxStar;
              cItem.esWarning = pack.esWarning;
              cItem.um = pack.um;
            }
          }
        }
        break;
      }
      case 1: {

        const detail = this.detailList.accessories.find(t => t.id == item.referenceId);
        if (!detail) {
          item.esWarning = true;
          break;
        }
        item.idBrand = detail.idBrand;
        if (item.sku == detail.sku) {
          item.image = detail.image;
          item.name = detail.esolverName ? detail.esolverName : "";
          item.prezzo = detail.prezzo;
          item.description = detail.esolverDescription;
          item.prezzoRiservato = detail.prezzoRiservato;
          item.availability = detail.qtaDisponibile;
          item.esWarning = detail.esWarning;
          item.anthology = detail.anthology;
          item.anthologyStar = detail.anthologyStar;
          item.mxStar = detail.mxStar;
          item.um = detail.um;
        } else {
          //si tratta di un pack
          if (!detail.packs || detail.packs.length == 0) {
            item.esWarning = true;
            break;
          }

          //si tratta di un pack
          const pack = detail.packs.find(t => t.sku == item.sku);
          if (!pack) {
            item.esWarning = true;
            break;
          }
          item.image = detail.image;
          item.name = pack.esolverName ? pack.esolverName : "";
          item.prezzo = pack.prezzo;
          item.prezzoRiservato = pack.prezzoRiservato;
          item.availability = pack.qtaDisponibile;
          item.anthology = pack.anthology;
          item.anthologyStar = pack.anthologyStar;
          item.mxStar = pack.mxStar;
          item.um = pack.um;
        }
        break;
      }
      case 2: {
        // wizard product

        const product = this.detailList.products.find(t => t.id == item.referenceId);
        if (product) {
          item.productModel = product.id;
          item.image = product.images ? product.images[0] : null;
          item.name = product.name;
          item.description = product.description;
          item.idBrand = product.idBrand;
        } else {
          item.esWarning = true;
        }
        if (item.childItems) {
          for (let cItem of item.childItems) {
            if (cItem.type == 1) { //accessorio
              const cdetail = this.detailList.accessories.find(t => t.id == cItem.referenceId);
              if (!cdetail) {
                cItem.esWarning = true;
                continue;
              }
              cItem.idBrand = cdetail.idBrand;

              if (cItem.sku == cdetail.sku) {
                cItem.image = cdetail.image;
                cItem.name = cdetail.esolverName ? cdetail.esolverName : "";
                cItem.prezzo = cdetail.prezzo;
                cItem.prezzoRiservato = cdetail.prezzoRiservato;
                cItem.availability = cdetail.qtaDisponibile;
                cItem.anthology = cdetail.anthology;
                cItem.anthologyStar = cdetail.anthologyStar;
                cItem.mxStar = cdetail.mxStar;
                cItem.esWarning = cdetail.esWarning;
                cItem.um = cdetail.um;
              } else {
                if (!cdetail.packs || cdetail.packs.length == 0) {
                  item.esWarning = cItem.esWarning = true;
                  continue;
                }

                const pack = cdetail.packs.find(t => t.sku == cItem.sku);
                if (!pack) {
                  item.esWarning = cItem.esWarning = true;
                  continue;
                }

                cItem.image = cdetail.image;
                cItem.name = pack.esolverName ? pack.esolverName : "";
                cItem.prezzo = pack.prezzo;
                cItem.prezzoRiservato = pack.prezzoRiservato;
                cItem.availability = pack.qtaDisponibile;
                cItem.anthology = pack.anthology;
                cItem.anthologyStar = pack.anthologyStar;
                cItem.mxStar = pack.mxStar;
                cItem.esWarning = pack.esWarning;
                cItem.um = pack.um;
              }
            } else {
              const cdetail = this.detailList.variants.find(t => t.id == cItem.referenceId);
              if (!cdetail) {
                cItem.esWarning = true;
                continue;
              }
              cItem.idBrand = cdetail.idBrand;
              cItem.productModel = cdetail.productModel;
              cItem.image = cdetail.images ? cdetail.images[0] : null;
              cItem.name = cdetail.esolverName ? cdetail.esolverName : "";
              cItem.prezzo = cdetail.prezzo;
              cItem.description = cdetail.esolverDescription;
              cItem.prezzoRiservato = cdetail.prezzoRiservato;
              cItem.availability = cdetail.qtaDisponibile;
              cItem.anthology = cdetail.anthology;
              cItem.anthologyStar = cdetail.anthologyStar;
              cItem.mxStar = cdetail.mxStar;
              cItem.esWarning = cdetail.esWarning;
              cItem.um = cdetail.um;
              cItem.sku = cdetail.sku;
              cItem.categoryName = cdetail.categoryName;

              if (!item.categoryName) {
                item.categoryName = cdetail.categoryName;
              }

            }
          }
          let counterPrezzo = 0;
          let counterPrezzoRiservato = 0;
          let allOk = true;
          for (let cItem of item.childItems) {
            counterPrezzo += cItem.prezzo * cItem.quantity;
            counterPrezzoRiservato += cItem.prezzoRiservato * cItem.quantity;
            allOk = allOk && !cItem.esWarning;
          }
          item.prezzo = allOk ? counterPrezzo : 0;
          item.prezzoRiservato = allOk ? counterPrezzoRiservato : 0;
          item.esWarning = !allOk;
        }
        break;
      }
      default: {
        //nothing here
        break;
      }
    }
  }

  reloadListDetail(checkListId?: string) {

    if (!this._currentListSource.value)
      return;

    if (checkListId && checkListId != this._currentListSource.value.id)
      return;

    this.httpClient.get<any>(`/api/list/${checkListId}/get-details/`).subscribe((t) => {
      this.detailList = t.detail;
      this._currentListSource.next(t.list);
    });

  }

}
