<nav class="columns">
  <div class="column">
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field" *ngIf="isAdmin">
          <div class="control ui-fluid">
            <p-autoComplete [suggestions]="searchResultsCompanies" (completeMethod)="searchCompanies($event)"
              [(ngModel)]="company" [inputStyleClass]="'input'" appendTo="body" [minLength]="3"
              [field]="MySelectCompanyConvertion" [forceSelection]="true" [emptyMessage]="translations.noCompanyFound"
              [placeholder]="translations.companyClientName">
              <ng-template let-comp pTemplate="item">
                <div class="ui-helper-clearfix">
                  <div class="title is-6">{{comp.ragSoc}}</div>
                  <div class="subtitle is-7"><span [translate]="'codCli'"></span> {{comp.codCliFor}}</div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
        <div class="field" *ngIf="isAdmin">
          <div class="control">
            <input class="input" [(ngModel)]="searchPublicCode" type="text" [placeholder]="'publicCode' | translate">
          </div>
        </div>
        <div class="field has-addons">
          <div class="control">
            <input class="input" [(ngModel)]="searchString" type="text" [placeholder]="'quotationName' | translate">
          </div>
          <div class="control">
            <button type="button" class="button is-info" (click)="search()" [translate]="'search'">
              Cerca
            </button>
          </div>
          <div class="control">
            <button type="button" class="button is-warning" (click)="reset()" [translate]="'reset'">
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="column is-narrow">
    <div class="select">
      <select [(ngModel)]="status" (ngModelChange)="changeStatus()">
        <option [ngValue]="0" [translate]="'quotations'">Preventivi</option>
        <option [ngValue]="2" [translate]="'orders'">Ordini</option>
        <option [ngValue]="3" [translate]="'archives'">Archiviati</option>
        <option [ngValue]="-1" [translate]="'seeAll'">Vedi tutti</option>
      </select>
    </div>
  </div>
</nav>
<div *ngIf="!data?.quotations; else dataAvailable" [translate]="'loading'">
  Caricamento...
</div>
<ng-template #dataAvailable>
  <div *ngIf="data.count == 0; else showData" [translate]="'noQuotationsFound'">
    Nessun preventivo trovato con i criteri di ricerca
  </div>
  <ng-template #showData>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th style="width:5em;">&nbsp;</th>
          <th>
            <a (click)="setSortOrder(false)"><span [translate]="'name'">Nome</span><i *ngIf="!modeDate"
                class="fas fa-fw fa-chevron-up"></i></a>
          </th>
          <th *ngIf="isAdmin" [translate]="'behalfOf'">Per conto di</th>
          <th [translate]="'sellValue'">Val. Vendita</th>
          <th *ngIf="shopMode == 0" [translate]="'buyValue'">Val. Acquisto</th>
          <!--        <th [translate]="'status'">Stato</th>-->
          <th>
            <a (click)="setSortOrder(true)">
              <span [translate]="'lastModify'">Ultima modifica</span><i *ngIf="modeDate"
                class="fas fa-fw fa-chevron-down"></i>
            </a>
          </th>
          <th style="width:5em;">&nbsp;</th>
        </tr>
      </thead>
      <tr *ngFor="let quotation of data.quotations">
        <td>
          <i *ngIf="quotation.statusProject == 2; else noProgetto" class="far fa-fw fa-registered"
            [pTooltip]="translations['projectRegistered']" tooltipStyleClass="m-tooltip"></i>
          <ng-template #noProgetto>
            <i class="far fa-fw fa-registered has-text-white"></i>
          </ng-template>
          <i *ngIf="quotation.shareWithCompany; else noShare" class="fas fa-fw"
            [pTooltip]="quotation.userId == claims.sub ? translations['quotationShared'] : translations['quotationSharedFrom']"
            tooltipStyleClass="m-tooltip" [class.fa-file-export]="quotation.userId == claims.sub"
            [class.fa-file-import]="quotation.userId != claims.sub"></i>
          <ng-template #noShare>
            <i class="fas fa-fw fa-share has-text-white"></i>
          </ng-template>
        </td>
        <td>
          <a (click)="goToQuotation(quotation)">{{quotation.name}}</a>
        </td>
        <td *ngIf="isAdmin">
          {{quotation.managedBy}}
        </td>
        <td>
          {{quotation.totale | currency:'EUR'}}
        </td>
        <td *ngIf="shopMode == 0">
          {{quotation.totaleRiservato | currency:'EUR'}}
        </td>
        <!--        <td>-->
        <!--          <ng-container [ngSwitch]="quotation.listState">-->
        <!--            <span class="tag is-light" *ngSwitchCase="1" [translate]="'statusInProgress'">In lavorazione</span>-->
        <!--            <span [translate]="'statusOrder'" *ngSwitchCase="2" class="tag is-info has-text-white">Ordine</span>-->
        <!--            <span class="tag is-light" *ngSwitchCase="3" [translate]="'statusArchived'">Archiviato</span>-->
        <!--            <span class="tag is-warning" *ngSwitchDefault [translate]="'statusOpen'">Aperto</span>-->
        <!--          </ng-container>-->
        <!--        </td>-->
        <td>
          {{quotation.updateOn | date : 'd MMM yyyy' : undefined : locale}}
        </td>
        <td>
          <a (click)="goToQuotation(quotation)"><i class="fas fa-pen"></i></a>
          &nbsp;
          <a (click)="deleteQuotation(quotation.id)" *ngIf="quotation.listState == 0">
            <i class="fas fa-trash-alt"></i>
          </a>
          <i class="fas fa-hourglass-half" *ngIf="quotation.listState == 1"
            [pTooltip]="translations['statusInProgress']" tooltipPosition="bottom" tooltipStyleClass="m-tooltip"></i>
          <ng-container *ngIf="quotation.listState == 2">
            <span *ngIf="shopMode != 0; else buyModeOrders" [pTooltip]="translations['requireModeBuy']"
              tooltipPosition="bottom" tooltipStyleClass="m-tooltip">
              <i class="far fa-eye"></i>
            </span>
            <ng-template #buyModeOrders>
              <a [routerLink]="['/orders'] | localize" *ngIf="quotation.orders?.length == 0; else goToDetailOrder"
                [pTooltip]="translations['viewOrder']" tooltipStyleClass="m-tooltip">
                <i class="far fa-eye"></i>
              </a>
              <ng-template #goToDetailOrder>
                <a *ngIf="quotation.orders.length == 1" [pTooltip]="translations['viewOrder']" tooltipStyleClass="m-tooltip"
                   [routerLink]="['/orders', quotation.orders[0]] | localize"><i class="far fa-eye"></i></a>
                <a *ngIf="quotation.orders.length > 1" [pTooltip]="translations['viewOrder']" tooltipStyleClass="m-tooltip"
                    (click)="popperOrdersContent.toggle($event);setPopperItem(quotation.orders);"><i class="far fa-eye"></i></a>
              </ng-template>
            </ng-template>
          </ng-container>
        </td>
      </tr>
    </table>
    <nav *ngIf="pageSize < data.count" class="level">
      <div class="level-left">
        <div class="level-item">
          <small class="has-text-right">
            <span [translate]="'page'">Pagina</span> {{currentPage}} <span [translate]="'pageOf'">di</span>
            {{(data.count / pageSize) | ceiling}}
          </small>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="buttons ">
            <button [disabled]="currentPage == 1" class="button" (click)="prevPageEvent()">
              <i class="fas fa-chevron-left"></i>
            </button>
            <button [disabled]="data.quotations.length != pageSize || currentPage*pageSize >= data.count" class="button"
              (click)="nextPageEvent()">
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </nav>
    <p-dialog [(visible)]="archivingId" [draggable]="false" [resizable]="false" [modal]="true">
      <p-header>
        Archiviazione
      </p-header>
      <div>Si prega di fornire un motivo per l'archiviazione:</div>
      <div class="select is-fullwidth" style="margin: 0.75em 0">
        <select [(ngModel)]="archiveReason">
          <option [ngValue]="'competitor'">Vinta da un competitor</option>
          <option [ngValue]="'budget'">Budget insufficiente</option>
          <option [ngValue]="'indecisione'">Nessuna decisione</option>
          <option [ngValue]="'prezzo'">Prezzo</option>
          <option [ngValue]="'altro'">Altro</option>
        </select>
      </div>
      <p-footer>
        <button class="button is-info" [disabled]="!archiveReason" (click)="archive()">Procedi</button>
        <button class="button" (click)="undoArchive()">Annulla</button>
      </p-footer>
    </p-dialog>
    <p-confirmDialog icon="pi pi-exclamation-triangle">
    </p-confirmDialog>
  </ng-template>
</ng-template>
<p-overlayPanel #popperOrdersContent>
  <div [translate]="'ordersConnect'">Ordini collegati</div>
  <div *ngFor="let order of idOrders">
    <a [routerLink]="['/orders', order] | localize">
      <span [translate]="'statusOrder'">
      </span>&nbsp;{{order}}
    </a>
  </div>
</p-overlayPanel>
