<div class="column">
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="columns">
        <div class="column is-narrow">
          <figure class="image is-128x128" [class.draggable]="list.listState == 0" dndHandle hoverIntent
            (onHoverIntent)="item.hoverImage = true" (mouseleave)="item.hoverImage = false">
            <img fallback-src="https://via.placeholder.com/128x128" query="width=128&height=128&mode=crop"
              [cdnImg]="item.childItems[0].image" [productId]="item.childItems[0].productModel">
          </figure>
        </div>
        <div class="column">
          <h2 class="title is-5">
            <span *ngIf="item.idBrand">{{item.idBrand | brandName | async }}&nbsp;</span>
            <span *ngIf="item.name">{{item.name}}</span>&nbsp;-&nbsp;<span
              [translate]="'configuration'">configurazione</span>
            <span>
              <i *ngIf="item.esWarning" class="fas fa-fw fa-exclamation-triangle has-text-warning"
                [pTooltip]="this.translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
            </span>
            <span class="is-size-6">&nbsp;{{item.categoryName}}</span>
          </h2>
          <h2 *ngIf="!item.name" class="title is-5">
            <span [translate]="'accessoryProductNoLongerAvailable'"></span>
            <i class="fas fa-fw fa-exclamation-triangle has-text-warning"
              [pTooltip]="this.translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
          </h2>
          <h3 class="subtitle is-6 buttons">
            <span>
              {{item.configurationId}}
            </span>
            &nbsp;
            <a *ngIf="list.listState == 0" (click)="setPopperItem(item);popperListContent.toggle($event)"
              class="has-text-weight-semibold">
              <span [translate]="'modify'" style="margin-right:0.5em">Modifica</span>
              <i class="fas fa-angle-down"></i>
            </a>
            <a [href]="item.downloads.linkPdf" *ngIf="item.downloads?.linkPdf" class="pointer has-text-weight-semibold"
              target="_blank">
              <i class="fas fa-file-download fa-fw"></i>PDF
            </a>
            <a [href]="item.downloads.linkDxf" *ngIf="item.downloads?.linkDxf" class="pointer has-text-weight-semibold"
              target="_blank">
              <i class="fas fa-file-download fa-fw"></i>DXF
            </a>
            <a *ngIf="item.productModel" (click)="goToProduct()" class="has-text-weight-semibold">
              <span [translate]="'goToProduct'">Vai al prodotto</span>
            </a>
          </h3>
          <div class="columns info">
            <div class="column is-narrow">
              <span class="is-size-7" [translate]="'quantity'">Quantità</span><br />
              <span class="is-size-5 has-text-weight-semibold">{{item.quantity}}</span>
            </div>
            <ng-container *ngIf="shopMode == 0">
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'listPrice'">Prezzo di listino</span><br />
                <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzo"
                  [class.is-line-through]="item.discount > 0 || item.discount2 > 0 || item.discount3 > 0">{{(item.prezzo
                  / item.quantity) |
                  currency:'EUR'}}</span>
                <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzo">N/A</span>
              </div>
              <ng-container *ngIf="!list.hideDiscount">
                <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <span class="icon-text">
                    <span class="is-size-7" [translate]="'discount'">Sconto</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateItemDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <span class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>2</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount2" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateItemDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                  <span class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>3</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount3" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateItemDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
              </ng-container>
              <div class="column is-narrow prezzo-vendita">
                <span class="is-size-7" [translate]="'salePrice'">Prezzo di vendita</span>
                <br />
                <span class="is-size-5 has-text-weight-semibold">{{item | calculatePrezzoVendita |
                  currency:'EUR'}}</span>
              </div>
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span>
                <br />
                <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzoRiservato">{{(item.prezzoRiservato /
                  item.quantity) | currency:'EUR'}}</span>
                <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzoRiservato">N/A</span>
              </div>
              <div class="column is-narrow is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
                <span class="is-size-7" [translate]="'profitMargin'">Margine</span><br />
                <span class="is-size-5 has-text-weight-semibold"
                  [class.has-text-danger]="(item | calculateMargine) < 0.05">{{item | calculateMargine |
                  percent}}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="shopMode == 1">
              <ng-container *ngIf="!list.hideDiscount">
                <div class="column is-narrow">
                  <span class="is-size-7" [translate]="'listPrice'">Prezzo di listino</span><br />
                  <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzo"
                    [class.is-line-through]="item.discount > 0 || item.discount2 > 0 || item.discount3 > 0">{{(item.prezzo
                    / item.quantity) | currency:'EUR'}}</span>
                  <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzo">N/A</span>
                </div>
                <div class="column is-narrow">
                  <span class="icon-text">
                    <span class="is-size-7" [translate]="'discount'">Sconto</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateItemDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow">
                  <span class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>2</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount2" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateItemDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="column is-narrow">
                  <span class="icon-text">
                    <span class="is-size-7"><span [translate]="'discount'">Sconto</span>3</span>
                    <span class="icon is-small">
                      <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                    </span>
                  </span>
                  <br />
                  <p-inputNumber [(ngModel)]="item.discount3" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateItemDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
              </ng-container>
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'reservedPrice'">Prezzo riservato</span><br />
                <span class="is-size-5 has-text-weight-semibold">
                  {{item | calculatePrezzoVendita | currency:'EUR'}}</span>
              </div>
            </ng-container>
          </div>
          <div class="columns info is-hidden-fullhd" *ngIf="shopMode == 0">
            <ng-container *ngIf="!list.hideDiscount">
              <div class="column is-narrow">
                <span class="icon-text">
                  <span class="is-size-7" [translate]="'discount'">Sconto</span>
                  <span class="icon is-small">
                    <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                  </span>
                </span>
                <br />
                <p-inputNumber [(ngModel)]="item.discount" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateItemDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
              </div>
              <div class="column is-narrow">
                <span class="icon-text">
                  <span class="is-size-7"><span [translate]="'discount'">Sconto</span>2</span>
                  <span class="icon is-small">
                    <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                  </span>
                </span>
                <br />
                <p-inputNumber [(ngModel)]="item.discount2" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateItemDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
              </div>
              <div class="column is-narrow">
                <span class="icon-text">
                  <span class="is-size-7"><span [translate]="'discount'">Sconto</span>3</span>
                  <span class="icon is-small">
                    <i class="is-size-7 fas fa-circle-info" [pTooltip]="'acceptPointAsSeparator' | translate"></i>
                  </span>
                </span>
                <br />
                <p-inputNumber [(ngModel)]="item.discount3" placeholder="0%"
                    [disabled]="list.listState != 0" (ngModelChange)="updateItemDiscount()" 
                    [size]="4" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
              </div>
            </ng-container>
            <div class="column">
              <span class="is-size-7" [translate]="'profitMargin'">Margine</span><br />
              <span class="is-size-5 has-text-weight-semibold"
                [class.has-text-danger]="(item | calculateMargine) < 0.05">
                {{item | calculateMargine | percent}}
              </span>
            </div>
          </div>
          <div *ngIf="item.childItems?.length > 0">
            <a (click)="toggleAccessories()" class="has-text-weight-semibold">
              <i class="fas fa-fw fa-angle-down"></i>{{item.childItems.length}}&nbsp;
              <span [translate]="'components'">componenti</span>
            </a>
          </div>
        </div>
        <div class="column is-2 price">
          <div class="has-text-right">
            <div class="title is-size-5 has-text-weight-semibold" *ngIf="prezzoFinale">{{(prezzoFinale) |
              currency:'EUR'}}</div>
            <div class="title is-size-5 has-text-weight-semibold" *ngIf="!prezzoFinale">N/A</div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-12 child-item" *ngIf="item.childItems?.length > 0 && showAccessories">
      <div class="columns" *ngFor="let acc of item.childItems | orderBy:'type'">
        <div class="column is-narrow">
          <div style="width:128px"></div>
        </div>
        <div class="column is-narrow">
          <figure class="image is-64x64">
            <img fallback-src="https://via.placeholder.com/128x128" query="width=128&height=128&mode=crop"
              *ngIf="acc.type == 0" [cdnImg]="acc.image" [productId]="acc.productModel">
            <img fallback-src="https://via.placeholder.com/128x128" query="width=128&height=128&mode=crop"
              *ngIf="acc.type == 1" [cdnImg]="acc.image" type="accessory">
          </figure>
        </div>
        <div class="column">
          <h4 *ngIf="acc.name" class="subtitle has-text-weight-semibold is-size-6">
            <span>{{acc.name}}</span><span class="is-size-7"
              *ngIf="acc.categoryName">&nbsp;{{acc.categoryName}}</span><span class="is-size-7">&nbsp;{{acc.sku}}</span>
            <i *ngIf="acc.esWarning" class="fas fa-fw fa-exclamation-triangle has-text-warning"
              [pTooltip]="translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
          </h4>
          <h4 *ngIf="!acc.name" class="subtitle has-text-weight-semibold is-size-6">
            <span [translate]="'accessoryProductNoLongerAvailable'"></span>
            <i *ngIf="acc.esWarning" class="fas fa-fw fa-exclamation-triangle has-text-warning"
              [pTooltip]="translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
          </h4>
          <div class="columns info is-vcentered">
            <div class="column is-narrow">
              <div class="is-size-7">
                <span [translate]="'quantity'">Quantità</span> <ng-container *ngIf="acc.um">({{acc.um | lowercase
                  }})</ng-container>
              </div>
              <div class="is-size-6 has-text-weight-semibold">{{acc.quantity}}</div>
            </div>
            <ng-container *ngIf="shopMode == 0">
              <div class="column is-narrow">
                <div>
                  <span class="is-size-7" [translate]="'listPrice'">Prezzo listino</span>&nbsp;&nbsp;
                  <span class="is-size-6 has-text-weight-semibold" *ngIf="acc.prezzo"
                    [class.is-line-through]="item.discount > 0 || item.discount2 > 0 || item.discount3 > 0">{{acc.prezzo
                    | currency:'EUR'}}</span>
                  <span class="is-size-6 has-text-weight-semibold" *ngIf="!acc.prezzo">N/A</span>
                </div>
                <div>
                  <span class="is-size-7" [translate]="'salePrice'">Prezzo di vendita</span>&nbsp;&nbsp;
                  <span class="is-size-6 has-text-weight-semibold">{{ acc | calculatePrezzoVendita:item |
                    currency:'EUR'}}</span>
                </div>
              </div>
              <div class="column is-narrow">
                <div>
                  <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span>&nbsp;&nbsp;
                  <span class="is-size-6 has-text-weight-semibold" *ngIf="acc.prezzoRiservato">{{acc.prezzoRiservato |
                    currency:'EUR'}}</span>
                  <span class="is-size-6 has-text-weight-semibold" *ngIf="!acc.prezzoRiservato">N/A</span>
                </div>
                <div>
                  <span class="is-size-7" [translate]="'profitMargin'">Margine</span>&nbsp;&nbsp;
                  <span class="is-size-6 has-text-weight-semibold"
                    [class.has-text-danger]="(acc | calculateMargine:item) < 0.05">{{acc | calculateMargine:item |
                    percent}}</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="shopMode == 1">
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'listPrice'">Prezzo listino</span>&nbsp;&nbsp;
                <span class="is-size-6 has-text-weight-semibold" *ngIf="acc.prezzo"
                  [class.is-line-through]="item.discount > 0 || item.discount2 > 0 || item.discount3 > 0">{{acc.prezzo |
                  currency:'EUR'}}</span>
                <span class="is-size-6 has-text-weight-semibold" *ngIf="!acc.prezzo">N/A</span>
              </div>
              <div class="column is-narrow">
                <span class="is-size-7" [translate]="'reservedPrice'">Prezzo riservato</span>&nbsp;&nbsp;
                <span class="is-size-6 has-text-weight-semibold">{{acc | calculatePrezzoVendita | currency:'EUR'}}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-overlayPanel #popperListContent>
  <a (click)="gotoConfiguration(popperItem)" class="has-text-dark"
    [translate]="'modifyconfiguration'">Configurazione</a><br />
  <ng-container *ngIf="popperItem?.tmp === true">
    <span [translate]="'duplicateArticle'">Articolo duplicato</span><br />
  </ng-container>
  <ng-container *ngIf="sections.length > 1">
    <a (click)="inplaceSection = true" class="has-text-dark" [translate]="'changeSection'">Sposta
      di sezione <br /></a>
  </ng-container>
  <ng-container *ngIf="sections.length > 1 && inplaceSection">
    <div class="field has-addons">
      <div class="control is-expanded">
        <div class="select is-small is-fullwidth">
          <select [(ngModel)]="inplaceCurrentSection">
            <option value="" [translate]="'noSection'">Nessuna sezione</option>
            <option *ngFor="let sec of inplaceAllowedSections" [value]="sec.value">{{sec.label}}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-info is-small" (click)="changeSection()">
          <i class="fas fa-check"></i>
        </button>
      </div>
    </div>
  </ng-container>
  <a (click)="removeItem(popperItem?.configurationId)" class="has-text-danger" [translate]="'delete'">Elimina</a>
</p-overlayPanel>