<div class="columns is-vcentered">
  <div class="column">
    <h1 class="title is-4" *ngIf="!editTitleMode">
      <span *ngIf="baseList && baseList.defaultShoppingCart" [translate]="'carrello'"></span>
      <span *ngIf="baseList && !baseList.defaultShoppingCart">{{baseList?.name}}</span>
      <a (click)="popperListQuote.toggle($event)" class="has-text-weight-semibold is-size-6">
        <i class="fas fa-lg fa-fw fa-angle-down"></i>
      </a>
    </h1>
    <form *ngIf="editTitleMode">
      <div class="field has-addons">
        <div class="control">
          <input class="input" type="text" [(ngModel)]="newTitle" name="newTitle" [placeholder]="'addList' | translate">
        </div>
        <div class="control">
          <button type="submit" class="button is-info" (click)="updateTitle()">
            <i class="fas fa-check"></i>
          </button>
        </div>
        <div class="control">
          <button type="submit" class="button" (click)="editTitle(false)">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </form>
    <h2 class="subtitle is-4">
      <span [translate]="'total'">Totale</span>&nbsp;{{listTotal | currency:'EUR'}}
    </h2>
  </div>
  <div class="column is-narrow">
    <div
      [pTooltip]="differenceTest !== undefined ? translations['saveModifyBeforeContinue'] : (shopMode != 0 ? translations['requireModeBuy'] : translations['articleNotValidInCart'])"
      tooltipPosition="left" [tooltipDisabled]="!differenceTest && shopMode === 0 && !baseList?.invalid"
      tooltipStyleClass="m-tooltip">
      <button class="button is-medium is-link"
        [disabled]="differenceTest || baseList?.invalid || shopMode != 0 || !(baseList?.items.length > 0)"
        (click)="checkout(baseList)">
        <span [translate]="'proceedWithOrder'">Procedi con l'ordine</span>&nbsp;&nbsp;<i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </div>
</div>
<hr />
<div *ngIf="baseList?.items.length > 0;else no_items">

  <div [dndDraggable]="item" dndEffectAllowed="move" *ngFor="let item of baseList.items; index as i; first as f"
    (dndEnd)="onDragEnd($event)" (dndStart)="onDragStart($event, i, item.configurationId)"
    [dndDisableIf]="baseList.listState != 0 || !item.hoverImage" (dndMoved)="onDragged(i, baseList.items, 'move')">
    <div class="drop-zone" [class.no-border]="f" dndDropzone
      [dndDisableIf]="dragStart && (cuItemId == item.configurationId || i == cuIndex+1 )"
      (dndDrop)="onDrop($event, baseList.items, i)">
      <div dndPlaceholderRef class="dndplaceHolder"></div>
    </div>

    <list-item [item]="item" class="columns" *ngIf="item.type != 2" (onTotalChange)="calculateTotals($event)"
      [list]="baseList"></list-item>
    <list-wizard-item *ngIf="item.type == 2 && item.childItems?.length > 0" [item]="item" class="columns"
      (onTotalChange)="calculateTotals($event)" [list]="baseList"></list-wizard-item>
  </div>
  <div class="drop-zone no-border" dndDropzone [dndDisableIf]="dragStart && (cuIndex+1 == baseList.items.length)"
    (dndDrop)="onDrop($event, baseList.items, -1)">
    <div dndPlaceholderRef class="dndplaceHolder"></div>
  </div>
  <hr />
  <div class="columns">
    <div class="column has-text-right">
      <div class="title is-4" [translate]="'total'">Totale</div>
    </div>
    <div class="column is-narrow">
      <div class="title is-4 has-text-right">{{listTotal | currency:'EUR'}}</div>
      <div class="subtitle is-size-6 has-text-right">
        <span [hidden]="shopMode == 0" [translate]="'reservedPrice'">Prezzo riservato</span>
        <span [hidden]="shopMode != 0" [translate]="'purchasePrice'">Prezzo d'acquisto</span>
      </div>
    </div>
  </div>
  <div class="columns is-vcentered">
    <div class="column">

    </div>
    <div class="column">
      <span class="has-text-danger is-italic" *ngIf="baseList?.invalid" [translate]="'totalMayBeDifferent'">I totali
        potrebbero differire poichè una o più articoli non sono validi<br></span>
      <span class="is-italic" [translate]="'noTaxInclude'">Gli importi si intendono tutti IVA esclusa</span>
    </div>
    <div class="column has-text-right">
      <div [pTooltip]="differenceTest !== undefined ? translations['saveModifyBeforeContinue'] :
           (shopMode === 1 ? translations['requireModeBuy'] : translations['articleNotValidInCart'])"
        tooltipPosition="left" [tooltipDisabled]="!differenceTest && shopMode === 0 && !baseList?.invalid"
        tooltipStyleClass="m-tooltip">
        <button class="button is-medium is-link" (click)="checkout(baseList)"
          [disabled]="differenceTest || baseList?.invalid || shopMode === 1  || !(baseList?.items.length > 0)">
          <span [translate]="'proceedWithOrder'">Procedi con l'ordine</span>&nbsp;&nbsp;<i
            class="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #no_items [translate]="'listsIsEmpty'">La lista è vuota.</ng-template>

<p-overlayPanel #popperListQuote>
  <ng-container *ngIf="!baseList?.defaultShoppingCart">
    <a (click)="editTitle(true)" [translate]="'rename'">Rinomina</a><br />
    <a (click)="toQuote()" [translate]="'trasformIntoQuotation'">Trasforma in preventivo</a><br />
    <a *ngIf="canDelete" (click)="delete(baseList?.id)" class="has-text-danger" [translate]="'delete'">Elimina</a>
  </ng-container>
  <ng-container *ngIf="baseList?.defaultShoppingCart">
    <a (click)="empty()" class="has-text-danger" [translate]="'clean'">Svuota</a>
  </ng-container>
</p-overlayPanel>
<div class="sticky-header" *ngIf="differenceTest">
  <div class="container">
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item" [translate]="'warningUnsavedChanges'">
          Attenzione: ci sono modifiche non salvate.
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button" [translate]="'cancel'" (click)="cancel()">
            Annulla
          </button>
        </div>
        <div class="level-item is-marginless">
          <div class="field is-grouped">
            <p class="control">
              <button class="button is-link" [ngClass]="{'is-loading': isSending}" (click)="saveList()"
                [translate]="'save'">Salva</button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>