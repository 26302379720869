<section class="section">
  <div class="container is-widescreen">
    <div class="columns">
      <div class="column is-one-fifth list-enum">
        <h3 class="title is-4" [translate]="'accessories'">Accessori</h3>
        <h4 class="title is-5">{{brandName}}</h4>
        <ul>
          <li *ngFor="let cat of categoryAccessories$ | async">
            <a class="has-text-dark" [routerLink]="['/accessories', cat.id] | localize"
              routerLinkActive="has-text-weight-bold">{{cat.name}}</a>
          </li>
        </ul>
      </div>
      <div class="column is-four-fifths detail">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>