import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SearchQuotationModel } from '../../shared/search-quotation.model';
import { Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { StoreService } from '../../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { IsSuperAdmin } from '../../shared/functions';
import { QuotationService } from '../../quotation.service';
import * as moment from 'moment';

//item all'interno di list-quotation. E' per il riassunto delle quotation, non per la modifica della quotation corrente. Rappresenta un'intera quotation, non un item
@Component({
  selector: 'item-quotation',
  templateUrl: './item-quotation.component.html',
  styleUrls: ['./item-quotation.component.css']
})
/** item-quotation component*/
export class ItemQuotationComponent implements OnInit, OnDestroy {

  @Input("quotations")
  data: any;

  @Input("shopMode")
  shopMode: number;

  @Input("claims")
  claims: any;

  isAdmin: boolean;
  status: number = 0;

  @Output()
  selectQuotation = new EventEmitter<any>();

  @Output()
  nextPage = new EventEmitter<SearchQuotationModel>();

  @Output()
  prevPage = new EventEmitter<SearchQuotationModel>();

  lastIdsNext: string[];
  lastNameNext: string;
  lastUpdateNext: string;

  modeDate: boolean = true;
  currentPage: number = 1;

  searchString: string;
  //searchStringCached: string;

  pageSize: number = 25;

  translations: any = {};

  destroy$: Subject<boolean> = new Subject<boolean>();

  locale: string;

  archivingId: string;
  archiveReason: string;

  idOrders: string[] = [];

  private searchTermsCompany$ = new Subject<string>();
  searchResultsCompanies: any[];
  company: any;
  searchPublicCode: string;

  private keySessionStorageSearch: string = "searchParam";
  private keySessionStorageCompany: string = "company";
  /** item-quotation ctor */
  constructor(private quotationService: QuotationService, private storeService: StoreService) {

  }

  ngOnInit(): void {

    let translationRequired = marker(['projectRegistered', 'noCompanyFound', 'companyClientName',
      'quotationShared', 'quotationSharedFrom', 'requireModeBuy', 'statusInProgress']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.locale = this.storeService.localeDefault;

    this.storeService.getLocaleObservable().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.locale = data;
    });


    this.currentPage = 1;
    this.isAdmin = IsSuperAdmin(this.claims);

    this.searchTermsCompany$.pipe(switchMap((term: string) => {
      return this.quotationService.searchImpersonation(term);
    }), takeUntil(this.destroy$)).subscribe(data => {
      this.searchResultsCompanies = data;
    });

    let companySession = sessionStorage.getItem(this.keySessionStorageCompany);
    if(companySession) {
      this.company = JSON.parse(companySession);
    }

    let valueSearchInSession = sessionStorage.getItem(this.keySessionStorageSearch);
    if (valueSearchInSession) {
      let searchParam = JSON.parse(valueSearchInSession);
      //this.searchStringCached =
      this.searchString = searchParam.mustContain;
      this.status = searchParam.status;
      this.modeDate = searchParam.orderModeDate;

      this.searchPublicCode = searchParam.publicCode;
      this.search();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setSortOrder(value: boolean) {
    if (this.modeDate == value) return;

    this.modeDate = value;
    this.currentPage = 1;

    let searchParam: SearchQuotationModel = {
      codCliFor: this.company ? this.company.codCliFor : undefined,
      deleteId: undefined,
      deleteReason: undefined,
      lastIds: undefined,
      lastNameSearch: undefined,
      lastUpdateOnSearch: undefined,
      mustContain: this.searchString,
      status: this.status,
      orderModeDate: this.modeDate,
      pageSize: this.pageSize,
      publicCode: this.searchPublicCode
    };

    this.nextPage.emit(searchParam);
  }

  goToQuotation(quotation: any) {
    this.selectQuotation.emit({ quotation: quotation, publicCode: this.searchPublicCode });
  }

  setPopperItem(items: string[]) {
    this.idOrders = items;
  }

  deleteQuotation(id: string) {
    this.archivingId = id;
  }

  archive() {
    let searchParam: SearchQuotationModel = {
      codCliFor: this.company ? this.company.codCliFor : undefined,
      deleteId: this.archivingId,
      deleteReason: this.archiveReason,
      lastIds: undefined,
      lastNameSearch: undefined,
      lastUpdateOnSearch: undefined,
      mustContain: this.searchString,
      status: this.status,
      orderModeDate: this.modeDate,
      pageSize: this.pageSize,
      publicCode: this.searchPublicCode,
    };

    this.nextPage.emit(searchParam);
    this.currentPage = 1;
    this.archiveReason = undefined;
    this.archivingId = undefined;
  }

  undoArchive() {
    this.archiveReason = undefined;
    this.archivingId = undefined;
  }

  search() {
    this.currentPage = 1;
    //this.searchStringCached = this.searchString;

    let searchParam: SearchQuotationModel = {
      codCliFor: this.company ? this.company.codCliFor : undefined,
      deleteId: undefined,
      deleteReason: undefined,
      lastIds: undefined,
      lastNameSearch: undefined,
      lastUpdateOnSearch: undefined,
      mustContain: this.searchString,
      status: this.status,
      orderModeDate: this.modeDate,
      pageSize: this.pageSize,
      publicCode: this.searchPublicCode,
    };

    sessionStorage.setItem(this.keySessionStorageSearch, JSON.stringify(searchParam));
    sessionStorage.setItem(this.keySessionStorageCompany, JSON.stringify(this.company));

    this.nextPage.emit(searchParam);
  }

  reset() {
    //this.searchStringCached = 
    this.searchString = undefined;
    this.status = 0;
    this.modeDate = true;
    this.currentPage = 1;

    this.searchPublicCode = undefined;
    this.company = undefined;
    let searchParam: SearchQuotationModel = {
      codCliFor: undefined,
      deleteId: undefined,
      deleteReason: undefined,
      lastIds: undefined,
      lastNameSearch: undefined,
      lastUpdateOnSearch: undefined,
      mustContain: this.searchString,
      status: this.status,
      orderModeDate: this.modeDate,
      pageSize: this.pageSize,
      publicCode: this.searchPublicCode
    };

    sessionStorage.removeItem(this.keySessionStorageSearch);

    this.nextPage.emit(searchParam);
  }

  changeStatus() {

    //this.lastIdsNext = this.lastNameNext = this.lastUpdateNext = undefined;
    //this.lastCallWasPrev = false;
    let searchParam: SearchQuotationModel = {
      codCliFor: this.company ? this.company.codCliFor : undefined,
      deleteId: undefined,
      deleteReason: undefined,
      lastIds: undefined,
      lastNameSearch: undefined,
      lastUpdateOnSearch: undefined,
      mustContain: this.searchString,
      status: this.status,
      orderModeDate: this.modeDate,
      pageSize: this.pageSize,
      publicCode: this.searchPublicCode
    };

    sessionStorage.setItem(this.keySessionStorageSearch, JSON.stringify(searchParam));
    sessionStorage.setItem(this.keySessionStorageCompany, JSON.stringify(this.company));

    this.currentPage = 1;
    this.nextPage.emit(searchParam);
  }

  nextPageEvent() {
    let lastQuotation = this.data.quotations[this.data.quotations.length - 1];
    ++this.currentPage;
    let lastUpdate = moment(lastQuotation.updateOn, 'YYYY-MM-DDTHH:mm:ss.SSS ZZ').toISOString();
    let searchParam: SearchQuotationModel = {
      codCliFor: this.company ? this.company.codCliFor : undefined,
      deleteId: undefined,
      deleteReason: undefined,
      lastIds: this.data.quotations.map(q => q.id),
      lastNameSearch: lastQuotation.name,
      lastUpdateOnSearch: lastUpdate,
      mustContain: this.searchString,
      status: this.status,
      orderModeDate: this.modeDate,
      pageSize: this.pageSize,
      publicCode: this.searchPublicCode,
    };
    this.nextPage.emit(searchParam);

  }

  prevPageEvent() {
    let firstQuotation = this.data.quotations[0];
    --this.currentPage;

    let firstUpdate = moment(firstQuotation.updateOn, 'YYYY-MM-DDTHH:mm:ss.SSS ZZ').toISOString();
    let searchParam: SearchQuotationModel = {
      codCliFor: this.company ? this.company.codCliFor : undefined,
      deleteId: undefined,
      deleteReason: undefined,
      lastIds: this.data.quotations.map(q => q.id),
      lastNameSearch: firstQuotation.name,
      lastUpdateOnSearch: firstUpdate,
      mustContain: this.searchString,
      status: this.status,
      orderModeDate: this.modeDate,
      pageSize: this.pageSize,
      publicCode: this.searchPublicCode
    };

    this.prevPage.emit(searchParam);
  }

  searchCompanies(event) {
    this.searchTermsCompany$.next(event.query);
  }

  MySelectCompanyConvertion(company: any): string {
    if (!company) return "";
    return company.ragSoc + ' (' + company.codCliFor + ')';
  }
}
