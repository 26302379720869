import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PdfService } from '../pdf.service';
import { QuotationService } from '../quotation.service';
import { takeWhile, take, takeUntil } from 'rxjs/operators';
import { IsSuperAdmin } from '../shared/functions'
import { AuthenticationService } from '../services/authentication.service';
import { StoreService } from '../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Subject, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-quotations-share',
  templateUrl: './quotations-share.component.html',
  styleUrls: ['./quotations-share.component.css']
})
export class QuotationsShareComponent implements OnInit, OnDestroy {

  shareForm: FormGroup;
  baseQuotation: any;
  admin: boolean;

  destroy$: Subject<boolean> = new Subject<boolean>();

  get shareQuotation() {
    return this.shareForm.get('shareQuotation').value
  }

  get shareBrochure() {
    return this.shareForm.get('shareBrochure').value
  }

  optionsShareQuotation : any[] = [];
  translations: any = {};

  constructor(private fb: FormBuilder, private quotationService: QuotationService, private authService: AuthenticationService,
    private pdfService: PdfService, private storeService: StoreService) {

    let userdata = this.authService.getUserProfile();
    this.admin = IsSuperAdmin(userdata);

    this.shareForm = this.fb.group({
      shareQuotation: true,
      shareQuotationMode: undefined,
      showComponentiConfig: false,
      showProductCode: false,
      showProductImage: false,
      shareBrochure: false,
      email: ['', Validators.required],
      messageText: ['', Validators.required]
    })

  }

  ngOnInit() {
    let translationRequired = marker(['standard', 'proforma']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;


      this.optionsShareQuotation = [
        { value: 'standard', label: this.translations['standard'] },
        { value: 'proforma', label: this.translations['proforma'] }
      ];

      this.shareForm.controls.shareQuotationMode.setValue(this.optionsShareQuotation[0]);
    });

    this.quotationService.baseQuotation.pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.baseQuotation = t;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  share() {

    let options = this.shareForm.value;
    if (options.shareQuotationMode.value == 'standard') {
      options.tipoOfferta = 0;
    } else if (options.shareQuotationMode.value == 'proforma') {
      options.tipoOfferta = 1;
    }

    firstValueFrom(this.pdfService.shareQuotation(this.baseQuotation.id, options)).then(_ => {

    });
  }
}
