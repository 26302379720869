<table class="table is-fullwidth">
  <thead>
  <tr>
    <th style="width:5em;">&nbsp;</th>
    <th [translate]="'agreement'">Contratto</th>
    <th>PDF</th>
    <th [translate]="'lastModify'">Ultima modifica</th>
    <th>&nbsp;</th>
  </tr>
  </thead>
  <tr>
    <td>
      <span class="tag is-warning is-fullwidth" [translate]="'active'">Attivo</span>
    </td>
    <td>
      Anthology
    </td>
    <td>
      <a (click)="getPdfContract()" ><i class="far fa-file-pdf fa-lg"></i></a>
    </td>
    <td>
      N/A
    </td>
    <td>
      <button class="button is-warning is-fullwidth is-small" [translate]="'resolve'">Risolvi</button>
    </td>
  </tr>
</table>
