import { Component } from '@angular/core';
import { NavMenuStandardComponent } from './nav-menu-std.component';

@Component({
  selector: 'nav-menu-viewer',
  templateUrl: './nav-menu-viewer.component.html',
  styleUrls: ['./nav-menu-viewer.component.css'],
})
export class NavMenuViewerComponent extends NavMenuStandardComponent {
}
