import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ShareQuotationOptions {
  shareQuotation: boolean;
  tipoOfferta: 0 | 1;
  showComponentiConfig: boolean,
  showProductCode: boolean,
  showProductImage: boolean,
  shareBrochure: boolean,
  email: string,
  messageText: string
}


@Injectable({
  providedIn: 'root'
})
export class PdfService {

  private httpHeadersDefault: HttpHeaders;

  constructor(private httpClient: HttpClient) {
    this.httpHeadersDefault = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  printQuotationPdf(idQuotation: string, showComponentiConfig: boolean, doctype: any, showProductCode: boolean, showProductImage: boolean): Observable<any> {
    let params = new HttpParams().set('idQuotation', idQuotation);
    if (showComponentiConfig != undefined) {
      params = params.set('showComponentiConfig', showComponentiConfig.toString());
    }
    if (doctype != undefined) {
      params = params.set('doctype', doctype.toString());
    }
    if (showProductCode != undefined) {
      params = params.set('showProductCode', showProductCode.toString());
    }
    if (showProductImage != undefined) {
      params = params.set('showProductImage', showProductImage.toString());
    }

    const options = { params: params, headers: this.httpHeadersDefault, responseType: 'blob' as 'json' };
    return this.httpClient.get<any>('/api/pdf/getPdfQuotation', options);
  }

  printBrochureProduct(idProduct: string): Observable<any> {
    let params = new HttpParams().set('idModello', idProduct);
    const options = { params: params, headers: this.httpHeadersDefault, responseType: 'blob' as 'json' };
    return this.httpClient.get<any>('/api/pdf/getPdfBrochureModello', options);
  }

  printBrochureAccessory(idAcc: string): Observable<any> {
    let params = new HttpParams().set('idAcc', idAcc);
    const options = { params: params, headers: this.httpHeadersDefault, responseType: 'blob' as 'json' };
    return this.httpClient.get<any>('/api/pdf/getPdfBrochureAccessorio', options);
  }

  printBrochureQuotation(idQuotation: string): Observable<any> {
    let params = new HttpParams().set('idQuotation', idQuotation);
    const options = { params: params, headers: this.httpHeadersDefault, responseType: 'blob' as 'json' };
    return this.httpClient.get<any>('/api/pdf/getPdfBrochureQuotation', options);
  }

  printPdfRetailQuotation(idQuotation: string, showComponentiConfig: boolean, showProductCode: boolean, showProductImage: boolean): Observable<any> {
    let params = new HttpParams().set('idQuotation', idQuotation);
    if (showComponentiConfig != undefined) {
      params = params.set('showComponentiConfig', showComponentiConfig.toString());
    }
    if (showProductCode != undefined) {
      params = params.set('showProductCode', showProductCode.toString());
    }
    if (showProductImage != undefined) {
      params = params.set('showProductImage', showProductImage.toString());
    }
    const options = { params: params, headers: this.httpHeadersDefault, responseType: 'blob' as 'json' };
    return this.httpClient.get<any>('/api/pdf/getPdfRetailQuotation', options);
  }

  shareQuotation(idQuotation: string, shareOptions: ShareQuotationOptions): Observable<any> {
    
    const url = `/api/pdf/shareQuotation/${idQuotation}`
    return this.httpClient.post(url, JSON.stringify(shareOptions), { headers: this.httpHeadersDefault });

  }

}
