import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { QuotationService } from '../../quotation.service';
import { CatalogService } from '../../catalog.service';
import { ListService } from '../../list.service';
import { SearchQuotationModel } from '../../shared/search-quotation.model';
import { Router } from '@angular/router';
import { StoreService } from '../../store.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'list-quotation',
  templateUrl: './list-quotation.component.html',
  styleUrls: ['./list-quotation.component.css'],
})
/** listQuotation.ts component*/
export class ListQuotationComponent implements OnInit {


  quotations: Observable<any[]>;
  shopMode: Observable<number>;
  claims: any;

  /** listQuotation.ts ctor */
  constructor(private quotationService: QuotationService, private catalogService: CatalogService, private listService: ListService, private storeService: StoreService,
    private authService: AuthenticationService, private router: Router) {

  }

  ngOnInit(): void {
    let defaultSearch: SearchQuotationModel = {
      codCliFor: undefined,
      deleteId: undefined,
      deleteReason: undefined,
      lastIds: undefined,
      lastNameSearch: undefined,
      lastUpdateOnSearch: undefined,
      mustContain: undefined,
      publicCode: undefined,
      status: 0,
      orderModeDate: true,
      pageSize: 25
    };


    this.quotations = this.quotationService.getTableNextQuotation(defaultSearch);
    this.claims = this.authService.getUserProfile();
    this.shopMode = this.catalogService.getShopMode();
  }

  updateQuotations(event: SearchQuotationModel) {
    this.quotations = this.quotationService.getTableNextQuotation(event);

    if (event.deleteId) {
      this.quotations = this.quotations.pipe(tap(() => this.listService.refreshQuotationList()))
    }
  }

  selectQuotation(event: any) {
    

    this.listService.setCurrentList(event.quotation);
    if (event.publicCode) {
      this.router.navigate(this.storeService.createUrl(['/quotations', event.quotation.id]) as any[], { queryParams: { code: event.publicCode } });
    } else {
      this.router.navigate(this.storeService.createUrl(['/quotations', event.quotation.id]) as any[]);
    }
    
  }

  prevQuotation(event: SearchQuotationModel) {
    this.quotations = this.quotationService.getTablePrevQuotation(event);
    if (event.deleteId) {
      this.quotations = this.quotations.pipe(tap(() => this.listService.refreshQuotationList()))
    }
  }

}
