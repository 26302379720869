import { PassedInitialConfig } from "angular-auth-oidc-client";
import { environment } from "../../environments/environment";

export const configAuthId : string = "b2bMacroluxConfig";
export const configAuth : PassedInitialConfig = {

    config : {
      configId: configAuthId,
      authority:  environment.identityUrl,
      redirectUrl: `${window.location.origin}/callback`,
      postLogoutRedirectUri: `${window.location.origin}/`,
      clientId: 'b2b-vnext',
      scope: 'openid offline_access profile email address esolver yuppie orders identity.read',
      responseType: 'code',
      logLevel: environment.logLevel,
      silentRenew: true,
      useRefreshToken: true,
      ignoreNonceAfterRefresh: true,    
      renewUserInfoAfterTokenRenew: true,
      unauthorizedRoute: "/",
      autoUserInfo: true,
      startCheckSession: true,
      secureRoutes: ['/api', `${window.location.origin}/api`, `${environment.identityUrl}/service/service`]
    }
}