<div class="column" >
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="columns">
        <div class="column is-narrow">
          <figure class="image is-128x128 draggable" dndHandle hoverIntent (onHoverIntent)="item.hoverImage = true" (mouseleave)="item.hoverImage = false">
            <img fallback-src="https://via.placeholder.com/128x128" query="width=128&height=128&mode=crop" [cdnImg]="item.childItems[0].image"
                 [productId]="item.childItems[0].productModel">
          </figure>
        </div>
        <div class="column">
          <h2 class="title is-5">
            <span *ngIf="item.idBrand">{{item.idBrand | brandName | async }}&nbsp;</span>
            <span *ngIf="item.name">{{item.name}}</span>&nbsp;-&nbsp;<span [translate]="'configuration'">configurazione</span>
            <span>
              <i *ngIf="item.esWarning" class="fas fa-fw fa-exclamation-triangle has-text-warning" [pTooltip]="this.translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
            </span>
            <span class="is-size-6">&nbsp;{{item.categoryName}}</span>
          </h2>
          <h2 *ngIf="!item.name" class="title is-size-5">
            <span [translate]="'accessoryProductNoLongerAvailable'"></span>
            <i *ngIf="item.esWarning" class="fas fa-fw fa-exclamation-triangle has-text-warning" [pTooltip]="this.translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
          </h2>
          <h3 class="subtitle is-size-6 buttons">
            <span>
              {{item.configurationId}}
              <i class="fas fa-info-circle fa-sm" [pTooltip]="item.description" tooltipStyleClass="m-tooltip"></i>
            </span>
            &nbsp;
            <a (click)="setPopperItem(item); popperListContent.toggle($event)" class="has-text-weight-semibold">
              <span [translate]="'modify'">Modifica</span>&nbsp;<i class="fas fa-angle-down"></i>
            </a>
            <a [href]="item.downloads.linkPdf" *ngIf="item.downloads?.linkPdf" class="pointer" target="_blank">
              <i class="fas fa-file-download fa-fw"></i>PDF
            </a>
            <a [href]="item.downloads.linkDxf" *ngIf="item.downloads?.linkDxf" class="pointer" target="_blank">
              <i class="fas fa-file-download fa-fw"></i>DXF
            </a>
            <a *ngIf="item.productModel" (click)="goToProduct()" class="has-text-weight-semibold">
              <span [translate]="'goToProduct'">Vai al prodotto</span>
            </a>
          </h3>
          <div class="columns info">
            <div class="column is-narrow">
              <span class="is-size-7" [translate]="'quantity'">Quantità</span><br />
              <span class="is-size-5 has-text-weight-semibold">{{item.quantity}} {{item.um | lowercase }}</span>
            </div>
            <div class="column is-narrow">
              <span class="is-size-7" [translate]="'listPrice'">Prezzo di listino</span><br />
              <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzo" [class.is-line-through]="shopMode == 0">{{item.prezzo | currency:'EUR'}}</span>
              <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzo">N/A</span>
            </div>
            <div *ngIf="shopMode == 0" class="column is-narrow">
              <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span><br />
              <span class="is-size-5 has-text-weight-semibold" *ngIf="item.prezzoRiservato">{{item.prezzoRiservato | currency:'EUR'}}</span>
              <span class="is-size-5 has-text-weight-semibold" *ngIf="!item.prezzoRiservato">N/A</span>
            </div>
          </div>
          <div *ngIf="item.childItems?.length > 0">
            <a (click)="toggleAccessories()" class="has-text-weight-semibold">
              <i class="fas fa-fw fa-angle-down"></i>{{item.childItems.length}} <span [translate]="'components'">componenti</span>
            </a>
          </div>
        </div>
        <div class="column is-2 price">
          <div class="has-text-right">
            <div class="title is-size-5 has-text-weight-semibold" *ngIf="prezzoFinale">{{(prezzoFinale) | currency:'EUR'}}</div>
            <div class="title is-size-5 has-text-weight-semibold" *ngIf="!prezzoFinale">N/A</div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-12 child-item" *ngIf="item.childItems?.length > 0 && showAccessories">
      <div class="columns" *ngFor="let acc of item.childItems | orderBy:'type'">
        <div class="column is-narrow">
          <div style="width:128px"></div>
        </div>
        <div class="column is-narrow">
          <figure class="image is-64x64">
            <img fallback-src="https://via.placeholder.com/128x128" query="width=128&height=128&mode=crop" *ngIf="acc.type == 0" [cdnImg]="acc.image"
                 [productId]="acc.productModel">
            <img fallback-src="https://via.placeholder.com/128x128" query="width=128&height=128&mode=crop" *ngIf="acc.type == 1" [cdnImg]="acc.image"
                 type="accessory">
          </figure>
        </div>
        <div class="column">
          <h4 *ngIf="acc.name" class="subtitle has-text-weight-semibold is-size-6">
            <span>{{acc.name}}</span><span class="is-size-7" *ngIf="acc.categoryName">&nbsp;{{acc.categoryName}}</span><span class="is-size-7">&nbsp;{{acc.sku}}</span>
            <i *ngIf="acc.esWarning" class="fas fa-fw fa-exclamation-triangle has-text-warning" [pTooltip]="translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
          </h4>
          <h4 *ngIf="!acc.name" class="subtitle has-text-weight-semibold is-size-6">
            <span [translate]="'accessoryProductNoLongerAvailable'"></span>
            <i class="fas fa-fw fa-exclamation-triangle has-text-warning" [pTooltip]="translations['articleProductNotValid']" tooltipStyleClass="m-tooltip"></i>
          </h4>
          <div class="columns info is-vcentered">
            <div class="column is-narrow">
              <span class="is-size-7"><span [translate]="'quantity'">Quantità</span> <ng-container *ngIf="acc.um">({{acc.um | lowercase }})</ng-container></span>&nbsp;&nbsp;
              <span class="is-size-6 has-text-weight-semibold">{{acc.quantity}} {{acc.um | lowercase }}</span>
            </div>
            <div class="column is-narrow">
              <span class="is-size-7" [translate]="'listPrice'">Prezzo di listino</span>&nbsp;&nbsp;
              <span class="is-size-6 has-text-weight-semibold" *ngIf="acc.prezzo" [class.is-line-through]="shopMode == 0">{{acc.prezzo | currency:'EUR'}}</span>
              <span class="is-size-6 has-text-weight-semibold" *ngIf="!acc.prezzo">N/A</span>
            </div>
            <div *ngIf="shopMode == 0" class="column is-narrow">
              <span class="is-size-7" [translate]="'purchasePrice'">Prezzo d'acquisto</span>&nbsp;&nbsp;
              <span class="is-size-6 has-text-weight-semibold" *ngIf="acc.prezzoRiservato">{{acc.prezzoRiservato | currency:'EUR'}}</span>
              <span class="is-size-6 has-text-weight-semibold" *ngIf="!acc.prezzoRiservato">N/A</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-overlayPanel #popperListContent>
  <ng-container *ngIf="popperItem?.tmp !== true">
    <a (click)="gotoConfiguration(popperItem)" class="has-text-dark" [translate]="'configuration'">Configurazione</a><br />
  </ng-container>
  <ng-container *ngIf="popperItem?.tmp === true">
    <span>Articolo duplicato</span><br />
  </ng-container>
  <a (click)="removeItem(popperItem?.configurationId)" class="has-text-danger" [translate]="'delete'">Elimina</a>
</p-overlayPanel>
