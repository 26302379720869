import { Component, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { UploadService } from "../upload.service";
import { forkJoin, Subject } from "rxjs";
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { StoreService } from '../../../store.service';
import { takeUntil } from 'rxjs/operators';
import { Guid } from 'guid-typescript';
import { FileIdWithError } from '../interfaces';

@Component({
  selector: 'app-dialog-upload',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit, OnDestroy, AfterViewInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  translations: any = {};


  @ViewChild('file', { static: false }) file;

  @Input() open = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeWithUploadedFile: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() url: string;

  public files: Set<FileIdWithError> = new Set();

  progresses;
  canBeClosed = true;
  filesHasError: boolean = false;

  primaryButtonText = 'Upload';
  showCancelButton = true;
  uploading = false;
  uploadComplete = false;

  constructor(public uploadService: UploadService, private storeService: StoreService) { }

  ngOnInit(): void {
    let translationRequired = marker(['upload', 'sendFiles', 'fileTooBig']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
      this.primaryButtonText = this.translations.upload;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit() {
  }

  onFilesAdded() {
    const files: { [key: string]: FileIdWithError } = this.file.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        let fileToCheck = files[key];
        fileToCheck.id = Guid.create().toString();

        if (fileToCheck.size >= 52428800) {
          fileToCheck.error = this.translations.fileTooBig;
          this.filesHasError = true;
        }
        this.files.add(fileToCheck);
      }
    }
    this.file.nativeElement.value = "";
  }

  removeFile(file: File) {
    if (this.uploading) return;
    this.files.delete(file);
    this.filesHasError = false;
    this.files.forEach(function (item) {
      if (item.error) {
        this.filesHasError = true;
      }
    });
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  closeDialog() {
    // if everything was uploaded already, just close the dialog
    if (this.uploadComplete) {
      return this.close();
    }

    // set the component state to "uploading"
    this.uploading = true;

    // start the upload and save the progress map
    this.progresses = this.uploadService.upload(this.files, this.url);

    let allProgressObservables = [];
    for (const key in this.progresses) {
      allProgressObservables.push(this.progresses[key].progress);

      //this.progresses[key].progress.subscribe(() => { }, (err) => {

      //});
    }
    // The dialog should not be closed while uploading
    this.canBeClosed = false;

    // Hide the cancel-button
    this.showCancelButton = false;

    // When all progress-observables are completed...
    forkJoin(allProgressObservables).subscribe(end => {
      // ... the dialog can be closed again...
      this.canBeClosed = true;
      // ... the upload was successful...
      this.uploadComplete = true;

      this.files.forEach(item => {
        if (item.error) {
          this.filesHasError = true;
        } else {
          this.files.delete(item);
        }
      });

      // ... and the component is no longer uploading
      this.uploading = false;


      if (!this.filesHasError) {
        this.close();
      }
    });
  }

  onDialogHide(event) {
    this.close()
  }

  close() {
    this.open = false;
    this.openChange.emit(false);
    this.closeWithUploadedFile.emit(this.uploadComplete);
    this.files = new Set();
    this.progresses = undefined;
    // ... the upload was successful...
    this.uploadComplete = false;
    this.filesHasError = false;

  }

}
