import { Component, OnInit } from '@angular/core';
import { CatalogService } from "../catalog.service";

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
  constructor(private catalogService: CatalogService) {
  }

  categories: any[];

  ngOnInit() {
    this.catalogService.getCategories().subscribe(t => {
      this.categories = t;
    });

  }

}
