import {
  Component,
  OnChanges,
  Input,
  ViewChild,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ListService } from '../../list.service';
import { CatalogService } from '../../catalog.service';
import { QuotationService } from '../../quotation.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StoreService } from '../../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReturnTo } from '../../shared/costants';

@Component({
  selector: 'quotation-item',
  templateUrl: './quotation-item.component.html',
  styleUrls: ['./quotation-item.component.css'],
})
/** quotation-item component*/
export class QuotationItemComponent implements OnInit, OnChanges, OnDestroy {

  @Input() item: any;
  @Input() list: any;
  @Input() section: any;
  @Input() sections: any[];

  @Output() onTotalChange = new EventEmitter<number>();
  @Output() onKelvinCheck = new EventEmitter<any>();
  @Output() onClone = new EventEmitter<any>();

  prezzoFinale: number = 0;
  accessoryTotal: number = 0;
  showAccessories: boolean = false;

  inplaceSection: boolean = false;
  inplaceCurrentSection: string;
  inplaceAllowedSections: any[];

  shopMode: number = 1;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  popperItem: any;
  @ViewChild('popperListContent', { static: true }) popperListContent: any;

  translations: any = {};

  constructor(private router: Router, private listService: ListService, private storeService: StoreService,
    private quotationService: QuotationService, private catalogService: CatalogService, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    let publicCode = this.route.snapshot.queryParamMap.get('code');
    if (publicCode) {

      this.item.searchedPublicCode = this.item.publicCode == publicCode;
      for (let child of this.item.childItems) {
        child.searchedPublicCode = child.publicCode == publicCode;
      }
    }

    let translationRequired = marker(['articleProductNotValid', 'confirmDeleteItemFromQuotation', 'confirmDeleteAccessoryFromVariant']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.catalogService.getShopMode().pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.shopMode = t;
      this.updatePrezzoFinale();
    });

    let inversoDiscount = (1 - (this.item.discount || 0) / 100) * (1 - (this.item.discount2 || 0) / 100) * (1 - (this.item.discount3 || 0) / 100);
    this.item.prezzoVendita = inversoDiscount * this.item.prezzo;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges() {

    if (!this.item) return;

    this.accessoryTotal = 0;
    this.quotationService.fillItemDetail(this.item);
    this.updatePrezzoFinale();
    this.checkKelvin();
  }

  setPopperItem(item) {
    this.inplaceCurrentSection = this.section.key;
    this.inplaceAllowedSections = this.sections.filter(t => t.key != "").map(t => {
      return {
        value: t.key,
        label: t.value.name
      }
    });
    this.inplaceSection = false;
    this.popperItem = item;
  }

  changeSection() {
    if (this.inplaceCurrentSection === this.section.key) {
      //no changes
      this.inplaceSection = false;
      return;
    }
    this.section.value.items = this.section.value.items.filter(t => t.configurationId != this.item.configurationId);
    if (this.section.key != "") {
      this.list.section.find(t => t.sectionId == this.section.key).item = this.list.section.find(t => t.sectionId == this.section.key).item.filter(t => t != this.item.configurationId);
    }
    this.sections.find(t => t.key == this.inplaceCurrentSection).value.items.push(this.item);
    if (this.inplaceCurrentSection != "") {
      this.list.section.find(t => t.sectionId == this.inplaceCurrentSection).item.push(this.item.configurationId);

    }

    this.list.items = [].concat.apply([], this.sections.map(d => d.value.items));
  }

  gotoConfiguration(item) {
    this.listService.setCurrentList(this.list);
    this.catalogService.populateAllCache(this.item.idBrand).subscribe(() => {
      this.router.navigate(this.storeService.createUrl(["/product", item.productModel, item.configurationId]) as any[], {
        queryParams: {
          returnTo: ReturnTo.quotation,
        }
      });
    });
  }

  removeItem(configurationId) {
    if (confirm(this.translations["confirmDeleteItemFromQuotation"])) {
      this.list.items = this.list.items.filter(i => i.configurationId != configurationId);
      this.section.value.items = this.section.value.items.filter(i => i.configurationId != configurationId);
      this.popperListContent.hide();
      this.onTotalChange.emit(this.shopMode);
    }
  }

  deleteChildItem(acc) {
    if (confirm(this.translations["confirmDeleteAccessoryFromVariant"])) {
      this.item.childItems = this.item.childItems.filter(c => c.referenceId != acc.referenceId);

      this.updatePrezzoFinale();
    }
  }

  toggleAccessories() {
    this.showAccessories = !this.showAccessories;
  }

  //per sku e' voluto
  updateDiscountAccessory(acc) {
    for (let item of this.list.items) {
      if (item.sku == acc.sku) {
        item.discount = acc.discount;
        item.discount2 = acc.discount2;
        item.discount3 = acc.discount3;
      }
      if (item.childItems) {
        item.childItems.filter(t => t.sku == acc.sku).forEach(art => {
          art.discount = acc.discount;
          art.discount2 = acc.discount2;
          art.discount3 = acc.discount3;
        });
      }
    }
    this.updatePrezzoFinale();
  }

  //per sku e' voluto
  updateDiscountVariant(variant) {
    let inversoDiscount = (1 - (variant.discount || 0) / 100) * (1 - (variant.discount2 || 0) / 100) * (1 - (variant.discount3 || 0) / 100);
    this.item.prezzoVendita = inversoDiscount * variant.prezzo;
    this.list.items.filter(t => t.sku == variant.sku).forEach(art => {
      art.prezzoVendita = this.item.prezzoVendita;
      art.discount = variant.discount;
      art.discount2 = variant.discount2;
      art.discount3 = variant.discount3;
    });
    this.updatePrezzoFinale();
  }

  //per sku e' voluto
  updatePriceVariant(newPrice) {
    if (newPrice > this.item.prezzo) {
      newPrice = this.item.prezzo;
      this.item.prezzoVendita = this.item.prezzo;
      //this.maskDirectives.forEach(t => t.writeValue(this.item.prezzo));
    }
    this.item.discount = 100 - newPrice * 100 / this.item.prezzo;
    this.item.discount2 = this.item.discount3 = 0;
    this.list.items.filter(t => t.sku == this.item.sku).forEach(art => {
      art.discount = this.item.discount;
      art.prezzoVendita = this.item.prezzoVendita;
      art.discount2 = art.discount3 = 0;
    });
    this.updatePrezzoFinale();
  }

  selectOnfocus(event: FocusEvent) {
    (<HTMLInputElement>event.target).select()
  }

  clone(item) {
    this.listService.cloneQuotationItem(this.list.id, item).subscribe(t => {

      this.popperListContent.hide();
      this.onClone.emit(t);
    });
  }

  updatePrezzoFinale() {
    if (!this.item.quantity) {
      this.item.quantity = 1;
    }

    let allOk = this.item.prezzo && this.item.prezzoRiservato && this.item.quantity;
    if (allOk) {
      if (this.shopMode == 0) {
        this.prezzoFinale = (this.item.prezzoRiservato * this.item.quantity);
      }
      else {
        let inversoDiscount = (1 - (this.item.discount || 0) / 100) * (1 - (this.item.discount2 || 0) / 100) * (1 - (this.item.discount3 || 0) / 100);
        this.prezzoFinale = inversoDiscount * this.item.prezzo * this.item.quantity;
      }
    } else {

      this.prezzoFinale = 0;
      this.accessoryTotal = 0;

      this.item.esWarning = true;

      return;
    }
    let counter = 0;

    if (this.item.childItems) {
      for (let cItem of this.item.childItems) {
        if (!cItem.quantity) {
          cItem.quantity = 1;
        }

        allOk = allOk && cItem.prezzoRiservato && cItem.prezzo && cItem.quantity;
        if (!allOk) {
          break;
        }

        if (cItem.prezzo) {
          if (this.shopMode == 0) {
            counter += (cItem.prezzoRiservato * cItem.quantity);
          }
          else {
            let inversoDiscountChild = (1 - cItem.discount / 100) * (1 - cItem.discount2 / 100) * (1 - cItem.discount3 / 100);
            counter += inversoDiscountChild * cItem.prezzo * cItem.quantity;
          }
            
        }
      }
    }
    if (allOk) {
      this.accessoryTotal = counter;
      this.prezzoFinale = counter + this.prezzoFinale;
      this.item.esWarning = false;
      this.onTotalChange.emit(this.shopMode);
    } else {
      this.accessoryTotal = 0;
      this.prezzoFinale = 0;
      this.item.esWarning = true;
    }
  }

  checkKelvin() {
    this.onKelvinCheck.emit({ configurationId: this.item.configurationId, colors: this.item.colors });
  }

  goToProduct() {
    this.catalogService.populateAllCache(this.item.idBrand).subscribe(() => {
      this.router.navigate(this.storeService.createUrl(['/product', this.item.productModel]) as any[]);
    });
  }
}
