import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from '../store.service';
import { UserPreferenceService } from '../user-preference.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { takeUntil, filter } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Iban, Bank, BicSwift } from '../shared/costants';
import { CatalogService } from '../catalog.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.css']
})
/** creditCards component*/
export class PaymentMethodsComponent implements OnInit, OnDestroy {

  loading: boolean;
  insertNewCard: boolean;

  userdata: any;
  creditCards: any[];
  creditCardTemp: any;

  destroy$: Subject<boolean> = new Subject<boolean>();
  translations: any = {};

  sendNewCard: boolean;
  cardElement: any;
  stripe: any;
  intestatarioCarta: string;
  
  loadingInfoPagamento: boolean;
  infoCustomerPagamentoStdB2b : any;

  bank = Bank;
  iban = Iban;
  swift = BicSwift;

  private fragment: string;

  constructor(private userService: UserPreferenceService, private messageService: MessageService,
    private confirmationService: ConfirmationService, private route: ActivatedRoute,
    private authService: AuthenticationService, private storeService: StoreService, private catalogService : CatalogService) {

  }

  //per scrollare ad una certa view
  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

  //per scrollare ad una certa view
  ngOnInit(): void {
    this.route.fragment.pipe(takeUntil(this.destroy$)).subscribe(fragment => { this.fragment = fragment; });
    
    this.loadingInfoPagamento = true;
    forkJoin([this.userService.getMetodoPagamentoDefault(), this.catalogService.getPaymentConditions()]).subscribe(([pagDefault, conditions]) => {
      this.infoCustomerPagamentoStdB2b = pagDefault;
      let result = conditions.find(c => c.codCondizPagamento.toUpperCase() ==
         this.infoCustomerPagamentoStdB2b.codiceCondizPagamento.toUpperCase());

      if(result) {
        this.infoCustomerPagamentoStdB2b.codiceCondizPagamentoDescr = result.des;
      }

      this.loadingInfoPagamento = false;
    });

    this.userdata = this.authService.getUserProfile();
    if (this.userdata.customerIdStripe) {
      this.loading = true;
      this.userService.getAllCard().subscribe(data => {
        this.creditCards = data;
        this.loading = false;
        if (!this.creditCards) {
          this.insertNewCard = true;
        }
      });
    } else {
      this.insertNewCard = true;
    }
    let translationRequired = marker(['mustCompileAllCard', 'verifyCreditCard', 'warningAllUpper', 'proceed', 'cancel', 'areAyouSureDeleteCard', 'cardRefusedOrNotValid']);
    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.stripe = Stripe(environment.stripePublicKey);
    let elements = this.stripe.elements({
      locale: this.storeService.translateService.currentLang
    });
    this.cardElement = elements.create('card', {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: "#363636",
          color: "#363636",
          fontWeight: 400,
          fontFamily: "'Work Sans', sans-serif",
          fontSize: "14px",
          fontSmoothing: "antialiased",

          "::placeholder": {
            color: "#666"
          },
          ":-webkit-autofill": {
            color: "#666"
          }
        },
        invalid: {
          iconColor: "#ff3860",
          color: "#ff3860"
        }
      }
    });
    this.cardElement.mount('#formNewCreditCard');

  

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addCard() {
    if (!this.intestatarioCarta) {
      this.messageService.add({ key: 'toastErrorCreditCard', severity: 'error', summary: this.translations["error"], detail: this.translations.mustProvideHeader });
      return;
    }
    this.sendNewCard = true;
    this.stripe.createPaymentMethod('card', this.cardElement, {
      billing_details: { name: this.intestatarioCarta },
    }).then((result) => {
      if (result.error) {
        this.messageService.add({ key: 'toastCardErrorModify', severity: 'error', summary: this.translations["error"], detail: this.translations.verifyCreditCard });
        this.sendNewCard = false;
        return;
        // Show error in payment form
      } else {
        this.userService.addCard(result.paymentMethod.id).subscribe(data => {

          if (!this.creditCards) {
            this.creditCards = []
          }

          this.creditCards.push(data);
          this.sendNewCard = false;
          this.insertNewCard = false;
        }, err => {
          this.sendNewCard = false;
            this.messageService.add({
              key: 'toastCardErrorModify', severity: 'error',
              summary: this.translations.error, detail: this.translations.cardRefusedOrNotValid
            });

        });
      }
    });
  }

  editing(card: any) {
    this.creditCardTemp = cloneDeep(card);
  }

  saveMethod(card: any) {
    if (!card.holderCard || !card.holderCard || !card.expireMonth || !card.expireYear) {
      this.messageService.add({
        key: 'toastCardErrorModify', severity: 'error', summary: this.translations.error, detail: this.translations.mustCompileAllCard
      });
      return;
    }

    this.userService.updateCard(card).subscribe(data => {
      this.creditCardTemp = undefined;
      let index = this.creditCards.findIndex(c => c.id == card.id);
      if (index != -1) {
        this.creditCards[index] = data;
      }
    }, err => {
        this.messageService.add({
          key: 'toastCardErrorModify', severity: 'error', summary: this.translations.error, detail: this.translations.cardRefusedOrNotValid
      });
    });
  }

  deleteCard(idCard: string) {
    this.confirmationService.confirm({
      key: 'confirmDeleteCardFromProfile',
      header: this.translations.warningAllUpper,
      message: this.translations.areAyouSureDeleteCard,
      acceptLabel: this.translations.proceed,
      rejectLabel: this.translations.cancel,
      accept: () => {
        this.userService.deleteCard(idCard).subscribe(() => {
          this.creditCards = this.creditCards.filter(c => c.id != idCard);
        });
      },
      reject: () => {
        return;
      }
    });
  }
}
