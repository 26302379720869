import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ListService } from './list.service';
import { tap, catchError } from 'rxjs/operators';
import { ServiceMoment } from './factory-moment.service';
import { CatalogService } from './catalog.service';
import { maxBy } from 'lodash';
import { CheckoutService } from './checkout.service';
import { AuthenticationService } from './services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private tolleranzaGestioneOrdine: number = 3;

  private httpHeadersDefault: HttpHeaders;

  exWorksAddress: any = {
    ragSoc1: "Macrolux Srl",
    indirizzo1: "Via Padania 69",
    cap: "31020",
    localita1: "San Vendemiano",
    provincia: "TV",
    codStato: "IT",
  };

  shippingModes: { [id: number]: string; } = {
    1: "CON CORRIERE MACROLUX",
    2: "CON CORRIERE CLIENTE",
    3: "NS RITIRO (EX WORKS)",
  };

  paymentModes: { [id: number]: string; } = {
    1: "",
    2: "X001",
    3: "BB251",
    4: "X001"
  };

  brands: any[];

  constructor(private httpClient: HttpClient, private authService: AuthenticationService, private catalogService: CatalogService,
    private listService: ListService, private checkoutService: CheckoutService, private serviceMoment: ServiceMoment) {
    this.httpHeadersDefault = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  generatePayment(paymentMethodId: string, amount: number) {
    let integerAmount = Math.round(amount * 100);

    var payload = {
      paymentMethodId: paymentMethodId,
      amount: integerAmount,
      currency: 'eur'

    };
    return this.httpClient.post("/api/order/paymentIntent", JSON.stringify(payload), { headers: this.httpHeadersDefault }).pipe(
      catchError(err => {
        return throwError(() => new Error(err));
      })
    );
  }

  confirmPayment(paymentId: string) {
    var payload = {
      paymentIntentId: paymentId
    };
    return this.httpClient.post("/api/order/confirmBySecureCode", JSON.stringify(payload), { headers: this.httpHeadersDefault })
  }

  send(cartDetail: any, listId: string, listType: string, userProfile: any,
    forcedItems: any[] = [], disableCart1: boolean, disableCart2: boolean, idListino: number,
    disableSendMailCustomer: boolean = false, modalitaListino2: boolean = false): Observable<any> {

    this.brands = this.catalogService.getBrandsSync();

    const userClaims = this.authService.getUserProfile();
    var orderModel = {
      disableSendMailCustomer: disableSendMailCustomer,
      listId: listId,
      listType: listType === "q" ? 1 : 0,
      orders: []
    };

    let total = cartDetail.totals.reduce((total, num) => total + num);

    if (userProfile) {
      this.paymentModes[1] = userProfile.condPag;
    } else {
      this.paymentModes[1] = userClaims.condPag;
    }

    if (cartDetail.anthology && cartDetail.anthology.length > 0) {
      let index = 0;
      let items = cartDetail.anthology;

      let dataConsegna = this.serviceMoment.addDayBusiness(2).toDate(); //moment-business usa UTC internamente
      let shippingAddress = cartDetail.shipAddressMultiple ? cartDetail.shipAddress[index] : cartDetail.shipAddress[2];
      let payment = cartDetail.paymentModeMultiple ? cartDetail.payments[index] : cartDetail.payments[2];
      let shipMode = cartDetail.shipModeMultiple ? cartDetail.shipModes[index] : cartDetail.shipModes[2];
      let shipCost = cartDetail.shipCosts[index];

      let order: any = {
        orderType: index,
        orderItems: [],
        indirizzoFatturazione: { numProgr: 0 },
        indirizzoSpedizione: shipMode == 3 ? this.exWorksAddress : {
          numProgr: 0,
          ragSoc1: shippingAddress.company.hasOwnProperty("ragSoc1") ? shippingAddress.company.ragSoc1 : shippingAddress.company,
          indirizzo1: shippingAddress.address1,
          //indirizzo2: shippingAddress.address2,
          cap: shippingAddress.zipCode,
          localita1: shippingAddress.city1,
          //provincia: shippingAddress.provincia,
          codStato: shippingAddress.countryCode,
        },
        riferimento: cartDetail.riferimento,
        codCondizPagamento: this.paymentModes[payment],
        descrPagamento: cartDetail.paymentDescription[index],
        cliente: {
          codCliFor: parseInt(cartDetail.assignedCodCliFor || userClaims.codCli)
        },
        descrTrasporto: cartDetail.shipDescription[index],
        trasportoAMezzo: this.shippingModes[shipMode],
        spese1: shipCost,
        spese2: 0,
        spese3: "0",
        projectDiscountPerc: cartDetail.projectDiscount > 0 ? cartDetail.projectDiscount : 0,
        extraDiscountPerc: cartDetail.extraDiscount,
        tipoDocumento: 0,
        notaDocumento: (`${shippingAddress.firstName || ''} ${shippingAddress.lastName || ''} ${shippingAddress.telephoneNumber1 || ''} ${shippingAddress.emailAddress || ''}`)
          .trim().replace(';', ' '),
        idListino: idListino,
        totalNoDiscountOrder: cartDetail.totals[index]
      };

      if (cartDetail.projectDiscount > 0) {
        order.spese2 = Math.abs(cartDetail.discountsProj[index].toFixed(2));
      }

      if (cartDetail.checkPrizeApplied) {
        order.spese3 = (cartDetail.totals[index] * cartDetail.checkPrizeApplied / total).toFixed(2);
      }

      if (cartDetail.idTrasactionPaypal && payment == 4) {
        order.paypalTransactionId = cartDetail.idTrasactionPaypal;
      }

      let dataConsegnaRichiesta = dataConsegna.toISOString(); //moment-business usa UTC internamente

      for (let item of items) {
        let orderItem = this.generateOrderItem(item, forcedItems, modalitaListino2, dataConsegnaRichiesta);
        order.orderItems.push(orderItem);
      }

      if (cartDetail.configurationIds && cartDetail.configurationIds.length > 0) {
        order.orderItems = order.orderItems.concat(this.generateRowsItemConfiguration(dataConsegnaRichiesta, cartDetail.configurationIds));
      }

      let noteOrderItem: any = []
      if (cartDetail.noteDaInviare && cartDetail.noteDaInviare.length) {
        for (let nota of cartDetail.noteDaInviare) {
          let notaOrderItem = {
            tipoRiga: 20,
            descrizioneEstesa: nota.text,
            quantity: 1,
            tipoStampaCommento: nota.tipoStampaCommento,
            dataConsegnaRichiesta: dataConsegnaRichiesta
          }
          noteOrderItem.push(notaOrderItem);
        }
      }
      order.orderItems = order.orderItems.concat(noteOrderItem);
      orderModel.orders.push(order);
    }

    if (cartDetail.reserve && cartDetail.reserve.length > 0) {
      let index = 1;
      let items = cartDetail.reserve;

      let dataConsegna = this.serviceMoment.addDayBusiness(12).toDate(); //moment-business usa UTC internamente

      let shippingAddress = cartDetail.shipAddressMultiple ? cartDetail.shipAddress[index] : cartDetail.shipAddress[2];
      let payment = cartDetail.paymentModeMultiple ? cartDetail.payments[index] : cartDetail.payments[2];
      let shipMode = cartDetail.shipModeMultiple ? cartDetail.shipModes[index] : cartDetail.shipModes[2];
      let shipCost = cartDetail.shipCosts[index];

      let order: any = {
        orderType: index,
        orderItems: [],
        indirizzoFatturazione: { numProgr: 0 },
        indirizzoSpedizione: shipMode == 3 ? this.exWorksAddress : {
          ragSoc1: shippingAddress.company.hasOwnProperty("ragSoc1") ? shippingAddress.company.ragSoc1 : shippingAddress.company,
          indirizzo1: shippingAddress.address1,
          //indirizzo2: shippingAddress.address2,
          cap: shippingAddress.zipCode,
          localita1: shippingAddress.city1,
          //provincia: shippingAddress.provincia,
          codStato: shippingAddress.countryCode,
        },
        riferimento: cartDetail.riferimento,
        codCondizPagamento: this.paymentModes[payment],
        descrPagamento: cartDetail.paymentDescription[index],
        cliente: {
          codCliFor: parseInt(cartDetail.assignedCodCliFor || userClaims.codCli)
        },
        descrTrasporto: cartDetail.shipDescription[index],
        trasportoAMezzo: this.shippingModes[shipMode],
        spese1: shipCost,
        spese2: 0,
        spese3: "0",
        projectDiscountPerc: cartDetail.projectDiscount > 0 ? cartDetail.projectDiscount : 0,
        extraDiscountPerc: cartDetail.extraDiscount,
        tipoDocumento: 1,
        notaDocumento: (`${shippingAddress.firstName || ''} ${shippingAddress.lastName || ''} ${shippingAddress.telephoneNumber1 || ''} ${shippingAddress.emailAddress || ''}`)
          .trim().replace(';', ' '),
        idListino: idListino,
        totalNoDiscountOrder: cartDetail.totals[index]
      };

      if (cartDetail.projectDiscount > 0) {
        order.spese2 = Math.abs(cartDetail.discountsProj[index].toFixed(2));
      }

      if (cartDetail.idTrasactionPaypal && payment == 4) {
        order.paypalTransactionId = cartDetail.idTrasactionPaypal;
      }

      if (cartDetail.checkPrizeApplied)
        order.spese3 = (cartDetail.totals[index] * cartDetail.checkPrizeApplied / total).toFixed(2);

      let dataConsegnaRichiesta = dataConsegna.toISOString(); //moment-business usa UTC internamente

      for (let item of items) {
        let orderItem = this.generateOrderItem(item, forcedItems, modalitaListino2, dataConsegnaRichiesta);
        order.orderItems.push(orderItem);
      }

      if (cartDetail.configurationIds && cartDetail.configurationIds.length > 0) {
        order.orderItems = order.orderItems.concat(this.generateRowsItemConfiguration(dataConsegnaRichiesta, cartDetail.configurationIds));
      }

      let noteOrderItem: any = []
      if (cartDetail.noteDaInviare && cartDetail.noteDaInviare.length) {
        for (let nota of cartDetail.noteDaInviare) {
          let notaOrderItem = {
            tipoRiga: 20,
            descrizioneEstesa: nota.text,
            quantity: 1,
            tipoStampaCommento: nota.tipoStampaCommento,
            dataConsegnaRichiesta: dataConsegnaRichiesta
          }
          noteOrderItem.push(notaOrderItem);
        }
      }
      order.orderItems = order.orderItems.concat(noteOrderItem);
      orderModel.orders.push(order);
    }

    if (cartDetail.onDemand && cartDetail.onDemand.length > 0) {
      let index = 2;
      let items = cartDetail.onDemand;

      let dataConsegna : Date;
      if (cartDetail.shipDate3) {
        dataConsegna = this.serviceMoment.subtractDayBusiness(this.tolleranzaGestioneOrdine, cartDetail.shipDate3).toDate();
      } else {
        let settMinimeConsegna = maxBy(cartDetail.onDemand as any[], 'leadTime').leadTime;
        dataConsegna = this.serviceMoment.addDayBusiness(settMinimeConsegna * 5 - this.tolleranzaGestioneOrdine).toDate(); //moment-business usa UTC internamente
      }

      let shippingAddress = cartDetail.shipAddressMultiple ? cartDetail.shipAddress[index] : cartDetail.shipAddress[2];
      let payment = cartDetail.paymentModeMultiple ? cartDetail.payments[index] : cartDetail.payments[2];
      let shipMode = cartDetail.shipModeMultiple ? cartDetail.shipModes[index] : cartDetail.shipModes[2];
      let shipCost = cartDetail.shipCosts[index];

      let order: any = {
        orderType: index,
        orderItems: [],
        disableAnthology: disableCart1 ? true : undefined,
        disableReserve: disableCart2 ? true : undefined,

        indirizzoFatturazione: { numProgr: 0 },
        indirizzoSpedizione: shipMode == 3 ? this.exWorksAddress : {
          ragSoc1: shippingAddress.company.hasOwnProperty("ragSoc1") ? shippingAddress.company.ragSoc1 : shippingAddress.company,
          indirizzo1: shippingAddress.address1,
          //indirizzo2: shippingAddress.address2,
          cap: shippingAddress.zipCode,
          localita1: shippingAddress.city1,
          //provincia: shippingAddress.provincia,
          codStato: shippingAddress.countryCode
        },
        riferimento: cartDetail.riferimento,
        codCondizPagamento: this.paymentModes[payment],
        descrPagamento: cartDetail.paymentDescription[index],
        cliente: {
          codCliFor: parseInt(cartDetail.assignedCodCliFor || userClaims.codCli)
        },
        descrTrasporto: cartDetail.shipDescription[index],
        trasportoAMezzo: this.shippingModes[shipMode],
        spese1: shipCost,
        spese2: 0,
        spese3: "0",
        projectDiscountPerc: cartDetail.projectDiscount > 0 ? cartDetail.projectDiscount : 0,
        extraDiscountPerc: cartDetail.extraDiscount,
        tipoDocumento: 1,
        notaDocumento: (`${shippingAddress.firstName || ''} ${shippingAddress.lastName || ''} ${shippingAddress.telephoneNumber1 || ''} ${shippingAddress.emailAddress || ''}`)
          .trim().replace(';', ' '),
        idListino: idListino,
        totalNoDiscountOrder: cartDetail.totals[index]
      };

      if (cartDetail.projectDiscount > 0) {
        order.spese2 = Math.abs(cartDetail.discountsProj[index].toFixed(2));
      }

      if (cartDetail.idTrasactionPaypal && payment == 4) {
        order.paypalTransactionId = cartDetail.idTrasactionPaypal;
      }

      if (cartDetail.checkPrizeApplied > 0)
        order.spese3 = (cartDetail.totals[index] * cartDetail.checkPrizeApplied / total).toFixed(2);

      let dataConsegnaRichiesta = dataConsegna.toISOString(); //moment-business usa UTC internamente

      for (let item of items) {
        let orderItem = this.generateOrderItem(item, forcedItems, modalitaListino2, dataConsegnaRichiesta);
        order.orderItems.push(orderItem);
      }

      if (cartDetail.configurationIds && cartDetail.configurationIds.length > 0) {
        order.orderItems = order.orderItems.concat(this.generateRowsItemConfiguration(dataConsegnaRichiesta, cartDetail.configurationIds));
      }

      let noteOrderItem: any = []
      if (cartDetail.noteDaInviare && cartDetail.noteDaInviare.length) {
        for (let nota of cartDetail.noteDaInviare) {
          let notaOrderItem = {
            tipoRiga: 20,
            descrizioneEstesa: nota.text,
            quantity: 1,
            tipoStampaCommento: nota.tipoStampaCommento,
            dataConsegnaRichiesta: dataConsegnaRichiesta
          }
          noteOrderItem.push(notaOrderItem);
        }
      }
      order.orderItems = order.orderItems.concat(noteOrderItem);
      orderModel.orders.push(order);
    }

    return this.httpClient.post("/api/order/send", orderModel, { headers: this.httpHeadersDefault }).pipe(tap(t => {
      if (t.type == 0) {
        this.listService.updateListLocal(t);
      } else {
        this.listService.updateQuotationLocal(t);
      }
    }));
  }

  sendOrderUnique(cartDetail: any, orderType: number, userProfile: any = null, idListino: number,
    disableSendMailCustomer = false, modalitaListino2: boolean = false): Observable<any> {
    const userClaims = this.authService.getUserProfile();

    this.brands = this.catalogService.getBrandsSync();

    var orderModel = {
      disableSendMailCustomer: disableSendMailCustomer,
      listId: cartDetail.id,
      listType: 1,
      orders: []
    };
    if (userProfile) {
      this.paymentModes[1] = userProfile.condPag;
    } else {
      this.paymentModes[1] = userClaims.condPag;
    }
    let items = cartDetail.items;
    let dataConsegna : Date;
    if(orderType == 0) {
      dataConsegna = this.serviceMoment.addDayBusiness(2).toDate();
    } 
    else {
      if (!cartDetail.shipDate) {

        let minSettimaneShip = 3;
    
        for (let item of cartDetail.items) {
          if (item.type === 2) {
            // in caso di configurazione  è considerata disponibile in 48h
            //non cambia nulla
          } else if (item.type === 3) {
            // in caso di item custom gli articoli sono da considerarsi disponbili in almeno 3 settimane
          }
          else {
            // gli articoli disponibili sono sempre evadibili in 48h
            let availableItem = item.quantity <= item.availability
    
            //se articolo disponibile ignoro lead-time
            if (!availableItem) {
              let indexLeadTime = item.attributes.findIndex(at => at.sysName == 'lead-time');
              if (indexLeadTime != -1) {
                let leadTime = item.attributes[indexLeadTime].value;
                if (leadTime > minSettimaneShip) {
                  minSettimaneShip = leadTime;
                }
              }
            }
          }
          if (item.childItems) {
            for (let itemChild of item.childItems) {
              // gli articoli disponibili sono sempre evadibili in 48h
              let availableItemChild = true;
              if (itemChild.um == 'M' && item.type === 2) {
                availableItemChild = this.checkoutService.manageAvailabilityVariantForPlainCheckout(itemChild)
              } else {
                availableItemChild = itemChild.quantity <= itemChild.availability;
              }

              if (!availableItemChild) {
                let indexLeadTimeChild: number = -1;
                if (itemChild.attributes) {//gli itemchild delle configurazioni non hanno gli attributi direttamente
                  indexLeadTimeChild = itemChild.attributes.findIndex(at => at.sysName == 'lead-time');
                } else {
                  indexLeadTimeChild = itemChild.entity.attributes.findIndex(at => at.sysName == 'lead-time');
                }
    
                if (indexLeadTimeChild != -1) {
                  let leadTimeChild : any 
                  if (itemChild.attributes) {//gli itemchild delle configurazioni non hanno gli attributi direttamente
                    leadTimeChild = itemChild.attributes[indexLeadTimeChild].value;
                  } else {
                    leadTimeChild = itemChild.entity.attributes[indexLeadTimeChild].value;
                  }
                  if (leadTimeChild > minSettimaneShip) {
                    minSettimaneShip = leadTimeChild;
                  }
                }
              }
            }
          }
        }
        dataConsegna = this.serviceMoment.addDayBusiness(minSettimaneShip * 5 - this.tolleranzaGestioneOrdine).toDate();
      } else {
        dataConsegna = this.serviceMoment.subtractDayBusiness(this.tolleranzaGestioneOrdine, cartDetail.shipDate).toDate();
      }
    }
  
    let shippingAddress = cartDetail.shipAddress;
    let shipMode = cartDetail.shipMode;
    let shipCost = cartDetail.shipCost;

    let order: any = {
      orderType: orderType,
      orderItems: [],
      indirizzoFatturazione: { numProgr: 0 },
      indirizzoSpedizione: shipMode == 3 ? this.exWorksAddress : {
        ragSoc1: shippingAddress.company.hasOwnProperty("ragSoc1") ? shippingAddress.company.ragSoc1 : shippingAddress.company,
        indirizzo1: shippingAddress.address1,
        //indirizzo2: shippingAddress.address2,
        cap: shippingAddress.zipCode,
        localita1: shippingAddress.city1,
        //provincia: shippingAddress.provincia,
        codStato: shippingAddress.countryCode,
      },
      riferimento: cartDetail.riferimento,
      codCondizPagamento: this.paymentModes[cartDetail.payment],
      descrPagamento: cartDetail.paymentDescription,
      cliente: {
        codCliFor: parseInt(cartDetail.assignedCodCliFor || userClaims.codCli)
      },
      descrTrasporto: cartDetail.shipDescription,
      trasportoAMezzo: this.shippingModes[shipMode],
      spese1: shipCost,
      spese2: 0,
      spese3: "0",
      projectDiscountPerc: cartDetail.projectDiscount > 0 ? cartDetail.projectDiscount : 0,
      extraDiscountPerc: cartDetail.extraDiscount,
      tipoDocumento: orderType == 0 ? 0 : 1,
      notaDocumento: (`${shippingAddress.firstName || ''} ${shippingAddress.lastName || ''} ${shippingAddress.telephoneNumber1 || ''} ${shippingAddress.emailAddress || ''}`)
        .trim().replace(';', ' '),
      section: [],
      idListino: idListino,
      totalNoDiscountOrder: cartDetail.total
    };

    if (cartDetail.discountsProj > 0) {
      order.spese2 = Math.abs(cartDetail.discountsProj.toFixed(2));
    }

    if (cartDetail.idTrasactionPaypal && cartDetail.payment == 4) {
      order.paypalTransactionId = cartDetail.idTrasactionPaypal;
    }

    if (cartDetail.checkPrizeApplied > 0) {
      order.spese3 = cartDetail.checkPrizeApplied.toFixed(2);
    }


    let dataConsegnaRichiesta = dataConsegna.toISOString();
    let sectionsWithItem = cartDetail.section ? cartDetail.section.filter(s => s.item && s.item.length > 0) : undefined;

    let sectionEmpty = {
      name: "",
      item: []
    };
    let itemsIdInSection = [];
    if (sectionsWithItem && sectionsWithItem.length > 0) {
      order.section = sectionsWithItem;
      for (let section of sectionsWithItem) {
        order.orderItems = order.orderItems.concat(this.generateHeaderSezione(dataConsegnaRichiesta, section.name));

        for (let i = 0; i < section.item.length; ++i) {
          let addLastLine = (i + 1) != section.item.length;
          let item = items.find(x => x.configurationId == section.item[i]);
          if (item) {
            order.orderItems = this.addItemToOrderPlain(item, order.orderItems, dataConsegnaRichiesta, addLastLine, modalitaListino2);
          }
        }
        itemsIdInSection = itemsIdInSection.concat(section.item);
      }
    }

    if (itemsIdInSection.length != items.length) {
      let itemsInEmptySection = items.filter(item => itemsIdInSection.indexOf(item.configurationId) == -1);
      order.orderItems = order.orderItems.concat(this.generateHeaderSezione(dataConsegnaRichiesta, "Sezione non definita"));
      for (let i = 0; i < itemsInEmptySection.length; ++i) {
        let addLastLine = (i + 1) != itemsInEmptySection.length;
        let item = itemsInEmptySection[i];
        sectionEmpty.item.push(item.configurationId);
        order.orderItems = this.addItemToOrderPlain(item, order.orderItems, dataConsegnaRichiesta, addLastLine, modalitaListino2);
      }
      order.section.push(sectionEmpty);
    }
    let noteOrderItem: any = []
    if (cartDetail.noteDaInviare && cartDetail.noteDaInviare.length) {
      for (let nota of cartDetail.noteDaInviare) {
        let notaOrderItem = {
          tipoRiga: 20,
          descrizioneEstesa: nota.text,
          quantity: 1,
          tipoStampaCommento: nota.tipoStampaCommento,
          dataConsegnaRichiesta: dataConsegnaRichiesta
        }
        noteOrderItem.push(notaOrderItem);
      }
    }
    order.orderItems = order.orderItems.concat(noteOrderItem);

    orderModel.orders.push(order);

    return this.httpClient.post("/api/order/send", orderModel, { headers: this.httpHeadersDefault }).pipe(tap(t => {
      this.listService.updateQuotationLocal(t);
    }));
  }

  private generateRowsItemConfiguration(dataConsegnaRichiesta, configurationIds) : any[] {
    let configurazioni: any[] = [];
    for(let id of configurationIds) {
      configurazioni.push({
        tipoRiga: 20,
        descrizioneEstesa: "Configurazione: #" + id,
        quantity: 1,
        dataConsegnaRichiesta: dataConsegnaRichiesta
      })
    }
    return configurazioni
  }

  private generateHeaderSezione(dataConsegnaRichiesta, nameSezione): any[] {
    return [
      this.generateSeparatorRow(dataConsegnaRichiesta),
      {
        tipoRiga: 20,
        descrizioneEstesa: nameSezione,
        quantity: 1,
        dataConsegnaRichiesta: dataConsegnaRichiesta
      },
      this.generateSeparatorRow(dataConsegnaRichiesta)
    ];
  }

  private addItemToOrderPlain(item: any, orderItems: any[], dataConsegnaRichiesta: any,
    addLastLine: boolean, modalitaListino2: boolean): any[] {
    if (item.type == 2) {
      orderItems.push(this.generateRowItemConfiguration(dataConsegnaRichiesta, item.configurationId));
    } else {
      if (item.type == 3) {
        item.prezzo = item.custom.prezzoVendita;
      }

      let inversoDiscount = 1;
      if(modalitaListino2) {
        inversoDiscount = (1 - (item.discount || 0) / 100) * (1 - (item.discount2 || 0) / 100) * (1 - (item.discount3 || 0) / 100);
      }

      let orderItem: any = {
        configurationId: item.configurationId,
        qtyAvailable: (item.availability || item.availability == 0) ? item.availability : undefined,
        tipoRiga: item.type == 3 ? 30 : 1,
        codArt: item.sku,
        descrizione: item.type == 3 ? item.custom.name : item.name,
        quantity: item.quantity,
        unitPrice: modalitaListino2 ? item.prezzo * inversoDiscount : item.prezzoRiservato,
        dataConsegnaRichiesta: dataConsegnaRichiesta,
        unitaMisura: item.type == 3 ? item.custom.unitaMisura : item.entity.um
      };

      if (item.type == 0) { //e' una variante
        orderItem.entityVariant = item.entity;
        orderItem.idBrand = item.entity.idBrand;
        orderItem.nameBrand = this.brands.find(b => b.id == item.entity.idBrand).name;
      } else if (item.type == 1) {
        orderItem.entityAccessory = item.entity;
        orderItem.idBrand = item.entity.idBrand;
        orderItem.nameBrand = this.brands.find(b => b.id == item.entity.idBrand).name;
      }
      orderItems.push(orderItem);
    }
    if (item.childItems && item.childItems.length > 0) {
      for (let cItem of item.childItems) {

        let inversoDiscountChild = 1;
        if(modalitaListino2) {
          inversoDiscountChild = (1 - (item.discount || 0) / 100) * (1 - (item.discount2 || 0) / 100) * (1 - (item.discount3 || 0) / 100);
        }
        let orderItemChild: any = {
          configurationId: cItem.configurationId,
          tipoRiga: 1,
          codArt: cItem.sku,
          descrizione: cItem.name,
          quantity: cItem.quantity,
          unitPrice: modalitaListino2 ? cItem.prezzo * inversoDiscountChild : cItem.prezzoRiservato,
          dataConsegnaRichiesta: dataConsegnaRichiesta,
        };
        if (cItem.type == 0) { //e' una variante
          orderItemChild.entityVariant = cItem.entity;
          orderItemChild.idBrand = cItem.entity.idBrand;
          orderItemChild.nameBrand = this.brands.find(b => b.id == cItem.entity.idBrand).name;
        } else { //qui puo' essere solo accessorio, custom mai figlio
          orderItemChild.entityAccessory = cItem.entity;
          orderItemChild.idBrand = cItem.entity.idBrand;
          orderItemChild.nameBrand = this.brands.find(b => b.id == cItem.entity.idBrand).name;
        }
        orderItems.push(orderItemChild);
      }

    }
    if (addLastLine) {
      orderItems.push(this.generateSeparatorRow(dataConsegnaRichiesta));
    }
    return orderItems;
  }

  private generateSeparatorRow(dataConsegnaRichiesta: any): any {
    let minus = "-"
    return {
      tipoRiga: 20,
      descrizioneEstesa: minus.repeat(20),
      quantity: 1,
      dataConsegnaRichiesta: dataConsegnaRichiesta
    };
  }

  private generateRowItemConfiguration(dataConsegnaRichiesta: any, configurationId: string) {
    return {
      configurationId: configurationId,
      tipoRiga: 20,
      descrizione: configurationId,
      descrizioneEstesa: "Configurazione: " + configurationId,
      quantity: 1,
      dataConsegnaRichiesta: dataConsegnaRichiesta
    };
  }

  private generateOrderItem(item: any, forcedItems: any[], modalitaListino2: boolean, dataConsegnaRichiesta: any): any {

    let inversoDiscount = 1;
    if(modalitaListino2) {
      inversoDiscount = (1 - (item.discount || 0) / 100) * (1 - (item.discount2 || 0) / 100) * (1 - (item.discount3 || 0) / 100);
    }
    let orderItem: any = {
      tipoRiga: item.type == 3 ? 30 : 1,
      forcedToProduction: (forcedItems.indexOf(item.sku) > -1) ? true : undefined,
      qtyAvailable: (item.entity.qtaDisponibile || item.entity.qtaDisponibile == 0) ? item.entity.qtaDisponibile : undefined,
      codArt: item.sku,
      descrizione: item.type == 3 ? item.entity.name : item.entity.esolverName,
      quantity: item.quantity,
      unitPrice: modalitaListino2 ? item.entity.prezzo * inversoDiscount : item.entity.prezzoRiservato,
      dataConsegnaRichiesta: dataConsegnaRichiesta,
      unitaMisura: item.type == 3 ? item.entity.unitaMisura : item.entity.um,
      configurationId: item.configurationId,
      configForceToReserve: item.forceToReserve,
    }
    if (item.entity.images) { //e' una variante
      orderItem.entityVariant = item.entity;
      orderItem.idBrand = item.entity.idBrand;
      orderItem.nameBrand = this.brands.find(b => b.id == item.entity.idBrand).name;
    } else if (item.type != 3) { // accessorio
      orderItem.entityAccessory = item.entity;
      orderItem.idBrand = item.entity.idBrand;
      orderItem.nameBrand = this.brands.find(b => b.id == item.entity.idBrand).name;
    }
    return orderItem;
  }
}
