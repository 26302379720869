import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import { ListService } from "../list.service";
import { CatalogService } from "../catalog.service";
import { List } from 'immutable';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StoreService } from '../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MessageService } from 'primeng/api';
import { IdAggStucchi } from '../shared/costants';

@Component({
  selector: 'app-accessory-category',
  templateUrl: './accessory-category.component.html',
  styleUrls: ['./accessory-category.component.css']
})
export class AccessoryCategoryComponent implements OnInit, OnDestroy {

  category: any;
  accessories: any[];
  currentList: any;
  shopMode: number;
  selectedAccessories: List<any>;
  skuFilter: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  //accManagedStock: string;

  translations: any = {};

  coloreSfondoAccessories: string = "#ececec" //#e0e1e3

  constructor(private router: Router, private route: ActivatedRoute, private storeService: StoreService,
    private catalogService: CatalogService, private listService: ListService, private messageService: MessageService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    let translationRequired = marker(['accManagedStock', 'quantityError', 'error']);

    this.catalogService.getCurrentBrandId().pipe(takeUntil(this.destroy$)).subscribe((brand) => {
        if(brand == IdAggStucchi) {
          this.coloreSfondoAccessories = "#e0e1e3";
        } else {
          this.coloreSfondoAccessories = "#ececec";
        }

    });

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.category = this.route.parent.snapshot.data.categories.find(t => t.id == this.route.snapshot.paramMap.get('id'));
    if (!this.category) {
      this.router.navigate(this.storeService.createUrl(["/accessories", this.route.parent.snapshot.data.categories[0].id]) as any[]);
      return;
    }

    this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe((data) => {

      var name = data.get("name");
      if (name) {
        this.skuFilter = name;
      }
    });

    this.accessories = this.route.snapshot.data.accessories.filter(t => t.esWarning === false);
    this.listService.resetCurrentConfiguration();
    this.listService.getCurrentConfiguration().subscribe(tmpItems => {
      this.selectedAccessories = tmpItems.filter(x => x.type == 1).toList();
      for (let accessory of this.accessories) {
        let index = tmpItems.findIndex(t => t.referenceId == accessory.id && t.type == 1);
        accessory.selected = index != -1;
        if (!accessory.selected)
          accessory.quantity = null;
        else {
          accessory.quantity = tmpItems.get(index).quantity;
          if (tmpItems.get(index).sku && tmpItems.get(index).sku != accessory.sku) {
            accessory.selectedPack = accessory.packs.find(t => t.sku == tmpItems.get(index).sku);
          }
        }
      }
    });
    this.listService.getCurrentList().subscribe(t => this.currentList = t);
    this.catalogService.getShopMode().subscribe(t => this.shopMode = t);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  addToCart(accessory) {
    let sku = accessory.selectedPack ? accessory.selectedPack.sku : accessory.sku;
    if (accessory.selected) {
      accessory.quantity = 1;
      this.listService.addOrUpdateConfigurationItem({
        referenceId: accessory.id,
        sku: sku,
        quantity: accessory.quantity,
        type: 1
      });
    } else {
      accessory.quantity = null;
      this.listService.removeConfigurationItem({ referenceId: accessory.id, type: 1 });
    }
  }

  onQtyChange(accessory) {
    let sku = accessory.selectedPack ? accessory.selectedPack.sku : accessory.sku;
    this.listService.addOrUpdateConfigurationItem({
      referenceId: accessory.id,
      quantity: accessory.quantity,
      type: 1,
      sku: sku
    });
  }

  selectPack(accessory, pack) {
    if (accessory.selectedPack) {
      delete accessory.selectedPack;
      return;
    }

    accessory.selectedPack = pack;
    if (accessory.selected) {
      accessory.quantity = 1;
      this.listService.addOrUpdateConfigurationItem({
        referenceId: accessory.id,
        quantity: accessory.quantity,
        type: 1,
        sku: pack.sku
      });
    }
  }

  addToList(reset: boolean) {
    let obs = this.listService.addToList(reset);
    if (!obs) {
      this.messageService.add({ key: 'toastErrorAccessory', severity: 'error', summary: this.translations["error"], detail: this.translations["quantityError"] });
      return;
    }

    obs.subscribe((t) => {
      if (reset && this.currentList) {
        let destinationPage: string;
        if (this.currentList.type == 0) {
          destinationPage = "/lists";
        } else {
          destinationPage = "/quotations";
        }
        this.router.navigate(this.storeService.createUrl([destinationPage, this.currentList.id]) as any[]);
      }
    });
  }

  cancel() {
    this.listService.resetCurrentConfiguration();
  }
}
