import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ProductService } from "../product.service";
import { ReplaySubject, combineLatest } from 'rxjs';

@Component({
  selector: 'attribute-control',
  templateUrl: './attribute-control.component.html',
  styleUrls: ['./attribute-control.component.css']
})
export class AttributeControlComponent implements OnInit, OnChanges {

  @Input()
  product: any;

  @Input()
  editMode: any;

  filterableAttributes: any[];
  stdFilterableAttributes: any[];
  attributes: any[];

  currentVariant: any;
  currentChoice: any;

  index: number = 0;
  stdView: boolean = false;

  needSelectionVisible: boolean = false;
  additionalChoices: any[];

  private _product$ : ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private productService: ProductService) {
  }

  ngOnInit() {
    combineLatest([this.productService.getFiltrabeAttribute(), this.productService.getCurrentVariant(), this._product$.asObservable()])
      .subscribe(([{ normal, standard }, v, prod]) => {
        this.filterableAttributes = normal;
        this.stdFilterableAttributes = standard;
        this.currentVariant = v;
        this.stdView = prod.productsSelectableVariant.filter(t => t.attributes.some(x => x.sysName === "standard" && x.value == true))
          .some(t => t.id == v.id);
        this.attributes = this.stdView ? this.stdFilterableAttributes : this.filterableAttributes;
      });
  }

  ngOnChanges() {
    if(this.product) {
      this._product$.next(this.product);
    }
  }

  selectAttributeValue(event: any, attribute: any, value: any) {


    let [result, choices] = this.productService.setVariantAttribute(attribute.sysName, value);
    if (result === false) {
      event.preventDefault();

      this.additionalChoices = choices;
      this.currentChoice = choices[this.index];
      this.needSelectionVisible = true;
    }
  }

  selectChoice(variant) {
    this.currentChoice = variant;
  }

  selectVariant(variant) {
    this.productService.setCurrentVariant(variant.id);
    this.currentChoice = null;
    this.additionalChoices = null;
    this.needSelectionVisible = false;
    this.index = 0;
  }

  onNeedSelectionHide() {
    this.currentChoice = null;
    this.additionalChoices = null;
  }

  setStdView(value) {
    this.attributes = value ? this.stdFilterableAttributes : this.filterableAttributes;
    this.productService.setDefaultVariant(value ? "standard" : "normal");
    
    //this.stdView = value;
  }

}
