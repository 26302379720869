import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, inject } from '@angular/core';
import { Observable, Subject, combineLatest } from "rxjs";
import { ProductService } from "../../product.service";
import { takeUntil } from 'rxjs/operators';
import { ServiceMoment } from '../../factory-moment.service';
import { DatePipe } from '@angular/common';
import { StoreService } from '../../store.service';
import { CheckoutService } from '../../checkout.service';
import { doc } from 'firebase/firestore';
import { docData, Firestore } from '@angular/fire/firestore';

@Component({
  selector: 'checkout-item',
  templateUrl: './checkout-item.component.html',
  styleUrls: ['./checkout-item.component.css']
})
export class CheckoutItemComponent implements OnInit, OnDestroy, OnChanges {

  @Input() chartType: number;
  @Input() item: any;
  @Input() checkRestoration: boolean = false;
  @Input() quotationId: any;
  @Input() hideDiscount: boolean;
  @Output() qtaDisponibileChange = new EventEmitter<any>();
  @Output() toggleForceAnthologyChange = new EventEmitter<any>();

  restoration$: Observable<any>;
  dateProvisional: Date;

  locale: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  prezzo: number;
  firestore: Firestore = inject(Firestore);
  
  //private storeService: StoreService, 
  constructor(private productService: ProductService, private checkoutService: CheckoutService,
    private serviceMoment: ServiceMoment, private datePipe: DatePipe, private storeService: StoreService) {

  }
  ngOnChanges(_changes: SimpleChanges): void {
    if(this.item) {
      if(this.checkoutService.isModalitaListino2()) {
        let inversoDiscount = (1 - (this.item.discount || 0) / 100) * (1 - (this.item.discount2 || 0) / 100) * (1 - (this.item.discount3 || 0) / 100);
        this.prezzo = this.item.entity.prezzo * inversoDiscount;
      } else {
          this.prezzo = this.item.entity.prezzoRiservato;
      }

    }
  }

  ngOnInit() {

    this.storeService.getLocaleObservable().pipe(takeUntil(this.destroy$)).subscribe((l) => {
      this.locale = l;
      if (this.item.configForceToReserve) {
        var offSetDay = 2 + Math.floor(this.item.amountOffset / this.item.meterPerDay);
        this.dateProvisional = this.serviceMoment.addDayBusiness(offSetDay).toDate();
        this.item.restoreDateString = this.datePipe.transform(this.dateProvisional, 'EEEE, d MMMM', undefined, this.locale);
      } else {
        this.item.restoreDate = undefined;
      }
    });

    if (!this.item.entity.anthology)
      return;

    if (this.checkRestoration) {
      this.restoration$ = this.productService.getRestoration(this.item.sku);

      combineLatest([this.restoration$, this.storeService.getLocaleObservable()]).pipe(takeUntil(this.destroy$))
        .subscribe(([restore, locale]) => {
          if (restore && restore.dataTermine) {
            this.item.restoreDateString = this.datePipe.transform(restore.dataTermine, 'EEEE, d MMMM', undefined, locale);
          } else {
            this.item.restoreDateString = undefined;
          }

      });
    }

    let docRef = doc(this.firestore, `articoli/${this.item.sku}`);
    docData(docRef).pipe(takeUntil(this.destroy$)).subscribe(doc => {
      if (!doc) return;
      let newQuantity = doc.quantity;
      if (newQuantity != this.item.entity.qtaDisponibile) {
        this.item.entity.qtaDisponibile = newQuantity;

        if (this.item.entity.qtaDisponibile && this.item.entity.qtaDisponibile > 0) {
          this.item.entity.qtaDisponibile = Math.floor(this.item.entity.qtaDisponibile);
        } else {
          this.item.entity.qtaDisponibile = 0;
        }
        this.qtaDisponibileChange.emit({ qtaAvailable: this.item.entity.qtaDisponibile, sku: this.item.sku, type: 'qtyChange' });
      }
    });
  }

  toggleMoveToProd() {
    this.toggleForceAnthologyChange.emit({ sku: this.item.sku, quantity: this.item.quantity, type: 'toggleProd', forcedToProduction: true });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  


}
