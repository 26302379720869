<form [formGroup]="shareForm">

    <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label">Email</label>
        </div>
        <div class="field-body">
            <div class="field">
                <p class="control">
                    <input class="input" type="email" formControlName="email"
                        placeholder="fank@example.com;paul@example.com">
                </p>
            </div>
        </div>
    </div>

    <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label" [translate]="'message'">Messaggio</label>
        </div>
        <div class="field-body">
            <div class="field">
                <p class="control">
                    <textarea class="textarea" formControlName="messageText"></textarea>
                </p>
            </div>
        </div>
    </div>

    <div class="columns">
        <div class="column">
            <div class="field">
                <div class="control">
                    <input type="checkbox" id="shareQuotation" class="switch" formControlName="shareQuotation">
                    <label for="shareQuotation">
                        <span [translate]="'shareQuotation'"></span>
                    </label>
                </div>
            </div>
            <div class="field">
                <div class="control" *ngIf="shareQuotation">
                    <input type="checkbox" id="hideShowComponent" class="switch" formControlName="showComponentiConfig">
                    <label for="hideShowComponent">
                        <span [translate]="'showComponentConfiguration'"></span>
                    </label>
                </div>
            </div>
            
        </div>
        <div class="column">
            <div class="field">
                <div class="control" *ngIf="shareQuotation">
                    <input type="checkbox" id="hideProductCode" class="switch" formControlName="showProductCode">
                    <label for="hideProductCode">
                        <span [translate]="'showProductCode'"></span>
                    </label>
                </div>
            </div>
            <div class="field">
                <div class="control" *ngIf="shareQuotation && admin">
                    <input type="checkbox" id="hideProductImage" class="switch" formControlName="showProductImage">
                    <label for="hideProductImage">
                        <span [translate]="'showProductImage'"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="field">
                <div class="control">
                    <input type="checkbox" id="shareBrochure" class="switch" formControlName="shareBrochure">
                    <label for="shareBrochure">
                        <span [translate]="'shareBrochure'"></span>
                    </label>
                </div>
            </div>
            <div class="field">
                <div class="control" *ngIf="shareQuotation">
                    <p-dropdown formControlName="shareQuotationMode" [options]="optionsShareQuotation" optionLabel="label"></p-dropdown>
                </div>
            </div>
        </div>
    </div>




    <div class="buttons is-right">
        <button class="button is-info" (click)="share()"
            [disabled]="!shareForm.valid || !(shareQuotation || shareBrochure)" [translate]="'share'">
            Condividi
        </button>
    </div>

</form>