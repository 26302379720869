<header *ngIf="!hide">
  <div class="container is-widescreen">
    <div class="columns top-menu">
      <div class="column is-one-fifth">
        <div class="first-row">
          <div class="nav-logo is-inline-block">
            <a [routerLink]="['/'] | localize">
              <img [src]="userdata.customLogoUrl ? userdata.customLogoUrl : '/images/macrolux-logo.png'"
                style="height:27px;" alt="Macrolux B2B" class="logo" />
            </a>
          </div>
        </div>
        <div class="second-row is-relative is-flex">
          <div class="is-inline-block nav-menu-popper" style="margin-right:1.5em"
            (click)="accountPopper.toggle($event)">
            <span class="is-italic is-size-7">{{userdata?.given_name}}</span><br />
            <span class="has-text-weight-semibold is-size-6" style="cursor: pointer;">
              account&nbsp;&nbsp;<i class="fas fa-angle-down"></i>
            </span>
          </div>
          <div class="is-inline-block nav-menu-popper" style="margin-right:1.5em">
            <span class="is-italic is-size-7">Check Prize</span><br />
            <span *ngIf="shopMode == 1" [pTooltip]="translations['requireModeBuy']" tooltipPosition="bottom"
              tooltipStyleClass="m-tooltip">
              <i class="fas fa-eye-slash has-text-grey-light is-size-6"></i>
            </span>
            <a *ngIf="shopMode == 0" class="has-text-weight-semibold is-size-6"
              [routerLink]="['/profile', 'checkprize'] | localize">
              {{userdata.checkPrize | currency:'EUR'}}
            </a>
          </div>
          <div class="is-inline-flex nav-menu-popper" style="cursor: pointer;flex-grow: 1;align-items: flex-end">
            <div style="margin: 0 auto">
              <new-notifications></new-notifications>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="nav-search">
          <search class="first-row" [shopMode]="shopMode"></search>
          <div class="second-row is-flex is-justify-content-space-between is-align-items-flex-end"
            *ngIf="categories && categories.length > 0">
            <div (click)="popperSelectProduct.toggle($event)">
              <span class="is-italic is-size-7" [translate]="'navMenuChoice'">Scegli per</span><br />
              <span class="has-text-weight-semibold is-size-6" [translate]="'category'">categoria</span>&nbsp;&nbsp;<i
                class="fas fa-angle-down"></i>
            </div>
            <div>
              <div class="category-menu-popper"
                *ngFor="let application of applications; let first = first;"
                [class.active]="application.id == currentApplication?.id">
                <a class="has-text-grey app-menu-link"
                  (click)="openApplicationMenu(application)">{{application.name}}</a>
              </div>
            </div>

            <div>
              <div class="is-inline-flex">
                <span class="is-italic is-size-7">&nbsp;</span><br />
                <a [routerLink]="['/quotations', 'listQuotations'] | localize"
                  class="has-text-weight-semibold has-text-dark is-size-6" style="padding-right: 1em"
                  [translate]="'quotations'">Preventivi</a>
              </div>
              <div class="is-inline-flex">
                <span class="is-italic is-size-7">&nbsp;</span><br />
                <a [routerLink]="['/orders'] | localize" class="has-text-weight-semibold has-text-dark is-size-6"
                  *ngIf="shopMode == 0" [translate]="'orders'">Ordini</a>
                <span class="has-text-weight-semibold has-text-grey-light is-size-6" *ngIf="shopMode == 1"
                  [pTooltip]="translations['requireModeBuy']" tooltipPosition="bottom" tooltipStyleClass="m-tooltip"
                  [translate]="'orders'">Ordini</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="first-row has-text-right">
          <div class="sell-buy-switch is-inline-flex">
            <button class="button buy" [class.is-warning]="shopMode == 0" (click)="setShopMode(0)" [translate]="'buy'">
              Acquista
            </button>
            <button class="button sell" [class.is-info]="shopMode != 0" (click)="setShopMode(1)" [translate]="'sell'">
              Vendi
            </button>
          </div>
        </div>
        <div class="second-row is-flex is-justify-content-flex-end">
          <div>
            <span class="list-type is-italic is-size-7" *ngIf="currentList?.type; else listaSpan" style="right:0;"
              [translate]="'quotation'">
              Preventivo
            </span>
            <ng-template #listaSpan>
              <span class="list-type is-italic is-size-7" style="right:0;" [translate]="'list'">
                Lista
              </span>
            </ng-template>
            <br />
            <span class="is-inline-block has-text-weight-semibold has-text-dark is-size-6 truncate"
              style="cursor: pointer; max-width: 125px;">
              <a class="has-text-dark" (click)="viewSingleListQuotation(currentList)">
                <span *ngIf="currentList && currentList.defaultShoppingCart" [translate]="'carrello'"></span>
                <span *ngIf="currentList && !currentList.defaultShoppingCart">{{currentList?.name}}</span>
              </a>&nbsp;&nbsp;
            </span>
          </div>
          <div class="cart">
            <span class="has-text-weight-semibold has-text-dark is-size-6" (click)="popperListContent.toggle($event)"
              style="cursor: pointer">
              <img src="/images/cart-2.png" alt="cart" height="24" /><i class="fas fa-angle-down"
                style="margin-left: -8px;"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <p-overlayPanel #popperSelectProduct (onShow)="onCategoryMenuShown()" (onHide)="onCategoryMenuHide()">
      <div class="category-menu is-clearfix is-flex">
        <div class="cf-column is-pulled-left">
          <ul>
            <li *ngFor="let cat of categories" [class.active]="currentCategory?.id == cat.id" [dndDraggable]="cat"
              dndType="category" (dndStart)="onDragStart($event)" (dndEnd)="onDragEnd($event)">
              <a hoverIntent (onHoverIntent)="selectCategory(cat)" (click)="popperSelectProduct.hide()"
                class="level not-underline" [routerLink]="['/catalog', cat.id] | localize">
                <span class="level-left">
                  <span class="level-item">{{cat.name}}</span>
                </span>
                <span class="level-right">
                  <span class="level-item"><i class="fas fa-angle-right"></i></span>
                </span>
              </a>
            </li>
          </ul>
          <hr />
          <ul>
            <li>
              <a (click)="popperSelectProduct.hide()" class="level not-underline accessories-link"
                [routerLink]="['/accessories'] | localize">
                <span class="level-left">
                  <span class="level-item" [translate]="'accessories'">Accessori</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="cs-column is-pulled-right" *ngIf="currentCategory">
          <ul>
            <ng-container *ngFor="let cat of currentCategory.childrens">
              <li *ngIf="filteredFamilies[cat.id]?.length > 0" class="subcategory">
                <h3 class="subcategory-name">{{cat.name}}</h3>
                <div class="families">
                  <div *ngFor="let fam of filteredFamilies[cat.id]" [dndDraggable]="fam" dndType="family"
                    (dndStart)="onDragStart($event)" (dndEnd)="onDragEnd($event)"
                    [class.active]="currentFamily?.id == fam.id && currentSubCategory?.id == cat.id" class="family">
                    <a hoverIntent (onHoverIntent)="selectFamily(fam, cat)" (click)="popperSelectProduct.hide()"
                      class="has-text-dark not-underline" [routerLink]="['/catalog', 'families', fam.id] | localize"
                      [queryParams]="{sub: cat.id}">{{fam.name}}</a>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="cp-column" *ngIf="currentFamily" [cdnImgBackground]="currentFamily.image" type="family">
          <ul class="pl-0">
            <li class="modelli" [translate]="'models'">
              Modelli
            </li>
            <li *ngFor="let product of currentFamily.productsModel" [dndDraggable]="product" dndType="product"
              (dndStart)="onDragStart($event)" (dndEnd)="onDragEnd($event)">
              <a (click)="goToProduct(product.id)" class="has-text-dark">
                {{product.name}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </p-overlayPanel>
    <p-overlayPanel #popperListContent>
      <div class="columns popperlist">
        <div class="column">
          <div class="level is-mobile">
            <div class="level-left">
              <div class="level-item">
                <div class="has-text-weight-bold has-text-dark is-size-6">
                  <a (click)="viewLists(0)" class="has-text-dark" [translate]="'yourLists'">
                    Le mie liste
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ul>
            <li [pTooltip]="list.name" showIfTruncated *ngFor="let list of listService.lists | async"
              class="cart-list-item truncate" style="width: 150px;">
              <ng-container *ngIf="list.defaultShoppingCart; else noDefault">
                <a class="has-text-dark not-underline" [title]="'carrello' | translate" (click)="setCurrentList(list)">
                  <i class="fas fa-angle-right" style="margin-right: .5em" *ngIf="currentList?.id === list.id"></i>
                  <span [class.has-text-weight-semibold]="currentList?.id === list.id"
                    [translate]="'carrello'">{{list.name}}</span>
                </a>
              </ng-container>
              <ng-template #noDefault>
                <a class="has-text-dark not-underline" [title]="list.name" (click)="setCurrentList(list)">
                  <i class="fas fa-angle-right" style="margin-right: .5em" *ngIf="currentList?.id === list.id"></i><span
                    [class.has-text-weight-semibold]="currentList?.id === list.id">{{list.name}}</span>
                </a>
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="column">
          <div class="level is-mobile">
            <div class="level-left">
              <div class="level-item">
                <div class="has-text-weight-bold has-text-dark is-size-6">
                  <a (click)="viewLists(1)" class="has-text-dark" [translate]="'yoursQuotations'">I miei
                    preventivi</a>
                </div>
              </div>
            </div>
          </div>
          <ul>
            <li [pTooltip]="list.name" showIfTruncated *ngFor="let list of listService.quotations | async"
              class="cart-list-item truncate" style="width: 150px;">
              <a class="has-text-dark not-underline" [title]="list.name" (click)="setCurrentList(list)">
                <i class="fas fa-angle-right" style="margin-right: .5em" *ngIf="currentList?.id === list.id"></i><span
                  [class.has-text-weight-semibold]="currentList?.id === list.id">{{list.name}}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

    </p-overlayPanel>
    <application-menu [(status)]="applicationMenuStatus" [(currentApplication)]="currentApplication"
      [categories]="categories"></application-menu>
  </div>
  <div class="container is-widescreen">
    <div *ngIf="!dragging && favourites.length > 0" style="height: 25px;margin-bottom: .25rem;" class="tags">
      <span class="tag is-light" *ngFor="let favourite of favourites">
        <a (click)="goToFavourite(favourite)" class="favourite is-size-7">{{favourite.name}}</a>
      </span>
    </div>
    <div dndDropzone (dndDrop)="onFavouriteDrop($event)" style="height: 25px; background-color: whitesmoke;"
      *ngIf="dragging">
      <span class="is-size-7">
        <i class="far fa-fw fa-heart fa-fw"></i>
        <span [translate]="'dragHereYourFavourite'">Trascina qui per aggiungere ai preferiti</span>
      </span>
    </div>
  </div>
  <p-confirmDialog key="confirmAddWith" icon="pi pi-exclamation-triangle"></p-confirmDialog>

</header>
<p-overlayPanel #accountPopper>
  <div class="columns">
    <div class="column is-narrow">
      <i class="far fa-user fa-2x"></i>
    </div>
    <div class="column">
      <div>
        <a class="has-text-dark" [routerLink]="['/profile', 'detail'] | localize" *ngIf="shopMode == 0"
          [translate]="'detailCustomer'">Dettagli cliente</a>
        <span class="has-text-grey-light" *ngIf="shopMode == 1" [pTooltip]="translations['requireModeBuy']"
          tooltipStyleClass="m-tooltip" [translate]="'detailCustomer'">Dettagli cliente</span>
      </div>
      <div>
        <a class="has-text-dark" [routerLink]="['/profile', 'settings'] | localize" *ngIf="shopMode == 0"
          [translate]="'settings'">Impostazioni</a>
        <span class="has-text-grey-light" *ngIf="shopMode == 1" [pTooltip]="translations['requireModeBuy']"
          tooltipStyleClass="m-tooltip" [translate]="'settings'">Impostazioni</span>
      </div>
      <div>
        <a class="has-text-dark" [routerLink]="['/profile', 'paymentMethods'] | localize" *ngIf="shopMode == 0"
          [translate]="'paymentMethods'">paymentMethods</a>
        <span class="has-text-grey-light" *ngIf="shopMode == 1" [pTooltip]="translations['requireModeBuy']"
          tooltipStyleClass="m-tooltip" [translate]="'paymentMethods'">paymentMethods</span>
      </div>
      <div>
        <a class="has-text-dark" [routerLink]="['/profile', 'contracts'] | localize" *ngIf="shopMode == 0"
          [translate]="'myAgreements'">I miei contratti</a>
        <span class="has-text-grey-light" *ngIf="shopMode == 1" [pTooltip]="translations['requireModeBuy']"
          tooltipStyleClass="m-tooltip" [translate]="'myAgreements'">I miei contratti</span>
      </div>
      <div>
        <a class="has-text-dark" [routerLink]="['/profile', 'address'] | localize" *ngIf="shopMode == 0"
          [translate]="'myAddresses'">I miei indirizzi</a>
        <span class="has-text-grey-light" *ngIf="shopMode == 1" [pTooltip]="translations['requireModeBuy']"
          tooltipStyleClass="m-tooltip" [translate]="'myAddresses'">I miei indirizzi</span>
      </div>
      <div>
        <a class="has-text-dark" [routerLink]="['/profile', 'checkprize'] | localize" *ngIf="shopMode == 0">
          Check Prize
        </a>
        <span class="has-text-grey-light" *ngIf="shopMode == 1" [pTooltip]="translations['requireModeBuy']"
          tooltipStyleClass="m-tooltip">Check Prize</span>
      </div>
      <hr>
      <div>
        <a [routerLink]="['/orders'] | localize" class="has-text-dark" *ngIf="shopMode == 0" [translate]="'myOrders'">I
          miei ordini</a>
        <span class="has-text-grey-light" *ngIf="shopMode == 1" [pTooltip]="translations['requireModeBuy']"
          tooltipStyleClass="m-tooltip" [translate]="'myOrders'">I miei ordini</span>
      </div>
      <div>
        <a [routerLink]="['/profile', 'notifications'] | localize" class="has-text-dark"
          [translate]="'myNotifications'"></a>
      </div>
      <div>
        <a (click)="gotoList(0)" class="has-text-dark" [translate]="'listsAndQuotations'">Liste e preventivi</a>
      </div>
      <hr>
      <div><a (click)="logOut()" class="has-text-dark">Logout</a></div>
    </div>
  </div>
</p-overlayPanel>
<p-overlayPanel #popperUnlock>
  <form>
    <div class="field">
      <div class="field has-addons">
        <div class="control has-icons-left">
          <input class="input is-small" autocomplete="off" name="unlockCode" [(ngModel)]="unlockCode" autofocus
            type="password" [placeholder]="'unlockCode' | translate">
          <span class="icon is-left">
            <i class="fas fa-unlock-alt"></i>
          </span>
        </div>
        <div class="control">
          <button type="submit" class="button is-primary is-small" (click)="unlockShopMode()">
            Sblocca
          </button>
        </div>
      </div>
      <p class="help is-danger" *ngIf="wrongUnlockCode" [translate]="'wrongCode'">Codice errato</p>
      <a (click)="recoverCode()" class="help is-info" [translate]="'doYouForgetCode'">Hai
        dimenticato il codice?</a>
    </div>
  </form>
</p-overlayPanel>