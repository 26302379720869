<div class="columns is-vcentered">
  <div class="column">
    <h1 class="title is-4">
      {{baseQuotation?.name}}
    </h1>
    <div class="subtitle is-6">
      <span class="tag is-warning" *ngIf="opportunity.status == 0" [translate]="'waitingConfirm'">In attesa di conferma</span>
      <span class="tag is-success" *ngIf="opportunity.status == 1" [translate]="'confirmed'">Confermata</span>
    </div>
  </div>
  <div class="column is-narrow">
    <button class="button is-medium is-link" [disabled]="opportunity.status == 0 || shopMode != 0"
            (click)="checkout(baseQuotation)">
      <span [translate]="'proceedWithOrder'" >Procedi con l'ordine</span>&nbsp;&nbsp;<i class="fas fa-arrow-right"></i>
    </button>
  </div>
</div>
<div class="tabs is-boxed">
  <ul>
    <li [class.is-active]="tab===1"><a (click)="tab=1" [translate]="'header'">Intestazione</a></li>
    <li [class.is-active]="tab===0"><a (click)="tab=0" [translate]="'listArticles'">Lista articoli</a></li>
  </ul>
</div>
<table class="table is-fullwidth" *ngIf="tab === 0">
  <thead>
    <tr>
      <th style="width: 70px"></th>
      <th style="width: 2.5em" class="has-text-centered"><abbr title="Quantità">Qty.</abbr></th>
      <th><abbr [title]="'codArticle' | translate">Cod.</abbr></th>
      <th [translate]="'article'">Articolo</th>
      <th><abbr [title]="'availabilityNoDot' | translate"><span [translate]="'abbrAvail'">Disp.</span></abbr></th>
      <th class="has-text-right" [translate]="'priceList'">Listino</th>
      <th class="has-text-right" *ngIf="shopMode === 0" [translate]="'purchase'">Acquisto</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of itemsList | orderBy: 'type'">
      <td class="has-text-centered">
        <figure class="image is-64x64" [class.accessory]="item.type == 1">
          <img fallback-src="https://via.placeholder.com/96x96" query="width=96&height=96&mode=crop"
               *ngIf="item.type == 0" [cdnImg]="item.entity.images[0]"
               [productId]="item.entity.productModel">
          <img fallback-src="https://via.placeholder.com/96x96" query="width=96&height=96&mode=crop"
               *ngIf="item.type == 1" [cdnImg]="item.entity.image"
               type="accessory">
        </figure>
      </td>
      <td class="has-text-centered">
        {{item.quantity}}
      </td>
      <td>
        {{item.sku}}
      </td>
      <td>
        {{item.entity.esolverName}}
      </td>
      <td>
        {{item.entity.qtaDisponibile}}
      </td>
      <td class="has-text-right">
        {{(item.quantity * item.entity.prezzo) | currency:'EUR'}}
      </td>
      <td class="has-text-right" *ngIf="shopMode === 0">
        {{(item.quantity * item.entity.prezzoRiservato) | currency:'EUR'}}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th colspan="5">&nbsp;</th>
      <th class="has-text-right">
        {{totaleListino | currency:'EUR'}}
      </th>
      <th class="has-text-right" *ngIf="shopMode === 0">
        {{totaleRiservato | currency:'EUR'}}
      </th>
    </tr>
  </tfoot>
</table>
<div *ngIf="tab === 1">
  <div class="columns">
    <div class="column">
      <label class="label"  [translate]="'agent'">Agente</label>
      <div class="control">
        <input [value]="opportunity.client.accountId | accountName | async" class="input is-static" type="text"
               readonly [placeholder]="'notAvailable' | translate">
      </div>
    </div>
    <div class="column" *ngIf="shopMode === 0">
      <label class="label" [translate]="'purchaseValue'">Valore d'acquisto</label>
      <div class="control">
        <input [value]="totaleRiservato | currency:'EUR'" class="input is-static" type="text"
               readonly [placeholder]="'notAvailable' | translate">
      </div>
    </div>
    <div class="column">
      <label class="label" [translate]="'salesValue'">Valore di vendita</label>
      <div class="control">
        <input [value]="totaleListino  | currency:'EUR'" class="input is-static" type="text"
               readonly [placeholder]="'notAvailable' | translate">
      </div>
    </div>
    <div class="column" *ngIf="shopMode === 0">
      <label class="label" [translate]="'profitMargin'">Margine</label>
      <div class="control">
        <input [value]="margine | percent:'1.1-1'" class="input is-static" type="text" [placeholder]="'notAvailable' | translate">
      </div>
    </div>
  </div>
  <hr />
  <div *ngIf="opportunity.dealerOption === 2;else invisible">
    <h2 class="title is-5" [translate]="'indirizzoFatturazione'">Indirizzo di fatturazione</h2>
    <div class="columns">
      <div class="column">
        <label class="label" [translate]="'companyName'">Ragione Sociale</label>
        <div class="control">
          <input name="companyCliente" [value]="baseQuotation.customerAddress.company" class="input is-static"
                 readonly type="text" [placeholder]="'notAvailable' | translate">
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'vatRegNumber'">Partita IVA</label>
        <div class="control">
          <input name="vat" [value]="baseQuotation.customerAddress.vat" class="input is-static" type="text"
                 readonly [placeholder]="'notAvailable' | translate">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">PEC</label>
        <div class="control">
          <input name="pec" [value]="baseQuotation.customerAddress.pec" class="input is-static" email type="email"
                 readonly [placeholder]="'notAvailable' | translate">
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'destinationNumber'">Codice destinatario</label>
        <div class="control">
          <input name="codiceDestinatario" [value]="baseQuotation.customerAddress.codiceDestinatario"
                 readonly class="input is-static" type="text" [placeholder]="'notAvailable' | translate">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label" [translate]="'name'">Nome</label>
        <div class="control">
          <input name="nomeCliente" [value]="baseQuotation.customerAddress.firstName" class="input is-static"
                 readonly type="text" [placeholder]="'notAvailable' | translate">
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'familyName'">Cognome</label>
        <div class="control">
          <input name="cognomeCliente" [value]="baseQuotation.customerAddress.lastName" class="input is-static"
                 readonly type="text" [placeholder]="'notAvailable' | translate">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Email</label>
        <div class="control">
          <input name="emailCliente" [value]="baseQuotation.customerAddress.emailAddress" class="input is-static"
                 readonly type="text" [placeholder]="'notAvailable' | translate">
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'telephone'">Telefono</label>
        <div class="control">
          <input name="telefonoCliente" [value]="baseQuotation.customerAddress.telephoneNumber1"
                 readonly class="input is-static" type="text" [placeholder]="'notAvailable' | translate">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label" [translate]="'address'">Indirizzo</label>
        <div class="control">
          <input name="indirizzoCliente" [value]="baseQuotation.customerAddress.address1" class="input is-static"
                 readonly type="text" [placeholder]="'notAvailable' | translate">
        </div>
      </div>
      <div class="column">
        <label class="label">&nbsp;</label>
        <div class="control">
          <input name="indirizzoCliente2" [value]="baseQuotation.customerAddress.address2" class="input is-static"
                 readonly type="text">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label" [translate]="'city'">Città</label>
        <div class="control">
          <input name="cittaCliente" [value]="baseQuotation.customerAddress.city1" class="input is-static"
                 readonly type="text" [placeholder]="'notAvailable' | translate">
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'locality'">Località</label>
        <div class="control">
          <input name="localitaCliente" [value]="baseQuotation.customerAddress.city2" class="input is-static"
                 readonly type="text" [placeholder]="'notAvailable' | translate">
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'zipCode'">CAP</label>
        <div class="control">
          <input name="capCliente" [value]="baseQuotation.customerAddress.zipCode" class="input is-static"
                 readonly type="text" [placeholder]="'notAvailable' | translate">
        </div>
      </div>
      <div class="column">
        <label class="label" [translate]="'countryCode'">Codice Stato</label>
        <div class="control">
          <input name="statoCliente" maxlength="2" [value]="baseQuotation.customerAddress.countryCode"
                 readonly class="input is-static" type="text" [placeholder]="'notAvailable' | translate">
        </div>
      </div>
    </div>
    <ng-template #invisible [translate]="'customerNotAvailable'">
      Cliente non visibile
    </ng-template>
  </div>
  <ng-template #invisible>
    <p class="notification" [translate]="'agentSetCustomerAsReserved'">
      L'agente ha deciso per il momento di mantenere il cliente riservato.
    </p>
  </ng-template>
</div>
