import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CatalogService } from '../catalog.service';
import { groupBy, isNil, orderBy } from 'lodash';

@Component({
  selector: 'app-accessory-catalog',
  templateUrl: './accessory-catalog.component.html',
  styleUrls: ['./accessory-catalog.component.css']
})
export class AccessoryCatalogComponent implements OnInit {

  categoryAccessories$: Observable<any[]>;

  brandName: string;

  constructor(private route: ActivatedRoute, private catalogService: CatalogService) {
  }

  ngOnInit() {

    this.categoryAccessories$ = combineLatest([this.catalogService.getAccessoryCategories(),
    this.catalogService.getCurrentBrandId(),
    this.catalogService.getBrands()])
      .pipe(map(([categories, brandId, brands]) => {

        this.brandName = brands.find(b => b.id == brandId).name;
        return categories;
      }));


    // this.brands$ = combineLatest([this.route.data, this.catalogService.getBrands()]).pipe(map(([t, brands]) => {

    //   let categoriesFlat: any[] = t.categories;
    //   let group = groupBy(categoriesFlat, 'idBrand');

    //   //l'ordine voluto e' macrolux, arcdecor, stucchi
    //   let brandsReversedOrder = orderBy(brands, ['order'], ['asc']);
    //   for(let brand of brandsReversedOrder) {
    //     if(group[brand.id]) {
    //       brand.categories = group[brand.id];
    //     }
    //   }
    //   brandsReversedOrder = brandsReversedOrder.filter(b => !isNil(b.categories))

    //   return brandsReversedOrder;
    // }));
  }

}
