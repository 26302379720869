import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { StoreService } from '../store.service';
import { LocalizeMap } from '../shared/costants';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class StoreResolver  {

  localeStoreMap = LocalizeMap;

  constructor(private storeService: StoreService, private authService: AuthenticationService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot) {
    if (!route.params.store) {
      let claims = this.authService.getUserProfile();
      if (claims) {
        let store = this.storeService.storeDefault;

        //se non c'e' nel claim o nella mappa sopra uso il default
        if (claims.locale && this.localeStoreMap[claims.locale.toString()]) {
          store = this.localeStoreMap[claims.locale.toString()];
        }

        this.router.navigate(['/' + store]);

      } 
      return false;
    }
    return this.storeService.setStoreIfExist(route.params.store);    
  }
}
