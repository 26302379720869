import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { StatsService } from '../stats.service';
import { tap, switchMap, takeUntil } from 'rxjs/operators';
import { CurrencyPipe, formatCurrency } from '@angular/common';
import * as moment from 'moment';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { StoreService } from '../store.service';

@Component({
  selector: 'app-performance-agente',
  templateUrl: './performance-agente.component.html',
  providers: [CurrencyPipe],
  styleUrls: ['./performance-agente.component.scss']
})
export class PerformanceAgenteComponent implements OnInit, OnDestroy {

  @Input() level: string;
  @Input() agenti: any[];
  @Input() agente: any;

  private agenteSubject$: BehaviorSubject<any>;

  today = new Date();

  statsBudget: any;
  loadingBudget: boolean = false;
  budgetChartData: any
  budgetChartOptions: any = {
    scales: {

      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true
        }
      },
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: { enabled: false },
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold'
        },
        display: (ctx) => {
          if (ctx.dataIndex === 1)
            return false;
          let value = ctx.dataset.data[ctx.dataIndex];
          return value > 0;
        },
        formatter: (value) => {
          return formatCurrency(value, 'it-IT', '€', 'EUR');
        }
      }
    }
    
  }

  thresholdConfigQ = {
    '33': { color: 'red' },
    '66': { color: '#c6912f' },
    '100': { color: '#173554' }
  }
  thresholdConfigO = {
    '33': { color: 'red' },
    '66': { color: '#c6912f' },
    '100': { color: '#173554' }
  }

  statsOrdinato: any;
  loadingOrdinato: boolean = false;
  deltaPercent: number = 0;


  statsPreventivi: any;
  loadingPreventivi: boolean = false;

  statsUsers: any;
  loadingUsers: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private statsService: StatsService, private currencyPipe: CurrencyPipe, private storeService: StoreService) {
  }

  ngOnInit() {
    this.agenteSubject$ = new BehaviorSubject(this.agente);
    const currentAgente$ = this.agenteSubject$.asObservable();//.pipe(shareReplay());

    let budget = currentAgente$.pipe(
      tap(() => this.loadingBudget = true),
      switchMap(agente => {
        if (agente) {
          return this.statsService.getPerformanceBudgetForAgente(agente.uid)
        } else {
          return of(null)
        }
      }),
      tap(() => this.loadingBudget = false)
    );

    let ordinato = currentAgente$.pipe(
      tap(() => this.loadingOrdinato = true),
      switchMap(agente => {
        if (agente) {
          return this.statsService.getPerformanceOrdinatoForAgente(agente.uid)
        } else {
          return of(null)
        }
      }),
      tap(() => this.loadingOrdinato = false)
    );

    let translationRequired = marker(['current', 'target']);

    combineLatest([budget, ordinato, this.storeService.translateService.stream(translationRequired)])
      .pipe(takeUntil(this.destroy$)).subscribe(([budget, ordinato, trad]) => {
        this.statsBudget = budget;
        this.statsOrdinato = ordinato;

        let ordinatoImporto = ordinato ? ordinato.importo : null;
        //budget 
        let budgetIntraPresent = budget ? budget.budgetIntra * moment().dayOfYear() / 365 : null;
        let budgetIntraToBeUsed = budget ? (budget.budgetIntra - budgetIntraPresent) : null

        this.deltaPercent = 0;
        let labelExtraOrdinato = "";
        let labelExtraTarget = "";
        if (budgetIntraPresent && ordinatoImporto > 0) {
          let deltaFixed = ordinatoImporto - budgetIntraPresent;
          this.deltaPercent = deltaFixed / budgetIntraPresent;
        }

        if (budgetIntraPresent) {
          labelExtraTarget += " (" + this.currencyPipe.transform(budgetIntraPresent, 'EUR') + ")";
        }

        if (ordinatoImporto) {
          labelExtraOrdinato += " (" + this.currencyPipe.transform(ordinatoImporto, 'EUR') + ")";
        }

        this.budgetChartData = {
          labels: [[trad['current'], labelExtraOrdinato], [trad['target'], labelExtraTarget]],
          datasets: [
            {
              type: 'bar',
              backgroundColor: [
                "#c6912f",
                "#173554",
              ],
              hoverBackgroundColor: [
                "#bc892d",
                "#173554",
              ],
              data: [ordinatoImporto, budgetIntraPresent]
            },
            {
              type: 'bar',
              backgroundColor: [
                'rgba(23,53,84, 0.35)',
                'rgba(23,53,84, 0.35)'
              ],
              data: [null, budgetIntraToBeUsed]
            }
          ]
        }
      });

    currentAgente$.pipe(
      tap(() => this.loadingPreventivi = true),
      switchMap(agente => {
        if (agente) {
          return this.statsService.getPerformancePreventiviForAgente(agente.uid)
        } else {
          return of(null)
        }
      }),
      tap(() => this.loadingPreventivi = false),
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.statsPreventivi = data;
    });

    currentAgente$.pipe(
      tap(() => this.loadingUsers = true),
      switchMap(agente => {
        if (agente) {
          return this.statsService.getPerformanceUsersForAgente(agente.uid)
        } else {
          return of(null)
        }
      }),
      tap(() => this.loadingUsers = false),
      takeUntil(this.destroy$)
    ).subscribe(data => this.statsUsers = data)

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onSelectedAgente(agente: any) {
    this.agenteSubject$.next(agente);
  }


}
