<ng-container *ngIf="family">
  <h2 class="title is-4">{{family?.name}}</h2>
  <h3 class="subtitle">
    <span class="pointer" *ngIf="family.categories.length > 1;else one_cat" 
        (click)="categoryPopper.toggle($event)">
      <span *ngIf="sub">
        {{sub | categoryName:true | async}}&nbsp;&nbsp;<i class="fas fa-angle-down has-text-info"></i>
      </span>
      <span *ngIf="!sub">
        <span [translate]="'filterByCategory'">Filtra per categoria</span>&nbsp;&nbsp;<i class="fas fa-angle-down has-text-info" ></i>
      </span>
    </span>
    <ng-template #one_cat>
      <span *ngIf="family.categories.length == 1">{{family.categories[0] | categoryName:true | async}}</span>
    </ng-template>
    <span *ngIf="categoryApplications?.length > 0" class="spacer"> |</span>
    <span *ngIf="categoryApplications?.length > 1;else one_app" (click)="appPopper.toggle($event)">
      <span *ngIf="app">
        {{app | applicationName | async}}&nbsp;&nbsp;<i class="fas fa-angle-down has-text-info"></i>
      </span>
      <span *ngIf="!app">
        <span [translate]="'filterByApplication'">
          Filtra per applicazione</span>
          &nbsp;&nbsp;<i class="fas fa-angle-down has-text-info"></i>
        </span>
      </span>
    <ng-template #one_app>
      <span *ngIf="categoryApplications?.length == 1">{{categoryApplications[0] | applicationName | async}}</span>
    </ng-template>
  </h3>
  <div class="section-margined" *ngFor="let prodG of filteredProducts | groupBy: 'application' | pairs">
    <h2 class="title-hr is-size-4 is-uppercase font-condensed has-text-weight-bold" *ngIf="prodG[0] != 'null'">
      <span>{{prodG[0] | applicationName | async}}</span>
    </h2>
    <div class="columns is-multiline">
      <div class="column is-3" *ngFor="let prod of prodG[1]">
        <div class="card pointer card-equal-height" [routerLink]="['/product', prod.id] | localize">
          <div class="card-image">
            <figure class="image is-square">
              <img fallback-src="https://via.placeholder.com/1200x1200" query="width=800&height=800&mode=crop"
                   [cdnImg]="prod.images[0]" [productId]="prod.id">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content" style="min-width: 0">
                <p class="title is-5 truncate">{{prod.name}}</p>
                <p class="subtitle is-6" *ngIf="family.categories.length > 1 && !sub">
                  {{prod.category | categoryName:true | async }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-overlayPanel #categoryPopper>
    <div>
      <a class="has-text-dark" (click)="closePopper()" [routerLink]="['/catalog', 'families', family.id] | localize" [translate]="'allCategories'">Tutte le categorie</a>
    </div>
    <div *ngFor="let fam of family?.categories">
      <a class="has-text-dark" (click)="closePopper()" [routerLink]="['/catalog', 'families', family.id] | localize" [queryParams]="{sub: fam}">{{fam | categoryName:true | async}}</a>
    </div>
  </p-overlayPanel>
  <p-overlayPanel #appPopper>
    <div>
      <a class="has-text-dark" (click)="closePopper()" [routerLink]="['/catalog', 'families', family.id] | localize" [queryParams]="{sub: sub}" [translate]="'allApplications'">Tutte le applicazioni</a>
    </div>
    <div *ngFor="let app of categoryApplications">
      <a class="has-text-dark" (click)="closePopper()" [routerLink]="['/catalog', 'families', family.id] | localize" [queryParams]="{app: app, sub: sub}">{{app | applicationName | async}}</a>
    </div>
  </p-overlayPanel>
</ng-container>
