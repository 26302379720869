<section class="section">
  <div class="container is-widescreen">
    <div class="columns">
      <div class="column is-one-fifth list-enum">
        <h3 class="title is-4" [translate]="'cateogories'">Categorie</h3>
        <ul>
          <li *ngFor="let cat of categories">
            <a class="has-text-dark" [routerLink]="['/catalog', cat.id]  | localize" routerLinkActive="has-text-weight-bold">{{cat.name}}</a>
          </li>
        </ul>
      </div>
      <div class="column is-four-fifths detail">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>
