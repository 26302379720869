import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { AuthOptions, EventTypes, LoginResponse, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { map, filter, tap } from 'rxjs/operators';
import isNil from 'lodash-es/isNil';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _authenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAuthenticated$: Observable<boolean> = this._authenticated.asObservable();

  private _userProfile: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public userProfile$ = this._userProfile.asObservable().pipe(filter(profile => !isNil(profile)));

  public isDoneLoading$: Observable<boolean> = this.userProfile$.pipe(map((profile) => !!profile));

  constructor(private oidcSecurityService: OidcSecurityService,
    private router: Router, private eventService: PublicEventsService) {
      
    this.oidcSecurityService.isAuthenticated$.subscribe(authenticatedResult => {
      this._authenticated.next(authenticatedResult.isAuthenticated);
    });
    this.oidcSecurityService.userData$.subscribe(data => {
      this._userProfile.next(data.userData)
    });
    this.oidcSecurityService.checkSessionChanged$.pipe(
      filter(sessionChanged => sessionChanged) //initial value is false... probably due to BehaviourSubject initialization.
    ).subscribe(t => {
      //when session change we need to logout user locally
      this.oidcSecurityService.logoffLocalMultiple();
      this.router.navigateByUrl('/').then(() => console.debug('User logged off since identity server session changed! (probably external logout)'));
    });
    // this.eventService.registerForEvents()
    //   .pipe(filter((notification) => notification.type === EventTypes.TokenExpired))
    //   .subscribe((_value) => {
    //     this.router.navigateByUrl('/').then(() => console.debug('User logged off since token expired'));
    //     alert('Session has expired, please reload or login again.')
    //   });
  }

  isAuthenticated() {
    return this._authenticated.value;
  }

  login(options: AuthOptions = null): void {
    if (options) {
      this.oidcSecurityService.authorize(null, options);
    } else {
      this.oidcSecurityService.authorize();
    }
  }

  logout(): Observable<any> {
    
    return this.oidcSecurityService.logoff().pipe(tap(() => { 
      this._authenticated.next(false); 
    }));
  }

  getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  getUserProfile(): any {
    return this._userProfile.getValue();
  }

  subClaim(): string {
    return this._userProfile.getValue().sub
  }

  companyClaim(): string {
    return this._userProfile.getValue().company
  }

  async forceReload(): Promise<void> {
    await firstValueFrom(this.oidcSecurityService.forceRefreshSession()) //.then(() => console.warn('session force reloaded'));
  }

  checkAuthIncludingServer(): Observable<boolean> {

    return this.oidcSecurityService.checkAuthIncludingServer().pipe(map((loginResponse) => {
      if (!loginResponse || loginResponse.errorMessage) {
        
        return false;
      } else {
        return loginResponse.isAuthenticated;
      }
    }));

  }

  checkAuth(): Observable<boolean> {
    return this.oidcSecurityService.checkAuth().pipe(map((loginResponse) => {
      if (!loginResponse || loginResponse.errorMessage) {
        
        return false;
      } else {
        return loginResponse.isAuthenticated;
      }
    }));
  }
}