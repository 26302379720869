import {Component, OnDestroy, OnInit} from '@angular/core';
import { ListService } from "../list.service";
import { QuotationService } from '../quotation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StoreService } from '../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GetQuotationDifference } from './quotation.guard';

@Component({
  templateUrl: './quotation-discount.component.html',
  styleUrls: ['./quotation-discount.component.css']
})
export class QuotationDiscountComponent implements OnInit, OnDestroy, GetQuotationDifference {

  baseQuotation: any;
  globalDiscount: number;
  extraDiscount: number;

  destroy$: Subject<boolean> = new Subject<boolean>();

  translations: any = {};

  constructor(private listService: ListService, private quotationService: QuotationService, private storeService: StoreService) { }

  ngOnInit() {
    let translationRequired = marker(['proceedWillDestroyModifyQuotation']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.quotationService.baseQuotation.pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.baseQuotation = t;
      this.globalDiscount = t.globalDiscount;
      this.extraDiscount = t.extraDiscount;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  differenceQuotation(): boolean {
    return this.quotationService.getDifferenceStatus();
  }

  alertMessage(): string {
    return this.translations.proceedWillDestroyModifyQuotation;
  }

  reloadDetail(): void {
    this.quotationService.reloadQuotationDetail();
  }

  hideDiscount(value) {
    this.baseQuotation.hideDiscount = value;
  }

  setDiscount() {
    if (this.globalDiscount == 0) {
      this.globalDiscount = null;
    }
    this.baseQuotation.globalDiscount = this.globalDiscount;
    this.listService.setGlobalDiscount(this.baseQuotation, this.baseQuotation.globalDiscount);
  }

  setExtraDiscount() {
    if (this.extraDiscount <= 0) {
      this.extraDiscount = 0;
    }
    this.baseQuotation.extraDiscount = this.extraDiscount;
  }

  removeExtraDiscount() {
    this.extraDiscount = 0;
    this.baseQuotation.extraDiscount = this.extraDiscount;
  }

  removeDiscount() {
    this.globalDiscount = null;
    this.baseQuotation.globalDiscount = this.globalDiscount;
    this.listService.setGlobalDiscount(this.baseQuotation, this.baseQuotation.globalDiscount);
  }
}
