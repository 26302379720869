import { AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CatalogService } from "../catalog.service";
import { ActivatedRoute, Router } from "@angular/router";
import { cloneDeep, orderBy, uniq } from 'lodash';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.scss']
})
export class FamilyComponent implements OnInit, OnDestroy {

  family: any;
  category: any;
  sub: string;
  app: string;

  filteredProducts: any[];
  categoryApplications: any[];

  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('categoryPopper', { static: false }) categoryPopper: any;

  constructor(private catalogService: CatalogService,
    private router: Router,
    private route: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.catalogService.setCurrentFamily(this.route.snapshot.params.id);
    if (this.route.snapshot.queryParams.sub)
      this.catalogService.setCurrentCategory(this.route.snapshot.queryParams.sub);
  }

  ngOnInit() {
    combineLatest([this.catalogService.getCurrentFamily(), this.catalogService.getApplications()])
      .pipe(takeUntil(this.destroy$)).subscribe(([t, applications]) => {
        if (!t) return;

        let applicationIdToHide = applications.filter(a => a.hiddenCatalog).map(a => a.id);
        this.family = t;
        this.route.queryParams.subscribe(val => {
          this.filteredProducts = cloneDeep(this.family.productsModel);
          this.filteredProducts = orderBy(this.filteredProducts.filter(x => !applicationIdToHide.some(id => id == x.application)), 
          ['order', p => p.name.toLowerCase()], ["asc", "asc"]);

          if (val.sub) {
            this.sub = this.route.snapshot.queryParams.sub;
            this.filteredProducts = this.filteredProducts.filter(t => t.category == this.sub);
          } else {
            //TODO che categoria assegno
            this.sub = undefined;
          }
          this.categoryApplications = uniq(this.filteredProducts.map(t => t.application)).filter(t => t != null);
          if (val.app) {
            this.app = this.route.snapshot.queryParams.app;
            this.filteredProducts = this.filteredProducts.filter(t => t.application == this.app);
          } else {
            this.app = undefined;
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  closePopper() {
    this.categoryPopper.hide();
  }

}
