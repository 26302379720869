import { Directive, ElementRef, Renderer2, Input, OnChanges, HostListener } from '@angular/core';
import { Tooltip } from 'primeng/tooltip';
import { ImageService } from './image.service';

@Directive({
  selector: "[cdnImg]"
})
export class CdnImageDirective implements OnChanges {
  @Input('cdnImg') image: string;
  @Input('productId') product: string;
  @Input('fallback-src') fallback: string;
  @Input('type') type: string = 'images';
  @Input('query') query: string;
  @Input('quotationId') quotationId: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private imageService: ImageService) {
  }

  ngOnChanges() {
    let url = this.imageService.getImageUrl(this.image, this.product, this.fallback, this.type, this.query, this.quotationId);
    this.renderer.setAttribute(this.el.nativeElement, 'src', url);
  }
}

@Directive({
  selector: "[cdnImgBackground]"
})
export class CdnImageBackgroundDirective implements OnChanges {
  @Input('cdnImgBackground') image: string;
  @Input('productId') product: string;
  @Input('fallback-src') fallback: string;
  @Input('type') type: string = 'images';
  @Input('query') query: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private imageService: ImageService) {
  }

  ngOnChanges() {
    let url = this.imageService.getImageUrl(this.image, this.product, this.fallback, this.type, this.query);
    this.renderer.setStyle(this.el.nativeElement, 'background-image', "url('" + url + "')");
    this.renderer.setStyle(this.el.nativeElement, 'background-repeat', 'no-repeat');
    this.renderer.setStyle(this.el.nativeElement, 'background-position', 'center center');
  }
}

@Directive({
  selector: '[pTooltip][showIfTruncated]'
})
export class ShowTooltipIfTruncatedDirective {

  constructor(
    private pTooltip: Tooltip,
    private elementRef: ElementRef<HTMLElement>
  ) { }

  @HostListener('mouseenter', ['$event'])
  setTooltipState() {
    const element = this.elementRef.nativeElement;
    this.pTooltip.setOption({
      disabled : element.scrollWidth <= element.clientWidth
    });
  }
}