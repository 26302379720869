<ng-container *ngIf="authorized; else unauth_outlet">
  <div id="wrapper" class="nm-wrapper">
    <app-nav-menu></app-nav-menu>
    <div id="inner-wrapper">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <p-toast>
  </p-toast>
</ng-container>
<ng-template #unauth_outlet>
  <router-outlet></router-outlet>
</ng-template>
