import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ProductService } from '../../product.service';
import { ListService } from '../../list.service';
import { CatalogService } from '../../catalog.service';
import { Subject } from 'rxjs';
import { StoreService } from '../../store.service';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { PdfService } from '../../pdf.service';
import { IdAggStucchi } from '../../shared/costants';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'accessory-list',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.css']
})
export class AccessoriesComponent implements OnInit, OnDestroy {

  accessories: any[];
  components: any[];
  currentList: any;
  @Output() hasComponentsWarn = new EventEmitter<boolean>();

  shopMode: number = 1;
  translations: any = {};
  //accManagedStock: string;

  destroy$: Subject<boolean> = new Subject<boolean>();

  coloreSfondoAccessories: string = "#ececec" //#e0e1e3

  constructor(private listService: ListService, private productService: ProductService, private storeService: StoreService,
    private catalogService: CatalogService, private pdfService: PdfService, private fileSaverService: FileSaverService) {

  }

  ngOnInit() {
    let translationRequired = marker(['accManagedStock', 'errorCreatingPdf']);
    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.catalogService.getCurrentBrandId().pipe(takeUntil(this.destroy$)).subscribe((brand) => {
      if (brand == IdAggStucchi) {
        this.coloreSfondoAccessories = "#e0e1e3";
      } else {
        this.coloreSfondoAccessories = "#ececec";
      }
    });

    this.listService.getCurrentList().pipe(takeUntil(this.destroy$)).subscribe(t => this.currentList = t);

    this.productService.getCurrentVariant().pipe(
      mergeMap(() => this.productService.getAccessories()),
      mergeMap((accs) => {

        this.accessories = accs.filter(y => y.attributes.findIndex(x => x.sysName === "tipo-accessorio" && x.value == 1) > -1
          || y.attributes.findIndex(x => x.sysName === "tipo-accessorio") == -1);

        this.components = accs.filter(y => y.attributes.findIndex(x => x.sysName === "tipo-accessorio" && x.value == 0) > -1);
        return this.listService.getCurrentConfiguration();
      }), takeUntil(this.destroy$)).subscribe((tmpItems) => {
        if (!tmpItems)
          return;
        for (let accessory of this.accessories) {
          let index = tmpItems.findIndex(t => t.referenceId == accessory.id && t.type == 1);
          accessory.selected = index != -1;
          if (!accessory.selected)
            accessory.quantity = null;
          else {
            accessory.quantity = tmpItems.get(index).quantity;
            if (tmpItems.get(index).sku && tmpItems.get(index).sku != accessory.sku) {
              accessory.selectedPack = accessory.packs.find(t => t.sku == tmpItems.get(index).sku);
            }
          }
        }
        for (let accessory of this.components) {
          let index = tmpItems.findIndex(t => t.referenceId == accessory.id && t.type == 1);
          accessory.selected = index != -1;
          if (!accessory.selected)
            accessory.quantity = null;
          else {
            accessory.quantity = tmpItems.get(index).quantity;
            if (tmpItems.get(index).sku && tmpItems.get(index).sku != accessory.sku) {
              accessory.selectedPack = accessory.packs.find(t => t.sku == tmpItems.get(index).sku);
            }
          }
        }

        let tester = this.components.length > 0 && this.components.filter(t => t.selected).length < 1;
        this.hasComponentsWarn.emit(tester);
      });

    this.catalogService.getShopMode().pipe(takeUntil(this.destroy$)).subscribe(t => {
      this.shopMode = t;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  allowedAttributes(attribute) {
    return attribute.sysName !== "anthology";
  }

  addToCart(accessory) {
    let sku = accessory.selectedPack ? accessory.selectedPack.sku : accessory.sku;
    if (accessory.selected) {
      accessory.quantity = 1;
      this.listService.addOrUpdateConfigurationItem({ referenceId: accessory.id, sku: sku, quantity: accessory.quantity, type: 1 });
    } else {
      accessory.quantity = null;
      this.listService.removeConfigurationItem({ referenceId: accessory.id, type: 1 });
    }
  }

  onQtyChange(accessory) {
    let sku = accessory.selectedPack ? accessory.selectedPack.sku : accessory.sku;
    this.listService.addOrUpdateConfigurationItem({ referenceId: accessory.id, quantity: accessory.quantity, type: 1, sku: sku });
  }

  selectPack(accessory, pack) {
    if (accessory.selectedPack) {
      accessory.selectedPack = undefined;
      if (accessory.selected) {
        this.listService.addOrUpdateConfigurationItem({ referenceId: accessory.id, quantity: accessory.quantity, type: 1, sku: accessory.sku });
      }

      return;
    }

    accessory.selectedPack = pack;
    if (accessory.selected) {
      accessory.quantity = 1;
      this.listService.addOrUpdateConfigurationItem({ referenceId: accessory.id, quantity: accessory.quantity, type: 1, sku: pack.sku });
    }
  }

  downloadBrochureAccessory(accessory: any) {
    this.pdfService.printBrochureProduct(accessory.id).subscribe({

      next: (data) => {
        //this.isDownloadingBrochure = false;
        if (data.size > 0) {
          this.fileSaverService.save(data, `Brochure ${accessory.name}.pdf`);
        } else {
          alert(this.translations["errorCreatingPdf"]);
        }
      },
      error: (_error: any) => {
        //this.isDownloadingBrochure = false;
        alert(this.translations["errorCreatingPdf"]);
      }
    });
  }
}
