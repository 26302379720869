<div *ngIf="(notifiche && notifiche.length > 0); else noNotifiche" class="has-badge-rounded has-badge-warning"
  [attr.data-badge]="notifiche.length" (click)="panelNotification.toggle($event)" style="cursor: pointer">
  <i class="far fa-2x fa-bell"></i>
</div>
<ng-template #noNotifiche>
  <div (click)="panelNotification.toggle($event)" style="cursor: pointer">
    <i class="far fa-2x fa-bell"></i>
  </div>
</ng-template>
<p-overlayPanel #panelNotification [dismissable]="true" [appendTo]="'body'" [style]=" {'width':'450px','max-height':'550px', 'overflow-y':'auto', 'padding': 0 ,'border':'none'}">
  <div *ngIf="!notifiche || notifiche.length == 0; else showNotifies" style="width: 100%; cursor: default;">
    <div class="level">
      <div class="level-left">
        <span class="has-text-weight-semibold" [translate]="'noNotification'"></span>
      </div>
      <div class="level-right">
        <div class="level-item">
          <a (click)="panelNotification.hide()"><i class="fas fa-times"></i></a>
        </div>
      </div>
    </div>
    <hr style="margin: 1rem 0;" />
    <div class="level">
      <div class="level-left">
        <div class="level-item is-size-7">
          <a [translate]="'goToArchiveNotification'" (click)="goToArchive()">Vai all'archivio</a>
        </div>
      </div>
      <div class="level-right">
      </div>
    </div>
  </div>
  <ng-template #showNotifies>
    <div class="columns">
      <div class="column">
        <span [translate]="'newNotifications'">Nuove notifiche</span>
      </div>
      <div class="column has-text-centered">
        <a (click)="deleteAllNotifications()" [translate]="'archiveNotifications'">Archivia tutte</a>
      </div>
      <div class="column has-text-right">
        <a (click)="panelNotification.hide()"><i class="fas fa-times"></i></a>
      </div>
    </div>
    <hr style="margin: 1rem 0;" />
    <div *ngFor="let notifica of notifiche | orderBy: '-dateFormatted'" style="cursor: default;">
      <div class="media">
        <div class="media-left">
          <figure class="image is-48x48 is-square" *ngIf="notifica.urlImg">
            <img [src]="urlBlob + 'notificationimage/' + notifica.urlImg">
          </figure>
          <div class="icon is-large" *ngIf="!notifica.urlImg">
            <i class="far fa-message-smile fa-2x"></i>
          </div>
        </div>
        <div class="media-content">
          <div class="content">
            <div style="margin-bottom: .5em;">
              <strong>{{notifica.titleNotification}}</strong>
            </div>
            <div><span style="white-space: pre-line">{{notifica.abstractNotification}}</span></div>
            <div style="margin-top: .5em;">
              <div class="is-relative image-to-open is-inline-block" style="height:256px;" *ngIf="notifica.urlContentImg">
                <img style="height:256px; width: auto;" [src]="urlBlob + 'notificationimage/' + notifica.urlContentImg">
                <div class="to-show-when-hover" (click)="openFullImage(notifica.urlContentImg); panelNotification.hide($event)"><i
                    class="fas fa-eye fa-2x"></i>
                </div>
              </div>
            </div>
            <div style="margin-top: .5em">
              <a class="has-text-weight-medium" style="text-decoration: underline;"
                *ngIf="notifica.urlRouting || notifica.urlNotification" (click)="openNotificationUrl(notifica)"
                [translate]="'showMore'">Scopri di più</a>
            </div>
          </div>
        </div>
        <div class="media-right">
          <a (click)="deleteNotification(notifica)">
            <i class="fas fa-check"></i>
          </a>
        </div>
      </div>
      <hr style="margin: 1rem 0;" />
    </div>
    <div class="level">
      <div class="level-left">
        <div class="level-item is-size-7">
          <a [translate]="'goToArchiveNotification'" (click)="goToArchive()">Vai all'archivio</a>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item is-size-7">
          <a (click)="deleteAllNotifications()" [translate]="'archiveNotifications'">Archivia tutte</a>
        </div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<div class="modal is-active" *ngIf="showFullImage">
  <div class="modal-background" (click)="showFullImage = false"></div>
  <div class="modal-content">
    <p class="image is-square">
      <img [src]="urlBlob + 'notificationimage/' + urlImageToShowFullScreen">
    </p>
  </div>
  <button class="modal-close is-large" aria-label="close" (click)="showFullImage = false"></button>
</div>