import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewChildren, QueryList, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { CheckoutService } from '../checkout.service';
import { cloneDeep, round } from 'lodash';
import { OrderService } from '../order.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { StoreService } from '../store.service';
import { distinctUntilChanged, filter, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from '../../environments/environment';
import { UserPreferenceService } from '../user-preference.service';
import { ReturnTo, Bank, Iban, BicSwift } from '../shared/costants';
import { ServiceMoment } from '../factory-moment.service';
import { IsSuperAdmin } from '../shared/functions';
import { QuotationService } from '../quotation.service';
import { Overlay } from 'primeng/overlay';
import { AuthenticationService } from '../services/authentication.service';
import { collection } from 'firebase/firestore';
import { collectionData, docData, Firestore } from '@angular/fire/firestore';

@Component({
  selector: 'checkout-plain',
  templateUrl: './checkout-plain.component.html',
  styleUrls: ['./checkout-plain.component.css']
})
export class CheckoutPlainComponent implements OnInit, OnDestroy {

  b2bPaymentAllowed: boolean = false;
  b2bEnabled: boolean = false;
  companyInfo: any;

  //TODO parametrizzare su server
  shippingCostCad: number = 50;
  shippingCostThreshold: number = 500;
  shipDateRequired: boolean = false;
  shipDate: Date;
  maxShipDate: Date;
  minShipDate: Date;

  userdata: any;
  allAvaliable: boolean = true;
  atleastOneavailable: boolean = true;
  minSettimaneShip: number = 3;

  cartCount: number;

  cartDetail: any;
  countryCart: any;

  listTotalReserved: number;

  projectDiscountValue: number;
  extraSconto: number = 0;

  shippingMode: number = 1;

  shippingTotal: number = 0;

  shipAddress: any = {};
  disableShippingAddress: boolean = false;
  shippingInvalid: boolean = true;
  paypalSelect: boolean = false;
  paymentMethod: number = 3;

  checkPrize: number = 0;
  checkPrizeApplied: number = 0;
  checkPrizeThreshold: { [id: string]: number; } = {
    "2": 334,
    "3": 334,
    "4": 556,
    "5": 556,
    "9": 0
  };

  checkPrizeDisableText: string;
  rifOrdine: string;
  orderSending: boolean = false;
  onlyExWork: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  translations: any = {};

  @ViewChild('containerCreditCard', { static: true })
  containerCreditCard: ElementRef;

  cardElement: any;
  stripe: any;
  elements: any;
  creditCards: any[];
  crediCardSelectId: string;
  intestatarioCarta: string;
  insertNewCard: boolean;
  totalCard: number;

  dataShown: Map<string, any>;

  ready: boolean;
  lastRequestedConfigId: string;
  lastRequestedSku: string;

  displayInsufficientFund: boolean;

  bank = Bank;
  iban = Iban;
  swift = BicSwift;

  holydays: [];
  customer: any;
  customerDiscountApplied: string;

  aiPopperSubstiteVisibile: boolean = false;
  firestore: Firestore = inject(Firestore);
  hideCourierChoiceByCustomer: boolean = false;

  constructor(private route: ActivatedRoute, private messageService: MessageService, private quotationService: QuotationService,
    private router: Router, private confirmationService: ConfirmationService, private userPreferenceService: UserPreferenceService,
    private orderService: OrderService, private checkOutService: CheckoutService, private serviceMoment: ServiceMoment,
    private authService: AuthenticationService, private storeService: StoreService) {
  }

  ngOnInit() {
    paypal.Buttons({
      // Sets up the transaction when a payment button is clicked
      createOrder: (_data, actions) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: this.getPaypalAmount() // Can also reference a variable or function
            }
          }]
        });
      },
      // Finalize the transaction after payer approval
      onApprove: (_data, actions) => {
        return actions.order.capture().then((orderData) => {
          const transaction = orderData.purchase_units[0].payments.captures[0];
          this.confirmSendMailifAdmin(transaction.id);
          //console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
          //alert(`Transaction ${transaction.status}: ${transaction.id}\n\nSee console for all available details`);

        });
      }
    }).render('#paypal-button');

    let translationRequired = marker(['toQuotation', 'clickDataToModify', 'quotation', 'list', 'proceed', 'cancel', 'warningAllUpper', 'cardRefusedOrNotValid',
      'confirmOrder', 'errorSendOrderRetry', 'noCheckPrizeDiscountProject', 'nocheckPrizeAmountTooLow', 'courier', 'courierCustomer', 'verifyCreditCard',
      'ourMagazine', 'b2bCustomerEntrusted', 'creditCard', 'bankTransfer', 'mustProvideHeader', 'headerConfirmOrder', 'changePaymentModeFromB2b',
      'headerAskadminSendMail', 'askadminSendMail', 'yes', 'no']);

    this.stripe = Stripe(environment.stripePublicKey);

    this.elements = this.stripe.elements({
      locale: this.storeService.translateService.currentLang
    });

    this.cardElement = this.elements.create('card', {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: "#363636",
          color: "#363636",
          fontWeight: 400,
          fontFamily: "'Work Sans', sans-serif",
          fontSize: "14px",
          fontSmoothing: "antialiased",

          "::placeholder": {
            color: "#666"
          },
          ":-webkit-autofill": {
            color: "#666"
          }
        },
        invalid: {
          iconColor: "#ff3860",
          color: "#ff3860"
        }
      }
    });

    this.cardElement.mount('#formCreditCard');

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      //QUA e solo qua NON si puo' assegnare data a translations (chiavi non coincidono)
      this.translations["clickDataToModify"] = data.clickDataToModify;
      this.translations["toQuotation"] = data.toQuotation;
      this.translations["toList"] = data.toList;
      this.translations["quotation"] = data.quotation;
      this.translations["list"] = data.list;
      this.translations["proceed"] = data.proceed;
      this.translations["cancel"] = data.cancel;
      this.translations["confirmOrder"] = data.confirmOrder;
      this.translations["errorSendOrderRetry"] = data.errorSendOrderRetry;
      this.translations["noCheckPrizeDiscountProject"] = data.noCheckPrizeDiscountProject;
      this.translations["nocheckPrizeAmountTooLow"] = data.nocheckPrizeAmountTooLow;
      this.translations["ship1"] = data.courier;
      this.translations["ship2"] = data.courierCustomer;
      this.translations["ship3"] = data.ourMagazine;
      this.translations["pay1"] = data.b2bCustomerEntrusted;
      this.translations["pay2"] = data.creditCard;
      this.translations["pay3"] = data.bankTransfer;
      this.translations["mustProvideHeader"] = data.mustProvideHeader;
      this.translations["headerConfirmOrder"] = data.headerConfirmOrder;
      this.translations["areAyouSureDeleteCard"] = data.areAyouSureDeleteCard;
      this.translations["warningAllUpper"] = data.warningAllUpper;
      this.translations["areAyouSureDeleteCard"] = data.areAyouSureDeleteCard;
      this.translations["verifyCreditCard"] = data.verifyCreditCard;
      this.translations["cardRefusedOrNotValid"] = data.cardRefusedOrNotValid;
      this.translations["changePaymentModeFromB2b"] = data.changePaymentModeFromB2b;
      this.translations["headerAskadminSendMail"] = data.headerAskadminSendMail;
      this.translations["askadminSendMail"] = data.askadminSendMail;
      this.translations["yes"] = data.yes;
      this.translations["no"] = data.no;
    });

    this.countryCart = this.route.snapshot.data['infoCheckout'][0];

    if (this.countryCart.codStato.toLowerCase() == "it") {
      this.hideCourierChoiceByCustomer = true;
    } else {
      this.hideCourierChoiceByCustomer = false;
    }
    this.holydays = this.route.snapshot.data['infoCheckout'][1];
    this.cartDetail = this.route.snapshot.data['cartDetail'];
    this.companyInfo = this.route.snapshot.data['company'];
    this.b2bPaymentAllowed = this.companyInfo.clienteAffidato;

    this.userdata = this.authService.getUserProfile();

    this.shipDate = this.serviceMoment.addDayBusiness(12).toDate();
    this.minShipDate = this.serviceMoment.addDayBusiness(12).toDate();
    this.maxShipDate = this.serviceMoment.addDayBusiness(90).toDate();

    this.quotationService.getCompany(this.cartDetail.assignedCodCliFor).subscribe((c) => {
      this.customer = c;
      if (this.checkOutService.isModalitaListino2()) {
        this.customerDiscountApplied = "";
      } else {
        this.customerDiscountApplied = this.customer.desScontoListino;
      }
    });

    this.authService.userProfile$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.ManageChartsAndPayment();
    });

    const itemCollection = collection(this.firestore, 'adminParameter');

    collectionData(itemCollection).pipe(filter((doc) => doc?.length == 0), take(1), mergeMap((doc) => {
      let docInfo = doc[0] as any;
      return docData<any>(docInfo);
    }), distinctUntilChanged()).subscribe((value) => {
      this.updateMeterWorkable(value.remainingMeter);

    });
  }

  private ManageChartsAndPayment() {
    this.userdata = this.authService.getUserProfile();


    if (this.companyInfo.shipPlan) {
      if (this.companyInfo.shipPlan == 9) {
        this.onlyExWork = true;
        this.disableShippingAddress = true;
        this.shippingMode = 3;
        this.shippingInvalid = false;

      } else if (this.companyInfo.shipPlan == 4 || this.companyInfo.shipPlan == 5) {
        this.shippingCostThreshold = 800;
      }
    }

    this.removeSelectionOnItems();
    this.populateData();

    this.reCalculateInfoCheckOut();

    if (this.cartDetail.projectDiscount && this.cartDetail.projectDiscount > 0) {
      this.checkPrizeDisableText = this.translations["noCheckPrizeDiscountProject"];
    } else if (this.checkPrizeThreshold[this.companyInfo.shipPlan] > this.listTotalReserved) {
      this.checkPrizeDisableText = this.translations["nocheckPrizeAmountTooLow"];
    } else {
      this.checkPrizeDisableText = "";
    }

    if (this.userdata.checkPrize > 0) {
      this.checkPrize = Math.min(this.listTotalReserved * 0.1, this.userdata.checkPrize);
    }

    if (this.b2bPaymentAllowed) {
      if (this.companyInfo.fido <= 0) {
        return;
      }

      //suff per il totale, abilito tutto
      if (this.companyInfo.fido > (this.shippingTotal + this.listTotalReserved - this.checkPrizeApplied - this.projectDiscountValue)) {

        this.b2bEnabled = true;
        this.paymentMethod = 1;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private reCalculateInfoCheckOut() {
    this.listTotalReserved = this.checkOutService.calculateTotalListBeforeDiscountsForPlainCheckout(this.cartDetail);
    this.calculateShippingTotal();
    this.projectDiscountValue = this.checkOutService.calculateProjectDiscountForPlainCheckout(this.cartDetail);
    if (this.checkOutService.isModalitaListino2()) {
      this.extraSconto = this.cartDetail.extraDiscount * this.listTotalReserved / 100;
    }
    this.calculateTotalCreditCardOrPaypal();
    this.calculateTotalForAffidatario();
  }

  applyCheckPrize() {
    this.checkPrizeApplied = this.checkPrize;
    this.calculateTotalCreditCardOrPaypal();
    this.calculateTotalForAffidatario();
  }

  calculateShippingTotal() {
    this.shippingTotal = 0;
    if (this.shippingMode != 3) {
      this.shippingTotal = this.shippingMode == 1 && this.listTotalReserved < this.shippingCostThreshold ? this.shippingCostCad : 0;
    }
  }

  sendOrder() {

    this.confirmationService.confirm({
      key: 'confirmOrder',
      header: this.translations["headerConfirmOrder"],
      message: this.translations["confirmOrder"],
      acceptLabel: this.translations["proceed"],
      rejectLabel: this.translations["cancel"],
      accept: () => {

        if (this.paymentMethod == 2) {

          this.orderSending = true;
          if (this.crediCardSelectId) {
            this.sendCreditCardPayment(this.crediCardSelectId);
          } else {
            if (!this.intestatarioCarta) {
              this.messageService.add({
                key: 'toastErrorCreditCard',
                severity: 'error',
                summary: this.translations["error"],
                detail: this.translations.mustProvideHeader
              });
              return;
            }
            this.stripe.createPaymentMethod('card', this.cardElement, {
              billing_details: { name: this.intestatarioCarta },
            }).then((result) => {
              if (result.error) {
                this.messageService.add({
                  key: 'toastErrorCreditCard',
                  severity: 'error',
                  summary: this.translations["error"],
                  detail: this.translations.verifyCreditCard
                });
                this.orderSending = false;
                return;
                // Show error in payment form
              } else {
                //calcolo totali per carta di credito
                this.sendCreditCardPayment(result.paymentMethod.id);
              }
            });
          }
        } else {
          this.confirmSendMailifAdmin();
        }
      }
    });
  }

  private calculateTotalCreditCardOrPaypal() {
    this.totalCard = 0;
    if (this.paymentMethod != 2 && this.paymentMethod != 4) return;

    if (this.listTotalReserved) {
      this.totalCard += this.listTotalReserved - this.projectDiscountValue;
    }
    if (this.checkPrizeApplied) {
      this.totalCard -= this.checkPrizeApplied;
    }
    if (this.shippingTotal) {
      this.totalCard += this.shippingTotal;
    }
    if (this.countryCart.codStato.toLowerCase() == "it") {
      this.totalCard *= 1.22;
    }
  }

  private calculateTotalForAffidatario(isReset = true, showAlert: boolean = true) { //se e' a true il metodo riorganizza per mettere piu' valori possibili in b2b
    if (!this.b2bPaymentAllowed) return;

    let fidoAvailable = this.companyInfo.fido;
    let totalSingle = 0;
    if (this.listTotalReserved) {
      totalSingle += this.listTotalReserved - this.projectDiscountValue;
    }
    if (this.checkPrizeApplied) {
      totalSingle -= this.checkPrizeApplied;
    }

    if (isReset) { //sto riassegnando
      if (totalSingle <= fidoAvailable) {
        this.b2bEnabled = true;
        this.paymentMethod = 1;
        this.displayInsufficientFund = false;
      } else {
        this.b2bEnabled = false;
        this.paymentMethod = 3;
        this.displayInsufficientFund = true && showAlert;
      }
    } else {
      this.b2bEnabled = totalSingle <= fidoAvailable;
    }
  }

  private sendCreditCardPayment(paymentId: string) {
    this.calculateTotalCreditCardOrPaypal();

    this.orderService.generatePayment(paymentId, this.totalCard).subscribe((result: any) => {
      this.manageResponseStripeFromServer(result);
      if (!this.userdata.customerIdStripe) {
        //qua fare reload profile
      }

    }, (error: HttpErrorResponse) => {
      this.messageService.add({
        key: 'toastErrorCreditCard',
        severity: 'error',
        summary: this.translations["error"],
        detail: error.error
      });
      this.orderSending = false;
    });
  }

  private manageResponseStripeFromServer(result: any) {

    if (result.requiresAction) {
      this.stripe.handleCardAction(result.paymentIntentClientSecret,
        this.cardElement,
        {
          save_payment_method: true,
        }).then((result) => {
          if (result.error) {
            this.messageService.add({
              key: 'toastErrorCreditCard',
              severity: 'error',
              summary: this.translations["error"],
              detail: result.error
            });
            this.orderSending = false;
          } else {
            this.orderService.confirmPayment(result.paymentIntent.id).subscribe((result: any) => {
              this.manageResponseStripeFromServer(result); //chiamata ricorsiva. possono essere richieste verifiche in successione o altro
            }, () => {
              this.messageService.add({
                key: 'toastErrorCreditCard',
                severity: 'error',
                summary: this.translations["error"],
                detail: this.translations.cardRefusedOrNotValid
              });
              this.orderSending = false;
            });
          }
        }).catch(() => {
          this.messageService.add({
            key: 'toastErrorCreditCard',
            severity: 'error',
            summary: this.translations["error"],
            detail: this.translations.cardRefusedOrNotValid
          });
        });
    } else {
      this.confirmSendMailifAdmin();
    }
  }

  singleShippingModeChange() {
    this.disableShippingAddress = this.shippingMode == 3;

    this.calculateShippingTotal();
    this.calculateTotalCreditCardOrPaypal();
    this.updateEnableButtonOrder();
  }

  updateEnableButtonOrder() {
    if (this.disableShippingAddress) {
      this.shippingInvalid = false;
      return;
    }

    if (this.nonValidShipAddress(this.shipAddress)) {

      this.shippingInvalid = true;

    } else {
      this.shippingInvalid = false;
    }
  }

  nonValidShipAddress(shipAddress: any, shipMode: any = null): boolean {
    if (shipMode && shipMode == 3)
      return false;

    return !shipAddress.company || !shipAddress.address1 || !shipAddress.countryCode || !shipAddress.zipCode || !shipAddress.city1;
  }

  changeMethodPayment(event, noAlert = false, showAlertSelectB2b: boolean = true) {

    this.calculateTotalCreditCardOrPaypal();
    this.calculateTotalForAffidatario(noAlert);
    if (this.b2bPaymentAllowed && event == 3 && !noAlert && this.paymentMethod == 1 && this.b2bEnabled) {
      this.confirmationService.confirm({
        key: 'confirmChangeModePay',
        header: this.translations["warningAllUpper"],
        message: this.translations["changePaymentModeFromB2b"],
        acceptLabel: this.translations["proceed"],
        rejectLabel: this.translations["cancel"],
        accept: () => {

        },
        reject: () => {
          this.paymentMethod = 1;
          this.changeMethodPayment(event, true, showAlertSelectB2b)
        }
      });
    }

    if (this.paymentMethod == 2) {

      if (this.containerCreditCard.nativeElement.classList.contains("is-hidden")) {

        this.containerCreditCard.nativeElement.classList.remove("is-hidden");
        this.userPreferenceService.getAllCard().subscribe(data => {
          this.creditCards = data;
          if (this.creditCards && this.creditCards.length > 0) {
            this.crediCardSelectId = this.creditCards[0].id;

          } else {
            this.insertNewCard = true;
          }
        });
      }

    } else {
      this.containerCreditCard.nativeElement.classList.add("is-hidden");
    }

    this.paypalSelect = this.paymentMethod == 4;
  }

  sendOrderToServer(disableSendMailCustomer: boolean, idTrasactionPaypal: string = undefined) {
    this.orderSending = true;
    let cartDetailOrder = cloneDeep(this.cartDetail);

    cartDetailOrder.total = this.listTotalReserved;
    cartDetailOrder.discountsProj = this.projectDiscountValue;
    cartDetailOrder.checkPrizeApplied = this.checkPrizeApplied;
    if (idTrasactionPaypal) {
      cartDetailOrder.idTrasactionPaypal = idTrasactionPaypal;
    }
    cartDetailOrder.payment = this.paymentMethod;

    let stringValuePay = "pay" + this.paymentMethod.toString();
    cartDetailOrder.paymentDescription = this.translations[stringValuePay];

    cartDetailOrder.shipAddress = this.shipAddress;

    cartDetailOrder.shipMode = this.shippingMode;
    cartDetailOrder.shipCost = this.shippingTotal;

    let stringValue = "ship" + this.shippingMode.toString();
    cartDetailOrder.shipDescription = this.translations[stringValue];

    if (this.rifOrdine) {
      cartDetailOrder.riferimento = this.rifOrdine.trim().replace(';', ' ').substring(0, 24);
    }

    let orderType = this.allAvaliable ? 0 : 2;
    if (this.shipDateRequired) {
      cartDetailOrder.shipDate = this.shipDate;
      orderType = 2;
    }

    if (this.cartDetail.assignedCodCliFor) {
      this.userPreferenceService.getCompany(this.cartDetail.assignedCodCliFor).pipe(switchMap((userProfile) => {
        return this.orderService.sendOrderUnique(cartDetailOrder, orderType, userProfile, this.checkOutService.getIdListino(),
          disableSendMailCustomer, this.checkOutService.isModalitaListino2())
      })).subscribe({
        next: () => {
          this.authService.forceReload().then(() => {
            this.router.navigateByUrl(this.storeService.createUrl("/order-complete") as string);
          }).catch(() => {
            this.authService.checkAuth().pipe(take(1)).subscribe((isAuthenticated) => {
              if(isAuthenticated) {
                this.router.navigateByUrl(this.storeService.createUrl("/order-complete") as string);
              }
            });
          });
        },
        error: (err) => {
          console.error(err);
          this.orderSending = false;
          alert(this.translations["errorSendOrderRetry"]);
        }
      });
    } else {
      this.orderService.sendOrderUnique(cartDetailOrder, orderType, undefined, this.checkOutService.getIdListino(),
        disableSendMailCustomer, this.checkOutService.isModalitaListino2()).subscribe({
          next: () => {
            this.authService.forceReload().then(() => {
              this.router.navigateByUrl(this.storeService.createUrl("/order-complete") as string);
            }).catch(() => {
              this.authService.checkAuth().pipe(take(1)).subscribe((isAuthenticated) => {
                if(isAuthenticated) {
                  this.router.navigateByUrl(this.storeService.createUrl("/order-complete") as string);
                }
              });
            });
          },
          error: (err) => {
            console.error(err);
            this.orderSending = false;
            alert(this.translations["errorSendOrderRetry"]);
          }
        });
    }
  }

  goTo3Car() {
    this.router.navigate(this.storeService.createUrl(["quotations", this.cartDetail.id, "checkout"]) as any[]);
  }

  recalcAvailability(event: any) {
    for (let item of this.cartDetail.items) {
      if (item.sku == event.sku) {
        item.availability = event.newQuantity;
      }
    }
    this.checkOutService.evaluateItemToSubstitute(this.cartDetail);
    this.populateData();
  }

  private populateData() {

    this.dataShown = new Map<string, any>();

    if (this.cartDetail.section) {
      for (let sect of this.cartDetail.section) {
        this.dataShown.set(sect.sectionId, { name: sect.name, items: new Array<any>() });
      }
    }

    //vedere se spostare gestione disponibilità nella checkoutservice
    this.allAvaliable = true;
    this.minSettimaneShip = 3;
    this.atleastOneavailable = false;

    this.dataShown.set("", { name: "", items: new Array<any>() });
    for (let item of this.cartDetail.items) {
      if (item.type === 2) {
        // in caso di configurazione  è considerata disponibile in 48h
        //non cambia nulla
      } else if (item.type === 3) {
        // in caso di item custom gli articoli sono da considerarsi disponbili in almeno 3 settimane
        this.allAvaliable = false;
      }
      else {
        // gli articoli disponibili sono sempre evadibili in 48h
        let availableItem = item.quantity <= item.availability;
        this.allAvaliable = this.allAvaliable && availableItem;
        this.atleastOneavailable = this.atleastOneavailable || (item.availability > 0);

        //se articolo disponibile ignoro lead-time
        if (!availableItem) {
          let indexLeadTime = item.attributes.findIndex(at => at.sysName == 'lead-time');
          if (indexLeadTime != -1) {
            let leadTime = item.attributes[indexLeadTime].value;
            if (leadTime > this.minSettimaneShip) {
              this.minSettimaneShip = leadTime;
            }
          }
        }


      }
      if (item.childItems) {
        for (let itemChild of item.childItems) {
          // gli articoli disponibili sono sempre evadibili in 48h
          let availableItemChild = true;
          if (itemChild.um == 'M' && item.type === 2) {
            availableItemChild = this.checkOutService.manageAvailabilityVariantForPlainCheckout(itemChild)
          } else {
            availableItemChild = itemChild.quantity <= itemChild.availability;
          }

          this.allAvaliable = this.allAvaliable && availableItemChild;
          this.atleastOneavailable = this.atleastOneavailable || (itemChild.availability > 0);

          if (!availableItemChild) {
            let indexLeadTimeChild: number = -1;
            if (itemChild.attributes) {//gli itemchild delle configurazioni non hanno gli attributi direttamente
              indexLeadTimeChild = itemChild.attributes.findIndex(at => at.sysName == 'lead-time');
            } else {
              indexLeadTimeChild = itemChild.entity.attributes.findIndex(at => at.sysName == 'lead-time');
            }

            if (indexLeadTimeChild != -1) {
              let leadTimeChild : any 
              if (itemChild.attributes) {//gli itemchild delle configurazioni non hanno gli attributi direttamente
                leadTimeChild = itemChild.attributes[indexLeadTimeChild].value;
              } else {
                leadTimeChild = itemChild.entity.attributes[indexLeadTimeChild].value;
              }
              if (leadTimeChild > this.minSettimaneShip) {
                this.minSettimaneShip = leadTimeChild;
              }
            }
          }
        }
      }
      let sectionItemId: string = "";
      let posInSection: number = -1;
      if (this.cartDetail.section) {
        for (let sect of this.cartDetail.section) {
          posInSection = sect.item.findIndex(i => i == item.configurationId);
          if (posInSection != -1) {
            sectionItemId = sect.sectionId;
            break;
          }
        }
      }
      let sectionToAddItem = this.dataShown.get(sectionItemId);
      if (posInSection == -1) {
        sectionToAddItem.items.push(item);
      } else {
        sectionToAddItem.items.splice(posInSection, 0, item);
      }
      this.dataShown[sectionItemId] = sectionToAddItem;
    }

    this.minShipDate = this.serviceMoment.addDayBusiness(this.minSettimaneShip * 5).toDate();
    if (this.allAvaliable) {
      this.shipDate = this.serviceMoment.addDayBusiness(2).toDate();
    } else {
      this.shipDate = this.serviceMoment.addDayBusiness(this.minSettimaneShip * 5).toDate();
    }
  }

  updateShipDateRequired(event: boolean) {
    if(event && this.allAvaliable) {
      this.shipDate = this.serviceMoment.addDayBusiness(this.minSettimaneShip * 5).toDate();
    } else if (event == false && this.allAvaliable) {
      this.shipDate = this.serviceMoment.addDayBusiness(2).toDate();
    }
  }

  openSuggest(event: any, item: any) {
    item.itemSuggest = [];
    if (this.lastRequestedConfigId == event.configId) {
      return;
    }
    this.lastRequestedConfigId = event.configId;
    this.removeSelectionOnItems();
    let indexItem = this.cartDetail.items.findIndex(it => it.configurationId == this.lastRequestedConfigId);
    this.cartDetail.items[indexItem].highlight = true;
    this.lastRequestedSku = event.sku;
    this.ready = false;
    this.aiPopperSubstiteVisibile = true;
    this.checkOutService.getSubstitute(this.lastRequestedConfigId, this.cartDetail.id, 1).subscribe((data) => {
      item.itemSuggest = data;
      this.ready = true;
    });
  }

  removeSelectionOnItems() {
    for (let item of this.cartDetail.items) {
      item.highlight = false;
    }
    this.aiPopperSubstiteVisibile = false;
  }

  substituteArticle(event: any) {

    if (!event.suggestedItem) {
      this.ready = false;
      event.item.itemSuggest = [];
      this.lastRequestedConfigId = undefined;
      this.removeSelectionOnItems();
      return;
    }

    let item = event.item;
    item.itemSuggest = [];
    let suggestedItem = event.suggestedItem;

    let quantityToSubmit = item.quantity;

    let indexPiecesItem = item.attributes.findIndex(at => at.sysName == 'pieces');
    if (indexPiecesItem != -1 && item.attributes[indexPiecesItem].value) {
      quantityToSubmit = quantityToSubmit * item.attributes[indexPiecesItem].value;
    }

    let indexPiecesSubstituteItem = suggestedItem.attributes.findIndex(at => at.sysName == 'pieces');
    if (indexPiecesSubstituteItem != -1 && suggestedItem.attributes[indexPiecesSubstituteItem].value) {
      quantityToSubmit = Math.ceil(quantityToSubmit / suggestedItem.attributes[indexPiecesSubstituteItem].value);
    }

    this.router.navigate(this.storeService.createUrl(['/product', suggestedItem.productModel]) as any[], {
      queryParams: {
        variant: suggestedItem.id,
        oldConfigId: this.lastRequestedConfigId,
        q: quantityToSubmit,
        returnTo: ReturnTo.quotationCheckoutPlain
      },
      state: {
        accessories: item.childItems
      }
    });
  }

  confirmSendMailifAdmin(transactionIdPaypal: string = undefined) {
    if (IsSuperAdmin(this.userdata)) {
      this.confirmationService.confirm({
        key: 'sendMailToCustomer',
        header: this.translations["headerAskadminSendMail"],
        message: this.translations["askadminSendMail"],
        acceptLabel: this.translations["yes"],
        rejectLabel: this.translations["no"],
        accept: () => {

          this.sendOrderToServer(false, transactionIdPaypal);
        },
        reject: () => {
          this.sendOrderToServer(true, transactionIdPaypal);
        }
      });
    } else {
      this.sendOrderToServer(false, transactionIdPaypal);
    }
  }

  //paypal vuole al massimo 2 cifre dopo la virgola
  getPaypalAmount() {
    return round(this.totalCard, 2).toString();
  }

  updateMeterWorkable(availability: number) {
    if (this.checkOutService.updateAdminRemainMeter(availability)) {
      this.populateData();
    }
  }
}
