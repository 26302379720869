<header *ngIf="!hide">
  <div class="container is-widescreen">
    <div class="columns top-menu">
      <div class="column is-one-fifth">
        <div class="first-row">
          <div class="nav-logo">
            <a [routerLink]="['/'] | localize">
              <img src="/images/macrolux-logo.png" alt="Macrolux B2B" class="logo" />
            </a>
          </div>
        </div>
      </div>
      <div class="column pb-0">
        <div class="nav-search">
          <div class="columns is-gapless">
            <div class="column is-narrow is-marginless">
              <div class="category-menu-popper nav-menu-popper" style="line-height: 1em;"
                (click)="popperSelectProduct.toggle($event)">
                <span class="is-italic is-size-7" [translate]="'navMenuChoice'">Scegli per</span><br />
                <span class="has-text-weight-semibold is-size-6" [translate]="'category'">categoria</span>&nbsp;&nbsp;<i
                  class="fas fa-angle-down"></i>
              </div>
            </div>
            <div class="column is-marginless">
              <search class="field has-addons first-row" [shopMode]="shopMode" [viewer]="true"></search>
            </div>
            <div class="column is-narrow is-marginless">
              <div style="padding: .6em 0 .2em 1.4em; cursor: pointer;">
                <new-notifications></new-notifications>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="first-row is-flex is-justify-content-flex-end">
          <div style="line-height: 1.1em;">
            <span class="list-type is-italic is-size-7">Preventivo corrente</span><br />
            <span
              class="is-inline-block has-text-weight-semibold has-text-dark is-size-6 truncate">{{currentList?.name}}</span>
          </div>
          <div class="cart">
            <div class="pointer" (click)="accountPopper.toggle($event)">
              <i class="far fa-user fa-2x"></i>
              <i class="fas fa-angle-down" style="margin-left:5px"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-overlayPanel #popperSelectProduct (onShow)="onCategoryMenuShown()" (onHide)="onCategoryMenuHide()">
      <div class="category-menu is-clearfix is-flex">
        <div class="cf-column">
          <ul>
            <li *ngFor="let cat of categories" [class.active]="currentCategory?.id == cat.id" [dndDraggable]="cat"
              dndType="category" (dndStart)="onDragStart($event)" (dndEnd)="onDragEnd($event)">
              <a hoverIntent (onHoverIntent)="selectCategory(cat)" (click)="popperSelectProduct.hide()"
                class="level not-underline" [routerLink]="['/catalog', cat.id] | localize">
                <span class="level-left">
                  <span class="level-item">{{cat.name}}</span>
                </span>
                <span class="level-right">
                  <span class="level-item"><i class="fas fa-angle-right"></i></span>
                </span>
              </a>
            </li>
          </ul>
          <hr />
          <ul>
            <li>
              <a (click)="popperSelectProduct.hide()" class="level not-underline accessories-link"
                [routerLink]="['/accessories'] | localize">
                <span class="level-left">
                  <span class="level-item" [translate]="'accessories'">Accessori</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="cs-column" *ngIf="currentCategory">
          <ul>
            <ng-container *ngFor="let cat of currentCategory.childrens">
              <li *ngIf="filteredFamilies[cat.id]?.length > 0" class="subcategory">
                <h3 class="subcategory-name">{{cat.name}}</h3>
                <div class="families">
                  <div *ngFor="let fam of filteredFamilies[cat.id]" [dndDraggable]="fam" dndType="family"
                    (dndStart)="onDragStart($event)" (dndEnd)="onDragEnd($event)"
                    [class.active]="currentFamily?.id == fam.id && currentSubCategory?.id == cat.id" class="family">
                    <a hoverIntent (onHoverIntent)="selectFamily(fam, cat)" (click)="popperSelectProduct.hide()"
                      class="has-text-dark not-underline" [routerLink]="['/catalog', 'families', fam.id] | localize"
                      [queryParams]="{sub: cat.id}">{{fam.name}}</a>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="cp-column" *ngIf="currentFamily" [cdnImgBackground]="currentFamily.image" type="family">
          <ul class="pl-0">
            <li class="modelli" [translate]="'models'">
              Modelli
            </li>
            <li *ngFor="let product of currentFamily.productsModel | orderBy: 'name'" [dndDraggable]="product"
              dndType="product" (dndStart)="onDragStart($event)" (dndEnd)="onDragEnd($event)">
              <a (click)="goToProduct(product.id)" class="has-text-dark">
                {{product.name}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </p-overlayPanel>
  </div>
  <div class="container is-widescreen">
    <div *ngIf="!dragging && favourites.length > 0" style="height: 25px;margin-bottom: .25rem;" class="tags">
      <span class="tag is-light" *ngFor="let favourite of favourites">
        <a [routerLink]="[favourite.link] | localize" class="favourite is-size-7">{{favourite.name}}</a>
      </span>
    </div>
    <div dndDropzone (dndDrop)="onFavouriteDrop($event)" style="height: 25px; background-color: whitesmoke;"
      *ngIf="dragging">
      <span class="is-size-7"><i class="far fa-fw fa-heart fa-fw"></i>
        <span [translate]="'dragHereYourFavourite'">Trascina qui per aggiungere ai preferiti</span>
      </span>
    </div>
  </div>
</header>
<p-overlayPanel #accountPopper>
  <div>
    <a [routerLink]="['/quotations', 'listQuotations'] | localize" class="has-text-dark"
      [translate]="'yoursQuotations'">Preventivi</a>
  </div>
  <div>
    <a class="has-text-dark" [routerLink]="['/profile', 'detail'] | localize" [translate]="'detailCustomer'">Dettagli
      cliente</a>
  </div>
  <div>
    <a class="has-text-dark" [routerLink]="['/profile', 'settings'] | localize"
      [translate]="'settings'">Impostazioni</a>
  </div>
  <hr>
  <div>
    <a (click)="logOut()" class="has-text-dark">Logout</a>
  </div>
</p-overlayPanel>