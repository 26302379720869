<section class="section">
  <div class="container">
    <div class="columns">
      <div *ngIf="categoryAndFamily$ | async as categoryFamily" class="column is-narrow has-text-right">
        <a *ngIf="categoryFamily[0]" [routerLink]="['/catalog', categoryFamily[0].id] | localize">
          {{categoryFamily[0].name}}
        </a>
        <span *ngIf="categoryFamily[1]"> / {{categoryFamily[1].name | titlecase}} / </span>
        <a class="has-text-weight-semibold" (click)="familyPopperContent.toggle($event)">{{product.name}}&nbsp;&nbsp;<i
            class="fas fa-angle-down"></i></a>
      </div>
    </div>
    <div class="columns">
      <div class="column is-3">
        <product-images></product-images>
      </div>

      <div class="column is-6 main-col">
        <h1 class="title is-3">
          {{product.name}}&nbsp;
          <a (click)="toggleFavourite()" tooltipStyleClass="m-tooltip"
            [pTooltip]="isInFavoutites ? translations['removeFromFavourite'] : translations['AddToFavourite']">
            <i class="fa-star fa-xs" [class.has-text-black]="!isInFavoutites" [class.has-text-warning]="isInFavoutites"
              [class.fas]="isInFavoutites" [class.far]="!isInFavoutites"></i>
          </a>
        </h1>
        <h3 class="subtitle is-5">
          <span *ngIf="categoryAndFamily$ | async as categoryFamily; else naCategory">
            {{categoryFamily[0]?.name}} - {{categoryFamily[0]?.subcategory?.name}}
          </span>
          <ng-template #naCategory>
            <span>n/a</span>
          </ng-template>
        </h3>
        <p class="text">
          <ng-container *ngIf="!currentVariant.esWarning">
            {{currentVariant.automaticDescription}}
          </ng-container>
          <ng-container *ngIf="currentVariant.esWarning">
            N/A
          </ng-container>
        </p>

        <attribute-control [product]="product" [editMode]="configurationEdit"></attribute-control>
      </div>
      <div class="column is-3 is-flex">
        <add-to-cart [product]="product" [notReset]="isSubstitutingArticle" (askReplacement)="obtainReplacement($event)"
          class="add-to-cart">
        </add-to-cart>
        <p-dialog [(visible)]="aiPopperSubstiteVisibile" [closable]="true" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false"
          [resizable]="false">
          <ng-template pTemplate="header" >
            <div>
              <p class="title is-4" [translate]="'replaceArticles'"></p>
            </div>
          </ng-template>
          <ng-template pTemplate="closeicon">
            <i class="has-text-white fas fa-times"></i>
          </ng-template>
          <item-substitute [ready]="suggestLoaded" [item]="currentVariant" [itemSuggest]="suggestArticle"
            (close)="itemSubstituteSelect($event)"></item-substitute>
        </p-dialog>
      </div>
    </div>
    <hr *ngIf="currentVariant.accessories?.length > 0" />
    <div class="columns" *ngIf="currentVariant.accessories?.length > 0">
      <div class="column">
        <accessory-list (hasComponentsWarn)="setComponentsWarning($event)"></accessory-list>
      </div>
    </div>
    <hr />
    <h3 class="title is-4">Downloads</h3>
    <div class="columns downloads">
      <div class="column">
        <div class="columns">
          <div class="column is-3">
            <div class="level">
              <a (click)="downloadBrochure()" class="level-left has-text-dark"
                [ngClass]="{'no-decoration':isDownloadingBrochure}">
                <div class="level-item" style="margin-right:0;">
                  <figure class="image is-32x32">
                    <img src="/images/downloads/BROCHURE.png" />
                  </figure>
                </div>
                <div class="level-item">
                  <span class="button" style="border:none" [ngClass]="{'is-loading':isDownloadingBrochure}">
                    Brochure
                  </span>
                </div>
              </a>
            </div>
            <ng-container *ngIf="(product.files | hasFile:'conformity'); else noConformity">
              <div class="level">
                <a class="level-left has-text-dark" (click)="download('conformity')">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/CERTIFICATION.png" />
                    </figure>
                  </div>
                  <div class="level-item" [translate]="'certification'">
                    Certificazione
                  </div>
                </a>
              </div>
            </ng-container>
            <ng-template #noConformity>
              <div class="level inactive">
                <div class="level-left has-text-dark">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/CERTIFICATION.png" />
                    </figure>
                  </div>
                  <div class="level-item" [translate]="'certification'">
                    Certificazione
                  </div>
                </div>
              </div>
            </ng-template>

          </div>
          <div class="column is-3">
            <ng-container *ngIf="(product.files | hasFile:'instructions'); else noIstruction">
              <div class="level">
                <a class="level-left has-text-dark" (click)="download('instructions')">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/ISTRUCTION.png" />
                    </figure>
                  </div>
                  <div class="level-item" [translate]="'installationInstructions'">
                    Istruzioni di montaggio
                  </div>
                </a>
              </div>
            </ng-container>
            <ng-template #noIstruction>
              <div class="level inactive">
                <div class="level-left has-text-dark">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/ISTRUCTION.png" />
                    </figure>
                  </div>
                  <div class="level-item" [translate]="'installationInstructions'">
                    Istruzioni di montaggio
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-container *ngIf="(product.files | hasFile:'autocad'); else noAutocad">
              <div class="level">
                <a class="level-left has-text-dark" (click)="download('autocad')">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/DWG.png" />
                    </figure>
                  </div>
                  <div class="level-item">
                    DWG
                  </div>
                </a>
              </div>
            </ng-container>
            <ng-template #noAutocad>
              <div class="level inactive">
                <div class="level-left has-text-dark">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/DWG.png" />
                    </figure>
                  </div>
                  <div class="level-item">
                    DWG
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="column is-3">
            <ng-container *ngIf="(product.files | hasFile:'techSheet'); else noTechSheet">
              <div class="level">
                <a class="level-left has-text-dark" (click)="download('techSheet')">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/TECHNICAL.png" />
                    </figure>
                  </div>
                  <div class="level-item" [translate]="'technicalData'">
                    Dati tecnici
                  </div>
                </a>
              </div>
            </ng-container>
            <ng-template #noTechSheet>
              <div class="level inactive">
                <div class="level-left has-text-dark">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/TECHNICAL.png" />
                    </figure>
                  </div>
                  <div class="level-item" [translate]="'technicalData'">
                    Dati tecnici
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-container *ngIf="(product.files | hasFile:'photometric'); else noPhotometric">
              <div class="level">
                <a class="level-left has-text-dark" (click)="download('photometric')">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/LDT.png" />
                    </figure>
                  </div>
                  <div class="level-item">
                    LDT
                  </div>
                </a>
              </div>
            </ng-container>
            <ng-template #noPhotometric>
              <div class="level inactive">
                <div class="level-left has-text-dark">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/LDT.png" />
                    </figure>
                  </div>
                  <div class="level-item">
                    LDT
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="column is-3">
            <ng-container *ngIf="(product.files | hasFile:'warranty'); else noWarranty">
              <div class="level">
                <a class="level-left has-text-dark" (click)="download('warranty')">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/warranty.png" />
                    </figure>
                  </div>
                  <div class="level-item" [translate]="'warranty'">
                    Garanzia
                  </div>
                </a>
              </div>
            </ng-container>
            <ng-template #noWarranty>
              <div class="level inactive">
                <div class="level-left has-text-dark">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/warranty.png" />
                    </figure>
                  </div>
                  <div class="level-item" [translate]="'warranty'">
                    Garanzia
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-container *ngIf="(product.files | hasFile:'bim'); else noBim">
              <div class="level">
                <a class="level-left has-text-dark" (click)="download('bim')">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/LDT.png" />
                    </figure>
                  </div>
                  <div class="level-item">
                    3D BIM Revit
                  </div>
                </a>
              </div>
            </ng-container>
            <ng-template #noBim>
              <div class="level inactive">
                <div class="level-left has-text-dark">
                  <div class="level-item">
                    <figure class="image is-32x32">
                      <img src="/images/downloads/LDT.png" />
                    </figure>
                  </div>
                  <div class="level-item">
                    3D BIM Revit
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<p-overlayPanel #familyPopperContent>
  <div class="columns">
    <div class="column">
      <div *ngFor="let model of family?.productsModel" hoverIntent (onHoverIntent)="selectFamilyPopper(model)">
        <a (click)="goto(model)" style="white-space: nowrap;" class="has-text-dark"
          [class.has-text-weight-semibold]="model.id == product.id">{{model.name}}</a>
      </div>
    </div>
    <div class="column">
      <figure class="image is-128x128 familyPopperImage">
        <img fallback-src="https://via.placeholder.com/400x400" query="width=400&height=400&mode=crop"
          [cdnImg]="familyPopperModel?.images[0]" [productId]="familyPopperModel?.id">
      </figure>
    </div>
  </div>
</p-overlayPanel>
<div *ngIf="currentList && (tmpItems?.count() > 0 || configurationEdit)" class="add-to-list">
  <div class="container">
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <div>
            <ng-container *ngIf="tmpItems?.count() > 0">
              <span [translate]="'selected'">Hai selezionato</span>&nbsp;<span *ngIf="selectedArticle?.count() > 0"
                [translate]="'theArtSku'" [translateParams]="{sku: currentVariant.sku}"></span>
              <span *ngIf="selectedAccessories?.count() > 0 && selectedArticle?.count() > 0">
                &nbsp;
                <span [translate]="'and'"></span>
                &nbsp;
              </span>
              <span *ngIf="selectedAccessories?.count() > 0">
                &nbsp;{{selectedAccessories.count()}}&nbsp;<span [translate]="'accessoriesLowerCase'">accessori</span>
              </span>
              <span *ngIf="accSubstituteWarning"><br /><span [translate]="'youWillLostAccessories'"></span></span>
            </ng-container>
          </div>

        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="field">
            <p class="control">
              <button class="button" (click)="cancelAdd()" [translate]="'cancel'">Annulla</button>
            </p>
          </div>
        </div>
        <div class="level-item is-marginless">
          <div class="field">
            <p class="control">
              <button class="button is-link" [ngClass]="{'is-loading':isAdding}" (click)="addToList(true)">
                <ng-container *ngTemplateOutlet="nameList"></ng-container>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog key="confirmDialogProductProject" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmDialog key="confirmDialogProduct" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmDialog key="confirmDialogProductNoComponent" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog [header]="translations['warningAllUpper']" [(visible)]="displayAlert" [modal]="true"
  [translate]="'noModifyListBlock'">
  La modifica della lista prodotti non è possibile per le liste in lavorazione
</p-dialog>
<p-toast key='toastErrorProduct' id="toastError"></p-toast>

<ng-template #nameList>
  <span *ngIf="oldConfigIdToRemove; else normal" [translate]="'substituteArticleIn'"></span>&nbsp;
  <span *ngIf="currentList.type; else listaSpan" [translate]="'quotation'">
    Preventivo
  </span>
  <span>&nbsp;{{currentList.name}}</span>
</ng-template>
<ng-template #normal>
  <span *ngIf="configurationEdit; else no_edit" [translate]="'update'">Aggiorna</span>
</ng-template>
<ng-template #no_edit>
  <span [translate]="'addTo'">Aggiungi a</span>
</ng-template>
<ng-template #listaSpan>
  <span [translate]="'list'">
    Lista
  </span>
</ng-template>