<p style="margin-top: 0.75em" *ngIf="!baseQuotation.statusProject; else registered" >
  <span [innerHtml]="'registerAdvantage' | translate"></span>
</p>
<ng-template #registered>
  <div [ngSwitch]="baseQuotation.statusProject">
    <div *ngSwitchCase="1" class="notification is-info">
      <i class="fas fa-hourglass-start fa-fw"></i>&nbsp;<span [translate]="'projectWaitingApproval'">Progetto in attesa di approvazione</span>
    </div>
    <div *ngSwitchCase="3" class="notification is-warning">
      <i class="fas fa-exclamation-triangle fa-fw"></i>&nbsp;<span [translate]="'projectRejected'">Progetto rifiutato</span>
    </div>
    <div *ngSwitchDefault>
      <p *ngIf="shopMode == 0">
        <span class="is-size-5 has-text-weight-semibold" [translate]="'projectConfirmed'">Registrazione confermata</span><br>
        <ng-container *ngIf="baseQuotation.projectDiscount > 0">
          <span [translate]="'assignedProjectDiscount'">
            Ti è stato assegnato un extra sconto del</span>&nbsp;<b>{{baseQuotation.projectDiscount}}%</b>
        </ng-container>
      </p>
      <p *ngIf="shopMode == 1">
        <span class="is-size-5 has-text-weight-semibold" [translate]="'projectConfirmed'">Registrazione confermata</span>
      </p>
    </div>
  </div>
</ng-template>
<hr>
<form #f="ngForm" (ngSubmit)="register()">
  <div class="columns">
    <div class="column">
      <label class="label" [translate]="'header'">Intestazione</label>
      <div class="control">
        <div *ngIf="customer && customer.billingAddress && customer.billingAddress.company && customer.billingAddress.city1 ; else no_intestazione">
          <span *ngIf="customer.billingAddress.company; else nameCompany">{{customer.billingAddress.company}}</span>
          <br>
          <span>{{customer.billingAddress.address1}}</span><br>
          <span>{{customer.billingAddress.zipCode}} {{customer.billingAddress.city1}}</span>
        </div>
        <ng-template #no_intestazione>
          <span class="has-text-danger"><i class="fas fa-exclamation fa-lg"></i></span>
        </ng-template>
        <ng-template #nameCompany>
          <span>{{customer.billingAddress.lastName}} {{customer.billingAddress.firstName}}</span>
        </ng-template>
      </div>
    </div>
    <div class="column" *ngIf="shopMode === 0">
      <label class="label" [translate]="'purchaseValue'">Valore d'acquisto</label>
      <div class="control">
        <span [class.is-line-through]="baseQuotation.projectDiscount > 0">{{totaleRiservato | currency:'EUR'}}</span>
      </div>
      <div class="control" *ngIf="baseQuotation.projectDiscount > 0">

        <span>{{(totaleRiservato - projectDiscountValue) | currency:'EUR'}}</span>
        &nbsp;<i class="fas fa-info-circle fa-sm fa-fw" [pTooltip]="translations.anthologyWarn" tooltipStyleClass="m-tooltip"></i>
      </div>
    </div>
    <div class="column">
      <label class="label" [translate]="'salesValue'">Valore di vendita</label>
      <div class="control">
        <span>{{totaleListino | currency:'EUR'}}</span>
      </div>
    </div>
    <div class="column" *ngIf="shopMode === 0">
      <label class="label" [translate]="'profitMargin'">Margine</label>
      <div class="control">
        <span [class.is-line-through]="baseQuotation.projectDiscount > 0">{{margine | percent:'1.1-1'}}</span>
      </div>
      <div class="control" *ngIf="baseQuotation.projectDiscount > 0">
        {{(totaleListino - (totaleRiservato - projectDiscountValue)) / totaleListino | percent:'1.1-1'}}
      </div>
    </div>
    <div class="column">
      <label class="label" [translate]="'dueDate'">Data prevista</label>
      <p class="control ui-fluid" [class.has-icons-right]="f.form.controls.date?.invalid">
        <p-calendar [(ngModel)]="dateModel" [required]="true" name="date" dateFormat="dd/mm/yy" [firstDayOfWeek]="1"
                    [minDate]="minDate" [disabled]="baseQuotation.statusProject || baseQuotation.listState != 0"
                    appendTo="body" #date="ngModel" [placeholder]="'mandatory' | translate" (onSelect)="onProjectDateChange()"
                    [inputStyleClass]="f.form.controls.date?.invalid ? 'input is-danger' : 'input'"></p-calendar>
      </p>
    </div>
  </div>
  <p class="has-text-danger" *ngIf="!(customer.billingAddress.company && customer.billingAddress.city1)" [translate]="'headerQuotationCity'">
    Intestare il preventivo con ragione sociale e città per procedere alla registrazione.
  </p>
  <hr />
  <div class="buttons is-right" *ngIf="!baseQuotation.statusProject">
    <div [pTooltip]="translations.requireTotalBiggerThan" tooltipPosition="left"
         tooltipStyleClass="m-tooltip" [tooltipDisabled]="totaleRiservato >= 2500">
      <button class="button is-warning is-medium" type="submit" [ngClass]="{'is-loading': isSending}"
              [disabled]="baseQuotation.listState != 0 || totaleRiservato < 2500 || !dateModel || !(customer.billingAddress.company && customer.billingAddress.city1)"
              [translate]="'requestRegistration'">
        Richiedi registrazione >
      </button>
    </div>
  </div>
  <div class="buttons is-right" *ngIf="baseQuotation.statusProject && baseQuotation.statusProject != 3 && baseQuotation.listState == 0">
    <button class="button is-info is-medium" type="button" (click)="unRegister()" [ngClass]="{'is-loading': isSending}" [translate]="'undoneRegistration'">
      Annulla registrazione
    </button>
  </div>
</form>
