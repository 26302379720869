import { Component, OnInit } from '@angular/core';
import { Subject, map, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';


@Component({
  selector: 'auth-callback',
  templateUrl: './auth-callback.component.html',
})
export class AuthCallbackComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router, private authService: AuthenticationService
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    // this.authService.checkAuth().pipe(takeUntil(this.destroy$)).subscribe((auth) => {
    //   console.log(`AuthCallbackComponent: ${auth}`);
    // });
  }

}