import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PdfService } from '../pdf.service';
import { QuotationService } from '../quotation.service';
import { Subject } from 'rxjs';
import { StoreService } from '../store.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { saveAs } from 'file-saver';

@Component({
  selector: 'quotation-retail',
  templateUrl: './quotation-retail.component.html',
  styleUrls: ['./quotation-retail.component.scss']
})
/** quotation-retail component*/
export class QuotationRetailComponent implements OnInit, OnDestroy {

  baseQuotation: any;
  data: any;
  source: any;
  ready: boolean = false;

  errorCreatingPdf: string;
  translations: any = {};

  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('pdfViewerAutoLoadRetail', { static: false} )
  pdfViewerAutoLoadRetail;

  showComponentiConfigInPdf: boolean = true;
  showProductCodeInPdf: boolean = true;
  showProductImageInPdf: boolean = true;
  
  constructor(private pdfService: PdfService, private quotationService: QuotationService,
     private storeService: StoreService) {
  }

  ngOnInit() {
    let translationRequired = marker(['errorCreatingPdf', 'proforma', 'rev']);

    this.storeService.translateService.stream(translationRequired).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.translations = data;
    });

    this.quotationService.baseQuotation.pipe(switchMap(t => {
      this.baseQuotation = t;
      return this.pdfService.printPdfRetailQuotation(this.baseQuotation.id, this.showComponentiConfigInPdf, 
        this.showProductCodeInPdf, this.showProductImageInPdf)
    })).subscribe((data: any) => {
      if (data.size > 0) {
        this.data = data;

        this.pdfViewerAutoLoadRetail.pdfSrc = data; // pdfSrc can be Blob or Uint8Array
        this.pdfViewerAutoLoadRetail.refresh(); // Ask pdf viewer to load/refresh pdf

      } else {
        alert(this.errorCreatingPdf)
      }
    }, () => {
      alert(this.errorCreatingPdf)
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  printRetailQuotation() {
    saveAs(this.data, `${this.baseQuotation.name}-retail.pdf`);
  }

  reloadPdf() {
    this.ready = false;
    this.pdfService.printPdfRetailQuotation(this.baseQuotation.id, this.showComponentiConfigInPdf, this.showProductCodeInPdf, this.showProductImageInPdf).subscribe((data: any) => {
      if (data.size > 0) {
        this.data = data;
        this.pdfViewerAutoLoadRetail.pdfSrc = data; // pdfSrc can be Blob or Uint8Array
        this.pdfViewerAutoLoadRetail.refresh(); // Ask pdf viewer to load/refresh pdf

      } else {
        alert(this.translations['errorCreatingPdf']);
      }
    }, (_) => {
      alert(this.translations['errorCreatingPdf']);
    });
  }
}
