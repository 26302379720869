import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ListService } from '../list.service';
import { environment } from '../../environments/environment';
import { StoreService } from '../store.service';
import { AuthenticationService } from '../services/authentication.service';
import { forkJoin, take } from 'rxjs';

@Component({
  selector: 'app-product-wizard',
  templateUrl: './product-wizard.component.html',
  styleUrls: ['./product-wizard.component.css']
})
export class ProductWizardComponent implements OnInit {

  product: any;
  configuratorUrl: SafeResourceUrl;
  iframeStart: boolean = false;
  iframeReady: boolean = false;

  constructor(private route: ActivatedRoute, private listService: ListService,
    private sanitizer: DomSanitizer, private authService: AuthenticationService, private storeService: StoreService) { }

  ngOnInit() {
    this.product = this.route.snapshot.data.product;
    let configurationId = this.route.snapshot.params.configurationId;

    let langConfig: string = "en";

    if (this.storeService.translateService.currentLang == "it") {
      langConfig = "it";
    }

    forkJoin([this.listService.getCurrentList().pipe(take(1)), this.authService.getAccessToken().pipe(take(1))]).subscribe(([list, token]) => {
      let url = new URL(this.product.configuratorUrl);
      if (configurationId) {
        url = new URL("https://cfg.macrolux.it/anthology/edit");
        url.searchParams.append('isUpdate', "true");
        url.searchParams.append('idConfigurazione', configurationId);
      }
      url.searchParams.append('ref', "anthology");
      url.searchParams.append('referenceId', this.product.id);
      url.searchParams.append('returnUrl', `${environment.baseUrl}/api/list/${list.id}/${list.type}/wizard`);
      url.searchParams.append('token', token);
      url.searchParams.append('lang', langConfig);
      url.searchParams.append('applicationId', this.product.category);
      this.configuratorUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url.toString());
      this.iframeStart = true;
    })
  }

  iframeLoaded() {
    if (this.iframeStart === true) {
      this.iframeReady = true;
    }
  }

}
