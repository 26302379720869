<div class="container is-widescreen">
  <section class="section-margined">
    <div class="columns is-centered">
      <div class="column is-narrow">
        <div class="box box-thick is-flex" style="width: 270px; height: 150px; justify-content: center;"
          (click)="goToBrand('64c217bbaa4ee222c8fc8c54')">
          <img style="object-fit: contain;" src="/images/brands/Arcdecor.png">
        </div>
      </div>
      <div class="column is-narrow">
        <div class="box box-thick is-flex" style="width: 270px; height: 150px; justify-content: center;"
          (click)="goToBrand('64b69198a61b5d609471f75d')">
          <img style="object-fit: contain;" src="/images/brands/Macrolux.png">
        </div>
      </div>
      <div class="column is-narrow">
        <div class="box box-thick is-flex" style="width: 270px; height: 150px; justify-content: center;"
          (click)="goToBrand('64c217dbaa4ee222c8fc8c55')">
          <img style="object-fit: contain;" src="/images/brands/Stucchi.png">
        </div>
      </div>
    </div>
  </section>
</div>

<section>
  <p-carousel [value]="imageData" [numVisible]="1" [numScroll]="1" [circular]="true" autoplayInterval="8000">
    <ng-template let-product pTemplate="item">
      <figure class="image is-clickable" [ngStyle]="{ 'height': slideShowHeight}" (click)="openImage(product.openUrl)">
        <img [src]="product.url" alt="slide">
      </figure>
    </ng-template>
    <ng-template pTemplate="previousicon">
      <i class="fa-thin fa-chevron-left"></i>
    </ng-template>
    <ng-template pTemplate="nexticon">
      <i class="fa-thin fa-chevron-right"></i>
    </ng-template>
  </p-carousel>
</section>

<!-- <section class="is-clipped">
  <slideshow [minHeight]="'328px'" [height]="slideShowHeight" [imageUrls]="imageUrls" [autoPlay]="true"
    [autoPlayInterval]="8000" [showDots]="true">
  </slideshow>
</section> -->


<div class="container is-widescreen">
  <section class="section-margined">
    <div class="columns is-centered">
      <div class="column is-narrow">
        <div class="box box-thick" (click)="downloadListinoExcel()">
          <div class="media" [ngStyle]="{'align-items': downloadingListinoPdf ? 'center' : 'flex-start' }">
            <figure class="media-left">
              <i class="fas fa-3x fa-file-excel"></i>
            </figure>
            <div class="media-content">
              <ng-container *ngIf="!downloadingListino">
                <div class="title is-7">
                  {{'downloadTheofficial' | translate }}
                </div>
                <div class="subtitle is-5">
                  {{'priceListLink' | translate }}
                </div>
              </ng-container>
              <div *ngIf="downloadingListino" class="is-flex" style="align-items: baseline; width: 150px;">
                <progress class="progress is-small" max="100"></progress>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="box box-thick" (click)="downloadListinoPdf()">
          <div class="media" [ngStyle]="{'align-items': downloadingListinoPdf ? 'center' : 'flex-start' }">
            <figure class="media-left">
              <i class="fas fa-3x fa-file-pdf"></i>
            </figure>
            <div class="media-content">
              <ng-container *ngIf="!downloadingListinoPdf">
                <div class="title is-7">
                  {{'downloadTheofficial' | translate }}
                </div>
                <div class="subtitle is-5">
                  {{'priceListLinkPdf' | translate }}
                </div>
              </ng-container>

              <div *ngIf="downloadingListinoPdf" class="is-flex" style="align-items: baseline; width: 150px;">
                <progress class="progress is-small" [value]="percentDownload" max="100"></progress>
                <span>&nbsp;{{percentDownload}}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <a href="{{catalogoUrl}}" style="text-decoration: none!important;">
          <div class="box box-thick">
            <div class="media">
              <figure class="media-left">
                <i class="fas fa-3x fa-file-archive"></i>
              </figure>
              <div class="media-content">
                <div class="title is-7" style="text-decoration: none !important;">
                  {{'downloadTheofficial' | translate }}
                </div>
                <div class="subtitle is-5" style="text-decoration: none !important;">
                  {{'catalogZipDownload' | translate }}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="column is-narrow">
        <a href="https://macrolux.eu/media/5aqlqlhb/macrolux-it.pdf" target="_blank"
          style="text-decoration: none!important;">
          <div class="box box-thick">
            <div class="media">
              <figure class="media-left">
                <i class="fas fa-3x fa-presentation-screen"></i>
              </figure>
              <div class="media-content">
                <div class="title is-7" style="text-decoration: none !important;">
                  {{'downloadTheofficial' | translate }}
                </div>
                <div class="subtitle is-5" style="text-decoration: none !important;">
                  {{'businessPresentation' | translate }}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </section>


  <section class="section-margined" *ngIf="level==='admin'">
    <app-performance-admin></app-performance-admin>
  </section>

  <section class="section-margined" *ngIf="level==='agent' || level==='admin'">
    <app-performance-agente [level]="level" [agenti]="agents$ | async"
      [agente]="currentAgente"></app-performance-agente>
  </section>

  <section class="section-margined">
    <app-performance-macrolux [level]="level" [clientiAgente]="companies$ | async" [cliente]="currentMacrolux"
      (selectedCliente)="onSelectedMacrolux($event)">
    </app-performance-macrolux>
  </section>

  <section class="section-margined">
    <app-performance-cliente [level]="level" [clientiAgente]="companies$ | async" [cliente]="currentCliente"
      (selectedCliente)="onSelectedCliente($event)"></app-performance-cliente>
  </section>

</div>