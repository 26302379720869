import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserPreferenceService } from "../user-preference.service";
import { StoreService } from '../store.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-checkprize',
  templateUrl: './checkprize.component.html',
  styleUrls: ['./checkprize.component.css']
})
export class ProfileCheckprizeComponent implements OnInit, OnDestroy {

  checkPrizes: any[];
  currentCheckPrize: any;
  sumCheckPrizes: any;
  today: Date;

  destroy$: Subject<boolean> = new Subject<boolean>();
  translations: any = {};

  constructor(private userService: UserPreferenceService, private storeService: StoreService, 
    private authService: AuthenticationService, private fileSaverService: FileSaverService) { }

  ngOnInit() {
    let translationKeys = marker(['date', 'operation', 'reason', 'amount', 'debit', 'credit',
      'errorObtainDocument', 'date', 'companyName', 'balanceToday', 'user', 'subTitleExportCheck']);
    
    this.storeService.translateService.stream(translationKeys).pipe(takeUntil(this.destroy$))
      .subscribe((data) => this.translations = data);

    let claims = this.authService.getUserProfile();
    this.currentCheckPrize = claims.checkPrize;
    this.today = new Date();
    let year = this.today.getFullYear();
    this.userService.getUserCheckPrizes(year).subscribe(data => {
      this.checkPrizes = data;
      this.sumCheckPrizes = Math.abs(data.filter(d => d.source == 1 && d.delta < 0).reduce((acc, d) => acc + d.delta, 0));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  exportCheckPrize(): void {
    this.userService.exportExcelCheckPrize([
      this.translations['subTitleExportCheck'], this.translations['user'],
      this.translations['companyName'], this.translations['date'],
      this.translations['balanceToday'], this.translations['date'],
      this.translations['operation'], this.translations['reason'],
      this.translations['amount']], this.translations['debit'],
      this.translations['credit'], this.storeService.translateService.currentLang).subscribe((data: any) => {
      if (data.size > 0) {
        if (data.size > 0) {
          this.fileSaverService.save(data, `Export-checkPrize.xlsx`);
        } else {
          alert(this.translations['errorObtainDocument']); 
        }
      } else {
        alert(this.translations['errorObtainDocument']);
      }
    }, () => {
            alert(this.translations['errorObtainDocument']);
    });
  }

}
