import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload.component';
import { DialogComponent } from "./dialog/dialog.component";
import { UploadService } from "./upload.service";
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UploadComponent, DialogComponent],
  imports: [CommonModule, DialogModule, ProgressBarModule, TranslateModule.forChild()],
  exports: [UploadComponent],
  providers: [UploadService]
})
export class UploadModule {
}
