<div class="columns">
  <div class="column">
    <h2 class="title is-4">{{category?.name}}</h2>
  </div>
  <div class="column is-3">
    <div class="field has-addons">
      <div class="control is-expanded">
        <input type="text" class="input" [placeholder]="'filterByNameCodArt' | translate" [(ngModel)]="skuFilter">
      </div>
      <div class="control">
        <button class="button" [disabled]="skuFilter === undefined" (click)="skuFilter = undefined">
          <i class="fas fa-eraser fa-fw"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="columns is-multiline">
  <div class="column is-3" *ngFor="let accessory of accessories | filterByImpure: ['sku', 'esolverName']: skuFilter">
    <div class="rect-box" [ngStyle]="{'background-color': coloreSfondoAccessories }"  >
      <div class="content">
        <div class="columns is-vcentered mb-0">
          <div class="column is-narrow">
            <div class="field">
              <p class="control add-cart" [class.selected]="accessory.selected">
                <input class="is-checkradio is-medium is-info" id="add-cart_{{accessory.id}}" type="checkbox"
                       [(ngModel)]="accessory.selected" name="add-cart_{{accessory.id}}"
                       (change)="addToCart(accessory)">
                <label for="add-cart_{{accessory.id}}"></label>
              </p>
            </div>
          </div>
          <div class="column is-narrow">
            <p-inputNumber [showButtons]="true" size="2" [(ngModel)]="accessory.quantity" [min]="1" [disabled]="!accessory.selected"
                       (ngModelChange)="onQtyChange(accessory)"></p-inputNumber>
          </div>
          <div class="column has-text-right" *ngIf="accessory.files?.length > 0">
            <a [href]="accessory | accessoryFile" target="_blank" pTooltip="{{'specificationSheet' | translate}}" 
            tooltipPosition="bottom"  tooltipStyleClass="m-tooltip">
              <i class="far fa-file-pdf fa-2x"></i>
            </a>
          </div>
        </div>
        <div class="columns" *ngIf="!accessory.esWarning && !accessory.selectedPack?.esWarning; else es_warning">
          <div class="column">
            <div class="is-size-6">
              <span>{{accessory.selectedPack ? accessory.selectedPack.sku : accessory.sku}}</span>
              <ng-container *ngIf="(accessory.selectedPack && accessory.selectedPack.esolverDescription) || (!accessory.selectedPack && accessory.esolverDescription)">
                &nbsp;<i class="fas fa-info-circle fa-sm fa-sw" [pTooltip]="accessory.selectedPack ? accessory.selectedPack.esolverDescription : accessory.esolverDescription" tooltipStyleClass="m-tooltip"></i>
              </ng-container>
            </div>
            <div class="has-text-weight-semibold">{{accessory.selectedPack ? accessory.selectedPack.esolverName : accessory.esolverName}}</div>
            <div>
              <span *ngIf="!accessory.selectedPack">
                <span *ngIf="accessory.prezzo" style="margin-right:0.5em;">
                  <span [class.is-line-through]="shopMode == 0">
                    {{accessory.prezzo | currency:'EUR'}}
                  </span>
                </span>
                <span *ngIf="shopMode == 0">
                  &nbsp;&nbsp;{{accessory.prezzoRiservato | currency:'EUR'}}
                </span>
              </span>
              <span *ngIf="accessory.selectedPack">
                <span *ngIf="shopMode == 0">{{accessory.selectedPack.prezzoRiservato | currency:'EUR'}}&nbsp;&nbsp;</span>
                <span *ngIf="accessory.selectedPack.prezzo" style="margin-right:0.5em;">
                  <span [class.is-line-through]="shopMode == 0">{{accessory.selectedPack.prezzo | currency:'EUR'}}</span>
                </span>
              </span>
            </div>
          </div>
        </div>
        <ng-template #es_warning>
          <div class="columns">
            <div class="column">
              <div class="is-size-6">{{accessory.selectedPack ? accessory.selectedPack.sku : accessory.sku}}</div>
              <div class="has-text-weight-semibold" [translate]="'accessoryNotFound'"></div>
            </div>
          </div>
        </ng-template>
        <div class="columns is-vcentered">
          <div class="column">
            <div class="is-size-6 has-text-weight-bold">
              <span [translate]="'availability'"></span> {{accessory.selectedPack ? accessory.selectedPack.qtaDisponibile : accessory.qtaDisponibile}}
            </div>
            <div *ngFor="let attribute of accessory.attributes" class="is-size-6">
              <span *ngIf="attribute.sysName != 'anthology'">
                {{attribute | attributeName | async}} : {{attribute | attributeValue | async}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <figure class="image">
        <img fallback-src="https://via.placeholder.com/640x360" [alt]="accessory.name" [cdnImg]="accessory.image"
             type="accessory" [style.visibility]="accessory.image ? 'visible' : 'hidden'">
      </figure>
      <ng-container *ngIf="!accessory.selectedPack">
        <div *ngIf="accessory.mxStar; else anthology" class="anthology-holder">
          <div class="anthology-chevron" [pTooltip]="'MxStar'" tooltipPosition="right" tooltipStyleClass="m-tooltip">
            <span class="mxstar-icon">
              <i class="fas fa-star fa-fw"></i>
            </span>
          </div>
        </div>
        <ng-template #anthology>
          <div *ngIf="accessory | isAnthology" class="anthology-holder">
            <button class="anthology-chevron" [pTooltip]="translations['accManagedStock']" tooltipPosition="right" tooltipStyleClass="m-tooltip">
              <span class="anthology-icon">
                <img src="/images/anthology.png" height="10" />
              </span>
            </button>
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="accessory.selectedPack">
        <div *ngIf="accessory.selectedPack.mxStar; else anthologyPack" class="anthology-holder">
          <div class="anthology-chevron" [pTooltip]="'MxStar'" tooltipPosition="right" tooltipStyleClass="m-tooltip">
            <span class="mxstar-icon">
              <i class="fas fa-star fa-fw"></i>
            </span>
          </div>
        </div>
        <ng-template #anthologyPack>
          <div *ngIf="accessory.selectedPack.anthology" class="anthology-holder">
            <button class="anthology-chevron" [pTooltip]="accManagedStock" tooltipPosition="right" tooltipStyleClass="m-tooltip">
              <span class="anthology-icon">
                <img src="/images/anthology.png" height="10" />
              </span>
            </button>
          </div>
        </ng-template>
      </ng-container>
      <div class=" buttons is-right is-size-6 has-packs" *ngIf="accessory.packs?.length > 0" style="margin-bottom: 0">
        <a *ngFor="let pack of accessory.packs" (click)="selectPack(accessory, pack)" class="button is-info"
           style="width: 80%"
           [class.is-yellow]="accessory.selectedPack?.sku == pack.sku">
          <span *ngIf="accessory.selectedPack; else selectAPack" [translate]="'remove'">
            Rimuovi
          </span>
          <ng-template #selectAPack>
            <span [translate]="'select'">Seleziona</span>
          </ng-template>
          &nbsp;<span [translate]="'packSize'">pack da</span>&nbsp;{{pack.size}}
        </a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="currentList && selectedAccessories?.count() > 0" class="add-to-list">
  <div class="container">
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <ng-container *ngIf="selectedAccessories?.count() > 0">
            <span [translate]="'selected'"></span>&nbsp;{{selectedAccessories.count()}}&nbsp;<span [translate]="'accessoriesLowerCase'">accessori</span>
          </ng-container>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button" [translate]="'cancel'" (click)="cancel()">
            Annulla
          </button>
        </div>
        <div class="level-item is-marginless">
          <button class="button is-link" (click)="addToList(true)">
            <span [translate]="'addTo'"></span>&nbsp;
            <span *ngIf="currentList.type; else listaSpan" [translate]="'quotation'" >
              Preventivo
            </span>
            <span>&nbsp;{{currentList.name}}</span>
            <ng-template #listaSpan>
              <span [translate]="'list'" >
                Lista
              </span>
            </ng-template>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast id="toastError" key="toastErrorAccessory"></p-toast>
